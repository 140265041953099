import { useCallback, ChangeEvent } from 'react';

import debounce from 'lodash/debounce';

type Callback = (nextValue: string) => void;
type InputChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
export type DebouncedEventHandler = (event: InputChangeEvent) => void;

const DEFAULT_DELAY_MS = 300;

const useDebounce = (callback: Callback, ms = DEFAULT_DELAY_MS): DebouncedEventHandler => {
    const onChange = (event: InputChangeEvent): void => {
        callback(event.target?.value);
    };

    const memoizedCallback = useCallback(debounce(onChange, ms), [ms]);
    return (event) => {
        event.persist();
        memoizedCallback(event);
    };
};

export default useDebounce;
