import React, { FC } from 'react';

import { Divider } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import PolicyDescription from '../../../../../../../Common/PolicyDescription';
import FileOpenOptions from './FileOpenOptions';
import { LinkBasedOpenOptions, OpenOptions } from '../../../../../../../../config/openOptions';
import { FileAccessData } from '../../../hooks';
import { getPolicyAggregate } from '../../../../../../../utils';
import styles from './FilePopup.module.scss';

const nameSpace = 'recipientPage.filePopUp';

interface FilePopupProps extends FileAccessData {
    allowedApps: OpenOptions[];
}

const FilePopup: FC<FilePopupProps> = observer(({
    policy,
    allowedApps,
    fileId,
    ownerEmail,
    filename,
}) => {
    const { t } = useTranslation();
    const optionsForPopUp = allowedApps.filter((item) => item !== OpenOptions.viewer) as LinkBasedOpenOptions[];

    return (
        <div>
            {optionsForPopUp.length > 0 && (
                <>
                    <FileOpenOptions
                        allowedApps={optionsForPopUp}
                        fileId={fileId}
                        filename={filename}
                        ownerEmail={ownerEmail}
                        policy={policy}
                    />
                    <Divider />
                </>
            )}
            <div className={styles['policy-title']}>
                {t(`${nameSpace}.policySettings`)}
            </div>
            <PolicyDescription policyAggregate={getPolicyAggregate(policy)} isPublic />
        </div>
    );
});

export default FilePopup;
