// Array of country objects for the flag dropdown.

// Here is the criteria for the plugin to support a given country/territory
// - It has an iso2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
// - It has it's own country calling code (it is not a sub-region of another country):
// https://en.wikipedia.org/wiki/List_of_country_calling_codes
// - It has a flag in the region-flags project: https://github.com/behdad/region-flags/tree/gh-pages/png
// - It is supported by libphonenumber (it must be listed on this page):
// https://github.com/googlei18n/libphonenumber/blob/master/resources/ShortNumberMetadata.xml

export type Country = {
    name?: string;
    iso2?: string;
    dialCode?: string;
    priority?: number;
    areaCodes?: Array<string>;
}

export interface SelectedCountry extends Required<Pick<Country, 'iso2' | 'dialCode'>> {
    prefix: string;
}

/* eslint-disable max-len */
const ALL_COUNTRIES: Country[] = [
    {
        name: 'Afghanistan (‫افغانستان‬‎)', iso2: 'af', dialCode: '93', priority: 0,
    },
    {
        name: 'Albania (Shqipëri)', iso2: 'al', dialCode: '355', priority: 0,
    }, {
        name: 'Algeria (‫الجزائر‬‎)', iso2: 'dz', dialCode: '213', priority: 0,
    }, {
        name: 'American Samoa', iso2: 'as', dialCode: '1', priority: 5, areaCodes: ['684'],
    }, {
        name: 'Andorra', iso2: 'ad', dialCode: '376', priority: 0,
    }, {
        name: 'Angola', iso2: 'ao', dialCode: '244', priority: 0,
    }, {
        name: 'Anguilla', iso2: 'ai', dialCode: '1', priority: 6, areaCodes: ['264'],
    }, {
        name: 'Antigua and Barbuda', iso2: 'ag', dialCode: '1', priority: 7, areaCodes: ['268'],
    }, {
        name: 'Argentina', iso2: 'ar', dialCode: '54', priority: 0,
    }, {
        name: 'Armenia (Հայաստան)', iso2: 'am', dialCode: '374', priority: 0,
    }, {
        name: 'Aruba', iso2: 'aw', dialCode: '297', priority: 0,
    }, {
        name: 'Australia', iso2: 'au', dialCode: '61', priority: 0,
    }, {
        name: 'Austria (Österreich)', iso2: 'at', dialCode: '43', priority: 0,
    }, {
        name: 'Azerbaijan (Azərbaycan)', iso2: 'az', dialCode: '994', priority: 0,
    }, {
        name: 'Bahamas', iso2: 'bs', dialCode: '1', priority: 8, areaCodes: ['242'],
    }, {
        name: 'Bahrain (‫البحرين‬‎)', iso2: 'bh', dialCode: '973', priority: 0,
    }, {
        name: 'Bangladesh (বাংলাদেশ)', iso2: 'bd', dialCode: '880', priority: 0,
    }, {
        name: 'Barbados', iso2: 'bb', dialCode: '1', priority: 9, areaCodes: ['246'],
    }, {
        name: 'Belarus (Беларусь)', iso2: 'by', dialCode: '375', priority: 0,
    }, {
        name: 'Belgium (België)', iso2: 'be', dialCode: '32', priority: 0,
    }, {
        name: 'Belize', iso2: 'bz', dialCode: '501', priority: 0,
    }, {
        name: 'Benin (Bénin)', iso2: 'bj', dialCode: '229', priority: 0,
    }, {
        name: 'Bermuda', iso2: 'bm', dialCode: '1', priority: 10, areaCodes: ['441'],
    }, {
        name: 'Bhutan (འབྲུག)', iso2: 'bt', dialCode: '975', priority: 0,
    }, {
        name: 'Bolivia', iso2: 'bo', dialCode: '591', priority: 0,
    }, {
        name: 'Bosnia and Herzegovina (Босна и Херцеговина)', iso2: 'ba', dialCode: '387', priority: 0,
    }, {
        name: 'Botswana', iso2: 'bw', dialCode: '267', priority: 0,
    }, {
        name: 'Brazil (Brasil)', iso2: 'br', dialCode: '55', priority: 0,
    }, {
        name: 'British Indian Ocean Territory', iso2: 'io', dialCode: '246', priority: 0,
    }, {
        name: 'British Virgin Islands', iso2: 'vg', dialCode: '1', priority: 11, areaCodes: ['284'],
    }, {
        name: 'Brunei', iso2: 'bn', dialCode: '673', priority: 0,
    }, {
        name: 'Bulgaria (България)', iso2: 'bg', dialCode: '359', priority: 0,
    }, {
        name: 'Burkina Faso', iso2: 'bf', dialCode: '226', priority: 0,
    }, {
        name: 'Burundi (Uburundi)', iso2: 'bi', dialCode: '257', priority: 0,
    }, {
        name: 'Cambodia (កម្ពុជា)', iso2: 'kh', dialCode: '855', priority: 0,
    }, {
        name: 'Cameroon (Cameroun)', iso2: 'cm', dialCode: '237', priority: 0,
    }, {
        name: 'Canada',
        iso2: 'ca',
        dialCode: '1',
        priority: 1,
        areaCodes: ['204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416', '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587', '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807', '819', '825', '867', '873', '902', '905'],
    }, {
        name: 'Cape Verde (Kabu Verdi)', iso2: 'cv', dialCode: '238', priority: 0,
    }, {
        name: 'Caribbean Netherlands', iso2: 'bq', dialCode: '599', priority: 1, areaCodes: ['3', '4', '7'],
    }, {
        name: 'Cayman Islands', iso2: 'ky', dialCode: '1', priority: 12, areaCodes: ['345'],
    }, {
        name: 'Central African Republic (République centrafricaine)', iso2: 'cf', dialCode: '236', priority: 0,
    }, {
        name: 'Chad (Tchad)', iso2: 'td', dialCode: '235', priority: 0,
    }, {
        name: 'Chile', iso2: 'cl', dialCode: '56', priority: 0,
    }, {
        name: 'China (中国)', iso2: 'cn', dialCode: '86', priority: 0,
    }, {
        name: 'Christmas Island', iso2: 'cx', dialCode: '61', priority: 2,
    }, {
        name: 'Cocos (Keeling) Islands', iso2: 'cc', dialCode: '61', priority: 1,
    }, {
        name: 'Colombia', iso2: 'co', dialCode: '57', priority: 0,
    }, {
        name: 'Comoros (‫جزر القمر‬‎)', iso2: 'km', dialCode: '269', priority: 0,
    }, {
        name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', iso2: 'cd', dialCode: '243', priority: 0,
    }, {
        name: 'Congo (Republic) (Congo-Brazzaville)', iso2: 'cg', dialCode: '242', priority: 0,
    }, {
        name: 'Cook Islands', iso2: 'ck', dialCode: '682', priority: 0,
    }, {
        name: 'Costa Rica', iso2: 'cr', dialCode: '506', priority: 0,
    }, {
        name: 'Côte d’Ivoire', iso2: 'ci', dialCode: '225', priority: 0,
    }, {
        name: 'Croatia (Hrvatska)', iso2: 'hr', dialCode: '385', priority: 0,
    }, {
        name: 'Cuba', iso2: 'cu', dialCode: '53', priority: 0,
    }, {
        name: 'Curaçao', iso2: 'cw', dialCode: '599', priority: 0,
    }, {
        name: 'Cyprus (Κύπρος)', iso2: 'cy', dialCode: '357', priority: 0,
    }, {
        name: 'Czech Republic (Česká republika)', iso2: 'cz', dialCode: '420', priority: 0,
    }, {
        name: 'Denmark (Danmark)', iso2: 'dk', dialCode: '45', priority: 0,
    }, {
        name: 'Djibouti', iso2: 'dj', dialCode: '253', priority: 0,
    }, {
        name: 'Dominica', iso2: 'dm', dialCode: '1', priority: 13, areaCodes: ['767'],
    }, {
        name: 'Dominican Republic (República Dominicana)',
        iso2: 'do',
        dialCode: '1',
        priority: 2,
        areaCodes: ['809', '829', '849'],
    }, {
        name: 'Ecuador', iso2: 'ec', dialCode: '593', priority: 0,
    }, {
        name: 'Egypt (‫مصر‬‎)', iso2: 'eg', dialCode: '20', priority: 0,
    }, {
        name: 'El Salvador', iso2: 'sv', dialCode: '503', priority: 0,
    }, {
        name: 'Equatorial Guinea (Guinea Ecuatorial)', iso2: 'gq', dialCode: '240', priority: 0,
    }, {
        name: 'Eritrea', iso2: 'er', dialCode: '291', priority: 0,
    }, {
        name: 'Estonia (Eesti)', iso2: 'ee', dialCode: '372', priority: 0,
    }, {
        name: 'Ethiopia', iso2: 'et', dialCode: '251', priority: 0,
    }, {
        name: 'Falkland Islands (Islas Malvinas)', iso2: 'fk', dialCode: '500', priority: 0,
    }, {
        name: 'Faroe Islands (Føroyar)', iso2: 'fo', dialCode: '298', priority: 0,
    }, {
        name: 'Fiji', iso2: 'fj', dialCode: '679', priority: 0,
    }, {
        name: 'Finland (Suomi)', iso2: 'fi', dialCode: '358', priority: 0,
    }, {
        name: 'France', iso2: 'fr', dialCode: '33', priority: 0,
    }, {
        name: 'French Guiana (Guyane française)', iso2: 'gf', dialCode: '594', priority: 0,
    }, {
        name: 'French Polynesia (Polynésie française)', iso2: 'pf', dialCode: '689', priority: 0,
    }, {
        name: 'Gabon', iso2: 'ga', dialCode: '241', priority: 0,
    }, {
        name: 'Gambia', iso2: 'gm', dialCode: '220', priority: 0,
    }, {
        name: 'Georgia (საქართველო)', iso2: 'ge', dialCode: '995', priority: 0,
    }, {
        name: 'Germany (Deutschland)', iso2: 'de', dialCode: '49', priority: 0,
    }, {
        name: 'Ghana (Gaana)', iso2: 'gh', dialCode: '233', priority: 0,
    }, {
        name: 'Gibraltar', iso2: 'gi', dialCode: '350', priority: 0,
    }, {
        name: 'Greece (Ελλάδα)', iso2: 'gr', dialCode: '30', priority: 0,
    }, {
        name: 'Greenland (Kalaallit Nunaat)', iso2: 'gl', dialCode: '299', priority: 0,
    }, {
        name: 'Grenada', iso2: 'gd', dialCode: '1', priority: 14, areaCodes: ['473'],
    }, {
        name: 'Guadeloupe', iso2: 'gp', dialCode: '590', priority: 0,
    }, {
        name: 'Guam', iso2: 'gu', dialCode: '1', priority: 15, areaCodes: ['671'],
    }, {
        name: 'Guatemala', iso2: 'gt', dialCode: '502', priority: 0,
    }, {
        name: 'Guernsey', iso2: 'gg', dialCode: '44', priority: 1, areaCodes: ['1481', '7781', '7839', '7911'],
    }, {
        name: 'Guinea (Guinée)', iso2: 'gn', dialCode: '224', priority: 0,
    }, {
        name: 'Guinea-Bissau (Guiné Bissau)', iso2: 'gw', dialCode: '245', priority: 0,
    }, {
        name: 'Guyana', iso2: 'gy', dialCode: '592', priority: 0,
    }, {
        name: 'Haiti', iso2: 'ht', dialCode: '509', priority: 0,
    }, {
        name: 'Honduras', iso2: 'hn', dialCode: '504', priority: 0,
    }, {
        name: 'Hong Kong (香港)', iso2: 'hk', dialCode: '852', priority: 0,
    }, {
        name: 'Hungary (Magyarország)', iso2: 'hu', dialCode: '36', priority: 0,
    }, {
        name: 'Iceland (Ísland)', iso2: 'is', dialCode: '354', priority: 0,
    }, {
        name: 'India (भारत)', iso2: 'in', dialCode: '91', priority: 0,
    }, {
        name: 'Indonesia', iso2: 'id', dialCode: '62', priority: 0,
    }, {
        name: 'Iran (‫ایران‬‎)', iso2: 'ir', dialCode: '98', priority: 0,
    }, {
        name: 'Iraq (‫العراق‬‎)', iso2: 'iq', dialCode: '964', priority: 0,
    }, {
        name: 'Ireland', iso2: 'ie', dialCode: '353', priority: 0,
    }, {
        name: 'Isle of Man',
        iso2: 'im',
        dialCode: '44',
        priority: 2,
        areaCodes: ['1624', '74576', '7524', '7924', '7624'],
    }, {
        name: 'Israel (‫ישראל‬‎)', iso2: 'il', dialCode: '972', priority: 0,
    }, {
        name: 'Italy (Italia)', iso2: 'it', dialCode: '39', priority: 0,
    }, {
        name: 'Jamaica', iso2: 'jm', dialCode: '1', priority: 4, areaCodes: ['876', '658'],
    }, {
        name: 'Japan (日本)', iso2: 'jp', dialCode: '81', priority: 0,
    }, {
        name: 'Jersey',
        iso2: 'je',
        dialCode: '44',
        priority: 3,
        areaCodes: ['1534', '7509', '7700', '7797', '7829', '7937'],
    }, {
        name: 'Jordan (‫الأردن‬‎)', iso2: 'jo', dialCode: '962', priority: 0,
    }, {
        name: 'Kazakhstan (Казахстан)', iso2: 'kz', dialCode: '7', priority: 1, areaCodes: ['33', '7'],
    }, {
        name: 'Kenya', iso2: 'ke', dialCode: '254', priority: 0,
    }, {
        name: 'Kiribati', iso2: 'ki', dialCode: '686', priority: 0,
    }, {
        name: 'Kosovo', iso2: 'xk', dialCode: '383', priority: 0,
    }, {
        name: 'Kuwait (‫الكويت‬‎)', iso2: 'kw', dialCode: '965', priority: 0,
    }, {
        name: 'Kyrgyzstan (Кыргызстан)', iso2: 'kg', dialCode: '996', priority: 0,
    }, {
        name: 'Laos (ລາວ)', iso2: 'la', dialCode: '856', priority: 0,
    }, {
        name: 'Latvia (Latvija)', iso2: 'lv', dialCode: '371', priority: 0,
    }, {
        name: 'Lebanon (‫لبنان‬‎)', iso2: 'lb', dialCode: '961', priority: 0,
    }, {
        name: 'Lesotho', iso2: 'ls', dialCode: '266', priority: 0,
    }, {
        name: 'Liberia', iso2: 'lr', dialCode: '231', priority: 0,
    }, {
        name: 'Libya (‫ليبيا‬‎)', iso2: 'ly', dialCode: '218', priority: 0,
    }, {
        name: 'Liechtenstein', iso2: 'li', dialCode: '423', priority: 0,
    }, {
        name: 'Lithuania (Lietuva)', iso2: 'lt', dialCode: '370', priority: 0,
    }, {
        name: 'Luxembourg', iso2: 'lu', dialCode: '352', priority: 0,
    }, {
        name: 'Macau (澳門)', iso2: 'mo', dialCode: '853', priority: 0,
    }, {
        name: 'Macedonia (FYROM) (Македонија)', iso2: 'mk', dialCode: '389', priority: 0,
    }, {
        name: 'Madagascar (Madagasikara)', iso2: 'mg', dialCode: '261', priority: 0,
    }, {
        name: 'Malawi', iso2: 'mw', dialCode: '265', priority: 0,
    }, {
        name: 'Malaysia', iso2: 'my', dialCode: '60', priority: 0,
    }, {
        name: 'Maldives', iso2: 'mv', dialCode: '960', priority: 0,
    }, {
        name: 'Mali', iso2: 'ml', dialCode: '223', priority: 0,
    }, {
        name: 'Malta', iso2: 'mt', dialCode: '356', priority: 0,
    }, {
        name: 'Marshall Islands', iso2: 'mh', dialCode: '692', priority: 0,
    }, {
        name: 'Martinique', iso2: 'mq', dialCode: '596', priority: 0,
    }, {
        name: 'Mauritania (‫موريتانيا‬‎)', iso2: 'mr', dialCode: '222', priority: 0,
    }, {
        name: 'Mauritius (Moris)', iso2: 'mu', dialCode: '230', priority: 0,
    }, {
        name: 'Mayotte', iso2: 'yt', dialCode: '262', priority: 1, areaCodes: ['269', '639'],
    }, {
        name: 'Mexico (México)', iso2: 'mx', dialCode: '52', priority: 0,
    }, {
        name: 'Micronesia', iso2: 'fm', dialCode: '691', priority: 0,
    }, {
        name: 'Moldova (Republica Moldova)', iso2: 'md', dialCode: '373', priority: 0,
    }, {
        name: 'Monaco', iso2: 'mc', dialCode: '377', priority: 0,
    }, {
        name: 'Mongolia (Монгол)', iso2: 'mn', dialCode: '976', priority: 0,
    }, {
        name: 'Montenegro (Crna Gora)', iso2: 'me', dialCode: '382', priority: 0,
    }, {
        name: 'Montserrat', iso2: 'ms', dialCode: '1', priority: 16, areaCodes: ['664'],
    }, {
        name: 'Morocco (‫المغرب‬‎)', iso2: 'ma', dialCode: '212', priority: 0,
    }, {
        name: 'Mozambique (Moçambique)', iso2: 'mz', dialCode: '258', priority: 0,
    }, {
        name: 'Myanmar (Burma) (မြန်မာ)', iso2: 'mm', dialCode: '95', priority: 0,
    }, {
        name: 'Namibia (Namibië)', iso2: 'na', dialCode: '264', priority: 0,
    }, {
        name: 'Nauru', iso2: 'nr', dialCode: '674', priority: 0,
    }, {
        name: 'Nepal (नेपाल)', iso2: 'np', dialCode: '977', priority: 0,
    }, {
        name: 'Netherlands (Nederland)', iso2: 'nl', dialCode: '31', priority: 0,
    }, {
        name: 'New Caledonia (Nouvelle-Calédonie)', iso2: 'nc', dialCode: '687', priority: 0,
    }, {
        name: 'New Zealand', iso2: 'nz', dialCode: '64', priority: 0,
    }, {
        name: 'Nicaragua', iso2: 'ni', dialCode: '505', priority: 0,
    }, {
        name: 'Niger (Nijar)', iso2: 'ne', dialCode: '227', priority: 0,
    }, {
        name: 'Nigeria', iso2: 'ng', dialCode: '234', priority: 0,
    }, {
        name: 'Niue', iso2: 'nu', dialCode: '683', priority: 0,
    }, {
        name: 'Norfolk Island', iso2: 'nf', dialCode: '672', priority: 0,
    }, {
        name: 'North Korea (조선 민주주의 인민 공화국)', iso2: 'kp', dialCode: '850', priority: 0,
    }, {
        name: 'Northern Mariana Islands', iso2: 'mp', dialCode: '1', priority: 17, areaCodes: ['670'],
    }, {
        name: 'Norway (Norge)', iso2: 'no', dialCode: '47', priority: 0,
    }, {
        name: 'Oman (‫عُمان‬‎)', iso2: 'om', dialCode: '968', priority: 0,
    }, {
        name: 'Pakistan (‫پاکستان‬‎)', iso2: 'pk', dialCode: '92', priority: 0,
    }, {
        name: 'Palau', iso2: 'pw', dialCode: '680', priority: 0,
    }, {
        name: 'Palestine (‫فلسطين‬‎)', iso2: 'ps', dialCode: '970', priority: 0,
    }, {
        name: 'Panama (Panamá)', iso2: 'pa', dialCode: '507', priority: 0,
    }, {
        name: 'Papua New Guinea', iso2: 'pg', dialCode: '675', priority: 0,
    }, {
        name: 'Paraguay', iso2: 'py', dialCode: '595', priority: 0,
    }, {
        name: 'Peru (Perú)', iso2: 'pe', dialCode: '51', priority: 0,
    }, {
        name: 'Philippines', iso2: 'ph', dialCode: '63', priority: 0,
    }, {
        name: 'Poland (Polska)', iso2: 'pl', dialCode: '48', priority: 0,
    }, {
        name: 'Portugal', iso2: 'pt', dialCode: '351', priority: 0,
    }, {
        name: 'Puerto Rico', iso2: 'pr', dialCode: '1', priority: 3, areaCodes: ['787', '939'],
    }, {
        name: 'Qatar (‫قطر‬‎)', iso2: 'qa', dialCode: '974', priority: 0,
    }, {
        name: 'Réunion (La Réunion)', iso2: 're', dialCode: '262', priority: 0,
    }, {
        name: 'Romania (România)', iso2: 'ro', dialCode: '40', priority: 0,
    }, {
        name: 'Russia (Россия)', iso2: 'ru', dialCode: '7', priority: 0,
    }, {
        name: 'Rwanda', iso2: 'rw', dialCode: '250', priority: 0,
    }, {
        name: 'Saint Barthélemy', iso2: 'bl', dialCode: '590', priority: 1,
    }, {
        name: 'Saint Helena', iso2: 'sh', dialCode: '290', priority: 0,
    }, {
        name: 'Saint Kitts and Nevis', iso2: 'kn', dialCode: '1', priority: 18, areaCodes: ['869'],
    }, {
        name: 'Saint Lucia', iso2: 'lc', dialCode: '1', priority: 19, areaCodes: ['758'],
    }, {
        name: 'Saint Martin (Saint-Martin (partie française))', iso2: 'mf', dialCode: '590', priority: 2,
    }, {
        name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', iso2: 'pm', dialCode: '508', priority: 0,
    }, {
        name: 'Saint Vincent and the Grenadines', iso2: 'vc', dialCode: '1', priority: 20, areaCodes: ['784'],
    }, {
        name: 'Samoa', iso2: 'ws', dialCode: '685', priority: 0,
    }, {
        name: 'San Marino', iso2: 'sm', dialCode: '378', priority: 0,
    }, {
        name: 'São Tomé and Príncipe (São Tomé e Príncipe)', iso2: 'st', dialCode: '239', priority: 0,
    }, {
        name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', iso2: 'sa', dialCode: '966', priority: 0,
    }, {
        name: 'Senegal (Sénégal)', iso2: 'sn', dialCode: '221', priority: 0,
    }, {
        name: 'Serbia (Србија)', iso2: 'rs', dialCode: '381', priority: 0,
    }, {
        name: 'Seychelles', iso2: 'sc', dialCode: '248', priority: 0,
    }, {
        name: 'Sierra Leone', iso2: 'sl', dialCode: '232', priority: 0,
    }, {
        name: 'Singapore', iso2: 'sg', dialCode: '65', priority: 0,
    }, {
        name: 'Sint Maarten', iso2: 'sx', dialCode: '1', priority: 21, areaCodes: ['721'],
    }, {
        name: 'Slovakia (Slovensko)', iso2: 'sk', dialCode: '421', priority: 0,
    }, {
        name: 'Slovenia (Slovenija)', iso2: 'si', dialCode: '386', priority: 0,
    }, {
        name: 'Solomon Islands', iso2: 'sb', dialCode: '677', priority: 0,
    }, {
        name: 'Somalia (Soomaaliya)', iso2: 'so', dialCode: '252', priority: 0,
    }, {
        name: 'South Africa', iso2: 'za', dialCode: '27', priority: 0,
    }, {
        name: 'South Korea (대한민국)', iso2: 'kr', dialCode: '82', priority: 0,
    }, {
        name: 'South Sudan (‫جنوب السودان‬‎)', iso2: 'ss', dialCode: '211', priority: 0,
    }, {
        name: 'Spain (España)', iso2: 'es', dialCode: '34', priority: 0,
    }, {
        name: 'Sri Lanka (ශ්‍රී ලංකාව)', iso2: 'lk', dialCode: '94', priority: 0,
    }, {
        name: 'Sudan (‫السودان‬‎)', iso2: 'sd', dialCode: '249', priority: 0,
    }, {
        name: 'Suriname', iso2: 'sr', dialCode: '597', priority: 0,
    }, {
        name: 'Svalbard and Jan Mayen', iso2: 'sj', dialCode: '47', priority: 1, areaCodes: ['79'],
    }, {
        name: 'Swaziland', iso2: 'sz', dialCode: '268', priority: 0,
    }, {
        name: 'Sweden (Sverige)', iso2: 'se', dialCode: '46', priority: 0,
    }, {
        name: 'Switzerland (Schweiz)', iso2: 'ch', dialCode: '41', priority: 0,
    }, {
        name: 'Syria (‫سوريا‬‎)', iso2: 'sy', dialCode: '963', priority: 0,
    }, {
        name: 'Taiwan (台灣)', iso2: 'tw', dialCode: '886', priority: 0,
    }, {
        name: 'Tajikistan', iso2: 'tj', dialCode: '992', priority: 0,
    }, {
        name: 'Tanzania', iso2: 'tz', dialCode: '255', priority: 0,
    }, {
        name: 'Thailand (ไทย)', iso2: 'th', dialCode: '66', priority: 0,
    }, {
        name: 'Timor-Leste', iso2: 'tl', dialCode: '670', priority: 0,
    }, {
        name: 'Togo', iso2: 'tg', dialCode: '228', priority: 0,
    }, {
        name: 'Tokelau', iso2: 'tk', dialCode: '690', priority: 0,
    }, {
        name: 'Tonga', iso2: 'to', dialCode: '676', priority: 0,
    }, {
        name: 'Trinidad and Tobago', iso2: 'tt', dialCode: '1', priority: 22, areaCodes: ['868'],
    }, {
        name: 'Tunisia (‫تونس‬‎)', iso2: 'tn', dialCode: '216', priority: 0,
    }, {
        name: 'Turkey (Türkiye)', iso2: 'tr', dialCode: '90', priority: 0,
    }, {
        name: 'Turkmenistan', iso2: 'tm', dialCode: '993', priority: 0,
    }, {
        name: 'Turks and Caicos Islands', iso2: 'tc', dialCode: '1', priority: 23, areaCodes: ['649'],
    }, {
        name: 'Tuvalu', iso2: 'tv', dialCode: '688', priority: 0,
    }, {
        name: 'U.S. Virgin Islands', iso2: 'vi', dialCode: '1', priority: 24, areaCodes: ['340'],
    }, {
        name: 'Uganda', iso2: 'ug', dialCode: '256', priority: 0,
    }, {
        name: 'Ukraine (Україна)', iso2: 'ua', dialCode: '380', priority: 0,
    }, {
        name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', iso2: 'ae', dialCode: '971', priority: 0,
    }, {
        name: 'United Kingdom', iso2: 'gb', dialCode: '44', priority: 0,
    }, {
        name: 'United States', iso2: 'us', dialCode: '1', priority: 0,
    }, {
        name: 'Uruguay', iso2: 'uy', dialCode: '598', priority: 0,
    }, {
        name: 'Uzbekistan (Oʻzbekiston)', iso2: 'uz', dialCode: '998', priority: 0,
    }, {
        name: 'Vanuatu', iso2: 'vu', dialCode: '678', priority: 0,
    }, {
        name: 'Vatican City (Città del Vaticano)', iso2: 'va', dialCode: '39', priority: 1, areaCodes: ['06698'],
    }, {
        name: 'Venezuela', iso2: 've', dialCode: '58', priority: 0,
    }, {
        name: 'Vietnam (Việt Nam)', iso2: 'vn', dialCode: '84', priority: 0,
    }, {
        name: 'Wallis and Futuna (Wallis-et-Futuna)', iso2: 'wf', dialCode: '681', priority: 0,
    }, {
        name: 'Western Sahara (‫الصحراء الغربية‬‎)',
        iso2: 'eh',
        dialCode: '212',
        priority: 1,
        areaCodes: ['5288', '5289'],
    }, {
        name: 'Yemen (‫اليمن‬‎)', iso2: 'ye', dialCode: '967', priority: 0,
    }, {
        name: 'Zambia', iso2: 'zm', dialCode: '260', priority: 0,
    }, {
        name: 'Zimbabwe', iso2: 'zw', dialCode: '263', priority: 0,
    }, {
        name: 'Åland Islands', iso2: 'ax', dialCode: '358', priority: 1, areaCodes: ['18'],
    },
];
/* eslint-enable max-len */

type CountriesDict = Record<string, Country>;

const getCountriesDict = (): CountriesDict => ALL_COUNTRIES.reduce<CountriesDict>((acc, country) => {
    if (!country.areaCodes) {
        acc[country.dialCode] = country;
    }
    return acc;
}, {});

export const getCountriesList = (): Country[] => Object.values(getCountriesDict());

const DEFAULT_PREFIX: SelectedCountry = {
    iso2: 'il',
    prefix: '972',
    dialCode: '972',
};

export const findCountry = (prefix: string): SelectedCountry => {
    if (prefix) {
        const country = ALL_COUNTRIES.find(({ dialCode }) => dialCode === prefix);
        if (country) {
            const { dialCode, iso2 } = country;
            return { dialCode, iso2, prefix: dialCode };
        }
    }
    return DEFAULT_PREFIX;
};
