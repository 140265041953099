import React, { FC } from 'react';

import { Checkbox } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import i18n from '@/content';
import { useStores } from '../../../hooks';
import ColoredTooltip from '../../ColoredTooltip';
import styles from './NotifyRecipients.module.scss';

const nameSpace = 'sharingBlock.notifyRecipients';

const NotifyRecipients: FC = observer(() => {
    const { sharedUsersStore: { notifyRecipients, setNotifyRecipients } } = useStores();

    return (
        <Checkbox
            checked={notifyRecipients}
            onChange={(event) => setNotifyRecipients(event.target.checked)}
            data-testid="uploadDrawer_notifyRecipientCheck"
        >
            <span className={styles['label-content']} data-testid="uploadDrawer_notifyRecipient">
                {i18n.t(`${nameSpace}.label`)}
                <ColoredTooltip title={i18n.t(`${nameSpace}.tooltip`)}>
                    <QuestionCircleOutlined className={styles.icon} />
                </ColoredTooltip>
            </span>
        </Checkbox>
    );
});

export default NotifyRecipients;
