import React, { FC } from 'react';

import { Button, Result, message } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import ElasticFileName from '../../../Common/ElasticFileName';
import { useStores } from '../../../hooks';
import { SecuredFile } from '../../../../types/types';
import { ModalContentProps } from '../../../Common/Modal';

const buttonsNameSpace = 'general.buttons';
const nameSpace = 'filesTable.modals.deleteFile';
const messagesNameSpace = `${nameSpace}.messages`;

interface DeleteFileProps extends ModalContentProps {
    fileSelected: SecuredFile;
    closeModal: () => void;
}

const DeleteModal: FC<DeleteFileProps> = observer(({
    fileSelected,
    closeModal: closeModalProp,
    setLoadingStatus,
    setErrorStatus,
    resetParentModalState,
}) => {
    const { filesListStore } = useStores();

    const closeModal = (): void => {
        resetParentModalState?.();
        closeModalProp();
    };

    const displayFileName = fileSelected.filename || fileSelected.fid;

    const onSuccess = (): void => {
        message.success(i18n.t(
            `${messagesNameSpace}.${filesListStore.isShared ? 'successShared' : 'successOwn'}`,
            { displayFileName },
        ));
        filesListStore.deleteFilesOrFolder(fileSelected.fid, filesListStore.displayFilesType);
        setLoadingStatus(false);
        closeModal();
    };

    const handleDelete = async (): Promise<void> => {
        await filesListStore.rescheduleRefreshAsync<void>(async () => {
            setLoadingStatus(true, i18n.t(`${messagesNameSpace}.loading`));
            const result = await filesListStore.deleteFile(fileSelected.fid);
            if (result.succeed) {
                onSuccess();
            } else {
                setErrorStatus(true, i18n.t(`${messagesNameSpace}.error`));
            }
        });
    };

    return (
        <Result
            title={(
                <div>
                    {i18n.t(`${nameSpace}.title.${filesListStore.isShared ? 'sharedFile' : 'ownFile'}`)}
                    <ElasticFileName filename={displayFileName} justify="center" />
                </div>
            )}
            extra={(
                <>
                    <Button onClick={handleDelete} danger>
                        {i18n.t(`${buttonsNameSpace}.delete`)}
                    </Button>
                    <Button onClick={closeModal}>
                        {i18n.t(`${buttonsNameSpace}.cancel`)}
                    </Button>
                </>
            )}
        />
    );
});

export default DeleteModal;
