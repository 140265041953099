import {
    FC,
    ReactNode,
    useLayoutEffect,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
    children: ReactNode;
    nodeId: string;
}

const Portal: FC<PortalProps> = ({ children, nodeId }) => {
    const [isTargetFound, setIsTargetFound] = useState<boolean>(false);

    useLayoutEffect(() => {
        const observer: MutationObserver = new MutationObserver(() => {
            if (document.getElementById(nodeId)) {
                setIsTargetFound(true);
                observer.disconnect();
            }
        });

        if (document.getElementById(nodeId)) {
            setIsTargetFound(true);
        } else {
            observer.observe(document.body, { childList: true, subtree: true });
        }

        return () => observer.disconnect();
    }, []);

    return isTargetFound
        ? createPortal(children, document.getElementById(nodeId))
        : null;
};

export default Portal;
