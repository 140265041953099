import React, { FC } from 'react';

import { observer } from 'mobx-react';

import { Select } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import { filterByLabel } from '@/components/utils';

interface PolicySelectProps {
    selectedPolicyId: string;
    onChange: (policyId: string) => void | Promise<void>;
    className?: string;
    hasBuiltInBorders?: boolean;
}

const PolicySelect: FC<PolicySelectProps> = observer(({
    selectedPolicyId,
    onChange,
    className,
}) => {
    const { policyStore: { policiesListToDropdown } } = useStores();

    return (
        <Select<string>
            className={className}
            showSearch
            onChange={onChange}
            value={selectedPolicyId}
            options={policiesListToDropdown}
            filterOption={filterByLabel}
        />
    );
});

export default PolicySelect;
