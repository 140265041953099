import React, {
    FC, memo, ReactElement, useCallback,
} from 'react';

import { Button } from 'antd';
import { GoogleOutlined, CodeSandboxOutlined } from '@ant-design/icons';

import i18n from '@/content';
import { ConnectExternalStorageProps } from '../interfaces';
import { SimpleCallback, StorageProvider } from '../../../../types/types';

export interface ConnectButtonUIProps extends Pick<ConnectExternalStorageProps, 'isLoading' | 'onClick'> {
    storageProvider: StorageProvider;
    oauthClickHandler: SimpleCallback;
    isDisabled?: boolean;
}

interface ButtonUI {
    icon: ReactElement;
    providerName: string;
}

const MAP_STORAGE_PROVIDER_TO_BUTTON_UI: Record<StorageProvider, ButtonUI> = {
    box: {
        icon: <CodeSandboxOutlined />,
        providerName: 'Box',
    },
    googledrive: {
        icon: <GoogleOutlined />,
        providerName: 'Google',
    },
};

const BUTTON_STYLE = {
    padding: '0',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
};

export const useOauthClick = (
    oauthClickHandler: SimpleCallback,
    onClick?: SimpleCallback,
): SimpleCallback => useCallback(() => {
    if (onClick) {
        onClick();
    }
    oauthClickHandler();
}, [onClick, oauthClickHandler]);

const ConnectButton: FC<ConnectButtonUIProps> = ({
    storageProvider, onClick, oauthClickHandler, isLoading = false, isDisabled = false,
}) => {
    const { providerName, icon } = MAP_STORAGE_PROVIDER_TO_BUTTON_UI[storageProvider];

    const handleClick = useOauthClick(oauthClickHandler, onClick);

    return (
        <Button
            type="text"
            disabled={isDisabled}
            onClick={handleClick}
            loading={isLoading}
            icon={icon}
            style={BUTTON_STYLE}
        >
            {i18n.t('externalStorage.connectAccount', { providerName })}
        </Button>
    );
};

export default memo(ConnectButton);
