import { SecuredFile } from '../../../types/types';

export const getFilesIdsSet = (files: SecuredFile[]): Set<string> => (
    new Set<string>(files.map(({ fid }) => fid))
);

export const getSortedRefreshedFiles = (
    currentFilesIdsSet: Set<string>, fetchedFiles: SecuredFile[],
): SecuredFile[] => {
    let newFiles: SecuredFile[] = [];
    const previouslyLoadedFiles: SecuredFile[] = [];
    const firstPreviouslyLoadedFileIndex = fetchedFiles.findIndex(({ fid }) => currentFilesIdsSet.has(fid));
    if (firstPreviouslyLoadedFileIndex === -1) {
        newFiles = fetchedFiles;
    } else {
        fetchedFiles.forEach((file, index) => {
            if (currentFilesIdsSet.has(file.fid) || index >= firstPreviouslyLoadedFileIndex) {
                previouslyLoadedFiles.push(file);
            } else {
                newFiles.push(file);
            }
        });
    }
    return newFiles.concat(previouslyLoadedFiles);
};

export const mergeCompletedFiles = (
    currentFilesList: SecuredFile[],
    completedFilesList: SecuredFile[],
): SecuredFile[] => {
    const mapIndexToFileId: Record<string, number> = completedFilesList.reduce(
        (acc, file, index) => {
            acc[file.fid] = index;
            return acc;
        },
        {},
    );
    return currentFilesList.map((file) => {
        const completedFileIndex = mapIndexToFileId[file.fid];
        if (typeof completedFileIndex === 'number' && !file.menu) {
            return completedFilesList[completedFileIndex];
        }
        return file;
    });
};
