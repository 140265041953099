import React, { FC, useCallback, useRef } from 'react';

import { Button } from 'antd';
import {
    DeleteOutlined, FolderOutlined, CaretUpOutlined, CaretDownOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';

import UploadStatusIcon from '../UploadStatusIcon';
import { UploadedFile } from '../../../../../../stores';
import styles from './FolderItem.module.scss';
import FileItem from '../FileItem';
import { useStores, useDynamicSizeList } from '../../../../../hooks';

interface FolderItemProps extends Required<Pick<UploadedFile, 'name' | 'status' | 'uid'>> {
    onRemove: () => void | Promise<void>;
    setChosenForPolicyFileUid: (uid: string) => void;
    chosenForPolicyFileUid: string;
    uploadedFilesCount: number;
    foldedFiles: UploadedFile[];
    retry: (uid: string) => void;
    expanded: boolean;
}

const DEFAULT_CONTAINER_HEIGHT = 500;
const ESTIMATE_ITEM_HEIGHT = 50;

const estimateItemHeight = (): number => ESTIMATE_ITEM_HEIGHT;

const FolderItem: FC<FolderItemProps> = ({
    chosenForPolicyFileUid,
    name,
    onRemove,
    status,
    setChosenForPolicyFileUid,
    uid,
    uploadedFilesCount,
    foldedFiles,
    retry,
    expanded,
}) => {
    const scrollElementRef = useRef<HTMLDivElement>(null);

    const { virtualItems, measureElement, totalHeight } = useDynamicSizeList({
        itemsCount: foldedFiles?.length || 0,
        overscan: 3,
        scrollingDelay: 150,
        estimateItemHeight,
        getItemKey: useCallback((index) => foldedFiles[index].uid, [foldedFiles]),
        getScrollElement: useCallback(() => scrollElementRef.current, []),
    });

    const generalFolderStyle = classNames(
        styles['upload-folder'],
        { [styles['chosen-folder']]: chosenForPolicyFileUid === uid },
    );

    const hasError = status === 'error';
    const isUploading = status === 'uploading';

    const contentBoxClasses = classNames(styles['content-box'], { [styles['has-error']]: hasError });

    const { uploadFilesStore: { expandFolder } } = useStores();

    const foldedFilesLength = foldedFiles?.length || 0;

    const getFolderFilesText = (): string => {
        switch (status) {
        case 'error':
            return '';
        case 'done':
            return foldedFilesLength ? `${foldedFilesLength} files` : '';
        default:
            return `${uploadedFilesCount}/${foldedFilesLength} - Uploading...`;
        }
    };

    const openFolder = (val): void => {
        expandFolder(val, uid);
    };

    const containerHeight = (): number => {
        if (expanded && totalHeight < DEFAULT_CONTAINER_HEIGHT) return totalHeight;
        if (expanded && totalHeight >= DEFAULT_CONTAINER_HEIGHT) return DEFAULT_CONTAINER_HEIGHT;
        return 0;
    };

    return (
        <div className={generalFolderStyle} onClick={() => setChosenForPolicyFileUid(uid)}>
            <div className={contentBoxClasses}>
                <div className={styles['folder-name-wrapper']}>
                    { !!foldedFilesLength && expanded && <CaretUpOutlined className={styles['folder-chevron']} onClick={() => openFolder(false)} />}
                    { !!foldedFilesLength && !expanded && <CaretDownOutlined className={styles['folder-chevron']} onClick={() => openFolder(true)} />}
                    <FolderOutlined className={styles['folder-icon']} />
                    <span
                        className={classNames(
                            styles['folder-name'],
                            { [styles['folder-name-opacity']]: isUploading },
                        )}
                        title={name}
                    >
                        {name}
                    </span>
                </div>

                {onRemove && (
                    <div className={classNames(styles['folder-progress'], { [styles.expanded]: expanded })}>
                        {status !== 'uploading' && (
                            <UploadStatusIcon status={status} />
                        )}
                        <span className={styles['folder-progress-text']}>
                            {getFolderFilesText()}
                        </span>
                        <Button
                            size="small"
                            type="text"
                            className={styles['remove-btn']}
                            onClick={onRemove}
                            icon={<DeleteOutlined />}
                        />
                    </div>
                )}
                <div
                    ref={scrollElementRef}
                    className={styles['folder-virtual-list']}
                    style={{ height: containerHeight() }}
                >
                    {expanded && (
                        <div style={{ height: totalHeight }}>
                            {virtualItems.map((virtualItem) => {
                                const item = foldedFiles[virtualItem.index];
                                return (
                                    <div
                                        key={item.uid}
                                        data-index={virtualItem.index}
                                        ref={measureElement}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            transform: `translateY(${virtualItem.offsetTop}px)`,
                                            width: 200,
                                        }}
                                    >
                                        <FileItem
                                            chosenForPolicyFileUid={chosenForPolicyFileUid}
                                            name={item.name}
                                            percent={item.percent}
                                            retry={retry}
                                            status={item.status}
                                            setChosenForPolicyFileUid={setChosenForPolicyFileUid}
                                            uid={item.uid}
                                            size={item.size}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FolderItem;
