import React, { CSSProperties, FC, useCallback } from 'react';

/*
* Fork of react-iframe-form https://github.com/Horat1us/react-iframe-form
* */

export interface Config {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { [k: string]: any; };
    method: 'GET' | 'POST' | 'get' | 'post',
    url: string;
}

export interface IframeFormProps {
    name: string;
    config: Config;
    style?: CSSProperties;
}

const IframeForm: FC<IframeFormProps> = ({ config, name, style }) => {
    const [isSent, setIsSent] = React.useState<boolean>(false);

    const submitOnMount = useCallback((form?: HTMLFormElement) => {
        if (!form) {
            return;
        }
        form.submit();
        setIsSent(true);
    }, []);

    return (
        <>
            <iframe name={name} style={style} title="Office Viewer" />
            {!isSent && (
                <form target={name} action={config.url} method={config.method} ref={submitOnMount}>
                    {Object.keys(config.data).map((key) => (
                        <input
                            type="hidden"
                            name={key}
                            key={key}
                            value={
                                (typeof config.data[key] === 'string')
                                    ? config.data[key]
                                    : JSON.stringify(config.data[key])
                            }
                        />
                    ))}
                </form>
            )}
        </>
    );
};

IframeForm.displayName = 'IframeForm';

export default IframeForm;
