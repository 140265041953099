import { OrganizationUser } from '@/stores/UsersListStore/interfaces';
import { DropdownItem } from '../../../../../types/types';
import {
    GroupMembersChanges,
    CustomGroup,
    GroupMember,
    UserEntity,
} from '@/stores';

export const getSelectOptions = (usersList: OrganizationUser[], addedEmails: GroupMember[]): DropdownItem<string>[] => (
    usersList.reduce<DropdownItem<string>[]>((acc, { user_id: userId, user_email: email }) => {
        if (!addedEmails.find((item) => item.userId === userId)) {
            acc.push({ value: userId, label: email });
        }
        return acc;
    }, [])
);

const getUsersIdsDiff = (newList: GroupMember[], sample: GroupMember[]): string[] => (
    newList.reduce<string[]>((acc, current) => {
        if (!sample.find(({ userId }) => userId === current.userId)) {
            acc.push(current.userId);
        }
        return acc;
    }, [])
);

export const getChangedUsers = (
    userGroupsList: CustomGroup[],
    usersList: OrganizationUser[],
    addedEmails: GroupMember[],
    selectedGroupId: string,
): GroupMembersChanges => {
    const result: GroupMembersChanges = { deleted: [], added: [] };
    if (userGroupsList?.length) {
        const initUsersList = userGroupsList.find((item) => item.groupId === selectedGroupId)?.members || [];
        result.deleted = getUsersIdsDiff(initUsersList, addedEmails);
        result.added = getUsersIdsDiff(addedEmails, initUsersList);
    }
    return result;
};

export const userEntityToGroupMember = ({
    user_id: userId,
    user_email: email,
}: OrganizationUser): GroupMember => {
    const displayName = email.trim();
    return {
        displayName,
        userId,
        email,
        isGroupManager: false,
    };
};
