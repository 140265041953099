import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../hooks';

import styles from './Greeting.module.scss';

const COMMON_NAME_SPACE = 'recipientPage.common';
const NAME_SPACE = 'recipientPage.greeting';

const Greeting: FC = observer(() => {
    const { t } = useTranslation();

    const {
        recipientPageStore: {
            emailMessageStore: {
                messageId, letterContent,
            },
            sharedFilesStore: {
                senderEmail, allFilesCount,
            },
        },
    } = useStores();

    const sender = senderEmail || t(`${COMMON_NAME_SPACE}.someone`);

    const getSubject = (): string => {
        const defaultSubject = t(`${NAME_SPACE}.subject`, { sender, count: allFilesCount });
        if (letterContent) {
            return letterContent.subject || defaultSubject;
        }
        if (messageId) {
            return t(`${NAME_SPACE}.subjectEncrypted`);
        }
        return defaultSubject;
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.subject} data-testid="recipientPage_message">
                {getSubject()}
            </h1>
            <span className={styles.from}>
                {t(`${NAME_SPACE}.from`, { sender })}
            </span>
        </div>
    );
});

export default Greeting;
