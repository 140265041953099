class Counter {
    private innerValue: number;

    constructor(initValue = 0) {
        this.innerValue = initValue;
    }

    get value(): number {
        return this.innerValue;
    }

    increment(): void {
        this.innerValue += 1;
    }

    decrement(): void {
        this.innerValue -= 1;
    }

    reset(): void {
        this.innerValue = 0;
    }
}

export default Counter;
