import { MutableRefObject, useEffect, useRef } from 'react';

const useMounted = (): MutableRefObject<boolean> => {
    const isMountedRef = useRef<boolean>(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return isMountedRef;
};

export default useMounted;
