import React, { FC, useEffect, useState } from 'react';

import { Button, message } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import SetPolicyComponent from '../SetPolicyComponent';
import ElasticFileName from '../../../Common/ElasticFileName';
import { useStores, useMounted } from '../../../hooks';
import { ModalContentProps } from '../../../Common/Modal';
import { ENDPOINTS, BASEURL } from '../../../../api';
import { Policy, SecuredFile } from '../../../../types/types';
import { captureUnexpectedNetworkError, getErrorResponse } from '../../../utils';
import styles from './PolicyModal.module.scss';

const nameSpace = 'filesTable.modals.settings.policy';
const messagesNameSpace = `${nameSpace}.messages`;

interface PolicyProps extends ModalContentProps {
    closeModal: () => void;
    file: SecuredFile;
    isFilePage?: boolean;
}

const PolicyModal: FC<PolicyProps> = observer(({
    closeModal: closeModalProp,
    file,
    isFilePage = false,
    setLoadingStatus,
    setErrorStatus,
    resetParentModalState,
}) => {
    const [policy, setPolicy] = useState<Policy>(null);

    const { filesListStore: { updateFile }, authSettingsStore: { API }, policyStore } = useStores();

    const { selectedPolicy, hasPolicies, hasFilePolicyError } = policyStore;

    const isMountedRef = useMounted();

    useEffect(() => {
        const loadData = async (): Promise<void> => {
            const filePolicyId = file.policy?.id;
            setLoadingStatus(true, i18n.t(`${messagesNameSpace}.wait.loadingPolicy`));
            await policyStore.fetchPolicyList();
            if (isMountedRef.current) {
                const filePolicy = policyStore.policyList.find(({ id }) => id === filePolicyId);
                if (policyStore.hasLoadingError) {
                    setErrorStatus(true, i18n.t(`${messagesNameSpace}.error.loadingError`));
                } else {
                    policyStore.setSelectedPolicy(filePolicy as Policy);
                    setPolicy(filePolicy as Policy);
                }
                setLoadingStatus(false);
            }
        };

        loadData();
        return () => policyStore.unmount();
    }, []);

    const closeModal = (): void => {
        resetParentModalState?.();
        closeModalProp();
    };

    const onSubmit = async (): Promise<void> => {
        const policyId = policy?.id;
        const displayFileName = file.filename || file.fid;
        try {
            setLoadingStatus(true, i18n.t(`${messagesNameSpace}.wait.savingPolicy`));
            const fileId = file.fid;
            await API.put(BASEURL.backend(), ENDPOINTS.filePolicy(fileId), { body: { policy_id: policyId } });
            message.success(i18n.t(`${messagesNameSpace}.success.updateSuccess`, { displayFileName }));
            setLoadingStatus(false);
            if (isFilePage) {
                updateFile(fileId);
            }
            closeModal();
        } catch (error) {
            captureUnexpectedNetworkError(error, 'PolicyModal.onSubmit');
            const { statusCode, message: errorMessage } = getErrorResponse(error);
            if (statusCode < 500 && errorMessage) {
                setErrorStatus(true, errorMessage);
            } else {
                setErrorStatus(true, i18n.t(`${messagesNameSpace}.error.savingError`));
            }
        }
    };

    const changePolicy = (policyId: string): void => {
        const newPolicy = policyStore.policyList.find(({ id }) => id === policyId);
        setPolicy(newPolicy as Policy);
    };

    return (
        <>
            {!hasFilePolicyError
                && (
                    <div className={styles['file-policy-container']}>
                        <span className={styles.title}>
                            {i18n.t(`${nameSpace}.setFilePolicy`)}
                        </span>
                        <ElasticFileName
                            justify="center"
                            textClassname={styles['file-name']}
                            filename={file.filename || file.fid}
                        />
                        <SetPolicyComponent
                            file={file}
                            selectedPolicy={policy}
                            hasPolicies={hasPolicies}
                            changePolicy={changePolicy}
                        />
                        <Button
                            className={styles['file-policy-button']}
                            disabled={policy?.id === selectedPolicy?.id}
                            onClick={onSubmit}
                        >
                            {i18n.t('general.buttons.apply')}
                        </Button>
                    </div>
                )}
        </>
    );
});

export default PolicyModal;
