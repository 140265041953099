import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import { observer } from 'mobx-react';

import { useDynamicSizeList, useStores } from '@/components/hooks';
import type {
    FolderToDisplay,
    RemoveResult,
    UploadedFile,
} from '@/stores';
import { handleUploadedFileRemovingResult } from '@/stores';
import i18n from '@/content';

import UploadItem from './UploadItem';
import UploadProgress from '../../UploadProgress';
import { UploadIntoFolderHint } from './UploadIntoFolderHint';
import { DRAWER_UPLOAD_ONLY_WIDTH_PX, LIST_WIDTH_PX } from '../../consts';
import styles from './VirtualUploadList.module.scss';

interface VirtualUploadListProps {
    uploadOnly: boolean;
}

const ESTIMATE_ITEM_HEIGHT = 200;
const estimateItemHeight = (): number => ESTIMATE_ITEM_HEIGHT;

const VirtualUploadList: FC<VirtualUploadListProps> = observer(({ uploadOnly }) => {
    const {
        uploadFilesStore: {
            chosenForPolicyFileUid,
            removeUploadingItem,
            modifiedFoldedFiles,
            currentlyUploadingFolders,
            successfullyUploadedItemsCount,
            setChosenForPolicyFileUid,
        },
        filesListStore: {
            currentFolder,
        },
    } = useStores();
    const scrollElementRef = useRef<HTMLDivElement>(null);

    const { totalHeight } = useDynamicSizeList({
        itemsCount: modifiedFoldedFiles.length,
        overscan: 3,
        scrollingDelay: 150,
        estimateItemHeight,
        getItemKey: useCallback((index) => modifiedFoldedFiles[index].uid, [modifiedFoldedFiles]),
        getScrollElement: useCallback(() => scrollElementRef.current, []),
    });

    const removeFile = async (file: UploadedFile | FolderToDisplay): Promise<void> => {
        const item = file.isFolder ? currentlyUploadingFolders.find(({ uid }) => uid === file.uid) : file;
        if (item) {
            const result: RemoveResult = await removeUploadingItem(item);
            const itemName = (item as FolderToDisplay).name || (item as UploadedFile).filename;
            handleUploadedFileRemovingResult(i18n.t.bind(i18n), itemName, result);
        }
    };

    useEffect(() => {
        if (successfullyUploadedItemsCount && !chosenForPolicyFileUid) {
            setChosenForPolicyFileUid(modifiedFoldedFiles[0].uid);
        }
    }, [successfullyUploadedItemsCount, chosenForPolicyFileUid]);

    const hasUploadToFolderInfo = !!currentFolder && uploadOnly;

    return (
        <div className={styles['upload-list']}>
            {hasUploadToFolderInfo && <UploadIntoFolderHint />}
            <div className={styles['upload-list-counter']}>
                <UploadProgress />
            </div>
            <div
                className={styles['virtual-list-wrapper']}
                ref={scrollElementRef}
                style={{
                    width: `${uploadOnly ? DRAWER_UPLOAD_ONLY_WIDTH_PX : LIST_WIDTH_PX}px`,
                    height: totalHeight,
                }}
            >
                {modifiedFoldedFiles.map((file) => (
                    <div key={file.uid}>
                        <UploadItem
                            {...file}
                            onRemove={removeFile}
                            chosenForPolicyFileUid={chosenForPolicyFileUid}
                            foldedFiles={(file as FolderToDisplay).foldedFiles}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
});

export default VirtualUploadList;
