import React, { Component } from 'react';

import { withTranslation, WithTranslation as WithTranslationProps } from 'react-i18next';
import { Collapse } from 'antd';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import PolicyDescription from '../../Common/PolicyDescription';
import FilePanelContent from './FilePanelContent';
import PolicyStore from '../../../stores/PolicyStore';
import UploadFilesStore from '../../../stores/UploadFilesStore';
import { Policy } from '../../../types/types';
import { RemoveResult, PolicySimpleWithMFA } from '../../../stores/UploadFilesStore/interfaces';
import { handleUploadedFileRemovingResult } from '../../../stores';
import { getPolicyAggregate } from '../../utils';
import './index.scss';

const { Panel } = Collapse;

const MAX_ALLOWED_SET_FOR_ALL = 20;

interface FilesPolicyListProps extends WithTranslationProps {
    isExternalStorage: boolean;
    hasMfaNoComplete?: boolean;
    policyStore?: PolicyStore;
    uploadFilesStore?: UploadFilesStore;
}

@inject('policyStore', 'uploadFilesStore')
@observer
class FilesPolicyList extends Component<FilesPolicyListProps> {
    private readonly nameSpace = 'filesPolicyList';

    removeItem = async (fileId: string): Promise<void> => {
        const { uploadFilesStore, t } = this.props;
        const item = uploadFilesStore.successfullyUploadedItems.find(({ fid }) => fid === fileId);
        if (item) {
            const { filename } = item;
            const result: RemoveResult = await uploadFilesStore.removeUploadingItem(item);
            handleUploadedFileRemovingResult(t, filename || fileId, result);
        }
    };

    private getNewPolicy(policyId: string): PolicySimpleWithMFA {
        const { policyStore: { policyList } } = this.props;
        const newPolicy = policyList.find(({ id }) => id === policyId);
        return { id: newPolicy.id, title: newPolicy.title, isMfa: newPolicy.isMfa };
    }

    changePolicy = async (newPolicyId: string, fileId: string, isFolder: boolean): Promise<void> => {
        const { uploadFilesStore, isExternalStorage } = this.props;
        const newPolicy = this.getNewPolicy(newPolicyId);
        await uploadFilesStore.changePolicy({
            newPolicy,
            fileId,
            isFolder,
            isExternalStorage,
        });
    };

    bulkChangePolicy = (newPolicyId: string): void => {
        const { uploadFilesStore, isExternalStorage } = this.props;
        const newPolicy = this.getNewPolicy(newPolicyId);
        uploadFilesStore.bulkChangePolicy(newPolicy, isExternalStorage);
    };

    render(): JSX.Element {
        const {
            hasMfaNoComplete, isExternalStorage, t,
            policyStore: { policyList },
            uploadFilesStore: { successfullyUploadedItems, isSamePolicy },
        } = this.props;
        const { nameSpace } = this;

        const isSetForAllDisabled = isSamePolicy;
        const isSetForAllVisible = (
            successfullyUploadedItems.length > 1
          && successfullyUploadedItems.length <= MAX_ALLOWED_SET_FOR_ALL
        );

        let defaultActiveKey;
        const isOnlyOneFile = successfullyUploadedItems.length === 1;
        if (isOnlyOneFile) {
            const file = successfullyUploadedItems[0];
            defaultActiveKey = file.fid;
        }

        return (
            <div className="files-policy-list">
                <div className="set-files-title">
                    {t(`${nameSpace}.title`)}
                </div>
                <Collapse
                    accordion
                    className="collapse"
                    expandIconPosition="right"
                    defaultActiveKey={defaultActiveKey}
                >
                    {successfullyUploadedItems.map(({
                        filename, policy, fid, hasPolicyError, isFolder,
                    }) => {
                        const selectedPolicy = policyList.find((policyItem) => policy.id === policyItem.id);

                        return (
                            <Panel
                                key={fid}
                                className={classNames('panel', {
                                    'mfa-no-complete': hasMfaNoComplete,
                                    'has-error': hasPolicyError,
                                })}
                                header={(
                                    <FilePanelContent
                                        isExternalStorage={isExternalStorage}
                                        isSetForAllDisabled={isSetForAllDisabled}
                                        isSetForAllVisible={isSetForAllVisible}
                                        isFolder={isFolder}
                                        fid={fid}
                                        filename={filename || fid}
                                        hasPolicyError={hasPolicyError}
                                        policyId={policy?.id}
                                        removeFile={this.removeItem}
                                        bulkChangePolicy={this.bulkChangePolicy}
                                        changePolicy={this.changePolicy}
                                    />
                                )}
                            >
                                {selectedPolicy && (
                                    <PolicyDescription policyAggregate={getPolicyAggregate(selectedPolicy as Policy)} />
                                )}
                            </Panel>
                        );
                    })}

                </Collapse>
            </div>
        );
    }
}

export default withTranslation()(FilesPolicyList);
