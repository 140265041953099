import { FileAccessError, UserIdentity } from '../../../../../../../types/types';
import {
    checkIsMFAErrorType,
    checkIsPermissionsError,
    FileAccessState,
    SharedFile,
    PermissionsState,
    PermissionsSummary,
} from '../../../../../../../stores';
import { OpenOptions } from '../../../../../../../config/openOptions';
import { ContentType, SelectedFileViewState } from './interfaces';
import { eqUserIdentities } from '../../../../../../utils';

export const ERRORS_FOR_NEW_ATTEMPTS: FileAccessError[] = [
    'serverError',
    'MFARequired',
    'MFAFailed',
    'MFALimitReached',
];

interface SelectedFileViewInnerState extends SelectedFileViewState {
    selectedFilePermissionsSummary: PermissionsSummary;
}

export const getContentType = (errorType: FileAccessError, isFolder: boolean): ContentType => {
    if (errorType && !checkIsMFAErrorType(errorType)) {
        return 'error';
    }
    if (isFolder) {
        return 'folder';
    }
    return 'viewer';
};

export const getSelectedFileViewState = (
    selectedFilePermissions: PermissionsState,
    selectedFile: SharedFile,
    selectedItemState: FileAccessState,
): SelectedFileViewInnerState => {
    const selectedFilePermissionsSummary = selectedFilePermissions?.summary;
    const viewerState = selectedItemState?.accessTypesState?.[OpenOptions.viewer];
    const errorType: FileAccessError = checkIsPermissionsError(selectedFilePermissionsSummary)
        ? selectedFilePermissionsSummary
        : viewerState?.errorType;
    return {
        contentType: getContentType(errorType, selectedFile?.is_folder),
        selectedFilePermissionsSummary,
        viewerState,
        errorType,
    };
};

export const checkIsFileEditable = (
    permissionsState: PermissionsState,
    currentUserIdentity: UserIdentity,
    fileOwnerIdentity: UserIdentity,
): boolean => {
    if (eqUserIdentities(currentUserIdentity, fileOwnerIdentity)) {
        return true;
    }
    return !!permissionsState.editAllowed;
};
