/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MILLISECONDS_IN_MINUTE } from '@/consts';

export const AUTH_SCHEDULE_INTERVAL = 2 * MILLISECONDS_IN_MINUTE;
export const RESEND_TIMEOUT = 60;
export const EMAIL_OTP_LENGTH = 6;
export const MFA_CODE_LENGTH = 6;

export const COGNITO_CALL_RETRIES = 3;

export const NETWORK_THROTTLE_USERNAME = 'specterx-otp-network-killer';

export const LAMBDA_TIMEOUT_MESSAGE_PARTIAL = 'socket timeout while invoking lambda function.';

export const codeAuthNameSpace = 'codeAuth';
