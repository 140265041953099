import { NEXT_PAGE_AFTER_SIGN_IN, REDIRECT_WINDOW } from '@/consts';
import { captureErrorForSentry } from './sentryAdapter';

interface SavedRedirectPath {
    path: string;
    timestamp: number;
}

export const saveRedirectPath = (path: string): void => {
    const data: SavedRedirectPath = { path, timestamp: Date.now() };
    sessionStorage.setItem(NEXT_PAGE_AFTER_SIGN_IN, JSON.stringify(data));
};

export const tryGetTimelyRedirectPath = (): string | null => {
    let redirectPath = null;
    const serializedSavedData = sessionStorage.getItem(NEXT_PAGE_AFTER_SIGN_IN);
    if (serializedSavedData) {
        try {
            const { timestamp, path }: SavedRedirectPath = JSON.parse(serializedSavedData);
            if (Date.now() - timestamp < REDIRECT_WINDOW) {
                redirectPath = path;
            }
        } catch (error) {
            console.log('Could not parse redirect path data, saved value is', serializedSavedData);
            captureErrorForSentry(error, 'RedirectFlow.tryGetTimelyRedirectPath');
        }
    }
    return redirectPath;
};
