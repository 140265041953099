import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { RowProps } from '../interfaces';

export type VirtualRowProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type EventProps = Omit<RowProps, 'style' | 'className' | 'columns' | 'isScrolling' | 'key'>;

const getVirtualRowProps = ({
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    onRowClick,
    index,
    rowData,
}: EventProps): VirtualRowProps => {
    const virtualRowProps: VirtualRowProps = { 'aria-rowindex': index + 1 };

    if (
        onRowClick
        || onRowDoubleClick
        || onRowMouseOut
        || onRowMouseOver
        || onRowRightClick
    ) {
        virtualRowProps['aria-label'] = 'row';
        virtualRowProps.tabIndex = 0;

        if (onRowClick) {
            virtualRowProps.onClick = (event) => onRowClick({
                event,
                index,
                rowData,
            });
        }
        if (onRowDoubleClick) {
            virtualRowProps.onDoubleClick = (event) => onRowDoubleClick({
                event,
                index,
                rowData,
            });
        }
        if (onRowMouseOut) {
            virtualRowProps.onMouseOut = (event) => onRowMouseOut({
                event,
                index,
                rowData,
            });
        }
        if (onRowMouseOver) {
            virtualRowProps.onMouseOver = (event) => onRowMouseOver({
                event,
                index,
                rowData,
            });
        }
        if (onRowRightClick) {
            virtualRowProps.onContextMenu = (event) => onRowRightClick({
                event,
                index,
                rowData,
            });
        }
    }

    return virtualRowProps;
};

export default getVirtualRowProps;
