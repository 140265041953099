import React, {
    FC,
    useCallback,
    useState,
    useRef,
} from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import { HeaderPortal } from '../../Header';
import { ConfirmDownloadModal } from '../../Common/ConfirmDownload';
import { useStores } from '../../hooks';
import { downloadFile } from '../../utils';
import styles from './DownloadFile.module.scss';

interface DownloadFileProps {
    runDownload: () => Promise<string>;
    isLoading: boolean;
    ownerEmail: string;
    filename: string;
    filePolicyId: string;
}

// TODO: override it with useImperativeHandler to allow show popup from outside
const DownloadFile: FC<DownloadFileProps> = observer(({
    isLoading,
    ownerEmail,
    filename,
    filePolicyId,
    runDownload,
}) => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const { userStore: { currentUserEmail } } = useStores();

    const linkRef = useRef<string>();

    const onDownloadClick = async (): Promise<void> => {
        const link = await runDownload();
        if (link) {
            if (currentUserEmail === ownerEmail) {
                downloadFile(link);
            } else {
                linkRef.current = link;
                setShowConfirmModal(true);
            }
        }
    };

    const onDownloadConfirm = useCallback((isConfirmed: boolean): void => {
        setShowConfirmModal(false);
        if (isConfirmed) {
            downloadFile(linkRef.current);
        }
    }, []);

    return (
        <>
            <HeaderPortal>
                <div className={styles['header-content-container']}>
                    <Button
                        onClick={onDownloadClick}
                        className={styles.download}
                        loading={isLoading}
                    >
                        {i18n.t('general.buttons.download')}
                    </Button>
                </div>
            </HeaderPortal>
            {showConfirmModal && (
                <ConfirmDownloadModal
                    filename={filename}
                    filePolicyId={filePolicyId}
                    ownerEmail={ownerEmail}
                    onFinish={onDownloadConfirm}
                />
            )}
        </>
    );
});

export default DownloadFile;
