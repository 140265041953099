import { captureException, SeverityLevel } from '@sentry/react';

import CancellableAPI from '../../api/CancellableAPI';
import { CognitoAuthError, CognitoErrorCodes } from '../../types/types';
import errorsFactory from './errorsFactory';
import { checkIsSpecificCode } from './APIErrorsHandlers';

const prepareErrorInheritor = (error: Error, name: string): Error => Object.create(
    Object.getPrototypeOf(error),
    {
        name: {
            value: name,
            writable: true,
            configurable: true,
            enumerable: false,
        },
        ...Object.getOwnPropertyDescriptors(error),
    },
);

const prepareNonErrorException = (error: unknown, name: string): Error => {
    const ErrorClassConstructor = errorsFactory(name);
    let messageJSON: string;
    try {
        messageJSON = JSON.stringify(error);
    } catch {
        messageJSON = (error as Error).message ?? '';
    }
    return new ErrorClassConstructor(messageJSON);
};

export const prepareErrorForSentry = (error: unknown, name: string): Error => {
    if (error instanceof Error) {
        return prepareErrorInheritor(error, name);
    }
    return prepareNonErrorException(error, name);
};

const WARNING_STATUSES_LIST: number[] = [
    401,
    403,
    404,
    406,
    409,
    412,
    415,
];

const WARNING_STATUSES_LIST_WITH_400: number[] = [400, ...WARNING_STATUSES_LIST];

export type SentryAdapter = (error: unknown, name: string) => void;

export type HTTPStatusFilterCondition = 'expected4xx' | 'only404' | 'allow400' | undefined;

export interface ErrorCaptureConfig {
    httpStatusFilterCondition?: HTTPStatusFilterCondition;
    severityLevel?: SeverityLevel;
}

const CODES_DICT: Record<HTTPStatusFilterCondition, number | number[]> = {
    expected4xx: WARNING_STATUSES_LIST,
    only404: 404,
    allow400: WARNING_STATUSES_LIST_WITH_400,
};

const getSeverityLevel = (error: unknown, httpStatusFilterCondition: HTTPStatusFilterCondition): SeverityLevel => {
    let level: SeverityLevel = 'error';
    const isWarning: boolean = (
        !!httpStatusFilterCondition
        && checkIsSpecificCode(error, CODES_DICT[httpStatusFilterCondition])
    );
    if (isWarning) {
        level = 'warning';
    }
    return level;
};

export const captureErrorForSentry = (error: unknown, name: string, config: ErrorCaptureConfig = {}): void => {
    if (!CancellableAPI.isAnyInstanceCancel(error)) {
        const { httpStatusFilterCondition, severityLevel } = config;
        const errorItem: Error = prepareErrorForSentry(error, name);
        const level: SeverityLevel = severityLevel || getSeverityLevel(error, httpStatusFilterCondition);
        captureException(errorItem, { level });
    }
};

export const captureWarningForSentry = (error: unknown, name: string): void => {
    captureErrorForSentry(error, name, { severityLevel: 'warning' });
};

export const captureUnexpectedNetworkError = (error: unknown, name: string): void => {
    captureErrorForSentry(error, name, { httpStatusFilterCondition: 'expected4xx' });
};

export const captureServerError = (error: unknown, name: string): void => {
    captureErrorForSentry(error, name, { httpStatusFilterCondition: 'allow400' });
};

export const captureNon404Error = (error: unknown, name: string): void => {
    captureErrorForSentry(error, name, { httpStatusFilterCondition: 'only404' });
};

const errorToJSONString = (error: unknown): string => JSON.stringify(error, Object.getOwnPropertyNames(error));

export const captureOnce = (adapter: SentryAdapter): SentryAdapter => {
    const capturedErrorsSet: Set<string> = new Set<string>();

    return (error: unknown, name: string): void => {
        const errorJSONStr: string = errorToJSONString(error);
        if (!capturedErrorsSet.has(errorJSONStr)) {
            capturedErrorsSet.add(errorJSONStr);
            adapter(error, name);
        }
    };
};

type CognitoErrorKeys = keyof CognitoAuthError;

const COGNITO_ERROR_KEYS: CognitoErrorKeys[] = ['name', 'message', 'code'];

const checkIsCognitoError = (error: unknown): boolean => (
    !(error instanceof Error)
    && COGNITO_ERROR_KEYS.every((key) => Object.prototype.hasOwnProperty.call(error, key))
);

const COGNITO_WARNING_LEVEL_CODES_SET: ReadonlySet<CognitoErrorCodes> = new Set<CognitoErrorCodes>([
    CognitoErrorCodes.CodeMismatchException,
    CognitoErrorCodes.NotAuthorizedException,
    CognitoErrorCodes.UsernameExistsException,
    CognitoErrorCodes.NotAuthorizedExceptionSignIn,
    CognitoErrorCodes.UserNotConfirmedException,
    CognitoErrorCodes.UserNotFoundException,
    CognitoErrorCodes.UserNotFoundExceptionSignIn,
    CognitoErrorCodes.NetworkError,
]);

const getCognitoErrorSeverityLevel = (code: CognitoErrorCodes | string): SeverityLevel => (
    COGNITO_WARNING_LEVEL_CODES_SET.has(code as CognitoErrorCodes)
        ? 'warning'
        : 'error'
);

export const captureCognitoError = (error: unknown, name: string): void => {
    if (checkIsCognitoError(error)) {
        const { code } = error as CognitoAuthError;
        const level: SeverityLevel = getCognitoErrorSeverityLevel(code);
        captureException(prepareNonErrorException(error, name), { level });
    } else if (typeof error === 'string') {
        captureWarningForSentry(error, name);
    } else {
        captureErrorForSentry(error, name);
    }
};
