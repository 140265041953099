import { PhoneIdentity } from '../../types/types';
import {
    APIName, Endpoint, RequestConfig, SPXAPI,
} from '../interfaces';
import { BASEURL, ENDPOINTS } from '../endpoints';
import { getCognitoUserToken } from '../../components/utils';
import CancellableAPI from '../CancellableAPI';

const getRequestParams = (email: string): [APIName, Endpoint, RequestConfig] => ([
    BASEURL.backend(),
    ENDPOINTS.getPhoneByEmail(email),
    {},
]);

export const getPhoneByEmail = async (API: SPXAPI, email: string): Promise<PhoneIdentity> => (
    API.get(BASEURL.backend(), ENDPOINTS.getPhoneByEmail(email), {})
);

export const getPhoneByEmailCancellable = async (
    email: string,
    API: CancellableAPI,
): Promise<PhoneIdentity> => {
    const [apiName, endpoint, config] = getRequestParams(email);
    const { result } = await API.get<PhoneIdentity>(
        apiName,
        endpoint,
        email,
        config,
    );

    return result;
};

export const getMyPhone = async (API: SPXAPI): Promise<PhoneIdentity> => {
    const token = await getCognitoUserToken();
    return API.get(
        BASEURL.backend(),
        ENDPOINTS.getMyPhoneNumber(),
        { headers: { Authorization: token } },
    );
};
