import React, { ChangeEvent, Component } from 'react';

import { Button, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { Input } from '@/components/Common/UIKit';
import i18n from '@/content';

import TagsList from './TagsList';
import PolicySetting, { PolicyRowProps } from '../PolicySetting';
import { ListTypeInputs } from './interfaces';
import OPTIONS from './options';
import './index.scss';

interface PolicyTagInputRowProps extends PolicyRowProps<string[]> {
    listType: ListTypeInputs;
}

interface PolicyTagInputRowState {
    tags: string[];
    inputValue: string;
}

class PolicyTagInputRow extends Component<PolicyTagInputRowProps, PolicyTagInputRowState> {
    private readonly nameSpace: string;

    constructor(props: PolicyTagInputRowProps) {
        super(props);
        this.state = {
            tags: props.settingValue,
            inputValue: '',
        };
        this.nameSpace = `policyEditor.tagsInput.${props.listType}`;
    }

    componentDidUpdate(prevProps: Readonly<PolicyTagInputRowProps>): void {
        const { settingValue } = this.props;
        if (settingValue !== prevProps.settingValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ tags: settingValue });
        }
    }

    onInput = (event: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = event.target.value;

        this.setState({ inputValue });
    }

    addNew = (): void => {
        const { nameSpace } = this;
        const { inputValue } = this.state;
        const { settingId, handleChange, listType } = this.props;
        const isValid = inputValue.match(OPTIONS[listType].validationRegexp);
        if (!isValid) {
            message.error(i18n.t(`${nameSpace}.notValid`));
        } else {
            const { tags } = this.state;

            const tagExist: boolean = tags.includes(inputValue);

            if (tagExist) {
                message.warning(i18n.t(`${nameSpace}.alreadyIncluded`));
            } else {
                const newTagsList = [inputValue, ...tags];
                this.setState({ tags: newTagsList, inputValue: '' });
                handleChange(settingId, newTagsList);
            }
        }
    }

    onDelete = (tag: string): void => {
        const { tags } = this.state;
        const { settingId, handleChange } = this.props;
        const newTags: string[] = tags.filter((tagValue) => tagValue !== tag);

        this.setState({ tags: newTags });

        handleChange(settingId, newTags);
    }

    render(): JSX.Element {
        const {
            settingId,
            isChild,
            listType,
        } = this.props;
        const { showDescription = false } = OPTIONS[listType];
        const { tags, inputValue } = this.state;
        const hasTags = tags && !!tags.length;

        return (
            <div className="tags-input-wrapper">
                <PolicySetting
                    isChild={isChild}
                    settingId={settingId}
                    type="tag"
                    hideDescription={!showDescription}
                >
                    <Input
                        onChange={this.onInput}
                        value={inputValue}
                        className="add-tags"
                        placeholder={i18n.t(`${this.nameSpace}.placeholder`)}
                        addonAfter={(
                            <Button
                                type="link"
                                onClick={this.addNew}
                            >
                                <PlusOutlined />
                            </Button>
                        )}
                    />
                </PolicySetting>
                {hasTags && <TagsList onDelete={this.onDelete} tagsList={tags} />}
            </div>
        );
    }
}

export default PolicyTagInputRow;
