export enum AppRoutes {
    myFiles = '/my-files',
    sharedWithMe = '/shared-with-me',
    allFiles = '/all-files',
    myWorkspaces = '/my-workspaces',
    policyEditor = '/policy-editor',
    userRoleEditor = '/users-editor',
    auditLogs = '/audits',
    domainsDashboard = '/domains-dashboard',
    settings = '/Settings',
}

export type RoutesListType = AppRoutes[];

export const APP_ROUTES_DEFAULT: RoutesListType = Object.values(AppRoutes);

export enum ExternalStorageRoutes {
    gdrive = '/gdrive/share',
    box = '/box/share',
}

export const VIEWER_ROUTE = '/viewer';
export const VIEWER_ROUTE_TEMPLATE = `${VIEWER_ROUTE}/:id`;

export const EXTERNAL_STORAGE_ROUTES_SET = new Set<ExternalStorageRoutes>(Object.values(ExternalStorageRoutes));

export const APP_ADDITIONAL_ROUTES_TEMPLATES = [VIEWER_ROUTE_TEMPLATE, ...EXTERNAL_STORAGE_ROUTES_SET];

export const CROSS_ORIGIN_OAUTH = '/cross-origin-oauth';
export const SIGN_IN_PATHNAME = 'signin';

export enum MiniAppRoutes {
    sharedFile = '/shared-file',
    giveAccess = '/give-access',
    sharedWorkspace = '/request-file',
}

export const MINI_APPS_PATH_NAMES_LIST: MiniAppRoutes[] = Object.values(MiniAppRoutes);
export const ALLOWED_ROUTES_FOR_UNAUTHORIZED = [MiniAppRoutes.sharedFile];
