import type { UploadFileStatus } from 'antd/lib/upload/interface';

import { MEGABYTE, GIGABYTE } from '@/consts';
import { GenericStatus } from '@/components/Common/Icons';

export const PARALLEL_UPLOAD_PROCESSES_COUNT = 4;

const CHUNK_SIZE_MB = 20;
const SMALL_FILE_LIMIT_MB = 80;
const NAS_STORAGE_LIMIT_GB = 2;
const S3_UPLOAD_LIMIT_GB = 30;
const UPLOAD_TASK_LIMIT_MB = 512;

export const CHUNK_SIZE = CHUNK_SIZE_MB * MEGABYTE;
export const S3_UPLOAD_LIMIT = S3_UPLOAD_LIMIT_GB * GIGABYTE;

export const NAS_FILE_LIMIT = NAS_STORAGE_LIMIT_GB * GIGABYTE;
export const SMALL_FILE_LIMIT = SMALL_FILE_LIMIT_MB * MEGABYTE;

export const FINAL_STATES_SET = new Set<UploadFileStatus>(['error', 'done']);

export const UPLOAD_QUEUE_ELEMENT_LIMIT = 6;

export const UPLOAD_TASK_LIMIT_BYTES = UPLOAD_TASK_LIMIT_MB * MEGABYTE;

export const MAP_UPLOAD_STATUS_TO_GENERIC_STATUS: Record<UploadFileStatus, GenericStatus> = {
    done: 'done',
    uploading: 'loading',
    error: 'error',
    removed: 'error',
};

export const SCAN_MESSAGE_DURATION_SECONDS = 3;
export const ERROR_MESSAGE_DURATION_SECONDS = 8;
export const LONG_MESSAGE_DURATION_SECONDS = 10;
