import { AppRoutes } from '@/config/appRoutes';
import { MILLISECONDS_IN_SECOND } from '@/consts';
import { IS_LOCAL_ENV } from '@/config/env';

import type {
    FilesListType,
    FolderNodePayload,
    Ordering,
    SortableFields,
} from './interfaces';

export const PAGE_SIZE_DEFAULT = 30;
export const MIN_FILES_TO_FETCH_NEXT = 5;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ACTIVE_REFRESH_INTERVAL_SECONDS = IS_LOCAL_ENV ? 25 : 5;

const PASSIVE_REFRESH_INTERVAL_SECONDS = 60;
export const ACTIVE_REFRESH_INTERVAL_SECONDS_MS = ACTIVE_REFRESH_INTERVAL_SECONDS * MILLISECONDS_IN_SECOND;
export const PASSIVE_REFRESH_INTERVAL_SECONDS_MS = PASSIVE_REFRESH_INTERVAL_SECONDS * MILLISECONDS_IN_SECOND;

export const INIT_FOLDER_PAYLOAD: FolderNodePayload = {
    pageToken: '',
    isNextFilesLoading: false,
    isLoading: false,
    ordering: null,
};

export const DEFAULT_ORDERING: Ordering = {
    orderBy: 'last_used_time',
    isDescending: true,
};

export const FILE_META_ORDERING_FIELDS: SortableFields[] = ['filename', 'file_size', 'last_used_time'];

export const MAP_ROUTE_TO_FILES_LIST_TYPE: Partial<Record<AppRoutes, FilesListType>> = {
    [AppRoutes.myFiles]: 'myFiles',
    [AppRoutes.sharedWithMe]: 'sharedFiles',
    [AppRoutes.allFiles]: 'allFiles',
};
