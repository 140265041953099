import React, { FC, memo } from 'react';

import PolicyTag, { PolicyTagProps } from './PolicyTag';
import SkeletonItem from '../SkeletonItem';

const PolicyTagWithSkeleton: FC<PolicyTagProps> = ({ title, fid, handleMenuClick }) => (
    title ? <PolicyTag title={title} fid={fid} handleMenuClick={handleMenuClick} /> : <SkeletonItem />
);

export default memo(PolicyTagWithSkeleton);
