import ability from '../../config/ability';
import { CognitoGroups } from '@/types/types';

export const checkPermissions = (
    permissionRequired: string,
    groupsSet: Set<CognitoGroups>,
    allowedGroups?: CognitoGroups[],
): boolean => {
    if (allowedGroups) {
        const groupsIntersection = allowedGroups.filter((group) => groupsSet.has(group));

        if (!groupsIntersection.length) return false;
    }

    return !permissionRequired || (permissionRequired && ability.can(permissionRequired, 'ALL'));
};

export const isAdminOnly = (allowedGroups: CognitoGroups[]): boolean => (
    allowedGroups?.length === 1 && allowedGroups[0] === CognitoGroups.Administrators
);
