import { DATA_RETENTION, LANGUAGE_POLICY } from '@/consts';
import { Policy, PolicySetting, PolicySettingType } from '../../types/types';

export type PolicyAggregate = Record<string, PolicySetting>;

const SUPPORTED_ORIGINAL_TOP_LEVEL_TYPES: PolicySettingType[] = ['switch', 'dropdown'];

const SUPPORTED_DROPDOWN_TYPES: string[] = [LANGUAGE_POLICY];

export const getPolicyAggregate = (selectedPolicy: Policy): PolicyAggregate => {
    const policyAccess = selectedPolicy.settings?.access || [];

    const parentsMap: PolicyAggregate = {};

    return policyAccess.reduce<PolicyAggregate>((acc, current) => {
        if (current.public && current.value) {
            const [, childId] = current.id.split('.');
            if (
                !childId
                && SUPPORTED_ORIGINAL_TOP_LEVEL_TYPES.includes(current.type)
                && (current.type !== 'dropdown' || SUPPORTED_DROPDOWN_TYPES.includes(current.id))
            ) {
                parentsMap[current.id] = { ...current };
                if (current.id !== DATA_RETENTION) {
                    acc[current.id] = { ...current };
                }
            } else if (parentsMap[current.dependency]) {
                if (current.id === `${DATA_RETENTION}.Value`) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        dependency, id,
                        ...rest
                    } = current;
                    acc[DATA_RETENTION] = { id: DATA_RETENTION, ...rest };
                } else {
                    acc[current.id] = { ...current };
                }
            }
        }

        return acc;
    }, {});
};
