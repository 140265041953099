import { FC } from 'react';

import { Button } from 'antd';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import ProgressBar, { formatPercent } from '@/components/Common/ProgressBar';
import { MAP_UPLOAD_STATUS_TO_GENERIC_STATUS, UploadedFile } from '@/stores';
import { bytesToText } from '@/components/utils';

import UploadStatusIcon from '../UploadStatusIcon';
import styles from './FileItem.module.scss';

interface FileItemProps extends Required<Pick<UploadedFile, 'name' | 'status' | 'percent' | 'uid'>> {
    chosenForPolicyFileUid: string;
    onRemove?: () => void | Promise<void>;
    retry: (uid: string) => void;
    setChosenForPolicyFileUid: (uid: string) => void;
    size: number;
}

const FileItem: FC<FileItemProps> = ({
    chosenForPolicyFileUid,
    onRemove,
    name,
    percent,
    retry,
    setChosenForPolicyFileUid,
    status,
    uid,
    size,
}) => {
    const wrapperClasses = classNames(
        styles['file-item'],
        {
            [styles['chosen-file']]: chosenForPolicyFileUid === uid,
            [styles['in-folder']]: !onRemove,
        },
    );

    const isUploading = status === 'uploading';
    const hasError = status === 'error';

    const uploadRowClasses = classNames(styles['upload-row'], { [styles['has-error']]: hasError });
    const sizeText = bytesToText(size);
    const percentText = formatPercent(percent || 0);

    return (
        <div
            className={wrapperClasses}
            onClick={() => setChosenForPolicyFileUid(uid)}
        >
            <div className={uploadRowClasses}>
                <UploadStatusIcon status={status} />
                <span
                    className={classNames(styles.filename, { [styles['filename-opacity']]: isUploading })}
                    title={name}
                >
                    {name}
                </span>
                <div className={styles['actions-container']}>
                    {hasError && (
                        <Button
                            size="small"
                            type="text"
                            className={classNames(styles['icon-btn'], styles['retry-btn'])}
                            onClick={() => retry(uid)}
                            icon={<RedoOutlined />}
                        />
                    )}
                    {onRemove && (
                        <Button
                            size="small"
                            type="text"
                            className={classNames(styles['icon-btn'], styles['remove-btn'])}
                            onClick={onRemove}
                            icon={<DeleteOutlined />}
                        />
                    )}
                </div>
            </div>
            {isUploading && (
                <ProgressBar
                    percent={percent}
                    contentPlacement="column"
                    strokeWidth={5}
                    color="primary"
                    status={MAP_UPLOAD_STATUS_TO_GENERIC_STATUS[status]}
                />
            )}
            <div className={styles['progress-bottom']}>
                <span className={styles.size}>
                    {sizeText}
                </span>
                <span className={classNames(styles.percent, { [styles.invisible]: !isUploading })}>
                    {percentText}
                </span>
            </div>
        </div>
    );
};

export default FileItem;
