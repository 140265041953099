import { FC } from 'react';

import { ConfigProvider, AutoComplete as AntAutoComplete, AutoCompleteProps } from 'antd';
import classNames from 'classnames';

import { HEXToRGBA } from '@/components/utils';
import { COLORS, CONTROLS_SIZES } from '@/theme';

import './antOverride.scss';

const AutoComplete: FC<AutoCompleteProps> = ({ className, ...rest }) => (
    <ConfigProvider
        theme={{
            token: {
                colorBorder: HEXToRGBA(COLORS.cta, '25%'),
                colorText: COLORS.primaryNew,
                colorTextPlaceholder: HEXToRGBA(COLORS.primaryNew, '50%'),
                colorPrimary: COLORS.cta,
                colorPrimaryHover: COLORS.cta,
                colorTextQuaternary: HEXToRGBA(COLORS.primaryNew, '50%'),
                controlOutline: HEXToRGBA(COLORS.cta, '15%'),
                borderRadius: CONTROLS_SIZES.borderRadius,
                controlHeightSM: CONTROLS_SIZES.heightSM,
                controlHeight: rest.children
                    ? CONTROLS_SIZES.heightDefaultBorderless
                    : CONTROLS_SIZES.heightDefault,
                fontSizeSM: CONTROLS_SIZES.fontSM,
            },
            components: {
                Select: {
                    optionSelectedColor: COLORS.primaryNew,
                    optionActiveBg: COLORS.backgroundHover,
                },
            },
        }}
    >
        <AntAutoComplete
            {...rest}
            className={classNames(
                'ui-select-wrapper',
                {
                    [className]: !!className,
                    'select-has-children': !!rest.children,
                },
            )}
        />
    </ConfigProvider>
);

export default AutoComplete;
