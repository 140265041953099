import React, { FC, ReactNode, SyntheticEvent } from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { IS_LOCAL_ENV } from '../../../../config/env';
import styles from './BaseAuthLink.module.scss';

interface BaseAuthLinkProps {
    href?: string;
    onClick?: (event: SyntheticEvent) => void;
    children: ReactNode;
}

const BaseAuthLink: FC<BaseAuthLinkProps> = ({ href, onClick, children }) => {
    if (href) {
        return (
            <Link to={href} className={styles['base-auth-link']}>
                <span>{children}</span>
            </Link>
        );
    }
    if (IS_LOCAL_ENV && !onClick) {
        console.error('BaseAuthLink component should take one of `href` or `onClick` prop');
    }
    return (
        <Button
            block
            onClick={onClick}
            type="link"
            className={styles['base-auth-link']}
        >
            {children}
        </Button>
    );
};

export default BaseAuthLink;
