// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { START_SIGN_IN_IMMEDIATELY, FEDERATED_PROVIDER } from '@/consts';
import { AuthStates } from '@/types/types';
import { AuthMessage, AuthMessageTypes } from '../utils';

type ErrorDescription = {
    title: string;
    subtitle: string;
};

const nameSpace = 'auth.errors.wso2';

export const getWSOAuthErrors = (error: unknown): ErrorDescription => {
    console.log(error);
    if ((error as Error).message === 'Network Error') {
        return {
            title: `${nameSpace}.cannotLogin`,
            subtitle: `${nameSpace}.networkConnection`,
        };
    }
    return {
        title: `${nameSpace}.common`,
        subtitle: 'general.errors.try',
    };
};

export const hashToSearch = (locationHash: string): string => locationHash.replace('#', '?');

export const checkIsSignInPath = (pathName: string): boolean => pathName.toLowerCase() === '/signin';

const extractLocalStorageItem = (key: string): string => {
    const value = localStorage.getItem(key);
    localStorage.removeItem(key);
    return value;
};

export const extractSavedFederatedProvider = (): string => extractLocalStorageItem(FEDERATED_PROVIDER);

export const getSingInQueryParam = (): string => (
    new URLSearchParams(window.location.search).get(START_SIGN_IN_IMMEDIATELY)
);

export const refreshCognitoSession = async (): Promise<void> => {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession: CognitoUserSession = await Auth.currentSession();
    return new Promise((resolve, reject) => {
        user.refreshSession(currentSession.getRefreshToken(), (error) => {
            if (error) {
                reject(error);
            }
            resolve();
        });
    });
};

export const getUrlParams = (): URLSearchParams => {
    const locationHash = window.location.hash;
    if (locationHash.includes('state')) {
        // If Implicit auth flow is using, all query params will be passed into hash
        return new URLSearchParams(hashToSearch(locationHash));
    }
    return new URLSearchParams(window.location.search);
};

const OPPOSITES_MAP: Partial<Record<AuthStates, AuthMessageTypes>> = {
    [AuthStates.authenticated]: 'signOut',
    [AuthStates.verifyContact]: 'signOut',
    [AuthStates.unauthenticated]: 'signIn',
};

export const handleAuthTopic = (message: AuthMessage, authState: AuthStates): void => {
    if (OPPOSITES_MAP[authState] === message.type) {
        window.location.reload();
    }
};
