import React, {
    forwardRef,
    ReactNode,
    useState,
} from 'react';
import { createPortal } from 'react-dom';

import { Button } from 'antd';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './CollapsibleWidget.module.scss';

interface CollapsibleWidgetProps {
    title: ReactNode;
    renderChildren: () => ReactNode;
    renderChildrenInCollapsedMode?: () => ReactNode;
    closeWidget?: () => void;
    wrapperClassName?: string;
}

const CollapsibleWidget = forwardRef<HTMLDivElement, CollapsibleWidgetProps>(({
    title,
    renderChildren,
    renderChildrenInCollapsedMode,
    closeWidget,
    wrapperClassName,
}, ref) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = (): void => {
        setIsExpanded((currentValue) => !currentValue);
    };

    const { i18n } = useTranslation();
    const wrapperStyles = classNames(
        styles.wrapper,
        styles[`dir-${i18n.dir()}`],
        { [wrapperClassName]: !!wrapperClassName },
    );

    return createPortal((
        <div className={wrapperStyles}>
            <div className={styles.header}>
                <span className={styles['header-text']}>
                    {title}
                </span>
                <div className={styles['collapse-btn-container']}>
                    <Button
                        type="text"
                        size="small"
                        onClick={toggleExpand}
                        className={styles['collapse-btn']}
                    >
                        <CaretDownOutlined
                            className={classNames(
                                styles['trigger-icon'],
                                { [styles['icon-expand-mode']]: !isExpanded },
                            )}
                        />
                    </Button>
                    {closeWidget && (
                        <Button
                            type="text"
                            size="small"
                            onClick={closeWidget}
                            className={styles['collapse-btn']}
                        >
                            <CloseOutlined />
                        </Button>
                    )}
                </div>
            </div>
            <div className={styles['content-container']} ref={ref}>
                {isExpanded
                    ? renderChildren()
                    : renderChildrenInCollapsedMode?.()}
            </div>
        </div>
    ), document.body);
});

export default CollapsibleWidget;
