import { FC } from 'react';
import { Button } from 'antd';

import i18n from '@/content';
import { SimpleCallback } from '@/types/types';

import styles from './BecomeLicensedUserButton.module.scss';

const nameSpace = 'general';
interface BecomeLicensedUserButtonProps {
    onClick?: SimpleCallback;
}

const BecomeLicensedUserButton: FC<BecomeLicensedUserButtonProps> = ({ onClick }) => (
    <Button className={styles['license-button']} onClick={onClick}>
        {i18n.t(`${nameSpace}.buttons.becomeLicensedUser`)}
    </Button>
);

export default BecomeLicensedUserButton;
