import {
    DependencyList,
    EffectCallback,
    useEffect,
    useRef,
} from 'react';

const useComponentDidUpdate = (effect: EffectCallback, deps?: DependencyList): void => {
    const isMountedRef = useRef<boolean>(false);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (!isMountedRef.current) {
            isMountedRef.current = true;
        } else {
            return effect();
        }
    }, deps);
};

export default useComponentDidUpdate;
