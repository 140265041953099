import React, { FC, useState, useEffect } from 'react';

import {
    Button, Drawer, message,
} from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { PUBLIC_ASSETS } from '@/consts';
import Spinner from '../Common/Spin';
import SuccessScreen from './SuccessScreen';
import ShareWorkspace from './ShareWorkspace';
import { useStores } from '../hooks';
import { FileToShare } from '@/stores/SharedUsersStore/interfaces';
import { AppRoutes } from '@/config/appRoutes';
import {
    captureErrorForSentry,
    checkHasPhoneOnlyPolicyInFilesList,
    onFinishSharingFlow,
} from '../utils';
import styles from './WorkspaceDrawer.module.scss';
import './antOverride.scss';

const DRAWER_WIDTH_PX = 400;

const shareNameSpace = 'shareEditPolicy';
const workspaceNameSpace = 'workspaceDrawer';

const WorkspaceDrawer: FC = observer(() => {
    const navigate = useNavigate();

    const {
        appStore,
        sharedUsersStore,
        workspacesStore,
        policyStore,
        usersPhonesStore,
    } = useStores();
    const [showSuccessStep, setShowSuccessStep] = useState(false);
    const { t } = useTranslation();

    const {
        setChosenForPolicyWorkspaceFid,
        chosenForPolicyWorkspaceFid,
        setIsLoading,
        chosenForPolicyWorkspace,
        isLoading,
        setNewWorkspaceId,
        newWorkspaceId,
    } = workspacesStore;
    const { clearRecipients } = sharedUsersStore;
    const { isWorkspaceDrawerOpen } = appStore;

    const chosenWorkspaceListView = chosenForPolicyWorkspace ? [chosenForPolicyWorkspace] : [];

    const bulkShareFiles = async (): Promise<void> => {
        const filesToShare: FileToShare[] = chosenWorkspaceListView.map<FileToShare>((file) => ({
            file_id: file.fid,
            policy_id: file.policy?.id,
        }));
        try {
            setIsLoading(true);
            await sharedUsersStore.bulkShareFiles({ files: filesToShare });
            setShowSuccessStep(true);
        } catch (error) {
            console.log('bulkShareFiles fail', error);
            captureErrorForSentry(error, 'UploadDrawer.bulkShareFiles');
            message.error(t(`${shareNameSpace}.result.error`));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        workspacesStore.refreshWorkspaces();
    }, []);

    const onShareClick = (): void => {
        const workspaceId = newWorkspaceId || chosenForPolicyWorkspaceFid;
        if (workspaceId) {
            navigate(`${AppRoutes.myFiles}?folder_id=${workspaceId}`);
            setNewWorkspaceId('');
        }
        onFinishSharingFlow(
            t,
            bulkShareFiles,
            checkHasPhoneOnlyPolicyInFilesList(chosenWorkspaceListView, policyStore.policyList),
            { sharedUsersStore, workspacesStore, usersPhonesStore },
        );
    };

    const close = (): void => {
        const workspaceId = newWorkspaceId || chosenForPolicyWorkspaceFid;
        if (workspaceId) {
            navigate(`${AppRoutes.myFiles}?folder_id=${workspaceId}`);
            setNewWorkspaceId('');
        }
        clearRecipients();
        setChosenForPolicyWorkspaceFid('');
        appStore.setIsWorkspaceDrawerOpen(false);
        setShowSuccessStep(false);
    };

    return (
        <Drawer
            destroyOnClose
            title={!showSuccessStep && (
                <div className={styles['drawer-header']}>
                    <img
                        alt=""
                        src={`${PUBLIC_ASSETS}/icons/create_workspace.svg`}
                        className={styles.icon}
                    />
                    <div className={styles['header-title']}>
                        {t(`${workspaceNameSpace}.requestFiles`)}
                    </div>
                </div>
            )}
            width={DRAWER_WIDTH_PX}
            className="workspace-drawer-override"
            closable={false}
            open={isWorkspaceDrawerOpen}
            onClose={close}
        >
            <Spinner spinning={isLoading}>
                {!showSuccessStep ? (
                    <ShareWorkspace />
                ) : (
                    <SuccessScreen />
                )}
                <div className={styles['workspace-buttons-wrapper']}>
                    {!showSuccessStep
                        ? (
                            <>
                                <Button className={styles['cancel-button']} onClick={close} data-testid="workspaceDrawer_cancelButton">
                                    {t(`${workspaceNameSpace}.buttons.cancel`)}
                                </Button>
                                <Button className={styles['done-button']} onClick={onShareClick} data-testid="workspaceDrawer_requestButton">
                                    {t(`${workspaceNameSpace}.requestFiles`)}
                                </Button>
                            </>
                        )
                        : (
                            <Button className={styles['done-button']} onClick={close} data-testid="workspaceDrawer_doneButton">
                                {t(`${workspaceNameSpace}.buttons.done`)}
                            </Button>
                        )}
                </div>
            </Spinner>
        </Drawer>
    );
});

export default WorkspaceDrawer;
