import React, { FC, memo } from 'react';

import dayjs from 'dayjs';

import SimpleCell from '../SimpleCell';
import styles from './TimeStampCell.module.scss';

const formatDate = (date: string): string => {
    const dateObj = new Date(date);
    return dayjs(dateObj).format('YYYY-MM-DD HH:mm:ss');
};

interface TimeStampCellProps {
    timestamp: string;
}

const TimeStampCell: FC<TimeStampCellProps> = ({ timestamp }) => (
    <SimpleCell className={styles['timestamp-cell']} text={formatDate(timestamp)} />
);

export default memo(TimeStampCell);
