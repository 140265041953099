import React, { FC, memo } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { PUBLIC_ASSETS } from '@/consts';
import ColoredTooltip from '../../../../../../../Common/ColoredTooltip';
import nameSpace from '../i18nNameSpace';
import styles from './ViewerHint.module.scss';

interface ViewerHintProps {
    allFilesCount: number;
}

const ViewerHint: FC<ViewerHintProps> = ({ allFilesCount }) => {
    const { t } = useTranslation();

    return (
        <div className={styles['viewer-hint']} data-testid="recipientPage_openFileText">
            <img
                alt=""
                className={styles['spx-dark-icon']}
                src={`${PUBLIC_ASSETS}/company_logo/specterx-icon.png`}
            />
            <span className={styles['viewer-hint-text']}>
                {t(`${nameSpace}.viewFilesSecurely`, { count: allFilesCount })}
            </span>
            <ColoredTooltip title={t(`${nameSpace}.viewFilesSecurelyDescription`)}>
                <InfoCircleOutlined className={styles['info-icon']} />
            </ColoredTooltip>
        </div>
    );
};

export default memo(ViewerHint);
