import React, { FC } from 'react';

import { Modal } from 'antd';

import { MFA_ATTEMPTS } from '@/consts';
import AttemptsRemainWarning from '../../OpenViewer/AttemptsRemainWarning';
import MfaComp from '../../AppAuthenticator/MfaComp';

interface MFAModalProps {
    isMfaVisible: boolean;
    onMfaClick: (code: string) => Promise<void>;
    attemptsCounter: number;
    close: () => void;
}

const MFAModal: FC<MFAModalProps> = ({
    isMfaVisible,
    onMfaClick,
    attemptsCounter,
    close,
}) => (
    <Modal
        open={isMfaVisible}
        centered
        footer={null}
        onCancel={close}
        maskClosable={false}
    >
        <>
            {attemptsCounter < MFA_ATTEMPTS && (
                <AttemptsRemainWarning attemptsCounter={attemptsCounter} />
            )}
            <MfaComp onMfaClick={onMfaClick} />
        </>
    </Modal>
);

export default MFAModal;
