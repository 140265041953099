import {
    action,
    computed,
    observable,
    makeObservable,
    runInAction,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import { SingleCallAPI, BASEURL, ENDPOINTS } from '../../api';
import {
    AuditsRequestConfig,
    AuditsResponse,
    FormFieldsChange,
    GetAuditsParams,
    LogRecord,
    PageSize,
    PaginationState,
    SelectedOperation,
} from './interaces';
import { captureErrorForSentry, getErrorResponse } from '../../components/utils';
import { RemoveResult } from '..';

const getLogsForPage = (logsList: LogRecord[], targetPage: number, pageSize: PageSize): LogRecord[] => (
    logsList.slice((targetPage - 1) * pageSize, targetPage * pageSize)
);

const getRecordsCountForPage = (logsList: LogRecord[], targetPage: number, pageSize: PageSize): number => {
    const recordsOnRequestedPage = getLogsForPage(logsList, targetPage, pageSize).length;
    return pageSize - recordsOnRequestedPage;
};

const INIT_PAGINATION: PaginationState = {
    pageSize: 25,
    currentPage: 1,
    nextPageToken: '',
};

class AuditsStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.singleCallAPI = new SingleCallAPI(authSettingsStore, false);
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly singleCallAPI: SingleCallAPI;

    private readonly authSettingsStore: AuthSettingsStore;

    @observable logsList: LogRecord[] = [];

    @observable pagination: PaginationState = { ...INIT_PAGINATION };

    @observable fileId = '';

    @observable searchedUser = '';

    @observable searchedFilename = '';

    @observable selectedOperation: SelectedOperation = '';

    @observable hasError = false;

    @observable isLoading = false;

    @observable isEmailBecomeLicensedUserSentSuccess: RemoveResult = 'unset';

    @computed
    get logsToDisplay(): LogRecord[] {
        const { logsList, pagination: { currentPage, pageSize } } = this;
        return getLogsForPage(logsList, currentPage, pageSize);
    }

    @action
    setFileId = (newValue: string): void => {
        this.fileId = newValue;
    };

    @action
    setIsEmailBecomeLicensedUserSent = (newValue: RemoveResult): void => {
        this.isEmailBecomeLicensedUserSentSuccess = newValue;
    };

    @action
    setSelectedOperation = (newValue: SelectedOperation): void => {
        this.selectedOperation = newValue;
    };

    @action
    setSearchedFilename = (newValue: string): void => {
        this.searchedFilename = newValue;
    };

    @action
    setSearchedUser = (newValue: string): void => {
        this.searchedUser = newValue;
    };

    @action
    setFilter = (filter: Partial<FormFieldsChange>): void => {
        runInAction(() => {
            Object.entries(filter).forEach(([key, value]) => {
                this[key] = value;
            });
        });
    };

    @action
    clearFilters = (): void => {
        this.searchedFilename = '';
        this.searchedUser = '';
        this.selectedOperation = '';
        this.fileId = '';
    };

    @action
    setPagination = (value: PaginationState): void => {
        this.pagination = value;
    };

    @action
    setHasError = (value: boolean): void => {
        this.hasError = value;
    };

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    };

    updatePagination = (value: Partial<PaginationState>): void => {
        this.setPagination({ ...this.pagination, ...value });
    };

    async getAudits({
        recordsCount,
        allowInParallel,
        next = '',
    }: AuditsRequestConfig): Promise<AuditsResponse> {
        const {
            fileId,
            searchedUser,
            searchedFilename,
            selectedOperation,
            singleCallAPI,
            authSettingsStore: { API },
        } = this;
        const APIInstance = allowInParallel ? API : singleCallAPI;
        return APIInstance.get(BASEURL.backend(), ENDPOINTS.getAudits(recordsCount, {
            next,
            file_id: fileId,
            user: searchedUser,
            operation: selectedOperation,
            file_name: searchedFilename,
        }), {});
    }

    tryFetchAudits = async ({ page, restRecords }: GetAuditsParams = { page: 1 }): Promise<void> => {
        const { logsList, pagination: { nextPageToken, pageSize } } = this;

        const isFirstPage = page === 1;
        const next = isFirstPage && !restRecords ? '' : nextPageToken;
        const requestedRecordsCount = restRecords || (
            isFirstPage ? pageSize : getRecordsCountForPage(logsList, page, pageSize)
        );

        runInAction(() => {
            this.isLoading = true;
            this.hasError = false;
        });
        try {
            if (!isFirstPage && !next) {
                throw Error(
                    `Inconsistent getAudits call: page is ${page},
                     requestedRecordsCount is ${requestedRecordsCount},
                     next is ${next}`,
                );
            }
            const audits = await this.getAudits({
                recordsCount: requestedRecordsCount,
                allowInParallel: false,
                next,
            });
            const newPagination: PaginationState = {
                nextPageToken: audits.next,
                currentPage: page,
                pageSize,
            };
            const shouldReplaceCurrentRecords = isFirstPage && !restRecords;
            const currentLogsList = shouldReplaceCurrentRecords ? [] : logsList;

            runInAction(() => {
                this.pagination = newPagination;
                this.logsList = [...currentLogsList, ...audits.results];
                this.isLoading = false;
            });
        } catch (error) {
            if (!this.singleCallAPI.checkIsCancel(error)) {
                console.log('could not load records', error);
                captureErrorForSentry(error, 'AuditsStore.tryFetchAudits');
                runInAction(() => {
                    this.hasError = true;
                    this.isLoading = false;
                });
            }
        }
    };

    // TODO: create a separate store for this method or add a new loader
    generateBecomeLicensedUserEmail = async (
        newLeadEmail: string,
        newLeadRealLoginEmail: string,
    ): Promise<void> => {
        try {
            const {
                authSettingsStore: { API },
            } = this;
            this.setIsLoading(true);
            this.setIsEmailBecomeLicensedUserSent('unset');
            await API.post(
                BASEURL.backend(),
                ENDPOINTS.generateEmailBecomeLicensedUser(),
                {
                    body: {
                        notification_id: 'send_new_lead_notification',
                        new_lead_email: newLeadEmail,
                        new_lead_real_login_email: newLeadRealLoginEmail,
                    },
                },
            );
            this.setIsEmailBecomeLicensedUserSent('success');
        } catch (error) {
            const { statusCode } = getErrorResponse(error);
            if (statusCode === 404) {
                this.setIsEmailBecomeLicensedUserSent('error');
            } else {
                captureErrorForSentry(error, 'UploadFilesStore.deleteFile');
                this.setIsEmailBecomeLicensedUserSent('error');
            }
        } finally {
            this.setIsLoading(false);
        }
    };

    handlePageChange = (page: number): void => {
        const { logsList, pagination: { pageSize, nextPageToken } } = this;
        if (page === 1) {
            this.tryFetchAudits();
        } else if (nextPageToken && (page > logsList.length / pageSize)) {
            this.tryFetchAudits({ page });
        } else {
            this.updatePagination({ currentPage: page });
        }
    };

    handlePageSizeChange = (size: number): void => {
        const { logsList, pagination: { nextPageToken, currentPage } } = this;
        const recordsCount = logsList.length;
        const lastPage = Math.ceil(recordsCount / size);
        const newCurrentPage = currentPage * size > recordsCount ? lastPage : currentPage;
        this.updatePagination({ currentPage: newCurrentPage, pageSize: size as PageSize });
        const isFirstOrLast = newCurrentPage === 1 || newCurrentPage === lastPage;
        const recordsToFullPage = newCurrentPage * size - recordsCount;
        if (nextPageToken && isFirstOrLast && recordsToFullPage > 0) {
            this.tryFetchAudits({ page: newCurrentPage, restRecords: recordsToFullPage });
        }
    };
}

export default AuditsStore;
