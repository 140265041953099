import React, {
    FC,
    useCallback,
    useState,
} from 'react';

import { Button } from 'antd';
import {
    CaretUpOutlined,
    CaretDownOutlined,
    ExclamationCircleOutlined,
    FileProtectOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ErrorTooltip from '../../../../Common/ErrorTooltip';
import PolicySelect from '../../../../Common/PolicySelect';
import PolicyDescription from '../../../../Common/PolicyDescription';
import { useStores } from '../../../../hooks';
import { PolicySimpleWithMFA, UploadedItem } from '../../../../../stores/UploadFilesStore/interfaces';
import { getPolicyAggregate } from '../../../../utils';
import styles from './FilePolicySelector.module.scss';

const nameSpace = 'uploadFiles';
const errorsNameSpace = `${nameSpace}.messages.error`;

interface FilePanelProps {
    isSetForAllDisabled: boolean;
    isSetForAllVisible: boolean;
    chosenFile: UploadedItem;
    isWorkspace?: boolean;
    contentModulesClassName?: string;
}

// TODO: Probably it will be better to split this component and just reuse parts
const FilePolicySelector: FC<FilePanelProps> = observer(({
    isSetForAllDisabled,
    isSetForAllVisible,
    chosenFile,
    isWorkspace = false,
    contentModulesClassName,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const { uploadFilesStore, policyStore: { policyList }, workspacesStore } = useStores();

    const { t } = useTranslation();

    const getNewPolicy = (policyId: string): PolicySimpleWithMFA => {
        const newPolicy = policyList.find(({ id }) => id === policyId);
        return { id: newPolicy.id, title: newPolicy.title, isMfa: newPolicy.isMfa };
    };

    const {
        filename, policy, fid, hasPolicyError, isFolder,
    } = chosenFile;
    const selectedPolicy = policyList.find((policyItem) => policy?.id === policyItem.id);

    const onBulkChangePolicy = async (): Promise<void> => {
        const newPolicy = getNewPolicy(policy?.id);
        if (newPolicy) {
            await uploadFilesStore.bulkChangePolicy(newPolicy, false);
        }
    };

    const onChangePolicy = useCallback(async (newPolicyId: string): Promise<void> => {
        const newPolicy = getNewPolicy(newPolicyId);
        if (newPolicy) {
            if (isWorkspace) {
                await workspacesStore.changePolicy(fid, newPolicy);
            } else {
                await uploadFilesStore.changePolicy({
                    newPolicy,
                    fileId: fid,
                    isFolder,
                    isExternalStorage: false,
                });
            }
        }
    }, [fid, isFolder, isWorkspace]);

    return (
        <div className={styles.container}>
            {isWorkspace && (
                <div className={styles['workspace-collapse']}>
                    <div className={styles['workspace-title']} onClick={() => setCollapsed(!collapsed)}>
                        Choose policy
                        {collapsed ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    </div>
                    <div className={styles['workspace-warning']}>
                        <ExclamationCircleOutlined />
                        The selected policy will be applied to the items uploaded by the recipient
                    </div>
                </div>
            )}
            {!collapsed && (
                <div className={classNames({ [contentModulesClassName]: !!contentModulesClassName })}>
                    <div className={styles['file-info-wrapper']}>
                        <div className={styles['top-row']}>
                            <span className={styles['file-name']} title={filename}>
                                {filename}
                            </span>
                        </div>
                        <div className={styles['bottom-row']}>
                            {hasPolicyError && (
                                <ErrorTooltip
                                    message={t((`${errorsNameSpace}.${hasPolicyError}`))}
                                />
                            )}
                            <PolicySelect
                                onChange={onChangePolicy}
                                selectedPolicyId={policy?.id}
                                className={classNames(
                                    styles['policy-select'],
                                    { [styles['has-extra-content']]: isSetForAllVisible },
                                )}
                                data-testid="uploadDrawer_policyDropdown"
                            />
                            {isSetForAllVisible && (
                                <Button
                                    className={styles['set-for-all']}
                                    type="link"
                                    disabled={isSetForAllDisabled}
                                    onClick={onBulkChangePolicy}
                                >
                                    <FileProtectOutlined className={styles['set-all-icon']} />
                                    <span>
                                        {t(`${nameSpace}.buttons.setForAll`)}
                                    </span>
                                </Button>
                            )}
                        </div>
                    </div>
                    {selectedPolicy && <PolicyDescription policyAggregate={getPolicyAggregate(selectedPolicy)} />}
                </div>
            )}
        </div>
    );
});

export default FilePolicySelector;
