import { FileAccessError } from '@/types/types';
import { OpenOptions } from '@/config/openOptions';
import {
    AccessType,
    checkIsMFAErrorType,
    FullAccessState,
} from '@/stores';

const MFA_OPERATIONAL_ERRORS: FileAccessError[] = ['MFAFailed', 'MFALimitReached'];

export const checkIsNonMFAOperationalError = (errorType: FileAccessError): boolean => (
    !MFA_OPERATIONAL_ERRORS.includes(errorType)
);

export type MainContentType = 'message' | 'OTP' | 'fileView';

interface ContentTypeSensitiveParams {
    showOTP: boolean;
    isAuthorized: boolean;
    selectedFileId: string;
}

export const getContentType = ({
    isAuthorized,
    selectedFileId,
    showOTP,
}: ContentTypeSensitiveParams): MainContentType => {
    if (showOTP) {
        return 'OTP';
    }
    if (isAuthorized && selectedFileId) {
        return 'fileView';
    }
    return 'message';
};

export const checkIsLastOpenWithViewerFailed = (
    lastAccessType: AccessType,
    accessTypesState: FullAccessState,
): boolean => {
    const openViewerError = accessTypesState?.[OpenOptions.viewer]?.errorType;
    return lastAccessType === OpenOptions.viewer
        && openViewerError
        && !checkIsMFAErrorType(openViewerError);
};
