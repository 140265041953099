import React, { FC, memo } from 'react';

import { CheckCircleOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './StatusIcon.module.scss';

export type GenericStatus = 'loading' | 'done' | 'error';

interface StatusIconProps {
    status: GenericStatus;
    className?: string;
}

const StatusIcon: FC<StatusIconProps> = ({ status, className }) => {
    switch (status) {
    case 'loading':
        return <LoadingOutlined className={className} />;
    case 'error':
        return <CloseOutlined className={classNames(className, styles.error)} />;
    case 'done':
        return <CheckCircleOutlined className={classNames(className, styles.done)} />;
    default:
        return <></>;
    }
};

export default memo(StatusIcon);
