import { DATA_RETENTION } from '@/consts';
import { PolicySetting } from '../../../types/types';
import { PolicyAggregate } from '../../utils';

const POLICIES_TO_HIDE = ['limitToUsers'];
const PUBLIC_POLICIES_TO_HIDE = [...POLICIES_TO_HIDE, 'autoSharing'];

// eslint-disable-next-line import/prefer-default-export
export const getPoliciesToDisplay = (policyAggregate: PolicyAggregate, isPublic = false): PolicySetting[] => {
    const HIDE_LIST = isPublic ? PUBLIC_POLICIES_TO_HIDE : POLICIES_TO_HIDE;
    return Object.values(policyAggregate)
        .filter((policy) => (
            !HIDE_LIST.includes(policy.id)
            && !HIDE_LIST.includes(policy.dependency)
            && (policy.dependency
                || policy.type === 'switch' || policy.id === DATA_RETENTION)
        )).sort((current, prev) => (current.id > prev.id ? 1 : -1));
};
