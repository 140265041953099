import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd';
import type { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph';

import ElasticFileName from '../../Common/ElasticFileName';
import styles from './TitleWithFilename.module.scss';

const SKELETON_SETTINGS: SkeletonParagraphProps = { rows: 1, width: '50%' };

const nameSpace = 'externalStorage';

interface TitleWithFilenameProps {
    isLoading: boolean;
    filename: string;
}

const TitleWithFilename: FC<TitleWithFilenameProps> = ({ isLoading, filename }) => {
    const showSkeleton = !filename && isLoading;
    const { t } = useTranslation();

    return (
        <>
            {filename && (
                <ElasticFileName
                    textClassname={styles.filename}
                    filename={filename}
                    minWidth="300px"
                    maxWidth="max(70%, 600px)"
                    prefix={<span className={styles['text-extra']}>{t(`${nameSpace}.titlePrefix`)}</span>}
                    suffix={<span className={styles['text-extra']}>{t(`${nameSpace}.titleSuffix`)}</span>}
                />
            )}
            {showSkeleton && <Skeleton title={false} active paragraph={SKELETON_SETTINGS} />}
        </>
    );
};

export default memo(TitleWithFilename);
