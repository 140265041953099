import { makeObservable } from 'mobx';

import { captureErrorForSentry, ObservableTree } from '../../../components/utils';
import { UploadedFolderNode, UploadingFolderPayload, UploadingItem } from '../interfaces';

interface FilesAndFolders {
    readonly filesIds: string[];
    readonly foldersIds: string[];
}

interface ClosestParent {
    readonly closestNode: UploadedFolderNode;
    readonly level: number;
}

const findDirectChildByName = (parent: UploadedFolderNode, childName: string): UploadedFolderNode => (
    parent.children.find(
        (node) => node.type === 'node' && node.value.name === childName,
    ) as UploadedFolderNode
);

const getFolderId = (folderName: string, pathToRoot: string): string => (
    pathToRoot ? `${pathToRoot}/${folderName}` : folderName
);

const createSubTree = (
    startingNode: UploadedFolderNode,
    breadcrumbsToRoot: string[],
    level: number,
): UploadedFolderNode => {
    let currentFolder: UploadedFolderNode = startingNode;
    let pathToRoot: string = breadcrumbsToRoot.slice(0, level).join('/');
    let subTree: UploadedFolderNode;
    breadcrumbsToRoot.slice(level).forEach((name) => {
        const folderId: string = getFolderId(name, pathToRoot);

        const newNode: UploadedFolderNode = {
            key: folderId,
            value: { id: folderId, name },
            payload: { fid: '', status: 'uploading' },
            parentKey: currentFolder.key,
            type: 'node',
        };
        if (!subTree) {
            subTree = newNode;
        } else {
            currentFolder.children = [...(currentFolder.children || []), newNode];
        }
        currentFolder = newNode;
        pathToRoot = folderId;
    });
    return subTree;
};

class FoldersTree extends ObservableTree<UploadingItem, UploadingFolderPayload> {
    readonly id: string;

    constructor(key: string, rootValue: UploadingItem, rootPayload: UploadingFolderPayload, treeId: string) {
        super(key, rootValue, rootPayload);
        this.id = treeId;
        this.setNodeChildren(this.root.key, []);
        makeObservable(this);
    }

    insertFile(
        uid: string,
        name: string,
        parentFolderName: string,
        pathToRoot: string,
    ): string {
        const folderId: string = getFolderId(parentFolderName, pathToRoot);
        const folderNode: UploadedFolderNode = this.find(folderId);
        const fileNode: UploadedFolderNode = {
            value: { id: uid, name },
            type: 'leaf',
            parentKey: folderNode?.key,
            key: getFolderId(name, pathToRoot),
        };
        if (folderNode) {
            this.appendChildrenNodes(folderNode.key, fileNode);
        } else {
            const newNode: UploadedFolderNode = this.insertDeepNode(folderId);
            if (newNode) {
                fileNode.parentKey = newNode.key;
                this.appendChildrenNodes(newNode.key, fileNode);
            } else {
                throw new Error(`Could not insert file ${uid} into folder ${folderId}`);
            }
        }
        return folderId;
    }

    tryInsertFile(
        uid: string,
        name: string,
        parentFolderName: string,
        pathToRoot: string,
    ): string {
        let result = '';
        try {
            result = this.insertFile(uid, name, parentFolderName, pathToRoot);
        } catch (error) {
            console.log('could not insert file', error);
            captureErrorForSentry(error, 'UploadFile.FoldersTree.insertFile');
        }
        return result;
    }

    getPlainChildren(): FilesAndFolders {
        const result: FilesAndFolders = {
            filesIds: [],
            foldersIds: [],
        };
        for (const child of this.traversal()) {
            if (child !== this.root) {
                if (child.type === 'node') {
                    result.foldersIds.push(child.key);
                } else {
                    result.filesIds.push(child.value.id);
                }
            }
        }
        return result;
    }

    private findClosest(breadcrumbsToRoot: string[]): ClosestParent {
        let closestNode = this.root;
        let level = 1;
        for (let index = level; index < breadcrumbsToRoot.length; index++) {
            const folderName = breadcrumbsToRoot[index];
            const childNode = findDirectChildByName(closestNode, folderName);
            if (!childNode) {
                level = index;
                break;
            }
            closestNode = childNode;
        }
        return { closestNode, level };
    }

    private insertDeepNode(folderId: string): UploadedFolderNode {
        const breadcrumbsToRoot = folderId.split('/');
        const { level, closestNode } = this.findClosest(breadcrumbsToRoot);
        const subTree = createSubTree(closestNode, breadcrumbsToRoot, level);
        this.appendChildrenNodes(closestNode.key, subTree);
        return this.find(folderId);
    }
}

export default FoldersTree;
