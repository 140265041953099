import isEqual from 'lodash/isEqual';
import zip from 'lodash/zip';

import { RecipientPhoneInfo, SharedUser } from '../../../types/types';
import {
    compareActions,
    displayUserToBE,
    roleToPrimaryData,
    SharedUserToDisplay,
} from '../../../stores';

export const filterDeletedUsers = (
    changedUsers: SharedUserChanges[],
    users: SharedUserToDisplay[],
): SharedUserToDisplay[] => {
    const deletedUserIds = new Set<string>(changedUsers.map((item) => item.deleted && item.userId));
    return users.filter(({ userId }) => !deletedUserIds.has(userId));
};

export type SharedUserChanges = Required<Pick<SharedUser, 'userId' | 'email'>>
    & Partial<Pick<SharedUser, 'readOnly' | 'actions' | 'deleted'>>;

const getPrimaryDataChanges = (
    newUser: SharedUserToDisplay,
    originalUser: SharedUserToDisplay,
): SharedUserChanges => {
    const newUserPrimaryData = roleToPrimaryData(newUser.recipientRole);
    const originalUserPrimaryData = roleToPrimaryData(originalUser.recipientRole);
    const userChanges: SharedUserChanges = { userId: newUser.userId, email: newUser.email };
    if (newUserPrimaryData.readOnly !== originalUserPrimaryData.readOnly) {
        userChanges.readOnly = newUserPrimaryData.readOnly;
    }
    if (!compareActions(newUserPrimaryData.actions, originalUserPrimaryData.actions)) {
        userChanges.actions = newUserPrimaryData.actions;
    }
    return userChanges;
};

export const getChangedUsers = (
    newUsers: SharedUserToDisplay[],
    originalUsers: SharedUserToDisplay[],
): SharedUserChanges[] => (
    (zip(newUsers, originalUsers) as [SharedUserToDisplay, SharedUserToDisplay][])
        .reduce<SharedUserChanges[]>((changes, [newUser, originalUser]) => {
            if (newUser.deleted) {
                changes.push({ userId: newUser.userId, deleted: true, email: newUser.email });
            } else if (newUser.recipientRole !== originalUser.recipientRole) {
                changes.push(getPrimaryDataChanges(newUser, originalUser));
            }
            return changes;
        }, [])
);

export const prepareNewUsersList = (
    changedUsers: SharedUserChanges[],
    users: SharedUserToDisplay[],
): SharedUser[] => (
    filterDeletedUsers(changedUsers, users)
        .map<SharedUser>(displayUserToBE)
);

export const checkPhonesChanges = (
    originalList: SharedUserToDisplay[],
    phonesMap: Map<string, RecipientPhoneInfo>,
): boolean => (
    originalList.some(({ email }) => {
        const phoneInfo = phonesMap.get(email);
        if (phoneInfo?.phonesVector) {
            const { currentPhone, initSnapshot } = phoneInfo.phonesVector;
            return !isEqual(currentPhone, initSnapshot);
        }
        return false;
    })
);
