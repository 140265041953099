import { useContext } from 'react';

import { MobXProviderContext } from 'mobx-react';

import { StoreOverrideContext } from '../Context';
import useInstance from './useInstance';
import { AllStores } from '../../stores';

export const useGlobalStores = (): AllStores => useContext(MobXProviderContext) as AllStores;

export const useStores = (): AllStores => {
    const globalStores = useGlobalStores();

    const storesOverride = useContext<Partial<AllStores>>(StoreOverrideContext);

    return { ...globalStores, ...storesOverride };
};

type SupportedStores = Array<keyof Pick<AllStores, 'sharedUsersStore'>>;

// TODO: create factory for every store
const storesFactory = (keys: SupportedStores, stores: AllStores): Partial<AllStores> => (
    keys.reduce<Partial<AllStores>>((acc, key) => {
        if (key === 'sharedUsersStore') {
            acc.sharedUsersStore = stores[key].createNew();
        }
        return acc;
    }, {})
);

export const useInjectStores = (keys: SupportedStores): Partial<AllStores> => {
    const stores = useGlobalStores();

    return useInstance<Partial<AllStores>>(() => storesFactory(keys, stores));
};
