import { FileDetailsPublic, Policy } from '../../../../types/types';
import { BASEURL, ENDPOINTS, SPXAPI } from '../../../../api';
import { captureErrorForSentry } from '../../../utils';

const tryFetchActualPolicy = async (API: SPXAPI, fileId: string, policyId: string | null): Promise<Policy> => {
    let filePolicy: Policy = null;
    let filePolicyId: string = policyId;
    try {
        if (!filePolicyId) {
            const file: FileDetailsPublic = await API.get(
                BASEURL.backend(),
                ENDPOINTS.getFileDetailsPublic(fileId),
                {},
            );
            filePolicyId = file.policy_id;
        }
        filePolicy = await API.get(BASEURL.backend(), ENDPOINTS.policy(filePolicyId), {});
    } catch (error) {
        console.log('could not load file policy', error);
        captureErrorForSentry(error, 'SharedFile.tryFetchActualPolicy');
    }
    return filePolicy;
};

export default tryFetchActualPolicy;
