import { message } from 'antd';

import i18n from '@/content';
import { captureWarningForSentry } from './sentryAdapter';

const nameSpace = 'copyItemToClipboard';

const tryCopyToClipBoard = async (text: string, item: string): Promise<void> => {
    try {
        await navigator.clipboard.writeText(text);
        message.success(i18n.t(`${nameSpace}.success`, { item }));
    } catch (error) {
        console.log('Navigator clipboard error:', error);
        captureWarningForSentry(error, 'utils.tryCopyToClipBoard');
        message.error(i18n.t(`${nameSpace}.error`));
    }
};

export default tryCopyToClipBoard;
