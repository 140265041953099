import React, { FC, memo } from 'react';

import { Alert } from 'antd';

import i18n from '@/content';
import styles from './AttemptsRemainWarning.module.scss';

interface AttemptsRemainWarningProps {
    attemptsCounter: number;
}

const AttemptsRemainWarning: FC<AttemptsRemainWarningProps> = ({ attemptsCounter }) => (
    <div className={styles['warning-wrapper']}>
        <Alert message={i18n.t('viewer.warning.mfaAttemptsRemain', { attemptsCounter })} type="warning" />
    </div>
);

export default memo(AttemptsRemainWarning);
