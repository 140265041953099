import React, { FC } from 'react';

import i18n from '@/content';
import { nameSpace, SUBTITLE_STYLE } from './constants';
import styles from './TopTabLink.module.scss';

const TopTabLink: FC = () => (
    <a
        className={styles['button-link']}
        href={window.location.href}
        target="_top"
    >
        <span style={SUBTITLE_STYLE}>
            {i18n.t(`${nameSpace}.clickToContinue`)}
        </span>
    </a>
);

export default TopTabLink;
