import React, { FC, memo } from 'react';

import PolicyDescription from '../../../Common/PolicyDescription';
import PolicySelect from '../../../Common/PolicySelect';
import { Policy, SecuredFile } from '@/types/types';
import { getPolicyAggregate, PolicyAggregate } from '../../../utils';
import styles from './SetPolicyComponent.module.scss';

interface SetPolicyProps {
    file: SecuredFile;
    hasPolicies: boolean;
    selectedPolicy: Policy;
    changePolicy: (policyId: string) => Promise<void> | void;
}

const SetPolicyComponent: FC<SetPolicyProps> = ({
    file,
    hasPolicies,
    selectedPolicy,
    changePolicy,
}) => {
    const policyAggregate: PolicyAggregate = selectedPolicy ? getPolicyAggregate(selectedPolicy) : {};

    return (
        <div className={styles['set-policy-container']}>
            {(selectedPolicy && hasPolicies) && file && (
                <PolicySelect
                    className={styles['policy-select']}
                    selectedPolicyId={selectedPolicy.id}
                    onChange={changePolicy}
                />
            )}
            <div className={styles['description-wrapper']}>
                {selectedPolicy && hasPolicies
                && <PolicyDescription policyAggregate={policyAggregate} />}
            </div>
        </div>
    );
};

export default memo(SetPolicyComponent);
