import React, {
    CSSProperties,
    FC,
    ReactElement,
    useMemo,
} from 'react';

import {
    DndContext,
    DragOverlay,
    Modifier,
    pointerWithin,
} from '@dnd-kit/core';
import { observer } from 'mobx-react';

import DragView from './DragView';
import FilesDragContext from './context';
import { useStores } from '../../hooks';
import { useFilesMoving, useDndSensors } from './hooks';
import snapTopLeftCornerToCursor from './DragView/snapTopLeftCornerToCursor';

const DRAG_OVERLAY_MODIFIERS: Modifier[] = [snapTopLeftCornerToCursor];
/**
 * This property allow mouse wheel scroll while dragging
 * */
const DRAG_OVERLAY_STYLE: CSSProperties = { pointerEvents: 'none' };

interface FilesDragProviderProps {
    children: ReactElement | ReactElement[];
}

export const FilesDragProvider: FC<FilesDragProviderProps> = observer(({ children }) => {
    const { filesListStore } = useStores();
    const {
        moveCurrentFolderFiles,
        selectedItemsKeys,
    } = filesListStore;

    const selectedKeysSet = useMemo<ReadonlySet<string>>(
        () => new Set<string>(selectedItemsKeys),
        [selectedItemsKeys],
    );

    const {
        activeDraggedItem,
        destroyDragState,
        onDragEnd,
        onDragStart,
    } = useFilesMoving({
        moveFiles: moveCurrentFolderFiles,
        selectedKeysSet,
        selectedRowKeys: selectedItemsKeys,
    });

    const dndSensors = useDndSensors();

    return (
        <FilesDragContext.Provider value={activeDraggedItem}>
            <DndContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragCancel={destroyDragState}
                sensors={dndSensors}
                collisionDetection={pointerWithin}
            >
                {children}
                <DragOverlay
                    style={DRAG_OVERLAY_STYLE}
                    modifiers={DRAG_OVERLAY_MODIFIERS}
                >
                    {activeDraggedItem && (
                        <DragView
                            selectedFilesSet={selectedKeysSet}
                            activeItem={activeDraggedItem}
                        />
                    )}
                </DragOverlay>
            </DndContext>
        </FilesDragContext.Provider>
    );
});

export default FilesDragProvider;
