import { useCallback, useEffect, useRef } from 'react';

import {
    SharedFile, FilesAccessStore, PermissionsState, SharedFilesStore,
} from '../../../../../../../stores';
import { OpenOptions } from '../../../../../../../config/openOptions';
import { SimpleCallback } from '../../../../../../../types/types';
import { SelectedFileViewState } from './interfaces';
import { ERRORS_FOR_NEW_ATTEMPTS, getSelectedFileViewState } from './helpers';

export const useSignatureSaving = (filesAccessStore: FilesAccessStore): SimpleCallback => {
    const hasChangesRef = useRef<boolean>(false);

    const onSaveSignature = useCallback(() => {
        hasChangesRef.current = true;
    }, []);

    useEffect(() => {
        const fileId = filesAccessStore.selectedAccessFileId;
        return (): void => {
            if (hasChangesRef.current) {
                filesAccessStore.deleteAccessItem(fileId);
            }
        };
    }, []);

    return onSaveSignature;
};

interface UseOpenFileArgs {
    filesAccessStore: FilesAccessStore;
    sharedFilesStore: SharedFilesStore;
    selectedFilePermissions: PermissionsState;
    selectedFile: SharedFile;
}

export const useViewerState = ({
    filesAccessStore,
    sharedFilesStore,
    selectedFilePermissions,
    selectedFile,
}: UseOpenFileArgs): SelectedFileViewState => {
    const {
        selectedItemState,
        selectedAccessFileId,
    } = filesAccessStore;

    const {
        contentType,
        selectedFilePermissionsSummary,
        viewerState,
        errorType,
    } = getSelectedFileViewState(selectedFilePermissions, selectedFile, selectedItemState);

    useEffect(() => {
        if (selectedFilePermissionsSummary === 'permissionsAdded') {
            const shouldOpen = !(selectedFile?.is_folder)
                && selectedFilePermissions?.summary === 'permissionsAdded'
                && (
                    !viewerState
                    || ERRORS_FOR_NEW_ATTEMPTS.includes(viewerState.errorType)
                );
            sharedFilesStore.tryRefreshPermissions(selectedAccessFileId);
            if (shouldOpen) {
                filesAccessStore.tryFetchAccessLink({
                    fileId: selectedAccessFileId,
                    accessType: OpenOptions.viewer,
                });
            }
        }
    }, [selectedFilePermissionsSummary, selectedAccessFileId]);

    return {
        contentType,
        errorType,
        viewerState,
    };
};
