import React, { ReactElement } from 'react';

import {
    AuditOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SendOutlined,
} from '@ant-design/icons';

import { FileOptionType } from '../../../../../types/types';

export type FileActionType = FileOptionType | 'share' | 'download';

interface ActionButtonProps {
    type: FileActionType;
    icon: ReactElement;
}

export const ACTIONS: ActionButtonProps[] = [
    {
        type: 'share',
        icon: <SendOutlined />,
    },
    {
        type: 'download',
        icon: <DownloadOutlined />,
    },
    {
        type: 'audits',
        icon: <AuditOutlined />,
    },
    {
        type: 'delete',
        icon: <DeleteOutlined />,
    },
];
