import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import {
    Event as SentryEvent,
    EventHint,
    init,
} from '@sentry/react';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';

import { AppStore, AuthSettingsStore, UserStore } from '@/stores';
import { captureErrorForSentry } from '../utils';
import { IS_LOCAL_ENV } from '@/config/env';

import FallbackUI from './FallbackUI';

const DENY_URLS = [
    /dev-app\.specterx\.com/i,
    /staging-app\.specterx\.com/i,
];

const DEBOUNCE_TIMEOUT_MS = 100;

const tryShowDebugOverlay = debounce((error: Error): void => {
    if (IS_LOCAL_ENV) {
        const ErrorOverlay = customElements.get('vite-error-overlay');
        if (ErrorOverlay) {
            document.body.appendChild(new ErrorOverlay(error));
        }
    }
}, DEBOUNCE_TIMEOUT_MS);

interface ErrorsBoundaryProps {
    appStore?: AppStore;
    authSettingsStore?: AuthSettingsStore;
    userStore?: UserStore;
    // children: ReactElement | ReactElement[];
    children: ReactNode;
}

interface ErrorsBoundaryPropsState {
    hasError: boolean;
}

@inject('appStore', 'userStore', 'authSettingsStore')
@observer
class ErrorsBoundary extends Component<ErrorsBoundaryProps, ErrorsBoundaryPropsState> {
    private sentryEnabled: boolean;

    constructor(props: ErrorsBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorsBoundaryPropsState {
        return { hasError: true };
    }

    componentDidMount(): void {
        const { authSettingsStore } = this.props;
        if (!authSettingsStore.HAS_API_PROXY && !IS_LOCAL_ENV) {
            this.initSentry();
        }

        window.addEventListener('unhandledrejection', (event) => {
            const { reason } = event;
            captureErrorForSentry(reason, 'ErrorsBoundary.unhandledrejection');
            event.preventDefault();
        });
        window.addEventListener('error', (event) => {
            const { error } = event;
            tryShowDebugOverlay(error);
        });

        reaction<boolean>(
            () => authSettingsStore.HAS_API_PROXY,
            (hasAPIProxy) => {
                const shouldEnable = !hasAPIProxy && !IS_LOCAL_ENV && !this.sentryEnabled;
                if (shouldEnable) {
                    this.initSentry();
                }
            },
        );
    }

    componentDidCatch(error: Error): void {
        const { appStore } = this.props;
        const { errorStatus, setErrorStatus } = appStore;
        console.log('sentry catch error', error);

        if (!errorStatus.hasError) {
            captureErrorForSentry(error, 'ErrorsBoundary.componentDidCatch');
            setErrorStatus(true, 'application');
            tryShowDebugOverlay(error);
        }
    }

    beforeSendSentry = (event: SentryEvent, hint: EventHint): SentryEvent => {
        if ((hint.originalException as AxiosError)?.config) {
            const { config } = hint.originalException as AxiosError;
            const date = new Date();
            const timestamp = date.getTime();
            if (config.data) {
                event.breadcrumbs.push({
                    timestamp,
                    category: 'Request Data',
                    data: config.data,
                    level: 'info',
                });
            }

            event.breadcrumbs.push({
                timestamp,
                category: 'Path',
                message: config.url,
                level: 'info',
            });
        }

        const exception = hint.originalException;
        const { userStore: { userInformation } } = this.props;

        if (exception) {
            // eslint-disable-next-line no-param-reassign
            event.fingerprint = [
                '{{ default }}',
                userInformation.displayName,
                (exception as Error)?.message,
                hint.event_id,
            ];
        }

        return event;
    }

    private initSentry(): void {
        init({
            environment: import.meta.env.VITE_STAGE,
            dsn: 'https://105096a748f54f0ab4f29164c83d234d@sentry.io/1840560',
            attachStacktrace: true,
            release: 'specterx@0.2.0',
            beforeSend: this.beforeSendSentry,
            denyUrls: DENY_URLS,
            // by default this setting is true. It should be used for checking crash-fress session and users
            autoSessionTracking: true,
            // Previously we have BrowserTracing integration. It useful for bind session and transactions with BE
            beforeBreadcrumb: (breadcrumb, hint) => {
                if (breadcrumb.category === 'xhr') {
                    /* eslint-disable no-param-reassign */
                    breadcrumb.data.url = breadcrumb.data.url.replace('auth', 'a*th');
                    // hint.xhr is a whole XHR object that you can use to modify breadcrumb
                    breadcrumb.data.responseText = (hint.xhr as XMLHttpRequest).response;
                    /* eslint-enable no-param-reassign */
                }

                return breadcrumb;
            },
            sampleRate: 0.5,
        });
        this.sentryEnabled = true;
    }

    render(): JSX.Element | ReactNode {
        const { children } = this.props;
        const { hasError } = this.state;

        return hasError ? <FallbackUI /> : children;
    }
}

export default ErrorsBoundary;
