import { useContext, useState } from 'react';

import i18next from 'i18next';

import { PageLanguageContext } from '../../PageLanguageContextProvider';
import { LANGUAGES } from '../../../../content/languages';

interface PageLanguageState {
    isLanguageDefined: boolean;
    defineLanguage: (language?: LANGUAGES) => void;
}

const useLanguageDefinition = (): PageLanguageState => {
    const { changeLanguage, checkIsLanguageChosen } = useContext(PageLanguageContext);
    const [isLanguageDefined, setIsLanguageDefined] = useState<boolean>(checkIsLanguageChosen);

    const defineLanguage = (language?: LANGUAGES): void => {
        if (!checkIsLanguageChosen() && language && i18next.language !== language) {
            changeLanguage(language);
        }
        setIsLanguageDefined(true);
    };

    return {
        isLanguageDefined,
        defineLanguage,
    };
};

export default useLanguageDefinition;
