import React, { FC, memo } from 'react';

import { Modal } from 'antd';

import i18n from '@/content';

export { default as useDiscardModal } from './useDiscardModal';

interface DiscardNewPolicyProps {
    isVisible: boolean;
    onFinish: (isConfirmed: boolean) => void;
}

const nameSpace = 'policyEditor.discardModal';

const DiscardNewPolicyModal: FC<DiscardNewPolicyProps> = ({ isVisible, onFinish }) => (
    <Modal
        transitionName=""
        maskTransitionName=""
        visible={isVisible}
        title={i18n.t(`${nameSpace}.title`)}
        onOk={() => onFinish(true)}
        onCancel={() => onFinish(false)}
        okText={i18n.t('general.buttons.ok')}
        cancelText={i18n.t('general.buttons.cancel')}
    >
        {i18n.t(`${nameSpace}.content`)}
    </Modal>
);

export default memo(DiscardNewPolicyModal);
