import React, { FC } from 'react';

import FolderBreadcrumbs from './FolderBreadcrumbs';
import styles from './MyFilesHeader.module.scss';
import ExtraContent from './ExtraContent';

const MyFilesHeader: FC = () => (
    <div className={styles['my-files-header']}>
        <FolderBreadcrumbs />
        <ExtraContent />
    </div>
);

export default MyFilesHeader;
