import React, { FC, memo } from 'react';

import classNames from 'classnames';

import styles from './DotsLoading.module.scss';

interface DotsLoadingProps {
    className?: string;
}

const DotsLoading: FC<DotsLoadingProps> = ({ className }) => {
    const classes = classNames(styles['dots-loading'], { [className]: !!className });

    return (
        <span className={classes}>
            <span className={styles['central-dot']}>.</span>
        </span>
    );
};

export default memo(DotsLoading);
