import React, { FC, memo } from 'react';

import Highlighter from 'react-highlight-words';

const HIGHLIGHT_STYLE = { backgroundColor: '#859fdd', padding: 0 };

interface EmailProps {
    email: string;
    searchText: string;
}

const Email: FC<EmailProps> = ({ email, searchText }) => (
    searchText
        ? (
            <Highlighter
                highlightStyle={HIGHLIGHT_STYLE}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={email}
            />
        )
        : <span style={{ whiteSpace: 'nowrap' }}>{email}</span>
);

export default memo(Email);
