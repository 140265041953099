import React, {
    FC,
} from 'react';

import { observer } from 'mobx-react';

import { Avatar, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './GroupMemberLine.module.scss';

interface GroupMemberLineProps {
    email: string;
    displayName?: string;
    userId: string;
    onClear: (val: string) => void;
}

const GroupMemberLine: FC<GroupMemberLineProps> = observer(({
    email,
    displayName,
    onClear,
    userId,
}) => {
    const firstLetters = displayName
        ? displayName.split(' ').map((item) => item[0]).join('')
        : email?.[0]?.toUpperCase();

    return (
        <div className={styles['group-member-line']}>
            <Avatar className={styles.avatar} size={32} gap={7}>
                {firstLetters}
            </Avatar>
            <div className={styles['name-container']}>
                <div className={styles.name}>{displayName}</div>
                <div>{email}</div>
            </div>
            <Button
                onClick={() => onClear(userId)}
                className={styles['delete-button']}
                type="link"
            >
                <DeleteOutlined />
            </Button>
        </div>
    );
});

export default GroupMemberLine;
