import React, { FC, memo } from 'react';

import SkeletonItem from '../../SkeletonItem';
import { arrayOf } from '../../../../utils';
import styles from './ActionsSkeleton.module.scss';

const ACTIONS_COUNT = 3;

const ActionsSkeleton: FC = () => (
    <div className={styles['skeleton-wrapper']}>
        {arrayOf(ACTIONS_COUNT).map((num) => <SkeletonItem size="sm" key={num} />)}
    </div>
);

export default memo(ActionsSkeleton);
