import React, { SyntheticEvent, Component } from 'react';

import classNames from 'classnames';

import { PasswordVisibilityToggle, SpInput } from '@/components/Common/Sp';
import i18n from '@/content';
import styles from './PasswordValidation.module.scss';

type ConditionTypes = 'length' | 'numbers' | 'uppercase' | 'lowercase' | 'chars';

const MAP_CONDITION_TO_REGEXP: Record<ConditionTypes, string> = {
    length: '(?=.{8,})',
    numbers: '(?=.*[0-9])',
    uppercase: '(?=.*[A-Z])',
    lowercase: '(?=.*[a-z])',
    chars: '(?=.*[!@#$%^&*()_~-])',
};

export interface PasswordValidationProps {
    updateInput: (password: string, isValid: boolean) => void;
    password: string;
}

export interface PasswordValidationState {
    showPass: boolean;
}

class PasswordValidation extends Component<PasswordValidationProps, PasswordValidationState> {
    private readonly nameSpace = 'general.passwordValidation';

    constructor(props: PasswordValidationProps) {
        super(props);
        this.state = { showPass: false };
    }

    onPasswordChange = (password: string): void => {
        this.validatePasswordConditions(password);
    }

    showHide = (event: SyntheticEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        this.setState(({ showPass }) => ({ showPass: !showPass }));
    }

    validatePasswordConditions = (password: string): void => {
        const { updateInput } = this.props;
        const isPasswordValid = Object.values(MAP_CONDITION_TO_REGEXP)
            .every((regex) => new RegExp(regex).test(password));
        updateInput(password, isPasswordValid);
    }

    render(): JSX.Element {
        const { showPass } = this.state;
        const { password } = this.props;
        const inputType = showPass ? 'text' : 'password';
        const { nameSpace } = this;

        return (
            <div className={styles['password-check']}>
                <div className={styles['input-show-wrapper']}>
                    <SpInput
                        type={inputType}
                        value={password}
                        placeholder={i18n.t(`${nameSpace}.placeholder`)}
                        onChange={this.onPasswordChange}
                        required
                        autoComplete="new-password"
                    />
                    <PasswordVisibilityToggle className={styles.show} toggleVisibility={this.showHide} />
                </div>
                <ul className={styles['password-strength']}>
                    {Object.entries(MAP_CONDITION_TO_REGEXP).map(([key, regex]) => (
                        <li
                            key={key}
                            className={classNames({
                                [styles['password-strength-good']]: new RegExp(regex).test(password),
                            })}
                        >
                            {i18n.t([`${nameSpace}.${key}`])}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default PasswordValidation;
