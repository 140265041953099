import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './Input.module.scss';

interface SpInputProps {
    value: string;
    onChange: (value: string) => void | Promise<void>;
    className?: string;
    autoComplete?: string;
    placeholder?: string;
    type?: string;
    required?: boolean;
    disabled?: boolean;
}

const SpInput: FC<SpInputProps> = ({
    value,
    onChange,
    className = '',
    type = 'text',
    placeholder = '',
    autoComplete = '',
    required = false,
    disabled = false,
}) => {
    const wrapperClasses = classNames(styles['input-container'], { [className]: !!className });

    return (
        <div className={wrapperClasses}>
            <input
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                type={type}
                required={required}
                onChange={(e) => onChange(e.target.value)}
                autoComplete={autoComplete}
            />
            <span className={styles.hide}>Error message</span>
        </div>
    );
};

export default SpInput;
