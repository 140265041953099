import { PermissionsErrors, PermissionsSummary } from '../interfaces';
import { isUnionType } from '../../../../components/utils';

const PERMISSIONS_CHECK_FAIL_STATES: PermissionsErrors[] = ['permissionCheckFail', 'noPermissions'];

const checkIsPermissionsError = (summary: PermissionsSummary): summary is PermissionsErrors => (
    isUnionType<PermissionsErrors>(summary, PERMISSIONS_CHECK_FAIL_STATES)
);

export default checkIsPermissionsError;
