import {
    CSSProperties,
    RefObject,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import useResizeObserver from '@react-hook/resize-observer';

interface Size {
    width: number;
    height: number;
}

export interface UseSizeResult<T extends HTMLElement> {
    size: Size;
    containerRef: RefObject<T>;
}

const SIZE_INIT: Size = { width: 0, height: 0 };

export const useSize = <T extends HTMLElement>(): UseSizeResult<T> => {
    const [size, setSize] = useState<Size>(SIZE_INIT);

    const containerRef = useRef<T>(null);

    useLayoutEffect(() => {
        // TODO: add mutation observer to set initial size
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            setSize({ width, height });
        }
    }, [containerRef.current]);

    useResizeObserver(
        containerRef,
        (entry) => {
            const { width, height } = entry.contentRect;
            setSize({ width, height });
        },
    );

    return { size, containerRef };
};

interface UsePxSizeResult<T extends HTMLElement> extends Pick<UseSizeResult<T>, 'containerRef'> {
    size: Pick<CSSProperties, 'width' | 'height'>;
}

export const usePxSize = <T extends HTMLElement>(): UsePxSizeResult<T> => {
    const { containerRef, size } = useSize<T>();
    return {
        containerRef,
        size: {
            width: `${size.width}px`,
            height: `${size.height}px`,
        },
    };
};
