import { SavedSearch } from '../interfaces';

export const saveCurrentSearchParams = (searchParams: string): void => {
    const dataToStorage: SavedSearch = {
        timestamp: Date.now(),
        search: searchParams,
    };
    sessionStorage.setItem('searchParams', JSON.stringify(dataToStorage));
};

export const extractTimelySearchParams = (validationTime: number): string | null => {
    const savedSearch: SavedSearch = JSON.parse(sessionStorage.getItem('searchParams'));
    sessionStorage.removeItem('searchParams');
    if (savedSearch?.timestamp + validationTime > Date.now()) {
        return savedSearch.search;
    }
    return null;
};
