import {
    FC, useState,
} from 'react';

import { observer } from 'mobx-react';

import { t } from 'i18next';

import { Card, Collapse } from 'antd';

import { useStores } from '@/components/hooks';

import SharedBlock from './SharedBlock/SharedBlock';

import FilePanelContent from '@/components/ExternalStorage/FilesPolicyList/FilePanelContent';
import { PolicySimpleWithMFA, RemoveResult } from '@/stores/UploadFilesStore/interfaces';
import { handleUploadedFileRemovingResult } from '@/stores';
import PolicyDescription from '@/components/Common/PolicyDescription';
import { Policy } from '@/types/types';
import { getPolicyAggregate } from '@/components/utils';

import './antOverride.scss';
import styles from './FilesList.module.scss';
import Spinner from '@/components/Common/Spin';

const { Panel } = Collapse;

interface FilesListProps {
    success: boolean;
}

const FilesList: FC<FilesListProps> = ({ success }) => {
    const [policyChanging, setPolicyChanging] = useState<boolean>(false);
    const {
        uploadFilesStore: {
            uploadedFilesSummary,
            successfullyUploadedItems,
            googleDriveChangePolicy,
            removeUploadingItem,
        },
        policyStore: { policyList },
        sharedUsersStore: { recipientsList },
    } = useStores();

    const removeFileItem = async (fileId: string): Promise<void> => {
        const item = successfullyUploadedItems.find(({ fid }) => fid === fileId);
        if (item) {
            const { filename } = item;
            const result: RemoveResult = await removeUploadingItem(item);
            handleUploadedFileRemovingResult(t, filename || fileId, result);
        }
    };

    const getNewPolicy = (policyId: string): PolicySimpleWithMFA => {
        const newPolicy = policyList.find(({ id }) => id === policyId);
        return { id: newPolicy.id, title: newPolicy.title, isMfa: newPolicy.isMfa };
    };

    const changeFilePolicy = async (newPolicyId: string, fileId: string, isFolder: boolean): Promise<void> => {
        const newPolicy = getNewPolicy(newPolicyId);
        googleDriveChangePolicy(isFolder, fileId, newPolicy);
        setPolicyChanging(false);
    };

    const bulkChangeFilePolicy = (newPolicyId: string): void => {
        const newPolicy = getNewPolicy(newPolicyId);
        setPolicyChanging(true);
        const itemsForUpdate = successfullyUploadedItems
            .filter(({ policy }) => policy && policy.id !== newPolicy.id);
        itemsForUpdate.forEach(({ fid, isFolder = false }) => googleDriveChangePolicy(isFolder, fid, newPolicy));
        setPolicyChanging(false);
    };

    if (success) {
        return <SharedBlock uploadedFilesSummary={uploadedFilesSummary} recipientsList={recipientsList} />;
    }

    return (
        <Spinner spinning={policyChanging}>
            <div className={styles['files-list-title']}>
                <span>Set files policy</span>
            </div>
            <div
                className={styles['virtualized-files-list-wrapper']}
                style={{ height: '500px' }}
            >
                {successfullyUploadedItems.length > 0 && (
                    <div>
                        {successfullyUploadedItems?.map((item) => {
                            const selectedPolicy = policyList.find((policyItem) => item.policy.id === policyItem.id);
                            return (
                                <Card bordered={false} className={styles['file-card']} key={item.fid}>
                                    <Panel
                                        key={item.fid}
                                        className="panel"
                                        header={(
                                            <FilePanelContent
                                                isExternalStorage
                                                isSetForAllDisabled={false}
                                                isSetForAllVisible
                                                isFolder={item.isFolder || false}
                                                fid={item.fid}
                                                filename={item.filename || item.fid}
                                                hasPolicyError={item.hasPolicyError}
                                                policyId={item.policy?.id}
                                                removeFile={removeFileItem}
                                                bulkChangePolicy={bulkChangeFilePolicy}
                                                changePolicy={changeFilePolicy}
                                            />
                                        )}
                                    />
                                    {selectedPolicy && (
                                        <PolicyDescription
                                            policyAggregate={getPolicyAggregate(selectedPolicy as Policy)}
                                        />
                                    )}
                                </Card>
                            );
                        })}
                    </div>
                )}

            </div>
        </Spinner>
    );
};

export default observer(FilesList);
