import { SquashBoolean } from '../../../interfaces';
import {
    ALLOW_FORWARDING_IN_DOMAIN_TEMPLATE,
    ALLOW_FORWARDING_TEMPLATE,
    FORWARDING_DROPDOWN_TEMPLATE,
    FORWARDING_OPTIONS,
    MAP_FORWARDING_DROPDOWN_TO_BOOLEAN_SETTINGS,
} from './forwardingOptions';

export const SQUASH_SETTINGS: Record<string, SquashBoolean> = {
    [FORWARDING_OPTIONS]: {
        from: [ALLOW_FORWARDING_TEMPLATE, ALLOW_FORWARDING_IN_DOMAIN_TEMPLATE],
        to: FORWARDING_DROPDOWN_TEMPLATE,
        rules: MAP_FORWARDING_DROPDOWN_TO_BOOLEAN_SETTINGS,
    },
};

export const MAP_BOOLEAN_SETTING_ID_TO_DROPDOWN_ID: Record<string, string> = Object.values(SQUASH_SETTINGS).reduce(
    (acc, { from, to }) => {
        from.forEach(({ id }) => {
            acc[id] = to.id;
        });
        return acc;
    },
    {},
);
