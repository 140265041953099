import { PolicySetting } from '../../../../types/types';
import { IncompatibleSettings, SettingDescription } from '../../interfaces';
import { INCOMPATIBLE_ACCESS_SETTINGS } from './config';

type SettingsMap = Record<string, SettingDescription>;

export const findIncompatibleSettings = (settingsList: PolicySetting[]): IncompatibleSettings => {
    const settingsMap: SettingsMap = settingsList.reduce<SettingsMap>((acc, { id, value }) => {
        acc[id] = { id, isActive: !!value };
        return acc;
    }, {});
    return INCOMPATIBLE_ACCESS_SETTINGS.find(({ settings }) => (
        settings.every(({ id, isActive }) => isActive === settingsMap[id].isActive)
    ));
};

const findFirstIncompatibleBooleanSettingIndex = (
    settingId: string,
    settingsList: PolicySetting[],
    incompatibleSettings: IncompatibleSettings,
): number => {
    const incompatibleSettingsIdsSet = new Set<string>(incompatibleSettings.settings.map(({ id }) => id));
    return settingsList.findIndex(({ id, value }) => (
        typeof value === 'boolean' && id !== settingId && incompatibleSettingsIdsSet.has(id)
    ));
};

export const tryToggleIncompatibleBooleanSetting = (
    settingId: string,
    settingsList: PolicySetting[],
): PolicySetting[] => {
    const incompatibleSettings: IncompatibleSettings = findIncompatibleSettings(settingsList);
    if (incompatibleSettings) {
        const firstIncompatibleBooleanSettingIndex = findFirstIncompatibleBooleanSettingIndex(
            settingId,
            settingsList,
            incompatibleSettings,
        );
        if (firstIncompatibleBooleanSettingIndex >= 0) {
            const newSettingsList: PolicySetting[] = settingsList.slice();
            const incompatibleBooleanSetting: PolicySetting = newSettingsList[firstIncompatibleBooleanSettingIndex];
            newSettingsList[firstIncompatibleBooleanSettingIndex] = {
                ...incompatibleBooleanSetting,
                value: !incompatibleBooleanSetting.value,
            };
            return newSettingsList;
        }
    }
    return settingsList;
};
