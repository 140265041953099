import type { FC } from 'react';
import { memo } from 'react';

import { TFunction } from 'i18next';

import { Select } from '@/components/Common/UIKit';
import type { DropdownItem } from '@/types/types';
import i18n from '@/content';

import PolicySetting, { PolicyRowProps } from '../PolicySetting';
import styles from './PolicyDropdownRow.module.scss';

export type SupportedDropdownIds = 'language' | 'forwardingOptions';

export const SUPPORTED_IDS_SET: Set<SupportedDropdownIds> = new Set<SupportedDropdownIds>([
    'language', 'forwardingOptions',
]);

const getValuesToDropdown = (values: (string | number)[], settingId: string, t: TFunction): DropdownItem[] => (
    values.map<DropdownItem>((value) => ({ value, label: t(`policy.${settingId}.values.${value}`) }))
);

interface PolicyDropdownRowProps extends PolicyRowProps<string> {
    values: (string | number)[];
}

const PolicyDropdownRow: FC<PolicyDropdownRowProps> = ({
    settingId,
    settingValue,
    isChild,
    values,
    handleChange,
}) => {
    const onChange = (value: string): void => {
        handleChange(settingId, value);
    };

    return (
        <PolicySetting
            isChild={isChild}
            settingId={settingId}
            type="dropdown"
        >
            <Select
                size="small"
                className={styles['policy-dropdown']}
                popupMatchSelectWidth={false}
                value={settingValue}
                popupClassName={styles['policy-dropdown-overlay']}
                options={getValuesToDropdown(values, settingId, i18n.t.bind(i18n))}
                onChange={onChange}
            />
        </PolicySetting>
    );
};

export default memo(PolicyDropdownRow);
