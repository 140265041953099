import React, { FC, useEffect } from 'react';

import { CheckCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { CopyFileLink } from '../../Common/CopyLink';
import { useStores } from '../../hooks';
import styles from './SuccessScreen.module.scss';

const nameSpace = 'workspaceDrawer';

const SuccessScreen: FC = observer(() => {
    const { t } = useTranslation();

    const { sharedUsersStore, workspacesStore } = useStores();

    const { singleRecipientIdentity } = sharedUsersStore;

    useEffect(() => {
        sharedUsersStore.startLinksPreloading({
            filesIds: [workspacesStore.chosenForPolicyWorkspaceFid],
            mode: 'general',
        });
        return () => {
            sharedUsersStore.cancelLinksPreloading();
        };
    }, []);

    return (
        <div className={styles['workspace-success-wrapper']}>
            <div className={styles['text-block']}>
                <CheckCircleFilled className={styles['success-icon']} />
                <div>{t(`${nameSpace}.fileRequestSent`)}</div>
                <div className={styles.text}>
                    {t(`${nameSpace}.alsoLink`)}
                </div>
            </div>
            <div className={styles['copy-link-wrapper']}>
                <CopyFileLink linkKey={singleRecipientIdentity} UIMode="button" />
            </div>
        </div>
    );
});

export default SuccessScreen;
