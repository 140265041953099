import React, {
    FC, KeyboardEvent, useState, useCallback,
} from 'react';

import {
    Input, message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { AutoComplete } from '@/components/Common/UIKit';
import RecipientChip from '@/components/Common/SharingBlock/RecipientListItem/RecipientChip';
import CreateFolderModal from '@/components/Common/CreateFolderModal';
import { useStores } from '@/components/hooks';
import { DropdownItem } from '@/types/types';
import i18n from '@/content';
import { createWorkspace } from '@/api';
import { captureUnexpectedNetworkError, filterByLabel } from '@/components/utils';

import CreateNewWorkspaceButton from './CreateNewWorkspaceButton';
import styles from './WorkspaceNameBlock.module.scss';

const LIMIT_DEFAULT_OPTIONS = 5;

const workspaceNameSpace = 'workspaceDrawer';

const WorkspaceNameBlock: FC = observer(() => {
    const { workspacesStore, authSettingsStore } = useStores();
    const {
        workspacesList,
        chosenForPolicyWorkspace,
        setChosenForPolicyWorkspaceFid,
    } = workspacesStore;

    const [inputValue, setInputValue] = useState<string>('');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    const trimmedInputValue = inputValue.trim();
    const workspacesToDropdown: DropdownItem[] = workspacesList.map(
        (item) => ({ value: item.fid, label: item.filename }),
    );
    const createNewWorkspace = async (): Promise<void> => {
        const { API } = authSettingsStore;
        const { setIsLoading, setNewWorkspaceId } = workspacesStore;
        const workspaceName = trimmedInputValue || 'Untitled workspace';
        try {
            setIsLoading(true);
            const workspaceId = await createWorkspace(workspaceName, API);
            workspacesStore.insertNewWorkspace(workspaceId, workspaceName);
            setNewWorkspaceId(workspaceId);
        } catch (error) {
            console.log('Error!', error);
            captureUnexpectedNetworkError(error, 'CreateFolderModalContent.createFolder');
            message.error(i18n.t('createFolder.messages.error.createFolder', { workspaceName }));
        } finally {
            setIsLoading(false);
        }
    };

    const autocompleteOptions = [
        { label: (<CreateNewWorkspaceButton createNewWorkspace={createNewWorkspace} />), value: '' },
        ...workspacesToDropdown,
    ];

    const handleKeyUp = async (event: KeyboardEvent): Promise<void> => {
        if (event.key === 'Enter') {
            await createNewWorkspace();
        }
    };

    const onSelect = (value: string): void => {
        if (value) {
            setChosenForPolicyWorkspaceFid(value);
        }
    };

    const closeCreateWorkspace = useCallback((): void => {
        setIsCreateModalOpen(false);
    }, []);

    const unselectWorkspace = useCallback((): void => {
        setChosenForPolicyWorkspaceFid('');
        setInputValue('');
    }, []);

    return (
        <div className={styles['workspace-name-wrapper']}>
            <div className={styles['workspace-title']}>
                {t(`${workspaceNameSpace}.workspaceName`)}
            </div>
            <div className={styles['search-workspace-wrapper']}>
                {!chosenForPolicyWorkspace
                    ? (
                        <AutoComplete
                            className={styles['search-workspace']}
                            options={trimmedInputValue
                                ? autocompleteOptions
                                : workspacesToDropdown.slice(0, LIMIT_DEFAULT_OPTIONS)}
                            value={inputValue}
                            onChange={setInputValue}
                            onKeyUp={handleKeyUp}
                            onSelect={onSelect}
                            filterOption={filterByLabel}
                        >
                            <Input
                                placeholder="Select workspace or create a new one"
                                prefix={<div className={styles['search-icon-wrapper']}><SearchOutlined /></div>}
                            />
                        </AutoComplete>
                    )
                    : <RecipientChip content={chosenForPolicyWorkspace.filename} onClose={unselectWorkspace} />}
            </div>
            {/* We are using new component instance, because Modal has same z-index as Drawer, so Drawer overlap it */}
            <CreateFolderModal isOpen={isCreateModalOpen} closeModal={closeCreateWorkspace} isWorkspace />
        </div>
    );
});

export default WorkspaceNameBlock;
