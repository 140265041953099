import { FC, useState } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';

import Modal, {
    ModalFooter,
    ModalHeader,
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
} from '@/components/Common/Modal';
import { useStores } from '@/components/hooks';

import styles from './BlockingPopup.module.scss';

const BlockingPopup: FC = observer(() => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { appStore: { blockingPopup, setBlockingPopup } } = useStores();

    const onClick = async (): Promise<void> => {
        setIsLoading(true);
        await blockingPopup?.goNext?.action?.();
        setIsLoading(false);
    };

    // Fragment is necessary to escape DOM element from custom props
    return (
        <Modal
            isOpen={!!blockingPopup}
            closeModal={() => setBlockingPopup(null)}
            closable={false}
            initStyle={{
                additionalWrapClassName: MODAL_DISABLE_MIN_HEIGHT_CLASS,
            }}
        >
            <>
                <ModalHeader>
                    <span className={styles['header-message']}>
                        {blockingPopup?.message}
                    </span>
                </ModalHeader>
                <div className={styles.main}>
                    {blockingPopup?.description && (
                        <span className={styles['main-message']}>
                            {blockingPopup.description}
                        </span>
                    )}
                </div>
                <ModalFooter>
                    <div className={styles.footer}>
                        {blockingPopup?.goNext && (
                            <Button
                                onClick={onClick}
                                className={styles['action-button']}
                                loading={isLoading}
                            >
                                {blockingPopup.goNext.actionTitle}
                            </Button>
                        )}
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
});

export default BlockingPopup;
