import React, {
    FC,
    memo,
    useCallback,
    useState,
} from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { ChangePhoneProps } from '@/components/Common/EditPhoneInput';
import type { PhoneIdentity } from '@/types/types';

import PhoneView from './PhoneView';
import styles from './RecipientPhone.module.scss';
import PhoneInputEdit from '@/components/Common/PhoneInput/PhoneInputEdit';

interface RecipientPhoneProps extends ChangePhoneProps, PhoneIdentity {
    isSearching: boolean;
    setPhoneNumber?: (value: PhoneIdentity) => void,
    groupId?: string;
    isGroup?: boolean;
}

const RecipientPhone: FC<RecipientPhoneProps> = ({
    phone,
    prefix,
    isSearching,
    changePhone,
    groupId,
    isGroup,
    setPhoneNumber,
}) => {
    const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);

    const enableEditMode = useCallback(() => setIsEditModeEnabled(true), []);
    const disableEditMode = useCallback(() => setIsEditModeEnabled(false), []);

    const onPhoneInputClose = useCallback((): void => {
        disableEditMode();
    }, []);

    const handleSubmit = (value: PhoneIdentity): void => {
        changePhone?.(value);
        setPhoneNumber?.(value);
        disableEditMode();
    };

    if (isSearching && !groupId) {
        return (
            <span className={styles['loader-wrapper']}>
                <LoadingOutlined className={styles.loader} spin />
            </span>
        );
    }

    return (
        <div className={styles['phone-wrapper']}>
            {isEditModeEnabled ? (
                <PhoneInputEdit
                    onClose={onPhoneInputClose}
                    onSubmit={handleSubmit}
                    phone={phone}
                    prefix={prefix}
                />
            ) : (
                <PhoneView
                    phone={phone}
                    prefix={prefix}
                    enableEditMode={enableEditMode}
                    groupId={groupId}
                    isGroup={isGroup}
                />
            )}
        </div>
    );
};

export default memo(RecipientPhone);
