import { message } from 'antd';
import { TFunction } from 'i18next';
import type {
    RcFile,
    UploadFile as AntUploadedFile,
    UploadFileStatus,
} from 'antd/lib/upload/interface';
import { AxiosError } from 'axios';

import CancellableAPI from '@/api/CancellableAPI';
import i18n from '@/content';
import { getErrorResponse } from '@/components/utils';

import { BaseUploadedItem, RemoveResult, UploadedFile } from '../interfaces';
import NoProcessError from '../NoProcessError';
import FileInfectedError from '../FileInfectedError';

export const createAntFile = (file: RcFile, status: UploadFileStatus = 'uploading'): AntUploadedFile => {
    const {
        name,
        size,
        lastModified,
        lastModifiedDate,
        uid,
        type,
    } = file;
    return {
        name,
        size,
        lastModified,
        lastModifiedDate,
        uid,
        type,
        status,
        originFileObj: file,
        percent: 0,
    };
};

const PARENT_FOLDER_INDEX_SHIFT = 2;

export const parseFolderPath = (webkitRelativePath: string): [string, string] => {
    const breadcrumbs: string[] = webkitRelativePath.split('/');
    const parentFolderIndex: number = breadcrumbs.length - PARENT_FOLDER_INDEX_SHIFT;
    const parentFolder: string = breadcrumbs[parentFolderIndex];
    const pathToRoot: string = parentFolderIndex > 0 ? breadcrumbs.slice(0, parentFolderIndex).join('/') : '';
    return [parentFolder, pathToRoot];
};

export const checkIsFileInFolder = (file: UploadedFile): boolean => (
    (file.originFileObj as RcFile)?.webkitRelativePath?.includes('/')
);

export const partialUpdateUploadedItem = <T extends BaseUploadedItem>(
    list: T[],
    compare: (item: T) => boolean,
    itemPartial: Partial<T>,
): T[] => (
    list.map<T>((item) => {
        if (compare(item)) {
            return { ...item, ...itemPartial };
        }
        return item;
    })
);

const nameSpace = 'uploadFiles.messages';

export const handleUploadedFileRemovingResult = (t: TFunction, fileName: string, result: RemoveResult): void => {
    if (result === 'error') {
        message.error(t(`${nameSpace}.error.deleteError`, { fileName }));
    } else {
        message.success(t(`${nameSpace}.success.deleteSuccess`, { fileName }));
    }
};

export const uploadIdToRequestKey = (uid: string): string => `--${uid}--`;

export const ERRORS_NAMESPACE = `${nameSpace}.error`;

export const handleUploadError = (error: unknown, fileName: string): void => {
    if (error instanceof NoProcessError) {
        message.error(i18n.t(`${ERRORS_NAMESPACE}.unableToUpload`, { fileName }));
    } else if (error instanceof FileInfectedError) {
        message.error(i18n.t(`${ERRORS_NAMESPACE}.unableToUpload`, { fileName }));
    } else if (!CancellableAPI.isAnyInstanceCancel(error)) {
        const { statusCode, message: responseMessage } = getErrorResponse(error as AxiosError);
        if (responseMessage && statusCode === 400) {
            message.error(i18n.t(`${ERRORS_NAMESPACE}.uploadBadRequest`, { responseMessage, fileName }));
        } else {
            message.error(i18n.t(`${ERRORS_NAMESPACE}.couldNotUpload`, { fileName }));
        }
    }
};

export const mergePropsToRcFile = (file: RcFile, props: Partial<AntUploadedFile>): AntUploadedFile => {
    const fileOwnPropsCopy: AntUploadedFile = { ...file, ...props };
    const fileProtoPropsCopy = Object.keys(Object.getPrototypeOf(file)).reduce((acc, key) => {
        return { ...acc, [key]: file[key] };
    }, {});
    return { ...fileOwnPropsCopy, ...fileProtoPropsCopy };
};
