import {
    action,
    makeObservable,
    observable,
} from 'mobx';

import FilesAccessStore from '../FilesAccessStore';
import AuthSettingsStore from '../AuthSettingsStore';
import UserStore from '../UserStore';
import { EmailMessageStore } from './EmailMessageStore';
import { SharedFilesStore } from './SharedFilesStore';

class RecipientPageStore {
    constructor(filesAccessStore: FilesAccessStore, authSettingsStore: AuthSettingsStore, userStore: UserStore) {
        this.sharedFilesStore = new SharedFilesStore(authSettingsStore, userStore);
        this.emailMessageStore = new EmailMessageStore(authSettingsStore, filesAccessStore, this.sharedFilesStore);
        makeObservable(this);
    }

    readonly emailMessageStore: EmailMessageStore;

    readonly sharedFilesStore: SharedFilesStore;

    @observable
    fullScreen = false;

    @observable
    collapsed = false;

    @action
    setFullScreen = (fullScreen: boolean): void => {
        this.fullScreen = fullScreen;
    };

    @action
    setCollapsed = (collapsed: boolean): void => {
        this.collapsed = collapsed;
    };
}

export default RecipientPageStore;
