import { useRef, useState } from 'react';

import {
    useInstance,
    useStores,
    useWindowBeforeUnload,
} from '../../../hooks';
import {
    CancellableAPI,
    initiateOverwrite,
    OverwriteFileResponse,
    UploadAxiosInstance,
} from '../../../../api';
import { SimpleCallback } from '../../../../types/types';
import { captureErrorForSentry, SubscribablePromise } from '../../../utils';
import { Instance as PDFInstance } from '../pspdfkitTypes';

interface FileSaving {
    isSavingInProgress: boolean;
    hasError: boolean;
    hasUnFlattenAnnotations: boolean;
    connectInstance: (pdfInstance: PDFInstance) => () => void;
    saveFile: (flatten?: boolean) => Promise<boolean>;
    processUnFlattenAnnotations: (pdfInstance: PDFInstance) => Promise<void>;
}

const useFileSaving = (fileId: string, onSave?: SimpleCallback): FileSaving => {
    const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
    const [hasUnFlattenAnnotations, setHasUnFlattenAnnotations] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const { authSettingsStore: { API } } = useStores();

    const uploadURLFetcher = useInstance<SubscribablePromise<OverwriteFileResponse>>(
        () => new SubscribablePromise<OverwriteFileResponse>(),
    );
    const uploadInstance = useInstance<UploadAxiosInstance>(() => new UploadAxiosInstance('single'));

    const taskIdRef = useRef<number>(0);
    const uploadLinkRef = useRef<string>('');
    const pdfInstanceRef = useRef<PDFInstance>(null);

    useWindowBeforeUnload(() => isSavingInProgress || hasUnFlattenAnnotations);

    const processUnFlattenAnnotations = async (pdfInstance: PDFInstance): Promise<void> => {
        const pagesAnnotations = await Promise.all(
            Array.from({ length: pdfInstance.totalPageCount })
                .map((_, pageIndex) => pdfInstance.getAnnotations(pageIndex)),
        );

        const allAnnotations = pagesAnnotations.flatMap((pageList) => pageList.toJS());

        setHasUnFlattenAnnotations(allAnnotations.length > 0);
    };

    const trySaveFile = async (pdfInstance: PDFInstance, flatten = false): Promise<boolean> => {
        const taskId: number = taskIdRef.current;
        let isCanceled = false;
        let hasSavingError = false;
        try {
            const file = await pdfInstance.exportPDF({ flatten });
            if (taskId === taskIdRef.current && !uploadLinkRef.current) {
                const { url } = await uploadURLFetcher.call(() => initiateOverwrite(API, fileId));
                uploadLinkRef.current = url;
            }
            if (taskId === taskIdRef.current) {
                await uploadInstance.put(uploadLinkRef.current, new Int8Array(file), fileId);
                if (flatten) {
                    setHasUnFlattenAnnotations(false);
                } else {
                    processUnFlattenAnnotations(pdfInstance);
                }
            }
        } catch (error) {
            if (!CancellableAPI.isAnyInstanceCancel(error)) {
                console.log('file save error', error);
                captureErrorForSentry(error, 'useFileSaving.syncFile');
                hasSavingError = true;
                setHasError(true);
            } else {
                isCanceled = true;
            }
        } finally {
            if (taskId === taskIdRef.current && !isCanceled) {
                setIsSavingInProgress(false);
            }
        }
        return !hasSavingError;
    };

    const handleSaveStateChange = (): void => {
        setHasUnFlattenAnnotations(true);
    };

    const connectInstance = (pdfInstance: PDFInstance): () => void => {
        pdfInstanceRef.current = pdfInstance;
        pdfInstance.addEventListener('document.saveStateChange', handleSaveStateChange);
        return () => {
            pdfInstance.removeEventListener('document.saveStateChange', handleSaveStateChange);
        };
    };

    const runSaveFile = async (flatten = false): Promise<boolean> => {
        setHasError(false);
        setIsSavingInProgress(true);
        const isSucceed = await trySaveFile(pdfInstanceRef.current, flatten);
        if (isSucceed) {
            onSave?.();
        }
        return isSucceed;
    };

    return {
        isSavingInProgress,
        hasError,
        hasUnFlattenAnnotations,
        connectInstance,
        saveFile: runSaveFile,
        processUnFlattenAnnotations,
    };
};

export default useFileSaving;
