import React, {
    FC,
} from 'react';

import { Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import ActionsColumn from './ActionsColumn';
import FilesPicker from './FilesPicker';
import Download from './ActionsColumn/Download';
import { useStores, useTargetScreen } from '../../../../../hooks';
import { MainContentType } from '../helpers';
import styles from './FilesRow.module.scss';

interface FilesRowProps {
    showOpenViewerButton: boolean;
    contentType: MainContentType;
}

const FilesRow: FC<FilesRowProps> = observer(({
    showOpenViewerButton, contentType,
}) => {
    const isMobile = useTargetScreen('md');
    const {
        recipientPageStore: {
            collapsed,
            setCollapsed,
            sharedFilesStore: { filesList },
        },
    } = useStores();

    // TODO: solve it without extra wrapper
    return (
        <div className={classNames({
            [styles.expanded]: collapsed,
            [styles['single-file']]: filesList.length <= 1 && !isMobile,
            [styles['single-mobile-file']]: isMobile,
        })}
        >
            <div className={styles.container}>
                {!isMobile && contentType === 'fileView' && (
                    <Button
                        type="text"
                        className={styles['collapse-trigger-btn']}
                        onClick={() => setCollapsed(!collapsed)}
                    >
                        {collapsed
                            ? <CaretUpOutlined className={styles['caret-circle']} />
                            : <CaretDownOutlined className={styles['caret-circle']} />}
                    </Button>
                )}
                {isMobile && <Download />}
                <FilesPicker
                    collapsed={collapsed}
                    showOpenViewerButton={showOpenViewerButton}
                />
                {!isMobile && (
                    <ActionsColumn
                        collapsed={collapsed}
                        showOpenViewerButton={showOpenViewerButton}
                    />
                )}
            </div>
        </div>
    );
});

export default FilesRow;
