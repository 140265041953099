import { message } from 'antd';
import { computed } from 'mobx';
import { TFunction } from 'i18next';

import {
    checkIsMFAErrorType,
    FilesAccessStore,
    getOpenFileErrorText,
    UserStore,
} from '@/stores';
import {
    Policy,
    SimpleCallback,
} from '@/types/types';
import { LinkBasedOpenOptions, OpenOptions } from '@/config/openOptions';
import { checkIsWindowPopupBlocked, downloadFile } from '@/components/utils';
import { checkHasConfirmSetting } from '@/components/Common/ConfirmDownload';

import { useFileAccessPopups } from '../FileAccessPopupsContext';
import { AccessActionHandler } from './useVerifyAction';

interface FetchAccessLinkAction {
    isLoading: boolean;
    clickHandler: SimpleCallback;
}

export interface FileAccessData {
    fileId: string;
    filename: string;
    ownerEmail: string;
    policy: Policy;
}

const useFetchAccessLink = (
    {
        fileId,
        filename,
        policy,
        ownerEmail,
    }: FileAccessData,
    accessType: LinkBasedOpenOptions,
    t: TFunction,
    actionHandler: AccessActionHandler,
    filesAccessStore: FilesAccessStore,
    userStore: UserStore,
): FetchAccessLinkAction => {
    const { openConfirmPopup, openAllowPopup } = useFileAccessPopups();

    const isLoading = computed<boolean>(
        () => filesAccessStore.checkIsLoading(fileId, accessType),
    ).get();

    const processAccessLink = (link: string): void => {
        if (accessType === OpenOptions.download) {
            // TODO: use id for such checks!
            if (userStore.currentUserEmail !== ownerEmail && checkHasConfirmSetting(policy)) {
                openConfirmPopup(link, { ownerEmail, filename });
            } else {
                downloadFile(link);
            }
        } else {
            const newWin = window.open(link);
            if (checkIsWindowPopupBlocked(newWin)) {
                openAllowPopup(link);
            }
        }
    };

    const runAccessFlow = async (code?: string, throwError = false): Promise<void> => {
        await filesAccessStore.tryFetchAccessLink({
            fileId,
            filename,
            accessType,
            code,
            throwError,
        });
        const state = filesAccessStore.getAccessTypeState(fileId, accessType);
        if (state?.result) {
            const { link } = state.result;
            if (link) {
                processAccessLink(link);
            }
        } else if (state?.errorType && !checkIsMFAErrorType(state.errorType)) {
            message.error(getOpenFileErrorText(state.errorType, t));
        }
    };

    const onClick = actionHandler({
        action: runAccessFlow,
        verifyAction: async (code) => runAccessFlow(code, true),
        payload: { fileId, accessType },
    });

    return {
        isLoading,
        clickHandler: onClick,
    };
};

export default useFetchAccessLink;
