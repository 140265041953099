import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import OpenFileButton from '../OpenFileButton';

import { FileAccessData } from '../../../../../hooks';
import { LinkBasedOpenOptions, OpenOptions } from '@/config/openOptions';

import styles from '../FileOpenOptions.module.scss';

const buttonNameSpace = 'recipientPage.accessButtons';

export interface FileOpenOptionsProps extends FileAccessData {
    allowedApps: LinkBasedOpenOptions[];
}

const OpenFileButtonContainer: FC<FileOpenOptionsProps> = ({
    allowedApps,
    ...fileAccessData
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles['button-container']}>
            {allowedApps?.map((value, index) => (
                <Fragment key={value}>
                    <>
                        {value === OpenOptions.download && index > 0 && (
                            <span>
                                {t(`${buttonNameSpace}.or`)}
                            </span>
                        )}
                        <OpenFileButton
                            {...fileAccessData}
                            accessOption={value}
                        />
                    </>
                </Fragment>
            ))}
        </div>
    );
};

export default OpenFileButtonContainer;
