import React, { FC, memo } from 'react';

import { SwapOutlined } from '@ant-design/icons';

import { PUBLIC_ASSETS } from '@/consts';
import styles from './ConnectLogo.module.scss';

const SWAP_ICON_STYLE = {
    transform: 'rotateX(180deg)',
    fontSize: '28px',
};

const ConnectLogo: FC = () => (
    <div className={styles.container}>
        <img src={`${PUBLIC_ASSETS}/icons/box-i.png`} className={styles['storage-logo']} alt="Box logo" />
        <SwapOutlined style={SWAP_ICON_STYLE} />
        <div className={styles['spx-logo-wrapper']}>
            <img
                className={styles['spx-logo']}
                src={`${PUBLIC_ASSETS}/company_logo/SpecterX-Logo-X.png`}
                alt="SpecterX logo"
            />
        </div>
    </div>
);

export default memo(ConnectLogo);
