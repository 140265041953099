import React, { FC } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { observer } from 'mobx-react';

import Logout from './Logout';
import { useStores } from '../../../../hooks';
import styles from './PermissionsErrorExplain.module.scss';

const nameSpace = 'recipientPage.errors.permissions';

export interface PermissionsErrorExplainProps {
    ownerEmail?: string;
    item: string;
}

const PermissionsErrorExplain: FC<PermissionsErrorExplainProps> = observer(({ ownerEmail, item }) => {
    const { t } = useTranslation();

    const { userStore, authSettingsStore } = useStores();
    const { email: currentUserEmail } = userStore.userInformation;
    const { IS_WSO } = authSettingsStore;

    const tryContactWithOwner = t(`${nameSpace}.tryContact`, {
        ownerEmail: ownerEmail || t(`${nameSpace}.fileOwner`, { item }),
    });

    return (
        <div className={styles.wrapper}>
            <span className={styles['current-user-wrapper']} data-testid="recipientPage_noPermissionsPageText">
                <Trans
                    t={t}
                    i18nKey={`${nameSpace}.currentUser`}
                    tOptions={{ currentUserEmail, item }}
                >
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    You logged in as <strong>currentUserEmail</strong> You dont have access
                </Trans>
            </span>
            {!IS_WSO && <Logout />}
            <b>{tryContactWithOwner}</b>
        </div>
    );
});

export default PermissionsErrorExplain;
