import React, { FC, memo } from 'react';

import { PUBLIC_ASSETS } from '@/consts';
import StatusIcon, { CommonIconProps } from './StatusIcon';

const src = `${PUBLIC_ASSETS}/icons/error_yoga.svg`;

const YogaIcon: FC<CommonIconProps> = (props) => <StatusIcon src={src} {...props} />;

export default memo(YogaIcon);
