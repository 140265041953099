import React, { FC, useEffect, useState } from 'react';

import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicFooter } from '../../Common/PublicComponents';
import { Header } from '../Common';
import Spinner from '../../Common/Spin';
import Description from './Description';
import ResultTitle from './ResultTitle';
import ResultSubTitle from './ResultSubTitle';
import { useStores } from '../../hooks';
import { saveRedirectPath } from '../../utils';
import { AppRoutes, SIGN_IN_PATHNAME } from '@/config/appRoutes';
import { FileAccessParams, ResultType, ShareRequestParams } from './interfaces';
import {
    tryShareFile,
    getFileAccessParams,
    getResultContent,
    getShareActionButtonTitle,
} from './helpers';
import { MAP_RESULT_TYPE_TO_RESULT_STATUS, nameSpace, UNCOMPLETED_RESULTS_SET } from './constants';
import style from './GiveAccess.module.scss';

interface GiveAccessProps {
    isRootLoading: boolean;
}

const GiveAccess: FC<GiveAccessProps> = ({ isRootLoading }) => {
    const [resultType, setResultType] = useState<ResultType>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [queryParams, setQueryParams] = useState<FileAccessParams>(null);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { authSettingsStore: { API }, userStore } = useStores();

    const sendSharingRequest = async (requestParams: ShareRequestParams): Promise<void> => {
        const result = await tryShareFile(API, requestParams);
        setResultType(result);
        setIsLoading(false);
    };

    const handleAccessAction = async (readOnly: boolean): Promise<void> => {
        setIsLoading(true);
        await sendSharingRequest(
            { fileId: queryParams.fileId, subjectEmail: queryParams.subjectEmail, readOnly },
        );
    };

    useEffect(() => {
        if (!isRootLoading) {
            // TODO: migrate it on useSearchParams
            const { filename, fileId, subjectEmail } = getFileAccessParams(window.location.search);
            const isParamsValid = fileId && subjectEmail && filename;
            if (!isParamsValid) {
                setIsLoading(false);
                setResultType('incorrectURL');
            } else if (!userStore.isUserSetUp) {
                saveRedirectPath(`${window.location.pathname}${window.location.search}`);
                navigate(SIGN_IN_PATHNAME);
            } else {
                sendSharingRequest({ subjectEmail, fileId, readOnly: true });
                setQueryParams({ filename, fileId, subjectEmail });
            }
        }
    }, [isRootLoading]);

    const { titlePayload, subTitlePayload } = getResultContent(resultType, queryParams);

    const isFlowUncompleted = UNCOMPLETED_RESULTS_SET.has(resultType);
    const hasExtra = isFlowUncompleted || resultType === 'upgradeSuccess';
    const shouldGiveReadOnlyAccess = resultType === 'shareFail';

    return (
        <div className={style.container}>
            <Header />
            <div className={style['content-wrapper']}>
                <Spinner spinning={isLoading} className={style.spin} />
                {resultType
                    ? (
                        <>
                            <Result
                                className={style.result}
                                status={MAP_RESULT_TYPE_TO_RESULT_STATUS[resultType]}
                                title={titlePayload && <ResultTitle>{t(...titlePayload)}</ResultTitle>}
                                subTitle={subTitlePayload && <ResultSubTitle>{t(...subTitlePayload)}</ResultSubTitle>}
                                extra={hasExtra && (
                                    isFlowUncompleted
                                        ? (
                                            <Button
                                                type="primary"
                                                onClick={() => handleAccessAction(shouldGiveReadOnlyAccess)}
                                                disabled={isLoading}
                                            >
                                                {getShareActionButtonTitle(resultType, t)}
                                            </Button>
                                        ) : (
                                            <Link to={AppRoutes.myFiles}>
                                                <Button type="primary">
                                                    {t(`${nameSpace}.goToMyFiles`)}
                                                </Button>
                                            </Link>
                                        )
                                )}
                            />
                        </>
                    )
                    : (
                        <Description
                            subjectEmail={queryParams && queryParams.subjectEmail}
                            filename={queryParams && queryParams.filename}
                        />
                    )}
            </div>
            <PublicFooter />
        </div>
    );
};

export default GiveAccess;
