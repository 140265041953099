import { ShareLinksDict } from '../../../stores/SharedUsersStore/interfaces';

export interface BaseCopyLinkProps {
    link: string;
    isLinkLoading: boolean;
    linkKey: string;
}

export type LinkLoadState = 'success' | 'loading' | 'error';

export type LinkLoadParams = Pick<BaseCopyLinkProps, 'link' | 'isLinkLoading'>;

export const getLinkLoadState = ({ link, isLinkLoading }: LinkLoadParams): LinkLoadState => {
    let result: LinkLoadState = 'loading';
    if (link) {
        result = 'success';
    } else if (!isLinkLoading) {
        result = 'error';
    }
    return result;
};

export const getLinkData = (
    linksForFiles: ShareLinksDict,
    linkKey: string,
): LinkLoadParams => {
    const linkData = linksForFiles.get(linkKey);
    return {
        link: linkData?.link,
        isLinkLoading: linkData?.isLoading,
    };
};
