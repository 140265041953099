import { JSONSerializableRecord } from '../../../types/types';
import { CreateLinkPayload } from './interfaces';
import { toSnakeCase } from '../../../components/utils';
import { EMPTY_RECIPIENT } from '../constants';

const filterPayload = (payload: CreateLinkPayload): Partial<CreateLinkPayload> => (
    Object.entries(payload)
        .reduce<Partial<CreateLinkPayload>>((acc, [key, value]) => {
            if (value && !(value === EMPTY_RECIPIENT && key === 'recpEmail')) {
                acc[key] = value;
            }
            return acc;
        }, {})
);

export const getRequestBody = (payload: CreateLinkPayload): JSONSerializableRecord => {
    const filledPayload = filterPayload(payload);
    return toSnakeCase(filledPayload);
};

export const createRequestKey = (recipientEmail?: string): string => (
    recipientEmail || EMPTY_RECIPIENT
);
