import { getErrorResponse, NotFoundError } from '../../../utils';

type AsyncCallback<T> = () => Promise<T>;

const handleNotFound = async <T>(callback: AsyncCallback<T>): Promise<T | never> => {
    let result: T;
    try {
        result = await callback();
    } catch (error) {
        const { statusCode } = getErrorResponse(error);
        if (statusCode === 404) {
            throw new NotFoundError('Shared file is not found');
        } else {
            throw error;
        }
    }
    return result;
};

export default handleNotFound;
