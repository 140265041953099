import type {
    FilePermissionActions,
    RecipientRoles,
    SharedUser,
} from '@/types/types';
import {
    BulkShareRequestPayload,
    RecipientToDisplay,
    SharedUserToDisplay,
} from './interfaces';

type PrimaryAccessData = Required<Pick<SharedUser, 'readOnly' | 'actions'>>;

const CO_OWNER_ACTIONS: FilePermissionActions[] = ['manage_access', 'view_logs'];

const MAP_ROLE_TO_ACTIONS: Record<RecipientRoles, FilePermissionActions[]> = {
    viewer: [],
    editor: [],
    coOwner: CO_OWNER_ACTIONS,
};

export const roleToPrimaryData = (role: RecipientRoles): PrimaryAccessData => ({
    readOnly: role === 'viewer',
    actions: [...MAP_ROLE_TO_ACTIONS[role]],
});

export const compareActions = (actions: FilePermissionActions[], sample: FilePermissionActions[]): boolean => (
    actions.length === sample.length && actions.every((action) => sample.includes(action))
);

export const primaryDataToRole = ({ readOnly, actions }: PrimaryAccessData): RecipientRoles => {
    if (readOnly) {
        return 'viewer';
    }
    if (!actions?.length) {
        return 'editor';
    }
    return compareActions(actions, CO_OWNER_ACTIONS) ? 'coOwner' : 'editor';
};

export const BEUserToDisplayUser = ({
    readOnly,
    actions,
    deleted,
    ...rest
}: SharedUser): SharedUserToDisplay => ({
    ...rest,
    deleted: deleted ?? false,
    recipientRole: primaryDataToRole({ readOnly, actions }),
});

export const displayUserToBE = ({ recipientRole, ...rest }: SharedUserToDisplay): SharedUser => ({
    ...rest,
    ...roleToPrimaryData(recipientRole),
});

type RecipientsPayload = Pick<BulkShareRequestPayload, 'users' | 'groups'>;

export const prepareRecipientsPayload = (recipients: RecipientToDisplay[]): RecipientsPayload => (
    recipients.reduce<RecipientsPayload>((acc, {
        email,
        phoneInfo,
        role,
        type,
        id,
    }) => {
        if (type === 'user') {
            acc.users.push({
                ...roleToPrimaryData(role),
                email,
                phoneNumber: phoneInfo?.phonesVector?.currentPhone,
            });
        } else {
            acc.groups.push({
                ...roleToPrimaryData(role),
                group_id: id,
            });
        }
        return acc;
    }, {
        users: [],
        groups: [],
    })
);
