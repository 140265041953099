import React, { FC, memo } from 'react';

import { Tag } from 'antd';

import styles from './TagsList.module.scss';

interface TagsListProps {
    tagsList: string[];
    onDelete: (value: string) => void;
}

const TagsList: FC<TagsListProps> = ({ tagsList, onDelete }) => (
    <div className={styles['tags-list']}>
        {tagsList.map((value) => (
            <div key={value} className={styles.slot}>
                <Tag
                    className={styles['slot-child']}
                    closable
                    onClose={() => onDelete(value)}
                >
                    {value}
                </Tag>
            </div>
        ))}
    </div>
);

export default memo(TagsList);
