import React, { CSSProperties, FC, memo } from 'react';

import classNames from './StatusIcon.module.scss';

export type IconSize = 'default' | 'small' | 'large';

export interface CommonIconProps {
    iconSize?: IconSize,
    style?: CSSProperties,
}

interface IconProps extends CommonIconProps {
    src: string,
}

const StatusIcon: FC<IconProps> = ({ src, iconSize = 'default', style }) => (
    <img
        className={`${classNames['status-icon']} ${classNames[iconSize]}`}
        src={src}
        style={style}
        alt="status icon"
    />
);

export default memo(StatusIcon);
