import React, { memo } from 'react';
import type { FC } from 'react';

import i18n from '@/content';

import AuditsFilters from './AuditsFilters';
import { NAME_SPACE } from '../constants';
import styles from './AuditsHeader.module.scss';

const AuditsHeader: FC = () => (
    <div className={styles['audits-header']}>
        <div className={styles['title-wrapper']}>
            <span className={styles['page-title']}>
                {i18n.t(`${NAME_SPACE}.title`)}
            </span>
        </div>
        <div className={styles['filters-wrapper']}>
            <AuditsFilters />
        </div>
    </div>
);

export default memo(AuditsHeader);
