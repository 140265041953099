export interface TagInputOptions {
    validationRegexp: RegExp;
    showDescription: boolean;
}

export enum ListTypeInputs {
    domains = 'domains',
    emailAddresses = 'emailAddresses',
    ips = 'ips',
}
