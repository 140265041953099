import { useEffect } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { computed } from 'mobx';

import { checkIsNonMFAOperationalError } from '../helpers';
import { FileAccessError, SyncCallback } from '../../../../../../types/types';
import { OpenOptions } from '../../../../../../config/openOptions';
import { FilesAccessStore, SharedFilesStore } from '../../../../../../stores';

const batchActionsNameSpace = 'filesTable.extraContent.batchActions';

const useDownloadActionError = (
    filesAccessStore: FilesAccessStore,
    sharedFilesStore: SharedFilesStore,
    openOTP: SyncCallback,
): void => {
    const { batchDownloadError } = filesAccessStore;
    const { allFilesCount, downloadableFilesIds } = sharedFilesStore;

    const isSingleDownloadType = allFilesCount === 1;

    const downloadActionError: FileAccessError = isSingleDownloadType
        ? computed<FileAccessError>(() => (
            filesAccessStore.getAccessTypeState(downloadableFilesIds[0], OpenOptions.download)?.errorType
        )).get()
        : batchDownloadError;

    const { t } = useTranslation();

    // TODO: these effects became to complex. Probably it will be more use to handle it imperative way
    useEffect(() => {
        if (downloadActionError === 'MFARequired') {
            openOTP();
        } else if (
            !isSingleDownloadType
            && downloadActionError === 'noPermissions'
        ) {
            message.error(t(`${batchActionsNameSpace}.downloadPermissionFailed`));
        } else if (
            !isSingleDownloadType
            && downloadActionError
            && checkIsNonMFAOperationalError(downloadActionError)
        ) {
            message.error(t('batchDownload.cannotStart'));
        }
    }, [downloadActionError]);
};

export default useDownloadActionError;
