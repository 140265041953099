import { SharedUser } from '../../types/types';
import { SPXAPI } from '../interfaces';
import { BASEURL, ENDPOINTS } from '../endpoints';

interface FilePermissions {
    users: SharedUser[];
    // TODO: Needs to search on BE exact interface
    domains: unknown[];
}

const getFilePermissions = async (API: SPXAPI, fileId: string): Promise<FilePermissions> => (
    API.get(BASEURL.backend(), ENDPOINTS.shareFile(fileId), {})
);

export default getFilePermissions;
