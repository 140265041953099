import {
    action,
    observable,
    makeObservable,
    computed,
    runInAction,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import { DomainSharingStats, SharingStats, SharingStatsLoadStatus } from './interfaces';
import { ENDPOINTS, BASEURL } from '../../api';
import { captureErrorForSentry } from '../../components/utils';

const SHARING_STATUS_LOADING_INIT: SharingStatsLoadStatus = {
    isLoading: false,
    hasError: false,
};

const sortDomainsStats = (current: DomainSharingStats, prev: DomainSharingStats): number => {
    let result: number = prev.numberOfShares - current.numberOfShares;
    if (!result) {
        const currentNameLower = current.domainName.toLowerCase();
        const prevNameLower = prev.domainName.toLowerCase();
        result = currentNameLower > prevNameLower ? 1 : -1;
    }
    return result;
};

class SharingStatsStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    @observable sharingStats: SharingStats = null;

    @observable sharingStatsLoadingStatus: SharingStatsLoadStatus = { ...SHARING_STATUS_LOADING_INIT };

    @computed
    get totalShares(): number {
        return this.sharingStats?.totalShares;
    }

    @computed
    get hasData(): boolean {
        return !!this.sharingStats;
    }

    @action
    updateSharingStatsLoadingStatus = (loadingStatus: Partial<SharingStatsLoadStatus>): void => {
        this.sharingStatsLoadingStatus = { ...this.sharingStatsLoadingStatus, ...loadingStatus };
    }

    @action
    setSharingStats = (sharingStats: SharingStats): void => {
        this.sharingStats = sharingStats;
    }

    fetchSharingStats = async (): Promise<void> => {
        const { API } = this.authSettingsStore;
        try {
            this.updateSharingStatsLoadingStatus({ ...SHARING_STATUS_LOADING_INIT, isLoading: true });
            const sharingStats: SharingStats = await API.get(BASEURL.backend(), ENDPOINTS.getSharingStats(), {});
            sharingStats.domains.sort(sortDomainsStats);
            runInAction(() => {
                this.sharingStats = sharingStats;
                this.sharingStatsLoadingStatus = { ...this.sharingStatsLoadingStatus, isLoading: false };
            });
        } catch (error) {
            console.log('error while fetch sharing stats', error);
            captureErrorForSentry(error, 'SharingStatsStore.fetchSharingStats');
            this.updateSharingStatsLoadingStatus({
                isLoading: false,
                hasError: true,
                errorMessageKey: 'domainsDashboard.messages.error.couldNotLoadStats',
            });
        }
    }
}

export default SharingStatsStore;
