import { RefObject, useRef } from 'react';

import { SyncCallback } from '@/types/types';

const useFocus = (): {
    focus: SyncCallback;
    blur: SyncCallback;
    isFocusedRef: RefObject<boolean>;
} => {
    const isFocusedRef = useRef<boolean>(false);

    const focus = (): void => {
        isFocusedRef.current = true;
    };

    const blur = (): void => {
        isFocusedRef.current = false;
    };

    return {
        focus,
        blur,
        isFocusedRef,
    };
};

export default useFocus;
