import React, { FC } from 'react';

import { CheckCircleFilled } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';

import styles from './AddMemberModal.module.scss';

const nameSpace = 'userRoleEditor.addMembersModal';

const SuccessPage: FC = observer(() => {
    const { t } = useTranslation();
    return (
        <div className={styles['success-content']}>
            <CheckCircleFilled className={styles['success-icon']} />
            <div className={styles['success-title']}>{t(`${nameSpace}.usersAddedSuccessfully`)}</div>
            <div className={styles['success-text']}>{t(`${nameSpace}.newUsersInvited`)}</div>
        </div>
    );
});

export default SuccessPage;
