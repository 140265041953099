import React, { FC, memo, useState } from 'react';

import { Button, message } from 'antd';

import i18n from '@/content';
import { useMounted, useStores } from '@/components/hooks';
import { captureErrorForSentry } from '@/components/utils';

import createCSV from './createCSV.js';
import { RECORDS_TO_CSV } from '../../../constants';
import filtersNameSpace from '../nameSpace';

const nameSpace = 'auditLogs';

const ExportCSV: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isMountedRef = useMounted();

    const { auditsStore } = useStores();

    const onClick = async (): Promise<void> => {
        setIsLoading(true);
        try {
            const audits = await auditsStore.getAudits({ recordsCount: RECORDS_TO_CSV, allowInParallel: true });
            createCSV(audits.results);
        } catch (error) {
            console.log('Could not create csv', error);
            captureErrorForSentry(error, 'ExportCSV.onClick');
            message.error(i18n.t(`${nameSpace}.messages.couldNotCreateCSV`));
        }
        if (isMountedRef.current) {
            setIsLoading(false);
        }
    };

    return (
        <Button
            type="link"
            onClick={onClick}
            loading={isLoading}
        >
            {i18n.t(`${filtersNameSpace}.exportCSV`)}
        </Button>
    );
};

export default memo(ExportCSV);
