import React, {
    FC, SyntheticEvent, useState, memo,
} from 'react';

import { SpInput } from '@/components/Common/Sp';
import i18n from '@/content';
import PasswordValidation from '../../Common/PasswordValidation';
import AuthSubmitButton from '../AuthSubmitButton';
import { BaseAuthLink } from '../AuthLink';
import styles from './ForgotPassword.module.scss';

const nameSpace = 'forgotPassword.secondStep';

interface PasswordWithValidation {
    value: string;
    isValid: boolean;
}

interface CreateNewPasswordProps {
    onSubmit: (e: SyntheticEvent, code: string, newPassword: string) => void;
    doForgotPassword: (e: SyntheticEvent) => void;
    goBack: () => void;
}

const CreateNewPassword: FC<CreateNewPasswordProps> = ({ onSubmit, doForgotPassword, goBack }) => {
    const [code, setCode] = useState<string>('');
    const [passwordWithValidation, setPasswordWithValidation] = useState<PasswordWithValidation>({
        value: '', isValid: false,
    });

    const changePassword = (value: string, isValid: boolean): void => setPasswordWithValidation({
        value, isValid,
    });

    const checkIsFormValid = (): boolean => !!code.length && passwordWithValidation.isValid;

    const handleSubmit = (event: SyntheticEvent): void => onSubmit(event, code, passwordWithValidation.value);

    return (
        <div>
            <div className={styles['forgot-info']}>
                {i18n.t(`${nameSpace}.title`)}
            </div>
            <form onSubmit={handleSubmit}>
                <SpInput
                    type="text"
                    value={code}
                    placeholder={i18n.t(`${nameSpace}.placeholder`)}
                    required
                    onChange={setCode}
                    autoComplete="off"
                />
                <PasswordValidation password={passwordWithValidation.value} updateInput={changePassword} />
                <AuthSubmitButton
                    isLimitedWidth
                    disabled={!checkIsFormValid()}
                    onClick={handleSubmit}
                    titleKey={`${nameSpace}.changePassword`}
                />
            </form>
            <BaseAuthLink onClick={doForgotPassword}>
                {i18n.t(`${nameSpace}.resend`)}
            </BaseAuthLink>
            <BaseAuthLink onClick={goBack}>
                {i18n.t(`${nameSpace}.toPrevStep`)}
            </BaseAuthLink>
        </div>
    );
};

export default memo(CreateNewPassword);
