import React, { FC } from 'react';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import QuestionTooltip from '../../../../../../Common/QuestionTooltip';
import styles from './InfoTooltip.module.scss';

interface InfoTooltipProps {
    textContent: string;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ textContent }) => {
    const screens = useBreakpoint();

    return (
        <QuestionTooltip
            placement={screens.md ? 'right' : 'topLeft'}
            iconClassName={styles['tooltip-icon']}
            // It's necessary to render tooltip placement correctly on mobile devices
            destroyOnHide={!screens.sm}
        >
            <div className={styles['content-container']}>
                <span>{textContent}</span>
            </div>
        </QuestionTooltip>
    );
};

export default InfoTooltip;
