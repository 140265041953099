import { Grid } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserver';

const { useBreakpoint } = Grid;

const useTargetScreen = (targetBreakPoint: Breakpoint): boolean => {
    const screens = useBreakpoint();
    return !screens[targetBreakPoint];
};

export default useTargetScreen;
