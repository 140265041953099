import { useState, useRef, useLayoutEffect } from 'react';

const useTextOverflowing = (filename: string): [boolean, React.RefObject<HTMLSpanElement>] => {
    const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false);
    const textContainerRef = useRef<HTMLSpanElement>(null);

    useLayoutEffect(() => {
        const textContainer = textContainerRef.current;
        setIsTextOverflowing(textContainer && (textContainer?.scrollWidth > textContainer?.clientWidth));
    }, [filename]);

    return [isTextOverflowing, textContainerRef];
};

export default useTextOverflowing;
