import React, { FC } from 'react';

import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { tryCopyToClipboard } from '../../../../utils';
import styles from './DebugInfo.module.scss';

const nameSpace = 'recipientPage.errors.debug';

const TOP_ORDER_KEYS: string[] = ['message', 'status'];

export interface DebugInfoProps {
    data: Record<string, string>;
}

const DebugInfo: FC<DebugInfoProps> = ({ data }) => {
    const { t } = useTranslation();

    const sortedLogsEntries = Object.entries(data).sort(([currentKey]) => {
        const currentKeyLower = currentKey.toLowerCase();
        return -TOP_ORDER_KEYS.some((key) => currentKeyLower.includes(key));
    });

    const copyText = (): void => {
        const logs = sortedLogsEntries
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
        tryCopyToClipboard(logs, t(`${nameSpace}.logs`));
    };

    return (
        <div className={styles.container}>
            <ul className={styles.list}>
                {sortedLogsEntries.map(([key, value]) => (
                    <li className={styles['list-item']} key={key}>
                        {`${key}: ${value}`}
                    </li>
                ))}
            </ul>
            <Button
                type="link"
                onClick={copyText}
                className={styles['copy-button']}
            >
                <CopyOutlined />
            </Button>
        </div>
    );
};

export default DebugInfo;
