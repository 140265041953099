import React, { FC, useEffect, useState } from 'react';

import { Button, message } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import Modal, {
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
    ModalFooter,
    ModalHeader,
} from '@/components/Common/Modal';
import { useStores } from '@/components/hooks';
import { NewMember } from '@/stores/UsersListStore/interfaces';

import InvitingPage from './InvitingPage';
import SuccessPage from './SuccessPage';
import styles from './AddMemberModal.module.scss';

const nameSpace = 'userRoleEditor.addMembersModal';

const AddMembersModal: FC = observer(() => {
    const [userList, setUserList] = useState<NewMember[]>([]);
    const [successScreen, setSuccessScreen] = useState<boolean>(false);

    const { t } = useTranslation();

    const { usersListStore, userStore } = useStores();
    const {
        setAddMembersModal,
        addMembersModal,
        inviteMember,
        addMembersModalLoading,
        fetchUsersAndRoles,
    } = usersListStore;
    const { isAdmin, isUserSetUp } = userStore;

    const handleSubmit = async (): Promise<void> => {
        if (!(isAdmin && isUserSetUp)) {
            message.error(t(`${nameSpace}.cannotPerformOperation`));
            return;
        }
        const result = await inviteMember(userList);
        if (result) {
            setSuccessScreen(true);
            setUserList([]);
            fetchUsersAndRoles();
        } else {
            message.error(t(`${nameSpace}.error`));
        }
    };

    const closeModal = (): void => {
        setAddMembersModal(false);
        setSuccessScreen(false);
        setUserList([]);
    };

    const ableToAddUsers = userList.length || successScreen;

    return (
        <Modal
            loading={addMembersModalLoading}
            isOpen={addMembersModal}
            closeModal={closeModal}
            closable
            initStyle={{
                additionalWrapClassName: MODAL_DISABLE_MIN_HEIGHT_CLASS,
            }}
        >
            <>
                <ModalHeader>
                    <div className={styles.title}>
                        {t(`${nameSpace}.addToSpecterX`)}
                    </div>
                </ModalHeader>
                <div className={styles['invite-users-input']}>
                    {!successScreen
                        ? <InvitingPage userList={userList} setUserList={setUserList} />
                        : <SuccessPage />}
                </div>
                <ModalFooter>
                    <div className={styles['buttons-container']}>
                        {!successScreen && (
                            <Button
                                onClick={closeModal}
                                type="text"
                                className={styles.button}
                            >
                                {t('general.buttons.cancel')}
                            </Button>
                        )}
                        <Button
                            onClick={!successScreen ? handleSubmit : closeModal}
                            type="link"
                            className={styles.create}
                            disabled={!ableToAddUsers}
                        >
                            {t(`${nameSpace}.${successScreen ? 'thanks' : 'add'}`)}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
});

export default AddMembersModal;
