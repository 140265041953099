import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import ElasticFileName from '../../../Common/ElasticFileName';
import ShareLink from '../../ShareLink';
import ShareSuccessIcon from '../../../Common/StatusIcon/ShareSuccessIcon';
import { SHOW_COPY_LINKS, SHOW_TEXT_BOX_COPY_BUTTON } from '../../flags';
import styles from './ShareSuccess.module.scss';

interface ShareSuccessProps {
    filename: string;
    linkKey: string;
}

const ShareSuccess: FC<ShareSuccessProps> = ({ linkKey, filename }) => {
    const { t } = useTranslation();

    return (
        <div className={styles['share-success']}>
            <div className={styles['icon-wrapper']}>
                <ShareSuccessIcon />
            </div>
            <div className={styles.title}>
                {t('externalStorage.fileShared')}
            </div>
            <ElasticFileName textClassname={styles.subtitle} filename={filename} justify="center" />
            {SHOW_COPY_LINKS && (
                <div className={styles['share-link-wrapper']}>
                    <ShareLink linkKey={linkKey} showCopyButton={SHOW_TEXT_BOX_COPY_BUTTON} />
                </div>
            )}
        </div>
    );
};

export default memo(ShareSuccess);
