import React, { FC, memo } from 'react';

import i18n from '@/content';
import { FileNameCell } from '../../Columns';
import { useGlobalStylesToggle } from '../../../hooks';
import { DraggedFile } from '../interfaces';
import styles from './DragView.module.scss';

const nameSpace = 'filesTable.move';

interface DragViewProps {
    activeItem: DraggedFile;
    selectedFilesSet: ReadonlySet<string>;
}

const DragView: FC<DragViewProps> = ({ activeItem, selectedFilesSet }) => {
    const isMultiDrag: boolean = selectedFilesSet.has(activeItem.fid) && selectedFilesSet.size > 1;

    useGlobalStylesToggle(styles['global-cursor-grab']);

    return (
        <div className={styles.container}>
            {isMultiDrag
                ? i18n.t(`${nameSpace}.items`, { count: selectedFilesSet.size })
                : (
                    <FileNameCell
                        filename={activeItem.filename}
                        fileId={activeItem.fid}
                        isFolder={activeItem.isFolder}
                    />
                )}
        </div>
    );
};

export default memo(DragView);
