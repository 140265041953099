import AuthSettingsStore from '../../AuthSettingsStore';
import UserStore from '../../UserStore';
import {
    BASEURL,
    ENDPOINTS,
    CancellableAPI,
    CancellableCallResult,
} from '../../../api';
import { CheckAbilityToResult, SecuredFile, SimpleCallback } from '../../../types/types';
import {
    FilesListType,
    FilesRequestResult,
    Ordering,
    RefreshOptions,
    RefreshResult,
    RefreshActionResult,
} from '../interfaces';
import { checkIs404, eqUserIdentities, PermissionsError } from '../../../components/utils';
import { getFilesQuerystring, parseFolderId } from '../helpers';
import { IS_LOCAL_ENV } from '../../../config/env';
import {
    ACTIVE_REFRESH_INTERVAL_SECONDS_MS,
    PAGE_SIZE_DEFAULT,
    PASSIVE_REFRESH_INTERVAL_SECONDS_MS,
} from '../options';

const checkIsFolderRemoved = (error: unknown): boolean => error instanceof PermissionsError || checkIs404(error);

type FileDataRequestResult = CancellableCallResult<FilesRequestResult> | CancellableCallResult<SecuredFile>;

interface FilesAndFolderCancellableCallResult {
    isCanceled: boolean;
    isFolderRemoved: boolean;
    filesResult: FilesRequestResult | null;
    folder: SecuredFile | null;
}

interface FilesListRefresherConfig {
    filesListType: FilesListType;
    authSettingsStore: AuthSettingsStore;
    userStore: UserStore;
    onRefreshCompleted: (result: RefreshResult) => void | Promise<void>;
    onRefreshError: (isFolderRemoved: boolean, folderKey: string | null) => void | Promise<void>;
    disabled?: boolean;
}

interface PermissionsCheckResult {
    isForbidden: boolean;
    isCanceled: boolean;
}

class FilesListRefresher {
    private readonly APIInstance: CancellableAPI;

    private readonly filesListType: FilesListType;

    private readonly userStore: UserStore;

    private readonly onRefreshCompleted: (result: RefreshResult) => void | Promise<void>;

    private readonly onRefreshError: (isFolderRemoved: boolean, folderKey: string | null) => void | Promise<void>;

    private timeoutId: NodeJS.Timeout;

    private refreshInterval: number;

    private currentParentFolderKey: string;

    private currentSearchValue: string;

    private currentOrdering: Ordering;

    private currentFilesRequestKey: string;

    private currentFolderRequestKey: string;

    private disabled: boolean;

    private running: boolean;

    private fileOwner: string;

    // TODO: probably Symbol will be better here
    private enableKey: string;

    constructor({
        filesListType,
        authSettingsStore,
        userStore,
        onRefreshCompleted,
        onRefreshError,
        disabled = false,
    }: FilesListRefresherConfig) {
        this.APIInstance = new CancellableAPI(authSettingsStore, { isPeriodic: true });
        this.filesListType = filesListType;
        this.userStore = userStore;
        this.onRefreshCompleted = onRefreshCompleted;
        this.onRefreshError = onRefreshError;
        this.disabled = disabled;
        this.running = false;
        this.refreshInterval = PASSIVE_REFRESH_INTERVAL_SECONDS_MS;
    }

    get isDisabled(): boolean {
        return this.disabled;
    }

    get isRunning(): boolean {
        return this.running;
    }

    get timeout(): NodeJS.Timeout {
        return this.timeoutId;
    }

    enable(key: string): void {
        if (this.disabled) {
            if (this.enableKey && key === this.enableKey) {
                this.disabled = false;
                this.enableKey = null;
            } else if (IS_LOCAL_ENV) {
                console.warn(`Trying enable ${this.filesListType} refresher with incorrect key ${key}.
                    Current key is ${this.enableKey}`);
            }
        }
    }

    disable(key: string): void {
        if (!this.disabled) {
            this.disabled = true;
            this.enableKey = key;
        }
    }

    runTopOnlyRefresh(refreshOptions: RefreshOptions): void {
        if (!this.disabled) {
            this.onRefreshInit(refreshOptions);
        } else if (IS_LOCAL_ENV) {
            console.warn(`${this.filesListType} refresher is disabled. Could not runTopOnlyRefresh`);
        }
    }

    cancelRefresh(): void {
        clearTimeout(this.timeoutId);
        this.APIInstance.cancelRequest(this.currentFilesRequestKey);
        this.APIInstance.cancelRequest(this.currentFolderRequestKey);
    }

    setActiveMode(): void {
        this.refreshInterval = ACTIVE_REFRESH_INTERVAL_SECONDS_MS;
    }

    setPassiveMode(): void {
        this.refreshInterval = PASSIVE_REFRESH_INTERVAL_SECONDS_MS;
    }

    start(): void {
        if (!this.enableKey) {
            this.disabled = false;
        }
    }

    stop(): void {
        this.cancelRefresh();
        this.disabled = true;
        this.enableKey = null;
        this.timeoutId = null;
    }

    trySchedule(action: SimpleCallback, noDelay = false): void {
        if (!this.disabled) {
            if (noDelay) {
                action();
            } else {
                this.timeoutId = setTimeout(action, this.refreshInterval);
            }
        } else if (IS_LOCAL_ENV) {
            console.warn(`Could not schedule ${this.filesListType} refresh. Refresher is disabled`);
        }
    }

    private onFinish(callback: SimpleCallback): void {
        this.running = false;
        callback();
    }

    private handleRefreshResult(result: RefreshActionResult): void {
        const { files, pageToken, folder } = result;
        this.onFinish(() => this.onRefreshCompleted({
            files, pageToken, folder,
        }));
    }

    private async fetchFilesList(folderId: string): Promise<CancellableCallResult<FilesRequestResult>> {
        const {
            currentOrdering,
            currentSearchValue,
            filesListType,
            fileOwner,
        } = this;
        const queryString = getFilesQuerystring({
            filesListType,
            page_size: PAGE_SIZE_DEFAULT,
            parent_folder: folderId,
            email: fileOwner,
            search_string: currentSearchValue,
            ordering: currentOrdering,
        });
        this.currentFilesRequestKey = queryString;
        return this.APIInstance.get<FilesRequestResult>(
            BASEURL.backend(),
            ENDPOINTS.getFiles(queryString),
            this.currentFilesRequestKey,
        );
    }

    private async checkFolderPermissions(folderId: string): Promise<PermissionsCheckResult> {
        const { currentUserId } = this.userStore;
        const { result, isCanceled } = await this.APIInstance.get<CheckAbilityToResult>(
            BASEURL.backend(),
            ENDPOINTS.checkAbilityTo(folderId, currentUserId, 'read'),
            this.currentFolderRequestKey,
        );
        return {
            isCanceled,
            isForbidden: result?.allowed === false,
        };
    }

    private async fetchFolder(folderId: string): Promise<CancellableCallResult<SecuredFile>> {
        this.currentFolderRequestKey = this.currentParentFolderKey;
        const folderResult = await this.APIInstance.get<SecuredFile>(
            BASEURL.backend(),
            ENDPOINTS.getFileDetails(folderId),
            this.currentFolderRequestKey,
        );
        if (this.shouldCheckFolderPermissions(folderResult?.result)) {
            const { isCanceled, isForbidden } = await this.checkFolderPermissions(folderId);
            if (isCanceled) {
                return { isCanceled, result: null };
            }
            if (isForbidden) {
                return {
                    isCanceled,
                    result: null,
                    error: new PermissionsError(`User has no permissions for folder ${folderId}`),
                };
            }
        }
        return folderResult;
    }

    private async fetchFilesAndFolder(): Promise<FilesAndFolderCancellableCallResult> {
        const { currentParentFolderKey } = this;
        const folderId: string = parseFolderId(currentParentFolderKey);
        const pendingPromises: Promise<FileDataRequestResult>[] = [this.fetchFilesList(folderId)];
        if (currentParentFolderKey) {
            pendingPromises.push(this.fetchFolder(folderId));
        }
        const [filesData, folderData] = await Promise.all<FileDataRequestResult>(pendingPromises);
        const cancellableCallResult: FilesAndFolderCancellableCallResult = {
            isCanceled: filesData.isCanceled || folderData?.isCanceled,
            isFolderRemoved: false,
            filesResult: null,
            folder: null,
        };
        if (!cancellableCallResult.isCanceled) {
            const isFolderRemoved = folderData?.error
                ? checkIsFolderRemoved(folderData.error)
                : false;
            cancellableCallResult.isFolderRemoved = isFolderRemoved;
            if (!isFolderRemoved) {
                cancellableCallResult.filesResult = filesData.result as FilesRequestResult;
                cancellableCallResult.folder = folderData?.result as SecuredFile;
            }
        }
        return cancellableCallResult;
    }

    private async executeRefresh(): Promise<void> {
        const {
            isCanceled,
            filesResult,
            isFolderRemoved,
            folder,
        } = await this.fetchFilesAndFolder();
        if (!isCanceled) {
            const hasError = isFolderRemoved || !filesResult;
            if (hasError) {
                this.onFinish(() => this.onRefreshError(isFolderRemoved, this.currentParentFolderKey));
            } else {
                const {
                    items: files,
                    paginator: { page_token: pageToken },
                } = filesResult;
                this.handleRefreshResult({ files, folder, pageToken });
            }
        }
    }

    private onRefreshInit({
        parentFolderKey,
        fileOwner,
        searchValue,
        ordering,
    }: RefreshOptions): void {
        this.currentParentFolderKey = parentFolderKey;
        this.fileOwner = fileOwner;
        this.currentSearchValue = searchValue;
        this.currentOrdering = ordering;
        this.running = true;
        this.executeRefresh();
    }

    private shouldCheckFolderPermissions(folder: SecuredFile | null): boolean {
        const { currentUserIdentity } = this.userStore;
        return (!!folder
            && this.filesListType === 'sharedFiles'
            && !eqUserIdentities(currentUserIdentity, { id: folder.owner_id, email: folder.owner_email }));
    }
}

export default FilesListRefresher;
