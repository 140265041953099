import React, { FC } from 'react';

import { Button } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import i18n from '@/content';
import { useStores } from '../../../hooks';
import useOrderingHandler from './useOrderingHandler';
import { SortableFields } from '../../../../stores';
import styles from './SortableHeader.module.scss';

const nameSpace = 'filesTable.headers';

interface SortableHeaderProps {
    field: SortableFields;
}

const SortableHeader: FC<SortableHeaderProps> = observer(({ field }) => {
    const { filesListStore } = useStores();
    const { ordering, orderingFields, changeOrdering } = filesListStore;

    const isSortable = orderingFields.includes(field);
    const isCurrent = ordering?.orderBy === field;
    const isDescending = isCurrent ? ordering.isDescending : true;

    const wrapperClasses = classNames(styles['sort-button'], styles['sort-button-override'], {
        [styles.sortable]: isSortable,
        [styles.current]: isCurrent,
    });

    const orderingHandler = useOrderingHandler({ changeOrdering, isCurrentOrderBy: isCurrent });

    const onClick = (): void => {
        if (isSortable) {
            orderingHandler(field);
        }
    };

    const title = i18n.t(`${nameSpace}.${field}`);

    return (
        <Button
            type="link"
            size="small"
            className={wrapperClasses}
            onClick={onClick}
        >
            <span className={styles.label} title={title}>
                {title}
            </span>
            {isSortable && (
                isDescending ? <ArrowDownOutlined className={styles.label} /> : <ArrowUpOutlined className={styles.label} />
            )}
        </Button>
    );
});

export default SortableHeader;
