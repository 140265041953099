/*
* Object methods transforms everything to string, so it won't be safe to pass number keys here
* */
const invertDict = <Key extends string, Value extends string>(dict: Record<Key, Value>): Record<Value, Key> => {
    const entries: [Key, Value][] = Object.entries<Value>(dict) as [Key, Value][];
    return Object.fromEntries<Key>(
        entries.map<[Value, Key]>(([key, value]) => [value, key]),
    ) as Record<Value, Key>;
};

export default invertDict;
