import React, { FC, memo } from 'react';

import { Checkbox } from 'antd';
import './index.scss';

interface CheckAllProps {
    onChange: (value: boolean) => void;
    allLength: number;
    selectedLength: number;
}

const CheckAll: FC<CheckAllProps> = ({ allLength, selectedLength, onChange }) => (
    <Checkbox
        checked={!!allLength && allLength === selectedLength}
        disabled={!allLength}
        indeterminate={!!selectedLength && allLength > selectedLength}
        onChange={(event) => onChange(event.target.checked)}
        className="checkbox-cta"
    />
);

export default memo(CheckAll);
