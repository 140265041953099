import { useEffect } from 'react';

import { useNavigate } from 'react-router';

import { SharedFilesStore, UserStore } from '../../../stores';
import getPathToSharedFolder from './getPathToSharedFolder';

const usePossibleRedirectToFolder = (
    sharedFilesStore: SharedFilesStore,
    userStore: UserStore,
): void => {
    const navigate = useNavigate();

    const { filesPermissions, filesList } = sharedFilesStore;
    const possibleSingleFile = filesList.length === 1 ? filesList[0] : null;
    const hasPermissions = filesPermissions.get(possibleSingleFile?.file_id)?.summary === 'permissionsAdded';
    const isWorkspace = possibleSingleFile?.is_workspace || possibleSingleFile?.is_folder;
    const shouldMakeRedirect = isWorkspace && hasPermissions;

    useEffect(() => {
        if (shouldMakeRedirect) {
            const { currentUserIdentity } = userStore;
            const path = getPathToSharedFolder(currentUserIdentity, filesList[0]);
            navigate(path);
        }
    }, [shouldMakeRedirect]);
};

export default usePossibleRedirectToFolder;
