import React, { FC, memo } from 'react';

import { Form } from 'antd';

import { Input } from '@/components/Common/UIKit';
import { FormFields } from '@/stores/AuditsStore/interaces';
import i18n from '@/content';

import nameSpace from '../nameSpace';
import styles from '../AuditsFilters.module.scss';

type FilterType = FormFields.searchedUser | FormFields.searchedFilename;

const MAP_FILTER_TYPE_TO_PLACEHOLDER_KEY: Record<FilterType, string> = {
    [FormFields.searchedUser]: `${nameSpace}.user`,
    [FormFields.searchedFilename]: `${nameSpace}.fileName`,
};

interface InputFilterProps {
    filterType: FilterType;
}

const InputFilter: FC<InputFilterProps> = ({ filterType }) => (
    <Form.Item className={styles['form-item']} name={filterType}>
        <Input
            className={styles['search-input']}
            placeholder={i18n.t(MAP_FILTER_TYPE_TO_PLACEHOLDER_KEY[filterType])}
        />
    </Form.Item>
);

export default memo(InputFilter);
