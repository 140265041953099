import React, {
    FC,
    useCallback,
    useState,
} from 'react';

import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';

import { ChangePhone } from '../../../../Common/EditPhoneInput';
import { ShowPhoneButtonView } from './PhoneNumberContentViews/ShowPhoneButtonView';
import { LoadingPhoneView } from './PhoneNumberContentViews/LoadingPhoneView';
import type { PhoneIdentity } from '@/types/types';

import './antOverride.scss';
import PhoneInputEdit from '@/components/Common/PhoneInput/PhoneInputEdit';
import { PhoneNumberView } from './PhoneNumberContentViews/PhoneNumberView';

export type PhoneViewContent = 'loadingView' | 'phoneNumberView' | 'showButtonView' | 'editingPhoneView';

type PhoneViewType = Exclude<PhoneViewContent, 'loadingView'>;

const getPhoneViewContentByType = (
    isSearching: boolean,
    phoneViewType: PhoneViewType,
): PhoneViewContent => {
    let phoneViewContentType: PhoneViewContent = 'showButtonView';

    if (phoneViewType === 'phoneNumberView' && !isSearching) {
        phoneViewContentType = 'phoneNumberView';
    } else if (phoneViewType === 'editingPhoneView' && !isSearching) {
        phoneViewContentType = 'editingPhoneView';
    } else if (phoneViewType === 'phoneNumberView' && isSearching) {
        phoneViewContentType = 'loadingView';
    }

    return phoneViewContentType;
};

const START_INDEX = 0;
const MIDDLE_INDEX = 3;
const END_INDEX = 6;
const LAST_FOUR_DIGITS = 4;

export const maskPhoneNumber = ({ phone, prefix }: PhoneIdentity): PhoneIdentity => {
    const lastFourDigits: string = phone.slice(-LAST_FOUR_DIGITS);

    const maskedMiddleDigits: string = 'X'.repeat(phone.length - LAST_FOUR_DIGITS);

    const startDigits = maskedMiddleDigits.slice(START_INDEX, MIDDLE_INDEX);
    const middleDigits = maskedMiddleDigits.slice(MIDDLE_INDEX, END_INDEX);

    const phoneMask = `${startDigits}-${middleDigits}-${lastFourDigits}`.trim();

    const maskedPhone = {
        phone: phoneMask,
        prefix,
    };

    return maskedPhone;
};

interface PhoneNumberProps {
    email: string;
}

const PhoneNumber: FC<PhoneNumberProps> = observer(({ email }) => {
    const {
        usersPhonesStore: {
            changePhoneNumber,
            fetchUserPhone,
            getUserPhone,
        },
    } = useStores();

    const { isSearching, phonesVector: { currentPhone } } = getUserPhone(email);

    const [phoneViewType, setPhoneViewType] = useState<PhoneViewType>('showButtonView');

    const [maskedPhone, setMaskedPhone] = useState<PhoneIdentity>(null);

    const handlePhoneShowChange = async (): Promise<void> => {
        setPhoneViewType('phoneNumberView');
        await fetchUserPhone(email);
    };

    const onPhoneChange: ChangePhone = useCallback(async (phoneNumber) => {
        const payload = {
            email,
            ...phoneNumber,
        };
        const result = await changePhoneNumber(payload);
        if (result) {
            const maskedPhoneNumber = maskPhoneNumber(phoneNumber);
            setMaskedPhone(maskedPhoneNumber);
            setPhoneViewType('phoneNumberView');
        } else {
            setPhoneViewType('editingPhoneView');
        }
    }, [email]);

    const phoneContentViewType = getPhoneViewContentByType(isSearching, phoneViewType);
    const phone = maskedPhone || currentPhone;

    const phoneViewContent = {
        showButtonView: <ShowPhoneButtonView handlePhoneShowChange={handlePhoneShowChange} />,
        loadingView: <LoadingPhoneView />,
        phoneNumberView: <PhoneNumberView setEdit={setPhoneViewType} phoneNumber={phone} />,
        editingPhoneView: <PhoneInputEdit
            onClose={() => setPhoneViewType('phoneNumberView')}
            onSubmit={onPhoneChange}
            phone={currentPhone?.phone}
            prefix={currentPhone?.prefix}
        />,
    }[phoneContentViewType];

    return (
        <div className="phone-users-container">
            { phoneViewContent }
        </div>
    );
});

export default PhoneNumber;
