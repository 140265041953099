import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { FOLDER_ID_QUERY_PARAM, ROOT_FOLDER } from '@/consts';
import { FilesListType } from '../../../stores';
import { AppRoutes } from '../../../config/appRoutes';

const MAP_FILES_LIST_TYPE_TO_ROUTE: Record<FilesListType, AppRoutes> = {
    myFiles: AppRoutes.myFiles,
    sharedFiles: AppRoutes.sharedWithMe,
    allFiles: AppRoutes.allFiles,
};

const getRoute = (filesListType: FilesListType, key: string): string => {
    const page = MAP_FILES_LIST_TYPE_TO_ROUTE[filesListType];
    return key === ROOT_FOLDER ? page : `${page}?${FOLDER_ID_QUERY_PARAM}=${key}`;
};

interface FolderLinkProps {
    filesListType: FilesListType;
    folderKey: string;
    children: ReactElement;
    className?: string;
}

const FolderLink: FC<FolderLinkProps> = ({
    filesListType,
    folderKey,
    children,
    className,
}) => (
    <Link to={getRoute(filesListType, folderKey)} className={className} data-clickable>
        {children}
    </Link>
);

export default FolderLink;
