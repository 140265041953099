import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import { extract } from 'letterparser';

import type { FileAccessError } from '@/types/types';
import { OpenOptions } from '@/config/openOptions';
import {
    captureErrorForSentry,
    base64ArrayBuffer,
} from '@/components/utils';
import { REQUIRED_PHONE_VERIFICATION } from '@/consts';

import AuthSettingsStore from '../../AuthSettingsStore';
import FilesAccessStore, { AccessTypeState } from '../../FilesAccessStore';
import { SharedFilesStore } from '../SharedFilesStore';

interface AttachmentType {
    link: string;
    cid: string;
}

export interface LetterContent {
    readonly html?: string;
    readonly subject?: string;
    readonly plainText: string;
    readonly attachments?: AttachmentType[];
}

class EmailMessageStore {
    constructor(
        authSettingsStore: AuthSettingsStore,
        filesAccessStore: FilesAccessStore,
        sharedFilesStore: SharedFilesStore,
    ) {
        this.filesAccessStore = filesAccessStore;
        this.sharedFilesStore = sharedFilesStore;
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly filesAccessStore: FilesAccessStore;

    private readonly sharedFilesStore: SharedFilesStore;

    @observable
    isLoading = false;

    @observable
    private isDownloadFailed = false;

    @observable
    letterContent: LetterContent | null = null;

    @computed
    get errorType(): FileAccessError | undefined {
        return (
            this.isDownloadFailed
                ? 'serverError'
                : this.messageDownloadState?.errorType
        );
    }

    @computed
    get messageId(): string | undefined {
        return this.sharedFilesStore.linkData?.message_id;
    }

    @computed
    private get messageDownloadState(): AccessTypeState<OpenOptions.download> | undefined {
        return this.filesAccessStore.getAccessTypeState(this.messageId, OpenOptions.download);
    }

    @action
    private setIsLoading(value: boolean): void {
        this.isLoading = value;
    }

    @action
    private setIsDownloadFailed(value: boolean): void {
        this.isDownloadFailed = value;
    }

    @action
    private setMessageContent(content: LetterContent): void {
        this.letterContent = content;
    }

    tryFetchMessage = async ({
        code, throwError = false,
    }: { code?: string, throwError?: boolean }): Promise<void> => {
        runInAction(() => {
            this.isLoading = true;
            this.isDownloadFailed = false;
        });
        try {
            this.filesAccessStore.setSelectedAccessFileId(this.messageId);
            await this.filesAccessStore.tryFetchAccessLink({
                fileId: this.messageId,
                code,
                accessType: OpenOptions.download,
                throwError: true,
                alwaysCheckMFA: !!this.sharedFilesStore.allFilesPoliciesAggregate?.[REQUIRED_PHONE_VERIFICATION],
            });
            await this.processAccessLink();
        } catch (error) {
            captureErrorForSentry(error, 'EmailMessageStore.tryFetchMessage');
            if (!this.messageDownloadState?.errorType) {
                this.setIsDownloadFailed(true);
            }
            if (throwError) {
                throw error;
            }
        } finally {
            this.setIsLoading(false);
        }
    };

    private async processAccessLink(): Promise<void> {
        const { result: { link } } = this.messageDownloadState;
        const fetchResponse = await fetch(link);
        const letterContent = await fetchResponse.text();
        const {
            html, text, subject, attachments,
        } = extract(letterContent);

        const checkIsDataExistsFromExtract = (): boolean => {
            const isData: boolean = !!(html && text && subject && attachments);
            return isData;
        };
        const isData = checkIsDataExistsFromExtract();

        let allAttachments = [];
        if (attachments.length) {
            allAttachments = attachments.map(({ body, contentType, contentId }) => {
                const attachmentLink = base64ArrayBuffer(body);
                return {
                    link: `data:${contentType};base64,${attachmentLink}`,
                    cid: `cid:${contentId}`,
                };
            });
        }
        this.setMessageContent({
            html,
            subject,
            attachments: allAttachments,
            plainText: isData ? text : letterContent,
        });
    }
}

export default EmailMessageStore;
