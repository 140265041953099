import {
    FC,
    ReactNode,
    useCallback,
    useMemo,
} from 'react';

import { SyncCallback } from '@/types/types';
import { authorizedAction, useStores } from '@/components/hooks';
import { OpenOptions } from '@/config/openOptions';

import { UseVerifyActionResult } from './hooks/useVerifyAction/useVerifyAction';
import { AccessActionHandler } from './hooks/useVerifyAction/interfaces';
import { FilesActionsContext } from './hooks/useFilesActionContext';

interface UseFilesActionsArgs extends Pick<UseVerifyActionResult, 'setAction'> {
    openOTP: SyncCallback;
    children: ReactNode;
}

const FilesActionsProvider: FC<UseFilesActionsArgs> = ({
    openOTP,
    setAction,
    children,
}) => {
    const { userStore, filesAccessStore, recipientPageStore: { sharedFilesStore } } = useStores();

    const setSelectedFileVerifyAccessAction = (fileId: string, isFolder: boolean): void => {
        if (isFolder) {
            setAction(null, null, fileId);
        } else {
            setAction(
                async (code) => {
                    await filesAccessStore.tryFetchAccessLink({
                        fileId,
                        accessType: OpenOptions.viewer,
                        code,
                        throwError: true,
                    });
                },
                OpenOptions.viewer,
                fileId,
            );
        }
    };

    const actionHandler = useMemo<AccessActionHandler>(
        () => ({ action, verifyAction, payload }) => () => {
            setAction(verifyAction, payload?.accessType, payload?.fileId);
            authorizedAction(
                userStore,
                action,
                () => {
                    openOTP();
                },
            )();
        },
        [],
    );

    const selectFile = useCallback((fileId: string): void => {
        sharedFilesStore.setSelectedFile(fileId);
        filesAccessStore.setSelectedAccessFileId(fileId);
        if (fileId) {
            const isFolder = sharedFilesStore.selectedFile?.is_folder;
            setSelectedFileVerifyAccessAction(fileId, isFolder);
            if (!userStore.isUserSetUp) {
                openOTP();
            }
        }
    }, []);

    const value = useMemo(() => ({
        selectFile,
        setSelectedFileVerifyAccessAction,
        actionHandler,
    }), [selectFile, setSelectedFileVerifyAccessAction, actionHandler]);

    return (
        <FilesActionsContext.Provider value={value}>
            {children}
        </FilesActionsContext.Provider>
    );
};

export default FilesActionsProvider;
