import React, { FC, memo } from 'react';

import { Route, Routes } from 'react-router';

import GiveAccess from './GiveAccess';
import SharedWorkspace from './SharedWorkspace';
import RecipientPage from './RecipientPage';
import { PageLanguageContextProvider } from './PageLanguageContextProvider';
import appConfig from '../../config/env';
import { MiniAppRoutes } from '@/config/appRoutes';

interface MiniAppsProps {
    isRootLoading: boolean;
}

const MiniAppsContainer: FC<MiniAppsProps> = ({ isRootLoading }) => (
    <PageLanguageContextProvider>
        <Routes>
            <Route
                path={MiniAppRoutes.sharedFile}
                element={<RecipientPage isRootLoading={isRootLoading} />}
            />
            <Route
                path={MiniAppRoutes.giveAccess}
                element={<GiveAccess isRootLoading={isRootLoading} />}
            />
            {appConfig.ENABLE_WORKSPACE && (
                <Route
                    path={MiniAppRoutes.sharedWorkspace}
                    element={<SharedWorkspace isRootLoading={isRootLoading} />}

                />
            )}
        </Routes>
    </PageLanguageContextProvider>
);

export default memo(MiniAppsContainer);
