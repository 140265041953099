import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import { ErrorResult, getDefaultErrorResultProps } from '../../MiniApps/Common';
import ResultWithIcon from '../../Common/ResultWithIcon';
import type { EntityLoadErrorType } from '@/types/types';
import i18n from '@/content';
import { PUBLIC_ASSETS } from '@/consts';

import { ErrorTypes } from '../interfaces';
import { getErrorMessage } from '../helpers';
import styles from './ViewerErrorResult.module.scss';

interface ViewerErrorResultProps {
    errorType: ErrorTypes;
    ownerEmail: string;
    itemType: 'folder' | 'file';
}

const ViewerErrorResult: FC<ViewerErrorResultProps> = ({ errorType, ownerEmail, itemType }) => {
    const { t } = useTranslation();

    const errorTitle = (
        <span className={styles.error}>
            {getErrorMessage(errorType, i18n.t.bind(i18n))}
        </span>
    );
    const getErrorType = (error: ErrorTypes): EntityLoadErrorType => {
        switch (error) {
        case 'noPermissions':
            return error;
        case 404:
            return 'notFound';
        default:
            return 'serverError';
        }
    };

    const getRender = (): JSX.Element => {
        switch (errorType) {
        case 'mobileView':
            return (
                <div className={styles['mobile-view-result']}>
                    {errorTitle}
                    <img
                        className={styles['guide-image']}
                        src={`${PUBLIC_ASSETS}/images/click_on_desktop_site.png`}
                        alt="Screenshot of mobile browser"
                    />
                </div>
            );
        case 'noPermissions':
        case 404:
        case 500:
            return (
                <ErrorResult
                    {...getDefaultErrorResultProps(
                        t,
                        getErrorType(errorType),
                        `general.specterxCommon.${itemType}`,
                        ownerEmail,
                    )}
                />
            );
        default:
            return (
                <ResultWithIcon
                    iconSize="large"
                    status="error"
                    title={errorTitle}
                />
            );
        }
    };
    return (
        getRender()
    );
};

export default memo(ViewerErrorResult);
