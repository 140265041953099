import React, { FC, memo, useCallback } from 'react';

import { useStores } from '@/components/hooks';
import type { PolicySetting, PolicySettingValue, PolicySettingsGroup } from '@/types/types';
import i18n from '@/content';
import { DAYS_RETENTION_RANGE } from '@/stores/PolicyStore/dataRetention';

import PolicyTagInputRow from './PolicyTagInputRow';
import PolicyNumInputRow from './PolicyNumInputRow';
import PolicySwitchRow from './PolicySwitchRow';
import PolicyDropdownRow, { SUPPORTED_IDS_SET, SupportedDropdownIds } from './PolicyDropdownRow';
import {
    getSettingsContent,
    shouldShow,
} from './helpers';
import {
    MAP_POLICY_SETTING_ID_TO_TAG_INPUT_TYPE,
    MAP_UI_CHILD_TO_PARENT,
} from './constants';
import styles from './PolicySection.module.scss';

const nameSpace = 'policyEditor';

interface ValueChanges {
    value: PolicySettingValue;
}

interface PolicySectionProps {
    settingsGroupType: PolicySettingsGroup;
    policySettings: PolicySetting[];
}

const PolicySection: FC<PolicySectionProps> = ({
    settingsGroupType,
    policySettings = [],
}) => {
    const { settingsToDisplay, policySettingsDict } = getSettingsContent(policySettings);

    const { policyStore } = useStores();
    const editPolicy = useCallback((settingId: string, newValue: PolicySettingValue): void => {
        const settingChanges: ValueChanges = { value: newValue };
        policyStore.editPolicy(settingId, settingsGroupType, settingChanges);
    }, []);

    return (
        <div className={styles['section-container']}>
            <div className={styles['section-title-container']}>
                <span className={styles['section-text']}>
                    {i18n.t(`${nameSpace}.${settingsGroupType}.subTitle`)}
                </span>
            </div>
            <div className={styles['settings-list']}>
                {settingsToDisplay.map(({
                    id, public: isPublic, dependency, type, value, values,
                }) => {
                    if (isPublic) {
                        const dependencyId: string = dependency || MAP_UI_CHILD_TO_PARENT[id];
                        const show: boolean = shouldShow(policySettingsDict, dependencyId);
                        const isChild = !!dependencyId;

                        switch (type) {
                        case 'switch':
                            return show && (
                                <PolicySwitchRow
                                    key={id}
                                    isChild={isChild}
                                    settingId={id}
                                    settingValue={value as boolean}
                                    handleChange={editPolicy}
                                />
                            );
                        case 'input':
                            return show && (
                                <PolicyNumInputRow
                                    key={id}
                                    isChild={isChild}
                                    settingId={id}
                                    settingValue={value as number}
                                    handleChange={editPolicy}
                                    numRange={DAYS_RETENTION_RANGE}
                                />
                            );
                        case 'tag':
                        case 'list':
                            return show && (
                                <PolicyTagInputRow
                                    key={id}
                                    isChild={isChild}
                                    listType={MAP_POLICY_SETTING_ID_TO_TAG_INPUT_TYPE[id]}
                                    settingId={id}
                                    settingValue={value as string[]}
                                    handleChange={editPolicy}
                                />
                            );
                        case 'dropdown':
                            return show && SUPPORTED_IDS_SET.has(id as SupportedDropdownIds) && (
                                <PolicyDropdownRow
                                    values={values}
                                    key={id}
                                    isChild={isChild}
                                    settingId={id}
                                    settingValue={value as string}
                                    handleChange={editPolicy}
                                />
                            );
                        default:
                            return null;
                        }
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default memo(PolicySection);
