import { APIClass } from 'aws-amplify';

export enum BackendServices {
    access = '/access/',
    upload = '/upload/',
    admin = '/admin/',
    links = '/links-manager/',
    entities = '/entities/',
    audit = '/audit/',
    auth = '/auth/',
    appinfo = '/appinfo',
    organizations = '/organizations-manager/organizations/',
}

/*
* For most cases we need to add ext/ after the service name. It's necessary to authorization of WSO users
* These endpoints should have this signature - [{BackendServices}, {string}]
* If no need to resolve authorization, endpoint should be a {string}
* */
export type Endpoint = [BackendServices, string];
export type SPXEndpoint = Endpoint | string;

export interface RequestConfig extends Record<string, any> {
    headers?: Record<string, string>;
    body?: Record<string, any>;
    response?: boolean;
}

export type APIName = 'specterx' | 'localhost' | 'specterxProxy';

export type APIType = APIClass | AxiosAdapter;

export interface APIData {
    apiInstance: APIType;
    apiName: APIName;
    endpoint: string;
}

export type RequestMethod = 'post' | 'put' | 'patch' | 'get' | 'delete';

interface BaseAdapter<NameType, EndpointType> {
    get: (apiName: NameType, endpoint: EndpointType, init: RequestConfig) => Promise<any>;
    post: (apiName: NameType, endpoint: EndpointType, init: RequestConfig) => Promise<any>;
    put: (apiName: NameType, endpoint: EndpointType, init: RequestConfig) => Promise<any>;
    patch: (apiName: NameType, endpoint: EndpointType, init: RequestConfig) => Promise<any>;
    del: (apiName: NameType, endpoint: EndpointType, init: RequestConfig) => Promise<any>;
}

export type SPXAPI = BaseAdapter<APIName, SPXEndpoint>;

export type AxiosAdapter = BaseAdapter<string, string>;

export type BackendTypes = 'backend' | 'localhost' | 'backendProxy';

export interface AuthHeader {
    Authorization: string;
    SpecterxUserId: string;
}

/* eslint-disable camelcase */
export interface OrganizationIDHeader {
    OrganizationId: string;
}
