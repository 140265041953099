import React, { ChangeEvent, Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Button, message } from 'antd';

import { Input } from '@/components/Common/UIKit';
import { ModalFooter, ModalHeader } from '@/components/Common/Modal';
import { AuthSettingsStore, FilesListStore, WorkspacesStore } from '@/stores';
import type { ModalContentProps } from '@/components/Common/Modal';
import i18n from '@/content';
import { createFolder, createWorkspace } from '@/api';
import { validateFilename, captureUnexpectedNetworkError } from '@/components/utils';

import styles from './CreateFolderModalContent.module.scss';

interface CreateFolderModalProps extends ModalContentProps {
    closeModal: (folderCreated: boolean) => void;
    isWorkspace?: boolean;
    authSettingsStore?: AuthSettingsStore;
    filesListStore?: FilesListStore;
    workspacesStore?: WorkspacesStore;
}

interface CreateFolderModalState {
    folderName: string;
}

// TODO: use one modal instance in future. We need to pass onClose function in open action
@inject('authSettingsStore', 'filesListStore', 'workspacesStore')
@observer
export default class CreateFolderModalContent extends Component<CreateFolderModalProps, CreateFolderModalState> {
    private readonly nameSpace = 'createFolder';

    constructor(props: CreateFolderModalProps) {
        super(props);
        this.state = { folderName: '' };
    }

    componentDidMount(): void {
        const { isWorkspace } = this.props;
        if (isWorkspace) {
            this.setState({ folderName: 'Untitled workspace' });
        }
    }

    setFolderName = (folderName: string): void => {
        this.setState({ folderName });
    }

    private validateFolderName(name: string): boolean {
        let result = true;
        try {
            validateFilename(name, `${this.nameSpace}.messages.error`);
        } catch {
            result = false;
        }
        return result;
    }

    createFolder = async (): Promise<void> => {
        const {
            filesListStore,
            authSettingsStore,
            setLoadingStatus,
            setErrorStatus,
            isWorkspace,
            workspacesStore,
            closeModal,
        } = this.props;
        const { API } = authSettingsStore;
        const { folderName } = this.state;

        const nameSpace = `${this.nameSpace}.messages`;
        const creatingNewMessage = isWorkspace ? 'creatingNewWorkspace' : 'creatingNewFolder';
        const successMessage = isWorkspace ? 'createWorkspace' : 'createFolder';
        if (this.validateFolderName(folderName)) {
            setLoadingStatus(true, i18n.t(`${nameSpace}.wait.${creatingNewMessage}`));

            try {
                if (isWorkspace) {
                    const workspaceId = await createWorkspace(folderName, API);
                    workspacesStore.insertNewWorkspace(workspaceId, folderName);
                } else {
                    await createFolder({
                        folderName,
                        parentFolderId: filesListStore.getPossibleUploadFolderId(),
                    }, API);
                }
                setLoadingStatus(false);
                closeModal(true);
                message.success(i18n.t(`${nameSpace}.success.${successMessage}`, { folderName }));
            } catch (error) {
                console.log('Error!', error);
                captureUnexpectedNetworkError(error, 'CreateFolderModalContent.createFolder');
                setErrorStatus(true, i18n.t(`${nameSpace}.error.createFolder`, { folderName }));
            }
        }
    }

    onFolderNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setFolderName(event.target.value.trim());
    }

    render(): JSX.Element {
        const { nameSpace } = this;
        const { folderName } = this.state;
        const { isWorkspace, closeModal } = this.props;
        const confirmDisabled = !folderName.trim();

        return (
            <>
                <ModalHeader>
                    <h2 className={styles.title}>
                        {i18n.t(`${nameSpace}.${isWorkspace ? 'titleWorkspace' : 'title'}`)}
                    </h2>
                </ModalHeader>
                <div className={styles['folder-name-input']}>
                    <Input
                        autoFocus
                        onPressEnter={this.createFolder}
                        onChange={this.onFolderNameChange}
                        placeholder={i18n.t(`${nameSpace}.${isWorkspace ? 'untitledWorkspace' : 'untitledFolder'}`)}
                    />
                </div>
                <ModalFooter>
                    <div className={styles['buttons-container']}>
                        <Button
                            onClick={() => closeModal(true)}
                            type="text"
                            className={styles.button}
                        >
                            {i18n.t('general.buttons.cancel')}
                        </Button>
                        <Button
                            disabled={confirmDisabled}
                            onClick={this.createFolder}
                            type="link"
                            className={styles.create}
                        >
                            {i18n.t('general.buttons.create')}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        );
    }
}
