import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import {
    message, Typography,
} from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Input, Select } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import type { GroupMember } from '@/stores';
import i18n from '@/content';
import { filterByLabel } from '@/components/utils';

import GroupMemberLine from './GroupMemberLine';
import GroupButtons from '../GroupButtons';
import { getChangedUsers, getSelectOptions, userEntityToGroupMember } from './helpers';
import styles from './ConfigureGroup.module.scss';

const { Text } = Typography;

const nameSpace = 'customGroupsEditor';
const errorsNameSpace = `${nameSpace}.errors`;

const ConfigureGroup: FC = observer(() => {
    const [groupName, setGroupName] = useState<string>('');
    const [addedEmails, setAddedEmails] = useState<GroupMember[]>([]);

    const { usersListStore } = useStores();
    const {
        usersList,
        selectedGroupId,
        newGroupsMode,
        createUsersCustomGroup,
        userGroupsList,
        trySaveChangedUsersCustomGroup,
        setNewGroupsMode,
    } = usersListStore;

    const setGroupInitState = (): void => {
        const selectedGroup = userGroupsList.find((item) => item.groupId === selectedGroupId);
        if (selectedGroup) {
            const membersList = selectedGroup.members;
            const name = selectedGroup.groupName;
            setAddedEmails(membersList);
            setGroupName(name);
        }
        setNewGroupsMode(false);
    };

    const clear = (): void => {
        setGroupName('');
        setAddedEmails([]);
    };

    useEffect(() => {
        if (selectedGroupId && !newGroupsMode) {
            setGroupInitState();
        }
    }, [selectedGroupId]);

    useEffect(() => {
        if (newGroupsMode) {
            clear();
        }
    }, [newGroupsMode]);

    const changedUsers = getChangedUsers(
        userGroupsList,
        usersList,
        addedEmails,
        selectedGroupId,
    );

    const filteredOptions = getSelectOptions(usersList, addedEmails);

    const addUser = (id: string): void => {
        const addedUser = usersList.find((item) => item.user_id === id);
        if (addedUser) {
            setAddedEmails([userEntityToGroupMember(addedUser), ...addedEmails]);
        }
    };

    const onRemove = (id: string): void => {
        setAddedEmails(addedEmails.filter((item) => item.userId !== id));
    };

    const handleCreate = async (isDuplicate = false): Promise<void> => {
        if (!userGroupsList.find((item) => item.groupName === groupName)) {
            const name = isDuplicate ? `${groupName}(1)` : groupName;
            const isSucceed = await createUsersCustomGroup(name, addedEmails);
            if (isSucceed) {
                clear();
            } else {
                message.error(i18n.t(`${errorsNameSpace}.cannotCreate`));
            }
        } else {
            message.warning(i18n.t(`${errorsNameSpace}.notUniqueName`));
        }
    };

    const handleSave = async (): Promise<void> => {
        if (groupName) {
            const isSucceed = await trySaveChangedUsersCustomGroup(
                selectedGroupId,
                changedUsers,
                addedEmails,
                groupName,
            );
            if (!isSucceed) {
                message.error(i18n.t(`${errorsNameSpace}.cannotSave`));
            }
        }
    };

    const saveDisabled = !!changedUsers?.deleted.length || !!changedUsers?.added.length;

    return (
        <div className={styles['group-configure-container']}>
            <div className={styles['group-block']}>
                <Text className={classNames(styles['group-name-title'], styles['big-title'])}>
                    {i18n.t(`${nameSpace}.inputs.group`)}
                </Text>
                <div className={
                    classNames(
                        styles['group-name-input'],
                        {
                            [styles['group-name-input-new']]: newGroupsMode,
                        },
                    )
                }
                >
                    <Input
                        disabled={!newGroupsMode}
                        autoFocus
                        value={groupName}
                        onChange={(value) => setGroupName(value.target.value)}
                        placeholder={i18n.t(`${nameSpace}.inputs.namePlaceholder`)}
                    />
                </div>
            </div>
            {
                !newGroupsMode
                && (
                    <div className={styles['group-configure-disclaimer']}>
                        {i18n.t(`${nameSpace}.disclaimer`)}
                    </div>
                )
            }
            <div className={styles['group-block']}>
                <Text className={styles['group-name-title']}>{i18n.t(`${nameSpace}.inputs.members`)}</Text>
                <div className={styles['group-name-input']}>
                    <Select
                        onSelect={addUser}
                        style={{ width: '100%' }}
                        options={filteredOptions}
                        value={[]} // Need for correct work single select
                        filterOption={filterByLabel}
                        showSearch
                        className={styles['members-select']}
                        showArrow
                        placeholder={(
                            <span className={styles.placeholder}>
                                <SearchOutlined className="group-name-input-icon" />
                                {i18n.t(`${nameSpace}.inputs.search`)}
                            </span>
                        )}
                        suffixIcon={<CaretDownOutlined />}
                    />
                </div>
            </div>
            <div className={styles['member-list']}>
                {addedEmails?.map((item) => (
                    <GroupMemberLine
                        email={item.email}
                        displayName={item.displayName}
                        onClear={onRemove}
                        userId={item.userId}
                        key={item.userId}
                    />
                ))}
            </div>
            <GroupButtons
                onCreate={handleCreate}
                onSave={handleSave}
                onCancel={setGroupInitState}
                isCreateDisabled={!!groupName}
                isSaveDisabled={saveDisabled}
            />
        </div>
    );
});

export default ConfigureGroup;
