import React, {
    FC,
    memo,
    KeyboardEvent,
    MouseEvent,
    useEffect,
    useState,
} from 'react';

import { Button } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { HAS_CLIPBOARD } from '../../../../../config/browserEnv';
import { onSpacePress, tryCopyToClipboard } from '../../../../utils';
import styles from './LinkTextBox.module.scss';

const nameSpace = 'copyLink';

const selectElementContent = (element: HTMLElement): void => {
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
};

interface LinkTextBoxProps {
    link: string;
    showButton?: boolean;
}

const LinkTextBox: FC<LinkTextBoxProps> = ({ link, showButton = true }) => {
    const [copied, setCopied] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        setCopied(false);
    }, [link]);

    const onCopyClick = (): void => {
        tryCopyToClipboard(link, t(`${nameSpace}.link`));
        setCopied(true);
    };

    const onLinkClick = (event: MouseEvent<HTMLSpanElement>): void => {
        selectElementContent(event.target as HTMLSpanElement);
    };

    const onLinkKeyPress = (event: KeyboardEvent<HTMLSpanElement>): void => {
        onSpacePress<HTMLSpanElement>(event, () => selectElementContent(event.target as HTMLSpanElement));
    };

    const hasButton = HAS_CLIPBOARD && showButton;

    return (
        <div className={styles.wrapper}>
            <span
                role="button"
                className={styles.link}
                onClick={onLinkClick}
                onKeyUp={onLinkKeyPress}
                tabIndex={0}
            >
                {link}
            </span>
            {hasButton && (
                <Button
                    onClick={onCopyClick}
                    className={copied ? styles['copied-button'] : styles['copy-button']}
                    data-testid="whoHasAccess_copyLink"
                >
                    {!copied && <SnippetsOutlined />}
                    {t(`${nameSpace}.${copied ? 'textCopied' : 'copy'}`)}
                </Button>
            )}
        </div>
    );
};

export default memo(LinkTextBox);
