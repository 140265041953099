import { AuthFlowType } from './interfaces';

export const STEPS_COUNT_MIN = 2;

export const MAP_AUTH_TYPE_TO_PHONE_FIRST_STEP: Record<AuthFlowType, number> = {
    email: 1,
    emailAndPhone: 3,
    phoneAuth: 2,
    phoneMFA: 1,
};

export const EMAIL_AUTH_FLOWS: AuthFlowType[] = ['email', 'emailAndPhone'];
export const PHONE_AUTH_FLOWS: AuthFlowType[] = [
    'emailAndPhone',
    'phoneAuth',
    'phoneMFA',
];

export const nameSpace = 'recipientPage.otpAuth';
