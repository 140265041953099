import { MouseEvent, MutableRefObject, useState } from 'react';

import { SecuredFile } from '../../../../types/types';

export interface ContextMenuState {
    readonly x: number;
    readonly y: number;
    readonly visible: boolean;
    readonly selectedFile?: SecuredFile;
}

const CONTEXT_MENU_INIT: ContextMenuState = {
    x: 0,
    y: 0,
    visible: false,
};

interface UseContextMenuResult {
    contextMenuState: ContextMenuState;
    onContextMenu: (event: MouseEvent, selectedFile: SecuredFile) => void;
}

const useContextMenu = (isMountedRef: MutableRefObject<boolean>): UseContextMenuResult => {
    const [contextMenuState, setContextMenuState] = useState<ContextMenuState>(CONTEXT_MENU_INIT);

    const onClickOutside = (): void => {
        if (isMountedRef.current) {
            setContextMenuState(CONTEXT_MENU_INIT);
        }
        document.removeEventListener('click', onClickOutside);
    };

    const onContextMenu = (event: MouseEvent, selectedFile: SecuredFile): void => {
        const { menu } = selectedFile;
        if (menu && (menu.share || menu.options || menu.open_with)) {
            event.preventDefault();
            const { clientX, clientY } = event;
            setContextMenuState({
                visible: true, x: clientX, y: clientY, selectedFile,
            });
            if (!contextMenuState.visible) {
                document.addEventListener('click', onClickOutside);
            }
        }
    };

    return { contextMenuState, onContextMenu };
};

export default useContextMenu;
