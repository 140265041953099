import { FC } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './ButtonsGroup.module.scss';

interface ButtonsGroupProps {
    handleInfoModalOpen: () => void;
    shareFiles: () => void;
}

const ButtonsGroup: FC<ButtonsGroupProps> = ({ handleInfoModalOpen, shareFiles }) => (
    <>
        <Button
            type="text"
            size="middle"
            className={styles['close-button']}
            onClick={() => { window.location.href = 'https://drive.google.com/drive/'; }}
        >
            Close
        </Button>
        <div className={styles['share-button-container']}>
            <Button
                type="primary"
                size="large"
                className={styles['share-files-button']}
                onClick={shareFiles}
            >
                Share files
            </Button>
            <Button
                icon={<InfoCircleOutlined />}
                size="large"
                className={styles['info-button']}
                onClick={handleInfoModalOpen}
            />
        </div>
    </>
);

export default ButtonsGroup;
