import React, { FC, ReactNode } from 'react';

import { Progress } from 'antd';
import classNames from 'classnames';

import { GenericStatus } from '../Icons';
import styles from './ProgressBar.module.scss';
import './antOverride.scss';

const PERCENTS_FRACTION_DIGITS = 2;

export const formatPercent = (value: number): string => `${parseFloat(value.toFixed(PERCENTS_FRACTION_DIGITS))}%`;

type ContentPlacement = 'inline' | 'column';

type Color = 'primary' | 'cta';

interface ProgressBarProps {
    percent: number;
    className?: string;
    contentPlacement?: ContentPlacement;
    color?: Color;
    extra?: ReactNode;
    status?: GenericStatus;
    strokeWidth?: number;
}

const ProgressBar: FC<ProgressBarProps> = ({
    percent,
    contentPlacement = 'inline',
    className,
    color = 'primary',
    extra,
    status = 'loading',
    strokeWidth,
}) => {
    const wrapperClasses = classNames(styles.wrapper, styles[contentPlacement]);
    const progressClasses = classNames(
        'progress-bar',
        `progress-bar-${status}`,
        `progress-bar-${color}`,
        {
            [className]: !!className,
            'progress-bar-column': contentPlacement === 'column',
        },
    );

    return (
        <div className={wrapperClasses}>
            <Progress
                showInfo={false}
                type="line"
                className={progressClasses}
                percent={percent}
                size={['100%', strokeWidth]}
            />
            {extra}
        </div>
    );
};

export default ProgressBar;
