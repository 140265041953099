import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';

import { Policy, PolicySettingsAll, PolicySetting } from '../../../types/types';
import { PolicyChangeRequestBody } from './interfaces';
import { clearMultipleSpaces } from '../../utils';

type PolicyNameErrors = 'emptyName' | 'alreadyExists';

export const findPolicyNameErrors = (policyName: string, policyList: Policy[]): PolicyNameErrors => {
    const clearName = clearMultipleSpaces(policyName);
    if (!clearName) {
        return 'emptyName';
    }
    return policyList.find(({ title }) => clearMultipleSpaces(title) === clearName) ? 'alreadyExists' : null;
};

type SettingsList = PolicySetting[];

const flatSettings = (settings: PolicySettingsAll): SettingsList => Object.values(settings)
    .filter((setting) => !!setting).flat();

const getResultSettings = (
    storedSettings: PolicySettingsAll,
    stateSettings: PolicySettingsAll,
    isNewPolicy = false,
): SettingsList => {
    let result: SettingsList;
    const stateSettingsFlat: SettingsList = flatSettings(stateSettings);
    if (isNewPolicy) {
        result = stateSettingsFlat;
    } else {
        const storedSettingsFlat: SettingsList = flatSettings(storedSettings);
        result = differenceWith(stateSettingsFlat, storedSettingsFlat, isEqual);
    }
    return result;
};

export const checkSettingsDifference = (selectedPolicy: Policy, policyState: Policy): boolean => {
    const { settings: storedSettings } = selectedPolicy;
    const { settings: stateSettings } = policyState;
    const settingsDifference: SettingsList = getResultSettings(storedSettings, stateSettings);
    return !!settingsDifference.length;
};

export const getRequestBody = (
    selectedPolicy: Policy,
    changedPolicy: Policy,
    isNewPolicy: boolean,
): PolicyChangeRequestBody => {
    const { settings: storedSettings } = selectedPolicy;
    const { settings: stateSettings, title } = changedPolicy;
    return {
        title: clearMultipleSpaces(title),
        settings: getResultSettings(storedSettings, stateSettings, isNewPolicy),
    };
};

export const validateIntRange = (value: number, min: number, max: number): boolean => (
    value >= min && value <= max && Number.isInteger(value)
);
