import React, { FC, memo } from 'react';

import i18n from '@/content';

import styles from './AuthPageTitle.module.scss';

interface AuthPageTitleProps {
    titleKey: string;
}

const AuthPageTitle: FC<AuthPageTitleProps> = ({ titleKey }) => (
    <h1 className={styles.title}>
        {i18n.t(titleKey)}
    </h1>
);

export default memo(AuthPageTitle);
