import React, {
    FC, ReactElement, useRef, useState, useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import adjustCoords from './adjustCoords';
import styles from './ContextMenu.module.scss';

interface ContextMenuProps {
    x: number;
    y: number;
    visible: boolean;
    children: ReactElement;
}

const ContextMenu: FC<ContextMenuProps> = ({
    x, y, visible, children,
}) => {
    const [coords, setCoords] = useState<[number, number]>([x, y]);
    const menuWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (visible) {
            setCoords(adjustCoords(x, y, menuWrapperRef.current));
        }
    }, [x, y, visible]);

    if (visible) {
        return createPortal((
            <div
                ref={menuWrapperRef}
                className={styles['context-menu-wrapper']}
                style={{ left: `${coords[0]}px`, top: `${coords[1]}px` }}
            >
                {children}
            </div>
        ), document.body);
    }
    return <></>;
};

export default ContextMenu;
