import React, { FC } from 'react';

import { Row } from 'antd';
import { observer } from 'mobx-react';

import { useStores } from '../../../hooks';
import DomainsDoughnut, { ShareTypes } from './DomainsDoughnut';
import { getTopDomainsDataset } from './helpers';
import { RECORDS_LIMIT } from './chartSettings';

const nameSpace = 'domainsDashboard.chartNames';

const Charts: FC = observer(() => {
    const { sharingStatsStore: { sharingStats } } = useStores();

    if (!sharingStats?.domains.length) {
        return <></>;
    }

    const domainSharingDataset = getTopDomainsDataset(sharingStats.domains, RECORDS_LIMIT);

    return (
        <Row justify="space-around">
            <DomainsDoughnut
                dataset={domainSharingDataset}
                shareTypeKey={ShareTypes.numberOfSharesList}
                chartNameKey={`${nameSpace}.numberOfShares`}
            />
            <DomainsDoughnut
                dataset={domainSharingDataset}
                shareTypeKey={ShareTypes.numberOfFilesSharedList}
                chartNameKey={`${nameSpace}.numberOfFilesShared`}
            />
        </Row>
    );
});

export default Charts;
