import { getErrorResponse } from '../../../../components/utils';

const getDebugInfo = (error: unknown, otherData: Record<string, string>): Record<string, string> => {
    const result: Record<string, string> = Object.keys(otherData)
        .reduce<Record<string, string>>((acc, currentKey) => {
            if (otherData[currentKey]) {
                acc[currentKey] = otherData[currentKey];
            }
            return acc;
        }, {});
    if (error) {
        const { statusCode, message } = getErrorResponse(error);
        if (statusCode) {
            result['status code'] = String(statusCode);
        }
        if (message) {
            result.message = message;
        } else if ((error as Error)?.message) {
            const errorMessage = (error as Error).message;
            result.message = errorMessage.toLowerCase() === 'network error'
                ? 'No response data available. Possible CORS issue'
                : errorMessage;
        } else {
            result.message = String(error);
        }
    }

    return result;
};

export default getDebugInfo;
