import React, {
    FC,
    ReactElement,
    useState,
    useEffect,
} from 'react';

import { observer } from 'mobx-react';

import Modal from '../Modal';
import SendFiles from './index';
import { useStores } from '../../hooks';
import { captureErrorForSentry } from '../../utils';


interface SharedOwnersLoadersProps {
    renderButton: (onClick: () => void) => ReactElement;
    allowMultipleSharing?: boolean;
    sourceEmail?: string;
}

const SharedOwnersLoaders: FC<SharedOwnersLoadersProps> = observer( ({ renderButton, allowMultipleSharing, sourceEmail }) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [showShareBackButton, setShowShareBackButton] = useState(false);

    const { userStore: { isUserSetUp }, sharedUsersStore: { fetchOwners } } = useStores();

    useEffect(() => {
        if (isUserSetUp) {
            fetchOwners().then(() => {
                setShowShareBackButton(true);
            }).catch((error) => {
                captureErrorForSentry(error, 'FileOwnersLoader.fetchOwners');
            });
        }
    }, [isUserSetUp]);

    const openModal = (): void => {
        setIsModalVisible(true);
    };

    const closeModal = (): void => {
        setIsModalVisible(false);
    };

    return (
        <>
            {showShareBackButton && renderButton(openModal)}
            <Modal isOpen={isModalVisible} closeModal={closeModal}>
                <SendFiles closeModal={closeModal} allowMultipleSharing={allowMultipleSharing} sourceEmail={sourceEmail}/>
            </Modal>
        </>
    );
});

// TODO: add here HOC which wrap this component by <Can /> from @casl/react
export default SharedOwnersLoaders;
