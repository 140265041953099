import React, { FC } from 'react';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../hooks';
import styles from './OpenUploaderButton.module.scss';

const nameSpace = 'myFiles.header.buttons';

const OpenUploaderButton: FC = observer(() => {
    const { t } = useTranslation();
    const {
        appStore,
        filesListStore,
    } = useStores();

    const openUploader = (): void => {
        appStore.setFileUploaderState(filesListStore.getPossibleUploadFolderId() ? 'uploadOnly' : 'uploadAndShare');
    };

    return (
        <Button
            className={styles['open-button']}
            icon={<PlusOutlined className={styles.icon} />}
            onClick={() => openUploader()}
        >
            {t(`${nameSpace}.addFiles`)}
        </Button>
    );
});

export default OpenUploaderButton;
