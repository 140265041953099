import type { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';

import {
    ErrorResult, getDefaultErrorResultProps,
} from '../../../Common';
import SkeletonView from '../SkeletonView';
import LinkView from '../LinkView';

type ContentType = 'errorResult' | 'skeletonView' | 'linkView';

const getContentType = (errorType: string, showSkeleton: boolean): ContentType => {
    let contentType: ContentType = 'skeletonView';

    if (errorType !== null) {
        contentType = 'errorResult';
    } else if (!showSkeleton) {
        contentType = 'linkView';
    }

    return contentType;
};

interface RecipientPageContentProps {
    isLanguageDefined: boolean;
}

const RecipientPageContent: FC<RecipientPageContentProps> = observer(({ isLanguageDefined }) => {
    const { recipientPageStore } = useStores();
    const { t } = useTranslation();
    const { sharedFilesStore } = recipientPageStore;

    const {
        errorType,
        linkData,
        linkDebugInfo,
    } = sharedFilesStore;

    const showSkeleton = !isLanguageDefined;

    const contentType = getContentType(errorType, showSkeleton);

    return ({
        errorResult: <ErrorResult
            {...getDefaultErrorResultProps(
                t,
                errorType,
                'general.specterxCommon.link',
                linkData?.src_email,
                linkDebugInfo,
            )}
        />,
        skeletonView: <SkeletonView />,
        linkView: <LinkView />,
    }[contentType]);
});

export default RecipientPageContent;
