import { DeferredTask } from './interfaces';
import { AsyncCallback } from '../../../types/types';

const COMPLETE_TASK_DEBOUNCE_MS = 100;
const BROWSER_PARALLEL_REQUESTS_LIMIT = 6;

class RequestsQueue<T extends DeferredTask> {
    protected readonly activeLimit: number;

    protected readonly activeTasksMap: Map<string, T>;

    protected queue: T[] = [];

    private batchCompleteTimeoutId: NodeJS.Timeout;

    constructor(activeLimit: number = BROWSER_PARALLEL_REQUESTS_LIMIT) {
        this.activeLimit = activeLimit;
        this.activeTasksMap = new Map<string, T>();
    }

    enqueue(task: T): void {
        this.queue.push(task);
        this.tryNext();
    }

    enqueueAsync(taskPartial: Omit<T, 'start' | 'cancel'>): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.enqueue({
                ...taskPartial,
                start: resolve,
                cancel: reject,
            } as T);
        });
    }

    async processSimpleRequest(
        taskPartial: Omit<T, 'start' | 'cancel'>,
        action: AsyncCallback<boolean>,
    ): Promise<void> {
        await this.enqueueAsync(taskPartial);
        const isSuccess = await action();
        this.finish(taskPartial.id, isSuccess);
    }

    finish(taskId: string, succeed: boolean): void {
        const task: T = this.removeTask(taskId);
        if (!succeed) {
            task?.cancel();
        }
        if (this.batchCompleteTimeoutId) {
            clearTimeout(this.batchCompleteTimeoutId);
        }
        this.batchCompleteTimeoutId = setTimeout(() => this.tryNext(), COMPLETE_TASK_DEBOUNCE_MS);
    }

    clear(): void {
        this.activeTasksMap.forEach((task) => task.cancel());
        this.activeTasksMap.clear();
        this.queue.forEach((task) => task.cancel());
        this.queue = [];
    }

    protected getFreeSlotsCount(): number {
        return this.activeLimit - this.activeTasksMap.size;
    }

    private next(freeSlotsCount: number): void {
        const activeTasks: T[] = this.queue.splice(0, freeSlotsCount);
        activeTasks.forEach((task) => {
            task.start();
            this.activeTasksMap.set(task.id, task);
        });
    }

    private removeTask(taskId: string): T {
        const isActive = this.activeTasksMap.has(taskId);
        const task: T = isActive
            ? this.activeTasksMap.get(taskId)
            : this.queue.find(({ id }) => id === taskId);
        if (isActive) {
            this.activeTasksMap.delete(taskId);
        } else {
            this.queue = this.queue.filter(({ id }) => id !== taskId);
        }
        return task;
    }

    private tryNext(): void {
        const freeSlotsCount: number = this.getFreeSlotsCount();
        if (freeSlotsCount > 0) {
            this.next(freeSlotsCount);
        }
    }
}

export default RequestsQueue;
