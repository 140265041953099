import { useSize, UseSizeResult } from '../../hooks';

const TABLE_HEADER_OFFSET_PX = 80;
const EMPTY_TABLE_HEIGHT_PX = 300;
const TABLE_MIN_HEIGHT_PX = 400;

interface TableHeightResult extends Pick<UseSizeResult<HTMLDivElement>, 'containerRef'> {
    height: number;
}

const useTableHeight = (hasRecords: boolean): TableHeightResult => {
    const { containerRef, size } = useSize<HTMLDivElement>();
    return {
        containerRef,
        height: hasRecords
            // TODO: provide non-hardcoded size calculation
            ? Math.max(size.height - TABLE_HEADER_OFFSET_PX, TABLE_MIN_HEIGHT_PX)
            : EMPTY_TABLE_HEIGHT_PX,
    };
};

export default useTableHeight;
