import React, { FC, memo } from 'react';

import { Skeleton } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph';

import { FileAccessParams } from '../interfaces';
import style from './Description.module.scss';

type DescriptionProps = Partial<Omit<FileAccessParams, 'fileId'>>;

const SKELETON_SETTINGS: SkeletonParagraphProps = { rows: 1, width: '100%' };

const nameSpace = 'giveAccess.description';

const Description: FC<DescriptionProps> = ({ filename, subjectEmail }) => {
    const showSkeleton = !(subjectEmail && filename);
    const classes = classNames(style.description, { [style['has-skeleton']]: showSkeleton });
    const { t } = useTranslation();

    return (
        <div className={classes}>
            {showSkeleton
                ? <Skeleton title={false} active paragraph={SKELETON_SETTINGS} />
                : (
                    <Trans i18nKey={nameSpace} t={t} tOptions={{ filename, subjectEmail }}>
                        sharing file
                        <strong>filename</strong>
                        with
                        <strong>subjectEmail</strong>
                    </Trans>
                )}
        </div>
    );
};

export default memo(Description);
