import {
    AxiosRequestConfig,
    AxiosResponse,
    AxiosProgressEvent,
} from 'axios';
import type { RcFile } from 'antd/lib/upload/interface';

import { FULL_PERCENTS } from '@/consts';
import CancellableAPI, { CancellableCallResult } from '../../../api/CancellableAPI';
import { Counter } from '../../../components/utils';
import MultipartProgressManager from '../MultipartProgressManager';
import UploadProcessesMap from '../UploadProcessesMap';
import { BASEURL, ENDPOINTS, UploadAxiosInstance } from '../../../api';
import {
    ETagResponse,
    MultipartUpload,
    UploadedPart,
    UploadProgressControls,
    UploadPartURL,
} from '../interfaces';
import { uploadIdToRequestKey } from './common';

interface CompleteUploadingResponse {
    readonly status: boolean;
    readonly message: string;
}

interface BatchUploadParams {
    readonly uploadURLs: UploadPartURL[];
    readonly slicedFile: ArrayBuffer;
}

const calculateProgressPercent = (totalProgress: number, fileSize: number): number => (
    Math.floor(
        (totalProgress * FULL_PERCENTS) / fileSize,
    )
);

export const getUploadDetailsMultipart = async (
    API: CancellableAPI,
    file: RcFile,
    parentFolder: string,
): Promise<MultipartUpload> => {
    const fileInfo = {
        filename: file.name,
        fileSize: file.size,
        parent_folder: parentFolder || null,
    };
    const { result } = await API.post<MultipartUpload>(
        BASEURL.backend(),
        ENDPOINTS.getUploadDetailsMultipart(),
        uploadIdToRequestKey(file.uid),
        { body: fileInfo },
    );
    return result;
};

const getUploadURLs = (
    sliceSize: number,
    chunkSize: number,
    uid: string,
    uploadDetails: MultipartUpload,
    chunksCounter: Counter,
    API: CancellableAPI,
): Promise<CancellableCallResult<UploadPartURL>>[] => {
    const chunksCount = Math.ceil(sliceSize / chunkSize);
    const {
        bucket,
        key,
        upload_id: uploadId,
    } = uploadDetails;
    const urlsParts: Promise<CancellableCallResult<UploadPartURL>>[] = [];

    for (let index = 1; index <= chunksCount; index++) {
        chunksCounter.increment();
        const uploadPartNumber = chunksCounter.value;
        const uploadPartUrl = API.post<UploadPartURL>(
            BASEURL.backend(),
            ENDPOINTS.getFileUploadURL(encodeURI(uploadId), uploadPartNumber),
            `${uploadIdToRequestKey(uid)}-multipart-get-URL-${chunksCounter.value}`,
            { body: { key, bucket } },
        );

        urlsParts.push(uploadPartUrl);
    }

    return urlsParts;
};

const uploadChunksToS3 = async (
    chunkSize: number,
    { file, onProgress }: UploadProgressControls,
    slicedFile: ArrayBuffer,
    uploadURLs: UploadPartURL[],
    progressManager: MultipartProgressManager,
    uploadInstance: UploadAxiosInstance,
): Promise<ETagResponse[]> => {
    const chunksCount = Math.ceil(slicedFile.byteLength / chunkSize);
    const promisesArray: Promise<AxiosResponse>[] = [];

    for (let index = 1; index <= chunksCount; index++) {
        const start = (index - 1) * chunkSize;
        const end = index * chunkSize;
        const chunk: ArrayBuffer = (index < chunksCount) ? slicedFile.slice(start, end) : slicedFile.slice(start);

        const currentChunkIndex = index - 1;
        const { upload_part_url: uploadUrl } = uploadURLs[currentChunkIndex];

        const requestConfig: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                progressManager.updateProgress(currentChunkIndex, progressEvent.loaded);
                const percentCompleted = calculateProgressPercent(progressManager.totalProgress, file.size);
                onProgress({ percent: percentCompleted });
            },
        };
        promisesArray.push(uploadInstance.put(uploadUrl, chunk, file.uid, requestConfig));
    }
    const results = await Promise.all(promisesArray);
    return results.map<ETagResponse>((result) => ({ ETag: result.headers.etag.slice(1, -1) }));
};

const readFileSlice = (fileSlice: Blob): Promise<ArrayBuffer> => (
    new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const { result } = event.target;
            if (typeof result === 'string') {
                reject(TypeError('File reader result should not be string'));
            } else {
                resolve(result);
            }
        };
        fileReader.readAsArrayBuffer(fileSlice);
    })
);

const prepareBatchUploading = async (
    cutSize: number,
    chunkSize: number,
    alreadyRead: number,
    chunksCounter: Counter,
    file: RcFile,
    uploadDetails: MultipartUpload,
    API: CancellableAPI,
): Promise<BatchUploadParams> => {
    const currentSlice: Blob = file.slice(alreadyRead, alreadyRead + cutSize);
    const urlPartsArray = await Promise.all(
        getUploadURLs(currentSlice.size, chunkSize, file.uid, uploadDetails, chunksCounter, API),
    );
    const slicedFile: ArrayBuffer = await readFileSlice(currentSlice);
    return {
        uploadURLs: urlPartsArray.map(({ result }) => result),
        slicedFile,
    };
};

const trySaveEntityTags = (uid: string, newTags: ETagResponse[], processesMap: UploadProcessesMap): void => {
    const process = processesMap.get(uid);
    const currentEntityTags = process?.progressSnapshot?.multiPart?.entityTags;
    if (currentEntityTags) {
        processesMap.updateProgressSnapshot(uid, {
            multiPart: {
                ...process.progressSnapshot.multiPart,
                entityTags: [...currentEntityTags, ...newTags],
            },
        });
    } else {
        console.warn(`Not entity tags for file ${uid}`);
    }
};

/* eslint-disable no-await-in-loop */
export const multipartUploadWorker = async (
    parallelUploadsCount: number,
    chunkSize: number,
    { file, onProgress }: UploadProgressControls,
    uploadDetails: MultipartUpload,
    API: CancellableAPI,
    uploadInstance: UploadAxiosInstance,
    processesMap: UploadProcessesMap,
): Promise<ETagResponse[]> => {
    const { progressSnapshot: { multiPart } } = processesMap.get(file.uid);
    const { entityTags } = multiPart;
    const cutSize = chunkSize * parallelUploadsCount;
    const uploadedBytes = entityTags.length * chunkSize;
    const chunksCounter = new Counter(entityTags.length);
    const progressManager = new MultipartProgressManager(parallelUploadsCount, uploadedBytes);
    const uploadResults: ETagResponse[] = [...entityTags];
    if (entityTags.length) {
        onProgress({ percent: calculateProgressPercent(progressManager.totalProgress, file.size) });
    }

    for (let alreadyRead = uploadedBytes; alreadyRead < file.size; alreadyRead += cutSize) {
        const { uploadURLs, slicedFile } = await prepareBatchUploading(
            cutSize,
            chunkSize,
            alreadyRead,
            chunksCounter,
            file,
            uploadDetails,
            API,
        );
        const uploadResultsPartial = await uploadChunksToS3(
            chunkSize,
            { file, onProgress },
            slicedFile,
            uploadURLs,
            progressManager,
            uploadInstance,
        );
        uploadResults.push(...uploadResultsPartial);
        trySaveEntityTags(file.uid, uploadResultsPartial, processesMap);
        progressManager.onChunksLoaded();
    }

    return uploadResults;
};

export const completeMultipartUploading = async (
    uploadResults: ETagResponse[],
    uploadDetails: MultipartUpload,
    uid: string,
    API: CancellableAPI,
): Promise<void> => {
    const {
        key,
        bucket,
        upload_id: uploadId,
    } = uploadDetails;

    const uploadedParts: UploadedPart[] = uploadResults.map(({ ETag }, index) => ({
        ETag,
        PartNumber: index + 1,
    }));

    const requestBody = {
        key,
        bucket,
        parts: uploadedParts,
    };

    const { result: { status, message: responseMassage } } = await API.post<CompleteUploadingResponse>(
        BASEURL.backend(),
        ENDPOINTS.completeMultipartFiles(uploadId),
        uploadIdToRequestKey(uid),
        { body: requestBody },
    );

    if (!status) {
        throw new Error(responseMassage);
    }
};
