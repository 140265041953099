import React, { FC, memo } from 'react';

import type { UploadFileStatus } from 'antd/lib/upload/interface';

import { StatusIcon } from '@/components/Common/Icons';
import { MAP_UPLOAD_STATUS_TO_GENERIC_STATUS } from '@/stores';
import styles from './UploadStatusIcon.module.scss';

interface PrefixIconProps {
    status: UploadFileStatus;
}

const UploadStatusIcon: FC<PrefixIconProps> = ({ status }) => (
    <StatusIcon
        className={styles['status-icon']}
        status={MAP_UPLOAD_STATUS_TO_GENERIC_STATUS[status]}
    />
);

export default memo(UploadStatusIcon);
