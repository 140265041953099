import React, { FC, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import Spinner from '../../Common/Spin';
import TabTitle from '../../Common/TabTitle';
import { CodeAuthenticator } from '../../Common/CodeAuthenticator';
import OTPEmailInput from '../../Common/OTPEmailInput';
import { BaseAuthLink } from '../AuthLink';
import {
    EMAIL_OTP_LENGTH,
    getEmail,
    RESEND_TIMEOUT,
    useEmailOTPFlow,
    useStores,
} from '../../hooks';
import styles from './OTPSignIn.module.scss';

const nameSpace = 'OTPSignIn';

const OTPSignIn: FC = observer(() => {
    const [isEmailAuthSucceed, setIsEmailAuthSucceed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const { authSettingsStore, userStore } = useStores();

    const {
        checkAuthCode, isMountedRef, sendEmailAuthCode, onSendEmailError,
        email, onChangeEmail, secondStepTitle, onEmailRetryLimitReached,
        emailAuthFirstStepMessages, resendTimerRef,
    } = useEmailOTPFlow(t, authSettingsStore, userStore);

    const initEmailInputValue = useMemo(() => ({ email: '' }), []);

    const pageTitle = t(`${nameSpace}.title`);

    const startLoading = (): void => {
        setIsLoading(true);
    };

    const stopLoading = (): void => {
        setIsLoading(false);
    };

    const onAuthSuccess = (): void => {
        if (isMountedRef.current) {
            setIsEmailAuthSucceed(true);
        }
    };

    const checkCode = async (code: string): Promise<void> => {
        await checkAuthCode(code, startLoading, stopLoading, onAuthSuccess);
    };

    return (
        <div className={styles['otp-sign-in-wrapper']}>
            <Spinner spinning={isLoading} tip={t('general.waitMessages.checkingCode')}>
                <TabTitle title={pageTitle} />
                <h1 className={styles.title}>{pageTitle}</h1>
                <CodeAuthenticator<string>
                    ref={resendTimerRef}
                    firstStepTitle={t(`${nameSpace}.firstStep.title`)}
                    firstStepMessages={emailAuthFirstStepMessages}
                    identityType="email"
                    initIdentityValues={initEmailInputValue}
                    getIdentity={getEmail}
                    sendCode={sendEmailAuthCode}
                    onSendCodeError={onSendEmailError}
                    secondStepTitle={secondStepTitle}
                    codeLength={EMAIL_OTP_LENGTH}
                    resendTimeout={RESEND_TIMEOUT}
                    checkCode={checkCode}
                    onRetryLimitReached={onEmailRetryLimitReached}
                    authSucceed={isEmailAuthSucceed}
                    render={(forceDisabled) => (
                        <OTPEmailInput forceDisabled={forceDisabled} email={email} onChange={onChangeEmail} />
                    )}
                />
                <div className={styles['backward-container']}>
                    <BaseAuthLink href="/forgotPassword">
                        {t(`${nameSpace}.toPrevStep`)}
                    </BaseAuthLink>
                </div>
            </Spinner>
        </div>
    );
});

export default OTPSignIn;
