import React, { ReactElement } from 'react';

import { PREVENT_DOWNLOAD_UNPROTECTED, PROTECT_ON_DOWNLOAD } from '@/consts';
import ProtectedFilesDescription from './ProtectedFilesDescription';

interface NotificationProps {
    titleKey: string;
    descriptionKey: string;
    activationFlag: boolean;
}

interface ExtraSettingContent {
    notification?: NotificationProps;
    bottomDescription?: ReactElement;
}

export const EXTRA_SETTINGS_CONTENT: Record<string, ExtraSettingContent> = {
    [PROTECT_ON_DOWNLOAD]: {
        notification: {
            titleKey: 'policyEditor.protectOnDownload.notification.title',
            descriptionKey: 'policyEditor.protectOnDownload.notification.description',
            activationFlag: true,
        },
    },
    [PREVENT_DOWNLOAD_UNPROTECTED]: {
        bottomDescription: <ProtectedFilesDescription />,
    },
};

export const NOTIFICATION_DURATION = 10;
