import CancellableAPI from '../CancellableAPI';
import { BASEURL, ENDPOINTS } from '../endpoints';
import {
    APIName,
    Endpoint,
    RequestConfig,
    SPXAPI,
} from '../interfaces';

/* eslint-disable camelcase */
interface CreateFolderResponse {
    folder_id: string;
}
/* eslint-enable camelcase */

interface CreateFolderPayload {
    folderName: string;
    parentFolderId?: string;
    isWorkspace?: boolean;
}

const getRequestParams = ({
    folderName,
    parentFolderId = null,
    isWorkspace = false,
}: CreateFolderPayload): [APIName, Endpoint, RequestConfig] => ([
    BASEURL.backend(),
    ENDPOINTS.createFolder(),
    {
        body: {
            filename: folderName,
            parent_folder: parentFolderId,
            is_workspace: isWorkspace,
        },
    },
]);

export const createFolder = async (payload: CreateFolderPayload, API: SPXAPI): Promise<string> => {
    const { folder_id: folderId }: CreateFolderResponse = await API.post(
        ...getRequestParams(payload),
    );
    return folderId;
};

export const createWorkspace = async (workspaceName: string, API: SPXAPI): Promise<string> => createFolder(
    {
        folderName: workspaceName,
        parentFolderId: null,
        isWorkspace: true,
    },
    API,
);

export const createFolderCancellable = async (
    payload: CreateFolderPayload,
    requestKey: string,
    API: CancellableAPI,
): Promise<string> => {
    const [apiName, endpoint, config] = getRequestParams(payload);
    const { result } = await API.post<CreateFolderResponse>(
        apiName,
        endpoint,
        requestKey,
        config,
    );
    return result.folder_id;
};
