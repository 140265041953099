import React, { FC } from 'react';

import { Result } from 'antd';
import classNames from 'classnames';

import { AntResultProps } from './helpers';
import styles from './ErrorResult.module.scss';

const ErrorResult: FC<AntResultProps> = ({
    title,
    subTitle,
    extra,
    ...rest
}) => (
    <Result
        className={classNames(styles.result, styles['error-result'])}
        title={<div className={styles.title} data-test-id="recipientPage_errorTitle">{title}</div>}
        subTitle={subTitle && <div className={styles.subtitle}>{subTitle}</div>}
        extra={extra && <div className={styles.extra}>{extra}</div>}
        {...rest}
    />
);

export default ErrorResult;
