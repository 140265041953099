import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Timer from '../../Timer';
import { TargetButton } from '../interfaces';
import styles from './SendButtons.module.scss';

interface SendButtonsProps {
    generalDisabled: boolean;
    isLoading: boolean;
    showTimeout: boolean;
    resendRestricted: boolean;
    resentTimeoutValue: number;
    hasSuccessSending: boolean;
    targetButton: TargetButton;
    onClick: () => void | Promise<void>;
    onTimeout: () => void;
}

const nameSpace = 'codeAuth.buttons';

const getFirstButtonTitle = (hasSuccessSending: boolean): 'codeSent' | 'send' => (
    hasSuccessSending ? 'codeSent' : 'send'
);

const SendButtons: FC<SendButtonsProps> = ({
    isLoading,
    showTimeout,
    generalDisabled,
    resendRestricted,
    resentTimeoutValue,
    hasSuccessSending,
    targetButton,
    onClick,
    onTimeout,
}) => {
    const { t, i18n } = useTranslation();

    const defaultDisabled = isLoading || generalDisabled;
    const isSendButtonTarget = targetButton === 'send';

    const sendButtonLoading = isLoading && isSendButtonTarget;
    const resendButtonLoading = isLoading && !isSendButtonTarget;

    const sendButtonTitle = sendButtonLoading ? 'sending' : getFirstButtonTitle(hasSuccessSending);
    const resendButtonTitle = resendButtonLoading ? 'sending' : 'resend';

    const disableSendCodeButton = sendButtonLoading ? false : defaultDisabled || !isSendButtonTarget;
    const disableResendCodeButton = resendButtonLoading ? false : (
        defaultDisabled
        || isSendButtonTarget
        || resendRestricted
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles['send-buttons-container']}>
                <Button
                    htmlType={isSendButtonTarget ? 'submit' : 'button'}
                    disabled={disableSendCodeButton}
                    className={styles['send-button']}
                    onClick={onClick}
                    loading={sendButtonLoading}
                >
                    {t(`${nameSpace}.${sendButtonTitle}`)}
                </Button>
                <Button
                    htmlType={!isSendButtonTarget ? 'submit' : 'button'}
                    className={styles['resend-button']}
                    disabled={disableResendCodeButton}
                    loading={resendButtonLoading}
                    onClick={onClick}
                >
                    {t(`${nameSpace}.${resendButtonTitle}`)}
                </Button>
            </div>
            {showTimeout && (
                <Timer
                    className={classNames(styles.timer, styles[i18n.dir()])}
                    countdownSeconds={resentTimeoutValue}
                    onTimeout={onTimeout}
                    prefix={t(`${nameSpace}.youCanResendIn`)}
                />
            )}
        </div>
    );
};

export default memo(SendButtons);
