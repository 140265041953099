import { FC, useRef } from 'react';

import { Button, Form, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import PhoneComp, { phoneNameSpace } from '../PhoneComp';
import { PhoneIdentity } from '@/types/types';
import { PHONE_REGEXP } from '../../../regExp';
import styles from './EditPhoneInput.module.scss';

const { useForm } = Form;

export type ChangePhone = (phone: PhoneIdentity) => void;

export interface ChangePhoneProps {
    changePhone?: ChangePhone;
}

interface EditPhoneInputProps extends ChangePhoneProps, PhoneIdentity {
    onSubmit?: (value?: PhoneIdentity) => void;
    onClose?: (initValue?: PhoneIdentity) => void;
}

const EditPhoneInput: FC<EditPhoneInputProps> = ({
    changePhone,
    phone,
    prefix,
    onSubmit,
    onClose,
}) => {
    const initialValuesRef = useRef<PhoneIdentity>({ prefix, phone });
    const [form] = useForm();
    const { t } = useTranslation();

    const onChange = (): void => {
        const { phone: formPhone, prefix: formPrefix } = form.getFieldsValue(['phone', 'prefix']);
        const newPrefix = formPrefix?.prefix as string || '';
        changePhone?.({ phone: formPhone, prefix: newPrefix });
    };

    const checkIsFormValid = (): boolean => {
        const formPhone = form.getFieldValue('phone');
        return PHONE_REGEXP.test(formPhone);
    };

    const handleSubmit = (): void => {
        if (checkIsFormValid()) {
            const { phone: formPhone, prefix: formPrefix } = form.getFieldsValue(['phone', 'prefix']);
            const newPrefix = formPrefix?.prefix as string || '';
            onSubmit({ phone: formPhone, prefix: newPrefix });
        } else {
            message.error(t(`${phoneNameSpace}.invalidPhone`));
        }
    };

    const cancelEditing = (): void => {
        onClose?.(initialValuesRef.current);
    };
    return (
        <Form
            form={form}
            onValuesChange={onChange}
            initialValues={{ phone }}
            onFinish={handleSubmit}
            className={styles['add-phone-form']}
        >
            <PhoneComp UIType="Share" initPrefix={prefix} />
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="link"
                        className={styles['submit-button']}
                        htmlType="submit"
                        disabled={!checkIsFormValid()}
                    >
                        <CheckOutlined className={styles.icon} />
                    </Button>
                )}
            </Form.Item>
            {onClose
                && (
                    <Button
                        type="link"
                        onClick={cancelEditing}
                        className={styles['close-button']}
                    >
                        <CloseOutlined className={styles.icon} />
                    </Button>
                )}
        </Form>
    );
};

export default EditPhoneInput;
