import React, {
    FC,
    useEffect,
    useState,
} from 'react';

import { Button, message } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { PUBLIC_ASSETS } from '@/consts';
import Spinner from '../../Common/Spin';
import FilesUploaderDragger from '../../Common/FilesUploaderDragger';
import { UploadingPopup } from '../../Common/OverflowWidget';
import SuccessScreen from './SuccessScreen/SuccessScreen';
import MessageTextBlock from './MessageTextBlock';
import { PublicFooter } from '../../Common/PublicComponents';
import {
    captureSharedFileFlowError,
    checkIsFileMetaCompleted,
    handleNotFound,
    Header,
    OTPAuth,
    ErrorResult,
    tryFetchActualPolicy,
    useRecipientEmail,
    usePolicySettings,
    getPolicyLanguage,
    MAIN_SPINNER_STYLE,
    useURLLinkId,
    useLanguageDefinition,
    getDefaultErrorResultProps,
} from '../Common';
import { useMounted, useStateWithRef, useStores } from '../../hooks';
import { CheckCodeError } from '../../Common/CodeAuthenticator';
import {
    EntityLoadErrorType,
    FileMetaLegacy,
    FileDetailsPublic,
    LinkDetails,
    Policy,
} from '@/types/types';
import {
    FileDetails,
    FileSharingParams,
} from './interfaces';
import config from '../../../config/env';
import configureAmplify from '../../../config/amplify';
import { BASEURL, ENDPOINTS, addPermissionsToFile } from '../../../api';
import { getDebugInfo } from '../../../stores';
import { checkIsMFAFailedError, checkIsMFARequiredError } from '../../utils';
import { errorToErrorType, validateAccess, verifyAccess } from './helpers';
import './index.scss';

interface SharedWorkspaceProps {
    isRootLoading: boolean;
}

const nameSpace = 'recipientPage.workspace';

const { DEFAULT_LANGUAGE } = config;

const SharedWorkspace: FC<SharedWorkspaceProps> = observer(({
    isRootLoading,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<EntityLoadErrorType>(null);
    const [showOTP, setShowOTP] = useState<boolean>(false);
    const [errorObject, setErrorObject] = useState<unknown>(null);

    const {
        authSettingsStore, uploadFilesStore, userStore,
    } = useStores();

    const { recpEmail, setInitRecipientEmail } = useRecipientEmail(userStore);

    const {
        policyAggregate,
        setPolicy,
    } = usePolicySettings(null);

    const { t } = useTranslation();
    const { isLanguageDefined, defineLanguage } = useLanguageDefinition();

    const { API, IS_WSO, skipMFA } = authSettingsStore;
    const { uploadingPopupOpened, setUploadingPopupOpened } = uploadFilesStore;
    const { takeWorkspaceFilesOwnership } = uploadFilesStore;

    const [
        fileSharingParams,
        setFileSharingParams,
        fileSharingParamsRef,
    ] = useStateWithRef<FileSharingParams>(null);

    const isMountedRef = useMounted();

    useEffect(() => {
        if (!IS_WSO) {
            configureAmplify();
        }
        return () => {
            uploadFilesStore.clear();
            setUploadingPopupOpened(false);
        };
    }, []);

    useURLLinkId((linkId) => setFileSharingParams({ linkId }));

    const isReadyToLoadData = !!fileSharingParams && !isRootLoading;

    const getLinkDetails = async (linkId: string): Promise<LinkDetails> => {
        if (!linkId) {
            return null;
        }
        const getLinkCallback = async (): Promise<LinkDetails> => (
            API.get(BASEURL.backend(), ENDPOINTS.getLinkDetails(linkId), {})
        );
        return handleNotFound<LinkDetails>(getLinkCallback);
    };

    const tryFetchPolicySettings = async (fileDetails: FileDetails, fileId: string): Promise<void> => {
        const filePolicy: Policy = await tryFetchActualPolicy(API, fileId, fileDetails.policy_id);
        setPolicy(filePolicy);
        if (filePolicy && !isLanguageDefined) {
            const language = getPolicyLanguage(filePolicy) || DEFAULT_LANGUAGE;
            defineLanguage(language);
        }
    };

    const getFileDetails = async (
        fileMetadata: FileMetaLegacy,
        fileId: string,
    ): Promise<FileDetails> => {
        if (checkIsFileMetaCompleted(fileMetadata)) {
            return fileMetadata;
        }
        const apiCall = async (): Promise<FileDetailsPublic> => API.get(
            BASEURL.backend(),
            ENDPOINTS.getFileDetailsPublic(fileId),
            {},
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fid, ...rest } = await handleNotFound<FileDetailsPublic>(apiCall);
        return { ...rest };
    };

    const fetchPageData = async (): Promise<void> => {
        const { linkId } = fileSharingParams;
        try {
            const linkDetails: LinkDetails = await getLinkDetails(linkId);
            setInitRecipientEmail(linkDetails?.recp_email);
            const fileId = linkDetails?.file_id;
            const srcEmail = linkDetails?.src_email;
            const fileDetails: FileDetails = await getFileDetails(linkDetails?.file_meta, fileId);
            await tryFetchPolicySettings(fileDetails, fileId);
            setFileSharingParams((currentState) => ({ ...currentState, fileId, srcEmail }));
            if (userStore.isUserSetUp) {
                await validateAccess(
                    API,
                    {
                        linkId,
                        fileId,
                        email: userStore.currentUserEmail,
                    },
                    setErrorObject,
                );
            } else {
                setShowOTP(true);
            }
        } catch (error) {
            console.log('error is', error);
            if (isMountedRef.current) {
                if (!isLanguageDefined) {
                    defineLanguage();
                }
                if (!skipMFA && checkIsMFARequiredError(error)) {
                    setShowOTP(true);
                } else {
                    captureSharedFileFlowError(error, 'SharedWorkspace.fetchPageData');
                    setErrorType(errorToErrorType(error));
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (isReadyToLoadData) {
            fetchPageData();
        }
    }, [isReadyToLoadData]);

    const closeOTP = (): void => {
        setShowOTP(false);
    };

    const onOTPSuccess = async (): Promise<void> => {
        closeOTP();
        setIsLoading(true);
        try {
            const { fileId, linkId } = fileSharingParamsRef.current;
            await addPermissionsToFile(API, { fileId, linkId, email: userStore.currentUserEmail }, setErrorObject);
        } catch (error) {
            setErrorType(errorToErrorType(error));
        }
        setIsLoading(false);
    };

    const onClickShare = async (): Promise<void> => {
        const { successfullyUploadedItems, isUploading } = uploadFilesStore;
        if (successfullyUploadedItems.length && !isUploading) {
            setIsLoading(true);
            const success = await takeWorkspaceFilesOwnership(fileSharingParamsRef.current.fileId);
            if (success) {
                setUploadingPopupOpened(false);
                setIsSuccess(true);
            } else {
                message.error(t('general.specterxCommon.couldNotShareTryAgain'));
            }
        } else {
            message.info(isUploading ? t('shareBlockers.isUploading') : t('shareBlockers.noFiles'));
        }
        setIsLoading(false);
    };

    const sourceEmail = fileSharingParams?.srcEmail || '';

    const verifyAccessInMFAFlow = async (code: string): Promise<void> => {
        try {
            await verifyAccess(API, fileSharingParamsRef.current.fileId, code);
        } catch (error) {
            if (checkIsMFAFailedError(error)) {
                throw new CheckCodeError('Code check failed');
            } else {
                throw error;
            }
        }
    };

    return (
        <Spinner
            size="large"
            style={MAIN_SPINNER_STYLE}
            spinning={isLoading}
        >
            <Header showSkeleton={!isLanguageDefined} />
            {errorType ? (
                <ErrorResult
                    {...getDefaultErrorResultProps(
                        t,
                        errorType,
                        'general.specterxCommon.workspace',
                        sourceEmail,
                        getDebugInfo(errorObject, {
                            linkId: fileSharingParams?.linkId,
                            workspaceId: fileSharingParams?.fileId,
                            email: userStore?.currentUserEmail,
                            userId: userStore?.currentUserId,
                        }),
                    )}
                />
            )
                : (
                    <>
                        {userStore.isUserSetUp && !showOTP && (
                            <div className="workspace-content-wrapper">
                                <img
                                    className="workspace-img"
                                    src={`${PUBLIC_ASSETS}/images/shared-workspace.svg`}
                                    alt="Shared workspace"
                                />
                                {!isSuccess ? (
                                    <>
                                        <div className="title">
                                            {t(`${nameSpace}.shareFilesWith`, { ownerEmail: sourceEmail })}
                                        </div>
                                        <MessageTextBlock source={sourceEmail} />
                                        <div className="files-uploader-workspace-wrapper">
                                            <FilesUploaderDragger
                                                drawerState="upload"
                                                isSidebar={false}
                                                sharedWorkspace
                                                folderId={fileSharingParams?.fileId}
                                                directory={false}
                                            />
                                        </div>
                                        <Button className="share-securely-button" onClick={onClickShare}>
                                            <img
                                                src={`${PUBLIC_ASSETS}/company_logo/specterx-icon.png`}
                                                alt="Share Securely"
                                                width={30}
                                            />
                                            <div>{t(`${nameSpace}.shareSecurely`)}</div>
                                        </Button>
                                    </>
                                ) : (<SuccessScreen sourceEmail={sourceEmail} />)}
                            </div>
                        )}
                        {showOTP
                            && (
                                <div className="otp-auth-container">
                                    <OTPAuth
                                        recpEmail={recpEmail}
                                        OTPType="workspace"
                                        setIsLoading={setIsLoading}
                                        closeOTP={closeOTP}
                                        onSuccess={onOTPSuccess}
                                        policyAggregate={policyAggregate}
                                        openFile={verifyAccessInMFAFlow}
                                        onMFASuccess={closeOTP}
                                        hasBackButton={false}
                                    />
                                </div>
                            )}
                    </>
                )}
            <PublicFooter showSkeleton={!isLanguageDefined} />
            {uploadingPopupOpened && <UploadingPopup />}
        </Spinner>
    );
});

export default SharedWorkspace;
