interface Subscriber<T> {
    resolve: (value: T) => void;
    reject: (error: unknown) => void;
}

interface Result<T> {
    value?: T;
    error?: unknown;
}

class SubscribablePromise<T> {
    private subscribers: Subscriber<T>[] = [];

    call(promiseCreator: () => Promise<T>): Promise<T> {
        if (!this.subscribers.length) {
            promiseCreator()
                .then((value) => this.onFinish({ value }))
                .catch((error) => this.onFinish({ error }));
        }
        return new Promise<T>((resolve, reject) => {
            this.subscribers.push({ resolve, reject });
        });
    }

    private onFinish({ value, error }: Result<T>): void {
        this.subscribers.forEach(({ resolve, reject }) => {
            if (!error) {
                resolve(value);
            } else {
                reject(error);
            }
        });
        this.subscribers = [];
    }
}

export default SubscribablePromise;
