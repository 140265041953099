import type { FC } from 'react';
import { memo } from 'react';

import { Form } from 'antd';

import { Select } from '@/components/Common/UIKit';
import i18n from '@/content';
import type { DropdownItem } from '@/types/types';
import { Operations, FormFields } from '@/stores/AuditsStore/interaces';
import { filterByLabel } from '@/components/utils';

import filtersNameSpace from '../nameSpace';
import styles from '../AuditsFilters.module.scss';

const SELECT_STYLE = { width: 250 };

const nameSpace = 'auditLogs.operationTypes';

const SELECT_VALUES: string[] = ['', ...Object.values(Operations)];

const SelectOperation: FC = () => {
    const selectOptions: DropdownItem[] = SELECT_VALUES
        .map<DropdownItem>((value) => ({ value, label: i18n.t(`${nameSpace}.${value || 'all'}`) }))
        .sort((current, prev) => {
            const currentLabel = current.label;
            const prevLabel = prev.label;
            return currentLabel > prevLabel ? 1 : -1;
        });

    return (
        <Form.Item className={styles['form-item']} name={FormFields.selectedOperation}>
            <Select<Operations | ''>
                showSearch
                placeholder={i18n.t(`${filtersNameSpace}.operation`)}
                optionFilterProp="children"
                style={SELECT_STYLE}
                options={selectOptions}
                filterOption={filterByLabel}
            />
        </Form.Item>
    );
};

export default memo(SelectOperation);
