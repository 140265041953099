import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import classNames from 'classnames';

import LinkTextBox from './LinkTextBox';
import CopyLinkButton from './CopyLinkButton';
import DotsLoading from '../../DotsLoading';
import { useStores } from '../../../hooks';
import {
    BaseCopyLinkProps,
    LinkLoadState,
    getLinkLoadState,
} from '../common';
import styles from './CopyLinkWithStateIndicator.module.scss';

const nameSpace = 'copyLink';

export type UIMode = 'textBox' | 'button';

interface CopyLinkWithStateIndicatorProps extends BaseCopyLinkProps {
    UIMode: UIMode;
    showButton?: boolean;
}

const CopyLinkWithStateIndicator: FC<CopyLinkWithStateIndicatorProps> = ({
    link,
    isLinkLoading,
    linkKey,
    showButton,
    UIMode,
}) => {
    const { sharedUsersStore } = useStores();

    const linkLoadState: LinkLoadState = getLinkLoadState({ link, isLinkLoading });
    const hasError = linkLoadState === 'error';
    const isLoading = linkLoadState === 'loading';

    const isButtonType = UIMode === 'button';

    const loadStateClasses = classNames(styles['load-state'], {
        [styles['has-error']]: hasError,
    });

    const { t } = useTranslation();

    const showMessage = linkLoadState !== 'success';

    return (
        <div className={styles.container}>
            {showMessage && (
                <div className={loadStateClasses}>
                    {t(`${nameSpace}.loadState.${linkLoadState}`)}
                    {isLoading && <DotsLoading />}
                    {hasError && (
                        <Button
                            type="link"
                            onClick={() => sharedUsersStore.forceRequestLink(linkKey)}
                            className={styles['try-again']}
                        >
                            {t('general.buttons.tryAgain')}
                        </Button>
                    )}
                </div>
            )}
            {link && (isButtonType ? (
                <CopyLinkButton link={link} />
            ) : (
                <LinkTextBox link={link} showButton={showButton} />
            ))}
        </div>
    );
};

export default memo(CopyLinkWithStateIndicator);
