import type { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';

import { HTMLEmailWrapper } from './HTMLEmailWrapper';
import styles from './EmailViewer.module.scss';

const EmailViewer: FC = observer(() => {
    const {
        recipientPageStore: {
            emailMessageStore: {
                letterContent: { html, plainText, attachments },
            },
        },
    } = useStores();

    return (
        <div className={styles.container}>
            {html
                ? <HTMLEmailWrapper html={html} plainText={plainText} attachments={attachments} />
                : <span className={styles['plain-text']}>{plainText}</span>}
        </div>
    );
});

export default EmailViewer;
