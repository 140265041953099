import { ITimeoutCleaner } from './interfaces';

class TimeoutCleaner implements ITimeoutCleaner {
    private cleaned: boolean;

    private readonly onClear: () => void;

    constructor(clear: () => void) {
        this.onClear = clear;
        this.cleaned = false;
    }

    get isCleaned(): boolean {
        return this.cleaned;
    }

    recharge(): void {
        this.cleaned = false;
    }

    clear(): void {
        if (!this.cleaned) {
            this.cleaned = true;
            this.onClear();
        }
    }
}

export default TimeoutCleaner;
