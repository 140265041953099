import React, { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';
import { EmailMessageStore } from '@/stores/RecipientPageStore/EmailMessageStore';
import { FileAccessError } from '@/types/types';

import { DefaultMessage } from './DefaultMessage';
import { EmailViewer } from './EmailViewer';
import { ErrorView } from './ErrorView';
import { MessagePreview } from './MessagePreview';

type ContentType = 'error' | 'preview' | 'defaultMessage' | 'customMessage';

const CRITICAL_ERRORS: FileAccessError[] = ['permissionCheckFail', 'noPermissions', 'notFound'];

const getContentType = (emailMessageStore: EmailMessageStore): ContentType => {
    if (!emailMessageStore.messageId) {
        return 'defaultMessage';
    }
    if (!emailMessageStore.letterContent) {
        const { errorType } = emailMessageStore;
        return CRITICAL_ERRORS.includes(errorType) ? 'error' : 'preview';
    }
    return 'customMessage';
};

const MessageView: FC = observer(() => {
    const { recipientPageStore: { emailMessageStore } } = useStores();
    const contentType = getContentType(emailMessageStore);

    return ({
        customMessage: <EmailViewer />,
        defaultMessage: <DefaultMessage />,
        error: <ErrorView />,
        preview: <MessagePreview />,
    }[contentType]);
});

export default MessageView;
