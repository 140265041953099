import React, { FC, memo } from 'react';

import i18n from '@/content';
import BaseAuthLink from './BaseAuthLink';
import DoubleColorMessage from './DoubleColorMessage';

type PageType = 'signIn' | 'signUp';

interface AuthToggleLinkProps {
    pageType: PageType;
}

interface LinkContent {
    route: string;
    grayMessagePartKey: string;
    otherMessagePartKey: string;
}

const MAP_PAGE_TYPE_TO_LINK_CONTENT: Record<PageType, LinkContent> = {
    signIn: {
        route: '/signUp',
        grayMessagePartKey: 'signIn.dontHaveAnAccount',
        otherMessagePartKey: 'signIn.signUpHere',
    },
    signUp: {
        route: '/signIn',
        grayMessagePartKey: 'signUp.alreadyHaveAnAccount',
        otherMessagePartKey: 'signUp.signInHere',
    },
};

const AuthToggleLink: FC<AuthToggleLinkProps> = ({ pageType }) => {
    const { route, grayMessagePartKey, otherMessagePartKey } = MAP_PAGE_TYPE_TO_LINK_CONTENT[pageType];
    return (
        <BaseAuthLink href={route}>
            <DoubleColorMessage
                coloredPart={i18n.t(grayMessagePartKey)}
                commonPart={i18n.t(otherMessagePartKey)}
            />
        </BaseAuthLink>
    );
};

export default memo(AuthToggleLink);
