import React, { ReactElement } from 'react';

import {
    AndroidOutlined,
    AppleOutlined,
    ChromeOutlined,
    WindowsOutlined,
} from '@ant-design/icons';

import { LinuxOutlined } from '../../../Common/Icons';

const OS_ICONS: Record<string, ReactElement> = {
    android: <AndroidOutlined />,
    'android-x86': <AndroidOutlined />,
    centos: <LinuxOutlined />,
    'chromium os': <ChromeOutlined />,
    debian: <LinuxOutlined />,
    ios: <AppleOutlined />,
    linux: <LinuxOutlined />,
    'mac os': <AppleOutlined />,
    mint: <LinuxOutlined />,
    ubuntu: <LinuxOutlined />,
    windows: <WindowsOutlined />,
    'windows mobile': <WindowsOutlined />,
    'windows phone': <WindowsOutlined />,
};

export default OS_ICONS;
