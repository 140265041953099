import { message } from 'antd';
import type { ArgsProps } from 'antd/lib/message';
import { TFunction } from 'i18next';

import UsersPhonesStore from '../../stores/UsersPhonesStore/UsersPhonesStore';
import UploadFilesStore from '../../stores/UploadFilesStore';
import WorkspacesStore from '../../stores/WorkspacesStore';
import SharedUsersStore, { RECIPIENTS_HINTS_LIST } from '../../stores/SharedUsersStore';
import { RecipientPhoneInfo, SharingBlockersTypes, SimpleCallback } from '@/types/types';
import { RecipientsShareHints } from '@/stores/SharedUsersStore/interfaces';
import findEmailInString from './findEmailInString';
import { PHONE_REGEXP } from '../../regExp';

export type ShareBlocker = Record<SharingBlockersTypes, boolean>;

export const BLOCKER_INFO_CONFIG: Pick<ArgsProps, 'duration' | 'type'> = {
    duration: 10,
    type: 'info',
};

const checkInvalidPhone = (phonesList: RecipientPhoneInfo[]): boolean => phonesList.some(({ phonesVector }) => {
    const currentPhone = phonesVector?.currentPhone;
    const initSnapshot = phonesVector?.initSnapshot;
    const isNewPhoneValid = PHONE_REGEXP.test(currentPhone?.phone || '');
    if (currentPhone && initSnapshot) {
        const initNumberStr = `${initSnapshot.phone}${initSnapshot.prefix}`;
        const newNumberStr = `${currentPhone.phone}${currentPhone.prefix}`;
        return initNumberStr !== newNumberStr && !isNewPhoneValid;
    }
    return !isNewPhoneValid;
});

interface Stores {
    sharedUsersStore: SharedUsersStore;
    uploadFilesStore?: UploadFilesStore;
    workspacesStore?: WorkspacesStore;
    usersPhonesStore: UsersPhonesStore;
}

const getShareBlockers = (
    hasPolicyWithPhoneOnly: boolean,
    {
        sharedUsersStore,
        uploadFilesStore,
        workspacesStore,
        usersPhonesStore,
    }: Stores,
): ShareBlocker => {
    const {
        recipientsList, currentInputEmail,
    } = sharedUsersStore;
    const { hasMissingNumber, hasSearchingNumber, usersPhonesMap } = usersPhonesStore;
    const isInputNotEmpty = !!currentInputEmail;
    const emailsArray = findEmailInString(currentInputEmail);
    return {
        missingRecipient: isInputNotEmpty && !!emailsArray,
        missingRecipientInvalid: isInputNotEmpty && !emailsArray,
        isUploading: uploadFilesStore?.isUploading ?? false,
        missingPhoneNumber: hasPolicyWithPhoneOnly && hasMissingNumber,
        searchPhoneNumber: hasPolicyWithPhoneOnly && hasSearchingNumber,
        noWorkspaces: workspacesStore?.noWorkspace || false,
        noFiles: uploadFilesStore?.noFiles || false,
        noRecipients: !recipientsList.length,
        invalidNumber: hasPolicyWithPhoneOnly && !hasMissingNumber && checkInvalidPhone([...usersPhonesMap.values()]),
    };
};

const getFirstBlocker = (
    hasPolicyWithPhoneOnly: boolean,
    stores: Stores,
): SharingBlockersTypes => {
    const shareBlockers: ShareBlocker = getShareBlockers(hasPolicyWithPhoneOnly, stores);
    const firstBlockerEntry = <[SharingBlockersTypes, boolean]>Object.entries(shareBlockers).find(([, value]) => value);
    return firstBlockerEntry ? firstBlockerEntry[0] : null;
};

export const onFinishSharingFlow = (
    t: TFunction,
    goNext: SimpleCallback,
    hasPolicyWithPhoneOnly: boolean,
    stores: Stores,
): void => {
    const firstBlocker = getFirstBlocker(hasPolicyWithPhoneOnly, stores);
    const isFlowUncompleted = !!firstBlocker;

    message.destroy();
    if (isFlowUncompleted) {
        message.open({
            content: t(`shareBlockers.${firstBlocker}`),
            ...BLOCKER_INFO_CONFIG,
        });
        if (RECIPIENTS_HINTS_LIST.includes(firstBlocker as RecipientsShareHints)) {
            stores.sharedUsersStore.clearShareHint();
            /*
            * Adding share hint into next tick is necessary to run new animation while previous not finished.
            * */
            setTimeout(
                () => stores.sharedUsersStore.addShareHint(firstBlocker as RecipientsShareHints),
                0,
            );
        }
    } else {
        goNext();
    }
};
