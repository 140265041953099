import React, { FC } from 'react';
import styles from './PhoneNumberContainer.module.scss';

interface PhoneViewContainerProps {
  children?: React.ReactNode;
}
const PhoneNumberViewContainer: FC<PhoneViewContainerProps> = ({ children }) => (

    <div className={styles['view-container']}>
        {children}
    </div>
);

export default PhoneNumberViewContainer;
