import {
    FC,
} from 'react';

import { observer } from 'mobx-react';

import useTextOverflowing from '@/components/hooks/useTextOverflowing';

import { FileIcon } from '@/components/Common/Icons';
import ColoredTooltip from '@/components/Common/ColoredTooltip';

import '../index.scss';

interface FileNameViewProps {
    filename: string;
}

const FileNameView: FC<FileNameViewProps> = ({ filename }) => {
    const [isTextOverflowing, textContainerRef] = useTextOverflowing(filename);

    return (
        <div className="filename-container">
            <FileIcon className="file-icon" filename={filename} isFolder={false} />
            {isTextOverflowing ? (
                <ColoredTooltip
                    title={filename}
                    placement="top"
                >
                    <span ref={textContainerRef} className="filename">
                        {filename}
                    </span>
                </ColoredTooltip>
            ) : (
                <span className="filename" ref={textContainerRef}>{filename}</span>
            )}
            {/* <CopyLinkButton
                className="copy-button"
                link={link}
                isLinkLoading={isLinkLoading}
                linkKey={EMPTY_RECIPIENT}
            /> */}
        </div>
    );
};

export default observer(FileNameView);
