import { Message, MessageListener, TabsMessenger } from './TabsMessenger';
import { SyncCallback } from '../../../types/types';

type TabMessagesTopics = 'auth';

const instance = new TabsMessenger<TabMessagesTopics>(['auth']);

export const getInstance = (): TabsMessenger<TabMessagesTopics> => instance;

export type TopicSubscriber<Type extends string, Payload = void> = (
    listener: MessageListener<Type, Payload>,
) => SyncCallback;

export type TopicEmitter<Type extends string, Payload = void> = (
    message: Message<Type, Payload>,
) => void;
