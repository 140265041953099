import { AxiosError } from 'axios';

import { getErrorResponse, sleep } from './index';

const DEFAULT_DOWNLOAD_RETRY_LIMIT = 5;
const MAX_RETRY_LIMIT = 30;

const AJAX_DONE = 4;
const RESPONSE_FAILED = 0;

const DELAY_BETWEEN_RETRIES = 4_000;

const validatePositiveNum = (num: number, maxLimit = Infinity): boolean => typeof num === 'number'
    && !Number.isNaN(num)
    && num >= 0
    && num <= maxLimit;

const checkTimeoutError = (error: unknown): boolean => {
    const { statusCode } = getErrorResponse(error as AxiosError);
    const { request } = error as AxiosError;
    return [408, 504].includes(statusCode)
        || (!request || (request?.readyState === AJAX_DONE && request?.status === RESPONSE_FAILED));
};

type ErrorFilter = (error: unknown) => boolean;

type AsyncRequest<T> = () => Promise<T>;

/* eslint-disable no-await-in-loop */
export const retryRequest = async <T>(
    callback: AsyncRequest<T>,
    filterError: ErrorFilter,
    retryLimit: number,
    hasDelay = true,
): Promise<T | never> => {
    if (!validatePositiveNum(retryLimit, MAX_RETRY_LIMIT)) {
        throw Error(`Invalid retryLimit param. It should be a positive number less then ${MAX_RETRY_LIMIT}`);
    }
    let counter = retryLimit;
    let result: T;
    while (counter >= 0) {
        try {
            result = await callback();
            break;
        } catch (error) {
            if (!filterError(error) || counter <= 1) {
                throw error;
            } else {
                if (hasDelay) {
                    await sleep(DELAY_BETWEEN_RETRIES);
                }
                counter -= 1;
            }
        }
    }
    return result;
};

export const preventTimeout = async <T>(
    callback: AsyncRequest<T>,
    retryLimit = DEFAULT_DOWNLOAD_RETRY_LIMIT,
): Promise<T | never> => (
    retryRequest(callback, checkTimeoutError, retryLimit)
);
