import React, { FC } from 'react';

import { observer } from 'mobx-react';

import DownloadAll from './DownloadAll';
import SingleDownload from './SingleDownload';
import { useStores } from '../../../../../../../hooks';
import { SharedFile } from '../../../../../../../../stores';

type DownloadType = 'multiple' | 'single' | 'none';

const getDownloadType = (filesList: SharedFile[], downloadableFilesCount: number): DownloadType => {
    if (!downloadableFilesCount) {
        return 'none';
    }
    if (
        filesList.length === 1
        && (!filesList[0].is_workspace && !filesList[0].is_folder)
    ) {
        return 'single';
    }
    return 'multiple';
};

const Download: FC = observer(() => {
    const {
        recipientPageStore: {
            sharedFilesStore: { filesList, downloadableFilesIds },
        },
    } = useStores();

    const downloadType = getDownloadType(filesList, downloadableFilesIds.length);

    return (
        <>
            {downloadType === 'multiple' && <DownloadAll />}
            {downloadType === 'single' && <SingleDownload />}
        </>
    );
});

export default Download;
