import {
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
} from 'react';

import { Auth } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from 'amazon-cognito-identity-js';

import useMounted from '../useMounted';
import AuthSettingsStore from '../../../stores/AuthSettingsStore';
import UserStore from '../../../stores/UserStore';
import { CheckCodeError, ITimeoutCleaner } from '../../Common/CodeAuthenticator';
import {
    CognitoAuthError,
    OTPAuthType,
    PhoneIdentity,
    SimpleCallback,
} from '@/types/types';
import {
    captureErrorForSentry,
    captureOnce,
    retryRequest,
    SentryAdapter,
    sleep,
} from '../../utils';
import { filterCognitoError, generateCode, trySignUp } from './helpers';
import { COGNITO_CALL_RETRIES, NETWORK_THROTTLE_USERNAME, AUTH_SCHEDULE_INTERVAL } from './constants';

export interface OTPFlowCore {
    isMountedRef: MutableRefObject<boolean>;
    checkAuthCode: (
        code: string,
        startLoading: SimpleCallback,
        stopLoading: SimpleCallback,
        onSuccess: SimpleCallback,
    ) => Promise<void>;
    sendAuthCode: (
        username: string, authType: OTPAuthType, phoneIdentity?: PhoneIdentity,
    ) => Promise<void>;
    resendTimerRef: MutableRefObject<ITimeoutCleaner>;
}

const NETWORK_TIMEOUT_MS = 5000;

const useOTPFlow = (
    authSettingsStore: AuthSettingsStore,
    userStore: UserStore,
): OTPFlowCore => {
    const signInScheduleRef = useRef<NodeJS.Timeout>(null);
    const signedInUserRef = useRef<CognitoUser>(null);
    const resendTimerRef = useRef<ITimeoutCleaner>(null);
    const isMountedRef = useMounted();

    const captureException = useMemo<SentryAdapter>(() => captureOnce(captureErrorForSentry), []);

    const cancelSignInSchedule = (): void => {
        clearInterval(signInScheduleRef.current);
    };

    useEffect(() => cancelSignInSchedule, []);

    const { API } = authSettingsStore;

    const checkAuthCode = async (
        code: string,
        startLoading: SimpleCallback,
        stopLoading: SimpleCallback,
        onSuccess: SimpleCallback,
    ): Promise<void> => {
        cancelSignInSchedule();
        startLoading();
        let hasError = false;
        let isWrongCode = false;
        let signedInUser: CognitoUser = null;
        try {
            signedInUser = await Auth.sendCustomChallengeAnswer(signedInUserRef.current, code);
        } catch (error) {
            console.log('could not send custom challenge answer', error);
            hasError = true;
            isWrongCode = (error as CognitoAuthError).code === 'NotAuthorizedException';
            if (!isWrongCode) {
                captureErrorForSentry(error, 'useOTPFlow.checkAuthCode');
            }
        }
        if (!hasError && !signedInUser?.getSignInUserSession()) {
            isWrongCode = true;
            hasError = true;
        } else {
            signedInUserRef.current = signedInUser;
        }
        if (hasError) {
            stopLoading();
            if (isWrongCode) {
                throw new CheckCodeError('Wrong OTP code');
            } else {
                throw new Error('Could not authenticate user');
            }
        } else {
            await userStore.subscribeOnceOnSetUser();
            stopLoading();
            onSuccess();
        }
    };

    const fakeSignIn = async (username: string): Promise<void> => {
        try {
            signedInUserRef.current = await Auth.signIn(username);
        } catch (error) {
            console.log('error due to otp schedule', error);
            captureException(error, 'useOTPFlow.fakeSignIn');
        }
    };

    const runSignInSchedule = (username: string): void => {
        signInScheduleRef.current = setInterval(async () => {
            await fakeSignIn(username);
        }, AUTH_SCHEDULE_INTERVAL);
    };

    const sendAuthCode = async (
        username: string, authType: OTPAuthType, phoneIdentity?: PhoneIdentity,
    ): Promise<void> => {
        cancelSignInSchedule();
        const usernameLower = username.toLowerCase();
        await generateCode(API, usernameLower, authType, phoneIdentity);
        // TODO: remove this check
        const shouldEmulateThrottling = username.includes(NETWORK_THROTTLE_USERNAME);
        if (shouldEmulateThrottling) {
            await sleep(NETWORK_TIMEOUT_MS);
        }
        await trySignUp(usernameLower);
        if (shouldEmulateThrottling) {
            await sleep(NETWORK_TIMEOUT_MS);
        }
        if (isMountedRef.current) {
            signedInUserRef.current = await retryRequest<CognitoUser>(
                async (): Promise<CognitoUser> => Auth.signIn(usernameLower),
                (error: unknown) => filterCognitoError(error as CognitoAuthError),
                COGNITO_CALL_RETRIES,
            );
        }
        if (isMountedRef.current) {
            runSignInSchedule(usernameLower);
        }
    };

    return {
        isMountedRef,
        sendAuthCode,
        checkAuthCode,
        resendTimerRef,
    };
};

export default useOTPFlow;
