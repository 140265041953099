import { useGoogleLogin } from '@react-oauth/google';
import { CognitoGroups, CognitoUser, SpecterXUser, WSOUser } from '../../types/types';
import { OAuthUserAttributes } from './interfaces';
import { captureErrorForSentry, safeSplit } from '../../components/utils';

export const useGoogleLoginService = (responseSuccess, responseFail) => useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/userinfo.profile '
        + 'openid https://www.googleapis.com/auth/userinfo.email '
        + 'https://www.googleapis.com/auth/drive.install '
        + 'https://www.googleapis.com/auth/drive.file',
    onSuccess: async (codeResponse) => {
        responseSuccess({ code: codeResponse.code });
    },
    onError: (errorResponse) => responseFail(new Error(errorResponse.error)),
});

export const getWSOGroups = (groups: string): CognitoGroups[] => safeSplit<CognitoGroups>(groups, ',');

export const getUserAttributes = (user: SpecterXUser, IS_WSO: boolean): Promise<OAuthUserAttributes> => (
    new Promise<OAuthUserAttributes>((resolve, reject) => {
        if (IS_WSO) {
            const {
                // eslint-disable-next-line camelcase
                sub, email, given_name, family_name, preferred_username,
            } = (user as WSOUser).profile;
            resolve({
                sub,
                email,
                given_name,
                family_name,
                preferred_username,
                groups: (user as WSOUser).profile.groups,
            });
        } else {
            (user as CognitoUser).getUserAttributes((error, attrs) => {
                if (error) {
                    reject(error);
                } else {
                    const attributesDict = attrs.reduce((acc, currentValue) => ({
                        ...acc, [currentValue.getName()]: currentValue.getValue(),
                    }), {});
                    resolve(attributesDict);
                }
            });
        }
    })
);

export const tryGetCognitoGroups = (user: CognitoUser): CognitoGroups[] => {
    const groupsList: CognitoGroups[] = [];
    try {
        groupsList.push(...(user.getSignInUserSession().getIdToken().payload['cognito:groups'] || []));
    } catch (error) {
        console.log('Failed to parse cognito groups', error);
        captureErrorForSentry(error, 'UserStore.tryGetCognitoGroups');
    }
    return groupsList;
};
