import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { IReactionDisposer, reaction } from 'mobx';

import {
    Avatar, Popover, Button, message,
} from 'antd';
import {
    UserOutlined, FolderAddOutlined, MenuOutlined,
} from '@ant-design/icons';
import { API as AmplifyAPI } from 'aws-amplify';
import { AxiosError } from 'axios';
import classNames from 'classnames';

import i18n from '@/content';
import {
    CheckBoxConnectionResponse,
    CheckGoogleConnectionResponse,
    OauthCodeResponse,
    SimpleCallback,
    StorageConnectionState,
    StorageProvider,
    StorageProviderLinkable,
} from '@/types/types';
import UserSettingsModal from './UserSettingsModal';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { BASEURL, ENDPOINTS } from '../../api';
import { AppStore, AuthSettingsStore } from '../../stores';
import {
    captureErrorForSentry,
    captureServerError,
    captureWarningForSentry,
    getCognitoUserToken,
    tryGetBadRequestMessage,
} from '../utils';
import { MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY } from '../Common/ConnectExternalStorageButton';
import config from '../../config/env';
import { EXTERNAL_STORAGE_ROUTES_SET, ExternalStorageRoutes } from '@/config/appRoutes';
import { HEADER_PORTAL_ID } from './HeaderPortal';
import styles from './Header.module.scss';
import { BecomeLicensedUserButton } from '../Common/BecomeLicensedUserButton';
import BecomeLicensedUserModal from '../Common/BecomeLicensedUserModal/BecomeLicensedUserModal';

export { default as HeaderPortal } from './HeaderPortal';

interface HeaderPublicProps {
    appStore?: AppStore;
    authSettingsStore?: AuthSettingsStore;
    hasCreateFolder: boolean;
    openCreateFolder: SimpleCallback;
}

type HeaderProps = HeaderPublicProps & WithRouterProps;

interface HeaderState {
    googleConnectionState: StorageConnectionState;
    boxConnectionState: StorageConnectionState;
    userModalVisible: boolean;
    isBecomeLicensedUserModalVisible: boolean;
}

type StorageConnectionStates = Pick<HeaderState, 'boxConnectionState' | 'googleConnectionState'>;

type ExternalStorageStateField = keyof StorageConnectionStates;

interface StorageProviderConnectionDetails {
    endpoint: string;
    stateField: ExternalStorageStateField;
    responseField: StorageProviderLinkable;
}

interface SaveStorageProviderDetails {
    endpoint: string;
    stateField: ExternalStorageStateField;
}

interface MapStorageProviderTypeToCheckConnectionResponse {
    googledrive: CheckGoogleConnectionResponse;
    box: CheckBoxConnectionResponse;
}

const MAP_STORAGE_PROVIDER_TO_DISPLAY_NAME: Record<StorageProvider, string> = {
    googledrive: 'Google',
    box: 'Box',
};

const SAVE_STORAGE_PROVIDER_DETAILS_DICT: Record<StorageProvider, SaveStorageProviderDetails> = {
    googledrive: {
        endpoint: ENDPOINTS.isGoogleRegister(),
        stateField: 'googleConnectionState',
    },
    box: {
        endpoint: ENDPOINTS.linkBoxAccount(),
        stateField: 'boxConnectionState',
    },
};

const CHECK_STORAGE_PROVIDER_CONNECTION_DICT: Record<StorageProvider, StorageProviderConnectionDetails> = {
    googledrive: {
        endpoint: ENDPOINTS.isGoogleRegister(),
        stateField: 'googleConnectionState',
        responseField: 'google_linkable',
    },
    box: {
        endpoint: ENDPOINTS.isBoxRegister(),
        stateField: 'boxConnectionState',
        responseField: 'box_linkable',
    },
};

enum OauthPopupClosedMessage {
    google = 'popup_closed_by_user',
    box = 'The popup was closed for an unexpected reason',
}

@inject('appStore', 'authSettingsStore')
@observer
class Header extends Component<HeaderProps, HeaderState> {
    private reactionDisposer: IReactionDisposer;

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            userModalVisible: false,
            isBecomeLicensedUserModalVisible: false,
            googleConnectionState: { isDisconnected: false },
            boxConnectionState: { isDisconnected: false },
        };
    }

    componentDidMount(): void {
        const { appStore, location } = this.props;
        const { authSettingsStore: { HAS_API_PROXY }, auditsStore } = appStore;
        this.reactionDisposer = reaction(
            () => auditsStore.isEmailBecomeLicensedUserSentSuccess,
            (currentValue) => {
                if (currentValue === 'error') {
                    message.error(i18n.t('general.specterxCommon.couldNotGetOfferTryAgain'));
                }

                if (currentValue === 'success') {
                    this.handleCloseBecomeLicensedUserModal();
                    message.success(i18n.t('general.specterxCommon.successfulyGetOffer'));
                }
            },
        );
        try {
            appStore.setCompanyInformation({
                name: 'Company Inc.',
                imageUrl: config.COMPANY_LOGO,
            });
            const shouldCheckConnection = (
                !HAS_API_PROXY && !EXTERNAL_STORAGE_ROUTES_SET.has(location.pathname as ExternalStorageRoutes)
            );
            if (shouldCheckConnection) {
                this.setUpExternalStorageConnection();
            }
        } catch (error) {
            console.log('failed to set up', error);
            captureErrorForSentry(error, 'Header.componentDidMount');
            appStore.setErrorStatus(true, '');
        }
    }

    componentWillUnmount(): void {
        this.reactionDisposer?.();
    }

    async setUpExternalStorageConnection(): Promise<void> {
        const shouldCheckIsGoogleRegister = !config.DISABLE_GOOGLE_REGISTER_CHECK;
        if (shouldCheckIsGoogleRegister) {
            try {
                const jwtToken = await getCognitoUserToken();

                if (shouldCheckIsGoogleRegister) {
                    // Don't call this function with await for run them in parallel
                    this.setStorageRegistered(jwtToken, 'googledrive');
                }
            } catch (error) {
                console.log('Could not setup external storage connection', error);
                captureErrorForSentry(error, 'App.setUpExternalStorageConnection');
            }
        }
    }

    async setStorageRegistered(jwtToken: string, storageProvider: StorageProvider): Promise<void> {
        const { endpoint, stateField, responseField } = CHECK_STORAGE_PROVIDER_CONNECTION_DICT[storageProvider];
        try {
            const result: MapStorageProviderTypeToCheckConnectionResponse[typeof storageProvider] = (
                await AmplifyAPI.get(
                    BASEURL.backend(),
                    endpoint,
                    { headers: { Authorization: jwtToken } },
                ));
            const clientId = result.client_id;
            const isDisconnected = result[responseField];
            this.setState({ [stateField]: { isDisconnected, clientId } } as StorageConnectionStates);
        } catch (e) {
            console.log(`could not make ${stateField} check`, e);
        }
    }

    handleVisibleChange = (userModalVisible: boolean): void => {
        this.setState({ userModalVisible });
    };

    handleCloseBecomeLicensedUserModal = (): void => {
        this.setState({ isBecomeLicensedUserModalVisible: false });
    };

    handleOpenBecomeLicensedUserModal = (): void => {
        this.setState({ isBecomeLicensedUserModalVisible: true });
    };

    responseGoogleSuccess = (response: OauthCodeResponse): void => {
        this.onOAuthSuccess(response, 'googledrive');
    };

    responseBoxSuccess = (response: OauthCodeResponse): void => {
        this.onOAuthSuccess(response, 'box');
    };

    onOAuthSuccess = async (response: OauthCodeResponse, authProvider: StorageProvider): Promise<void> => {
        const { code } = response;
        const { appStore: { setAppAlert } } = this.props;
        const { endpoint, stateField } = SAVE_STORAGE_PROVIDER_DETAILS_DICT[authProvider];

        try {
            const JWT = await getCognitoUserToken();
            await AmplifyAPI.post(BASEURL.backend(),
                endpoint,
                { headers: { Authorization: JWT }, body: { code } });

            this.setState(
                {
                    [stateField]: { isDisconnected: false },
                    userModalVisible: false,
                } as Pick<HeaderState, 'boxConnectionState' | 'googleConnectionState' | 'userModalVisible'>,
            );
        } catch (error) {
            console.log('error while send oauth code', error);
            const backEndMessage = tryGetBadRequestMessage(error as AxiosError);
            const descriptionKey = MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY[backEndMessage];
            const message = backEndMessage || `Could not connect your ${authProvider} account`;
            setAppAlert({
                hasAlert: true,
                type: 'error',
                message,
                description: descriptionKey && i18n.t(`externalStorage.${descriptionKey}`),
            });
            captureServerError(error, `Header.onOAuthSuccess.${authProvider}`);
        }
    };

    responseGoogleFail = (error: Error): void => {
        this.onOAuthFail(error.message, 'googledrive');
    };

    responseBoxFail = (error: Error): void => {
        console.log('box oauth fail', error);
        if (error.message !== OauthPopupClosedMessage.box) {
            this.onOAuthFail(error.message, 'box');
        }
    };

    onOAuthFail = (originalMessage: string, authProvider: StorageProvider): void => {
        const { appStore: { setAppAlert } } = this.props;
        setAppAlert({
            hasAlert: true,
            type: 'error',
            message: `Could not connect your ${MAP_STORAGE_PROVIDER_TO_DISPLAY_NAME[authProvider]} account`,
        });
        const error = new Error(originalMessage);
        captureWarningForSentry(error, `Header.onOAuthFail.${authProvider}`);
    };

    render(): JSX.Element {
        const {
            userModalVisible,
            googleConnectionState,
            boxConnectionState,
            isBecomeLicensedUserModalVisible,
        } = this.state;
        const { appStore, hasCreateFolder, openCreateFolder } = this.props;
        const {
            toggleSidebarVisibility,
            sideBarState: { isMobile },
            userStore: { userInformation, isExternal },
            authSettingsStore: { IS_WSO },
        } = appStore;
        const showUserSettingsPopup = !IS_WSO;
        const username = userInformation.email || userInformation.displayName;
        const isBecomeLicensedUserButtonShow = isExternal;

        return (
            <div className={classNames(styles['app-header'], { [styles['has-hamburger']]: isMobile })}>
                {isMobile && (
                    <Button className={styles['menu-ham']} onClick={toggleSidebarVisibility}>
                        <MenuOutlined />
                    </Button>
                )}
                <div className={styles.logo}>
                    {appStore.companyInformation && (
                        <img
                            src={isMobile ? config.HEADER_SMALL_COMPANY_LOGO : config.HEADER_COMPANY_LOGO}
                            alt={appStore.companyInformation.name}
                        />
                    ) }
                </div>
                <div id={HEADER_PORTAL_ID} />
                {isBecomeLicensedUserButtonShow && (
                    <div>
                        <BecomeLicensedUserButton onClick={this.handleOpenBecomeLicensedUserModal} />
                    </div>
                )}
                <div className={styles['right-items']}>
                    {hasCreateFolder && (
                        <FolderAddOutlined
                            className={styles['create-folder']}
                            onClick={openCreateFolder}
                        />
                    )}
                    {showUserSettingsPopup ? (
                        <Popover
                            open={userModalVisible}
                            placement="bottomRight"
                            title={username}
                            onOpenChange={this.handleVisibleChange}
                            trigger="click"
                            className={styles['user-settings']}
                            content={(
                                <UserSettingsModal
                                    responseGoogleSuccess={this.responseGoogleSuccess}
                                    responseGoogleFail={this.responseGoogleFail}
                                    googleConnection={googleConnectionState}
                                    boxConnection={boxConnectionState}
                                    responseBoxSuccess={this.responseBoxSuccess}
                                    responseBoxFail={this.responseBoxFail}
                                    onVisibleChange={this.handleVisibleChange}
                                />
                            )}
                        >
                            <div className={styles['user-box']}>
                                <span className={styles.username} data-testid="profile_userName">
                                    {username}
                                </span>
                                <Avatar
                                    shape="square"
                                    className={styles.avatar}
                                    data-testid="profile_userMenu"
                                    icon={<UserOutlined className={styles.icon} />}
                                />
                            </div>
                        </Popover>
                    ) : (
                        <span className={styles.username}>
                            {username}
                        </span>
                    )}
                </div>
                <BecomeLicensedUserModal
                    isOpen={isBecomeLicensedUserModalVisible}
                    closeModal={this.handleCloseBecomeLicensedUserModal}
                    username={username}
                />
            </div>
        );
    }
}

export default withRouter<HeaderPublicProps>(Header);
