import React, { FC, memo, useState } from 'react';

import { Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { StepBox } from '../../../../Common/CodeAuthenticator';
import './index.scss';

interface OTPLastStepProps {
    stepNumber: number;
    onFinish: () => void | Promise<void>;
}

const nameSpace = 'recipientPage.otpAuth.thirdStep';

const OTPLastStep: FC<OTPLastStepProps> = ({ onFinish, stepNumber }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const { t } = useTranslation();

    const onCheck = (event: CheckboxChangeEvent): void => {
        setIsChecked(event.target.checked);
    };

    return (
        <StepBox
            stepNumber={stepNumber}
            title={t(`${nameSpace}.signinWithOffice`)}
        >
            <div className="third-step-buttons-container">
                <Checkbox
                    checked={isChecked}
                    className="confirm-checkbox"
                    onChange={onCheck}
                >
                    <span className="label">
                        {t(`${nameSpace}.IUnderstand`)}
                    </span>
                </Checkbox>
                <Button
                    className="confirm-btn"
                    disabled={!isChecked}
                    onClick={onFinish}
                >
                    {t(`${nameSpace}.download`)}
                </Button>
            </div>
        </StepBox>
    );
};

export default memo(OTPLastStep);
