import { useEffect, useState } from 'react';

type PopupState = 'disabled' | 'opened' | 'closed';

interface PopupControls {
    isPopupOpen: boolean;
    onVisibilityChange: (value: boolean) => void;
}

const usePopupToggle = (isActive: boolean): PopupControls => {
    const [popupState, setPopupState] = useState<PopupState>('disabled');

    useEffect(() => {
        setPopupState(isActive ? 'closed' : 'disabled');
    }, [isActive]);

    const onVisibilityChange = (value: boolean): void => {
        setPopupState((currentValue) => {
            if (currentValue !== 'disabled') {
                return value ? 'opened' : 'closed';
            }
            return currentValue;
        });
    };

    return {
        isPopupOpen: popupState === 'opened',
        onVisibilityChange,
    };
};

export default usePopupToggle;
