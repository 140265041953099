import React, { FC, memo, SyntheticEvent } from 'react';

import { Button } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import classNames from 'classnames';

import styles from './PasswordVisibilityToggle.module.scss';

interface PasswordVisibilityToggleProps {
    className?: string;
    toggleVisibility: (event: SyntheticEvent) => void;
}

const PasswordVisibilityToggle: FC<PasswordVisibilityToggleProps> = ({ className, toggleVisibility }) => (
    <Button
        size="small"
        className={classNames(styles['toggle-button'], { [className]: !!className })}
        onClick={toggleVisibility}
        type="link"
    >
        <EyeFilled className={styles.icon} />
    </Button>
);

export default memo(PasswordVisibilityToggle);
