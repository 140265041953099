import axios from 'axios';
import { API as AmplifyAPI } from 'aws-amplify';

import {
    APIData,
    SPXEndpoint,
    APIName,
    RequestConfig,
    SPXAPI,
} from './interfaces';
import AuthSettingsStore from '../stores/AuthSettingsStore';
import EndpointsResolver from './EndpointsResolver';
import { captureErrorForSentry, captureOnce, SentryAdapter } from '../components/utils';

type RunningAxiosRequests = Map<string, AbortController>;

type RunningAmplifyRequests = Map<string, Promise<unknown>>;

type RequestsMap = RunningAxiosRequests | RunningAmplifyRequests;

type CancelMethod = (key: string) => void;

export type APIMethod = keyof SPXAPI;

export interface CancellableCallResult<T> {
    isCanceled?: boolean;
    error?: unknown;
    result: T;
}

type ErrorDetails = Pick<CancellableCallResult<unknown>, 'error' | 'isCanceled'>;

interface APIConfig {
    failSilently?: boolean;
    isPeriodic?: boolean;
}

const CONFIG_DEFAULT: APIConfig = {
    failSilently: true,
    isPeriodic: false,
};

class CancellableAPI {
    private readonly authSettingsStore: AuthSettingsStore;

    private readonly failSilently: boolean;

    private readonly captureException: SentryAdapter;

    private readonly runningAxiosRequestsMap: RunningAxiosRequests;

    private readonly runningAmplifyRequestsMap: RunningAmplifyRequests;

    constructor(
        authSettingsStore: AuthSettingsStore,
        {
            failSilently = true,
            isPeriodic = false,
        }: APIConfig = CONFIG_DEFAULT,
    ) {
        this.authSettingsStore = authSettingsStore;
        this.failSilently = failSilently;
        this.runningAxiosRequestsMap = new Map();
        this.runningAmplifyRequestsMap = new Map();
        this.captureException = isPeriodic ? captureOnce(captureErrorForSentry) : captureErrorForSentry;
    }

    static isCancel(error: unknown, IS_WSO: boolean): boolean {
        return IS_WSO ? axios.isCancel(error) : AmplifyAPI.isCancel(error);
    }

    static isAnyInstanceCancel(error: unknown): boolean {
        return axios.isCancel(error) || AmplifyAPI.isCancel(error);
    }

    private get requestsDict(): RequestsMap {
        const { IS_WSO } = this.authSettingsStore;
        return IS_WSO ? this.runningAxiosRequestsMap : this.runningAmplifyRequestsMap;
    }

    private get cancelMethod(): CancelMethod {
        const { IS_WSO } = this.authSettingsStore;
        return IS_WSO ? this.tryCancelAxiosRequest : this.tryCancelAmplifyRequest;
    }

    cancelRequest(requestKey: string): void {
        this.cancelMethod.call(this, requestKey);
    }

    cancelAll(): void {
        const { cancelMethod } = this;
        this.requestsDict.forEach((value, key) => cancelMethod.call(this, key));
    }

    cancelByPartialKey(partialKey: string): void {
        const { cancelMethod } = this;
        this.requestsDict.forEach((value, key) => {
            if (key.includes(partialKey)) {
                cancelMethod.call(this, key);
            }
        });
    }

    checkIsCancel(error: unknown): boolean {
        const { IS_WSO } = this.authSettingsStore;
        return CancellableAPI.isCancel(error, IS_WSO);
    }

    async sendRequest<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        method: APIMethod,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        const { IS_WSO } = this.authSettingsStore;
        const apiData = this.prepareAPI(apiName, endpoint);
        const requestMethod = IS_WSO ? this.axiosCancellableCall : this.amplifyCancellableCall;
        return (
            requestMethod.call(this, apiData, requestKey, method, requestConfig) as Promise<CancellableCallResult<T>>
        );
    }

    async get<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        return this.sendRequest<T>(apiName, endpoint, requestKey, 'get', requestConfig);
    }

    async post<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        return this.sendRequest<T>(apiName, endpoint, requestKey, 'post', requestConfig);
    }

    async put<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        return this.sendRequest<T>(apiName, endpoint, requestKey, 'put', requestConfig);
    }

    async patch<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        return this.sendRequest<T>(apiName, endpoint, requestKey, 'patch', requestConfig);
    }

    async del<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        requestKey: string,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        return this.sendRequest<T>(apiName, endpoint, requestKey, 'del', requestConfig);
    }

    private prepareAPI(apiName: APIName, endpoint: SPXEndpoint): APIData {
        const { API, HAS_API_PROXY } = this.authSettingsStore;
        const APIInstance = API.extract();
        const resolvedEndpoint: string = EndpointsResolver.resolveEndpoint(endpoint, HAS_API_PROXY);
        const resolvedApiName = EndpointsResolver.resolveApiName(apiName, HAS_API_PROXY);
        return { apiInstance: APIInstance, endpoint: resolvedEndpoint, apiName: resolvedApiName };
    }

    private handleError(error: unknown): ErrorDetails | never {
        console.log('Cancellable call failed:', error);
        if (!this.failSilently) {
            throw error;
        }
        const isCanceled = this.checkIsCancel(error);
        if (!isCanceled) {
            this.captureException(error as Error, 'CancellableAPI.silentCancellableCallFailed');
        }
        return { isCanceled, error };
    }

    private async axiosCancellableCall<T>(
        { apiName, apiInstance, endpoint }: APIData,
        key: string,
        method: APIMethod,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        let response: CancellableCallResult<T> = { result: null };
        const controller = new AbortController();
        this.runningAxiosRequestsMap.set(key, controller);
        try {
            response.result = await apiInstance[method](
                apiName, endpoint, { signal: controller.signal, ...requestConfig },
            );
        } catch (error) {
            response = { ...response, ...this.handleError(error) };
        } finally {
            this.runningAxiosRequestsMap.delete(key);
        }
        return response;
    }

    private async amplifyCancellableCall<T>(
        { apiName, apiInstance, endpoint }: APIData,
        key: string,
        method: APIMethod,
        requestConfig: RequestConfig = {},
    ): Promise<CancellableCallResult<T>> {
        let response: CancellableCallResult<T> = { result: null };
        const requestPromise = apiInstance[method](apiName, endpoint, requestConfig);
        this.runningAmplifyRequestsMap.set(key, requestPromise);
        try {
            response.result = await requestPromise;
        } catch (error) {
            response = { ...response, ...this.handleError(error) };
        } finally {
            this.runningAmplifyRequestsMap.delete(key);
        }
        return response;
    }

    private tryCancelAxiosRequest(key: string): void {
        const abortController = this.runningAxiosRequestsMap.get(key);
        if (abortController) {
            abortController.abort();
            this.runningAxiosRequestsMap.delete(key);
        }
    }

    private tryCancelAmplifyRequest(key: string): void {
        const requestPromise: Promise<unknown> = this.runningAmplifyRequestsMap.get(key);
        if (requestPromise) {
            AmplifyAPI.cancel(requestPromise);
            this.runningAmplifyRequestsMap.delete(key);
        }
    }
}

export default CancellableAPI;
