import React, {
    FC,
    useRef,
    useEffect,
    ChangeEvent,
} from 'react';

import {
    Button,
    InputRef,
    Space,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import classNames from 'classnames';

import { Input } from '@/components/Common/UIKit';
import i18n from '@/content';

import styles from './EmailFilter.module.scss';

const buttonsNameSpace = 'general.buttons';
const nameSpace = 'userRoleEditor.emailFilter';

type EmailFilterProps = Omit<FilterDropdownProps, 'prefixCls' | 'filters'>;

const EmailFilter: FC<EmailFilterProps> = ({
    selectedKeys,
    setSelectedKeys,
    confirm,
    clearFilters,
    visible,
}) => {
    const inputRef = useRef<InputRef>();

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setSelectedKeys(value ? [value] : []);
    };

    useEffect(() => {
        if (visible) {
            inputRef.current?.focus();
        }
    }, [visible]);

    return (
        <div className={styles.wrapper}>
            <Input
                ref={inputRef}
                placeholder={i18n.t(`${nameSpace}.placeholder`)}
                value={selectedKeys[0] as string}
                onChange={onChange}
                onPressEnter={() => confirm()}
                prefix={<SearchOutlined />}
            />
            <Space className={styles['buttons-container']}>
                <Button
                    onClick={clearFilters}
                    size="small"
                    className={classNames(styles.button, styles['button-reset'])}
                >
                    {i18n.t(`${buttonsNameSpace}.reset`)}
                </Button>
                <Button
                    type="text"
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    className={classNames(styles.button, styles['button-search'])}
                >
                    {i18n.t(`${buttonsNameSpace}.search`)}
                </Button>
            </Space>
        </div>
    );
};

/*
* We don't wrap this component into memo(), because all callbacks are changing on every render, so memo is useless
* */
export default EmailFilter;
