import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { MiniAppRoutes } from '@/config/appRoutes';
import { extractTimelySearchParams, saveCurrentSearchParams } from '../helpers';
import { OAUTH_REDIRECT_VALIDATION_TIME, SIGN_OUT_REDIRECT_VALIDATION_TIME } from '../constants';

const useURLLinkId = (
    onReceiveLinkId: (linkId: string) => void,
): void => {
    const navigate = useNavigate();
    // TODO: migrate on useSearchParams
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        let linkId = urlParams.get('linkId');
        if (linkId) {
            saveCurrentSearchParams(location.search);
        } else {
            const isOauthRedirect = urlParams.has('error_description') || urlParams.has('code');
            const savedSearch = extractTimelySearchParams(isOauthRedirect
                ? OAUTH_REDIRECT_VALIDATION_TIME
                : SIGN_OUT_REDIRECT_VALIDATION_TIME);
            const savedSearchURL = new URLSearchParams(savedSearch);
            linkId = savedSearchURL.get('linkId');
            navigate(`${MiniAppRoutes.sharedFile}${savedSearch}`, { replace: true });
        }
        onReceiveLinkId(linkId);
    }, []);
};

export default useURLLinkId;
