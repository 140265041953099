import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES } from './languages';
import en from './general.json';
import he from './general_hebrew.json';

export type I18Payload = [string, Record<string, string | number>?];

i18next
    .use(initReactI18next)
    .init({
        resources: {
            [LANGUAGES.en]: { common: en },
            [LANGUAGES.he]: { common: he },
        },
        ns: ['common'],
        defaultNS: 'common',
        lng: LANGUAGES.en,
        interpolation: {
            escapeValue: false,
            skipOnVariables: false,
        },
    });

export default i18next;
