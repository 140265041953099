import React, { FC, useMemo } from 'react';

import { message } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import DownloadButton from './DownloadButton';
import { useStores } from '../../../../../../../hooks';
import { useFilesActionsContext } from '../../../hooks/useFilesActionContext';
import { SyncCallback } from '../../../../../../../../types/types';
import nameSpace from '../i18nNameSpace';

const DownloadAll: FC = observer(() => {
    const { actionHandler } = useFilesActionsContext();
    const { t } = useTranslation();

    const {
        recipientPageStore: { sharedFilesStore },
        filesAccessStore,
    } = useStores();

    const { isBatchDownloadLoading } = filesAccessStore;

    const processBatchDownloading = async (code?: string, throwError = false): Promise<void> => {
        const { downloadableFilesIds, allFilesCount } = sharedFilesStore;
        const downloadableLength = downloadableFilesIds.length;
        if (!downloadableLength) {
            message.info(t(`${nameSpace}.noFiles`));
        } else {
            if (!code && downloadableLength !== allFilesCount) {
                message.info(t(`${nameSpace}.downloadPermissionFailed`));
            }
            await filesAccessStore.tryStartBatchDownload(downloadableFilesIds, code, throwError);
        }
    };

    const batchDownloadInMFAFlow = async (code: string): Promise<void> => {
        await processBatchDownloading(code, true);
    };

    const onClick = useMemo<SyncCallback>(() => actionHandler({
        action: processBatchDownloading,
        verifyAction: batchDownloadInMFAFlow,
        payload: { accessType: 'batchDownload' },
    }), []);

    return (
        <DownloadButton
            isLoading={isBatchDownloadLoading}
            isMultipleDownload
            onClick={onClick}
        />
    );
});

export default DownloadAll;
