import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { PhoneIdentity } from 'src/types/types';
import { PhoneNumberViewContainer } from '../../PhoneNumberViewContainer';
import { PhoneViewContent } from '../..';
import styles from './EditButton.module.scss';

interface PhoneNumberViewProps {
    phoneNumber: PhoneIdentity;
    setEdit: (value: Exclude<PhoneViewContent, 'loadingView'>) => void;
}

const nameSpace = 'userRoleEditor.phoneNumber';

const PhoneNumberView: FC<PhoneNumberViewProps> = observer(({ phoneNumber, setEdit }) => {
    const { t } = useTranslation();
    return (
        <PhoneNumberViewContainer>
            <span
                className={styles['phone-users-text']}
            >
                {phoneNumber
                    ? `${phoneNumber.prefix}${phoneNumber.prefix && '-'}${phoneNumber.phone}`
                    : t(`${nameSpace}.noPhone`)}
            </span>
            <Button
                type="link"
                className={styles['edit-button']}
            >
                <EditOutlined onClick={() => setEdit('editingPhoneView')} />
            </Button>
        </PhoneNumberViewContainer>
    );
});

export default PhoneNumberView;
