import { FC } from 'react';

import { Button } from 'antd';
import Modal, { InitModalStyle, ModalFooter, ModalHeader } from '@/components/Common/Modal';

import styles from './InfoModal.module.scss';

const INIT_STYLE: InitModalStyle = {
    initWidth: 430,
};

interface InfoModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const InfoModal: FC<InfoModalProps> = ({ isOpen, closeModal }) => (
    <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        initStyle={INIT_STYLE}
    >
        <>
            <ModalHeader>
                <span className={styles.title}>
                    Information Security Guidelines
                </span>
            </ModalHeader>
            <div className={styles['main-container']}>
                <div>
                    <span className={styles.subtitle}>
                        The SpecterX secure channel is intended for the transfer
                        of sensitive information for work purposes only.
                        <br />
                        Do not use this channel for any other purpose except with
                        the express permission of the Information Security team.
                        {' '}
                        <br />
                        Information will be transferred to providers authorized by
                        agreement or to customers after verified identification.
                        {' '}
                        <br />
                        The use of this channel is monitored and the violation
                        of the policy will be dealt with accordingly.
                    </span>
                </div>
            </div>
            <ModalFooter>
                <div className={styles['buttons-container']}>
                    <Button
                        type="primary"
                        className={styles['ok-button']}
                        onClick={closeModal}
                    >
                        Ok
                    </Button>
                </div>
            </ModalFooter>
        </>
    </Modal>
);

export default InfoModal;
