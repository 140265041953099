import React, {
    FC, memo, useCallback,
} from 'react';

import NumRangeInput from '@/components/Common/NumRangeInput';
import PolicySetting, { PolicyRowProps } from '../PolicySetting';
import { NumRange } from '@/types/types';
import { DAYS_RETENTION_RANGE } from '@/stores/PolicyStore/dataRetention';

interface PolicyNumInputRowProps extends PolicyRowProps<number> {
    numRange: NumRange;
}

const PolicyNumInputRow: FC<PolicyNumInputRowProps> = ({
    isChild,
    settingId,
    settingValue,
    handleChange,
}) => {
    const onChange = useCallback((value: number | string): void => {
        handleChange(settingId, Number(value));
    }, []);

    return (
        <PolicySetting
            isChild={isChild}
            settingId={settingId}
            type="input"
        >
            <NumRangeInput
                required
                onChange={onChange}
                min={DAYS_RETENTION_RANGE.min}
                max={DAYS_RETENTION_RANGE.max}
                allowDecimal={false}
                name="number"
                value={settingValue}
            />
        </PolicySetting>
    );
};

export default memo(PolicyNumInputRow);
