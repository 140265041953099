import {
    memo,
    useEffect,
    useState,
} from 'react';
import type { FC } from 'react';

import { Breadcrumb } from 'antd';

import { useStores } from '@/components/hooks';
import { getBreadcrumbs } from '@/api';
import { captureErrorForSentry } from '@/components/utils';

import styles from './AuditBreadcrumbs.module.scss';

interface AuditBreadcrumbsProps {
    fileId: string,
}

interface BreadcrumbsToAudit {
    label: string,
    key: string,
}

const AuditBreadcrumbs: FC<AuditBreadcrumbsProps> = ({ fileId }) => {
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsToAudit[]>([]);

    const { authSettingsStore: { API } } = useStores();

    useEffect(() => {
        getBreadcrumbs(fileId, API).then((response) => {
            const breadcrumbsToAudit = response.map((item) => ({ label: item.filename, key: item.file_id }));
            setBreadcrumbs(breadcrumbsToAudit);
        }).catch((error) => {
            captureErrorForSentry(error, 'AuditBreadcrumbs.getBreadcrumbs');
        });
    }, [fileId]);

    return (
        <Breadcrumb
            className={styles.breadcrumbs}
            separator={<span className={styles.separator}>/</span>}
            items={breadcrumbs.map((item) => ({
                title: item.label,
                className: styles['breadcrumbs-item'],
            }))}
        />
    );
};

export default memo(AuditBreadcrumbs);
