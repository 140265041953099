import React, {
    DetailedHTMLProps, forwardRef, HTMLAttributes, memo,
} from 'react';

import classNames from 'classnames';

import styles from './SimpleCell.module.scss';

interface SimpleCellProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
    text: string;
}

/*
* Forward ref using here because this component could be placed inside Ant Tooltip.
* Tooltip, Popover and Popconfirm children should be created through the forwardRef()
* */

const SimpleCell = forwardRef<HTMLElement, SimpleCellProps>(({ text, className, ...props }, ref) => {
    const classes = classNames(styles['cell-text'], { [className]: !!className });

    return (
        <code className={classes} {...props} ref={ref}>{text}</code>
    );
});

export default memo(SimpleCell);
