import { PolicySetting } from '../../../../../types/types';
import { MAP_SETTING_APPEARANCE_TO_CONFIG_FLAG, MAP_UI_CHILD_TO_PARENT } from './constants';

type PolicySettingsDict = Record<string, PolicySetting>;

export const shouldShow = (policySettingsDict: PolicySettingsDict, dependencyId: string): boolean => {
    if (!dependencyId) {
        return true;
    }
    const parent = policySettingsDict[dependencyId];
    return parent ? !!parent.value : true;
};

interface PolicySettingsContent {
    settingsToDisplay: PolicySetting[];
    policySettingsDict: PolicySettingsDict;
}

export const getSettingsContent = (policySettings: PolicySetting[]): PolicySettingsContent => {
    const mapUIParentToChild: Map<string, PolicySetting[]> = new Map<string, PolicySetting[]>();
    const settingsToDisplay: PolicySetting[] = [];
    const policySettingsDict: PolicySettingsDict = {};

    const UITopLevelSettings: PolicySetting[] = [];
    policySettings.forEach((setting) => {
        const showSetting = (
            setting.id !== 'suggestMfa'
            && (typeof MAP_SETTING_APPEARANCE_TO_CONFIG_FLAG[setting.id] === 'boolean'
                ? MAP_SETTING_APPEARANCE_TO_CONFIG_FLAG[setting.id]
                : true)
        );

        if (showSetting) {
            policySettingsDict[setting.id] = setting;
            const UIParentSettingId: string = MAP_UI_CHILD_TO_PARENT[setting.id];
            if (!UIParentSettingId) {
                UITopLevelSettings.push(setting);
            } else {
                mapUIParentToChild.set(
                    UIParentSettingId,
                    [...(mapUIParentToChild.get(UIParentSettingId) || []), setting],
                );
            }
        }
    });

    UITopLevelSettings.forEach((setting) => {
        settingsToDisplay.push(setting);
        if (mapUIParentToChild.has(setting.id)) {
            settingsToDisplay.push(...mapUIParentToChild.get(setting.id));
        }
    });

    return {
        settingsToDisplay,
        policySettingsDict,
    };
};
