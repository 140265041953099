import React, {
    FC,
} from 'react';

import { Checkbox } from 'antd';

import classNames from 'classnames';

import i18n from '@/content';
import styles from './RoleFilter.module.scss';
import './antOverride.scss';

interface RoleFilterProps {
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    selectedKeys: string[],
    confirm: () => void,
}

const RoleFilter: FC<RoleFilterProps> = ({
    confirm,
    selectedKeys,
    setSelectedKeys,
}) => {
    const onChange = (checkedValues): void => {
        setSelectedKeys(checkedValues as React.Key[]);
        confirm();
    };

    return (
        <div className={classNames(styles['role-filter-container'], 'role-filter-container')}>
            <Checkbox.Group className={styles['content-box']} onChange={onChange}>
                {selectedKeys.map((item) => (
                    <Checkbox
                        className={styles['role-item']}
                        key={item}
                        value={item}
                    >
                        {i18n.t(`general.groups.${item}`)}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        </div>
    );
};

/*
* We don't wrap this component into memo(), because all callbacks are changing on every render, so memo is useless
* */
export default RoleFilter;
