import { RequestsQueue } from '../../../components/utils';
import { DeferredUploading } from '../interfaces';
import calculateMemoryAllowedSlots from './calculateMemoryAllowedSlots';

class UploadQueue extends RequestsQueue<DeferredUploading> {
    private readonly activeMemoryLimit: number;

    constructor(activeLimit: number, memoryLimitBytes: number) {
        super(activeLimit);
        this.activeMemoryLimit = memoryLimitBytes;
    }

    private calculateCurrentTaskSizeSum(): number {
        let sum = 0;
        this.activeTasksMap.forEach(({ fileSize }) => {
            sum += fileSize;
        });
        return sum;
    }

    protected getFreeSlotsCount(): number {
        const slotsCount: number = super.getFreeSlotsCount();
        let result = 0;
        if (slotsCount) {
            const currentMemoryUsageMax: number = this.calculateCurrentTaskSizeSum();
            if (currentMemoryUsageMax < this.activeMemoryLimit) {
                result = calculateMemoryAllowedSlots(
                    currentMemoryUsageMax,
                    this.activeMemoryLimit,
                    this.queue.slice(0, slotsCount),
                );
            }
        }
        return result;
    }
}

export default UploadQueue;
