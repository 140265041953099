import AuthSettingsStore from '../../AuthSettingsStore';
import CancellableAPI from '../../../api/CancellableAPI';
import { createFolderCancellable } from '../../../api';

class CreateFolderService {
    private readonly cancellableAPI: CancellableAPI;

    private readonly currentRequestsSet: Set<string> = new Set<string>();

    constructor(authSettingsStore: AuthSettingsStore) {
        this.cancellableAPI = new CancellableAPI(authSettingsStore, { failSilently: false });
    }

    async createFolder(folderName: string, parentFolderId: string, requestKey: string): Promise<string> {
        let specterxFolderId: string;
        try {
            this.currentRequestsSet.add(requestKey);
            specterxFolderId = await createFolderCancellable(
                { folderName, parentFolderId },
                requestKey,
                this.cancellableAPI,
            );
        } finally {
            this.currentRequestsSet.delete(requestKey);
        }
        return specterxFolderId;
    }

    checkIsLoading(requestKey: string): boolean {
        return this.currentRequestsSet.has(requestKey);
    }

    clear(): void {
        this.currentRequestsSet.clear();
    }

    cancel(requestKey: string): void {
        this.cancellableAPI.cancelRequest(requestKey);
    }
}

export default CreateFolderService;
