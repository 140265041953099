import React, { FC } from 'react';

import { Button, Divider } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import SharingBlock from '../../Common/SharingBlock';
import ShareSuccess from './ShareSuccess';
import FilesPolicyList from '../FilesPolicyList';
import { useStores } from '../../hooks';
import { SimpleCallback } from '../../../types/types';
import { checkHasRecipientBlock } from '../helpers';
import { SHOW_COPY_LINKS } from '../flags';
import styles from './SingleFileView.module.scss';

interface SingleFileViewProps {
    success: boolean;
    isPoliciesEditable: boolean;
    onShareClick: SimpleCallback;
}

const SingleFileView: FC<SingleFileViewProps> = observer(({
    success,
    isPoliciesEditable,
    onShareClick,
}) => {
    const { t } = useTranslation();
    const {
        uploadFilesStore: { successfullyUploadedSingleFiles },
        sharedUsersStore: {
            hasRecipients,
            currentInputEmail,
            singleRecipientIdentity,
        },
        usersPhonesStore: {
            hasMissingNumber,
        },
    } = useStores();

    const firstFile = successfullyUploadedSingleFiles[0];

    if (success) {
        return <ShareSuccess linkKey={singleRecipientIdentity} filename={firstFile.filename || firstFile.fid} />;
    }

    const hasRecipientsBlock = checkHasRecipientBlock('box');
    const hasPoliciesBlock = isPoliciesEditable && firstFile;
    const showPhones = firstFile && firstFile.policy?.isMfa;
    const hasMfaNoComplete = showPhones && hasMissingNumber;
    const isRecipientsStateFilled = hasRecipients || currentInputEmail;
    const showShareButton = !hasRecipientsBlock || isRecipientsStateFilled || !SHOW_COPY_LINKS;

    return (
        <div className={styles['single-file-view']}>
            {hasRecipientsBlock && <SharingBlock showPhones={showPhones} hasNotifyRecipients />}
            {hasPoliciesBlock && (
                <>
                    {hasRecipientsBlock && <Divider />}
                    <FilesPolicyList isExternalStorage hasMfaNoComplete={hasMfaNoComplete} />
                </>
            )}
            {firstFile && (
                <>
                    {showShareButton && (
                        <div className={styles['footer-button-wrapper']}>
                            <Button onClick={onShareClick} className={styles['share-button']}>
                                {t('general.specterxCommon.share')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export default SingleFileView;
