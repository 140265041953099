import { FC } from 'react';

import { SharedBlockCard } from './SharedBlockCard';

import { FileSummary, Recipient } from '@/types/types';

import './index.scss';

interface SharedBlockProps {
    uploadedFilesSummary: FileSummary[];
    recipientsList: Recipient[];
}

const SharedBlock: FC<SharedBlockProps> = ({ uploadedFilesSummary, recipientsList }) => (
    <div className="shared-block">
        <SharedBlockCard
            sharedBlockType="sharedFiles"
            uploadedFilesSummary={uploadedFilesSummary}
        />
        <SharedBlockCard
            sharedBlockType="sharedWith"
            recipientsList={recipientsList}
        />
    </div>
);

export default SharedBlock;
