import { useCallback } from 'react';
import type { FC, ReactNode } from 'react';

import { Menu } from 'antd';
import { observer } from 'mobx-react';
import type { MenuProps } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import type { MenuInfo } from 'rc-menu/lib/interface';

import { FileMenu } from '@/types/types';
import { FilesListType } from '@/stores';
import i18n from '@/content';

import FileAction from '../../FileAction/FileActionItem';
import { useMenu } from '../../FileAction/hooks';
import { FileActionType, items } from '../../FileAction/menuItems';
import styles from './ContextMenuContent.module.scss';

const SHARE_OPTION: FileActionType = 'share';
const nameSpace = 'filesTable.contextMenu';

type MenuItem = Required<MenuProps>['items'][number];

interface ContextMenuContentProps {
    fileId: string;
    filesListType: FilesListType;
    menu: FileMenu;
    onFileActionClick: (action: FileActionType, fid: string) => void;
}

const getMenuItemsNew = (itemsKeys: string[]): MenuItem[] => (
    itemsKeys.map((option) => {
        const { icon, component, title } = items[option];
        return ({
            className: styles['menu-item'],
            key: option,
            label: (
                <FileAction
                    icon={icon}
                    component={component}
                    title={i18n.t(`${nameSpace}.${title}`)}
                />
            ),
        });
    })
);

const useOptionsToDisplay = (menu: FileMenu, filesListType: FilesListType): MenuItem[] => {
    const {
        openWith,
        options,
        hasShare,
    } = useMenu(menu, filesListType);

    const optionsWithShare: FileActionType[] = [
        ...(hasShare ? [SHARE_OPTION] : []),
        ...options.filter((option) => !!items[option]),
    ];

    const optionsToDisplay = getMenuItemsNew(optionsWithShare);
    if (openWith.length) {
        optionsToDisplay.unshift({
            key: 'openWith',
            label: i18n.t(`${nameSpace}.openWith`),
            children: getMenuItemsNew(openWith),
        });
    }
    return optionsToDisplay;
};

const ContextMenuContent: FC<ContextMenuContentProps> = observer(({
    menu,
    fileId,
    filesListType,
    onFileActionClick,
}) => {
    const optionsToDisplay = useOptionsToDisplay(menu, filesListType);

    const handleMenuClick = useCallback((event: MenuInfo) => {
        onFileActionClick(event.key as FileActionType, fileId);
    }, [fileId, onFileActionClick]);

    return (
        <Menu
            className={styles['menu-wrapper']}
            onClick={handleMenuClick}
            items={optionsToDisplay}
        />
    );
});

export default ContextMenuContent;
