import {
    action,
    autorun,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
} from 'mobx';

import { CURRENT_ORGANIZATION } from '@/consts';
import AuthSettingsStore from '../AuthSettingsStore';

/* eslint-disable camelcase */
interface UserOrganization {
    name: string;
    id: string;
    is_main: boolean;
}
/* eslint-enable camelcase */

class OrganizationsStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private currentOrganizationIdDisposer: IReactionDisposer;

    private readonly authSettingsStore: AuthSettingsStore;

    @observable currentOrganizationId = '';

    @observable userOrganizations: UserOrganization[] = [];

    @observable isLoading = false;

    @observable hasError = false;

    @computed
    get currentOrganization(): UserOrganization {
        return this.userOrganizations.find(({ id }) => id === this.currentOrganizationId);
    }

    @action
    setCurrentOrganizationId = (organizationId: string): void => {
        this.currentOrganizationId = organizationId;
    }

    @action
    setUserOrganizations = (organizations: UserOrganization[]): void => {
        this.userOrganizations = organizations;
    }

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    }

    @action
    setHasError = (value: boolean): void => {
        this.hasError = value;
    }

    setUp = (): void => {
        this.setCurrentOrganizationId(localStorage.getItem(CURRENT_ORGANIZATION));
        if (!this.currentOrganizationIdDisposer) {
            this.currentOrganizationIdDisposer = autorun(() => {
                if (this.currentOrganizationId) {
                    localStorage.setItem(CURRENT_ORGANIZATION, this.currentOrganizationId);
                } else {
                    localStorage.removeItem(CURRENT_ORGANIZATION);
                }
            });
        }
    }

    @action
    clear = (): void => {
        this.userOrganizations = [];
        this.currentOrganizationId = '';
    }
}

export default OrganizationsStore;
