import { action, makeObservable, observable } from 'mobx';

import BaseFilesManager from './BaseFilesManager';
import AuthSettingsStore from '../../AuthSettingsStore';
import UserStore from '../../UserStore';
import FilesListLoader from '../FilesListLoader';
import { FilesListType, LoadParams, RefreshOptions } from '../interfaces';
import { FILE_META_ORDERING_FIELDS } from '../options';

class AllFilesManager extends BaseFilesManager {
    constructor(...params: [FilesListType, AuthSettingsStore, UserStore, FilesListLoader]) {
        super(...params, [...FILE_META_ORDERING_FIELDS]);
        // TODO: check why extend not work into this flow
        makeObservable(this);
    }

    @observable
    searchedFileOwner = '';

    @action
    setSearchedFileOwner = (value: string): void => {
        this.searchedFileOwner = value;
    }

    searchByOwner = async (value: string): Promise<void> => {
        this.setSearchedFileOwner(value);
        this.clearTree();
        await this.clearAndFetch();
    }

    checkHasFilters(): boolean {
        return !!this.searchedFileOwner || super.checkHasFilters();
    }

    public destroyFilters(): void {
        super.destroyFilters();
        this.searchedFileOwner = '';
    }

    public getBaseQueryParams(): LoadParams {
        const { searchedFileOwner } = this;
        return { ...super.getBaseQueryParams(), email: searchedFileOwner };
    }

    protected getRefresherConfig(): RefreshOptions {
        const { searchedFileOwner } = this;
        return { ...super.getRefresherConfig(), fileOwner: searchedFileOwner };
    }
}

export default AllFilesManager;
