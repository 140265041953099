const REVOKE_AUTO_LOAD_LINK = 150;

const downloadFile = (fileUrl: string): void => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    link.setAttribute('target', '_blank');

    const clickHandler = (): void => {
        setTimeout(() => {
            URL.revokeObjectURL(fileUrl);
            link.remove();
        }, REVOKE_AUTO_LOAD_LINK);
    };

    link.addEventListener('click', clickHandler, false);
    link.click();
};

export default downloadFile;
