import React, { FC, memo } from 'react';

import classNames from 'classnames';

import SkeletonItem from '../SkeletonItem';
import { formatDate } from '../../../utils';
import { UUID_V4_REGEXP } from '../../../../regExp';
import styles from './LastUsed.module.scss';
import commonStyle from '../../../Common/Typography/Typography.module.scss';

interface LastUsedProps {
    lastUsedBy: string;
    lastUsedTime: string;
    userName: string;
}

const LastUsed: FC<LastUsedProps> = ({ lastUsedTime, lastUsedBy, userName }) => {
    const byWhoText = lastUsedBy === userName ? 'me' : lastUsedBy;

    return (
        <div
            className={classNames(
                styles['last-use'],
                commonStyle['blue-half'],
                commonStyle.ellipsis,
            )}
        >
            {lastUsedTime && (
                <span className={styles.date}>
                    {formatDate(lastUsedTime)}
                </span>
            )}
            {byWhoText && (UUID_V4_REGEXP.test(byWhoText)
                ? <SkeletonItem />
                : (
                    <span title={byWhoText} className={styles['by-who']}>
                        {byWhoText}
                    </span>
                )
            )}
        </div>
    );
};

export default memo(LastUsed);
