import React, { FC, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import DownloadButton from './DownloadButton';
import { useStores } from '../../../../../../../hooks';
import { FileAccessData, useFetchAccessLink } from '../../../hooks';
import { useFilesActionsContext } from '../../../hooks/useFilesActionContext';
import { SharedFile } from '../../../../../../../../stores';
import { OpenOptions } from '../../../../../../../../config/openOptions';
import { SyncCallback } from '../../../../../../../../types/types';

const getFileAccessData = (file: SharedFile): FileAccessData => {
    const {
        owner_email: ownerEmail,
        filename,
        file_id: fileId,
        policy,
    } = file;

    return {
        ownerEmail,
        filename,
        fileId,
        policy,
    };
};

const SingleDownload: FC = observer(() => {
    const { t } = useTranslation();
    const { actionHandler } = useFilesActionsContext();

    const { filesAccessStore, userStore, recipientPageStore: { sharedFilesStore } } = useStores();

    const { filesList } = sharedFilesStore;

    const { isLoading, clickHandler } = useFetchAccessLink(
        getFileAccessData(filesList[0]),
        OpenOptions.download,
        t,
        actionHandler,
        filesAccessStore,
        userStore,
    );

    const onClick = useMemo<SyncCallback>(() => clickHandler, []);

    return (
        <DownloadButton
            isLoading={isLoading}
            isMultipleDownload={false}
            onClick={onClick}
        />
    );
});

export default SingleDownload;
