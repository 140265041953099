import { TFunction } from 'i18next';

import { MAP_ORIGIN_TO_LOCAL_STORAGE_KEY } from '@/consts';
import NoFilesError from './NoFilesError';
import { Policy, StorageProvider } from '../../types/types';
import { UploadedFile } from '../../stores';
import { ExternalStorageFile, ExternalStorageFileResponse } from './interfaces';

const NAME_SPACE = 'externalStorage';

export const checkIsBox = (storageProvider: StorageProvider): boolean => storageProvider === 'box';


const checkIsBlockVisible = (storageProvider: StorageProvider): boolean => (
    storageProvider === 'googledrive'
);

export const checkHasPolicies = (storageProvider: StorageProvider): boolean => (
    checkIsBlockVisible(storageProvider)
);

export const checkHasRecipientBlock = (storageProvider: StorageProvider): boolean => (
    checkIsBlockVisible(storageProvider)
);

const injectIsMFAPolicyToFile = (
    file: ExternalStorageFileResponse,
    policies: Policy[],
    isPoliciesEditable: boolean,
): ExternalStorageFile => {
    const filePolicy: Policy = (
        policies.find(({ id }) => id === file.policy_id)
        || policies.find((policy) => policy.active)
    );

    return ({
        fid: file.fid,
        originId: file.origin_id,
        filename: file.filename,
        policy: filePolicy ? {
            title: filePolicy.title,
            id: filePolicy.id,
            isMfa: isPoliciesEditable && filePolicy.isMfa,
        } : null,
    });
};

export const getTitleText = (isBox: boolean, t: TFunction, filename: string): string => (
    isBox && filename ? t(`${NAME_SPACE}.titleWithFilename`, { filename }) : t(`${NAME_SPACE}.title`)
);

interface FilesForMobX {
    filesForUploadStore: UploadedFile[];
    filesForPreloader: string[];
}

export const prepareFilesForMobX = (
    files: ExternalStorageFileResponse[],
    policies: Policy[],
    origin: StorageProvider,
    isPoliciesEditable: boolean,
): FilesForMobX => {
    const newFiles: ExternalStorageFile[] = files.map<ExternalStorageFile>((file) => (
        injectIsMFAPolicyToFile(file, policies, isPoliciesEditable)
    ));
    const filesForUploadStore = newFiles.map((file) => ({
        ...file,
        fid: file.fid || file.originId,
    }));
    const filesForPreloader = newFiles.map<string>(({ originId, fid }) => fid || originId);
    return { filesForPreloader, filesForUploadStore };
};

export const getFilesIdsQueryString = (origin: StorageProvider): string | never => {
    const localStorageFiles = sessionStorage.getItem(MAP_ORIGIN_TO_LOCAL_STORAGE_KEY[origin]);

    let filesIdsQueryString = '';
    if (localStorageFiles) {
        try {
            filesIdsQueryString = JSON.parse(localStorageFiles)
                .ids
                .reduce((accumulator, currentValue) => `${accumulator}&originId=${currentValue}`, '');
        } catch {
            console.warn('could not parse storage data');
            throw new NoFilesError('Could not parse storage data');
        }
    } else {
        console.warn('no data in storage');
        throw new NoFilesError('No data in storage');
    }
    return filesIdsQueryString;
};
