import React, { createContext, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { usePageLanguage, LanguageControls } from '../../hooks';

export const PageLanguageContext = createContext<LanguageControls>(null);

interface URLLanguageContextProviderProps {
    children: ReactElement | ReactElement[];
}

export const PageLanguageContextProvider: FC<URLLanguageContextProviderProps> = ({ children }) => {
    const { i18n } = useTranslation();
    const languageControls = usePageLanguage(i18n);

    return (
        <PageLanguageContext.Provider value={languageControls}>
            {children}
        </PageLanguageContext.Provider>
    );
};
