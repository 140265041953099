import type { FC } from 'react';

import { Portal, PortalProps } from '@/components/Common/Portal';

export const HEADER_PORTAL_ID = 'header-portal';

const HeaderPortal: FC<Omit<PortalProps, 'nodeId'>> = ({ children }) => (
    <Portal nodeId={HEADER_PORTAL_ID}>{children}</Portal>
);

export default HeaderPortal;
