import React, { FC, useState } from 'react';

import { Button, Table } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import RecipientRoleSelect, { SharedItemType } from '../../../Common/RecipientRoleSelect';
import RecipientCell from './RecipientCell';
import { useStores } from '../../../hooks';
import {
    PhoneIdentity, RecipientRoles, SecuredFile, SharedUser,
} from '@/types/types';
import { SharedUserToDisplay } from '../../../../stores';
import styles from './ShareSettingsTable.module.scss';

const { Column } = Table;

const nameSpace = 'filesTable.modals.settings.shareSettings';

const createOwnerUser = (email: string, userId: string): SharedUserToDisplay => ({
    email,
    userId,
    recipientRole: 'coOwner',
    recipientType: 'user',
    deleted: false,
});

interface ShareSettingsProps {
    fileSelected: SecuredFile;
    sharedItemType?: SharedItemType;
    sharedUsers: SharedUserToDisplay[];
    onChangeUsers: (newUsers: SharedUserToDisplay[]) => void;
}

const ShareSettingsTable: FC<ShareSettingsProps> = observer(({
    fileSelected,
    sharedItemType,
    onChangeUsers,
    sharedUsers,
}) => {
    const [recipientForPhoneEditing, setRecipientForPhoneEditing] = useState<string>('');

    const {
        filesListStore: { isMyFiles },
        usersPhonesStore: {
            usersPhonesMap,
            saveRecipientPhone,
        },
    } = useStores();

    const usersToDisplay: SharedUserToDisplay[] = [
        createOwnerUser(fileSelected.owner_email, fileSelected.owner_id),
        ...sharedUsers,
    ];

    const changeRole = (email: string, recipientRole: RecipientRoles): void => {
        const newUsersList = sharedUsers.map((user) => {
            if (user.email === email) {
                return { ...user, recipientRole };
            }
            return user;
        });
        onChangeUsers(newUsersList);
    };

    const toggleUserAccess = (email: string, isDeleted: boolean): void => {
        const newUsersList = sharedUsers.map((user) => {
            if (user.email === email) {
                return { ...user, deleted: !isDeleted };
            }
            return user;
        });
        onChangeUsers(newUsersList);
    };

    // TODO: check if it works in all cases. If not, use alphanumeric_email check from BE download-service
    const checkIsElementEnabled = (userId: string): boolean => (
        (userId !== fileSelected.owner_id)
        && (isMyFiles || fileSelected?.menu?.edit_shares)
    );

    const setPhone = (email: string, phone: PhoneIdentity): void => {
        saveRecipientPhone(email, phone);
        setRecipientForPhoneEditing('');
    };

    return (
        <Table
            rowKey="userId"
            size="middle"
            pagination={false}
            dataSource={usersToDisplay}
            className={styles['share-settings-table']}
        >
            <Column
                title={i18n.t(`${nameSpace}.usersWithAccess`)}
                dataIndex="email"
                key="email"
                ellipsis
                render={(cellValue, row: SharedUserToDisplay) => {
                    const recipientPhone = usersPhonesMap.get(cellValue);
                    return (
                        <RecipientCell
                            cellValue={cellValue}
                            recipientForPhoneEditing={recipientForPhoneEditing}
                            setRecipientForPhoneEditing={setRecipientForPhoneEditing}
                            setPhone={setPhone}
                            phoneInfo={recipientPhone?.phonesVector?.currentPhone}
                            fileOwnerId={fileSelected.owner_id}
                            sharedUser={row}
                        />
                    );
                }}
            />
            <Column
                title={i18n.t(`${nameSpace}.role`)}
                dataIndex="recipientRole"
                width="120px"
                render={(cellValue, row: SharedUserToDisplay) => (
                    <>
                        {!row.deleted && checkIsElementEnabled(row.userId) && (
                            <RecipientRoleSelect
                                value={cellValue}
                                sharedItemType={sharedItemType}
                                onChange={(newValue) => changeRole(row.email, newValue)}
                            />
                        )}
                    </>
                )}
                key="readOnly"
            />
            <Column
                title=""
                dataIndex=""
                width="100px"
                key="delete"
                render={(cellValue, row: SharedUser) => {
                    const isDeleted = row.deleted;

                    return (
                        <Button
                            disabled={!checkIsElementEnabled(row.userId)}
                            type="link"
                            className="user-6access-button"
                            data-testid="manageAccess_revokeFirstUser"
                            onClick={() => toggleUserAccess(row.email, isDeleted)}
                        >
                            {i18n.t(`${nameSpace}.${isDeleted ? 'share' : 'revoke'}`)}
                        </Button>
                    );
                }}
            />
        </Table>
    );
});

export default ShareSettingsTable;
