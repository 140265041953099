import React, { FC } from 'react';

import { Button } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PUBLIC_ASSETS } from '@/consts';
import { useStores } from '../../../../../../../../../hooks';
import { FileAccessData, useFetchAccessLink } from '../../../../../hooks';
import { useFilesActionsContext } from '../../../../../hooks/useFilesActionContext';
import { LinkBasedOpenOptions, OpenOptions } from '../../../../../../../../../../config/openOptions';
import { GET_FILE_BUTTONS } from '../../../../../../../../Common';
import styles from './OpenFileButton.module.scss';

interface OpenFileButtonProps extends FileAccessData {
    accessOption: LinkBasedOpenOptions;
}

const OpenFileButton: FC<OpenFileButtonProps> = observer(({
    accessOption,
    ...fileAccessData
}) => {
    const { t } = useTranslation();
    const { actionHandler } = useFilesActionsContext();

    const { filesAccessStore, userStore } = useStores();

    const { isLoading, clickHandler } = useFetchAccessLink(
        fileAccessData,
        accessOption,
        t,
        actionHandler,
        filesAccessStore,
        userStore,
    );

    const isDownload = accessOption === OpenOptions.download;
    const { titleKey, iconPath } = GET_FILE_BUTTONS[accessOption];
    const icon = iconPath
        ? <img src={`${PUBLIC_ASSETS}${iconPath}`} alt={accessOption} />
        : <DownloadOutlined />;

    return (
        <Button
            type="link"
            onClick={clickHandler}
            className={classNames(styles['open-button'], { [styles['has-text']]: isDownload })}
        >
            <div className={styles['icon-wrapper']}>
                {isLoading ? <LoadingOutlined /> : icon}
            </div>
            {isDownload && t(titleKey)}
        </Button>
    );
});

export default OpenFileButton;
