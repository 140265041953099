import {
    action,
    computed,
    makeObservable,
    observable,
    toJS,
} from 'mobx';
import { message } from 'antd';
import type {
    RcFile,
    UploadFile as AntUploadedFile,
} from 'antd/lib/upload/interface';
import type { UploadProgressEvent } from 'rc-upload/lib/interface';

import CancellableAPI from '@/api/CancellableAPI';
import {
    BASEURL,
    ENDPOINTS,
    UploadAxiosInstance,
    changeFilePolicy,
} from '@/api';
import type { FileSummary } from '@/types/types';
import type { Dict } from '@/components/utils';
import i18n from '@/content';
import appConfig from '@/config/env';
import { captureErrorForSentry, getErrorResponse } from '@/components/utils';
import { FULL_PERCENTS } from '@/consts';
import { messagesNameSpace } from '@/components/Common/FilesUploaderDragger/constants';

import AuthSettingsStore from '../AuthSettingsStore';
import PolicyStore from '../PolicyStore';
import FolderUploader from './FolderUploader';
import InsertionBuffer from './InsertionBuffer';
import UploadProcessesMap from './UploadProcessesMap';
import UploadQueue from './UploadQueue';
import NoProcessError from './NoProcessError';
import {
    ChangePolicyConfig,
    DeferredFile,
    DeferredFileToFolder,
    FolderToDisplay,
    FuturePolicy,
    MultipartUpload,
    OSFolder,
    PolicySimpleWithMFA,
    RemoveResult,
    SinglePartUploadInfo,
    UploadedFile,
    UploadedFolderInfo,
    UploadedItem,
    UploadedRootFolder,
    UploadProcess,
    UploadProgressControls,
} from './interfaces';
import {
    checkIsFileInFolder,
    completeMultipartUploading,
    createAntFile,
    createMessageMetadata,
    createSinglePartFileMetadata,
    getUploadDetailsMultipart,
    handleUploadedFileRemovingResult,
    handleUploadError,
    mergePropsToRcFile,
    multipartUploadWorker,
    partialUpdateUploadedItem,
    readMessage,
    readSinglePartFile,
    uploadIdToRequestKey,
    uploadMessage,
    uploadSinglePartFile,
    waitForScanning,
} from './helpers';
import {
    CHUNK_SIZE,
    S3_UPLOAD_LIMIT,
    FINAL_STATES_SET,
    MAP_UPLOAD_STATUS_TO_GENERIC_STATUS,
    ERROR_MESSAGE_DURATION_SECONDS,
    SCAN_MESSAGE_DURATION_SECONDS,
    NAS_FILE_LIMIT,
    PARALLEL_UPLOAD_PROCESSES_COUNT,
    SMALL_FILE_LIMIT,
    UPLOAD_QUEUE_ELEMENT_LIMIT,
    UPLOAD_TASK_LIMIT_BYTES,
} from './constants';
import { SharedFilesStore, SharedUsersStore } from '..';

const { UPLOAD_FILES_COUNT_LIMIT } = appConfig;

export { handleUploadedFileRemovingResult, MAP_UPLOAD_STATUS_TO_GENERIC_STATUS };
export type {
    FolderToDisplay,
    RemoveResult,
    UploadedFile,
    UploadedItem,
};

// TODO: UploadedFile interface is not corresponding to ExternalStorageFile. These list should be split.
class UploadFilesStore {
    constructor(
        authSettingsStore: AuthSettingsStore,
        policyStore: PolicyStore,
        sharedUsersStore: SharedUsersStore,
    ) {
        this.authSettingsStore = authSettingsStore;
        this.policyStore = policyStore;
        this.sharedUsersStore = sharedUsersStore;
        this.uploadProcessesMap = new UploadProcessesMap();
        this.folderUploader = new FolderUploader(
            authSettingsStore,
            (uid, folderInfo) => this.onInsertFileIntoFolder(uid, folderInfo),
            (uid: string, fileId: string) => this.tryAttachFuturePolicy(uid, fileId, true),
        );
        this.uploadInstance = new UploadAxiosInstance('multiple');
        this.uploadQueue = new UploadQueue(UPLOAD_QUEUE_ELEMENT_LIMIT, UPLOAD_TASK_LIMIT_BYTES);
        this.cancellableAPI = new CancellableAPI(authSettingsStore, { failSilently: false });
        this.insertionBuffer = new InsertionBuffer((deferredFiles) => this.onFillInsertionBuffer(deferredFiles));
        this.futurePolicy = { policy: null, itemsUIDsSet: new Set() };
        makeObservable(this);
    }

    @observable uploadedFiles: UploadedFile[] = [];

    @observable isLoading = false;

    @observable uploadingPopupOpened = false;

    @observable chosenForPolicyFileUid = '';

    @observable private S3uploadLimit: number = S3_UPLOAD_LIMIT;

    private readonly folderUploader: FolderUploader;

    private readonly sharedUsersStore: SharedUsersStore;

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly policyStore: PolicyStore;

    private readonly uploadProcessesMap: UploadProcessesMap;

    private readonly uploadInstance: UploadAxiosInstance;

    private readonly uploadQueue: UploadQueue;

    private readonly cancellableAPI: CancellableAPI;

    private readonly insertionBuffer: InsertionBuffer;

    private futurePolicy: FuturePolicy;

    @computed
    get uploadedFilesToJS(): UploadedFile[] {
        return toJS(this.uploadedFiles);
    }

    @computed
    get modifiedFoldedFiles(): (UploadedFile | FolderToDisplay)[] {
        const folders: FolderToDisplay[] = this.folderUploader
            .currentlyUploadingFolders
            .map<FolderToDisplay>(({
            uid,
            filename,
            status,
            expanded,
        }) => ({
            foldedFiles: [],
            name: filename,
            isFolder: true,
            status,
            uid,
            expanded,
        }));
        const topLevelFiles: UploadedFile[] = [];
        this.uploadedFilesToJS.forEach((item) => {
            const process = this.uploadProcessesMap.get(item.uid);
            if (process?.folderInfo) {
                const targetRoot = folders.find(({ uid }) => uid === process?.folderInfo.treeId);
                targetRoot.foldedFiles.push(item);
            } else {
                topLevelFiles.push(item);
            }
        });

        // TODO: move this logic into component
        const foldersWithStatus: FolderToDisplay[] = folders.map<FolderToDisplay>((folder): FolderToDisplay => {
            const { foldedFiles } = folder;
            const uploadedFilesCount = foldedFiles.filter(({ status }) => status === 'done').length;
            let { status } = folder;
            if (status !== 'error') {
                status = uploadedFilesCount === foldedFiles.length ? 'done' : 'uploading';
            }
            return {
                ...folder,
                status,
                uploadedFilesCount,
            };
        });
        return [...foldersWithStatus, ...topLevelFiles];
    }

    @computed
    get atLeastOneUploadedItem(): boolean {
        return !!this.successfullyUploadedItems.length;
    }

    @computed
    get noFiles(): boolean {
        return !this.atLeastOneUploadedItem;
    }

    @computed
    get successfullyUploadedSingleFiles(): UploadedFile[] {
        return this.uploadedFiles.filter(
            (file) => {
                const isDone = file.status ? file.status === 'done' : true;
                return isDone ? !checkIsFileInFolder(file) : false;
            },
        );
    }

    @computed
    get currentlyUploadingSingleFiles(): UploadedFile[] {
        return this.uploadedFiles.filter(
            (file) => !checkIsFileInFolder(file),
        );
    }

    @computed
    get completedFiles(): UploadedFile[] {
        return this.uploadedFiles.filter(({ status }) => status === 'done');
    }

    @computed
    get currentlyUploadingFolders(): UploadedRootFolder[] {
        return this.folderUploader.currentlyUploadingFolders;
    }

    @computed
    get currentlyUploadingItems(): UploadedRootFolder | UploadedFile[] {
        return [...this.folderUploader.currentlyUploadingFolders, ...this.currentlyUploadingSingleFiles];
    }

    @computed
    get successfullyUploadedItems(): UploadedItem[] {
        return [...this.folderUploader.successfullyUploadedFolders, ...this.successfullyUploadedSingleFiles];
    }

    @computed
    get uploadedFilesSummary(): FileSummary[] {
        return this.successfullyUploadedItems.map(({ fid, filename }) => ({ fid, filename }));
    }

    @computed
    get uploadedFileIds(): string[] {
        return this.successfullyUploadedItems.map(({ fid }) => fid).filter(Boolean);
    }

    @computed
    get allFilesCount(): number {
        return this.uploadedFiles.length;
    }

    @computed
    get completedFilesCount(): number {
        return this.completedFiles.length;
    }

    @computed
    get successfullyUploadedItemsCount(): number {
        return this.successfullyUploadedItems.length;
    }

    @computed
    get hasFiles(): boolean {
        return !!this.uploadedFiles.length;
    }

    @computed
    get hasItems(): boolean {
        return this.hasFiles || !!this.folderUploader.currentlyUploadingFolders.length;
    }

    @computed
    get isSamePolicy(): boolean {
        const policiesIdsSet = new Set<string>(this.successfullyUploadedItems
            .map(({ policy }) => policy && policy.id).filter(Boolean));
        return policiesIdsSet.size === 1;
    }

    @computed
    get hasMfa(): boolean {
        return !!this.successfullyUploadedItems.find(({ policy }) => policy && policy.isMfa);
    }

    @computed
    get isUploading(): boolean {
        return !!this.uploadedFiles.find(({ status }) => status === 'uploading');
    }

    @computed
    get currentlyUploadingFiles(): UploadedFile[] {
        return this.uploadedFiles.filter(({ status }) => status === 'uploading');
    }

    @computed
    get currentUploadingFile(): UploadedFile {
        return this.uploadedFiles.find(({ status }) => status === 'uploading');
    }

    @computed
    get uploadLimit(): number {
        const { hasNASStorage } = this.authSettingsStore;
        return hasNASStorage ? NAS_FILE_LIMIT : this.S3uploadLimit;
    }

    @computed
    get chosenForPolicyFile(): UploadedFile {
        return this.successfullyUploadedItems.find((item) => item.uid === this.chosenForPolicyFileUid);
    }

    @action
    expandFolder = (value: boolean, uid: string): void => {
        this.folderUploader.expandFolder(value, uid);
    };

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    };

    @action
    setUploadingPopupOpened = (value: boolean): void => {
        this.uploadingPopupOpened = value;
    };

    @action
    setChosenForPolicyFileUid = (value: string): void => {
        this.chosenForPolicyFileUid = value;
    };

    @action
    setFilesList = (filesList: UploadedFile[]): void => {
        this.uploadedFiles = filesList;
    };

    @action
    setS3UploadLimit = (value: number): void => {
        this.S3uploadLimit = value;
    };

    uploadMessageMetadata = async (): Promise<string> => {
        const {
            file_id: fid, url,
            is_onprem: isOnprem,
            fileName,
        }: SinglePartUploadInfo = await createMessageMetadata(
            this.cancellableAPI,
        );
        const messageContent: ArrayBuffer = await readMessage(this.sharedUsersStore.customMessage);
        await uploadMessage(
            messageContent,
            fileName,
            url,
            this.uploadInstance,
            fid,
            isOnprem,
        );
        return fid;
    };

    uploadMessage = async (): Promise<boolean> => {
        let fileId: string;
        try {
            fileId = await this.uploadMessageMetadata();
        } catch (error) {
            console.log('could not upload message', error);
            captureErrorForSentry(error, 'UploadFilesStore.uploadFile');
        }
        if (fileId) {
            this.sharedUsersStore.setMessageId(fileId);
        }
        return !!fileId;
    };

    injectDefaultPolicies = (): void => {
        const { defaultPolicyWithMFA } = this.policyStore;
        if (defaultPolicyWithMFA) {
            this.setFilesList(this.uploadedFiles.map((file) => {
                let newFile: UploadedFile = file;
                if (!newFile.policy && !checkIsFileInFolder(file)) {
                    newFile = { ...newFile, policy: defaultPolicyWithMFA };
                }
                return newFile;
            }));
            this.folderUploader.injectInitPolicy(defaultPolicyWithMFA);
        }
    };

    private async tryUpdateFilePolicy({
        fileId,
        newPolicy,
        isExternalStorage,
        isFolder,
        isSilent,
    }: ChangePolicyConfig): Promise<boolean> {
        const { API } = this.authSettingsStore;
        let result: boolean;
        const hasLoading = !(isSilent || isExternalStorage);
        this.setItemPolicyErrorStatus(isFolder, fileId, false);
        if (hasLoading) {
            this.setIsLoading(true);
        }
        try {
            await changeFilePolicy(API, fileId, newPolicy.id);
            result = true;
            this.setItemPolicy(isFolder, fileId, newPolicy);
        } catch (error) {
            result = false;
            console.log('could not change file policy', error);
            this.setItemPolicyErrorStatus(isFolder, fileId, true);
            captureErrorForSentry(error, 'UploadFilesStore.tryUpdateFilePolicy');
        }
        if (hasLoading) {
            this.setIsLoading(false);
        }
        return result;
    }

    private partialUpdateFile(fileId: string, newDataPartial: Partial<UploadedFile>): void {
        this.setFilesList(partialUpdateUploadedItem<UploadedFile>(
            this.uploadedFiles,
            (file) => file.fid === fileId,
            newDataPartial,
        ));
    }

    private injectNewPolicy(fileId: string, newPolicy: PolicySimpleWithMFA): void {
        this.partialUpdateFile(fileId, { policy: newPolicy });
    }

    private setChangePolicyErrorStatus(fileId: string, hasPolicyError: boolean): void {
        this.partialUpdateFile(fileId, { hasPolicyError });
    }

    private setItemPolicyErrorStatus(isFolder: boolean, spxItemId: string, hasPolicyError: boolean): void {
        if (isFolder) {
            this.folderUploader.setChangePolicyErrorStatus(spxItemId, hasPolicyError);
        } else {
            this.setChangePolicyErrorStatus(spxItemId, hasPolicyError);
        }
    }

    private setItemPolicy(isFolder: boolean, spxItemId: string, policy: PolicySimpleWithMFA): void {
        if (isFolder) {
            this.folderUploader.injectNewPolicy(spxItemId, policy);
        } else {
            this.injectNewPolicy(spxItemId, policy);
        }
    }

    googleDriveChangePolicy = (isFolder: boolean, spxItemId: string, policy: PolicySimpleWithMFA) => {
        this.setItemPolicy(isFolder, spxItemId, policy);
    }

    changePolicy = async ({
        newPolicy,
        fileId,
        isFolder,
        isExternalStorage,
    }: ChangePolicyConfig): Promise<boolean> => (
        this.tryUpdateFilePolicy({
            fileId,
            newPolicy,
            isExternalStorage,
            isFolder,
            isSilent: false,
        })
    );

    private handleDeleteSuccess(fid: string, uid: string): RemoveResult {
        const pk = uid || fid;
        this.setFilesList(this.uploadedFiles.filter(
            ({ uid: uploadId, fid: fileId }) => ![uploadId, fileId].includes(pk),
        ));
        if (uid) {
            const process = this.uploadProcessesMap.get(uid);
            if (process?.folderInfo) {
                const { folderId, treeId } = process.folderInfo;
                this.folderUploader.removeFile(treeId, folderId, uid);
            }
            this.uploadProcessesMap.remove(uid);
        }
        if (uid === this.chosenForPolicyFileUid) {
            this.setChosenForPolicyFileUid('');
        }
        return 'success';
    }

    private cancelUploading(uid: string): void {
        this.uploadInstance.cancel(uid);
        this.cancellableAPI.cancelByPartialKey(uploadIdToRequestKey(uid));
    }

    private async removeFileFromBE(specterxFileId: string): Promise<RemoveResult> {
        let result: RemoveResult;
        try {
            const { API } = this.authSettingsStore;
            this.setIsLoading(true);
            await API.del(BASEURL.backend(), ENDPOINTS.deleteFile(specterxFileId), {});
            result = 'success';
        } catch (error) {
            const { statusCode } = getErrorResponse(error);
            if (statusCode === 404) {
                result = 'success';
            } else {
                console.log('could not remove file', error);
                captureErrorForSentry(error, 'UploadFilesStore.deleteFile');
                result = 'error';
            }
        } finally {
            this.setIsLoading(false);
        }
        return result;
    }

    private async removeFile(file: UploadedFile): Promise<RemoveResult> {
        const { uid } = file;
        let result: RemoveResult;
        this.cancelUploading(uid);
        const specterxFileId = this.uploadProcessesMap.get(uid)?.fid;
        if (!specterxFileId) {
            result = 'success';
        } else {
            result = await this.removeFileFromBE(specterxFileId);
        }
        if (result === 'success') {
            this.handleDeleteSuccess(specterxFileId, uid);
        }
        return result;
    }

    private async removeFolder(folder: UploadedRootFolder): Promise<RemoveResult> {
        const { fid: specterxFolderId, uid: treeId } = folder;
        this.setIsLoading(true);
        const uidsToRemove: string[] = this.folderUploader.prepareRemove(treeId);
        uidsToRemove.forEach((uid) => {
            this.cancelUploading(uid);
            this.failUploadingTask(uid);
        });
        const removeResult: RemoveResult = await this.removeFileFromBE(specterxFolderId);
        if (removeResult === 'success') {
            const uidsToRemoveSet: Set<string> = new Set<string>(uidsToRemove);
            uidsToRemove.forEach((uid) => this.uploadProcessesMap.remove(uid));
            this.setFilesList(this.uploadedFiles.filter(({ uid }) => !uidsToRemoveSet.has(uid)));
            this.folderUploader.removeTree(treeId);
        }
        return removeResult;
    }

    removeUploadingItem = async (item: UploadedItem): Promise<RemoveResult> => {
        if (item.isFolder) {
            return this.removeFolder(item as UploadedRootFolder);
        }
        return this.removeFile(item as UploadedFile);
    };

    private async uploadSinglePart(uploadOptions: UploadProgressControls, parentFolder: string): Promise<string> {
        const { file } = uploadOptions;
        const { hasNASStorage } = this.authSettingsStore;
        const {
            file_id: fid, url, is_onprem: isOnprem,
        }: SinglePartUploadInfo = await createSinglePartFileMetadata(
            file,
            parentFolder,
            hasNASStorage,
            this.cancellableAPI,
        );
        this.uploadProcessesMap.update(file.uid, { fid });
        this.uploadProcessesMap.updateProgressSnapshot(file.uid, { singlePart: { url, isOnprem } });
        const fileContent: ArrayBuffer = await readSinglePartFile(uploadOptions.file);
        await uploadSinglePartFile(
            fileContent,
            url,
            uploadOptions,
            isOnprem,
            this.uploadInstance,
        );
        return fid;
    }

    private async retrySinglePart(uid: string, uploadOptions: UploadProgressControls): Promise<void> {
        const { file } = uploadOptions;
        const { progressSnapshot: { singlePart } } = this.uploadProcessesMap.get(uid);
        const fileContent: ArrayBuffer = await readSinglePartFile(file);
        await uploadSinglePartFile(
            fileContent,
            singlePart.url,
            uploadOptions,
            singlePart.isOnprem,
            this.uploadInstance,
        );
    }

    private async processMultipartUpload(
        controls: UploadProgressControls,
        uploadDetails: MultipartUpload,
    ): Promise<void> {
        const { cancellableAPI } = this;
        const uploadResults = await multipartUploadWorker(
            PARALLEL_UPLOAD_PROCESSES_COUNT,
            CHUNK_SIZE,
            controls,
            uploadDetails,
            cancellableAPI,
            this.uploadInstance,
            this.uploadProcessesMap,
        );
        await completeMultipartUploading(
            uploadResults,
            uploadDetails,
            controls.file.uid,
            cancellableAPI,
        );
    }

    private async retryMultipart(uid: string, uploadOptions: UploadProgressControls): Promise<void> {
        const { progressSnapshot: { multiPart } } = this.uploadProcessesMap.get(uid);
        const { bucketData: uploadDetails } = multiPart;
        await this.processMultipartUpload(uploadOptions, uploadDetails);
    }

    private async retryUpload(uid: string): Promise<void> {
        const process = this.uploadProcessesMap.get(uid);
        if (process?.progressSnapshot) {
            const {
                fid,
                progressSnapshot: {
                    controls, singlePart, multiPart,
                },
            } = process;
            try {
                if (singlePart) {
                    await this.retrySinglePart(uid, controls);
                } else if (multiPart) {
                    await this.retryMultipart(uid, controls);
                } else {
                    throw new NoProcessError(`Process ${uid} doesn't have both single part and multipart upload info`);
                }
                this.processUploadSuccess({}, controls.file);
                if (!checkIsFileInFolder(controls.file)) {
                    this.tryAttachFuturePolicy(uid, fid, false);
                }
            } catch (error) {
                console.log(`Could not retry upload ${uid}:`, error);
                this.processUploadError(error as Error, controls.file);
            }
        }
    }

    private async uploadMultipart(controls: UploadProgressControls, parentFolder: string): Promise<string> {
        const { file } = controls;
        const { cancellableAPI } = this;
        const uploadDetails = await getUploadDetailsMultipart(cancellableAPI, file, parentFolder);
        const { file_id: fid } = uploadDetails;
        this.uploadProcessesMap.update(file.uid, { fid });
        this.uploadProcessesMap.updateProgressSnapshot(file.uid, {
            multiPart: { entityTags: [], bucketData: uploadDetails },
        });
        await this.processMultipartUpload(controls, uploadDetails);
        return fid;
    }

    private async tryAttachFuturePolicy(uid: string, fileId: string, isFolder: boolean): Promise<void> {
        const { policy: newPolicy, itemsUIDsSet } = this.futurePolicy;
        const { defaultPolicyWithMFA } = this.policyStore;

        const isFuturePolicy = newPolicy && newPolicy.id !== defaultPolicyWithMFA?.id && itemsUIDsSet.has(uid);
        const initPolicy: PolicySimpleWithMFA = isFuturePolicy ? newPolicy : defaultPolicyWithMFA;
        if (initPolicy) {
            this.setItemPolicy(isFolder, fileId, initPolicy);
        }
        if (isFuturePolicy) {
            itemsUIDsSet.delete(uid);
            const result = await this.tryChangePolicySilently({
                fileId,
                newPolicy,
                isFolder,
                isExternalStorage: false,
            });
            if (!result) {
                if (defaultPolicyWithMFA) {
                    this.setItemPolicy(isFolder, fileId, initPolicy);
                }
            }
        }
    }

    private processUploadSuccess(data: Dict, file: RcFile): void {
        this.changeUploadingStatus(mergePropsToRcFile(file, { status: 'done', response: data }));
    }

    private processUploadError(error: Error, file: RcFile): void {
        this.changeUploadingStatus(mergePropsToRcFile(file, { error, status: 'error' }));
        captureErrorForSentry(error, 'UploadFilesStore.uploadFile');
        handleUploadError(error, file.name);
    }

    private processProgress({ percent }: UploadProgressEvent, file: RcFile): void {
        this.changeUploadingStatus(mergePropsToRcFile(file, { percent, status: 'uploading' }));
    }

    uploadFile = async (file: RcFile): Promise<void> => {
        const uploadControls = {
            file,
            onProgress: (event: UploadProgressEvent): void => {
                this.processProgress(event, file);
            },
        };
        const process: UploadProcess = this.uploadProcessesMap.get(file.uid);
        if (!process || process.completed) {
            console.warn('could not start process', file.uid);
            this.processUploadError(
                new NoProcessError(`Upload process ${file.uid} has been completed or already removed`),
                file,
            );
        } else {
            await this.enqueueFile(file.uid, file.size);
            this.uploadProcessesMap.update(file.uid, { progressSnapshot: { controls: uploadControls } });
            const { myFilesParentFolderId } = process;
            const parentFolderId: string = (
                this.uploadProcessesMap.get(file.uid).folderInfo?.specterxFolderId
                || myFilesParentFolderId
            );
            const isSinglePart = this.authSettingsStore.hasNASStorage || file.size < SMALL_FILE_LIMIT;
            try {
                let fileId: string;
                if (isSinglePart) {
                    fileId = await this.uploadSinglePart(uploadControls, parentFolderId);
                } else {
                    fileId = await this.uploadMultipart(uploadControls, parentFolderId);
                }

                let scanStatus: boolean;
                if (appConfig.ENABLE_OPSWAT_FILE_SCANNING === true) {
                    message.info(i18n.t(`${messagesNameSpace}.info.scanStart`), SCAN_MESSAGE_DURATION_SECONDS);
                    // It required for files with 0 size, because onProgress dont call during the uploading
                    uploadControls.onProgress({ percent: FULL_PERCENTS });
                    scanStatus = await waitForScanning(this.cancellableAPI, fileId, file.uid);
                }
                if (scanStatus === false) {
                    message.info(i18n.t(`${messagesNameSpace}.info.scanInProgress`), SCAN_MESSAGE_DURATION_SECONDS);
                } else if (scanStatus === true) {
                    message.info(i18n.t(`${messagesNameSpace}.info.scanPass`), SCAN_MESSAGE_DURATION_SECONDS);
                }

                this.processUploadSuccess({}, file);

                if (!checkIsFileInFolder(file)) {
                    this.tryAttachFuturePolicy(file.uid, fileId, false);
                }
            } catch (error) {
                console.log('could not upload file', error);
                captureErrorForSentry(error, 'UploadFilesStore.uploadFile');
                this.processUploadError(error as Error, file);
            }
        }
    };

    private resetStatus(controls: UploadProgressControls): void {
        const { file } = controls;
        const fileItem = this.uploadedFiles.find(({ uid }) => uid === file.uid);
        const newFile = { ...fileItem, status: 'uploading', error: null };
        this.uploadProcessesMap.update(file.uid, { completed: false });
        this.changeUploadingStatus(newFile as AntUploadedFile);
    }

    retry = async (uid: string): Promise<void> => {
        const process = this.uploadProcessesMap.get(uid);
        if (!process?.progressSnapshot) {
            const errorMessage = `Could not retry process ${uid}. Process has been removed or completed`;
            console.error(errorMessage);
            captureErrorForSentry(new Error(errorMessage), 'UploadStore.retry');
            // TODO: call message in component
            message.error(i18n.t('uploadFiles.messages.error.retry'));
        } else {
            const { controls } = process.progressSnapshot;
            this.resetStatus(controls);
            if (!process.fid) {
                await this.uploadFile(controls.file);
            } else {
                await this.enqueueFile(uid, controls.file.size);
                await this.retryUpload(uid);
            }
        }
    };

    private failUploadingTask(uid: string): void {
        this.uploadProcessesMap.complete(uid, false);
    }

    private updateFileStatus(targetFile: AntUploadedFile): void {
        const { uid: targetFileUid } = targetFile;
        const uploadProcess = this.uploadProcessesMap.get(targetFileUid);
        if (uploadProcess) {
            const uploadedFileId = uploadProcess.fid;
            const targetFileWithExtraMetadata: UploadedFile = {
                ...targetFile,
                fid: uploadedFileId,
                filename: targetFile.name,
            };
            const newFilesList = this.uploadedFiles.map((file) => (
                file.uid === targetFileWithExtraMetadata.uid ? { ...file, ...targetFileWithExtraMetadata } : file
            ));
            this.setFilesList(newFilesList);
        }
    }

    private changeUploadingStatus(file: AntUploadedFile): void {
        // TODO: provide more clear way with passing uid and partial file data
        const { status, uid } = file;
        const process: UploadProcess = this.uploadProcessesMap.get(uid);
        if (process && !process.completed) {
            this.updateFileStatus(file);

            if (FINAL_STATES_SET.has(status)) {
                this.uploadProcessesMap.complete(uid, status !== 'error');
            }
        }
    }

    appendFile = async ({ file, myFilesParentFolderId, folder }: {
        file: RcFile,
        myFilesParentFolderId?: string,
        folder?: OSFolder,
    }): Promise<void> => {
        const { uid } = file;
        if (!this.uploadProcessesMap.has(uid)) {
            this.uploadProcessesMap.add({ uid, completed: false, myFilesParentFolderId });
        }
        await this.insertionBuffer.appendFile(file, folder);
    };

    private onFillInsertionBuffer(deferredFiles: DeferredFile[]): void {
        // TODO: solve it through limit prop
        if (
            UPLOAD_FILES_COUNT_LIMIT
            && (deferredFiles.length + this.allFilesCount > UPLOAD_FILES_COUNT_LIMIT)
        ) {
            deferredFiles.forEach(({ file, cancel }) => {
                this.uploadProcessesMap.remove(file.uid);
                cancel(new Error('Upload counts limit reached'));
            });
            message.error({
                content: i18n.t(`${messagesNameSpace}.error.filesCountLimit`, { limit: UPLOAD_FILES_COUNT_LIMIT }),
                duration: ERROR_MESSAGE_DURATION_SECONDS,
                key: 'validateUploadFilesCount',
            });
        } else {
            const newFiles: UploadedFile[] = [];
            const foldersFiles: DeferredFileToFolder[] = [];
            deferredFiles.forEach(({
                file,
                folder,
                cancel,
                next,
            }) => {
                if (this.uploadProcessesMap.get(file.uid)?.completed) {
                    cancel(new Error(`Process ${file.uid} already completed`));
                } else {
                    newFiles.push(file);
                    if (folder) {
                        foldersFiles.push({ file, folder });
                    }
                    next();
                }
            });
            this.setFilesList([...this.uploadedFiles, ...newFiles]);
            this.folderUploader.createNewTrees(foldersFiles);
        }
    }

    private onInsertFileIntoFolder(uid: string, folderInfo: UploadedFolderInfo): void {
        if (folderInfo) {
            this.uploadProcessesMap.update(uid, { folderInfo });
        } else {
            const file = this.uploadedFiles.find(({ uid: uploadId }) => uploadId === uid);
            this.changeUploadingStatus({ ...file, status: 'error' } as AntUploadedFile);
        }
    }

    private onPreloadError(error: unknown, file: RcFile): void {
        captureErrorForSentry(error, 'UploadFilesStore.runPreloadActions');
        const antFileWithError = createAntFile(file, 'error');
        this.changeUploadingStatus(antFileWithError);
        throw error;
    }

    runCreateFolderActions = async (file: RcFile): Promise<void> => {
        try {
            const { folderInfo: { folderId, treeId }, myFilesParentFolderId } = this.uploadProcessesMap.get(file.uid);
            const specterxFolderId: string = await this.folderUploader.waitForBEFolder(
                treeId,
                folderId,
                myFilesParentFolderId,
            );
            this.uploadProcessesMap.update(
                file.uid,
                ({ folderInfo }) => ({ folderInfo: { ...folderInfo, specterxFolderId } }),
            );
        } catch (error) {
            this.onPreloadError(error, file);
        }
    };

    private async enqueueFile(uid: string, fileSize: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.uploadQueue.enqueue({
                id: uid,
                fileSize,
                start: resolve,
                cancel: reject,
            });
            this.uploadProcessesMap.update(uid, {
                onFinish: (succeed: boolean) => this.uploadQueue.finish(uid, succeed),
            });
        });
    }

    clear = (): void => {
        this.setIsLoading(false);
        this.setFilesList([]);
        this.setChosenForPolicyFileUid('');
        this.setS3UploadLimit(S3_UPLOAD_LIMIT);
        this.folderUploader.clear();
        this.uploadProcessesMap.clear();
        this.uploadQueue.clear();
        this.futurePolicy = { policy: null, itemsUIDsSet: new Set<string>() };
    };

    private async tryChangePolicySilently(
        {
            fileId,
            newPolicy,
            isExternalStorage,
            isFolder,
        }: ChangePolicyConfig,
    ): Promise<boolean> {
        return this.tryUpdateFilePolicy({
            fileId,
            newPolicy,
            isExternalStorage,
            isFolder,
            isSilent: true,
        });
    }

    private createFuturePolicy(policy: PolicySimpleWithMFA): void {
        const filesUIDs = this.uploadedFiles.reduce<string[]>((acc, file) => {
            if (file.status === 'uploading' && !checkIsFileInFolder(file)) {
                acc.push(file.uid);
            }
            return acc;
        }, []);
        const folderUIDs = this.folderUploader.rootsInProgress.map(({ uid }) => uid);
        this.futurePolicy = {
            policy,
            itemsUIDsSet: new Set<string>([...filesUIDs, ...folderUIDs]),
        };
    }

    bulkChangePolicy = async (newPolicy: PolicySimpleWithMFA, isExternalStorage: boolean): Promise<void> => {
        const itemsForUpdate = this.successfullyUploadedItems
            .filter(({ policy }) => policy && policy.id !== newPolicy.id);
        this.createFuturePolicy(newPolicy);
        this.setIsLoading(true);
        const pendingPromises: Promise<boolean>[] = [];
        itemsForUpdate.forEach(({ fid, isFolder = false }) => {
            pendingPromises.push(this.tryChangePolicySilently({
                fileId: fid,
                newPolicy,
                isExternalStorage,
                isFolder,
            }));
        });
        await Promise.all(pendingPromises);
        this.setIsLoading(false);
    };

    // TODO: move it into component
    takeWorkspaceFilesOwnership = async (workspaceId: string): Promise<boolean> => {
        const { successfullyUploadedItems } = this;
        const requestBody = successfullyUploadedItems.map(({ fid }) => ({ file_id: fid }));
        try {
            const { API } = this.authSettingsStore;
            this.setIsLoading(true);
            await API.patch(BASEURL.backend(), ENDPOINTS.changeOwner(workspaceId), { body: { files: requestBody } });
            return true;
        } catch (error) {
            console.log('could not take ownership', error);
            captureErrorForSentry(error, 'UploadFilesStore.cannotTakeOwnership');
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default UploadFilesStore;
