import isEqual from 'lodash/isEqual';

import { DAYS_RETENTION_VALUE } from '@/consts';
import { Policy, PolicySetting, PolicySettingsAll } from '../../../../types/types';
import { BooleanIdToValue } from '../../interfaces';
import { MAP_BOOLEAN_SETTING_ID_TO_DROPDOWN_ID, SQUASH_SETTINGS } from './config';
import { DAYS_RETENTION_RANGE } from '../../dataRetention';
import { getDaysRangeSetting } from '../common';

type SquashedSettingsDict = Record<string, PolicySetting[]>;

interface SettingsToSquash {
    squashedSettings: SquashedSettingsDict;
    otherSettings: PolicySetting[];
}

const splitSettingsToSquash = (settingsList: PolicySetting[]): SettingsToSquash => {
    const squashedSettings: SquashedSettingsDict = {};
    const otherSettings: PolicySetting[] = [];
    settingsList.forEach((setting) => {
        const dropdownSettingId = MAP_BOOLEAN_SETTING_ID_TO_DROPDOWN_ID[setting.id];
        if (dropdownSettingId) {
            squashedSettings[dropdownSettingId] = [...(squashedSettings[dropdownSettingId] || []), setting];
        } else {
            otherSettings.push(setting);
        }
    });
    return {
        squashedSettings,
        otherSettings,
    };
};

const createDropdownSettings = (squashedSettings: SquashedSettingsDict): PolicySetting[] => {
    const result: PolicySetting[] = [];
    Object.keys(squashedSettings).forEach((key) => {
        const booleansDict: BooleanIdToValue<string> = squashedSettings[key].reduce((acc, setting) => {
            acc[setting.id] = setting.value;
            return acc;
        }, {});
        const settingsConfig = SQUASH_SETTINGS[key];
        if (settingsConfig) {
            const { rules, to } = SQUASH_SETTINGS[key];
            const resultValue = Object.keys(rules).find((dropdownValue) => (
                Array.isArray(rules[dropdownValue])
                    ? (rules[dropdownValue] as BooleanIdToValue<string>[]).find(
                        (settingsDict) => isEqual(settingsDict, booleansDict),
                    )
                    : isEqual(rules[dropdownValue], booleansDict)
            ));
            if (resultValue) {
                result.push({ ...to, value: resultValue });
            }
        }
    });
    return result;
};

const transformSettings = (
    policy: Policy,
    settingsType: keyof PolicySettingsAll,
    transformWorker: (settingsList: PolicySetting[]) => PolicySetting[],
): Policy => {
    const settingsList = policy.settings?.[settingsType];
    if (!settingsList) {
        return policy;
    }
    return {
        ...policy,
        settings: {
            ...policy.settings,
            [settingsType]: transformWorker(settingsList),
        },
    };
};

export const squashSettings = (policy: Policy, settingsType: keyof PolicySettingsAll): Policy => transformSettings(
    policy,
    settingsType,
    (settingsList) => {
        const { squashedSettings, otherSettings } = splitSettingsToSquash(settingsList);
        const dropdownSettings = createDropdownSettings(squashedSettings);
        return [...dropdownSettings, ...otherSettings];
    },
);

interface SettingsToExpand {
    dropdownSettings: PolicySetting[];
    otherSettings: PolicySetting[];
}

const splitSettingsToExpand = (settingsList: PolicySetting[]): SettingsToExpand => {
    const otherSettings: PolicySetting[] = [];
    const dropdownSettings: PolicySetting[] = [];
    settingsList.forEach((setting) => {
        if (SQUASH_SETTINGS[setting.id]) {
            dropdownSettings.push(setting);
        } else {
            otherSettings.push(setting);
        }
    });
    return {
        otherSettings,
        dropdownSettings,
    };
};

const restoreBooleanSettings = (dropdownSettings: PolicySetting[]): PolicySetting[] => {
    const result: PolicySetting[] = [];
    dropdownSettings.forEach(({ id, value }) => {
        const settingsConfig = SQUASH_SETTINGS[id];
        if (settingsConfig) {
            const { rules, from } = SQUASH_SETTINGS[id];
            const settings: PolicySetting[] = from.map<PolicySetting>((template) => ({
                ...template,
                value: Array.isArray(rules[value as string])
                    ? rules[value as string][0][template.id]
                    : rules[value as string][template.id],
            }));
            result.push(...settings);
        }
    });
    return result;
};

export const expandSettings = (policy: Policy, settingsType: keyof PolicySettingsAll): Policy => transformSettings(
    policy,
    settingsType,
    (settingsList) => {
        const { otherSettings, dropdownSettings } = splitSettingsToExpand(settingsList);
        const originalBooleanSettings = restoreBooleanSettings(dropdownSettings);
        return [...originalBooleanSettings, ...otherSettings];
    },
);

const setInitDaysRetentionSetting = (policy: Policy): void => {
    const daysRangeSetting = getDaysRangeSetting(policy);
    if (daysRangeSetting && typeof daysRangeSetting.value !== 'number') {
        const daysRangeSettingIndex = policy.settings.access.findIndex(
            ({ id }) => id === DAYS_RETENTION_VALUE,
        );
        // eslint-disable-next-line no-param-reassign
        policy.settings.access[daysRangeSettingIndex] = {
            ...daysRangeSetting,
            value: DAYS_RETENTION_RANGE.min,
        };
    }
};

export const preparePolicyForEditor = (policy: Policy): Policy => {
    const squashedPolicy = squashSettings(policy, 'access');
    if (!squashedPolicy.id) {
        // Set default value to days retention if it doesn't exist in new policy
        setInitDaysRetentionSetting(squashedPolicy);
    }
    return squashedPolicy;
};
