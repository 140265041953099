import React, {
    ChangeEvent,
    FC,
    KeyboardEvent,
    useEffect,
    useState,
} from 'react';

import {
    Button, Dropdown, message, Space,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import classNames from 'classnames';

import { Input } from '@/components/Common/UIKit';
import RecipientChip from '../../../Common/SharingBlock/RecipientListItem/RecipientChip';
import { CognitoGroupsOrganizations } from '@/types/types';
import { NewMember } from '@/stores/UsersListStore/interfaces';
import { findEmailInString } from '@/components/utils';

import styles from './AddMemberModal.module.scss';

const addMembersNameSpace = 'userRoleEditor.addMembersModal';
const ALL_ROLES_LIST = Object.values(CognitoGroupsOrganizations);

interface InvitingPageInterface {
    userList: NewMember[];
    setUserList: (newValue: NewMember[]) => void;
}

const InvitingPage: FC<InvitingPageInterface> = observer(({ userList, setUserList }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string>('');
    const [errorStatus, setErrorStatus] = useState<boolean>(false);

    const inviteUsers = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (errorStatus || !inputValue) {
            message.error(t(`${addMembersNameSpace}.noEmail`));
            return null;
        }
        const emails = findEmailInString((event.target as HTMLInputElement).value);
        const recipientsToAdd = emails.map((email) => ({ email, role: CognitoGroupsOrganizations.Collaborators }));
        setUserList([...userList, ...recipientsToAdd]);
        setInputValue('');
    };
    const onClickButton = (): void => {
        const emails = findEmailInString(inputValue);
        if (emails) {
            const recipientsToAdd = emails.map((email) => ({ email, role: CognitoGroupsOrganizations.Collaborators }));
            setUserList([...userList, ...recipientsToAdd]);
            setInputValue('');
        } else {
            message.error(t(`${addMembersNameSpace}.noEmail`));
        }
    }

    useEffect(() => {
        const emails = findEmailInString(inputValue);
        setErrorStatus(!emails && !!inputValue);
    }, [inputValue]);

    const setEmailHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        setInputValue(event.target.value);
    };

    const onRemoveClick = (emailToDelete: string): void => {
        setUserList([...userList.filter(({ email }) => email !== emailToDelete)]);
    };

    const changeUserRole = (event: MenuInfo, invitedEmail: string): void => {
        const changedUsers = userList.map(({ email, role }) => {
            if (email === invitedEmail) {
                return { email, role: event.key as CognitoGroupsOrganizations };
            }
            return { email, role };
        });
        setUserList([...changedUsers]);
    };

    return (
        <>
            <span className={styles['input-title']}>{t(`${addMembersNameSpace}.addMembers`)}</span>
            <Space.Compact style={{ width: '100%' }}>
                <Input
                    placeholder={t(`${addMembersNameSpace}.typeEmailAddress`)}
                    onPressEnter={inviteUsers}
                    value={inputValue}
                    onChange={setEmailHandler}
                    status={errorStatus && 'error'}
                />
                <Button type="primary" onClick={onClickButton}>{t(`${addMembersNameSpace}.add`)}</Button>
            </Space.Compact>
            <ul className={styles['users-list']}>
                {
                    userList.map(({ email, role }) => (
                        <li key={email}>
                            <div className={styles['recipient-row']}>
                                <RecipientChip
                                    content={email}
                                    onClose={() => onRemoveClick(email)}
                                />
                                <div className={styles['file-access']}>
                                    <Dropdown
                                        trigger={['click']}
                                        menu={{
                                            className: styles['role-select'],
                                            onClick: (event) => changeUserRole(event, email),
                                            items: ALL_ROLES_LIST.map((cognitoRole) => ({
                                                key: cognitoRole,
                                                className: classNames(
                                                    styles.item,
                                                    { [styles.chosen]: cognitoRole === role },
                                                ),
                                                label: (
                                                    <span className={styles['role-title']}>
                                                        {t(`${addMembersNameSpace}.groups.${cognitoRole}`)}
                                                    </span>
                                                ),
                                            })),
                                        }}
                                    >
                                        <Button
                                            type="link"
                                            size="small"
                                            className={styles['select-trigger']}
                                        >
                                            <span className={styles['role-title']}>
                                                {t(`${addMembersNameSpace}.groups.${role}`)}
                                            </span>
                                            <CaretDownOutlined className={styles.icon} />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </>
    );
});

export default InvitingPage;
