import React, {
    createContext,
    FC,
    ReactElement,
    useContext,
    useState,
} from 'react';

import { IdentityType } from './interfaces';

type CodeSentMap = Readonly<Record<IdentityType, boolean>>;

interface AuthenticatorContext {
    onCodeSend: (identity: IdentityType) => void;
    codeSentMap: CodeSentMap;
}

export const SnapshotContext = createContext<AuthenticatorContext>(null);

export const useCodeAuthenticatorSnapshot = (): AuthenticatorContext => (
    useContext<AuthenticatorContext>(SnapshotContext)
);

const CODE_SENT_MAP_INIT: CodeSentMap = { email: false, phone: false };

interface ProviderProps {
    children: ReactElement | ReactElement[];
}

export const CodeAuthenticatorSnapshotProvider: FC<ProviderProps> = ({ children }) => {
    const [codeSentMap, setCodeSentMap] = useState<CodeSentMap>({ ...CODE_SENT_MAP_INIT });

    const onCodeSend = (identity: IdentityType): void => {
        setCodeSentMap((value) => ({ ...value, [identity]: true }));
    };

    return (
        <SnapshotContext.Provider
            value={{
                onCodeSend,
                codeSentMap,
            }}
        >
            {children}
        </SnapshotContext.Provider>
    );
};
