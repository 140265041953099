import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import useLatest from './useLatest';

type Key = string | number;

interface UseDynamicSizeListProps {
    itemsCount: number;
    itemHeight?: (index: number) => number;
    estimateItemHeight?: (index: number) => number;
    getItemKey: (index: number) => Key;
    overscan?: number;
    scrollingDelay?: number;
    getScrollElement: () => HTMLElement | null;
}

interface UseDynamicSizeList {
    virtualItems: DynamicSizeListItem[];
    totalHeight: number;
    startIndex: number;
    endIndex: number;
    isScrolling: boolean;
    allItems: DynamicSizeListItem[];
    measureElement: (element: Element | null) => void
}

interface DynamicSizeListItem {
    key: Key;
    index: number;
    offsetTop: number;
    height: number;
}

const DEFAULT_OVERSCAN = 3;
const DEFAULT_SCROLLING_DELAY = 150;

const validateProps = (props: UseDynamicSizeListProps): void => {
    const { itemHeight, estimateItemHeight } = props;

    if (!itemHeight && !estimateItemHeight) {
        throw new Error(
            'you must pass either "itemHeight" or "estimateItemHeight" prop',
        );
    }
};

const useDynamicSizeList = (props: UseDynamicSizeListProps): UseDynamicSizeList => {
    validateProps(props);

    const {
        itemHeight,
        estimateItemHeight,
        getItemKey,
        itemsCount,
        scrollingDelay = DEFAULT_SCROLLING_DELAY,
        overscan = DEFAULT_OVERSCAN,
        getScrollElement,
    } = props;

    const [measurementCache, setMeasurementCache] = useState<Record<Key, number>>(
        {},
    );
    const [listHeight, setListHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    useLayoutEffect(() => {
        const scrollElement = getScrollElement();

        if (!scrollElement) {
            return;
        }

        const resizeObserver = new ResizeObserver(([entry]) => {
            if (!entry) {
                return;
            }
            const height = entry.borderBoxSize[0]?.blockSize
        ?? entry.target.getBoundingClientRect().height;

            setListHeight(height);
        });

        resizeObserver.observe(scrollElement);

        // eslint-disable-next-line consistent-return
        return () => resizeObserver.disconnect();
    }, [getScrollElement]);

    useLayoutEffect(() => {
        const scrollElement = getScrollElement();

        if (!scrollElement) {
            return;
        }

        const handleScroll = (): void => {
            const { scrollTop: scrollElementScrollTop } = scrollElement;

            setScrollTop(scrollElementScrollTop);
        };

        handleScroll();

        scrollElement.addEventListener('scroll', handleScroll);

        // eslint-disable-next-line consistent-return
        return () => scrollElement.removeEventListener('scroll', handleScroll);
    }, [getScrollElement]);

    useEffect(() => {
        const scrollElement = getScrollElement();

        if (!scrollElement) {
            return;
        }

        let timeoutId: null | ReturnType<typeof setTimeout> = null;

        const handleScroll = (): void => {
            setIsScrolling(true);

            if (typeof timeoutId === 'number') {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                setIsScrolling(false);
            }, scrollingDelay);
        };

        scrollElement.addEventListener('scroll', handleScroll);

        // eslint-disable-next-line consistent-return
        return () => {
            if (typeof timeoutId === 'number') {
                clearTimeout(timeoutId);
            }
            scrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [getScrollElement]);

    const {
        virtualItems, startIndex, endIndex, totalHeight, allItems,
    } = useMemo(() => {
        const getItemHeight = (index: number): number => {
            if (itemHeight) {
                return itemHeight(index);
            }

            const key = getItemKey(index);
            if (typeof measurementCache[key] === 'number') {
                return measurementCache[key]!;
            }

            return estimateItemHeight!(index);
        };

        const rangeStart = scrollTop;
        const rangeEnd = scrollTop + listHeight;

        let totalContainerHeigh = 0;
        let containerStartIndex = -1;
        let containerEndIndex = -1;
        const allRows: DynamicSizeListItem[] = Array(itemsCount);

        for (let index = 0; index < itemsCount; index++) {
            const key = getItemKey(index);
            const row = {
                key,
                index,
                height: getItemHeight(index),
                offsetTop: totalContainerHeigh,
            };

            totalContainerHeigh += row.height;
            allRows[index] = row;

            if (containerStartIndex === -1 && row.offsetTop + row.height > rangeStart) {
                containerStartIndex = Math.max(0, index - overscan);
            }

            if (containerEndIndex === -1 && row.offsetTop + row.height >= rangeEnd) {
                containerEndIndex = Math.min(itemsCount - 1, index + overscan);
            }
        }

        const virtualRows = allRows.slice(containerStartIndex, containerEndIndex + 1);

        return {
            virtualItems: virtualRows,
            startIndex: containerStartIndex,
            endIndex: containerEndIndex,
            allItems: allRows,
            totalHeight: totalContainerHeigh,
        };
    }, [
        scrollTop,
        overscan,
        listHeight,
        itemHeight,
        getItemKey,
        estimateItemHeight,
        measurementCache,
        itemsCount,
    ]);

    const latestData = useLatest({
        measurementCache,
        getItemKey,
        allItems,
        getScrollElement,
        scrollTop,
    });

    const measureElementInner = useCallback(
        (
            element: Element | null,
            resizeObserver: ResizeObserver,
            entry?: ResizeObserverEntry,
        ) => {
            if (!element) {
                return;
            }

            if (!element.isConnected) {
                resizeObserver.unobserve(element);
                return;
            }

            const indexAttribute = element.getAttribute('data-index') || '';
            const index = parseInt(indexAttribute, 10);

            if (Number.isNaN(index)) {
                console.error(
                    'dynamic elements must have a valid `data-index` attribute',
                );
                return;
            }
            const {
                measurementCache: measurementCacheRef,
                getItemKey: getItemKeyRef,
                allItems: allItemsRef,
                scrollTop: scrollTopRef,
            } = latestData.current;

            const key = getItemKeyRef(index);
            const isResize = Boolean(entry);

            resizeObserver.observe(element);

            if (!isResize && typeof measurementCacheRef[key] === 'number') {
                return;
            }

            const height = entry?.borderBoxSize[0]?.blockSize
        ?? element.getBoundingClientRect().height;

            if (measurementCache[key] === height) {
                return;
            }

            const item = allItemsRef[index]!;
            const delta = height - item.height;

            if (delta !== 0 && scrollTopRef > item.offsetTop) {
                const scrollElement = getScrollElement();
                if (scrollElement) {
                    scrollElement.scrollBy(0, delta);
                }
            }

            setMeasurementCache((cache) => ({ ...cache, [key]: height }));
        },
        [latestData],
    );

    const itemsResizeObserver = useMemo(() => {
        const ro = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                measureElementInner(entry.target, itemsResizeObserver, entry);
            });
        });
        return ro;
    }, [measureElementInner]);

    const measureElement = useCallback(
        (element: Element | null) => {
            measureElementInner(element, itemsResizeObserver);
        },
        [itemsResizeObserver],
    );

    return {
        virtualItems,
        totalHeight,
        startIndex,
        endIndex,
        isScrolling,
        allItems,
        measureElement,
    };
};

export default useDynamicSizeList;
