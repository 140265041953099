import { useDraggable, useDroppable } from '@dnd-kit/core';
import { UniqueIdentifier } from '@dnd-kit/core/dist/types';
import { Data } from '@dnd-kit/core/dist/store';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core/dist/hooks/useDraggable';

import { DraggedFileData } from '../interfaces';

interface BaseDndHookConfig {
    id: UniqueIdentifier;
    data?: Data;
}

interface DraggedFileProps {
    fileId: string;
    filename: string;
    isFolder: boolean;
    itemKey: string;
}

const getBaseHookConfig = ({
    fileId,
    filename,
    isFolder,
    itemKey,
}: DraggedFileProps): BaseDndHookConfig => {
    const draggedFileData: DraggedFileData = {
        filename,
        isFolder,
        itemKey,
    };

    return {
        id: fileId,
        data: draggedFileData,
    };
};

interface SingleHookProps extends DraggedFileProps {
    disabled?: boolean;
}

type SetNodeRef = (element: HTMLElement | null) => void;

interface Draggable {
    attributes: DraggableAttributes;
    setNodeRef: SetNodeRef;
    listeners: SyntheticListenerMap;
}

export const useDraggableFile = ({ disabled = false, ...rest }: SingleHookProps): Draggable => {
    const baseHookConfig = getBaseHookConfig(rest);

    const {
        attributes,
        listeners,
        setNodeRef,
    } = useDraggable({ ...baseHookConfig, disabled });

    return {
        attributes,
        listeners,
        setNodeRef,
    };
};

interface Droppable {
    isOver: boolean;
    setNodeRef: SetNodeRef;
}

export const useDroppableFile = ({ disabled = false, ...rest }: SingleHookProps): Droppable => {
    const baseHookConfig = getBaseHookConfig(rest);

    const {
        isOver,
        setNodeRef,
    } = useDroppable({ ...baseHookConfig, disabled });

    return {
        isOver,
        setNodeRef,
    };
};

interface ComplexHookProps extends DraggedFileProps {
    isDragAllowed: boolean;
    isDropAllowed: boolean;
}

type OmitDragNDropAttributes = Omit<Draggable, 'attributes'> & Droppable;

interface DragAndDroppable extends OmitDragNDropAttributes {
    attributes: Partial<DraggableAttributes>;
}

export const useDragAndDroppable = ({
    isDragAllowed,
    isDropAllowed,
    ...rest
}: ComplexHookProps): DragAndDroppable => {
    const baseHookConfig = getBaseHookConfig(rest);

    const {
        attributes,
        listeners,
        setNodeRef: setDraggableRef,
    } = useDraggable({ ...baseHookConfig, disabled: !isDragAllowed });

    const {
        isOver,
        setNodeRef: setDroppableRef,
    } = useDroppable({ ...baseHookConfig, disabled: !isDropAllowed });

    const setNodeRef: SetNodeRef = isDropAllowed ? setDroppableRef : setDraggableRef;

    const dragAttributes: Partial<DraggableAttributes> = isDragAllowed ? attributes : {};
    const dragListeners: SyntheticListenerMap = isDragAllowed ? listeners : {};

    return {
        attributes: dragAttributes,
        listeners: dragListeners,
        isOver,
        setNodeRef,
    };
};
