import React, {
    FC,
    useEffect,
} from 'react';

import { observer } from 'mobx-react';
import { when } from 'mobx';

import i18n from '@/content';
import TabTitle from '../../Common/TabTitle';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import Spinner from '../../Common/Spin';
import PolicySettings from './PolicySettings';
import PolicyButtons from './PolicyButtons';
import PolicyEditorSidebar from './PolicyEditorSidebar';
import { useStores } from '../../hooks';
import styles from './PolicyEditor.module.scss';

const nameSpace = 'policyEditor';

type ReactionPromise = Promise<void> & { cancel(): void };

const PolicyEditor: FC = observer(() => {
    const { policyStore } = useStores();

    const {
        selectedPolicy,
        loadingPolicy,
        hasFilePolicyError,
        selectPolicyForEditing,
    } = policyStore;

    useEffect(() => {
        let reactionPromise: ReactionPromise;

        (async (): Promise<void> => {
            if (policyStore.loadingPolicy) {
                reactionPromise = when(() => !policyStore.loadingPolicy);
                await reactionPromise;
            } else {
                await policyStore.fetchPolicyList();
            }
            selectPolicyForEditing();
        })();

        return () => {
            policyStore.unmount();
            reactionPromise?.cancel();
        };
    }, []);

    const title = i18n.t(`${nameSpace}.title`);

    return (
        <Spinner spinning={loadingPolicy} screenCentered>
            <TabTitle title={title} />
            <div className={styles['policy-editor']}>
                <PolicyEditorSidebar title={title} />
                <div className={styles['policy-settings-container']}>
                    {hasFilePolicyError
                        ? (
                            <ErrorMessage
                                title={i18n.t('general.errors.1', { type: title })}
                                subtitle={i18n.t('general.errors.try')}
                            />
                        )
                        : selectedPolicy && <PolicySettings />}
                    {selectedPolicy && <PolicyButtons />}
                </div>
            </div>
        </Spinner>
    );
});

export default PolicyEditor;
