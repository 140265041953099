import { toJS } from 'mobx';

import { ROOT_FOLDER } from '@/consts';
import FilesTree from '../FilesManager/FilesTree';
import { SecuredFile } from '../../../types/types';
import {
    FileToDisplay,
    FolderChildren,
    FolderNode,
} from '../interfaces';
import { INIT_FOLDER_PAYLOAD } from '../options';

type ChildNodesMap = Readonly<Record<string, FolderNode>>;

export const createFolderKey = (parentKey: string, folderId: string): string => (
    parentKey === ROOT_FOLDER ? folderId : `${parentKey}/${folderId}`
);

export const parseFolderId = (key: string): string => {
    const breadCrumbs = key.split('/');
    return breadCrumbs[breadCrumbs.length - 1];
};

const getChildNodesMap = (children: FolderChildren): ChildNodesMap => children.reduce((acc, current) => {
    if (current.type === 'node') {
        acc[current.key] = toJS(current);
    }
    return acc;
}, {});

export const transformFilesToTreeNodes = (
    fileList: SecuredFile[],
    parentFolderNode: FolderNode,
): FolderChildren => {
    const childNodesMap: ChildNodesMap = getChildNodesMap(parentFolderNode.children || []);
    return fileList.map<FolderNode>((file: SecuredFile) => {
        const unproxifiedCopy: SecuredFile = toJS(file);
        const key: string = createFolderKey(parentFolderNode.key, file.fid);
        const currentNode: FolderNode = childNodesMap[key];
        if (currentNode) {
            currentNode.value = unproxifiedCopy;
            return currentNode;
        }
        return {
            key,
            parentKey: parentFolderNode.key,
            payload: file.is_folder ? { ...INIT_FOLDER_PAYLOAD } : null,
            type: file.is_folder ? 'node' : 'leaf',
            value: unproxifiedCopy,
        };
    });
};

const transformTreeNodes = <T>(childNodes: FolderChildren, transform: (value: FolderNode) => T): T[] => {
    if (!childNodes) {
        return [];
    }
    return childNodes.map<T>(transform);
};

export const transformTreeNodesToFiles = (childNodes: FolderChildren): SecuredFile[] => (
    transformTreeNodes<SecuredFile>(
        childNodes, ({ value }) => value,
    ));

export const transformTreeNodesToDisplayFiles = (childNodes: FolderChildren): FileToDisplay[] => (
    transformTreeNodes<FileToDisplay>(
        childNodes, ({ value, key }) => ({ ...value, itemKey: key }),
    ));

export const findRelatedFolder = (
    currentNode: FolderNode,
    filesTree: FilesTree,
    folderKey: string,
    isLevelDown = false,
): FolderNode => {
    let result: FolderNode;
    if (currentNode.key === folderKey) {
        result = currentNode;
    } else if (isLevelDown) {
        result = currentNode.children.find(
            (node) => node.type === 'node' && node.key === folderKey,
        ) as FolderNode;
    } else {
        result = filesTree.find(folderKey);
    }
    return result;
};

const filterByAddedNodes = (nodesList: FolderChildren, addedKeysSet: Set<string>): string[] => {
    const result: string[] = [];
    nodesList?.forEach((node) => {
        if (node.type === 'node' && addedKeysSet.has(node.key)) {
            result.push(node.key);
        }
    });
    return result;
};

export const getNewChildNodesKeys = (addedNodesKeys: string[], currentNode: FolderNode): string[] => {
    const { children: currentNodeChildren } = currentNode;
    const addedKeysSet: Set<string> = new Set<string>(addedNodesKeys);
    return filterByAddedNodes(currentNodeChildren, addedKeysSet);
};

export const getFolderBreadcrumbs = (tree: FolderNode, folderKey: string): FileToDisplay[] => {
    const breadcrumbsList: FileToDisplay[] = [];
    if (folderKey === ROOT_FOLDER) {
        return breadcrumbsList;
    }
    const folderIdsChain = folderKey.split('/');
    let parentNode: FolderNode = tree;
    folderIdsChain.forEach((folderId) => {
        const childFolderKey = createFolderKey(parentNode.key, folderId);
        const childNode = parentNode.children?.find(({ key }) => key === childFolderKey);
        if (childNode) {
            breadcrumbsList.push({ ...childNode.value, itemKey: childNode.key });
            parentNode = childNode;
        }
    });
    return breadcrumbsList;
};
