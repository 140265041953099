import React, { FC } from 'react';

/* eslint-disable max-len */
const Office365Svg: FC = () => (
    <svg enableBackground="new 0 0 2075 2499.8" viewBox="0 0 2075 2499.8" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m0 2016.6v-1519.8l1344.4-496.8 730.6 233.7v2045.9l-730.6 220.3-1344.4-483.3 1344.4 161.8v-1769.2l-876.8 204.6v1198.3z"
            fill="#eb3c00"
        />
    </svg>
);

export default Office365Svg;
