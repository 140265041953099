import {
    KeyboardSensor,
    MouseSensor,
    PointerActivationConstraint,
    SensorDescriptor,
    SensorOptions,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

const MOUSE_ACTIVATION_CONSTRAINT: PointerActivationConstraint = {
    distance: 3,
};

type DndSensors = SensorDescriptor<SensorOptions>[];

const useDndSensors = (): DndSensors => {
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: MOUSE_ACTIVATION_CONSTRAINT,
    });

    const keyboardSensor = useSensor(KeyboardSensor, {});

    return useSensors(mouseSensor, keyboardSensor);
};

export default useDndSensors;
