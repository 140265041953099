import { encryptValue, decryptValue } from './utils';

export default class WebappStorageCipherProxy {
    /**
     * Proxy class above WebApp storage. Provides encryption and decryption before set and get items
     */
    constructor(private storage: Storage) {}

    setItem(key: string, value: string): void {
        const encrypted: string = encryptValue(value);
        this.storage.setItem(key, encrypted);
    }

    getItem(key: string): string | null {
        const encrypted: string | null = this.storage.getItem(key);
        if (typeof encrypted === 'string') {
            // If we will change the salt value, decryptValue raises error
            try {
                return decryptValue(encrypted);
            } catch (e) {
                return null;
            }
        }
        return encrypted;
    }

    clear(): void {
        this.storage.clear();
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    get length(): number {
        return this.storage.length;
    }
}
