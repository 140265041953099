import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './Paginator.module.scss';

const nameSpace = 'recipientPage.filesPaginator.text';

export enum SelectionDirections {
    Prev = -1,
    Next = 1,
}

interface PaginatorProps {
    selectSiblingFile: (value: SelectionDirections) => void | Promise<void>,
    collapsed: boolean,
    selectedFileIndex: number,
    fileCount: number,
}

const Paginator: FC<PaginatorProps> = observer(({
    selectSiblingFile,
    collapsed,
    selectedFileIndex,
    fileCount,
}) => {
    const { t, i18n } = useTranslation();

    return (
        <span
            className={classNames(
                styles['arrow-button'],
                styles[i18n.dir()],
                {
                    [styles['single-position']]: collapsed,
                    [styles['multi-file-mode']]: !collapsed,
                },
            )}
        >
            <LeftOutlined
                className={
                    classNames(
                        styles['arrow-icon'],
                        {
                            [styles['active-btn']]: selectedFileIndex,
                        },
                    )
                }
                onClick={() => selectSiblingFile(SelectionDirections.Prev)}
            />
            {t(nameSpace, { index: selectedFileIndex + 1, count: fileCount })}
            <RightOutlined
                className={
                    classNames(
                        styles['arrow-icon'],
                        {
                            [styles['active-btn']]: selectedFileIndex + 1 !== fileCount,
                        },
                    )
                }
                onClick={() => selectSiblingFile(SelectionDirections.Next)}
            />
        </span>
    );
});

export default Paginator;
