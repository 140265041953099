import React, {
    CSSProperties,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import Modal, {
    InitModalStyle,
    MODAL_PADDING_8_STEPS,
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
} from '../../Common/Modal';
import FileSavingIndicator from './FileSavingIndicator';
import LockModalContent from './LockSingaturesModalContent';
import { useMounted } from '../../hooks';
import {
    usePSPDFKitLifeCycle,
    useWatermarks,
    useFileSaving,
} from './hooks';
import { SimpleCallback } from '../../../types/types';
import { Instance, Size } from './pspdfkitTypes';
import { IS_LOCAL_ENV } from '../../../config/env';
import {
    emailWatermarkPlugin,
    getToolbarItems,
    imageWatermarkPlugin,
    preventDefault,
} from './helpers';
import { LICENSE_KEY, CAROUSEL_MODE_STYLESHEET } from './constants';
import styles from './PDFViewer.module.scss';

const MODAL_STYLE: InitModalStyle = {
    additionalWrapClassName: classNames(MODAL_DISABLE_MIN_HEIGHT_CLASS, MODAL_PADDING_8_STEPS),
    initWidth: 400,
};

export interface PDFViewerPublicProps {
    setIsLoading: (value: boolean) => void;
    fileId: string;
    filePolicyId: string;
    isEditable: boolean;
    carouselMode?: boolean;
    PDFViewerClassName?: string;
    PDFViewerStyle?: CSSProperties;
    onSaveSignature?: SimpleCallback;
}

interface PDFViewerProps extends PDFViewerPublicProps {
    documentUrl: string;
}

const PDFViewer: FC<PDFViewerProps> = observer(({
    PDFViewerClassName,
    documentUrl,
    fileId,
    setIsLoading,
    filePolicyId,
    isEditable,
    PDFViewerStyle,
    carouselMode = false,
    onSaveSignature,
}) => {
    const [isLockModalOpen, setIsLockModalOpen] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>();
    const isInitializedRef = useRef<boolean>(null);
    const PDFInstanceRef = useRef<Instance>(null);
    const savingDisposerRef = useRef<SimpleCallback>(null);

    const isMountedRef = useMounted();

    const {
        isSavingInProgress,
        hasError,
        hasUnFlattenAnnotations,
        connectInstance,
        saveFile,
        processUnFlattenAnnotations,
    } = useFileSaving(fileId, onSaveSignature);

    const watermarks = useWatermarks({
        filePolicyId,
        isMountedRef,
        setIsLoading,
    });

    const changeLifeCycleStage = usePSPDFKitLifeCycle();

    const onInstall = (): void => {
        changeLifeCycleStage('installed', () => setIsLoading(true));
        processUnFlattenAnnotations(PDFInstanceRef.current);
    };

    const onFirstRender = (): void => {
        changeLifeCycleStage('rendered', () => setIsLoading(false));
    };

    const handleInstance = (onInstallCallback?: SimpleCallback): void => {
        /*
        * readOnly to disable annotations/highlights
        * allowPrinting to enable/disable print:
        * not enough to hide print button, user can still Ctrl+P to print
        * */
        PDFInstanceRef.current?.setViewState((state) => state
            .set('readOnly', !isEditable)
            .set('allowPrinting', false)
            .set('allowExport', isEditable)
        );

        if (isEditable) {
            savingDisposerRef.current = connectInstance(PDFInstanceRef.current);
            PDFInstanceRef.current?.contentDocument.removeEventListener('contextmenu', preventDefault);
        } else {
            savingDisposerRef.current?.();
            PDFInstanceRef.current?.contentDocument.addEventListener('contextmenu', preventDefault);
        }

        PDFInstanceRef.current?.setToolbarItems((items) => {
            /*
            * Looks like pdfInstance doesn't have any specific hooks which being called on installation.
            * That's why we run side effect into setToolbarItems
            * */
            onInstallCallback?.();
            return getToolbarItems(items, isEditable);
        });
    };

    // Render PDF container after watermark is loaded or if options changed
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const container = containerRef.current;
        if (watermarks && !isInitializedRef.current) {
            (async function () {
                PDFInstanceRef.current = await window.PSPDFKit.load({
                    licenseKey: !IS_LOCAL_ENV ? LICENSE_KEY : undefined,
                    // Dismiss copying and selection for readonly user
                    preventTextCopy: !isEditable,
                    disableTextSelection: !isEditable,
                    styleSheets: carouselMode ? [CAROUSEL_MODE_STYLESHEET] : [],
                    // Container where PSPDFKit should be mounted.
                    container,
                    // The document to open.
                    document: documentUrl,
                    baseUrl: `${window.location.protocol}//${window.location.host}/`,
                    renderPageCallback(
                        ctx: CanvasRenderingContext2D,
                        pageIndex: number,
                        pageSize: Size,
                    ) {
                        onFirstRender();
                        // Draw image Watermark on every page.
                        imageWatermarkPlugin(watermarks.logo, ctx, pageSize);
                        if (watermarks.email) {
                            // Email watermark on every page.
                            emailWatermarkPlugin(watermarks.email, ctx, pageSize);
                        }
                    },
                    isAPStreamRendered: () => false,
                    renderPagePreview: false,
                    toolbarItems: [],
                    theme: window.PSPDFKit.Theme.AUTO,
                });
                handleInstance(onInstall);
            }());
        }
    }, [watermarks]);

    useEffect(() => () => {
        if (PDFInstanceRef.current) {
            window.PSPDFKit?.unload(PDFInstanceRef.current);
        }
        setIsLoading?.(false);
    }, []);

    useEffect(() => {
        if (PDFInstanceRef.current) {
            handleInstance();
        }
    }, [isEditable]);

    const memoLockChanges = useCallback(() => saveFile(true), []);

    const openLock = useCallback(() => setIsLockModalOpen(true), []);
    const closeLock = useCallback(() => setIsLockModalOpen(false), []);

    return (
        <div className={styles['outer-wrapper']}>
            <div
                ref={containerRef}
                className={classNames(styles.container, { [PDFViewerClassName]: !!PDFViewerClassName })}
                style={PDFViewerStyle}
            />
            {isEditable && (
                <>
                    <FileSavingIndicator
                        hasError={hasError}
                        isSavingInProgress={isSavingInProgress}
                        hasUnFlattenAnnotations={hasUnFlattenAnnotations}
                        onLockClick={openLock}
                        saveFile={memoLockChanges}
                        carouselMode={carouselMode}
                    />
                    <Modal
                        isOpen={isLockModalOpen}
                        closeModal={closeLock}
                        initStyle={MODAL_STYLE}
                    >
                        <LockModalContent
                            close={closeLock}
                            lockSignatures={memoLockChanges}
                        />
                    </Modal>
                </>
            )}
        </div>
    );
});

export default PDFViewer;
