import React, { FC, ReactElement } from 'react';

import ColoredTooltip from '../../../Common/ColoredTooltip';
import { DesktopOutlined } from '@ant-design/icons';
import UAParser from 'ua-parser-js';

import OS_ICONS from './OSIcons';

const getOSIcon = (OSName: string): ReactElement => OS_ICONS[OSName.toLowerCase()] || <DesktopOutlined />;

const getOSTitle = (name: string, version: string): string => `${name} ${version || ''}`.trim();

interface UserAgentProps {
    userAgentStr: string;
}

const UserAgent: FC<UserAgentProps> = ({ userAgentStr }) => {
    const userAgent = new UAParser(userAgentStr);
    const { name, version } = userAgent.getOS();

    return name ? (
        <ColoredTooltip title={getOSTitle(name, version)}>
            {getOSIcon(name)}
        </ColoredTooltip>
    ) : null;
};

export default UserAgent;
