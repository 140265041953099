import React, { Component, ReactNode } from 'react';

import { withTranslation, WithTranslation as WithTranslationProps } from 'react-i18next';
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import RecipientListItem from './RecipientListItem';
import EmailInput, { InitRecipientItem } from './EmailInput';
import InviteAs from './InviteAs';
import NotifyRecipients from './NotifyRecipients';
import GroupPhoneNumbersModal from './GroupPhoneNumbersModal';
import {
    AuthSettingsStore, SharedUsersStore, UserStore, UsersListStore, UsersPhonesStore,
} from '../../../stores';
import { Recipient } from '../../../types/types';
import { validateEmail } from '../../validators';
import { SharedItemType } from '../RecipientRoleSelect';
import './index.scss';

export { NotifyRecipients };

const getCustomHeaderContent = (headerContent: ReactNode): ReactNode => (
    headerContent && (
        typeof headerContent === 'number' || typeof headerContent === 'string'
            ? (
                <span className="title">
                    {headerContent}
                </span>
            )
            : headerContent
    )
);

interface RecipientCheckResult {
    result: 'success' | 'warning' | 'info' | 'error';
    messageKey?: string;
    messagePayload?: Record<string, string>;
}

interface SharingBlockProps extends WithTranslationProps {
    showPhones: boolean;
    hasSharedUsers?: boolean;
    hasAccessRoleToggle?: boolean;
    headerWrapperClassName?: string;
    listWrapperClassName?: string;
    hasNotifyRecipients?: boolean;
    ownerEmail?: string;
    sharedItemType?: SharedItemType;
    headerContent?: ReactNode;
    authSettingsStore?: AuthSettingsStore;
    sharedUsersStore?: SharedUsersStore;
    usersPhonesStore?: UsersPhonesStore;
    userStore?: UserStore;
    usersListStore?: UsersListStore;
}

@inject('authSettingsStore', 'sharedUsersStore', 'userStore', 'usersListStore', 'usersPhonesStore')
@observer
class SharingBlock extends Component<SharingBlockProps> {
    private readonly titleNameSpace = 'general.specterxCommon';

    private readonly nameSpace = 'sharingBlock';

    componentDidMount(): void {
        const { sharedUsersStore } = this.props;
        // It's safe because recipientsReaction cannot be activated twice
        sharedUsersStore.runRecipientsReaction();
    }

    private checkRecipientErrors(value: string): RecipientCheckResult {
        const emailLower = value.toLowerCase();
        const {
            hasSharedUsers,
            ownerEmail,
            userStore: { userInformation },
            sharedUsersStore: { recipientsToDisplay, sharedUsers },
            usersListStore: { userGroupsList },
        } = this.props;

        const nameSpace = `${this.nameSpace}.validation`;
        let checkResult: RecipientCheckResult;
        const isGroup = !!userGroupsList.find((item) => item.groupId.toLowerCase() === emailLower);
        const isGroupAlreadyAdded = (isGroup
            && !!recipientsToDisplay.find((item) => item.id.toLowerCase() === emailLower));
        const isEmailAlreadyAdded = !!recipientsToDisplay.find((item) => item.email.toLowerCase() === emailLower);

        switch (true) {
        case isGroupAlreadyAdded:
            checkResult = { result: 'info', messageKey: `${nameSpace}.groupAlreadyAdded` };
            break;

        case isGroup:
            checkResult = { result: 'success' };
            break;

        case !validateEmail(value):
            checkResult = { result: 'error', messageKey: 'general.specterxCommon.invalidEmail' };
            break;

        case userInformation.email.toLowerCase() === emailLower:
            checkResult = { result: 'info', messageKey: `${nameSpace}.cannotShareWithYourself` };
            break;

        case !!ownerEmail && ownerEmail.toLowerCase() === emailLower:
            checkResult = { result: 'info', messageKey: `${nameSpace}.cannotShareWithOwner` };
            break;

        case isEmailAlreadyAdded:
            checkResult = { result: 'info', messageKey: `${nameSpace}.emailAlreadyAdded` };
            break;

        case hasSharedUsers && !!sharedUsers.find((user) => user.email.toLowerCase() === emailLower):
            checkResult = {
                result: 'info',
                messageKey: `${nameSpace}.alreadyShared`,
                messagePayload: { value },
            };
            break;

        default:
            checkResult = { result: 'success' };
        }

        return checkResult;
    }

    tryAddRecipients = (recipientsToAdd: InitRecipientItem[]): void => {
        const { sharedUsersStore, t } = this.props;
        const {
            addRecipients,
            setCurrentInputEmail,
            inviteAs,
        } = sharedUsersStore;
        if (recipientsToAdd?.length) {
            const validRecipients = recipientsToAdd.filter(({ value }) => {
                const { result, messageKey, messagePayload } = this.checkRecipientErrors(value);
                const hasErrors = result !== 'success';
                if (hasErrors) {
                    message[result](t(messageKey, messagePayload));
                }
                return !hasErrors;
            });
            const newRecipients: Recipient[] = validRecipients.map<Recipient>(({ value, label, type }) => ({
                email: label,
                id: value,
                role: inviteAs,
                type,
            }));
            addRecipients(newRecipients);
            setCurrentInputEmail('');
        }
    };

    render(): JSX.Element {
        const {
            showPhones,
            headerWrapperClassName,
            listWrapperClassName,
            headerContent,
            hasNotifyRecipients,
            sharedUsersStore,
            usersPhonesStore,
            t,
            sharedItemType,
            hasAccessRoleToggle = true,
        } = this.props;
        const { titleNameSpace } = this;
        const {
            recipientsToDisplay, hasRecipients,
        } = sharedUsersStore;
        const { phoneNumbersGroupId } = usersPhonesStore;
        const showNotifyRecipients = hasNotifyRecipients && hasRecipients;

        const customHeader: ReactNode = getCustomHeaderContent(headerContent);

        return (
            <>
                <div className="users-share-list">
                    <div className={classNames('users-list-header', headerWrapperClassName)}>
                        {customHeader || (
                            <span className="title">
                                {t(`${titleNameSpace}.shareWith`)}
                            </span>
                        )}
                    </div>
                    <EmailInput addRecipients={this.tryAddRecipients} />
                    {hasAccessRoleToggle && <InviteAs sharedItemType={sharedItemType} />}
                    <div
                        className={classNames(
                            'recipients-list-wrapper',
                            { [listWrapperClassName]: !!listWrapperClassName },
                        )}
                    >
                        <ul className="users-list">
                            {recipientsToDisplay.map((recipient) => (
                                <RecipientListItem
                                    key={recipient.email}
                                    recipient={recipient}
                                    sharedItemType={sharedItemType}
                                    showPhones={showPhones}
                                    hasAccessRoleToggle={hasAccessRoleToggle}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
                {showNotifyRecipients && <NotifyRecipients />}
                {phoneNumbersGroupId && <GroupPhoneNumbersModal />}
            </>
        );
    }
}

export default withTranslation()(SharingBlock);
