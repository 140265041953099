import React, {
    FC,
    memo,
    MouseEvent,
    useCallback,
} from 'react';

import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ErrorTooltip from '../../../Common/ErrorTooltip';
import PolicySelect from '../../../Common/PolicySelect';
import ElasticFileName from '../../../Common/ElasticFileName';
import { FileIcon } from '../../../Common/Icons';
import stopPropagation from '../stopPropagation';
import styles from './FilePanelContent.module.scss';

const nameSpace = 'uploadFiles';
const errorsNameSpace = `${nameSpace}.messages.error`;
const buttonsNameSpace = 'general.buttons';

interface FilePanelProps {
    isSetForAllDisabled: boolean;
    isSetForAllVisible: boolean;
    isExternalStorage: boolean;
    isFolder: boolean;
    fid: string;
    filename: string;
    hasPolicyError: boolean;
    policyId: string;
    removeFile: (fileId: string) => void | Promise<void>;
    changePolicy: (newPolicyId: string, fileId: string, isFolder: boolean) => void | Promise<void>;
    bulkChangePolicy: (policyId: string) => void | Promise<void>;
}

const FilePanelContent: FC<FilePanelProps> = ({
    isExternalStorage,
    isSetForAllDisabled,
    isSetForAllVisible,
    isFolder,
    fid,
    filename,
    policyId,
    hasPolicyError,
    changePolicy,
    bulkChangePolicy,
    removeFile,
}) => {
    /*
    * event.stopPropagation() using here to prevent collapse open on remove button click
    * */
    const onBulkChangePolicy = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        bulkChangePolicy(policyId);
    };

    const onRemoveFile = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        removeFile(fid);
    };

    const { t } = useTranslation();

    const onChangePolicy = (newPolicyId: string) => {
        changePolicy(newPolicyId, fid, isFolder);
    };

    return (
        <div className={styles['file-info-wrapper']}>
            <div className={styles['top-row']}>
                <div className={styles['file-info']}>
                    <FileIcon
                        className={styles['file-avatar']}
                        filename={filename}
                        isFolder={isFolder}
                    />
                    <ElasticFileName filename={filename} textClassname={styles['file-name']} />
                </div>
                {!isExternalStorage && (
                    <Popconfirm
                        className={styles['popover-delete']}
                        placement="topRight"
                        onCancel={stopPropagation}
                        onConfirm={onRemoveFile}
                        title={t(`${nameSpace}.fileWillBeRemoved`)}
                        okText={t(`${buttonsNameSpace}.ok`)}
                        cancelText={t(`${buttonsNameSpace}.cancel`)}
                    >
                        <DeleteOutlined onClick={stopPropagation} />
                    </Popconfirm>
                )}
            </div>
            <div className={styles['bottom-row']}>
                {hasPolicyError && (
                    <ErrorTooltip
                        message={t((`${errorsNameSpace}.setPolicyError`))}
                    />
                )}
                <div className={styles['policy-select-wrapper']} onClick={stopPropagation}>
                    <PolicySelect
                        className={styles['policy-select']}
                        onChange={onChangePolicy}
                        selectedPolicyId={policyId}
                    />
                </div>
                {isSetForAllVisible && (
                    <Button
                        className={styles['set-for-all']}
                        type="link"
                        disabled={isSetForAllDisabled}
                        onClick={onBulkChangePolicy}
                    >
                        <span>
                            {t(`${nameSpace}.buttons.setForAll`)}
                        </span>
                    </Button>
                )}
            </div>
        </div>
    );
};

export default memo(FilePanelContent);
