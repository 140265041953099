import React, { FC } from 'react';

import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './CreateNewWorkspaceButton.module.scss';

const workspaceNameSpace = 'workspaceDrawer';

interface CreateNewWorkspaceButtonProps {
  createNewWorkspace: () => void;
}

const CreateNewWorkspaceButton: FC<CreateNewWorkspaceButtonProps> = ({ createNewWorkspace }) => {
    const { t } = useTranslation();

    return (
        <div className={styles['workspace-name-wrapper']}>
            <Button onClick={createNewWorkspace} className={styles['create-button']}>
                <PlusCircleOutlined />
                {t(`${workspaceNameSpace}.createNew`)}
            </Button>
        </div>
    );
};

export default CreateNewWorkspaceButton;
