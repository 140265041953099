import {
    PREVENT_DOWNLOAD_UNPROTECTED,
    PROTECT_ON_DOWNLOAD,
    REQUIRED_EMAIL_VERIFICATION,
    REQUIRED_PHONE_VERIFICATION,
    VIEWER_ONLY,
    VIEWER_WATERMARK_POLICY,
} from '@/consts';
import { IncompatibleSettings } from '../../interfaces';

// eslint-disable-next-line import/prefer-default-export
export const INCOMPATIBLE_ACCESS_SETTINGS: IncompatibleSettings[] = [
    {
        settings: [
            {
                id: REQUIRED_EMAIL_VERIFICATION,
                isActive: false,
            },
            {
                id: REQUIRED_PHONE_VERIFICATION,
                isActive: false,
            },
        ],
        i18nMessageKey: 'atLeastOneVerification',
    },
    {
        settings: [
            {
                id: VIEWER_ONLY,
                isActive: false,
            },
            {
                id: VIEWER_WATERMARK_POLICY,
                isActive: true,
            },
        ],
    },
    {
        settings: [
            {
                id: PROTECT_ON_DOWNLOAD,
                isActive: false,
            },
            {
                id: PREVENT_DOWNLOAD_UNPROTECTED,
                isActive: true,
            },
        ],
    },
];
