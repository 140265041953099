import AuthSettingsStore from '../../AuthSettingsStore';
import UserStore from '../../UserStore';
import BaseFilesManager from './BaseFilesManager';
import FilesListLoader from '../FilesListLoader';
import { SecuredFile } from '../../../types/types';
import { FolderNode, FilesListType, FolderLike } from '../interfaces';
import { moveFiles } from '../../../api/requests';
import { captureErrorForSentry } from '../../../components/utils';
import {
    parseFolderId,
    splitCurrentList,
    transformFilesToTreeNodes,
} from '../helpers';
import { FILE_META_ORDERING_FIELDS } from '../options';

class MyFilesManager extends BaseFilesManager {
    constructor(
        filesListType: FilesListType,
        authSettingsStore: AuthSettingsStore,
        userStore: UserStore,
        filesListLoader: FilesListLoader,
    ) {
        super('myFiles', authSettingsStore, userStore, filesListLoader, [...FILE_META_ORDERING_FIELDS]);
    }

    /*
    * Drag and drop
    * */
    moveCurrentFolderFiles = async (targetFolderKey: string, filesIds: string[]): Promise<boolean> => {
        const { key: currentFolderKey } = this.currentFolder;
        const listSnapshot: SecuredFile[] = this.currentFolderFiles.slice();
        const isSucceed = await this.rescheduleRefreshAsync<boolean>(
            async (): Promise<boolean> => {
                const { API } = this.authSettingsStore;
                let result = true;
                const targetFolderId = parseFolderId(targetFolderKey);
                const filesIdsToMoveSet: ReadonlySet<string> = new Set<string>(filesIds);
                this.setCurrentFolderLoading(true);
                this.optimisticMoveFiles(targetFolderKey, filesIdsToMoveSet);
                try {
                    await moveFiles(API, targetFolderId, filesIds);
                } catch (error) {
                    captureErrorForSentry(error, 'FilesListStore.moveFiles');
                    console.log('could not move files', error);
                    this.moveFilesBack(targetFolderKey, currentFolderKey, listSnapshot, filesIdsToMoveSet);
                    result = false;
                }
                this.setFolderLoading(currentFolderKey, false);
                return result;
            },
        );
        const filesLeftCount = listSnapshot.length - filesIds.length;
        this.onFilesCountReduce(currentFolderKey, filesLeftCount);
        return isSucceed;
    }

    private optimisticMoveFiles(targetFolderId: string, filesToMoveIdsSet: ReadonlySet<string>): void {
        const { currentFolder } = this;
        const { filesToStay, filesToMove } = splitCurrentList(this.currentFolderFiles, filesToMoveIdsSet);
        const targetFolder: FolderNode = this.filesTree.find(targetFolderId);
        if (!targetFolder) {
            this.setCurrentFolderFiles(filesToStay);
        } else {
            this.syncSelectedItems(filesToStay);
            this.filesTree.setNodeChildren(
                targetFolder.key,
                [...transformFilesToTreeNodes(filesToMove, targetFolder), ...(targetFolder.children || [])],
            );
            this.filesTree.setNodeChildren(
                currentFolder.key,
                transformFilesToTreeNodes(filesToStay, currentFolder),
            );
        }
    }

    private moveFilesBack(
        targetFolderId: string,
        fromFolderKey: string,
        listSnapshot: SecuredFile[],
        filesToMoveIdsSet: ReadonlySet<string>,
    ): void {
        if (this.currentFolderKey === fromFolderKey) {
            this.setCurrentFolderFiles(listSnapshot);
        } else {
            const fromFolder: FolderNode = this.filesTree.find(targetFolderId);
            if (fromFolder) {
                this.filesTree.setNodeChildren(fromFolder.key, transformFilesToTreeNodes(listSnapshot, fromFolder));
            }
        }
        const targetFolder: FolderNode = this.filesTree.find(targetFolderId);
        if (targetFolder) {
            if (targetFolderId === this.currentFolderId) {
                this.setCurrentFolderFiles(this.currentFolderFiles.filter(({ fid }) => !filesToMoveIdsSet.has(fid)));
            } else {
                this.filesTree.setNodeChildren(
                    targetFolder.key,
                    targetFolder.children?.filter((item) => !filesToMoveIdsSet.has(item.value.fid)),
                );
            }
        }
    }

    /*
    * Workspaces
    * */
    protected setCurrentFolderFiles(newList: SecuredFile[]): void {
        super.setCurrentFolderFiles(newList.filter((item) => !item.is_workspace));
    }

    protected setFolderFiles(folder: FolderLike, newList: SecuredFile[]): void {
        super.setFolderFiles(folder, newList.filter((item) => !item.is_workspace));
    }
}

export default MyFilesManager;
