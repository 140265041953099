import React from 'react';

import { inject, observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';

import {
    FilesListStore,
    PolicyStore,
    SharedUsersStore,
    UserStore,
    UsersListStore,
    SharedUserToDisplay,
} from '@/stores';
import type { SecuredFile, SimpleCallback } from '@/types/types';
import {
    ModalContentProps,
    DEFAULT_MODAL_WIDTH,
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
} from '@/components/Common/Modal';
import i18n from '@/content';
import { captureErrorForSentry } from '@/components/utils';
import { ALLOW_FORWARDING } from '@/consts';

import ShareFile from './ShareFile';
import ShareSettings from './ShareSettings';
import ShareModalFooter from './ShareModalFooter';
import ShareSuccess from './ShareSuccess';
import './index.scss';

interface ShareModalProps extends ModalContentProps {
    fileSelected: SecuredFile;
    closeModal: SimpleCallback;
    sharedUsersStore?: SharedUsersStore;
    policyStore?: PolicyStore;
    userStore?: UserStore;
    filesListStore?: FilesListStore;
    usersListStore?: UsersListStore;
}

interface ShareModalState {
    success: boolean;
    isShareSettings: boolean;
    isForwarding: boolean;
    users: SharedUserToDisplay[];
}

const AUTO_CLOSE_TIMEOUT = 7000;
const ADDITIONAL_MODAL_WIDTH = 10;

@inject('userStore', 'sharedUsersStore', 'policyStore', 'filesListStore', 'usersListStore')
@observer
class ShareModal extends React.Component<ShareModalProps, ShareModalState> {
    private timeout: NodeJS.Timeout;

    private mounted: boolean = true;

    private readonly nameSpace = 'filesTable.modals.shareFile';

    constructor(props: ShareModalProps) {
        super(props);
        this.state = {
            success: false,
            isShareSettings: false,
            isForwarding: false,
            users: [],
        };
    }

    componentDidMount(): void {
        const {
            setWidth, setAdditionalWrapClassName, sharedUsersStore, fileSelected,
        } = this.props;
        setWidth?.(DEFAULT_MODAL_WIDTH + ADDITIONAL_MODAL_WIDTH);
        setAdditionalWrapClassName?.(MODAL_DISABLE_MIN_HEIGHT_CLASS);
        sharedUsersStore.startLinksPreloading({ filesIds: [fileSelected.fid], mode: 'general' });
        this.fetchData();
    }

    componentWillUnmount(): void {
        const {
            policyStore: { unmount },
            sharedUsersStore: { clearSharedUsers, finishRecipientsFlow },
            filesListStore: { chosenTableFileId },
        } = this.props;
        this.mounted = false;
        finishRecipientsFlow();
        if (!chosenTableFileId) {
            clearSharedUsers();
        }
        unmount();
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    toggleFileSettings = (): void => {
        this.setState(({ isShareSettings }) => ({ isShareSettings: !isShareSettings }));
    }

    handleClose = (): void => {
        const { resetParentModalState, closeModal } = this.props;
        closeModal();
        if (resetParentModalState) {
            resetParentModalState();
        }
    }

    onResponse = (success = false): void => {
        if (success) {
            this.setState({ success: true });
            this.timeout = setTimeout(() => this.handleClose(), AUTO_CLOSE_TIMEOUT);
        } else {
            clearTimeout(this.timeout);
            this.handleClose();
        }
    }

    setAllowForwarding = (isForwarding: boolean): void => {
        this.setState({ isForwarding });
    }

    setUsers = (newUsersList: SharedUserToDisplay[]): void => {
        this.setState({ users: newUsersList });
    }

    private async fetchPolicy(): Promise<void> {
        const { policyStore, fileSelected } = this.props;
        const policy = await policyStore.tryFindPolicy(fileSelected.policy_id, true);
        if (policy && this.mounted) {
            const forwardingSetting = policy.settings.access.find((setting) => setting.id === ALLOW_FORWARDING);
            this.setAllowForwarding(!!(forwardingSetting?.value));
            policyStore.setSelectedPolicy(policy);
        }
    }

    private async fetchSharedUsers(): Promise<void> {
        const { sharedUsersStore, fileSelected } = this.props;
        await sharedUsersStore.fetchSharedUsers(fileSelected.fid, true);
        if (!sharedUsersStore.hasShareError && this.mounted) {
            this.setState({ users: cloneDeep(sharedUsersStore.sharedUsersToDisplay) });
        }
    }

    private async fetchData(): Promise<void> {
        const {
            setLoadingStatus, setErrorStatus, sharedUsersStore, userStore, usersListStore,
        } = this.props;
        try {
            setLoadingStatus(true);
            const pendingRequests: Promise<void>[] = [
                sharedUsersStore.fetchEmailsList(),
                this.fetchPolicy(),
                usersListStore.fetchUsersCustomGroups(),
            ];
            if (!userStore.isExternal) {
                pendingRequests.push(this.fetchSharedUsers());
            }
            await Promise.all(pendingRequests);
        } catch (error) {
            console.log('could not load share modal data', error);
            captureErrorForSentry(error, 'ShareModal.fetchData');
            setErrorStatus(true, i18n.t(`${this.nameSpace}.messages.error.couldNotLoadSettings`));
        } finally {
            setLoadingStatus(false);
        }
    }

    render(): JSX.Element {
        const {
            success, isShareSettings, isForwarding, users,
        } = this.state;
        const {
            fileSelected,
            setLoadingStatus,
            setErrorStatus,
            sharedUsersStore: { singleRecipientIdentity },
            userStore: { isExternal },
        } = this.props;
        const {
            filename,
            fid: fileId,
            owner_email: ownerEmail,
            policy_id: policyId,
            is_folder: isFolder,
            is_workspace: isWorkspace,
        } = fileSelected;
        const sharedItemType = isFolder || isWorkspace ? 'folder' : 'file';
        const showShareSettings = isShareSettings && !success && !isExternal;
        const showShareFile = !isShareSettings && !success;
        const showResult = success;
        const showFooter = !success;
        return (
            <>
                {showShareSettings
              && (
                  <ShareSettings
                      fileSelected={fileSelected}
                      toggleFileSettings={this.toggleFileSettings}
                      onShareSettings={this.onResponse}
                      sharedItemType={sharedItemType}
                      setErrorStatus={setErrorStatus}
                      setLoadingStatus={setLoadingStatus}
                      onChangeUsers={this.setUsers}
                      users={users}
                  />
              )}
                {showShareFile
              && (
                  <ShareFile
                      setErrorStatus={setErrorStatus}
                      hideSettings={isExternal}
                      setLoadingStatus={setLoadingStatus}
                      fileId={fileId}
                      sharedItemType={sharedItemType}
                      policyId={policyId}
                      ownerEmail={ownerEmail}
                      onShareFile={this.onResponse}
                      toggleFileSettings={this.toggleFileSettings}
                  />
              )}
                {showResult && (<ShareSuccess linkKey={singleRecipientIdentity} filename={filename || fileId} />)}
                {showFooter && (<ShareModalFooter linkKey={singleRecipientIdentity} isForwarding={isForwarding} />)}
            </>
        );
    }
}

export default ShareModal;
