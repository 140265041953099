import React, { FC, memo } from 'react';

import { Button, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import classNames from 'classnames';

import { RecipientRoles } from '@/types/types';
import styles from './RecipientRoleSelect.module.scss';

const ALL_ROLES_LIST: RecipientRoles[] = ['viewer', 'editor', 'coOwner'];

const nameSpace = 'sharingBlock.recipientRolesSelect';

export type SharedItemType = 'file' | 'folder' | 'workspace' | 'unknown';

interface RecipientRoleSelectProps {
    value: RecipientRoles;
    sharedItemType?: SharedItemType;
    onChange: (newValue: RecipientRoles) => void;
    disabled?: boolean;
}

const RecipientRoleSelect: FC<RecipientRoleSelectProps> = ({
    value,
    sharedItemType = 'unknown',
    onChange,
    disabled = false,
}) => {
    const { t } = useTranslation();

    const handleMenuClick = (event: MenuInfo): void => {
        onChange(event.key as RecipientRoles);
    };

    return (
        <Dropdown
            disabled={disabled}
            trigger={['click']}
            menu={{
                onClick: handleMenuClick,
                items: ALL_ROLES_LIST.map((role) => ({
                    className: classNames(styles.item, { [styles.chosen]: role === value }),
                    key: role,
                    label: (
                        <>
                            <span className={styles.title}>
                                {t(`${nameSpace}.${role}.title`)}
                            </span>
                            <br />
                            <span className={styles.description}>
                                {t(`${nameSpace}.${role}.${sharedItemType}.description`)}
                            </span>
                        </>
                    ),
                })),
            }}
        >
            <Button
                type="link"
                className={styles['select-trigger']}
            >
                <span className={styles.title}>
                    {t(`${nameSpace}.${value}.title`)}
                </span>
                <CaretDownOutlined className={styles.icon} />
            </Button>
        </Dropdown>
    );
};

export default memo(RecipientRoleSelect);
