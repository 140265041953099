import React, { FC, memo } from 'react';

import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import './index.scss';

interface CheckboxCellProps {
    fid: string;
    value: boolean;
    onChange: (fid: string, checked: boolean) => void;
}

const CheckboxCell: FC<CheckboxCellProps> = ({ fid, value, onChange }) => (
    <Checkbox
        checked={value}
        onChange={(event: CheckboxChangeEvent) => onChange(fid, event.target.checked)}
        className="checkbox-cta"
        data-clickable
    />
);

export default memo(CheckboxCell);
