import { Size } from '../pspdfkitTypes';

// Logo Image Configuration
const LOGO_WIDTH = 64;
const LOGO_MARGIN = 20;
const LOGO_ALPHA = 0.5;

// Font Configuration
const FONT_SETTINGS = '20px Open sans';
const FONT_ALIGN = 'center';
const FONT_COLOR = '#838383';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const FONT_ROTATION_ANGLE = -Math.PI / 4; // -45 degrees
const FONT_ALPHA = 0.33;

export const imageWatermarkPlugin = (
    img: HTMLImageElement,
    ctx: CanvasRenderingContext2D,
    pageSize: Size,
): void => {
    const imgRatio = img.height / img.width;
    const adjustedHeight = LOGO_WIDTH * imgRatio;

    ctx.save();

    ctx.globalAlpha = LOGO_ALPHA;
    ctx.drawImage(
        img,
        pageSize.width - LOGO_WIDTH - LOGO_MARGIN,
        pageSize.height - adjustedHeight - LOGO_MARGIN,
        LOGO_WIDTH,
        adjustedHeight,
    );

    ctx.restore();
};

const EMAIL_WATERMARK_Y_TRANSLATION_COEFF = 2;

/* eslint-disable @typescript-eslint/no-magic-numbers */
const EMAIL_WATERMARK_PERCENTAGES: number[] = [
    0.15,
    0.5,
    0.85,
];
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const emailWatermarkPlugin = (
    userEmail: string,
    ctx: CanvasRenderingContext2D,
    pageSize: Size,
): void => {
    ctx.save();

    ctx.globalAlpha = FONT_ALPHA;
    ctx.font = FONT_SETTINGS;
    ctx.textAlign = FONT_ALIGN;
    ctx.fillStyle = FONT_COLOR;

    EMAIL_WATERMARK_PERCENTAGES.forEach((percentage) => {
        ctx.save();
        ctx.translate(pageSize.width * percentage, pageSize.height / EMAIL_WATERMARK_Y_TRANSLATION_COEFF);
        ctx.rotate(FONT_ROTATION_ANGLE);
        ctx.fillText(userEmail, 0, 0);
        ctx.restore();
    });

    ctx.restore();
};
