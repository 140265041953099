import type { FC } from 'react';

import { Button } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { ModalFooter, ModalHeader, ModalContentProps } from '@/components/Common/Modal';
import { useMounted, useStores } from '@/components/hooks';
import useConfirmCheck from './useConfirmCheck';
import type { BaseConfirmProps } from '../interfaces';
import styles from './ConfirmDownloadContent.module.scss';

const nameSpace = 'confirmDownload';

type ConfirmDownloadContentProps = BaseConfirmProps & ModalContentProps;

const ConfirmDownloadContent: FC<ConfirmDownloadContentProps> = observer(({
    filePolicyId,
    filename,
    ownerEmail,
    onFinish,
    setLoadingStatus,
}) => {
    const { t } = useTranslation();

    const {
        policyStore,
        userStore: { currentUserEmail },
    } = useStores();

    const isMountedRef = useMounted();

    const showConfirm = useConfirmCheck({
        filePolicyId,
        ownerEmail,
        currentUserEmail,
        policyStore,
        isMountedRef,
        onFinish: () => onFinish(true),
        toggleLoader: (value: boolean): void => {
            setLoadingStatus(value, value ? t(`${nameSpace}.messages.wait.checkingPolicy`) : '');
        },
    });

    return (
        showConfirm
            ? (
                <>
                    <ModalHeader>
                        <div className={styles.header}>
                            <AuditOutlined className={styles['header-icon']} />
                            {t(`${nameSpace}.title`)}
                        </div>
                    </ModalHeader>
                    <div className={styles.content}>
                        <Trans t={t} i18nKey={`${nameSpace}.content`} tOptions={{ filename }}>
                            please confirm
                            <span>
                                filename
                            </span>
                        </Trans>
                    </div>
                    <ModalFooter>
                        <div className={styles.footer}>
                            <Button
                                type="text"
                                className={styles['cancel-btn']}
                                onClick={() => onFinish(false)}
                            >
                                {t('general.buttons.cancel')}
                            </Button>
                            <Button
                                className={styles['confirm-btn']}
                                onClick={() => onFinish(true)}
                            >
                                {t('general.buttons.confirm')}
                            </Button>
                        </div>
                    </ModalFooter>
                </>
            )
            : null
    );
});

export default ConfirmDownloadContent;
