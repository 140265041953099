import React, { FC } from 'react';

import { Button } from 'antd';
import { FileAddOutlined, FolderAddOutlined, SendOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../hooks';
import { DrawerState } from './interfaces';
import { bytesToText } from '../../utils';
import { nameSpace } from './constants';
import './index.scss';

const get18nKey = (draggerState: DrawerState | boolean, option: string): string => {
    switch (draggerState) {
    case 'success':
        return 'successScreenDragger';
    case true:
        return 'sidebarText';
    default:
        return option;
    }
};

interface FileShareContentProps {
    option: 'draggerFile' | 'draggerFolder' | 'draggerDropdownFolder';
    drawerState: DrawerState;
    isSidebar: boolean;
    openUploader: () => void;
}

const DraggerInnerContent: FC<FileShareContentProps> = observer(({
    option, drawerState, isSidebar, openUploader,
}) => {
    const { uploadFilesStore: { uploadLimit } } = useStores();
    const draggerState = drawerState || isSidebar;
    const { t } = useTranslation();

    return (
        <>
            {isSidebar
                ? (
                    <div className="sidebar-uploader-button">
                        <Button onClick={openUploader}>
                            <SendOutlined />
                            { t('myFiles.header.buttons.shareFiles') }
                        </Button>
                    </div>
                )
                : (
                    <div className="dragger-content">
                        {option === 'draggerFolder' || option === 'draggerDropdownFolder'
                            ? <FolderAddOutlined className="dragger-icon" />
                            : <FileAddOutlined className="dragger-icon" />}
                        <span className="dragger-text">
                            {t(`${nameSpace}.${get18nKey(draggerState, option)}`)}
                        </span>
                        {drawerState && (
                            <span className="dragger-size-note">
                                (
                                {t('uploadFiles.dragger.sizeLimit', { limit: bytesToText(uploadLimit) })}
                                )
                            </span>
                        )}
                    </div>
                )}
        </>
    );
});

export default DraggerInnerContent;
