import React, {
    FC,
    useMemo,
} from 'react';

import { useLocation } from 'react-router';
import {
    Button,
    Divider,
    Layout,
} from 'antd';
import { observer } from 'mobx-react';

import FilesUploaderDragger from '../Common/FilesUploaderDragger';
import type { MenuItem } from '@/stores';
import { AppRoutes } from '@/config/appRoutes';
import i18n from '@/content';
import { FOLDER_ID_QUERY_PARAM, PUBLIC_ASSETS } from '@/consts';

import SidebarMenu from './SidebarMenu';
import { useStores } from '../hooks';
import useSidebarState from './useSidebarState';

import { calculateChosenPath, getMenuItemsGroups } from './helpers';
import './index.scss';

const { Sider } = Layout;

const WIDTH = 200;

export interface SideBarProps {
    hasUpload: boolean;
    hasWorkspace: boolean;
}

const SideBar: FC<SideBarProps> = observer(({
    hasUpload,
    hasWorkspace,
}) => {
    const location = useLocation();

    const { appStore } = useStores();
    const { displayMenuItems, workspacesStore } = appStore;
    const { workspacesList, workspacesIdsSet } = workspacesStore;
    const { myFiles, other } = useMemo(() => getMenuItemsGroups(displayMenuItems), [displayMenuItems]);

    const workspacesSubItems = useMemo<MenuItem[]>(() => workspacesList.map((item) => ({
        displayName: item.filename,
        route: `${AppRoutes.myFiles}?${FOLDER_ID_QUERY_PARAM}=${item.fid}` as AppRoutes,
    })), [workspacesList]);

    const workspaceListRoutes: MenuItem[] = useMemo(() => (
        workspacesSubItems.length ? [{
            displayName: 'My workspaces',
            route: AppRoutes.myWorkspaces,
            children: workspacesSubItems,
        }] : []
    ), [workspacesSubItems]);

    const chosenMenuItem = calculateChosenPath(location, workspacesIdsSet);

    const { isVisible, isMobile } = useSidebarState(appStore);

    const openWorkspace = (): void => {
        appStore.setIsWorkspaceDrawerOpen(true);
    };

    return (
        <Sider
            width={isMobile && !isVisible ? 0 : WIDTH}
            className="side-bar-wrap"
            collapsible
            collapsed={false}
            theme="light"
            trigger={isMobile ? null
                : (
                    <img
                        src={`${PUBLIC_ASSETS}/company_logo/sidebar-bottom-logo.svg`}
                        alt="SpecterX logo"
                    />
                )}
        >
            {hasUpload && <FilesUploaderDragger drawerState="upload" isSidebar />}
            {hasWorkspace && (
                <>
                    <Button className="request-files-button" onClick={openWorkspace}>
                        <img alt="" src={`${PUBLIC_ASSETS}/icons/create_workspace.svg`} />
                        {i18n.t('workspaceDrawer.requestFiles')}
                    </Button>
                    <div className="divider-wrapper">
                        <Divider className="divider" />
                    </div>
                </>
            )}
            <SidebarMenu
                items={myFiles}
                pathname={location.pathname}
                chosenMenuItem={chosenMenuItem}
            />
            {hasWorkspace && (
                <SidebarMenu
                    items={workspaceListRoutes}
                    pathname={location.pathname}
                    chosenMenuItem={chosenMenuItem}
                />
            )}
            {!!other.length && (
                <>
                    <div className="divider-wrapper">
                        <Divider className="divider" />
                    </div>
                    <SidebarMenu
                        items={other}
                        pathname={location.pathname}
                        chosenMenuItem={chosenMenuItem}
                    />
                </>
            )}
        </Sider>
    );
});

export default SideBar;
