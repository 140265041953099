import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import { PhoneNumberViewContainer } from '../../PhoneNumberViewContainer';
import styles from './ShowButton.module.scss';

interface ShowButtonViewProps {
  handlePhoneShowChange: () => void;
}

const nameSpace = 'userRoleEditor.phoneNumber';

const ShowPhoneButtonView: FC<ShowButtonViewProps> = observer(({ handlePhoneShowChange }) => {
    const { t } = useTranslation();
    return (
        <PhoneNumberViewContainer>
            <Button
                className={styles['show-button']}
                onClick={handlePhoneShowChange}
            >
                {t(`${nameSpace}.showPhone`)}
            </Button>
        </PhoneNumberViewContainer>
    );
});

export default ShowPhoneButtonView;
