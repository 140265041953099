import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';
import { NavigateFunction } from 'react-router';

import { ROOT_FOLDER } from '@/consts';
import AuthSettingsStore from '../AuthSettingsStore';
import UserStore from '../UserStore';
import FilesListLoader from './FilesListLoader';
import { AllFilesManager, BaseFilesManager, MyFilesManager } from './FilesManager';
import { SecuredFile } from '@/types/types';
import { UploadedFile } from '../UploadFilesStore';
import {
    DeleteFileResult,
    FilesListType,
    FileToDisplay,
    FolderNode,
    FolderReceivingDetails,
    OnDelete,
    Ordering,
    SortableFields,
} from './interfaces';

export type {
    DeleteFileResult,
    FileToDisplay,
    FilesListType,
    SortableFields,
};
export { createFolderKey, parseFolderId } from './helpers';
export { MAP_ROUTE_TO_FILES_LIST_TYPE } from './options';

interface FilesSystem {
    readonly myFiles: MyFilesManager;
    readonly sharedFiles: BaseFilesManager;
    readonly allFiles: AllFilesManager;
}

/*
* There is important difference between folderId and folderKey.
* folderId is UUID of the folder which come from BE.
* folderKey is absolute path of the folder.
* In some cases, for example search, it's possible to have same folders on different levels.
* To prevent it, we use folderKey to make nodes unique.
* This solution is not optimal, because same entities are split, so in future we need to provide a good synchronization
* */
class FilesListStore {
    constructor(authSettingsStore: AuthSettingsStore, userStore: UserStore) {
        const filesListLoader = new FilesListLoader(authSettingsStore);
        this.filesListLoader = filesListLoader;
        this.fileSystem = {
            myFiles: new MyFilesManager(
                'myFiles',
                authSettingsStore,
                userStore,
                filesListLoader,
            ),
            sharedFiles: new BaseFilesManager(
                'sharedFiles',
                authSettingsStore,
                userStore,
                filesListLoader,
                ['filename'],
            ),
            allFiles: new AllFilesManager(
                'allFiles',
                authSettingsStore,
                userStore,
                filesListLoader,
            ),
        };
        makeObservable(this);
    }

    @observable
    displayFilesType: FilesListType = 'myFiles';

    @observable
    searchFileName = '';

    @observable
    searchFileOwner = '';

    private readonly fileSystem: FilesSystem;

    private readonly filesListLoader: FilesListLoader;

    @computed
    get currentFolderNode(): FolderNode {
        return this.currentFilesManager.currentFolder;
    }

    @computed
    get currentFolderKey(): string {
        return this.currentFilesManager.currentFolderKey;
    }

    @computed
    get currentNonRootKey(): string {
        return this.currentFilesManager.currentNonRootKey;
    }

    @computed
    get currentFolder(): SecuredFile {
        return this.currentFolderNode.value;
    }

    @computed
    get currentFolderBreadcrumbs(): FileToDisplay[] {
        return this.currentFilesManager.currentFolderBreadcrumbs;
    }

    @computed
    get currentFolderId(): string {
        return this.currentFolder?.fid || null;
    }

    @computed
    get myFilesFolderId(): string {
        return this.fileSystem.myFiles.currentFolderId;
    }

    @computed
    get sharedFilesFolderId(): string {
        return this.fileSystem.sharedFiles.currentFolderId;
    }

    @computed
    get allFilesFolderId(): string {
        return this.fileSystem.allFiles.currentFolderId;
    }

    @computed
    get filesToDisplay(): FileToDisplay[] {
        return this.currentFilesManager.filesToDisplay;
    }

    @computed
    get selectedItems(): FileToDisplay[] {
        return this.currentFilesManager.selectedItems;
    }

    @computed
    get selectedFiles(): FileToDisplay[] {
        return this.currentFilesManager.selectedFiles;
    }

    @computed
    get selectedFilesKeys(): string[] {
        return this.currentFilesManager.selectedFiles.map(({ fid }) => fid);
    }

    @computed
    get selectedItemsKeys(): string[] {
        return this.currentFilesManager.selectedItemsKeys;
    }

    @computed
    get selectedItemsCount(): number {
        return this.selectedItemsKeys.length;
    }

    @computed
    get hasSelectedItems(): boolean {
        return !!this.selectedItemsKeys.length;
    }

    @computed
    get selectedFilesCount(): number {
        return this.currentFilesManager.selectedFiles.length;
    }

    @computed
    get hasBatchDownload(): boolean {
        if (this.selectedItems.length > 1) {
            return true;
        }
        const possibleSingleItem = this.selectedItems[0];
        return possibleSingleItem?.is_workspace || possibleSingleItem?.is_folder;
    }

    @computed
    get hasPageToken(): boolean {
        return !!this.currentFilesManager.currentFolder?.payload?.pageToken;
    }

    @computed
    get isLoading(): boolean {
        return this.currentFilesManager.isLoading;
    }

    @computed
    get selectedItem(): FileToDisplay {
        return this.currentFilesManager.selectedItem;
    }

    @computed
    get chosenTableFileId(): string {
        return this.currentFilesManager.chosenTableFileId;
    }

    @computed
    get isNextFilesLoading(): boolean {
        return this.currentFilesManager.isNextFilesLoading;
    }

    @computed
    get isShared(): boolean {
        return this.displayFilesType === 'sharedFiles';
    }

    @computed
    get isMyFiles(): boolean {
        return this.displayFilesType === 'myFiles';
    }

    @computed
    get searchValue(): string {
        return this.currentFilesManager.searchedFileName;
    }

    @computed
    get searchedFileOwner(): string {
        return this.fileSystem.allFiles.searchedFileOwner;
    }

    @computed
    get orderingFields(): SortableFields[] {
        return this.currentFilesManager.orderingFields;
    }

    @computed
    get ordering(): Ordering {
        return this.currentFilesManager.ordering;
    }

    @computed
    private get currentFilesManager(): BaseFilesManager {
        return this.fileSystem[this.displayFilesType];
    }

    @action
    setDisplayFilesType = (newValue: FilesListType): void => {
        this.displayFilesType = newValue;
    }

    @action
    setSearchFileOwner = (value: string): void => {
        this.searchFileOwner = value;
    }

    @action
    setSearchFileName = (value: string): void => {
        this.searchFileName = value;
    }

    cleanSearchInputs = (): void => {
        this.setSearchFileName('');
        this.setSearchFileOwner('');
    }

    checkHasFilters = (): boolean => this.currentFilesManager.checkHasFilters();

    checkIsLoadNecessary = (filesListType: FilesListType, URLFolderId: string): boolean => {
        const filesManager: BaseFilesManager = this.fileSystem[filesListType];
        const { currentFolder: { children }, currentFolderId } = filesManager;
        return (URLFolderId && URLFolderId !== currentFolderId) || (!children && !filesManager.isLoading);
    }

    getPossibleUploadFolderId = (): string => {
        const { myFilesFolderId, currentFolder } = this;
        if (this.isMyFiles) {
            return myFilesFolderId && myFilesFolderId !== ROOT_FOLDER
                ? myFilesFolderId
                : null;
        }
        return (currentFolder?.menu?.options?.includes('add_files')
            && currentFolder?.fid !== ROOT_FOLDER)
            ? currentFolder.fid
            : null;
    }

    setUp = (navigate?: NavigateFunction): void => {
        (Object.values(this.fileSystem) as BaseFilesManager[]).forEach((filesManager) => filesManager.setUp(navigate));
    }

    rescheduleRefreshAsync = async <T>(target: () => Promise<T>): Promise<T> => (
        this.currentFilesManager.rescheduleRefreshAsync<T>(target)
    )

    initScheduleRefresh = (filesListType: FilesListType): void => {
        this.fileSystem[filesListType].initScheduleRefresh();
    }

    setRefresherToActiveMode = (filesListType: FilesListType): void => {
        this.fileSystem[filesListType].setRefresherToActiveMode();
    }

    setRefresherToPassiveMode = (filesListType: FilesListType): void => {
        this.fileSystem[filesListType].setRefresherToPassiveMode();
    }

    fetchFolder = async (details: FolderReceivingDetails): Promise<void> => (
        this.currentFilesManager.fetchFolder(details)
    )

    deleteFile = async (fileId: string): Promise<DeleteFileResult> => (
        this.currentFilesManager.tryDeleteFileFromBE(fileId)
    )

    deleteSelectedFiles = async (onDelete?: OnDelete): Promise<void> => {
        await this.currentFilesManager.deleteSelectedFiles(onDelete);
    }

    fetchFolderBreadcrumbs = async (targetFolderId: string): Promise<void> => (
        this.currentFilesManager.fetchFolderBreadcrumbs(targetFolderId)
    )

    refreshFiles = async (): Promise<void> => {
        this.cleanSearchInputs();
        await this.currentFilesManager.refreshFiles();
    }

    searchByOwner = async (value: string): Promise<void> => {
        await this.fileSystem.allFiles.searchByOwner(value);
    }

    searchByFilename = async (value: string): Promise<void> => {
        await this.currentFilesManager.searchByFilename(value);
    }

    navigateToRoot = (filesListType: FilesListType): void => {
        this.fileSystem[filesListType].navigateToRoot();
    }

    clearFilesListStore = (): void => {
        this.filesListLoader.cancelAllPacks();
        Object.values(this.fileSystem).forEach((filesManager) => filesManager.unmount());
    }

    nextFiles = async (): Promise<void> => (
        this.currentFilesManager.nextFiles()
    )

    deleteFilesFromCurrentFolder = (fileIds: string | string[], filesListType: FilesListType): void => {
        this.fileSystem[filesListType].deleteFilesFromCurrentFolder(fileIds);
    }

    deleteFilesOrFolder = (fileIds: string | string[], filesListType: FilesListType): void => {
        this.fileSystem[filesListType].deleteCurrentFolderContent(fileIds);
    }

    setSelectedItems = (filesListType: FilesListType, filesIds: string[]): void => {
        this.fileSystem[filesListType].setSelectedItems(filesIds);
    }

    setCurrentSelectedFiles = (filesIds: string[]): void => {
        this.setSelectedItems(this.displayFilesType, filesIds);
    }

    updateFile = async (fileId: string, isSilent = false): Promise<void> => (
        this.currentFilesManager.updateFile(fileId, isSilent)
    )

    onUploadNewFiles = async (uploadedFiles: UploadedFile[]): Promise<void> => (
        this.currentFilesManager.onUploadNewFiles(uploadedFiles)
    )

    fetchNewFiles = async (filesCount: number): Promise<void> => (
        this.currentFilesManager.fetchNewFiles(filesCount)
    )

    moveCurrentFolderFiles = async (targetFolderKey: string, filesIds: string[]): Promise<boolean> => (
        this.fileSystem.myFiles.moveCurrentFolderFiles(targetFolderKey, filesIds)
    )

    changeOrdering = async (orderBy: SortableFields | null): Promise<void> => (
        this.currentFilesManager.changeOrdering(orderBy)
    )

    setChosenTableFileId = (value: string): void => {
        this.currentFilesManager.setChosenTableFileId(value);
    }
}

export default FilesListStore;
