import {
    FC, useState,
} from 'react';

import { t } from 'i18next';
import { observer } from 'mobx-react';
import {
    Card,
} from 'antd';

import Spinner from '@/components/Common/Spin';
import { InfoModal } from './InfoModal';
import { ButtonsGroup } from './ButtonsGroup';
import { UsersList } from './UsersList';
import { FilesList } from './FilesList';
import { ErrorModal } from './ErrorModal';

import { FileToShare } from '@/stores/SharedUsersStore/interfaces';
import { useStores } from '@/components/hooks';
import { checkHasPhoneOnlyPolicyInFilesList, onFinishSharingFlow } from '@/components/utils';

import './index.scss';

type FilesSharingStatus = 'loading' | 'success' | 'error' | 'none';

const ORIGIN = 'googledrive';

const ShareDrawer: FC = () => {
    const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
    const [filesSharingStatus, setFilesSharingStatus] = useState<FilesSharingStatus>('none');
    const {
        sharedUsersStore,
        uploadFilesStore,
        usersPhonesStore,
        policyStore: { policyList },
    } = useStores();

    const { isLoading, uploadedFiles, successfullyUploadedSingleFiles } = uploadFilesStore;

    const shareFiles = async (): Promise<void> => {
        const files: FileToShare[] = uploadedFiles.map<FileToShare>((file) => ({
            origin_id: file.originId,
            policy_id: file.policy?.id,
        }));

        try {
            setFilesSharingStatus('loading');
            await sharedUsersStore.bulkShareFiles({ files, origin: ORIGIN });
            setFilesSharingStatus('success');
        } catch (e) {
            setFilesSharingStatus('error');
        }
    };

    const onShareClick = (): void => {
        onFinishSharingFlow(
            t,
            shareFiles,
            checkHasPhoneOnlyPolicyInFilesList(successfullyUploadedSingleFiles, policyList),
            { sharedUsersStore, uploadFilesStore, usersPhonesStore },
        );
    };

    const handleInfoModalOpen = (): void => {
        setIsInfoModalOpen(true);
    };

    const handleInfoModalClose = (): void => {
        setIsInfoModalOpen(false);
    };

    const handleErrorModalClose = (): void => {
        setFilesSharingStatus('none');
        window.location.reload();
    };

    const isSharingSuccess = filesSharingStatus === 'success';
    const isSharingError = filesSharingStatus === 'error';
    const isSharingLoading = filesSharingStatus === 'loading';

    return (
        <>
            <Spinner spinning={isSharingLoading || isLoading}>
                <div className="share-drawer">
                    <Card className="share-drawer-card">
                        <Card.Grid className="users-list" hoverable={false}>
                            <UsersList success={isSharingSuccess} />
                        </Card.Grid>
                        <Card.Grid className="files-list" hoverable={false}>
                            <FilesList success={isSharingSuccess} />
                        </Card.Grid>
                        {!isSharingSuccess && (
                            <Card.Grid className="buttons-container" hoverable={false}>
                                <ButtonsGroup
                                    handleInfoModalOpen={handleInfoModalOpen}
                                    shareFiles={onShareClick}
                                />
                            </Card.Grid>
                        )}
                    </Card>
                </div>
            </Spinner>
            <InfoModal isOpen={isInfoModalOpen} closeModal={handleInfoModalClose} />
            <ErrorModal isOpen={isSharingError} closeModal={handleErrorModalClose} />
        </>
    );
};

export default observer(ShareDrawer);
