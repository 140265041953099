import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Location } from 'history';

import i18n from '@/content';
import { FOLDER_ID_QUERY_PARAM } from '@/consts';
import MyFilesHeader from './MyFilesHeader';
import { FilesTable } from '../FilesTable';
import { FilesDragProvider } from '../FilesTable/FilesDragContext';
import TabTitle from '../Common/TabTitle';
import { withRouter, WithRouterProps } from '@/components/HOC';
import { HeaderPortal } from '../Header';
import HeaderPortalContent from './HeaderPortalContent';
import { FilesListStore, MAP_ROUTE_TO_FILES_LIST_TYPE } from '../../stores';
import { AppRoutes } from '@/config/appRoutes';
import styles from './MyFiles.module.scss';

const getURLFolderKey = (location: Location): string => new URLSearchParams(location.search).get(FOLDER_ID_QUERY_PARAM);

interface MyFilesProps extends WithRouterProps {
    filesListStore?: FilesListStore;
}

@inject('filesListStore')
@observer
class MyFiles extends Component<MyFilesProps> {
    private readonly nameSpace = 'myFiles';

    async componentDidMount(): Promise<void> {
        const { filesListStore, location, navigate } = this.props;
        const filesListType = MAP_ROUTE_TO_FILES_LIST_TYPE[location.pathname as AppRoutes];
        filesListStore.setDisplayFilesType(filesListType);
        filesListStore.setRefresherToActiveMode(filesListType);

        const URLFolderId: string = getURLFolderKey(location);
        // TODO: just useSearchParams
        if (!URLFolderId && filesListStore.currentNonRootKey) {
            navigate(
                `${location.pathname}?${FOLDER_ID_QUERY_PARAM}=${filesListStore.currentNonRootKey}`,
                { replace: true },
            );
        }

        if (filesListStore.checkIsLoadNecessary(filesListType, URLFolderId)) {
            if (URLFolderId) {
                await filesListStore.fetchFolderBreadcrumbs(URLFolderId);
            } else {
                await filesListStore.fetchFolder({ folderKey: filesListStore.currentFolderKey });
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<MyFilesProps>) {
        const { location, filesListStore } = this.props;
        const { displayFilesType } = filesListStore;
        const currentURLFolderKey: string = getURLFolderKey(location);
        if (
            location.search !== prevProps.location.search
            && MAP_ROUTE_TO_FILES_LIST_TYPE[location.pathname] === displayFilesType
            && currentURLFolderKey !== filesListStore.currentFolderKey
        ) {
            filesListStore.fetchFolder({ folderKey: currentURLFolderKey, updateMode: 'silentUpdate' });
        }
    }

    componentWillUnmount(): void {
        const { filesListStore, location } = this.props;
        const filesListType = MAP_ROUTE_TO_FILES_LIST_TYPE[location.pathname as AppRoutes];
        filesListStore.setRefresherToPassiveMode(filesListStore.displayFilesType);
        filesListStore.navigateToRoot(filesListType);
        if (filesListStore.checkHasFilters()) {
            filesListStore.refreshFiles();
        } else {
            filesListStore.cleanSearchInputs();
        }
    }

    render(): JSX.Element {
        const { filesListStore: { displayFilesType } } = this.props;

        return (
            <FilesDragProvider>
                <div data-testid="my-files-container" className={styles['my-files-container']}>
                    <TabTitle title={i18n.t(`${this.nameSpace}.header.title.${displayFilesType}`)} />
                    <MyFilesHeader />
                    <FilesTable />
                </div>
                <HeaderPortal>
                    <HeaderPortalContent />
                </HeaderPortal>
            </FilesDragProvider>
        );
    }
}

export default withRouter(MyFiles);
