import CancellableAPI from '../CancellableAPI';
import { BASEURL, ENDPOINTS } from '../endpoints';
import { SPXAPI } from '../interfaces';
import { FileDetailsPublic, FileMetadata, SecuredFile } from '../../types/types';

export const getFile = async (API: SPXAPI, fileId: string): Promise<SecuredFile> => API.get(
    BASEURL.backend(),
    ENDPOINTS.getFileDetails(fileId),
    {},
);

export const getFileDetailsPublic = async (API: SPXAPI, fileId: string): Promise<FileDetailsPublic> => API.get(
    BASEURL.backend(),
    ENDPOINTS.getFileDetailsPublic(fileId),
    {},
);

export const getFileMetadataCancellable = async (
    API: CancellableAPI,
    fileId: string,
    key?: string,
): Promise<FileMetadata> => {
    const { result } = await API.get<FileMetadata>(BASEURL.backend(), ENDPOINTS.getFileMetadata(fileId), key || fileId);
    return result;
};

export const getFileMetadata = async (API: SPXAPI, fileId: string): Promise<FileMetadata> => (
    API.get(BASEURL.backend(), ENDPOINTS.getFileMetadata(fileId), {})
);
