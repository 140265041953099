import React, { FC, memo } from 'react';

import { notification as antNotification, Switch } from 'antd';
import classNames from 'classnames';

import i18n from '@/content';

import PolicySetting, { PolicyRowProps } from '../PolicySetting';
import { NOTIFICATION_DURATION, EXTRA_SETTINGS_CONTENT } from './constants';
import styles from './PolicySwitchRow.module.scss';

type PolicySwitchRowProps = PolicyRowProps<boolean>;

const PolicySwitchRow: FC<PolicySwitchRowProps> = ({
    settingValue,
    settingId,
    handleChange,
    isChild,
}) => {
    const extraContent = EXTRA_SETTINGS_CONTENT[settingId];
    const notification = extraContent?.notification;
    const bottomContent = extraContent?.bottomDescription;

    const onToggle = (checked: boolean): void => {
        if (notification) {
            antNotification.destroy();
            if (notification.activationFlag === checked) {
                antNotification.info({
                    message: i18n.t(notification.titleKey),
                    description: i18n.t(notification.descriptionKey),
                    duration: NOTIFICATION_DURATION,
                });
            }
        }
        handleChange(settingId, checked);
    };

    return (
        <PolicySetting
            isChild={isChild}
            settingId={settingId}
            type="switch"
            bottomContent={settingValue ? bottomContent : null}
        >
            <Switch
                className={classNames(
                    styles['policy-switch'],
                    settingValue ? styles.active : styles.inactive,
                )}
                datat-testid="policyEditor_settingsTuggle"
                onChange={onToggle}
                checked={settingValue}
            />
        </PolicySetting>
    );
};

export default memo(PolicySwitchRow);
