import React, { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';

import EditPhoneInput from '../../../../../Common/EditPhoneInput';
import { PhoneIdentity } from '@/types/types';
import styles from './EditPhone.module.scss';

interface EditPhoneProps extends PhoneIdentity {
    isSearching: boolean;
    onSubmit: (value: PhoneIdentity) => void,
    onClose: (value: PhoneIdentity) => void;
}

const EditPhone: FC<EditPhoneProps> = ({
    phone,
    prefix,
    isSearching,
    onSubmit,
    onClose,
}) => {
    if (isSearching) {
        return <LoadingOutlined className={styles.loader} spin />;
    }

    return (
        <EditPhoneInput
            phone={phone}
            prefix={prefix}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};

export default EditPhone;
