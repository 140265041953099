import {
    NotFoundError,
    PermissionsError,
    PermissionsCheckFail,
    BaseErrorConstructor,
} from '../../../../components/utils';
import { EntityLoadErrorType } from '../../../../types/types';

const MAP_ERROR_TYPE_TO_ERROR_CLASS: Partial<Record<EntityLoadErrorType, BaseErrorConstructor>> = {
    permissionCheckFail: PermissionsCheckFail,
    noPermissions: PermissionsError,
    notFound: NotFoundError,
};

const errorToErrorType = (error: unknown): EntityLoadErrorType => {
    let errorType: EntityLoadErrorType = (Object.keys(MAP_ERROR_TYPE_TO_ERROR_CLASS) as EntityLoadErrorType[]).find(
        (key) => error instanceof MAP_ERROR_TYPE_TO_ERROR_CLASS[key],
    );
    if (!errorType) {
        errorType = 'serverError';
    }
    return errorType;
};

export default errorToErrorType;
