import React, { SyntheticEvent, FC, memo } from 'react';

import { SpInput } from '@/components/Common/Sp';
import i18n from '@/content';
import AuthSubmitButton from '../AuthSubmitButton';
import { validateEmail } from '../../validators';

const nameSpace = 'forgotPassword.firstStep';

interface EnterEmailProps {
    email: string;
    onSubmit: (event: SyntheticEvent) => void;
    onChange: (email: string) => void;
}

const EnterEmail: FC<EnterEmailProps> = ({ onSubmit, onChange, email }) => (
    <div>
        <form onSubmit={onSubmit}>
            <SpInput
                type="text"
                value={email}
                placeholder={i18n.t(`${nameSpace}.placeholder`)}
                onChange={onChange}
            />
            <AuthSubmitButton
                isLimitedWidth
                disabled={!validateEmail(email)}
                onClick={onSubmit}
                titleKey={`${nameSpace}.reset`}
            />
        </form>
    </div>
);

export default memo(EnterEmail);
