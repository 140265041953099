import type { ResultStatusType } from 'antd/lib/result';

import type { ResultDescription, ResultType } from './interfaces';

export const nameSpace = 'giveAccess';

/*
* If you need to pass custom options into i18n payload, just pass empty object as placeholder
* */
export const MAP_RESULT_TYPE_TO_CONTENT: Record<ResultType, ResultDescription> = {
    incorrectURL: {
        titlePayload: [`${nameSpace}.shareResult.incorrectURL`],
        subTitlePayload: [`${nameSpace}.shareResult.someParamsMissing`],
    },
    shareFail: {
        titlePayload: ['general.specterxCommon.couldNotShareFile'],
        subTitlePayload: ['general.specterxCommon.pleaseTryAgain'],
    },
    shareForbidden: {
        titlePayload: [`${nameSpace}.shareResult.noPermissions`],
    },
    notFound: {
        titlePayload: ['general.errors.404', { type: '$t(general.specterxCommon.file)' }],
    },
    shareSuccess: {
        titlePayload: [`${nameSpace}.shareResult.sharingSuccess`],
        subTitlePayload: [`${nameSpace}.shareResult.sharingSuccessSubTitle`, {}],
    },
    upgradeFail: {
        titlePayload: [`${nameSpace}.upgradePermissionsResult.couldNotUpgradeAccess`],
        subTitlePayload: ['general.specterxCommon.pleaseTryAgain'],
    },
    upgradeForbidden: {
        titlePayload: [`${nameSpace}.upgradePermissionsResult.noPermissions`],
    },
    upgradeSuccess: {
        titlePayload: [`${nameSpace}.upgradePermissionsResult.giveAccessSuccess`, {}],
    },
};

export const MAP_RESULT_TYPE_TO_RESULT_STATUS: Record<ResultType, ResultStatusType> = {
    incorrectURL: 'warning',
    shareFail: 'error',
    notFound: 404,
    shareForbidden: 403,
    shareSuccess: 'success',
    upgradeFail: 'error',
    upgradeForbidden: 403,
    upgradeSuccess: 'success',
};

export const EMPTY_RESULT_DESCRIPTION: ResultDescription = { titlePayload: null };

export const UNCOMPLETED_RESULTS_SET: Set<ResultType> = new Set<ResultType>([
    'shareSuccess',
    'upgradeFail',
    'shareFail',
]);
