import React, {
    ChangeEvent, FC, useRef,
} from 'react';

import { InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { Input } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import i18n from '@/content';
import { clearMultipleSpaces } from '@/components/utils';

import styles from './SearchFiles.module.scss';

const nameSpace = 'myFiles.header.searchFiles';

const SearchFiles: FC = observer(() => {
    const {
        filesListStore: {
            searchByFilename,
            searchFileName,
            setSearchFileName,
        },
    } = useStores();

    const inputRef = useRef<InputRef>(null);

    const onSearch = (value: string): void => {
        searchByFilename(clearMultipleSpaces(value));
        inputRef.current?.blur();
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchFileName(event.target.value);
        if (event.target.value === '' && searchFileName) {
            onSearch('');
        }
    };

    return (
        <Input
            ref={inputRef}
            allowClear
            value={searchFileName}
            onChange={onChange}
            className={styles['search-input']}
            placeholder={i18n.t(`${nameSpace}.placeholder`)}
            defaultValue={searchFileName}
            onPressEnter={(event) => onSearch(event.currentTarget.getAttribute('value'))}
            prefix={(
                <SearchOutlined
                    className={styles['search-icon']}
                    onClick={() => onSearch(searchFileName)}
                />
            )}
        />
    );
});

export default SearchFiles;
