import React, {
    FC, ReactElement, useEffect, useState,
} from 'react';

import i18n from '@/content';
import ResultWithIcon from '../Common/ResultWithIcon';
import NewTabLink from './NewTabLink';
import TopTabLink from './TopTabLink';
import { IS_INSIDE_IFRAME, IS_SAFARI } from '../../config/browserEnv';
import { nameSpace } from './constants';

type Environment = 'browser' | 'nonBrowser' | 'safariInsideIframe';

const MAP_ENV_TYPE_TO_LINK_COMPONENT: Partial<Record<Environment, ReactElement>> = {
    nonBrowser: <NewTabLink />,
    safariInsideIframe: <TopTabLink />,
};

interface EnvironmentDetectorProps {
    children: ReactElement | ReactElement[];
}

const EnvironmentDetector: FC<EnvironmentDetectorProps> = ({ children }) => {
    const [environment, setEnvironment] = useState<Environment>(null);

    const isSupportedBrowser = environment === 'browser';
    const showError = environment && !isSupportedBrowser;

    useEffect(() => {
        if (!window.localStorage) {
            setEnvironment('nonBrowser');
        } else if (IS_SAFARI && IS_INSIDE_IFRAME) {
            setEnvironment('safariInsideIframe');
        } else {
            setEnvironment('browser');
        }
    }, []);

    return (
        <>
            {isSupportedBrowser && children}
            {showError && (
                <ResultWithIcon
                    status="onMoreStep"
                    title={i18n.t(`${nameSpace}.${environment}`)}
                    subTitle={MAP_ENV_TYPE_TO_LINK_COMPONENT[environment]}
                />
            )}
        </>
    );
};

export default EnvironmentDetector;
