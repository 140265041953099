import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { SimpleCallback } from '../../../../../../../../../types/types';
import nameSpace from '../../i18nNameSpace';
import styles from './DownloadButton.module.scss';

interface DownloadButtonProps {
    isLoading: boolean;
    isMultipleDownload: boolean;
    onClick: SimpleCallback;
}

const DownloadButton: FC<DownloadButtonProps> = ({
    isLoading,
    isMultipleDownload,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <Button
            className={styles['download-btn']}
            loading={isLoading}
            onClick={onClick}
            icon={<DownloadOutlined className={styles.icon} />}
        >
            <span
                className={styles['button-title']}
                data-testid="recipientPage_download"
            >
                {t(`${nameSpace}.${isMultipleDownload ? 'downloadAll' : 'singleDownload'}`)}
            </span>
        </Button>
    );
};

export default memo(DownloadButton);
