import React, { FC, ReactElement, useEffect } from 'react';

import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import appConfig from '../../../config/env';
import { LANGUAGES, MAP_I18N_LANGUAGE_TO_ANT_LOCALE } from '../../../content/languages';

/*
* Warning! Changing locale prop of the <ConfigProvider> will unmount children tree and mount it again.
* Try to avoid it if it's possible.
* */

interface LanguageProviderProps {
    children: ReactElement | ReactElement[];
    attachAntLocale?: boolean;
}

const LanguageProvider: FC<LanguageProviderProps> = ({ children, attachAntLocale }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (i18n.language !== appConfig.DEFAULT_LANGUAGE) {
            i18n.changeLanguage(appConfig.DEFAULT_LANGUAGE);
        }
        return () => {
            i18n.changeLanguage(LANGUAGES.en);
        };
    }, []);

    const locale = attachAntLocale ? MAP_I18N_LANGUAGE_TO_ANT_LOCALE[i18n.language] : null;

    return (
        <ConfigProvider direction={i18n.dir()} locale={locale}>
            {children}
        </ConfigProvider>
    );
};

export default LanguageProvider;
