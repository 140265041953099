// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultOptionType } from 'rc-select/lib/Select';

type SearchProp = 'label' | 'value';

export const filterOptions = (
    input: string,
    option: DefaultOptionType,
    searchProp: SearchProp,
): boolean => {
    const searchItem = option?.[searchProp] || '';
    if (typeof searchItem === 'string') {
        return searchItem.toLowerCase().includes(input.trim().toLowerCase());
    }
    return true;
};

export const filterByValue = (input: string, option: DefaultOptionType): boolean => filterOptions(input, option, 'value');

export const filterByLabel = (input: string, option: DefaultOptionType): boolean => filterOptions(input, option, 'label');
