import React, { FC, ReactElement } from 'react';

import { Button } from 'antd';
import classNames from 'classnames';

import ColoredTooltip from '../../../../../../Common/ColoredTooltip';
import { SimpleCallback } from '@/types/types';
import styles from './OverlayActionButton.module.scss';

interface OverlayActionButtonProps {
    onClick?: SimpleCallback;
    icon: ReactElement;
    tooltipText: string;
    // It's necessary to pass this props from carousel
    className?: string;
    buttonClassname: string;
}

const OverlayActionButton: FC<OverlayActionButtonProps> = ({
    onClick,
    icon,
    tooltipText,
    buttonClassname,
    className,
}) => (
    <ColoredTooltip title={tooltipText}>
        <Button
            type="link"
            onClick={onClick}
            size="small"
            className={classNames(
                styles['overlay-button'],
                styles['button-important'],
                buttonClassname,
                { [className]: !!className },
            )}
        >
            {icon}
        </Button>
    </ColoredTooltip>
);

export default OverlayActionButton;
