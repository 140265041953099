import React, { FC, memo, useMemo } from 'react';

import ElasticDescription, { ElasticDescriptionUIProps } from '../ElasticDescription';
import { ellipsisFile, splitEmail } from '../../utils';

interface EmailProps extends ElasticDescriptionUIProps {
    email: string;
    ellipsisDomainLimit?: number;
}

const DOMAIN_NAME_ELLIPSIS_LIMIT = 35;

const ElasticEmail: FC<EmailProps> = ({ email, ellipsisDomainLimit, ...rest }) => {
    const { username, domain } = useMemo(() => splitEmail(email), [email]);
    // domain name ellipsis happens same way as filename
    const ellipsisDomainLimitComputed = ellipsisDomainLimit || DOMAIN_NAME_ELLIPSIS_LIMIT;
    const domainNameToDisplay = useMemo(
        () => ellipsisFile(domain, ellipsisDomainLimitComputed),
        [ellipsisDomainLimitComputed],
    );

    return (
        <ElasticDescription
            fullDescription={email}
            ellipsisPart={username}
            nowrapPart={domainNameToDisplay}
            {...rest}
        />
    );
};

export default memo(ElasticEmail);
