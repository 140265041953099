import React, { FC, memo, MouseEvent } from 'react';

import { CloseOutlined, TeamOutlined } from '@ant-design/icons';

import { SimpleCallback } from '../../../../../types/types';
import styles from './RecipientChip.module.scss';
import { useStores } from '../../../../hooks';

interface RecipientChipProps {
    content: string;
    id?: string;
    onClose?: SimpleCallback;
    isGroup?: boolean;
}

const RecipientChip: FC<RecipientChipProps> = ({
    content, onClose, isGroup, id,
}) => {
    const { usersPhonesStore: { setPhoneNumbersGroupId } } = useStores();

    const onClick = (event: MouseEvent, groupId: string): void => {
        const clickableElement = (event.target as HTMLElement).closest('[data-clickable = true]');
        if (isGroup && groupId && !clickableElement) {
            setPhoneNumbersGroupId(groupId);
        }
    };

    return (
        <div className={styles['recipient-chip']} onClick={(event) => onClick(event, id)}>
            {isGroup && <TeamOutlined className={styles.icon} />}
            <span className={styles.content} data-testid="uploadDrawer_sharedUsers">
                {content}
            </span>
            {onClose && (
                <CloseOutlined
                    className={styles.close}
                    onClick={onClose}
                    data-clickable
                />
            )}
        </div>
    );
};

export default memo(RecipientChip);
