import {
    FC,
    createContext,
    ReactNode,
    useContext,
    useId,
    useLayoutEffect,
    useMemo,
} from 'react';

import { Portal } from '@/components/Common/Portal';

interface SlotPortalProps {
    dispatchMount: (nodeId: string) => void;
    footerId: string;
    headerId: string;
}

export const SlotPortalContext = createContext<SlotPortalProps>(null);

interface SlotPortalContextProviderProps extends Pick<SlotPortalProps, 'dispatchMount'> {
    children: ReactNode;
}

export const SlotPortalContextProvider: FC<SlotPortalContextProviderProps> = ({
    dispatchMount,
    children,
}) => {
    const footerId = useId();
    const headerId = useId();

    const contextValue = useMemo(() => ({
        footerId, headerId, dispatchMount,
    }), []);

    return (
        <SlotPortalContext.Provider value={contextValue}>
            {children}
        </SlotPortalContext.Provider>
    );
};

type NodeType = 'footerId' | 'headerId';

const SlotPortal: FC<{
    nodeType: NodeType;
    children: ReactNode;
}> = ({ nodeType, children }) => {
    const { dispatchMount, ...ids } = useContext(SlotPortalContext);

    const nodeId = ids[nodeType];

    useLayoutEffect(() => {
        dispatchMount(nodeId);
    }, []);

    return <Portal nodeId={nodeId}>{children}</Portal>;
};

export const ModalFooter: FC<{ children: ReactNode }> = ({ children }) => (
    <SlotPortal nodeType="footerId">{children}</SlotPortal>
);

export const ModalHeader: FC<{ children: ReactNode }> = ({ children }) => (
    <SlotPortal nodeType="headerId">{children}</SlotPortal>
);

export const ModalPortalAnchor: FC<{
    nodeType: NodeType,
    className: string,
}> = ({ nodeType, className }) => {
    const context = useContext(SlotPortalContext);

    return <div className={className} id={context[nodeType]} style={{ display: 'none' }} />;
};
