import React, { FC, memo } from 'react';

import { FileIcon } from '../../../Common/Icons';
import ClickableFile, { BaseClickableFileProps } from './ClickableFile';
import styles from './FileNameCell.module.scss';

interface FileNameCellProps extends BaseClickableFileProps {
    filename: string;
}

const FileNameCell: FC<FileNameCellProps> = ({
    filename,
    isFolder,
    fileId,
    filesListType,
    currentFolderKey,
    handleFileAction,
    fileAction,
}) => {
    const displayFileName = filename || fileId || '';

    return (
        <div className={styles['cell-wrapper']}>
            <FileIcon className={styles['file-icon']} filename={filename} isFolder={isFolder} />
            <ClickableFile
                fileId={fileId}
                isFolder={isFolder}
                currentFolderKey={currentFolderKey}
                fileAction={fileAction}
                handleFileAction={handleFileAction}
                filesListType={filesListType}
            >
                <span
                    title={displayFileName}
                    className={styles['file-name-text']}
                    data-testid="sharedWithMe_table_fileName"
                >
                    {displayFileName}
                </span>
            </ClickableFile>
        </div>
    );
};

export default memo(FileNameCell);
