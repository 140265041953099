import { useSize, UseSizeResult } from '../../../hooks';

const OFFSET_WITH_SHOW_MORE = 40;
const OFFSET_WITHOUT_SHOW_MORE = 4;

const useTableSize = (hasShowMore: boolean): UseSizeResult<HTMLDivElement> => {
    const { containerRef, size } = useSize<HTMLDivElement>();
    const heightOffset: number = hasShowMore ? OFFSET_WITH_SHOW_MORE : OFFSET_WITHOUT_SHOW_MORE;
    return {
        containerRef,
        size: {
            ...size,
            height: size.height - heightOffset,
        },
    };
};

export default useTableSize;
