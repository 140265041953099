import { PhoneIdentity } from '../../../../types/types';
import { AuthFlowType, AuthTypeParams } from './interfaces';

export const getAuthFlowType = ({
    hasEmailVerification,
    hasPhoneVerification,
    isAlreadyAuthenticated,
}: AuthTypeParams): AuthFlowType => {
    let flowType: AuthFlowType = isAlreadyAuthenticated ? 'phoneMFA' : 'email';
    if (hasPhoneVerification) {
        if (isAlreadyAuthenticated) {
            flowType = 'phoneMFA';
        } else if (hasEmailVerification) {
            flowType = 'emailAndPhone';
        } else if (!hasEmailVerification) {
            flowType = 'phoneAuth';
        }
    }
    return flowType;
};

const TRIPLE_X = 'xxx';

const maskPhone = (phone: string): string => {
    let result: string = phone;
    if (!phone.toLowerCase().includes(TRIPLE_X)) {
        const [,
            providerCode,
            firstPart,
            secondPart] = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d*)/);
        result = !firstPart
            ? providerCode
            : `${providerCode}-${firstPart}${(secondPart ? `-${secondPart}` : '')}`;
    }
    return result;
};

export const getPhoneNumberDisplay = ({ phone, prefix }: PhoneIdentity): string => (
    `+${prefix} ${maskPhone(phone)}`
);
