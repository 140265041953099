import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SimpleCallback } from '../../../../types/types';
import { NAME_SPACE } from '../../constants';
import styles from './FileSavingIndicator.module.scss';

const nameSpace = `${NAME_SPACE}.savingIndicator`;

interface FileSavingIndicatorProps {
    isSavingInProgress: boolean;
    hasError: boolean;
    hasUnFlattenAnnotations: boolean;
    onLockClick: SimpleCallback;
    saveFile: () => Promise<boolean>;
    carouselMode?: boolean;
}

const FileSavingIndicator: FC<FileSavingIndicatorProps> = ({
    isSavingInProgress,
    hasError,
    saveFile,
    hasUnFlattenAnnotations,
    onLockClick,
    carouselMode,
}) => {
    const { t } = useTranslation();

    const wrapperClasses = classNames(
        styles.wrapper,
        { [styles['carousel-mode']]: carouselMode },
    );

    return (
        <div className={wrapperClasses}>
            {hasError && (
                <div className={styles['state-chip']}>
                    <ExclamationCircleOutlined className={styles['error-icon']} />
                    {t(`${nameSpace}.error`)}
                    <Button
                        type="link"
                        className={styles['try-again']}
                        onClick={saveFile}
                    >
                        {t('general.buttons.save')}
                    </Button>
                </div>
            )}
            {!hasError && (
                isSavingInProgress
                    ? (
                        <div className={styles['state-chip']}>
                            <LoadingOutlined className={styles['loading-icon']} />
                            {t(`${nameSpace}.saving`)}
                        </div>
                    ) : (
                        hasUnFlattenAnnotations && (
                            <Button
                                className={styles.lock}
                                onClick={onLockClick}
                            >
                                {t(`${nameSpace}.lock`)}
                            </Button>
                        )
                    )
            )}
        </div>
    );
};

export default memo(FileSavingIndicator);
