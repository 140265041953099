/*
* Wrapper for ant-design Result component, which could use preset of custom icons instead of default warning triangle
* All props exclude the icon and status are same as default Result props
* @param status    {'error' | 'shareSuccess'}      used for set the icon type
* @param ?iconSize {'default' | 'small' | 'large'} used for limit the width of icon
* */
import React, { FC, memo, ReactNode } from 'react';

import { Result } from 'antd';
import type { ResultProps as AntResultProps } from 'antd/lib/result';

import {
    IconSize,
    LaunchRocket,
    ShareSuccessIcon,
    YogaIcon,
} from '../StatusIcon';

type Status = 'error' | 'shareSuccess' | 'onMoreStep';

interface ResultProps extends Omit<AntResultProps, 'icon' | 'status'> {
    iconSize?: IconSize;
    status: Status;
}

const getIcon = (status: Status, iconSize: IconSize): ReactNode => {
    switch (status) {
    case 'error':
        return <YogaIcon iconSize={iconSize} />;
    case 'shareSuccess':
        return <ShareSuccessIcon iconSize={iconSize} />;
    case 'onMoreStep':
        return <LaunchRocket iconSize={iconSize} />;
    default:
        return <></>;
    }
};

const ResultWithIcon: FC<ResultProps> = ({ status, iconSize = 'default', ...props }) => (
    <Result icon={getIcon(status, iconSize)} {...props} />
);

export default memo(ResultWithIcon);
