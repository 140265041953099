import React, {
    FC, KeyboardEvent,
} from 'react';

import {
    Button, Input, message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { AutoComplete } from '@/components/Common/UIKit';
import { useMemoizedEmailsToDropdown, useStores } from '@/components/hooks';
import { DropdownItem } from '@/types/types';
import i18n from '@/content';
import { validateEmail } from '@/components/validators';

import styles from './SearchOwner.module.scss';

const nameSpace = 'myFiles.header.searchOwner';

const validateEmailWithMessage = (emailValue: string): boolean => {
    const result = validateEmail(emailValue);
    if (!result) {
        message.error(i18n.t('general.specterxCommon.invalidEmail'));
    }
    return result;
};

const SearchOwner: FC = observer(() => {
    const { filesListStore, sharedUsersStore } = useStores();
    const { emailsList } = sharedUsersStore;
    const {
        searchByOwner, setSearchFileOwner, searchFileOwner,
    } = filesListStore;

    const trimmedInputValue = searchFileOwner.trim();

    const emailsToDropdown: DropdownItem[] = useMemoizedEmailsToDropdown(trimmedInputValue, emailsList);

    const runSearch = (newOwner: string): void => {
        searchByOwner(newOwner);
    };

    const handleKeyUp = (event: KeyboardEvent): void => {
        const currentTrimmedValue = (event.target as HTMLInputElement).value.trim();
        const isNonSelectEvent = event.key === 'Enter' && !emailsToDropdown.length;
        if (isNonSelectEvent && (!currentTrimmedValue || validateEmailWithMessage(currentTrimmedValue))) {
            event.preventDefault();
            runSearch(trimmedInputValue);
        }
    };

    const onSelect = (value: string): void => {
        runSearch(value);
    };

    const onSearch = (value: string): void => {
        const trimVal = value.trim();
        if (!trimVal || validateEmailWithMessage(trimVal)) {
            runSearch(trimVal);
        }
    };

    const onChange = (value: string) :void => {
        setSearchFileOwner(value);
        if (value === '') {
            onSearch(value);
        }
    };

    return (
        <div className={styles['search-owner-wrapper']}>
            <AutoComplete
                className={styles['search-owner']}
                options={emailsToDropdown}
                value={searchFileOwner}
                onChange={onChange}
                onKeyUp={handleKeyUp}
                onSelect={() => onSelect(searchFileOwner)}
            >
                <Input
                    allowClear
                    className={styles['input-wrapper']}
                    placeholder={searchFileOwner || i18n.t(`${nameSpace}.placeholder`)}
                    prefix={(
                        <Button
                            className={styles['search-button']}
                            type="link"
                            onClick={() => onSearch(searchFileOwner)}
                        >
                            <SearchOutlined />
                        </Button>
                    )}
                />
            </AutoComplete>
        </div>
    );
});

export default SearchOwner;
