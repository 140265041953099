import { TagInputOptions, ListTypeInputs } from './interfaces';
import { DOMAIN_REGEXP, EMAIL_REGEXP, IP_V4_REGEXP } from '../../../../../../regExp';

const DOMAINS_INPUT_OPTIONS: TagInputOptions = {
    showDescription: false,
    validationRegexp: DOMAIN_REGEXP,
};

const EMAIL_INPUT_OPTIONS: TagInputOptions = {
    showDescription: false,
    validationRegexp: EMAIL_REGEXP,
};

const IPS_INPUT_OPTIONS: TagInputOptions = {
    showDescription: false,
    validationRegexp: IP_V4_REGEXP,
};

const OPTIONS: Record<ListTypeInputs, TagInputOptions> = {
    [ListTypeInputs.domains]: DOMAINS_INPUT_OPTIONS,
    [ListTypeInputs.emailAddresses]: EMAIL_INPUT_OPTIONS,
    [ListTypeInputs.ips]: IPS_INPUT_OPTIONS,
};

export default OPTIONS;
