import { useMemo } from 'react';
import type { FC } from 'react';

import { CheckCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/he';

import SuccessFilesTable from '../SuccessFilesTable';
import { useStores } from '../../../hooks';
import '../index.scss';

const nameSpace = 'recipientPage.workspace.successScreen';

const useCurrentDate = (language: string): string => {
    const currentDate = useMemo(() => Date.now(), []);

    return dayjs(currentDate).locale(language).format('MMM Do YYYY, h:mm A');
};

interface SuccessScreenProps {
    sourceEmail: string;
}

const SuccessScreen: FC<SuccessScreenProps> = observer(({ sourceEmail }) => {
    const { t, i18n } = useTranslation();

    const formattedDate = useCurrentDate(i18n.language);

    const { uploadFilesStore: { successfullyUploadedItemsCount } } = useStores();

    return (
        <>
            <div>
                <CheckCircleFilled className="success-icon" />
            </div>
            <div className="title">
                {t(`${nameSpace}.filesSharedSuccessfullyWith`, {
                    filesCount: successfullyUploadedItemsCount,
                    ownerEmail: sourceEmail,
                })}
            </div>
            <div className="date">
                {t(`${nameSpace}.onDate`, { date: formattedDate })}
            </div>
            {successfullyUploadedItemsCount > 0 && <SuccessFilesTable />}
        </>
    );
});

export default SuccessScreen;
