import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../hooks';
import './index.scss';

const nameSpace = 'recipientPage.workspace.messageText';
const commonNameSpace = 'recipientPage.common';

interface MessageTextBlockProps {
    source: string;
}

const MessageTextBlock: FC<MessageTextBlockProps> = observer(({ source }) => {
    const { userStore } = useStores();
    const { currentUserEmail } = userStore;

    const { t } = useTranslation();

    return (
        <div className="workspace-default-message">
            <div>
                {t(`${nameSpace}.dear`, { user: currentUserEmail || t(`${nameSpace}.user`) })}
            </div>
            <div>
                {t(`${nameSpace}.hasSentYouAMessage`, { user: source || t(`${commonNameSpace}.someone`) })}
            </div>
        </div>
    );
});

export default MessageTextBlock;
