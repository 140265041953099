import type { ChangeEvent, FC } from 'react';

import { Tabs } from 'antd';
import { observer } from 'mobx-react';

import { Input } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import { PolicySettingsGroup } from '@/types/types';
import i18n from '@/content';

import PolicySection from './PolicySection';
import styles from './PolicySettings.module.scss';
import '../../../Common/Tabs/antOverride.scss';

const nameSpace = 'policyEditor';

const PolicySettings: FC = observer(() => {
    const {
        policyStore: {
            changedPolicy,
            setEditingPolicyName,
        },
    } = useStores();

    const changeName = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEditingPolicyName(value);
    };

    if (!changedPolicy) {
        return null;
    }

    const { settings } = changedPolicy;
    const availableSettings: PolicySettingsGroup[] = (Object.keys(settings) as PolicySettingsGroup[])
        .filter((key) => settings[key]?.length);

    return (
        <div className={styles['policy-settings']}>
            {changedPolicy.id === '' && (
                <div className={styles['new-policy-input-wrapper']}>
                    <span className={styles['section-title']}>
                        {i18n.t(`${nameSpace}.policyName`)}
                    </span>
                    <Input
                        onChange={changeName}
                        className={styles['name-input']}
                        value={changedPolicy.title}
                    />
                </div>
            )}

            <Tabs
                type="card"
                defaultActiveKey="access"
                className="antdesign-tabs-override"
                items={availableSettings.map((key) => ({
                    key,
                    label: i18n.t(`${nameSpace}.${key}.title`),
                    children: (
                        <PolicySection
                            settingsGroupType={key as PolicySettingsGroup}
                            policySettings={settings[key]}
                        />
                    ),
                }))}
            />
        </div>
    );
});

export default PolicySettings;
