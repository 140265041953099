import { DeferredUploading } from '../interfaces';

const calculateMemoryAllowedSlots = (
    currentMemoryUsageMax: number,
    activeMemoryLimit: number,
    candidates: DeferredUploading[],
): number => {
    let result = 0;
    let memorySum: number = currentMemoryUsageMax;
    for (let i = 0; i < candidates.length; i++) {
        const { fileSize } = candidates[i];
        result = i + 1;
        if (i === 0 && fileSize >= activeMemoryLimit) {
            break;
        }
        if (memorySum + fileSize <= activeMemoryLimit) {
            memorySum += fileSize;
        } else {
            break;
        }
    }
    return result;
};

export default calculateMemoryAllowedSlots;
