import { FC, memo } from 'react';

import { Skeleton } from 'antd';
import type { SkeletonParagraphProps } from 'antd/lib/skeleton/Paragraph';
import type { SkeletonTitleProps } from 'antd/lib/skeleton/Title';

import { arrayOf } from '../../../../utils';
import styles from './SkeletonView.module.scss';

const SKELETON_TITLE_PROPS: SkeletonTitleProps = {
    width: '70%',
};

const SKELETON_PARAGRAPH_PROPS: SkeletonParagraphProps = {
    rows: 3,
};

const FILES_SKELETON_COUNT = 3;

const SkeletonView: FC = () => (
    <div className={styles.container}>
        <div className={styles['top-content']}>
            <Skeleton
                active
                title={SKELETON_TITLE_PROPS}
                paragraph={SKELETON_PARAGRAPH_PROPS}
            />
        </div>
        <div className={styles['buttons-container']}>
            {arrayOf(FILES_SKELETON_COUNT).map((value) => (
                <Skeleton.Button
                    key={value}
                    className={styles['skeleton-button']}
                    active
                    shape="round"
                />
            ))}
        </div>
    </div>
);

export default memo(SkeletonView);
