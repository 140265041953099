import React, { FC, memo, SyntheticEvent } from 'react';

import { Button } from 'antd';
import classNames from 'classnames';

import i18n from '@/content';
import styles from './AuthSubmitButton.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

interface AuthSubmitButtonProps {
    disabled: boolean;
    titleKey: string;
    onClick: (event: SyntheticEvent) => void | Promise<void>;
    isLimitedWidth?: boolean;
    confirmUserLoading?: boolean
}

const AuthSubmitButton: FC<AuthSubmitButtonProps> = ({
    disabled, titleKey, onClick, isLimitedWidth = false, confirmUserLoading= false,
}) => (
    <div className={styles.container}>
        <Button
            disabled={disabled}
            htmlType="submit"
            className={classNames(styles['login-button'], { [styles['limited-width']]: isLimitedWidth })}
            onClick={onClick}
            size="large"
        >
            {i18n.t(titleKey)}
            {confirmUserLoading && <LoadingOutlined className={styles.loader} spin />}
        </Button>
    </div>
);

export default memo(AuthSubmitButton);
