import React, {
    FC, useEffect,
} from 'react';

import { observer } from 'mobx-react';

import i18n from '@/content';
import TabTitle from '../../../Common/TabTitle';
import Spinner from '../../../Common/Spin';
import ConfigureGroup from './ConfigureGroup';
import UserGroupsSidebar from './UserGroupsSidebar';
import { useStores } from '../../../hooks';
import styles from './UserGroups.module.scss';

const nameSpace = 'customGroupsEditor';

const UserGroups: FC = observer(() => {
    const { usersListStore } = useStores();

    const {
        loadingCustomGroups,
        fetchUsersCustomGroups,
        newGroupsMode,
    } = usersListStore;
    const title = newGroupsMode ? 'titleCreate' : 'titleConfigure';

    useEffect(() => {
        fetchUsersCustomGroups();
    }, []);

    return (
        <Spinner spinning={loadingCustomGroups} screenCentered>
            <TabTitle title="Groups" />
            <div className={styles['groups-editor']}>
                <UserGroupsSidebar title="Groups" />
                <div className={styles['groups-settings-container']}>
                    <div className={styles['group-title']}>{i18n.t(`${nameSpace}.${title}`)}</div>
                    <ConfigureGroup />
                </div>
            </div>
        </Spinner>
    );
});

export default UserGroups;
