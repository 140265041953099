import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { PhoneNumberViewContainer } from '../../PhoneNumberViewContainer';
import styles from './LoadingPhone.module.scss';

const LoadingPhoneView: FC = () => (
    <PhoneNumberViewContainer>
        <LoadingOutlined className={styles['users-action-loader']} spin />
    </PhoneNumberViewContainer>
);

export default LoadingPhoneView;
