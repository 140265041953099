import { useEffect, useRef } from 'react';

import { SortableFields } from '../../../../stores';

const CLICKS_TO_RESET = 2;

type ChangerOrdering = (orderBy: SortableFields) => Promise<void>;

interface Arguments {
    isCurrentOrderBy: boolean;
    changeOrdering: ChangerOrdering;
}

const useOrderingHandler = ({
    changeOrdering,
    isCurrentOrderBy,
}: Arguments): ChangerOrdering => {
    const clicksCounter = useRef<number>(0);

    const onClick = async (field: SortableFields): Promise<void> => {
        const isReset = clicksCounter.current === CLICKS_TO_RESET;
        clicksCounter.current = isReset ? 0 : clicksCounter.current + 1;
        await changeOrdering(isReset ? null : field);
    };

    useEffect(() => {
        if (!isCurrentOrderBy) {
            clicksCounter.current = 0;
        }
    }, [isCurrentOrderBy]);

    return onClick;
};

export default useOrderingHandler;
