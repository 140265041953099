export const HAS_CLIPBOARD = 'clipboard' in navigator;
export const IS_INSIDE_IFRAME = window.location !== window.top.location;

const checkIsSafari = (): boolean => {
    const userAgent = navigator && navigator.userAgent;
    if (userAgent) {
        return userAgent.includes('Safari') && !userAgent.includes('Chrome');
    }
    return false;
};

export const IS_SAFARI = checkIsSafari();

export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
