import React, { FC, memo } from 'react';

import SkeletonItem from './SkeletonItem';
import { UUID_V4_REGEXP } from '../../../regExp';

interface OwnerProps {
    owner: string;
}

const Owner: FC<OwnerProps> = ({ owner }) => (
    UUID_V4_REGEXP.test(owner) ? <SkeletonItem /> : <>{owner}</>
);

export default memo(Owner);
