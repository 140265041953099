import { useEffect } from 'react';

import { useComponentDidUpdate } from '@/components/hooks';
import {
    AccessType,
    checkIsPermissionsError,
    FilesAccessStore,
    SharedFilesStore,
    UserStore,
} from '@/stores';
import { OpenOptions } from '@/config/openOptions';
import { AsyncCallback } from '@/types/types';

import { OTPState } from './useOTPState';
import { checkIsLastOpenWithViewerFailed } from '../helpers';

interface OTPToggleReactiveArgs extends OTPState {
    readonly filesAccessStore: FilesAccessStore;
    readonly userStore: UserStore;
    readonly sharedFilesStore: SharedFilesStore;
    readonly lastAccessType: AccessType;
    readonly postAuthAction: AsyncCallback;
    readonly messageId?: string;
}

const useOTPReactiveToggle = ({
    showOTP,
    closeOTP,
    openOTP,
    sharedFilesStore,
    filesAccessStore,
    userStore,
    lastAccessType,
    postAuthAction,
    messageId,
}: OTPToggleReactiveArgs): void => {
    const {
        selectedFilePermissionsSummary,
    } = sharedFilesStore;

    const {
        selectedItemMFAError,
        selectedAccessFileId,
        selectedItemAccessTypesState,
    } = filesAccessStore;

    useEffect(() => {
        const isMessage = messageId === selectedAccessFileId;
        if (
            userStore.isUserSetUp
            && selectedAccessFileId
            && showOTP
            && selectedItemMFAError !== 'MFARequired'
            && !isMessage
        ) {
            closeOTP();
        }
    }, [selectedAccessFileId]);

    const isLastTryOpenViewerFailed = checkIsLastOpenWithViewerFailed(lastAccessType, selectedItemAccessTypesState);

    useEffect(() => {
        const isNotPossibleToFinishMFA = checkIsPermissionsError(selectedFilePermissionsSummary)
            || isLastTryOpenViewerFailed;
        if (isNotPossibleToFinishMFA) {
            closeOTP();
        } else if (selectedItemMFAError === 'MFARequired') {
            openOTP();
        }
    }, [selectedFilePermissionsSummary, selectedItemMFAError, isLastTryOpenViewerFailed]);

    useComponentDidUpdate(() => {
        (async () => {
            if (!showOTP && userStore.isUserSetUp && !sharedFilesStore.filesPermissions.size) {
                await sharedFilesStore.tryBatchAddPermissions();
                // In viewer flow is much more simple to fetch the link on mount FileView
                if (lastAccessType !== OpenOptions.viewer) {
                    await postAuthAction();
                }
            }
        })();
    }, [showOTP]);
};

export default useOTPReactiveToggle;
