import React, { FC } from 'react';

import withIconWrapper from './withIconWrapper';

/* eslint-disable max-len */
const SelectRightOut: FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#0F778C"
            d="M21.858 22.718H2.143a.856.856 0 01-.857-.857V2.146c0-.474.383-.857.857-.857h9.643c.118 0 .214.097.214.214v1.5a.215.215 0 01-.214.215H3.215v17.571h17.571v-8.571c0-.118.097-.214.214-.214h1.5c.118 0 .215.096.215.214v9.643a.856.856 0 01-.857.857zM18.934 3.623l-1.398-1.398a.214.214 0 01.126-.364l4.806-.563c.136-.016.254.1.238.238l-.563 4.806a.215.215 0 01-.364.126l-1.404-1.404-6.862 6.863a.215.215 0 01-.303 0l-1.135-1.136a.215.215 0 010-.303l6.86-6.865z"
        />
    </svg>
);

export default withIconWrapper(SelectRightOut);
