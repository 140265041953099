import type { FC } from 'react';
import { memo } from 'react';

import { Select } from '@/components/Common/UIKit';
import { CognitoGroupsOrganizations, DropdownItem } from '@/types/types';
import styles from './RoleGroup.module.scss';

interface RoleGroupProps {
    options: DropdownItem[];
    group: CognitoGroupsOrganizations;
    userId: string;
    onChange: (userId: string, group: CognitoGroupsOrganizations) => void;
}

const RoleGroups: FC<RoleGroupProps> = ({
    options,
    userId,
    group,
    onChange,
}) => {
    const handleChange = (value: CognitoGroupsOrganizations): void => onChange(userId, value);

    return (
        <div className={styles['role-group-container']}>
            {group && (
                <Select<CognitoGroupsOrganizations>
                    onChange={handleChange}
                    defaultValue={group}
                    options={options}
                />
            )}
        </div>
    );
};

export default memo(RoleGroups);
