import React, { FC } from 'react';

import { Breadcrumb } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import FolderLink from '@/components/Common/FolderLink';
import { useStores } from '@/components/hooks';
import type { FileToDisplay } from '@/stores';
import i18n from '@/content';
import { ROOT_FOLDER } from '@/consts';

import DroppableBreadcrumb from './DroppableBreadcrumb';
import type { BreadcrumbToDisplay } from './interfaces';
import { nameSpace } from '../nameSpaces';
import styles from './FolderBreadcrumbs.module.scss';

const createBreadcrumbsToDisplay = (rootTitle: string, foldersChain: FileToDisplay[]): BreadcrumbToDisplay[] => {
    const root: BreadcrumbToDisplay = {
        name: rootTitle,
        itemKey: ROOT_FOLDER,
    };
    const restBreadcrumbs = foldersChain.map<BreadcrumbToDisplay>(
        ({ filename, itemKey }) => ({ name: filename, itemKey }),
    );
    return [root, ...restBreadcrumbs];
};

const FolderBreadcrumbs: FC = observer(() => {
    const {
        filesListStore:
            {
                currentFolderBreadcrumbs,
                displayFilesType,
                currentFolderKey,
            },
    } = useStores();

    const title = i18n.t(`${nameSpace}.title.${displayFilesType}`);
    const breadcrumbsToDisplay = createBreadcrumbsToDisplay(title, currentFolderBreadcrumbs);

    return (
        <div className={styles['breadcrumbs-wrapper']}>
            {currentFolderBreadcrumbs.length > 0
                ? (
                    <Breadcrumb
                        className={styles.breadcrumbs}
                        separator={<span className={styles.item}>{'>'}</span>}
                        items={breadcrumbsToDisplay.map(({ itemKey, name }) => ({
                            title: (
                                <FolderLink filesListType={displayFilesType} folderKey={itemKey}>
                                    <DroppableBreadcrumb
                                        isDropAllowed={displayFilesType === 'myFiles'}
                                        isSelected={itemKey === currentFolderKey}
                                        itemKey={itemKey}
                                        name={name}
                                    />
                                </FolderLink>
                            ),
                        }))}
                    />
                )
                : (
                    <span className={classNames(styles.item, styles.current)}>
                        {title}
                    </span>
                )}
        </div>
    );
});

export default FolderBreadcrumbs;
