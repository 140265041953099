import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../hooks';
import styles from './UploadProgress.module.scss';

const UploadProgress: FC = observer(() => {
    const { uploadFilesStore: { allFilesCount, completedFilesCount } } = useStores();

    const { t } = useTranslation();

    return (
        <>
            {allFilesCount > 0 && (
                <div className={styles.container}>
                    <span>
                        {t('uploadFiles.uploadProgress', { allFilesCount, completedFilesCount })}
                    </span>
                </div>
            )}
        </>
    );
});

export default UploadProgress;
