import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { message } from 'antd';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router';

import TabTitle from '../Common/TabTitle';
import Modal from '../Common/Modal';
import { useComponentDidUpdate, useStores } from '@/components/hooks';
// TODO: move it into some common module
import { FileActionType } from '../FilesTable/FileAction/menuItems';
import i18n from '@/content';

import AuditsTable from './AuditsTable';
import ActionsModal from './ActionsModalContent';
import { AuditsHeader } from './AuditsHeader';
import { NAME_SPACE } from './constants';
import styles from './Audits.module.scss';

interface FileActionState {
    actionType: FileActionType;
    fileId: string;
}

const Audits: FC = observer(() => {
    const [fileAction, setFileAction] = useState<FileActionState>({ fileId: '', actionType: null });

    const { auditsStore } = useStores();
    const { hasError } = auditsStore;

    const location = useLocation();

    useEffect(() => {
        const searchedFileId = new URLSearchParams(location.search).get('file_id') || '';
        auditsStore.setFileId(searchedFileId);
        auditsStore.tryFetchAudits();
        return () => {
            message.destroy();
            auditsStore.setHasError(false);
            auditsStore.clearFilters();
        };
    }, []);

    useEffect(() => {
        if (hasError) {
            message.destroy();
            message.error(i18n.t(`${NAME_SPACE}.messages.couldNotLoadLogs`));
        }
    }, [hasError]);

    useComponentDidUpdate(() => {
        auditsStore.tryFetchAudits();
    }, [location.key]);

    const handleFileAction = useCallback((actionType: FileActionType, fileId: string): void => {
        setFileAction({ actionType, fileId });
    }, []);

    const closeActionModal = useCallback((): void => {
        setFileAction({ actionType: null, fileId: '' });
        if (auditsStore.pagination.currentPage === 1) {
            auditsStore.tryFetchAudits();
        }
    }, []);

    const { fileId, actionType } = fileAction;

    return (
        <div className={styles['audits-wrapper']}>
            <TabTitle title={i18n.t(`${NAME_SPACE}.title`)} />
            <AuditsHeader />
            <AuditsTable handleMenuClick={handleFileAction} />
            <Modal closeModal={closeActionModal} isOpen={!!actionType}>
                <ActionsModal actionType={actionType} fileId={fileId} onClose={closeActionModal} />
            </Modal>
        </div>
    );
});

export default Audits;
