import { useLayoutEffect } from 'react';

const useGlobalStylesToggle = (className: string): void => {
    useLayoutEffect(() => {
        document.body.classList.add(className);
        return () => {
            document.body.classList.remove(className);
        };
    }, [className]);
};

export default useGlobalStylesToggle;
