import {
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from 'react';

import { TFunction } from 'i18next';

import useDebounce, { DebouncedEventHandler } from '../useDebounce';
import useOTPFlow, { OTPFlowCore } from './useOTPFlow';
import useSecondStepTitle from './useSecondStepTitle';
import AuthSettingsStore from '../../../stores/AuthSettingsStore';
import UserStore from '../../../stores/UserStore';
import { StatusMessage, InitFormValues } from '../../Common/CodeAuthenticator';
import { SimpleCallback } from '@/types/types';
import { onSendCodeError } from './helpers';
import { codeAuthNameSpace, EMAIL_OTP_LENGTH } from './constants';

interface EmailOTPFlow extends OTPFlowCore {
    email: string;
    secondStepTitle: ReactNode;
    initEmailInputValue: InitFormValues;
    emailAuthFirstStepMessages: StatusMessage[];
    sendEmailAuthCode: (email: string) => Promise<void>;
    onSendEmailError: (error: unknown) => void;
    onEmailRetryLimitReached: SimpleCallback;
    onChangeEmail: DebouncedEventHandler;
}

const useEmailOTPFlow = (
    t: TFunction,
    authSettingsStore: AuthSettingsStore,
    userStore: UserStore,
    initEmailValue = '',
): EmailOTPFlow => {
    const [email, setEmail] = useState<string>(initEmailValue);
    const [emailAuthFirstStepMessages, setEmailAuthFirstStepMessages] = useState<StatusMessage[]>([]);

    const [
        setShowEmailIntoSecondStepTitle,
        setNewSecondStepTitle,
        secondStepTitle,
    ] = useSecondStepTitle(
        initEmailValue,
        EMAIL_OTP_LENGTH,
        t,
        `${codeAuthNameSpace}.secondStepCommon`,
    );

    const {
        checkAuthCode,
        sendAuthCode,
        isMountedRef,
        resendTimerRef,
    } = useOTPFlow(authSettingsStore, userStore);

    const initEmailInputValue = useMemo<InitFormValues>(() => ({ email: initEmailValue }), [initEmailValue]);

    const onEmailRetryLimitReached = (): void => {
        setEmailAuthFirstStepMessages([]);
        setShowEmailIntoSecondStepTitle(false);
    };

    const handleSendCodeSuccess = (emailValue: string): void => {
        setNewSecondStepTitle(emailValue);
        setEmailAuthFirstStepMessages([{ message: `${codeAuthNameSpace}.firstStepCommon.notReceived` }]);
    };

    const sendEmailAuthCode = async (emailValue: string): Promise<void> => {
        setEmailAuthFirstStepMessages([]);
        setShowEmailIntoSecondStepTitle(false);
        await sendAuthCode(emailValue, 'email');
        if (isMountedRef.current) {
            handleSendCodeSuccess(emailValue);
        }
    };

    const onSendEmailError = (error: unknown): void => {
        onSendCodeError(error, setEmailAuthFirstStepMessages);
    };

    const debouncedChangeEmail = useDebounce((value) => {
        resendTimerRef.current?.clear();
        setEmail(value);
    });

    const onChangeEmail = useCallback(debouncedChangeEmail, []);

    return {
        isMountedRef,
        sendAuthCode,
        checkAuthCode,
        email,
        secondStepTitle,
        emailAuthFirstStepMessages,
        initEmailInputValue,
        sendEmailAuthCode,
        onSendEmailError,
        onEmailRetryLimitReached,
        onChangeEmail,
        resendTimerRef,
    };
};

export default useEmailOTPFlow;
