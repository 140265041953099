import React, { FC, memo } from 'react';

import { Skeleton } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { PUBLIC_ASSETS, SPX_INFO_EMAIL } from '@/consts';
import { PRIVACY_POLICY_LINKS, nameSpace } from './constants';
import styles from './PublicFooter.module.scss';

interface PublicFooterProps {
    showSkeleton?: boolean;
}

const PublicFooter: FC<PublicFooterProps> = ({ showSkeleton = false }) => {
    const { t, i18n } = useTranslation();

    return (
        <footer className={styles.footer}>
            <img
                className={styles.logo}
                src={`${PUBLIC_ASSETS}/company_logo/specterx-yourdata.png`}
                alt="SpecterX"
            />
            {showSkeleton
                ? (
                    <Skeleton
                        active
                        className={styles.skeleton}
                        paragraph={false}
                        title
                    />
                )
                : (
                    <>
                        <div className={styles['footer-text']}>
                            <Trans
                                t={t}
                                i18nKey={`${nameSpace}.dropUsAMessage`}
                                tOptions={{ email: SPX_INFO_EMAIL }}
                            >
                                drop message
                                <a href={`mailto:${SPX_INFO_EMAIL}`}>
                                    {SPX_INFO_EMAIL}
                                </a>
                                or visit
                                <a
                                    href="https://specterx.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.specterx.com
                                </a>
                            </Trans>
                        </div>
                        <a
                            href={PRIVACY_POLICY_LINKS[i18n.language]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles['footer-text']}
                        >
                            {t(`${nameSpace}.privacyPolicy`)}
                        </a>
                    </>
                )}
        </footer>
    );
};

export default memo(PublicFooter);
