import { BASEURL, ENDPOINTS } from '../endpoints';
import { SPXAPI } from '../interfaces';
import { SecuredFile } from '../../types/types';

const getBreadcrumbs = async (currentFolderId: string, API: SPXAPI): Promise<SecuredFile[]> => {
    const { breadCrumbs } = await API.get(
        BASEURL.backend(),
        ENDPOINTS.getFilePath(currentFolderId),
        {},
    );
    return breadCrumbs;
};

export default getBreadcrumbs;
