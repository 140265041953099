import React, { Component } from 'react';

import { Button, message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import i18n from '@/content';
import SharingBlock, { NotifyRecipients } from '../../../Common/SharingBlock';
import SharePopupHeader from './SharePopupHeader';
import {
    PolicyStore, SharedUsersStore, FilesListStore, UsersPhonesStore,
} from '../../../../stores';
import { FileToShare } from '../../../../stores/SharedUsersStore/interfaces';
import { ModalContentProps } from '../../../Common/Modal';
import {
    captureUnexpectedNetworkError,
    checkIsPhoneOnlyPolicy,
    getErrorResponse,
    onFinishSharingFlow,
} from '../../../utils';
import { SharedItemType } from '../../../Common/RecipientRoleSelect';
import './index.scss';

const REFRESH_PERMISSIONS_TIMEOUT_MS = 3_000;

interface ShareFileProps extends ModalContentProps {
    onShareFile: (success: boolean) => void;
    fileId: string;
    policyId: string;
    ownerEmail: string;
    hideSettings: boolean;
    sharedItemType?: SharedItemType;
    toggleFileSettings: () => void;
    policyStore?: PolicyStore;
    sharedUsersStore?: SharedUsersStore;
    filesListStore?: FilesListStore;
    usersPhonesStore?: UsersPhonesStore;
}

@inject('policyStore', 'sharedUsersStore', 'filesListStore', 'usersPhonesStore')
@observer
class ShareFile extends Component<ShareFileProps> {
    private readonly nameSpace = 'filesTable.modals.shareFile';

    componentWillUnmount(): void {
        message.destroy();
    }

    onShareClick = (): void => {
        const { sharedUsersStore, usersPhonesStore, policyStore: { selectedPolicy } } = this.props;
        const hasPhoneOnlyPolicy = selectedPolicy ? checkIsPhoneOnlyPolicy(selectedPolicy.settings) : false;
        onFinishSharingFlow(
            i18n.t.bind(i18n),
            this.shareFile,
            hasPhoneOnlyPolicy,
            { sharedUsersStore, usersPhonesStore },
        );
    }

    private shareFile = async (): Promise<void> => {
        const {
            setErrorStatus, setLoadingStatus, onShareFile,
            fileId, policyId, sharedUsersStore, filesListStore: { chosenTableFileId },
        } = this.props;
        const files: FileToShare[] = [{ file_id: fileId, policy_id: policyId }];

        const nameSpace = `${this.nameSpace}.messages`;

        setLoadingStatus(true, i18n.t(`${nameSpace}.wait.sharingFile`));

        try {
            await sharedUsersStore.bulkShareFiles({ files });
            onShareFile(true);
            setLoadingStatus(false);
            if (chosenTableFileId === fileId) {
                setTimeout(() => sharedUsersStore.fetchSharedUsers(chosenTableFileId), REFRESH_PERMISSIONS_TIMEOUT_MS);
            }
        } catch (error) {
            console.log('error while sharing', error);
            captureUnexpectedNetworkError(error, 'ShareModal.ShareFile.shareFile');
            const { statusCode, message: responseMessage } = getErrorResponse(error);
            const hasDomainError = responseMessage?.includes('domain');
            if (statusCode === 403 && hasDomainError) {
                setErrorStatus(true, responseMessage);
            } else {
                setErrorStatus(true, i18n.t(`${nameSpace}.error.couldNotShare`));
            }
        }
    }

    render(): JSX.Element {
        const {
            toggleFileSettings,
            hideSettings,
            ownerEmail,
            policyStore: { selectedPolicy },
            sharedUsersStore: { hasRecipients },
            sharedItemType,
        } = this.props;
        const { nameSpace } = this;

        const headerTitle = i18n.t(`${nameSpace}.defaultTitle`);
        const isMfa = !!selectedPolicy?.isMfa;

        return (
            <div className="share-file-wrapper">
                <SharingBlock
                    hasSharedUsers
                    showPhones={isMfa}
                    sharedItemType={sharedItemType}
                    ownerEmail={ownerEmail}
                    headerWrapperClassName="header"
                    headerContent={(
                        <SharePopupHeader
                            title={headerTitle}
                            hideToggle={hideSettings}
                            toggleFileSettings={toggleFileSettings}
                            toggleText={i18n.t(`${nameSpace}.toggleText`)}
                            toggleIcon={<SettingOutlined />}
                        />
                    )}
                />
                <div className={classNames('confirm-button-wrap', { 'justify-between': hasRecipients })}>
                    {hasRecipients && <NotifyRecipients />}
                    <Button
                        onClick={this.onShareClick}
                        className="submit-button"
                        type="primary"
                    >
                        {i18n.t('general.specterxCommon.share')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ShareFile;
