import {
    makeObservable,
    observable,
    runInAction,
} from 'mobx';
import cloneDeep from 'lodash/cloneDeep';

type NodeType = 'node' | 'leaf';

export interface TreeNode<Value, Payload> {
    key: string;
    parentKey: string | null;
    value: Value;
    type: NodeType;
    payload?: Payload;
    children?: TreeNode<Value, Payload>[];
}

class ObservableTree<Value, Payload> {
    constructor(key: string, rootValue: Value, rootPayload: Payload) {
        makeObservable(this);
        runInAction(() => {
            this.root = {
                key,
                type: 'node',
                parentKey: null,
                value: rootValue,
                payload: rootPayload,
            };
        });
    }

    @observable root: TreeNode<Value, Payload>;

    * traversalNodes(
        node: TreeNode<Value, Payload> = this.root,
    ): Generator<TreeNode<Value, Payload>> {
        yield node;
        if (node.children) {
            for (const child of node.children) {
                if (child.type === 'node') {
                    yield* this.traversalNodes(child);
                }
            }
        }
    }

    * traversal(
        node: TreeNode<Value, Payload> = this.root,
    ): Generator<TreeNode<Value, Payload>> {
        yield node;
        if (node.children) {
            for (const child of node.children) {
                yield* this.traversal(child);
            }
        }
    }

    setNodeChildren(key: string, children: TreeNode<Value, Payload>[]): void {
        this.mutateNode(key, (node) => {
            // eslint-disable-next-line no-param-reassign
            node.children = children;
        });
    }

    setNodeValue(key: string, value: Value): void {
        this.mutateNode(key, (node) => {
            // eslint-disable-next-line no-param-reassign
            node.value = value;
        });
    }

    setNodePayload(key: string, payload: Payload): void {
        this.mutateNode(key, (node) => {
            // eslint-disable-next-line no-param-reassign
            node.payload = payload;
        });
    }

    appendChildrenNodes(
        key: string,
        children: TreeNode<Value, Payload> | TreeNode<Value, Payload>[],
    ): void {
        this.mutateNode(key, (node) => {
            const newNodes: TreeNode<Value, Payload>[] = Array.isArray(children)
                ? children
                : [children];
            if (!node.children) {
                // eslint-disable-next-line no-param-reassign
                node.children = newNodes;
            } else {
                node.children.push(...newNodes);
            }
        });
    }

    insert(
        parentNodeKey: string,
        key: string,
        value: Value,
        payload: Payload,
    ): TreeNode<Value, Payload> {
        let result: TreeNode<Value, Payload> = null;

        this.mutateNode(parentNodeKey, (node) => {
            result = {
                value,
                payload,
                key,
                parentKey: parentNodeKey,
                type: 'node',
                children: null,
            };
            if (!node.children) {
                // eslint-disable-next-line no-param-reassign
                node.children = [result];
            } else {
                node.children.push(result);
            }
        });
        return result;
    }

    find(key: string): TreeNode<Value, Payload> | null {
        for (const node of this.traversalNodes()) {
            if (node.key === key) {
                return node;
            }
        }
        return null;
    }

    protected mutateNode(key: string, mutation: (node: TreeNode<Value, Payload>) => void): void {
        const node = this.find(key);
        if (!node) {
            this.warn(`No node with key ${key}`);
        }
        runInAction(() => mutation(node));
    }

    protected remove(key: string): void {
        const node = this.find(key);
        if (node) {
            this.mutateNode(node.parentKey, (parent) => {
                // eslint-disable-next-line no-param-reassign
                parent.children = parent.children?.filter(
                    (child) => (child.type === 'node' ? child.key !== key : true),
                );
            });
        }
    }

    protected warn(message: string): void {
        console.warn(message);
        console.log('tree snapshot is', cloneDeep(this.root));
    }
}

export default ObservableTree;
