import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import Hotjar from '@hotjar/browser';

import { useStores } from '../hooks';
import { IS_NON_PROD_ENV } from '../../config/env';

const HOTJAR_ID = 3121761;
const HOTJAR_VERSION = 6;

interface HotjarWrapperProps {
    children: ReactElement | ReactElement[];
}

const HotjarWrapper: FC<HotjarWrapperProps> = ({ children }) => {
    const { authSettingsStore: { HAS_API_PROXY } } = useStores();

    const isInitiatedRef = useRef<boolean>(false);

    useEffect(() => {
        if (!IS_NON_PROD_ENV && !HAS_API_PROXY && !isInitiatedRef.current) {
            Hotjar.init(
                Number(import.meta.env.VITE_HOTJAR_ID || HOTJAR_ID),
                Number(import.meta.env.VITE_HOTJAR_VERSION || HOTJAR_VERSION),
            );
            isInitiatedRef.current = true;
        }
    }, [HAS_API_PROXY]);

    return <>{children}</>;
};

export default HotjarWrapper;
