import React, { CSSProperties, FC } from 'react';

import { Button } from 'antd';

import i18n from '@/content';
import ResultWithIcon from '../Common/ResultWithIcon';

const nameSpace = 'errorsBoundary';

const BUTTON_STYLE: CSSProperties = { width: '300px' };

const FallbackUI: FC = () => (
    <ResultWithIcon
        status="error"
        title={i18n.t(`${nameSpace}.title`)}
        extra={(
            <div>
                <p>
                    {i18n.t(`${nameSpace}.subTitle`)}
                </p>
                <Button style={BUTTON_STYLE} type="primary" onClick={() => window.location.reload()} size="large">
                    {i18n.t('general.buttons.refresh')}
                </Button>
            </div>
        )}
    />
);

export default FallbackUI;
