import {
    action,
    observable,
    computed,
    makeObservable,
} from 'mobx';
import { API as AmplifyAPI } from 'aws-amplify';
import { UserManager } from 'oidc-client';

import { AxiosAmplifyAdapter, EndpointsResolver, SPXAPIWithEndpointsResolving } from '../../api';
import appConfig, {
    IS_WSO as IS_WSO_INITIAL, HAS_API_PROXY as HAS_API_PROXY_INITIAL, API_PROXY,
} from '../../config/env';

type ExtraAuthCheckKey = keyof Pick<API_PROXY, 'DISABLE_MFA_BEHIND_PROXY' | 'DISABLE_OTP_BEHIND_PROXY'>;

class AuthSettingsStore {
    constructor(userManager?: UserManager) {
        this.HAS_API_PROXY = HAS_API_PROXY_INITIAL;
        this.amplifyApi = new EndpointsResolver(HAS_API_PROXY_INITIAL, AmplifyAPI);
        if (IS_WSO_INITIAL) {
            const axiosApi = new AxiosAmplifyAdapter(userManager);
            this.axiosApi = new EndpointsResolver(true, axiosApi);
            this.IS_WSO = true;
            this.API = this.axiosApi;
        } else {
            this.IS_WSO = false;
            this.API = this.amplifyApi;
        }
        makeObservable(this);
    }

    private readonly axiosApi: SPXAPIWithEndpointsResolving;

    private readonly amplifyApi: SPXAPIWithEndpointsResolving;

    @observable IS_WSO: boolean;

    @observable HAS_API_PROXY: boolean;

    @observable API: SPXAPIWithEndpointsResolving;

    @computed
    get skipMFA(): boolean {
        return this.skipExtraAuthCheck('DISABLE_MFA_BEHIND_PROXY');
    }

    @computed
    get skipOTP(): boolean {
        return this.skipExtraAuthCheck('DISABLE_OTP_BEHIND_PROXY');
    }

    @computed
    get hasNASStorage(): boolean {
        return this.HAS_API_PROXY;
    }

    private skipExtraAuthCheck(flagKey: ExtraAuthCheckKey): boolean {
        return this.IS_WSO || (this.HAS_API_PROXY && appConfig.API_PROXY && appConfig.API_PROXY[flagKey]);
    }

    @action
    toggleWSOAuth = (IS_WSO: boolean): void => {
        this.IS_WSO = IS_WSO;
        this.API = IS_WSO ? this.axiosApi : this.amplifyApi;
    }

    @action
    toggleAPIProxy = (HAS_API_PROXY: boolean): void => {
        this.HAS_API_PROXY = HAS_API_PROXY;
        this.API.toggleHasApiProxy(HAS_API_PROXY);
    }
}

export default AuthSettingsStore;
