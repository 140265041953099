import { FC } from 'react';

import { observer } from 'mobx-react';

import useTextOverflowing from '@/components/hooks/useTextOverflowing';
import ColoredTooltip from '@/components/Common/ColoredTooltip';

import '../index.scss';

interface UserNameViewProps {
    username: string;
    role: string;
}

const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

const UserNameView: FC<UserNameViewProps> = ({ username, role }) => {
    const [isTextOverflowing, textContainerRef] = useTextOverflowing(username);

    return (
        <div className="username-container">
            <div>
                {isTextOverflowing ? (
                    <ColoredTooltip
                        title={username}
                        placement="top"
                    >
                        <span ref={textContainerRef} className="username">
                            {username}
                        </span>
                    </ColoredTooltip>
                ) : (
                    <span className="username" ref={textContainerRef}>{username}</span>
                )}
                <span>
                    {`as ${capitalizeFirstLetter(role)}`}
                </span>
            </div>
            {/* <CopyLinkButton
                className="copy-button"
                link={link}
                isLinkLoading={isLinkLoading}
                linkKey={username}
            /> */}
        </div>
    );
};

export default observer(UserNameView);
