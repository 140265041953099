import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { JSONSerializableRecord } from '../../types/types';

/*
* Currently it transforms objects only shallowly.
* If you need deep keys transforming, just call it recursively for objects
* */

type Transform = (key: string) => string;

export const toCase = (payload: JSONSerializableRecord, transformFunc: Transform): JSONSerializableRecord => Object.entries(payload)
    .reduce<JSONSerializableRecord>((acc, [key, value]) => {
        acc[transformFunc(key)] = value;
        return acc;
    }, {});

export const toSnakeCase = (payload: JSONSerializableRecord): JSONSerializableRecord => toCase(payload, snakeCase);

export const toCamelCase = (payload: JSONSerializableRecord): JSONSerializableRecord => toCase(payload, camelCase);
