import { Policy, PolicySetting, PolicySettingsGroup } from '../../../types/types';
import { tryToggleIncompatibleBooleanSetting } from './incompatibleSettings';

const editPolicy = (
    settingId: string,
    groupType: PolicySettingsGroup,
    settingChanges: Partial<PolicySetting>,
    policy: Policy,
): Policy => {
    let newSettingsList: PolicySetting[] = [...policy.settings[groupType]];
    const settingIndex = newSettingsList.findIndex(({ id }) => id === settingId);
    newSettingsList[settingIndex] = { ...newSettingsList[settingIndex], ...settingChanges };
    if (groupType === 'access' && typeof newSettingsList[settingIndex].value === 'boolean') {
        newSettingsList = tryToggleIncompatibleBooleanSetting(settingId, newSettingsList);
    }
    return {
        ...policy,
        settings: {
            ...policy.settings,
            [groupType]: newSettingsList,
        },
    };
};

export default editPolicy;
