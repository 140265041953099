import { useEffect, useState } from 'react';

import UserStore from '../../../../stores/UserStore';

type SetInitRecipientEmail = (initRecipientEmail: string) => string;

interface RecipientEmailState {
    recpEmail: string;
    setInitRecipientEmail: SetInitRecipientEmail;
}

const useRecipientEmail = (userStore: UserStore): RecipientEmailState => {
    const [recpEmail, setRecpEmail] = useState<string>(null);

    const { currentUserEmail } = userStore;

    useEffect(() => {
        if (currentUserEmail) {
            setRecpEmail(currentUserEmail);
        }
    }, [currentUserEmail]);

    const setInitRecipientEmail = (initRecipientEmail: string): string => {
        const recipientEmail = userStore.currentUserEmail
            || initRecipientEmail
            || '';
        setRecpEmail(recipientEmail);
        return recipientEmail;
    };

    return {
        recpEmail,
        setInitRecipientEmail,
    };
};

export default useRecipientEmail;
