import React, {
    FC, memo, useState,
} from 'react';

import { useLocation } from 'react-router';
import { Button, message } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { useStores } from '../../../../../hooks';
import { captureErrorForSentry } from '../../../../../utils';
import { saveCurrentSearchParams } from '../../../helpers';
import styles from './Logout.module.scss';

const nameSpace = 'recipientPage.errors.permissions';

const Logout: FC = () => {
    const [disabled, setDisabled] = useState<boolean>(false);

    const location = useLocation();

    const { t } = useTranslation();

    const { userStore } = useStores();

    const tryLogout = async (): Promise<void> => {
        setDisabled(true);
        try {
            saveCurrentSearchParams(location.search);
            await userStore.cognitoSignOut();
            window.location.reload();
        } catch (error) {
            captureErrorForSentry(error, 'SharedFile.logout');
            console.log('could not logout', error);
            message.error(t('auth.errors.couldNotLogout'));
            // We need enable logout only if it unsuccessfully. In other case this page will be unmounted.
            setDisabled(false);
        }
    };

    return (
        <span className={styles.logout}>
            <br />
            <Trans t={t} i18nKey={`${nameSpace}.multipleAccounts`}>
                If you have multiple email accounts,
                <Button type="link" className={styles['logout-btn-secondary']} disabled={disabled} onClick={tryLogout}>
                    logout
                </Button>
                and try another one.
            </Trans>
        </span>
    );
};

export default memo(Logout);
