import React, { FC, memo } from 'react';

import UserAgent from './UserAgent';
import styles from './DeviceIdentityCell.module.scss';

interface DeviceIdentityCellProps {
    userIP: string;
    userAgentStr: string;
}

const DeviceIdentityCell: FC<DeviceIdentityCellProps> = ({ userAgentStr, userIP }) => (
    <div className={styles['cell-wrapper']}>
        {userIP && <code className={styles['ip-text']}>{userIP}</code>}
        {userAgentStr && <UserAgent userAgentStr={userAgentStr} />}
    </div>
);

export default memo(DeviceIdentityCell);
