import React, { FC, ReactElement } from 'react';

import i18n from '@/content';

import { InfoTooltip } from '../Tooltips';
import { SettingType, BasePolicySettingProps } from './interfaces';
import getSettingContent from './getSettingContent';
import styles from './PolicySetting.module.scss';

export type { PolicyRowProps } from './interfaces';

interface PolicySettingProps extends BasePolicySettingProps {
    hideDescription?: boolean;
    type: SettingType;
    children: ReactElement;
    bottomContent?: ReactElement;
}

const PolicySetting: FC<PolicySettingProps> = ({
    type,
    isChild,
    settingId,
    bottomContent,
    children,
    hideDescription = false,
}) => {
    const {
        titleKey,
        explainKey,
        wrapperClasses: className,
    } = getSettingContent(settingId, type, isChild);

    return (
        <>
            <div className={className}>
                {i18n.exists(titleKey) && !hideDescription && (
                    <span className={styles.description} data-testid="policyEditor_settingLine">
                        {i18n.t(titleKey)}
                    </span>
                )}
                {children}
                <InfoTooltip i18nKey={explainKey} />
            </div>
            {bottomContent}
        </>
    );
};

export default PolicySetting;
