import {
    action,
    computed,
    observable,
    makeObservable,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import {
    DropdownItem,
    Policy,
    PolicySetting,
    PolicySettingsGroup,
} from '../../types/types';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureErrorForSentry } from '../../components/utils';
import { PolicySimpleWithMFA } from '../UploadFilesStore/interfaces';
import { preparePolicyForEditor, editPolicy } from './helpers';

interface FetchPolicyListPayload {
    isSilently?: boolean;
}

const FETCH_POLICY_LIST_PAYLOAD_DEFAULT: FetchPolicyListPayload = { isSilently: false };

class PolicyStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    @observable hasFilePolicyError = false;

    @observable loadingPolicy = false;

    @observable policyList: Policy[] = [];

    @observable selectedPolicy: Policy = null;

    @observable changedPolicy: Policy = null;

    @computed
    get hasLoadingError(): boolean {
        return this.hasFilePolicyError;
    }

    @computed
    get hasSettingsInList(): boolean {
        const { policyList } = this;
        if (policyList.length) {
            const firstElement = policyList[0];
            return !!(firstElement.settings && firstElement.settings.access);
        }
        return false;
    }

    @computed
    get policiesListToDropdown(): DropdownItem[] {
        return this.policyList
            .map<DropdownItem<string>>(({ id, title }) => ({ value: id, label: title }))
            .sort((current, prev) => (
                current.label?.toLowerCase() > prev.label?.toLowerCase() ? 1 : -1
            ));
    }

    @computed
    get hasPolicies(): boolean {
        return !!this.policyList.length;
    }

    @computed
    get defaultPolicy(): Policy | null {
        return this.policyList.length
            ? this.policyList.find((policy) => policy.active) || this.policyList[0]
            : null;
    }

    @computed
    get defaultPolicyWithMFA(): PolicySimpleWithMFA {
        const { defaultPolicy } = this;
        return defaultPolicy && {
            id: defaultPolicy.id,
            title: defaultPolicy.title,
            isMfa: defaultPolicy.isMfa,
        };
    }

    @action
    setPolicyList = (policyList: Policy[]): void => {
        this.policyList = policyList;
    }

    @action
    setSelectedPolicy = (selectedPolicy: Policy): void => {
        this.selectedPolicy = selectedPolicy;
    }

    @action
    setPolicyForEditing = (policy: Policy): void => {
        const policyForEditor = preparePolicyForEditor(policy);
        this.selectedPolicy = { ...policyForEditor };
        this.changedPolicy = { ...policyForEditor };
    }

    selectPolicyForEditing = (policyId?: string): void => {
        const policy: Policy = policyId ? this.findPolicy(policyId) : this.defaultPolicy;
        if (policy) {
            this.setPolicyForEditing(policy);
        }
    }

    @action
    setChangedPolicy = (changedPolicy: Policy): void => {
        this.changedPolicy = changedPolicy;
    }

    @action
    setEditingPolicyName = (policyName: string): void => {
        this.changedPolicy.title = policyName;
    }

    @action
    setLoadingPolicy = (val: boolean): void => {
        this.loadingPolicy = val;
    }

    fetchPolicyList = async ({
        isSilently,
    }: FetchPolicyListPayload = FETCH_POLICY_LIST_PAYLOAD_DEFAULT): Promise<void> => {
        const { API } = this.authSettingsStore;
        let hasError = false;
        try {
            if (!isSilently) {
                this.setHasFilePolicyError(false);
                this.setLoadingPolicy(true);
            }
            const policyList: Policy[] = await API.get(BASEURL.backend(), ENDPOINTS.policies(), {});
            this.setPolicyList(policyList);
        } catch (error) {
            hasError = true;
            captureErrorForSentry(error, 'PolicyStore.fetchPolicyList');
        } finally {
            if (!isSilently) {
                this.setLoadingPolicy(false);
                this.setHasFilePolicyError(hasError);
            }
        }
    }

    removePolicyFromList = (policyId: string): void => {
        this.setPolicyList(this.policyList.filter(({ id }) => id !== policyId));
    }

    tryAddPolicyToList = (policy: Policy): void => {
        if (!this.findPolicy(policy.id)) {
            this.setPolicyList([...this.policyList, policy]);
        }
    }

    @action
    setHasFilePolicyError = (val: boolean): void => {
        this.hasFilePolicyError = val;
    }

    @action
    unmount = (): void => {
        this.selectedPolicy = null;
        this.changedPolicy = null;
        this.hasFilePolicyError = false;
        this.loadingPolicy = false;
    }

    findPolicy = (policyId: string): Policy | undefined => (
        this.policyList.find(({ id }) => id === policyId)
    )

    tryFindPolicy = async (policyId: string, forceRefresh = false): Promise<Policy | undefined> => {
        if (!forceRefresh) {
            const cachedPolicy = this.findPolicy(policyId);
            if (cachedPolicy) {
                return cachedPolicy;
            }
        }
        await this.fetchPolicyList({ isSilently: true });
        return this.findPolicy(policyId);
    }

    editPolicy = (settingId: string, groupType: PolicySettingsGroup, settingChanges: Partial<PolicySetting>): void => {
        const newState: Policy = editPolicy(settingId, groupType, settingChanges, this.changedPolicy);
        this.setChangedPolicy(newState);
    }
}

export default PolicyStore;
