import React, {
    FC, useCallback, ReactElement,
} from 'react';

import { SendOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import FileActionsDropdown, { MenuClickCallback } from '../FileActionsDropdown';
import { useMenu } from '../../../FileAction/hooks';
import { FileMenu } from '@/types/types';
import { FileActionType } from '../../../FileAction/menuItems';
import { FilesListType } from '../../../../../stores';
import styles from './Actions.module.scss';
import commonStyle from '../../../../Common/Typography/Typography.module.scss';

export interface ActionsProps {
    fileId: string;
    menu: Partial<FileMenu>;
    handleMenuClick: (action: FileActionType, fid: string) => void;
    filesListType?: FilesListType;
    openIcon?: ReactElement;
}

const Actions: FC<ActionsProps> = observer(({
    fileId,
    menu,
    filesListType,
    handleMenuClick,
    openIcon = null,
}) => {
    const {
        openWith,
        options,
        hasShare,
    } = useMenu(menu, filesListType);

    const onFileActionClick = useCallback<MenuClickCallback>((event) => {
        handleMenuClick(event.key as FileActionType, fileId);
    }, [fileId]);

    return (
        <div className={styles['files-action']}>
            {openWith.length > 0
            && (
                <FileActionsDropdown
                    buttonClass={styles['open-grid-area']}
                    buttonTitle="open"
                    options={openWith}
                    handleMenuClick={onFileActionClick}
                />
            )}
            {hasShare
                && (
                    <button
                        type="button"
                        className={classNames(
                            commonStyle['blue-half'],
                            styles['share-grid-area'],
                            styles['send-icon-color'],
                            styles['linked-button'],
                            styles['icon-button'],
                        )}
                        onClick={() => handleMenuClick('share', fileId)}
                        data-clickable
                    >
                        <SendOutlined data-testid="myFiles_WhoHasAccess" />
                    </button>
                )}
            {options.length > 0 && (
                <FileActionsDropdown
                    buttonClass={styles['more-grid-area']}
                    buttonTitle="more"
                    options={options}
                    handleMenuClick={onFileActionClick}
                    openIcon={openIcon}
                />
            )}
        </div>
    );
});

export default Actions;

export { items } from '../../../FileAction/menuItems';
