import {
    Dispatch, MutableRefObject, SetStateAction,
    useRef, useState,
} from 'react';

const useStateWithRef = <T>(initial: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] => {
    const [state, setState] = useState<T>(initial);

    const ref = useRef<T>(initial);
    ref.current = state;

    const setStateWithRef = (payload: SetStateAction<T>): void => {
        setState(payload);
        if (typeof payload !== 'function') {
            ref.current = payload;
        }
    };

    return [state, setStateWithRef, ref];
};

export default useStateWithRef;
