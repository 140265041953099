import React, { FC, memo } from 'react';

import dayjs from 'dayjs';

import { MILLISECONDS_IN_SECOND } from '@/consts';

interface CreationDateProps {
    createDate: number;
}

const CreationDate: FC<CreationDateProps> = ({ createDate }) => (
    <span style={{ whiteSpace: 'nowrap' }}>
        {dayjs(new Date(parseInt(String(createDate), 10) * MILLISECONDS_IN_SECOND)).format('DD MMM YYYY')}
    </span>
);

export default memo(CreationDate);
