import React, { FC } from 'react';

import { Breadcrumb } from 'antd';
import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';
import { FileToDisplay } from '@/stores';
import i18n from '@/content';
import { ROOT_FOLDER } from '@/consts';

import { BreadcrumbToDisplay } from '../../../../MyFiles/MyFilesHeader/FolderBreadcrumbs/interfaces';
import styles from './FileBreadcrumbs.module.scss';

const nameSpace = 'myFiles.header';

const createBreadcrumbsToDisplay = (
    rootTitle: string,
    foldersChain: FileToDisplay[],
    fileName: string,
    fileId: string,
): BreadcrumbToDisplay[] => {
    const root: BreadcrumbToDisplay = {
        name: rootTitle,
        itemKey: ROOT_FOLDER,
    };
    const file: BreadcrumbToDisplay = {
        name: fileName,
        itemKey: fileId,
    };
    const restBreadcrumbs = foldersChain.map<BreadcrumbToDisplay>(
        ({ filename, itemKey }) => ({ name: filename, itemKey }),
    );
    return [root, ...restBreadcrumbs, file];
};

interface FileBreadcrumbsProps {
  fileName: string;
  fileId: string;
}

const FileBreadcrumbs: FC<FileBreadcrumbsProps> = observer(({ fileName, fileId }) => {
    const {
        filesListStore:
            {
                currentFolderBreadcrumbs,
                displayFilesType,
            },
    } = useStores();

    const title = i18n.t(`${nameSpace}.title.${displayFilesType}`);
    const breadcrumbsToDisplay = createBreadcrumbsToDisplay(title, currentFolderBreadcrumbs, fileName, fileId);

    return (
        <div className={styles['breadcrumbs-wrapper']}>
            {currentFolderBreadcrumbs.length > 0
                ? (
                    <Breadcrumb
                        className={styles.breadcrumbs}
                        separator={<span className={styles.item}>/</span>}
                    >
                        {breadcrumbsToDisplay.map(({ itemKey, name }) => (
                            <Breadcrumb.Item key={itemKey}>
                                <span className={styles.item}>{ name }</span>
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                )
                : (
                    <span>
                        {fileName}
                    </span>
                )}
        </div>
    );
});

export default FileBreadcrumbs;
