class MultipartProgressManager {
    private chunksProgress: number[];

    private alreadyUploaded: number;

    constructor(chunksNumber: number, initValue = 0) {
        this.chunksProgress = new Array(chunksNumber).fill(0);
        this.alreadyUploaded = initValue;
    }

    private calculateChunksProgressSum(): number {
        return this.chunksProgress.reduce((prev, current) => prev + current, 0);
    }

    get totalProgress(): number {
        return this.alreadyUploaded + this.calculateChunksProgressSum();
    }

    updateProgress(chunkIndex: number, newValue: number): void {
        this.chunksProgress[chunkIndex] = newValue;
    }

    onChunksLoaded(): void {
        this.alreadyUploaded += this.calculateChunksProgressSum();
        this.chunksProgress = this.chunksProgress.fill(0);
    }
}

export default MultipartProgressManager;
