import React, {
    memo, useCallback, useState, FC, useEffect, useMemo,
} from 'react';

import { useTranslation } from 'react-i18next';

import { ConnectExternalStorageProps, BoxOauthButton } from '../../Common/ConnectExternalStorageButton';
import QuestionTooltip from '../../Common/QuestionTooltip';
import ConnectLogo from './ConnectLogo';
import ConnectBoxButton from './ConnectBoxButton';
import { useMounted } from '../../hooks';
import { captureErrorForSentry } from '../../utils';
import styles from './ConnectBoxAccountBanner.module.scss';

interface ConnectBoxAccountBannerProps extends Required<
    Pick<ConnectExternalStorageProps, 'responseSuccess' | 'clientId' | 'onClick'>
    > {
    errorDescription?: string;
}

const ConnectBoxAccountBanner: FC<ConnectBoxAccountBannerProps> = ({
    responseSuccess, clientId, errorDescription, onClick: propOnClick,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const isMountedRef = useMounted();

    useEffect(() => {
        if (errorDescription) {
            setHasError(true);
        }
    }, [errorDescription]);

    const onClick = useCallback(() => {
        setHasError(false);
        setIsLoading(true);
        propOnClick();
    }, []);

    const responseFail = useCallback((error) => {
        setIsLoading(false);
        setHasError(true);
        captureErrorForSentry(error, 'ConnectBoxAccountBanner.responseFail');
        console.log('Box account connect fail', error);
    }, []);

    const onSuccess = useCallback(async (response) => {
        await responseSuccess(response);
        if (isMountedRef.current) {
            setIsLoading(false);
        }
    }, []);

    const { t } = useTranslation();

    const defaultErrorMessage = useMemo(() => t('externalStorage.couldNotConnectAccount', { providerName: 'Box' }), []);

    return (
        <div className={styles.wrapper}>
            <ConnectLogo />
            <p className={styles.title}>
                {t('externalStorage.accountNotConnected', { providerName: 'Box' })}
            </p>
            <div className={styles.footer}>
                {hasError && (
                    <div className={styles['error-wrapper']}>
                        {errorDescription
                            && errorDescription !== defaultErrorMessage
                            && <QuestionTooltip>{errorDescription}</QuestionTooltip>}
                        <span className={styles.error}>
                            {defaultErrorMessage}
                        </span>
                    </div>
                )}
                <BoxOauthButton
                    clientId={clientId}
                    responseSuccess={onSuccess}
                    responseFail={responseFail}
                    render={({ oauthClickHandler }) => (
                        <ConnectBoxButton
                            oauthClickHandler={oauthClickHandler}
                            onClick={onClick}
                            isLoading={isLoading}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default memo(ConnectBoxAccountBanner);
