import { useRef } from 'react';
import { SimpleCallback } from '../../../../types/types';

export type LifeCycleStage = 'init' | 'installed' | 'rendered';

const usePSPDFKitLifeCycle = (): (newStage: LifeCycleStage, effect: SimpleCallback) => void => {
    const lifeCycleStageRef = useRef<LifeCycleStage>('init');

    return (newStage: LifeCycleStage, effect: SimpleCallback): void => {
        if (newStage !== lifeCycleStageRef.current) {
            lifeCycleStageRef.current = newStage;
            effect();
        }
    };
};

export default usePSPDFKitLifeCycle;
