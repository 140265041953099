import React, { FC, memo, useMemo } from 'react';

import ElasticDescription, { ElasticDescriptionUIProps } from '../ElasticDescription';
import { splitFilename } from '../../utils';

interface ElasticFileNameProps extends ElasticDescriptionUIProps {
    filename: string;
}

const ElasticFileName: FC<ElasticFileNameProps> = ({ filename, ...rest }) => {
    const { name, extension } = useMemo(() => splitFilename(filename, true), [filename]);

    return (
        <ElasticDescription
            fullDescription={filename}
            ellipsisPart={name}
            nowrapPart={extension}
            {...rest}
        />
    );
};

export default memo(ElasticFileName);
