import React, {
    FC,
    memo,
    useEffect,
    useState,
} from 'react';

import i18n from '@/content';
import { DeleteModal, PolicyModal } from '../FilesTable/Modals';
import { useMounted, useStores } from '../hooks';
import { ModalContentProps } from '../Common/Modal';
import { SecuredFile, SimpleCallback } from '../../types/types';
import { FileActionType } from '../FilesTable/FileAction/menuItems';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureNon404Error, checkIs404 } from '../utils';

const nameSpace = 'auditLogs.actionsModal';

const ALLOWED_ACTIONS: FileActionType[] = ['delete_file', 'set_file_policy'];

interface ActionsModalProps extends ModalContentProps {
    actionType: FileActionType;
    fileId: string;
    onClose: SimpleCallback;
}

const ActionsModalContent: FC<ActionsModalProps> = ({
    actionType,
    fileId,
    onClose,
    setLoadingStatus,
    setErrorStatus,
}) => {
    const [fileDetails, setFileDetails] = useState<SecuredFile>(null);

    const isMountedRef = useMounted();

    const { authSettingsStore: { API } } = useStores();

    useEffect(() => {
        if (!ALLOWED_ACTIONS.includes(actionType)) {
            setErrorStatus(true, i18n.t(`${nameSpace}.unsupportedAction`, { actionType }));
        } else {
            const loadData = async (): Promise<void> => {
                try {
                    setLoadingStatus(true, i18n.t(`${nameSpace}.loadFile`));
                    const file: SecuredFile = await API.get(BASEURL.backend(), ENDPOINTS.getFileDetails(fileId), {});
                    if (isMountedRef.current) {
                        setLoadingStatus(false);
                        setFileDetails(file);
                    }
                } catch (error) {
                    captureNon404Error(error, 'ActionsModal.useEffect');
                    if (isMountedRef.current) {
                        const messageKey = checkIs404(error)
                            ? `${nameSpace}.fileWasDeleted`
                            : `${nameSpace}.couldNotLoadFileDetails`;
                        setErrorStatus(true, i18n.t(messageKey));
                    }
                }
            };
            loadData();
        }
    }, []);

    return (
        <>
            {fileDetails && (
                actionType === 'delete_file'
                    ? (
                        <DeleteModal
                            fileSelected={fileDetails}
                            closeModal={onClose}
                            setLoadingStatus={setLoadingStatus}
                            setErrorStatus={setErrorStatus}
                        />
                    )
                    : (
                        <PolicyModal
                            closeModal={onClose}
                            file={fileDetails}
                            setLoadingStatus={setLoadingStatus}
                            setErrorStatus={setErrorStatus}
                        />
                    )
            )}
        </>
    );
};

export default memo(ActionsModalContent);
