export interface UserEmail {
    username: string;
    domain: string;
}

const splitEmail = (email: string, withAt = true): UserEmail => {
    const [username, domain] = email.split('@');
    const domainPrefix = withAt ? '@' : '';
    return { username: username || '', domain: `${domainPrefix}${domain || ''}` };
};

export default splitEmail;
