import React, { FC, memo, useCallback } from 'react';

import OAuth2Login from 'react-simple-oauth2-login';

import ConnectButton from '../ConnectButton';
import { SimpleCallback } from '../../../../types/types';
import { CROSS_ORIGIN_OAUTH } from '../../../../config/appRoutes';
import { ConnectExternalStorageProps } from '../interfaces';

interface BoxOauthButtonProps extends ConnectExternalStorageProps {
    render?: ({ oauthClickHandler }: { oauthClickHandler: SimpleCallback }) => JSX.Element;
}

const BoxOauthButton: FC<BoxOauthButtonProps> = ({
    clientId, responseSuccess, responseFail, onClick, isLoading, render,
}) => {
    const onSuccess = useCallback(({ code }) => {
        try {
            if (!code) {
                throw new Error('No Oauth code param into response');
            }
            responseSuccess({ code });
        } catch (error) {
            responseFail(error as Error);
        }
    }, [responseSuccess, responseFail]);

    const onFailure = useCallback((error) => {
        responseFail(error);
    }, [responseFail]);

    return (
        <OAuth2Login
            isCrossOrigin
            authorizationUrl="https://account.box.com/api/oauth2/authorize"
            responseType="code"
            clientId={clientId}
            redirectUri={`${window.location.origin}${CROSS_ORIGIN_OAUTH}`}
            onSuccess={onSuccess}
            onFailure={onFailure}
            render={({ onClick: oauthClickHandler }) => {
                if (render) {
                    return render({ oauthClickHandler });
                }
                return (
                    <ConnectButton
                        onClick={onClick}
                        oauthClickHandler={oauthClickHandler}
                        storageProvider="box"
                        isLoading={isLoading}
                    />
                );
            }}
        />
    );
};

export default memo(BoxOauthButton);
