import { BASEURL, ENDPOINTS } from '../endpoints';
import { SPXAPI } from '../interfaces';

/* eslint-disable camelcase */
export interface OverwriteFileResponse {
    url: string;
    file_id: string;
}
/* eslint-enable camelcase */

export const initiateOverwrite = async (API: SPXAPI, fileId: string): Promise<OverwriteFileResponse> => API.put(
    BASEURL.backend(),
    ENDPOINTS.overwriteFile(fileId),
    { body: { lock_signatures: true }},
);
