import React, { FC, memo } from 'react';

import style from './ResultTitle.module.scss';

interface ResultTitleProps {
    children: string;
}

const ResultTitle: FC<ResultTitleProps> = ({ children }) => (
    <h3 className={style.title}>
        {children}
    </h3>
);

export default memo(ResultTitle);
