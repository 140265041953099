import { RecipientPhoneInfo } from '@/types/types';

// eslint-disable-next-line import/prefer-default-export
export const INIT_PHONE_INFO: RecipientPhoneInfo = {
    phonesVector: {
        currentPhone: null,
        initSnapshot: null,
    },
    isSearching: true,
};
