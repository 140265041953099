export const COLORS: string[] = [
    '#021138',
    '#0C3D80',
    '#38FFF0',
    '#5E8C61',
    '#4E6151',
    '#285943',
    '#006C67',
    '#888098',
    '#AAD922',
];

export const RECORDS_LIMIT = 10;

export const OTHER_I18N_KEY = 'domainsDashboard.otherDomains';
