import { createContext, useContext } from 'react';
import { AccessActionHandler } from './useVerifyAction/interfaces';

interface FilesActionsContextType {
  selectFile: (fileId: string) => void;
  actionHandler: AccessActionHandler;
  setSelectedFileVerifyAccessAction: (fileId: string, isFolder: boolean) => void;
}

export const FilesActionsContext = createContext<FilesActionsContextType | null>(null);

export const useFilesActionsContext = (): FilesActionsContextType => {
    const context = useContext<FilesActionsContextType>(FilesActionsContext);
    if (!context) {
        throw new Error('useFilesActionsContext must be used within a FilesActionsProvider');
    }

    return context;
};
