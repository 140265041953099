import React, { FC, memo } from 'react';

import { Button } from 'antd';

import i18n from '@/content';

import nameSpace from '../nameSpace';
import styles from '../AuditsFilters.module.scss';

interface ClearFiltersProps {
    onClick: () => void;
}

const ClearFilters: FC<ClearFiltersProps> = ({ onClick }) => (
    <Button
        onClick={onClick}
        className={styles['clear-filters']}
        size="small"
        type="link"
    >
        {i18n.t(`${nameSpace}.clearFilters`)}
    </Button>
);

export default memo(ClearFilters);
