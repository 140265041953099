import React, { FC, memo } from 'react';

import Actions, { ActionsProps } from './Actions';
import ActionsSkeleton from './ActionsSkeleton';

const ActionsWithSkeleton: FC<ActionsProps> = ({ menu, ...rest }) => (
    menu
        ? <Actions menu={menu} {...rest} />
        : <ActionsSkeleton />
);

export default memo(ActionsWithSkeleton);
