import React, { FC } from 'react';

import { observer } from 'mobx-react';

import RefreshButton from '../MyFilesHeader/RefreshButton';
import SearchFiles from '../MyFilesHeader/SearchFiles';
import SearchOwner from '../MyFilesHeader/SearchOwner';
import { useStores } from '../../hooks';
import styles from './HeaderPortalContent.module.scss';

const HeaderPortalContent: FC = observer(() => {
    const { filesListStore: { displayFilesType } } = useStores();

    return (
        <div className={styles.container}>
            <RefreshButton />
            <SearchFiles />
            {displayFilesType === 'allFiles' && <SearchOwner />}
        </div>
    );
});

export default HeaderPortalContent;
