import { AxiosError } from 'axios';

import type { SPXAPI } from '../interfaces';
import { BASEURL, ENDPOINTS } from '../endpoints';
import { checkIsSpecificCode, PermissionsError, PermissionsCheckFail } from '../../components/utils';

export interface AddPermissionsParams {
    fileId: string;
    linkId: string;
    email: string;
}

const RESPONSE_ORIGIN_HEADER = 'specterx-response-origin';
const RESPONSE_ORIGIN_HEADER_VALUE_LOWER = 'specterx';

const checkIsAccessDenied = (error: unknown): boolean => {
    const { response } = error as AxiosError;
    return (
        checkIsSpecificCode(error, 403)
        && response?.headers?.[RESPONSE_ORIGIN_HEADER]?.toLowerCase() === RESPONSE_ORIGIN_HEADER_VALUE_LOWER
    );
};

const addPermissionsToFile = async (
    API: SPXAPI,
    {
        fileId,
        email,
        linkId,
    }: AddPermissionsParams,
    captureOriginalError?: (error: unknown) => void,
): Promise<void> => {
    try {
        await API.put(BASEURL.backend(), ENDPOINTS.shareFile(fileId), {
            body: {
                email,
                link_id: linkId,
            },
        });
    } catch (error) {
        captureOriginalError?.(error);
        if (checkIsAccessDenied(error)) {
            throw new PermissionsError(`User doesn't have access to file ${fileId}`);
        } else {
            throw new PermissionsCheckFail(`SharedFile flow: permissions check failed for file ${fileId}`);
        }
    }
};

export default addPermissionsToFile;
