import type { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../../../hooks';

import styles from './DefaultMessage.module.scss';

const NAME_SPACE = 'recipientPage.message.default';

const DefaultMessage: FC = observer(() => {
    const { t } = useTranslation();
    const { recipientPageStore: { sharedFilesStore: { allFilesCount } } } = useStores();

    return (
        <div className={styles['default-message']} data-testid="recipientPage_messageBody">
            {t(`${NAME_SPACE}.attached`, { count: allFilesCount })}
        </div>
    );
});

export default DefaultMessage;
