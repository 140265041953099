import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal, { ModalHeader, ModalFooter } from '@/components/Common/Modal';
import type { SyncCallback } from '@/types/types';

import styles from './UploadDisclaimer.module.scss';

const nameSpace = 'uploadFiles.disclaimer';

interface UploadDisclaimerProps {
    close: SyncCallback;
    isOpen: boolean;
}

const UploadDisclaimer: FC<UploadDisclaimerProps> = ({ close, isOpen }) => {
    const { t } = useTranslation();

    return (
        <Modal
            closeModal={close}
            closable={false}
            isOpen={isOpen}
        >
            <>
                <ModalHeader>
                    <div className={styles.header}>
                        {t(`${nameSpace}.title`)}
                    </div>
                </ModalHeader>
                <div className={styles.main}>
                    {t(`${nameSpace}.main`)}
                </div>
                <ModalFooter>
                    <div className={styles.footer}>
                        <Button className={styles.submit} onClick={close}>
                            {t('general.buttons.ok')}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
};

export default memo(UploadDisclaimer);
