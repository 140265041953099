import AuthSettingsStore from '../AuthSettingsStore';
import CancellableAPI from '../../api/CancellableAPI';
import { SecuredFile } from '../../types/types';
import { FilesListType, LoadParams, FilesRequestResult } from './interfaces';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureErrorForSentry, retryRequest } from '../../components/utils';
import { getFilesQuerystring, loadParamsToQueryParams } from './helpers';

type LoadState = 'pending' | 'fulfilled' | 'rejected';

type OnCompleteSubscriber = (filesList: SecuredFile[], folderId: string) => void;

type SubscribersDict = Record<FilesListType, OnCompleteSubscriber>;

const FULL_LIST_RETRIES = 3;

const createRequestKeyCore = (filesListType: FilesListType, isPartial: boolean): string => (
    `__${filesListType}_${isPartial ? 'partial' : 'full'}__`
);

const createRequestKey = (filesListType: FilesListType, query: string, isPartial: boolean): string => (
    `${createRequestKeyCore(filesListType, isPartial)}${query}`
);

const ALL_FILES_TYPES: FilesListType[] = [
    'myFiles',
    'allFiles',
    'sharedFiles',
];

class FilesListLoader {
    private readonly APIInstance: CancellableAPI;

    private readonly subscribersDict: SubscribersDict = {
        myFiles: null,
        sharedFiles: null,
        allFiles: null,
    }

    constructor(authSettingsStore: AuthSettingsStore) {
        this.APIInstance = new CancellableAPI(authSettingsStore, { failSilently: false });
    }

    loadFiles(params: LoadParams): Promise<FilesRequestResult> {
        return new Promise<FilesRequestResult>((resolve, reject) => {
            const query: string = getFilesQuerystring(loadParamsToQueryParams(params));
            const { filesListType } = params;
            let partialFilesListState: LoadState = 'pending';
            let fullFilesListState: LoadState = 'pending';
            this.requestPartialFiles(filesListType, query).then((result) => {
                partialFilesListState = 'fulfilled';
                resolve(result);
            }).catch((error) => {
                partialFilesListState = 'rejected';
                if (fullFilesListState !== 'pending') {
                    reject(error);
                }
            });
            this.requestFullFiles(filesListType, query).then((result) => {
                fullFilesListState = 'fulfilled';
                if (partialFilesListState !== 'fulfilled') {
                    resolve(result);
                } else {
                    this.subscribersDict[filesListType]?.(result.items, params.folderKey);
                }
            }).catch((error) => {
                fullFilesListState = 'rejected';
                if (partialFilesListState === 'rejected') {
                    reject(error);
                } else if (!this.APIInstance.checkIsCancel(error)) {
                    console.log('onFilesCompleteError', error);
                    captureErrorForSentry(error as Error, 'FilesListStore.onFilesCompleteError');
                }
            });
        });
    }

    subscribeOnComplete(filesListType: FilesListType, subscriber: OnCompleteSubscriber): void {
        this.subscribersDict[filesListType] = subscriber;
    }

    cancelTargetRequests(filesListType: FilesListType, partialQuery: string): void {
        this.APIInstance.cancelByPartialKey(createRequestKey(filesListType, partialQuery, true));
        this.APIInstance.cancelByPartialKey(createRequestKey(filesListType, partialQuery, false));
    }

    cancelPack(filesListType: FilesListType, isPartial: boolean): void {
        this.APIInstance.cancelByPartialKey(createRequestKeyCore(filesListType, isPartial));
    }

    cancelBothPacks(filesListType: FilesListType): void {
        this.cancelPack(filesListType, true);
        this.cancelPack(filesListType, false);
    }

    cancelAllPacks(): void {
        ALL_FILES_TYPES.forEach((filesListType) => {
            this.cancelBothPacks(filesListType);
        });
    }

    private async requestFullFiles(filesListType: FilesListType, query: string): Promise<FilesRequestResult> {
        return retryRequest<FilesRequestResult>(
            async () => this.requestFiles(query, createRequestKey(filesListType, query, false)),
            (error) => !this.APIInstance.checkIsCancel(error),
            FULL_LIST_RETRIES,
            false,
        );
    }

    private async requestPartialFiles(filesListType: FilesListType, query: string): Promise<FilesRequestResult> {
        return this.requestFiles(
            `${query}&partial=true`,
            createRequestKey(filesListType, query, true),
        );
    }

    private async requestFiles(query: string, requestKey: string): Promise<FilesRequestResult> {
        const { result } = await this.APIInstance.get<FilesRequestResult>(
            BASEURL.backend(),
            ENDPOINTS.getFiles(query),
            requestKey,
        );
        return result;
    }
}

export default FilesListLoader;
