import React, { ReactElement, ComponentType } from 'react';

import {
    SafetyCertificateOutlined,
    DownloadOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    AuditOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';

import GoogleSvg from './GoogleSvg';
import Office365Svg from './Office365Svg';
import ViewerSvg from './ViewerSvg';
import { OpenOptions } from '../../../config/openOptions';
import { FileOptionType } from '../../../types/types';

export type FileActionType = FileOptionType
    | OpenOptions
    | 'share'
    | 'manage_access';

export interface FileMenuItem {
    title: string;
    icon?: ReactElement;
    component?: ComponentType;
}

export const items: Partial<Record<FileActionType, FileMenuItem>> = {
    set_policy: {
        title: 'securitySettings',
        icon: <SafetyCertificateOutlined />,
    },
    set_file_policy: {
        title: 'setFilePolicy',
        icon: <SafetyCertificateOutlined />,
    },
    download: {
        title: 'download',
        icon: <DownloadOutlined />,
    },
    delete: {
        title: 'delete',
        icon: <DeleteOutlined />,
    },
    delete_file: {
        title: 'deleteFile',
        icon: <DeleteOutlined />,
    },
    webdav: {
        title: 'officeForDesktop',
        component: ViewerSvg,
    },
    googledrive: {
        title: 'googleDocksViewingOnly',
        component: GoogleSvg,
    },
    office365: {
        title: 'office365',
        component: Office365Svg,
    },
    viewer: {
        title: 'viewer',
        component: ViewerSvg,
    },
    revoke: {
        title: 'revoke',
        icon: <FileExcelOutlined />,
    },
    audits: {
        title: 'audits',
        icon: <AuditOutlined />,
    },
    share: {
        title: 'share',
        icon: <ShareAltOutlined />,
    },
};
