import React, { memo, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import ElasticFileName from '../ElasticFileName';
import { FileSummary } from '../../../types/types';
import styles from './SimpleFilesList.module.scss';

const nameSpace = 'externalStorage.simpleFilesList';

/* eslint-disable react/require-default-props */
interface FilesListWithLinksProps<File extends FileSummary> {
    filesList: File[];
    customTitleKey?: string;
}

const SimpleFilesList = <File extends FileSummary>({
    filesList, customTitleKey,
}: FilesListWithLinksProps<File>): ReactElement<FilesListWithLinksProps<File>> => {
    const { t } = useTranslation();

    return (
        <div className={styles['files-list-wrapper']}>
            <strong className={styles.title}>
                {t(customTitleKey || `${nameSpace}.title`)}
            </strong>
            <div>
                {filesList.map(({ filename, fid }) => (
                    <div key={fid} className={styles['list-item']}>
                        <ElasticFileName filename={filename || fid} textClassname={styles.filename} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(SimpleFilesList);
