import type { RcFile } from 'antd/lib/upload/interface';
import type { AxiosRequestConfig, AxiosProgressEvent } from 'axios';

import CancellableAPI from '@/api/CancellableAPI';
import {
    BASEURL,
    ENDPOINTS,
    getFileMetadataCancellable,
    UploadAxiosInstance,
} from '@/api';
import { AWSRegions } from '@/types/types';
import { sleep } from '@/components/utils';
import { FULL_PERCENTS } from '@/consts';

import FileInfectedError from '../FileInfectedError';
import {
    SinglePartUploadInfo,
    SinglePartUploadRequestBody,
    UploadProgressControls,
    SinglePartMessageUploadRequestBody,
} from '../interfaces';
import { uploadIdToRequestKey } from './common';

const POLLING_INITIAL_INTERVAL_MS = 3_000;
const POLLING_COUNTER_INTERVAL_MS = 5_000;

function generateProtectedMessageFilename(): string {
    // Generate a timestamp (current time in milliseconds since epoch)
    const timestamp: number = Date.now();
    // Construct the filename using the timestamp
    const filename: string = `protected_message_${timestamp}.eml`;
    return filename;
}

export const createMessageMetadata = async (
    API: CancellableAPI,
): Promise<SinglePartUploadInfo> => {
    const messageFileName = generateProtectedMessageFilename();
    const requestBody: SinglePartMessageUploadRequestBody = {
        filename: messageFileName,
        type: 'message',
        regions: [AWSRegions.euCentral],
    };
    const { result } = await API.post<SinglePartUploadInfo>(
        BASEURL.backend(),
        ENDPOINTS.uploadFile(),
        uploadIdToRequestKey(messageFileName),
        {
            body: requestBody,
        },
    );
    return { ...result, fileName: messageFileName };
};

export const uploadMessage = async (
    messageContent: ArrayBuffer,
    fileName: string,
    url: string,
    uploadInstance: UploadAxiosInstance,
    fid: string,
    isOnprem: boolean,
): Promise<void> => {
    const int8View = new Int8Array(messageContent as ArrayBuffer);

    if (isOnprem) {
        const file = new Blob([messageContent]);
        const fileToFlask = new FormData();
        fileToFlask.append('file', file);
        fileToFlask.append('filename', fileName);
        await uploadInstance.post(url, fileToFlask, fid);
    } else {
        const key = String(Date.now());
        await uploadInstance.put(url, int8View, key);
    }
};

export const readMessage = (message: string): Promise<ArrayBuffer> => {
    const fileReader = new FileReader();
    const messageBlob = new Blob([message], { type: 'plain/text' });
    return new Promise<ArrayBuffer>((resolve, reject) => {
        fileReader.onload = (event) => resolve(event.target.result as ArrayBuffer);
        fileReader.onerror = () => {
            console.log('could not read file', fileReader.error);
            reject(fileReader.error);
        };
        fileReader.readAsArrayBuffer(messageBlob);
    });
};

export const createSinglePartFileMetadata = async (
    file: RcFile,
    parentFolder: string,
    hasNASStorage: boolean,
    API: CancellableAPI,
): Promise<SinglePartUploadInfo> => {
    const { name, size, uid } = file;
    const requestBody: SinglePartUploadRequestBody = {
        filename: name,
        fileSize: size,
        parent_folder: parentFolder || null,
        regions: [AWSRegions.euCentral],
    };
    if (hasNASStorage) {
        requestBody.storage = 'onprem';
    }

    const { result } = await API.post<SinglePartUploadInfo>(
        BASEURL.backend(),
        ENDPOINTS.uploadFile(),
        uploadIdToRequestKey(uid),
        { body: requestBody },
    );
    return result;
};

export const uploadSinglePartFile = async (
    fileContent: ArrayBuffer,
    url: string,
    { onProgress, file }: UploadProgressControls,
    isOnprem: boolean,
    uploadInstance: UploadAxiosInstance,
): Promise<void> => {
    const requestConfig: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const percentCompleted = Math.floor((progressEvent.loaded * FULL_PERCENTS) / progressEvent.total);
            onProgress({ percent: percentCompleted });
        },
    };

    if (isOnprem) {
        const fileToFlask = new FormData();
        fileToFlask.append('file', file);
        fileToFlask.append('filename', file.name);
        await uploadInstance.post(url, fileToFlask, file.uid, requestConfig);
    } else {
        const int8View = new Int8Array(fileContent as ArrayBuffer);
        await uploadInstance.put(url, int8View, file.uid, requestConfig);
    }
};

export const readSinglePartFile = (file: RcFile): Promise<ArrayBuffer> => {
    const fileReader = new FileReader();

    return new Promise<ArrayBuffer>((resolve, reject) => {
        fileReader.onload = (event) => resolve(event.target.result as ArrayBuffer);

        fileReader.onerror = () => {
            console.log('could not read file', fileReader.error);
            reject(fileReader.error);
        };

        fileReader.readAsArrayBuffer(file);
    });
};

const MAX_WAIT_ATTEMPTS = 20;

export const waitForScanning = async (API: CancellableAPI, fileId: string, uid: string): Promise<boolean> => {
    const passStatus = ['ready_for_open', 'ready_for_download', 'scan_accepted'];
    const failStatus = ['scan_blocked', 'error'];

    await sleep(POLLING_INITIAL_INTERVAL_MS);
    /* eslint-disable no-await-in-loop */
    for (let counter = 0; counter < MAX_WAIT_ATTEMPTS; counter++) {
        const result = await getFileMetadataCancellable(API, fileId, uid);

        if (passStatus.includes(result.file_status)) {
            return true;
        } else if (failStatus.includes(result.file_status)) {
            throw new FileInfectedError(`File ${fileId} is infected`);
        }

        await sleep(POLLING_COUNTER_INTERVAL_MS);
    }

    return false;
};
