import { FileMetaLegacy } from '../../../../types/types';

const FILE_METADATA_REQUIRED_KEYS: Array<keyof FileMetaLegacy> = [
    'filename',
    'is_folder',
    'policy_settings',
    'access',
    'owner_email',
];

const checkIsFileMetaCompleted = (fileMeta: FileMetaLegacy): boolean => {
    if (!fileMeta) {
        return false;
    }
    return FILE_METADATA_REQUIRED_KEYS.every((key) => {
        const value = fileMeta?.[key];
        return typeof value === 'boolean' || !!value;
    });
};

export default checkIsFileMetaCompleted;
