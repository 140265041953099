import { useRef, useState } from 'react';

import { SharedFilesStore, AccessType, checkIsPermissionsError } from '@/stores';
import { CheckCodeError } from '@/components/Common/CodeAuthenticator';
import { SPXAPI, verifyAccess } from '@/api';
import { AsyncCallback } from '@/types/types';
import { checkIsMFAFailedError } from '@/components/utils';

import { VerifyPromise, SetVerifyAction } from './interfaces';

const handleError = (error: unknown): void => {
    if (checkIsMFAFailedError(error)) {
        throw new CheckCodeError('Code check failed');
    } else {
        throw error;
    }
};

const processVerify = async (
    sharedFilesStore: SharedFilesStore,
    verify: AsyncCallback<void>,
    fileId?: string,
): Promise<void> => {
    // TODO: provide here more smart check
    if (!sharedFilesStore.filesPermissions.size) {
        await sharedFilesStore.tryBatchAddPermissions({ isSilent: true });
    }
    if (fileId) {
        const filePermissions = sharedFilesStore.filesPermissions.get(fileId);
        if (checkIsPermissionsError(filePermissions?.summary)) {
            throw Error(`Abort MFA flow: file ${fileId} don't have permission`);
        }
    }
    await verify();
};

export interface UseVerifyActionResult {
    readonly verifyAccess: VerifyPromise;
    readonly postAuthAction: AsyncCallback;
    readonly setAction: SetVerifyAction;
    readonly lastAccessType: AccessType,
}

const useVerifyAction = (
    sharedFilesStore: SharedFilesStore,
    API: SPXAPI,
): UseVerifyActionResult => {
    const [lastAccessType, setLastAccessType] = useState<AccessType>(null);

    const actionRef = useRef<VerifyPromise>(null);
    const fileIdRef = useRef<string>(null);

    const verifyAccessDefault = async (code: string): Promise<void> => {
        try {
            // We could select just first file because MFA works for whole session
            const fileId = fileIdRef.current || sharedFilesStore.filesList[0]?.file_id;
            await processVerify(
                sharedFilesStore,
                async () => verifyAccess(API, fileId, code),
                fileId,
            );
        } catch (error) {
            handleError(error);
        }
    };

    const setAction: SetVerifyAction = (action, accessType: AccessType, fileId) => {
        fileIdRef.current = fileId;
        setLastAccessType(accessType);
        if (action) {
            actionRef.current = async (code) => {
                try {
                    await processVerify(
                        sharedFilesStore,
                        async () => action(code),
                        fileId,
                    );
                } catch (error) {
                    handleError(error);
                }
            };
        } else {
            actionRef.current = null;
        }
    };

    const verifyAccessAction = async (code?: string): Promise<void> => {
        if (actionRef.current) {
            await actionRef.current(code);
        } else {
            await verifyAccessDefault(code);
        }
    };

    const postAuthAction = async (): Promise<void> => {
        try {
            await actionRef?.current();
            // eslint-disable-next-line no-empty
        } catch {}
    };

    return {
        verifyAccess: verifyAccessAction,
        postAuthAction,
        setAction,
        lastAccessType,
    };
};

export default useVerifyAction;
