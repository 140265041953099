import React, { FC } from 'react';

import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import styles from './OpenFolderInfoButton.module.scss';

const nameSpace = 'myFiles.header.buttons';

interface OpenAuditsProps {
    setChosenTableFileId: (currentFolderId: string) => void;
    currentFolderId: string;
}

const OpenFolderInfoButton: FC<OpenAuditsProps> = ({ setChosenTableFileId, currentFolderId }) => {
    const { t } = useTranslation();

    return (
        <Button
            icon={<InfoCircleOutlined className={styles.icon} />}
            className={styles['open-button']}
            onClick={() => setChosenTableFileId(currentFolderId)}
        >
            {t(`${nameSpace}.folderInfo`)}
        </Button>
    );
};

export default OpenFolderInfoButton;
