import React, {
    FC, useRef,
} from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import FileRadio from './FileRadio';
import Paginator, { SelectionDirections } from './Paginator';
import { useStores, useTargetScreen } from '../../../../../../hooks';
import { useFilesActionsContext } from '../../hooks/useFilesActionContext';
import { checkIsPermissionsError, SharedFile } from '../../../../../../../stores';

import styles from './FilesPicker.module.scss';

interface FilesPickerProps {
    showOpenViewerButton: boolean;
    collapsed: boolean;
}

const FilesPicker: FC<FilesPickerProps> = observer(({
    showOpenViewerButton,
    collapsed,
}) => {
    const {
        recipientPageStore: {
            sharedFilesStore: {
                filesList,
                selectedFileId,
                filesPermissions,
                selectedFileIndex,
            },
        },
    } = useStores();
    const { selectFile } = useFilesActionsContext();

    const target = useRef<HTMLDivElement>();
    const isMobile = useTargetScreen('md');

    const checkIsFileDisabled = (fileId: string): boolean => (
        checkIsPermissionsError(filesPermissions.get(fileId)?.summary)
    );

    const showPaginator = (
        !showOpenViewerButton
        && !isMobile
        && filesList.length > 1
    );

    const containerClasses = classNames(
        styles['radio-group'],
        styles['files-group'],
        {
            [styles.multiline]: filesList.length > 1 && !isMobile,
            [styles.singleline]: isMobile,
            [styles.singlefile]: collapsed,
            [styles.multifile]: !collapsed && !isMobile,
        },
    );

    const selectSiblingFile = (direction: SelectionDirections): void => {
        const newIndex = selectedFileIndex + direction;
        if (newIndex >= 0 && newIndex < filesList.length) {
            const nextFile = filesList[newIndex];
            selectFile(nextFile?.file_id);
            if (!collapsed) {
                target?.current?.children[selectedFileIndex + direction].scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            }
        }
    };

    const getSelectedFileByIndexListView = (): SharedFile[] => (
        // This check required to prevent MobX out of bounds read warning
        typeof selectedFileIndex === 'number' && selectedFileIndex < filesList.length
            ? [filesList[selectedFileIndex]].filter(Boolean)
            : []
    );

    const filesToDisplay = (collapsed
        ? getSelectedFileByIndexListView()
        : filesList);

    return (
        <div
            ref={target}
            className={containerClasses}
        >
            {filesToDisplay.map((file) => (
                <FileRadio
                    key={file.file_id}
                    file={file}
                    isSelected={file.file_id === selectedFileId}
                    isAccessDisabled={checkIsFileDisabled(file.file_id)}
                />
            ))}
            {showPaginator && (
                <Paginator
                    selectSiblingFile={selectSiblingFile}
                    collapsed={collapsed}
                    selectedFileIndex={selectedFileIndex}
                    fileCount={filesList.length}
                />
            )}
        </div>
    );
});

export default FilesPicker;
