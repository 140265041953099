import React, { FC, memo } from 'react';

import { PUBLIC_ASSETS } from '@/consts';
import ColoredTooltip from '../ColoredTooltip';
import style from './ErrorTooltip.module.scss';

interface ErrorTooltipProps {
    message: string;
}

const ErrorTooltip: FC<ErrorTooltipProps> = ({ message }) => (
    <ColoredTooltip title={message} placement="topLeft">
        <img
            className={style['error-info']}
            src={`${PUBLIC_ASSETS}/icons/info-red.svg`}
            alt="error-info"
        />
    </ColoredTooltip>
);

export default memo(ErrorTooltip);
