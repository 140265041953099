import React, { FC } from 'react';

import { Spin, Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import './Loading.scss';

interface LoadingProps {
    loadingText: string;
    fullScreen?: boolean;
    percentCompleted?: number;
}

const Loading: FC<LoadingProps> = ({
    loadingText, fullScreen = false, percentCompleted = null,
}) => (
    <div className={classNames({ 'loading-container': true, 'full-screen': fullScreen })}>
        <Spin tip={loadingText} indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
        { percentCompleted && <Progress percent={percentCompleted} />}
    </div>
);

export default Loading;
