import React, { FC, useEffect } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import i18n from '@/content';
import ShareHalf from './ShareHalf';
import VirtualUploadList from './VirtualUploadList';
import { useStores, useUploadCompletion } from '../../hooks';
import config from '../../../config/env';
import styles from './UploadFiles.module.scss';
import appConfig from '../../../config/env';

const {
    DEFAULT_PHONE_CHECK,
} = appConfig;

const nameSpace = 'uploadFiles';

interface UploadFilesProps {
    closeDrawer: () => void;
    onShareClick: () => void;
    uploadOnly?: boolean;
}

const UploadFiles: FC<UploadFilesProps> = observer(({
    closeDrawer,
    onShareClick,
    uploadOnly = false,
}) => {
    const {
        uploadFilesStore,
        policyStore,
        sharedUsersStore,
    } = useStores();

    useUploadCompletion(uploadFilesStore);

    useEffect(() => {
        if (!policyStore.loadingPolicy) {
            policyStore.fetchPolicyList({ isSilently: true });
        }
    }, []);

    const {
        isUploading,
        atLeastOneUploadedItem,
        completedFilesCount,
        hasMfa,
        hasItems,
    } = uploadFilesStore;
    const { hasRecipients } = sharedUsersStore;

    const isContinueDisabled = !atLeastOneUploadedItem
        || (config.DISALLOW_SHARE_WHILE_FILE_UPLOADING && isUploading);
    const sharedItemType = uploadFilesStore.currentlyUploadingFolders.length ? 'folder' : 'file';
    const checkPhones = DEFAULT_PHONE_CHECK || hasMfa;

    return (
        <div className={styles['upload-drawer-content']}>
            <div className={styles['main-content']}>
                <VirtualUploadList uploadOnly={uploadOnly} />
                {!uploadOnly && (
                    <ShareHalf
                        sharedItemType={sharedItemType}
                        hasItems={hasItems}
                        hasMfa={checkPhones}
                        hasRecipients={hasRecipients}
                    />
                )}
            </div>
            <div className={classNames(
                styles['buttons-container'],
                {
                    [styles['single-child']]: uploadOnly,
                },
            )}
            >
                <Button
                    disabled={isUploading}
                    onClick={closeDrawer}
                    type="link"
                    className={styles['cancel-button']}
                    data-testid="uploadDrawer_closeWithoutSharing"
                >
                    {uploadOnly ? i18n.t('general.buttons.done') : i18n.t(`${nameSpace}.buttons.close`)}
                </Button>
                {!uploadOnly && (
                    <Button
                        disabled={isContinueDisabled}
                        type="primary"
                        onClick={onShareClick}
                        className={styles['done-button']}
                        data-testid="uploadDrawer_doneButton"
                    >
                        {completedFilesCount
                            ? i18n.t(`${nameSpace}.buttons.shareNumberFiles`, { filesNumber: completedFilesCount })
                            : i18n.t('general.buttons.shareFiles')}
                    </Button>
                )}
            </div>
        </div>
    );
});

export default UploadFiles;
