import { ToolbarItem } from './pspdfkitTypes';

const VENDOR_DOMAIN_LICENSE = import.meta.env.VITE_PSPDFKIT_VENDOR_DOMAIN_LICENSE || (
    // eslint-disable-next-line max-len
    'OXZKJtmrvNYd91iIHotMl7Jvkv-b3vZ2WN-OHX4ZLdp0lujb0NsYLO9N5ZVYM8_4ml6XfRsOvhSpStwGTU2KVbYGiuvZFjHJfrLaqJUAUbWntMaoY74X32wSCtmbuCtIBDz-6oRNn7Uu7pfyUo99LUOtv4cnKWr0elDsR95PwJ6ZRjnxBBVB6eNEUZzkeJvzFM96WHjeay_DoJgJ3KUvItbr9YSltIJaD53NrR-MUeoAsC3ssSvz4fBG-T7NWqAqJKldA0_Ky93Chjq6pih7uh0tIOKqbSIRTJW88jTiZmMGP-IWCITtK3DWeBcjdOdaXnI-_DNVB8xaZSXbsR-PU2i8RCRTgLxX8j5aL4Mc5KJ8SL-PKdeHvZ5wfTJxQStOtGjvnlCrisjj7-MwrzeA_9aj7aNkaBhvERb2YusASfVnPT8bOmlULrC1ze7w6aUgJ4Ul9Fwzja6NCX33Lb8gYitFzo5iVCAglaYbvgZpqapNqXT9yWpQCca-O0mkkgM455Nou_daGfzFztTPxsirN4kAhM7RlQw0PX95zcw2BcARcmFGZdftibFL7eYghxB8SB-e0aeUnicbR3TN1VWu451zDD9Q0_bGjozxAX3bXuoiX3eJ4K1ecXEi_7hCWxwl'
    // old key might delete it, 'ry0zmKnwxZWhj34A26M3xbjJbJ5_SJIMh4NILeT5pqY5lwUl8I9XoZyeWxyl7bD16smhIbnAi9-Y26aUtYcdOTsorIh4Ta64kdO5ZFBa_j688eqHXzqAqRJCf262Vv97wMhelHNd4yULlQkg1HziDsAfcPU4r1hIT0uokpGTvmZB0Q29ZQLbkRmFhInIdZass34P5SqscuZvFHUyHfo1zvO8QUBI_QPJKCukp6-A7oZie7eJuilgHZyUqMgva10XobbMKeQK36h_0bxyyHLJ70IAUc-IJxryK6ubCYvmsNDvGChwQ0wGcAqZbZ5BCyVZ6Ju9lQ7zO7BZwkpCWA-AVp4PHpnVrvMuxLHgSXauZc4nxu7P5G4xOBtnTGhXcffFEaY85J1-8UfONh8FsUXWmR3tVPx9rd45GWmxK9Q6krzBNhrMukBZx1EsBuw0bNt_094YZ8dak3aLJh4nwhrRRu9otjJl6oOHXQwN6dG0boGwky9U_3xo9ylV3Ut7gzlw_U9xWxxDFFwUtBQIpkTqtuNuRr_jTdiEYiSTzkzR5Yqua32KaUo-iG6C_t9j8h8dv97XiMB2s5sfc6xNSDnK-o9S6mg1Vga-RAPaoecBVZo='
);

const CUSTOMER_DOMAIN_LICENSE = import.meta.env.VITE_PSPDFKIT_CUSTOMER_DOMAIN_LICENSE || (
    // eslint-disable-next-line max-len
    'ZttLkltrYbKHrhFPzetaqlQdbSypEnoxapeblOpe27Vf-PH0V9UAGqRZIFPyhY5flszmRU8Sw_q2C-TeamV50ap4aB_PmeoXsvHOfe_549KIqgBq17lDyLCqMUKaGQ4LpeZFaLJXWr-V132dqzMoIHjfKGLMkoIVwmP5_rtI1kMnAjc0bMafmpm1d8wAeSVPxH6qMsJhCmjvjHF98bVFIGjM2g5MAiSNUZYBwqbQ70E8huol_ieqd0SXXDwct5ZUIZe-0bIes418eH9n0zBPKHRjKwAM0PjAjQfWMlMeR6Ff4Ah3R7v25img_ntveMRq90YYu3k7GnIQNvSfMYpSK0L5wfyqQujQWvYPgu189sq6ycTlNmSKN4KFoDOson8H1cpX7dTSiB_MsnFBytWdqdji_O3nD5vHmVJvS_VtZJVEvUPbRaoIb0GY2X8FMXTjG3FTdCqHkc5IFmapNI01UmYSaLZrjH9mCOV4baBzLDI49pYdpPZLxXKWByZ3UnX5k4xfjr6w6b-UBpn2gb1_1fCHsUnzaAkE5KBorDceodPsyqLMci71RWldyIWgH_hrdkzVZUt6WgwjArijdFKKGYdfHq3qGVQxHn1QzrBBQtjFloMSwwPeVkWr0cM5k32i-5KUfV2nWYNF_iRmtp-oDQ=='
    
);

export const LICENSE_KEY = window.location.origin.endsWith('specterx.com')
    ? VENDOR_DOMAIN_LICENSE
    : CUSTOMER_DOMAIN_LICENSE;

export const DEFAULT_TOOLBAR_ITEMS: ToolbarItem[] = [
    { type: 'sidebar-thumbnails' },
    { type: 'sidebar-document-outline' },
    { type: 'pager' },
    { type: 'pan' },
    { type: 'zoom-out' },
    { type: 'zoom-in' },
    { type: 'zoom-mode' },
    { type: 'search' },
];

export const CAROUSEL_MODE_STYLESHEET = '/PSPDFKitCustomCSS/carouselMode.css';
