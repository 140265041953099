import React, {
    FC,
    useCallback,
} from 'react';

import { Can } from '@casl/react';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import NewPolicyPoP from './NewPolicyPop';
import ability, { SpecterXRules } from '../../../../config/ability';
import DiscardNewPolicyModal, { useDiscardModal } from '../DiscardNewPolicyModal';
import { useStores } from '../../../hooks';
import styles from './PolicyEditorSidebar.module.scss';

interface PolicyEditorSidebarProps {
    title: string;
}

const PolicyEditorSidebar: FC<PolicyEditorSidebarProps> = observer(({ title }) => {
    const { policyStore } = useStores();

    const {
        selectedPolicy,
        selectPolicyForEditing,
        policiesListToDropdown,
    } = policyStore;

    const {
        showDiscardModal,
        openDiscardModal,
        onFinish,
    } = useDiscardModal();

    const onSelectPolicy = (policyId: string): void => {
        if (!policyStore.selectedPolicy?.id) {
            openDiscardModal(selectPolicyForEditing, policyId);
        } else {
            selectPolicyForEditing(policyId);
        }
    };

    const onDiscardModalFinish = useCallback(onFinish, []);

    return (
        <>
            <DiscardNewPolicyModal
                isVisible={showDiscardModal}
                onFinish={onDiscardModalFinish}
            />
            <div className={styles['policy-list-container']}>
                <div className={styles['policy-title-container']}>
                    <div className={styles['policy-title']}>{title}</div>
                    <Can I={SpecterXRules.SpecterxEditPolicy} a="ALL" ability={ability}>
                        <NewPolicyPoP openDiscardModal={openDiscardModal} />
                    </Can>
                </div>
                {policiesListToDropdown?.map(({ value, label }) => (
                    <Button
                        onClick={() => onSelectPolicy(String(value))}
                        className={classNames(
                            styles['policy-list-item'],
                            { [styles['policy-list-item-active']]: value === selectedPolicy?.id },
                        )}
                        key={value}
                        block
                        type="link"
                        title={label || String(value)}
                    >
                        {label || value}
                    </Button>
                ))}
            </div>
        </>
    );
});

export default PolicyEditorSidebar;
