import React, {
    FC,
    memo,
    useEffect,
    useRef,
} from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useStateWithRef } from '@/components/hooks';
import { SimpleCallback } from '@/types/types';
import { MILLISECONDS_IN_SECOND } from '@/consts';

import styles from './Timer.module.scss';

interface TimerProps {
    className?: string;
    countdownSeconds: number;
    onTimeout: SimpleCallback;
    prefix?: string;
}

const nameSpace = 'general.time';

const Timer: FC<TimerProps> = ({
    className,
    countdownSeconds,
    onTimeout,
    prefix,
}) => {
    const [secondsLeft, setSecondsLeft, secondsLeftRef] = useStateWithRef<number>(countdownSeconds);

    const intervalRef = useRef<NodeJS.Timeout>(null);
    const finishTimeoutRef = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        finishTimeoutRef.current = setTimeout(() => {
            clearInterval(intervalRef.current);
            onTimeout();
        }, countdownSeconds * MILLISECONDS_IN_SECOND);

        intervalRef.current = setInterval(() => {
            if (secondsLeftRef.current !== 0) {
                setSecondsLeft((value) => value - 1);
            } else {
                clearInterval(intervalRef.current);
            }
        }, MILLISECONDS_IN_SECOND);

        return () => {
            clearInterval(finishTimeoutRef.current);
            clearTimeout(intervalRef.current);
        };
    }, []);

    const { t } = useTranslation();

    return (
        <span className={classNames(styles.timer, { [className]: !!className })}>
            {prefix && (
                <>
                    {prefix}
                    {' '}
                </>
            )}
            {t(`${nameSpace}.secondsLeft`, { seconds: secondsLeft })}
        </span>
    );
};

export default memo(Timer);
