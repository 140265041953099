import React, {
    FC, useEffect, useLayoutEffect, useRef, useState,
} from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { useStores } from '../../../hooks';

import ColoredTooltip from '../../../Common/ColoredTooltip';
import Spinner from '../../../Common/Spin';
import { FileIcon } from '../../../Common/Icons';
import FileInfoDetails from './FileInfoDetails';
import { FileActionType } from '../../FileAction/menuItems';
import { FileBreadcrumbs } from './FileBreadcrumbs';
import styles from './FileInfoDrawer.module.scss';
import '../../../Common/Tabs/antOverride.scss';

export interface FileInfoDrawerProps {
    handleFileAction: (action: FileActionType, fileId: string) => void;
}

const FileInfoDrawer: FC<FileInfoDrawerProps> = observer(({ handleFileAction }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isTextOverflowing, setIsTextOverflowing] = useState<boolean>(false);
    const textContainerRef = useRef<HTMLSpanElement>(null);

    const {
        filesListStore: {
            setChosenTableFileId,
            selectedItem,
        },
        sharedUsersStore: { fetchSharedUsers, clearSharedUsers },
        policyStore: { fetchPolicyList },
    } = useStores();

    const {
        filename,
        fid,
        is_folder: isFolder,
    } = selectedItem || {};

    const fetchSharedUsersList = async (fileId: string): Promise<void> => {
        setIsLoading(true);
        await fetchSharedUsers(fileId);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchSharedUsersList(fid);
        return clearSharedUsers;
    }, [fid]);

    useEffect(() => {
        fetchPolicyList();
    }, []);

    useLayoutEffect(() => {
        const textContainer = textContainerRef.current;
        setIsTextOverflowing(textContainer && (textContainer?.scrollWidth > textContainer?.clientWidth));
    }, [filename]);

    return (
        <div
            className={styles['file-info-drawer']}
        >
            <Spinner
                fullHeight
                screenCentered
                spinning={isLoading}
            >
                <div className={styles['name-block']}>
                    <FileIcon className={styles['file-icon']} filename={filename} isFolder={isFolder} />
                    {isTextOverflowing ? (
                        <ColoredTooltip
                            title={<FileBreadcrumbs fileName={filename} fileId={fid} />}
                            placement="bottom"
                        >
                            <span ref={textContainerRef} className={styles['file-text']}>
                                {filename}
                            </span>
                        </ColoredTooltip>
                    ) : (
                        <span className={styles['file-text']} ref={textContainerRef}>{filename}</span>
                    )}
                    <CloseOutlined className={styles.close} onClick={() => setChosenTableFileId('')} />
                </div>
                <FileInfoDetails handleFileAction={handleFileAction} setIsLoading={setIsLoading} />
            </Spinner>
        </div>
    );
});

export default FileInfoDrawer;
