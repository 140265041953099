import { KeyboardEvent } from 'react';

import { SimpleCallback } from '../../types/types';

const SPACE_CODE = 32;

const onSpacePress = <Element>(event: KeyboardEvent<Element>, callback: SimpleCallback): void => {
    if (event.keyCode === SPACE_CODE) {
        callback();
    }
};

export default onSpacePress;
