import React, { ChangeEvent, FC, useState } from 'react';

import {
    Popconfirm,
    Button,
    Typography,
    message,
} from 'antd';
import { PlusOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { Input } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import type { Policy } from '@/types/types';
import i18n from '@/content';
import { clearMultipleSpaces } from '@/components/utils';

import { OnConfirm } from '../../DiscardNewPolicyModal/useDiscardModal';
import { findPolicyNameErrors } from '../../helpers';
import styles from './NewPolicyPoP.module.scss';

const { Text } = Typography;

const nameSpace = 'policyEditor.newPolicyPop';

interface NewPolicyPoPProps {
    openDiscardModal: (onConfirm: OnConfirm) => void;
}

const NewPolicyPoP: FC<NewPolicyPoPProps> = observer(({ openDiscardModal }) => {
    const [policyName, setPolicyName] = useState<string>('');

    const {
        policyStore: {
            selectedPolicy,
            policyList,
            defaultPolicy,
            setPolicyForEditing,
        },
    } = useStores();

    const createNewPolicySnapshot = (): void => {
        const policyNameError = findPolicyNameErrors(policyName, policyList);
        if (policyNameError) {
            message.warning(i18n.t(`policyEditor.messages.${policyNameError}`));
        } else if (defaultPolicy) {
            const newPolicy: Policy = {
                ...defaultPolicy,
                id: '',
                title: clearMultipleSpaces(policyName),
            };
            setPolicyForEditing(newPolicy);
        }
    };

    const onConfirm = (): void => {
        if (selectedPolicy?.id) {
            createNewPolicySnapshot();
        } else {
            openDiscardModal(createNewPolicySnapshot);
        }
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const currentValue: string = event.target.value;
        setPolicyName(currentValue);
    };

    return (
        <div className={styles['policy-pop-wrapper']}>
            <Popconfirm
                icon={<SafetyCertificateOutlined />}
                placement="topLeft"
                title={(
                    <div className={styles['new-policy-title']}>
                        <Text>{i18n.t(`${nameSpace}.title`)}</Text>
                        <Input value={policyName} onChange={onChange} data-testid="policyEditor_enterPolicyName" />
                    </div>
                )}
                onConfirm={onConfirm}
                okText={i18n.t('general.buttons.ok')}
                cancelText={i18n.t('general.buttons.cancel')}
            >
                <Button
                    type="link"
                    className={styles['new-policy-button']}
                    data-testid="policyEditor_addNewPolicy"
                >
                    <PlusOutlined />
                    {i18n.t(`${nameSpace}.addPolicy`)}
                </Button>
            </Popconfirm>
        </div>
    );
});

export default NewPolicyPoP;
