import { DomainSharingStats } from '../../../../stores/SharingStatsStore/interfaces';
import { stringToColor } from '../../../utils';
import { COLORS, OTHER_I18N_KEY } from './chartSettings';

export interface DomainSharingDataset {
    numberOfSharesList: number[],
    numberOfFilesSharedList: number[],
    domainNamesList: string[],
    colors: string[],
}

const getTopRecords = (domainSharingList: DomainSharingStats[], limit: number): DomainSharingStats[] => {
    if (limit <= 1) {
        throw Error(`Invalid limit value. Should be more then 1, but given ${limit}`);
    }
    if (domainSharingList.length <= limit) {
        return domainSharingList;
    }
    const slicingIndex = limit - 1;
    const topRecords = domainSharingList.slice(0, slicingIndex);
    const otherRecordsAggregate = domainSharingList.slice(slicingIndex)
        .reduce<DomainSharingStats>(
            (acc, currentValue) => {
                acc.numberOfShares += currentValue.numberOfShares;
                acc.numberOfFilesShared += currentValue.numberOfFilesShared;
                return acc;
            },
            { domainName: OTHER_I18N_KEY, numberOfShares: 0, numberOfFilesShared: 0 },
        );
    topRecords.push(otherRecordsAggregate);
    return topRecords;
};

export const getTopDomainsDataset = (domainSharingList: DomainSharingStats[], limit: number): DomainSharingDataset => {
    const colorsList = [...COLORS];
    const domainSharingDataset = {
        numberOfSharesList: [],
        numberOfFilesSharedList: [],
        domainNamesList: [],
        colors: [],
    };
    const topRecords = getTopRecords(domainSharingList, limit);

    topRecords.forEach(({ domainName, numberOfFilesShared, numberOfShares }) => {
        domainSharingDataset.numberOfSharesList.push(numberOfShares);
        domainSharingDataset.numberOfFilesSharedList.push(numberOfFilesShared);
        domainSharingDataset.domainNamesList.push(domainName);
        // If colors list is empty, fill it again
        if (!colorsList.length) {
            colorsList.push(...COLORS);
        }
        const color = stringToColor(domainName, colorsList);
        // Try to prevent colors collision by removing picked color from list
        colorsList.splice(colorsList.findIndex((item) => item === color), 1);
        domainSharingDataset.colors.push(color);
    });
    return domainSharingDataset;
};
