/*
* @param filename {String}  name of the file
* @param withDot  {Boolean} Saves dot into the filename. It will be attached only if file has extension
* Usage examples:
*     splitFilename('file.txt') -> { name: 'file', extension: 'txt' }
*     splitFilename('file.txt', true) -> { name: 'file.', extension: 'txt' }
*     splitFilename('.gitignore') -> { name: '.gitignore', extension: '' }
*     splitFilename('gitignore') -> { name: 'gitignore', extension: '' }
*     splitFilename('gitignore.') -> { name: 'gitignore.', extension: '' }
* */

interface FileName {
    extension: string;
    name: string;
}

const splitFilename = (filename = '', withDot = false): FileName => {
    const lastDotIndex = filename.lastIndexOf('.');
    let name = '';
    let extension = '';
    if (lastDotIndex > 0 && lastDotIndex < filename.length - 1) {
        name = filename.substring(0, lastDotIndex + Number(withDot));
        extension = filename.substring(lastDotIndex + 1);
    } else {
        name = filename;
    }
    return { name, extension };
};

export default splitFilename;
