import { MutableRefObject, useInsertionEffect, useRef } from 'react';

const useLatest = <T>(value: T): MutableRefObject<T> => {
    const valueRef = useRef(value);
    useInsertionEffect(() => {
        valueRef.current = value;
    });
    return valueRef;
};

export default useLatest;
