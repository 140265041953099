import React from 'react';

import { inject, observer } from 'mobx-react';
import {
    Button, Tabs,
} from 'antd';

import TabTitle from '@/components/Common/TabTitle';
import Spinner from '@/components/Common/Spin';
import { Input } from '@/components/Common/UIKit';
import { AppStore, SettingsStore } from '@/stores';
import i18n from '@/content';
import { ENDPOINTS, BASEURL } from '@/api';
import { captureErrorForSentry } from '@/components/utils';

import NewStorageConnection from './NewStorageConnection';
import './index.scss';
import '../Common/Tabs/antOverride.scss';

interface SettingsState {
    settings: Record<string, any>;
    isLoading: boolean;
    disabledSave: boolean;
    hasEmptyChange: boolean;
    newConnection: boolean;
}

interface SettingsProps {
    appStore: AppStore;
    settingsStore: SettingsStore;
}

@inject('appStore', 'settingsStore')
@observer
class Settings extends React.Component<SettingsProps, SettingsState> {
    nameSpace: string;

    originalSettings: Record<string, any>;

    constructor(props: SettingsProps) {
        super(props);
        this.state = {
            settings: {},
            isLoading: false,
            disabledSave: true,
            hasEmptyChange: true,
            newConnection: false,
        };

        this.nameSpace = 'settings';
    }

    async componentDidMount(): Promise<void> {
        const {
            appStore: { authSettingsStore: { API } },
            settingsStore: { fetchStorageKeys },
        } = this.props;

        try {
            this.setState({ isLoading: true });
            const [response] = await Promise.all([
                API.get(BASEURL.backend(), ENDPOINTS.getApiSettings(), {}), fetchStorageKeys(),
            ]);
            this.originalSettings = response.settings;
            this.setState({ isLoading: false, ...response });
        } catch (e) {
            captureErrorForSentry(e, 'Settings.componentDidMount');
            this.setState({ isLoading: false });
        }
    }

    componentDidUpdate(prevProps: Readonly<SettingsProps>, prevState: Readonly<SettingsState>): void {
        const { settings } = this.state;
        if (prevState.settings !== settings) {
            this.checkForChanges();
        }
    }

    changeSettings = (key: string, setKey: string, val: string): void => {
        const { settings: currentSettings } = this.state;
        const settings = { ...currentSettings };
        const set = { ...settings[key] };
        set[setKey] = val;
        settings[key] = set;

        this.setState({
            settings,
        }, () => {
            this.checkForChanges();
        });
    }

    checkForChanges = (): Record<string, any> => {
        const { settings } = this.state;
        const settingsKeys = Object.keys(settings);
        let hasEmpty = false;
        const changedSettings = settingsKeys.reduce((acc, key) => {
            for (const setKey in settings[key]) {
                if (settings[key].hasOwnProperty(setKey)) {
                    if (settings[key][setKey] !== this.originalSettings[key][setKey]) {
                        if (!acc[key]) {
                            acc[key] = {};
                        }

                        if (!settings[key][setKey]) {
                            hasEmpty = true;
                        }

                        acc[key][setKey] = settings[key][setKey];
                    }
                }
            }
            return acc;
        }, {});

        this.setState({
            disabledSave: !Object.keys(changedSettings).length,
            hasEmptyChange: hasEmpty,
        });

        return changedSettings;
    }

    saveSettings = async (): Promise<void> => {
        const {
            appStore: { authSettingsStore: { API } },
            settingsStore: { newStorageType, newStorageObject },
        } = this.props;
        const { settings } = this.state;
        const changedSettings = this.checkForChanges();
        const isNew = Object.keys(changedSettings).length === 0;

        const newSettings = { [`${newStorageType}`]: { ...newStorageObject } };

        const settingsToSave = !isNew ? changedSettings : newSettings;
        try {
            this.setState({ isLoading: true });
            await API.post(BASEURL.backend(), ENDPOINTS.getApiSettings(), { body: { settings: settingsToSave } });
            this.originalSettings = settings;
            this.setState({ isLoading: false, disabledSave: true });
        } catch (e) {
            captureErrorForSentry(e, 'Settings.saveSettings');
            this.setState({ isLoading: false });
        }
    }

    cancelChanges = (): void => {
        this.setState({
            settings: { ...this.originalSettings },
        });
    }

    setNewConnection = (): void => {
        this.setState({
            newConnection: true,
        });
    }

    render(): JSX.Element {
        const {
            settings,
            isLoading,
            disabledSave,
            hasEmptyChange,
            newConnection,
        } = this.state;
        const { settingsStore: { storagesTypes } } = this.props;

        const settingsKeys = Object.keys(settings);
        const keysFound = settingsKeys.some((r) => storagesTypes.includes(r));

        return (
            <Spinner spinning={isLoading} screenCentered fullHeight>

                <div className="settings-wrapper">
                    <TabTitle title={i18n.t(`${this.nameSpace}.title`)} />
                    <div className="inner-wrapper">
                        <Tabs
                            defaultActiveKey="2"
                            className="antdesign-tabs-override"
                            type="card"
                            items={[
                                {
                                    key: '2',
                                    label: 'Integrations',
                                    children: (
                                        <>
                                            {!isLoading && (keysFound ? settingsKeys.map((key) => {
                                                const set = settings[key];
                                                const setKeys = Object.keys(set);

                                                return (
                                                    <div key={key} className="inputs-block">
                                                        <div className="title">
                                                            {i18n.t(`${this.nameSpace}.${key}.title`)}
                                                        </div>
                                                        {setKeys.map((setKey) => {
                                                            const setValue = set[setKey];
                                                            return (
                                                                <div key={`${key}-${setKey}`} className="input-wrapper">
                                                                    <label className="label">
                                                                        {i18n.t(`${this.nameSpace}.${key}.${setKey}`)}
                                                                        :
                                                                    </label>
                                                                    <Input
                                                                        disabled={settings[key][setKey] === (
                                                                            this.originalSettings[key][setKey]
                                                                            && settings[key][setKey] !== ''
                                                                        )}
                                                                        onChange={(event) => this.changeSettings(
                                                                            key,
                                                                            setKey,
                                                                            event.target.value,
                                                                        )}
                                                                        value={setValue}
                                                                    />
                                                                    <Button
                                                                        onClick={() => this.changeSettings(key, setKey, '')}
                                                                        type="link"
                                                                    >
                                                                        Clear
                                                                    </Button>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            }) : (
                                                <NewStorageConnection
                                                    newConnection={newConnection}
                                                    setNewConnection={this.setNewConnection}
                                                    settingsExist={!!settingsKeys.length}
                                                />
                                            ))}
                                            <div className="button-wrapper">
                                                <Button
                                                    onClick={this.saveSettings}
                                                    disabled={(disabledSave || hasEmptyChange) && !newConnection}
                                                    className="save-button"
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    onClick={this.cancelChanges}
                                                    disabled={disabledSave}
                                                    className="cancel-button"
                                                >
                                                    Cancel Changes
                                                </Button>
                                            </div>
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </Spinner>
        );
    }
}

export default Settings;
