import { useCallback, useMemo } from 'react';

import { SecuredFile } from '../../../../types/types';

export type KeysSet = ReadonlySet<string>;

interface RowSelection {
    readonly onRowSelect: (fid: string, value: boolean) => void;
    readonly selectAll: (value: boolean) => void;
    readonly selectedKeysSet: KeysSet;
}

export const useRowsSelection = (
    selectedKeys: string[],
    setSelectedKeys: (newKeys: string[]) => void,
    allFiles: SecuredFile[],
): RowSelection => {
    const selectedKeysSet: KeysSet = useMemo<KeysSet>(() => new Set(selectedKeys), [selectedKeys]);

    const onRowSelect = useCallback((fid: string, value: boolean): void => {
        const newKeysList: string[] = value
            ? [...selectedKeys, fid]
            : selectedKeys.filter((id) => fid !== id);
        setSelectedKeys(newKeysList);
    }, [selectedKeysSet]);

    const selectAll = useCallback((value: boolean) => {
        const keys = value
            ? allFiles.map<string>(({ fid }) => fid)
            : [];
        setSelectedKeys(keys);
    }, [allFiles]);

    return {
        onRowSelect,
        selectedKeysSet,
        selectAll,
    };
};

export default useRowsSelection;
