import React, { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AppAuthenticator from '../AppAuthenticator/AppAuthenticator';
import { Loading } from '../Common/Loading';
import { useStores } from '../hooks';
import appConfig, { HAS_API_PROXY as HAS_API_PROXY_INIT, IS_WSO as IS_WSO_INIT } from '../../config/env';
import { ExternalStorageRoutes } from '@/config/appRoutes';
import checkHasAPIProxy from './checkHasAPIProxy';
import styles from './Root.module.scss';

const Root: FC = observer(() => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const location = useLocation();

    const { authSettingsStore } = useStores();
    const { IS_WSO } = authSettingsStore;

    useEffect(() => {
        (async (): Promise<void> => {
            if (HAS_API_PROXY_INIT) {
                try {
                    const { CHECK_APP_INFO_BEFORE_USING } = appConfig.API_PROXY;
                    let hasAPIProxy = true;
                    if (CHECK_APP_INFO_BEFORE_USING) {
                        hasAPIProxy = await checkHasAPIProxy();
                    }
                    if (IS_WSO_INIT) {
                        authSettingsStore.toggleWSOAuth(hasAPIProxy);
                    }
                    console.log('hasAPIProxy is', hasAPIProxy);
                    authSettingsStore.toggleAPIProxy(hasAPIProxy);
                } catch (e) {
                    authSettingsStore.toggleWSOAuth(false);
                    authSettingsStore.toggleAPIProxy(false);
                }
            }
            setIsLoading(false);
        })();
    }, []);

    const isExternalStorage = location.pathname === ExternalStorageRoutes.gdrive
        || location.pathname === ExternalStorageRoutes.box;
    const wrapperClasses = classNames(styles.root, { [styles['external-storage']]: isExternalStorage });

    return (
        <div className={wrapperClasses}>
            {isLoading && <Loading fullScreen loadingText="Please wait..." />}
            {!isLoading && <AppAuthenticator />}
        </div>
    );
});

export default Root;
