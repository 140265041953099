import React, { FC, memo } from 'react';

import { Tag } from 'antd';
import classNames from 'classnames';

import { FileActionType } from '../../FileAction/menuItems';
import { getHex } from '../helpers.js';
import styles from './PolicyTag.module.scss';

export interface PolicyTagProps {
    title: string;
    fid?: string;
    handleMenuClick?: (action: FileActionType, fid: string) => void;
}

const PolicyTag: FC<PolicyTagProps> = ({ title = 'Default', handleMenuClick, fid }) => (
    <Tag
        color={getHex(title)}
        title={title}
        className={classNames(styles.tag, { [styles.clickable]: handleMenuClick })}
        onClick={() => handleMenuClick?.('set_policy', fid)}
        data-clickable={!!handleMenuClick}
        data-testid="sharedWithMe_table_filePolicy"
    >
        {title}
    </Tag>
);

export default memo(PolicyTag);
