import {
    APIName, RequestConfig, SPXAPI, SPXEndpoint,
} from './interfaces';
import AuthSettingsStore from '../stores/AuthSettingsStore';
import CancellableAPI, { APIMethod } from './CancellableAPI';

export interface ISingleCallAPI extends SPXAPI {
    cancel(): void;
    checkIsCancel(error: unknown): boolean;
}

/*
* SingleCallAPI has only one running request. Every new request will cancel previous one.
* Use cases: debounced search, reaction on typing.
* */
class SingleCallAPI implements ISingleCallAPI {
    private readonly cancellableAPI: CancellableAPI;

    private currentRequestKey: APIMethod;

    constructor(authSettingsStore: AuthSettingsStore, failSilently: boolean) {
        this.cancellableAPI = new CancellableAPI(authSettingsStore, { failSilently });
    }

    private async sendRequest<T>(
        apiName: APIName,
        endpoint: SPXEndpoint,
        init: RequestConfig,
        method: APIMethod,
    ): Promise<T> {
        this.cancellableAPI.cancelRequest(method);
        this.currentRequestKey = method;
        const { result } = await this.cancellableAPI.sendRequest<T>(
            apiName,
            endpoint,
            this.currentRequestKey,
            method,
            init,
        );
        return result;
    }

    async get<T>(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<T> {
        return this.sendRequest(apiName, endpoint, init, 'get');
    }

    async post<T>(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<T> {
        return this.sendRequest(apiName, endpoint, init, 'post');
    }

    async put<T>(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<T> {
        return this.sendRequest(apiName, endpoint, init, 'put');
    }

    async patch<T>(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<T> {
        return this.sendRequest(apiName, endpoint, init, 'patch');
    }

    async del<T>(apiName: APIName, endpoint: SPXEndpoint, init: RequestConfig): Promise<T> {
        return this.sendRequest(apiName, endpoint, init, 'del');
    }

    cancel(): void {
        this.cancellableAPI.cancelRequest(this.currentRequestKey);
    }

    checkIsCancel(error: unknown): boolean {
        return this.cancellableAPI.checkIsCancel(error);
    }
}

export default SingleCallAPI;
