import {
    action,
    computed,
    configure,
    makeObservable,
    observable,
} from 'mobx';
import { UserManager } from 'oidc-client';

import { PUBLIC_ASSETS } from '@/consts';
import appConfig, { IS_WSO as IS_WSO_INITIAL } from '../../config/env';
import { CompanyInformation } from '@/types/types';
import AuthSettingsStore from '../AuthSettingsStore';
import UserStore from '../UserStore';
import SharingStatsStore from '../SharingStatsStore';
import FilesListStore from '../FilesListStore';
import UsersListStore from '../UsersListStore';
import OrganizationsStore from '../OrganizationsStore';
import PolicyStore from '../PolicyStore';
import SharedUsersStore from '../SharedUsersStore';
import { RecipientPageStore, SharedFilesStore } from '../RecipientPageStore';
import FilesAccessStore from '../FilesAccessStore';
import UploadFilesStore from '../UploadFilesStore';
import AuditsStore from '../AuditsStore';
import SettingsStore from '../SettingsStore';
import WorkspacesStore from '../WorkspacesStore';
import BatchDownloadStore from '../BatchDownloadStore';
import { AppRoutes } from '@/config/appRoutes';
import {
    AppAlert,
    BlockingPopup,
    ErrorStatus,
    LoadingStatus,
    MenuItem,
    SideBarState,
    UploadDrawerState,
} from './interfaces';
import MenuItems from './menuItems';
import { getWsoOauth } from '@/config/oauth';
import { checkPermissions, isAdminOnly } from '../../components/utils';
import UsersPhonesStore from '../UsersPhonesStore/UsersPhonesStore';

export type { MenuItem };

export interface AllStores {
    appStore: AppStore;
    auditsStore: AuditsStore;
    authSettingsStore: AuthSettingsStore;
    batchDownloadStore: BatchDownloadStore;
    filesAccessStore: FilesAccessStore;
    filesListStore: FilesListStore;
    organizationsStore: OrganizationsStore;
    policyStore: PolicyStore;
    recipientPageStore: RecipientPageStore;
    settingsStore: SettingsStore;
    sharedUsersStore: SharedUsersStore;
    sharingStatsStore: SharingStatsStore;
    uploadFilesStore: UploadFilesStore;
    userStore: UserStore;
    usersListStore: UsersListStore;
    workspacesStore: WorkspacesStore;
    usersPhonesStore: UsersPhonesStore;
}

class AppStore {
    constructor() {
        this.companyInformation = {
            name: 'Company Inc.',
            imageUrl: `${PUBLIC_ASSETS}/images/company-icon.png`,
        };
        const routesSet = new Set<AppRoutes>(appConfig.ROUTES);
        this.menuItems = MenuItems.filter(({ route }) => routesSet.has(route));

        let authSettingsStore: AuthSettingsStore;
        let userStore: UserStore;
        if (IS_WSO_INITIAL) {
            const oauth = getWsoOauth(appConfig);
            const userManager = new UserManager(oauth);
            authSettingsStore = new AuthSettingsStore(userManager);
            userStore = new UserStore(authSettingsStore, userManager);
        } else {
            authSettingsStore = new AuthSettingsStore();
            userStore = new UserStore(authSettingsStore);
        }

        this.authSettingsStore = authSettingsStore;
        this.userStore = userStore;
        this.settingsStore = new SettingsStore(authSettingsStore);
        this.sharingStatsStore = new SharingStatsStore(authSettingsStore);
        this.usersListStore = new UsersListStore(authSettingsStore);
        this.policyStore = new PolicyStore(authSettingsStore);
        this.workspacesStore = new WorkspacesStore(authSettingsStore, this.policyStore);
        this.filesListStore = new FilesListStore(authSettingsStore, userStore);
        this.usersPhonesStore = new UsersPhonesStore(
            this.authSettingsStore, this.usersListStore,
        );
        this.sharedUsersStore = new SharedUsersStore(authSettingsStore, this.usersListStore, this.usersPhonesStore);
        this.uploadFilesStore = new UploadFilesStore(
            authSettingsStore, this.policyStore, this.sharedUsersStore,
        );
        this.auditsStore = new AuditsStore(authSettingsStore);
        this.organizationsStore = new OrganizationsStore(authSettingsStore);
        this.batchDownloadStore = new BatchDownloadStore(authSettingsStore);
        this.filesAccessStore = new FilesAccessStore(this.authSettingsStore, this.batchDownloadStore);
        this.recipientPageStore = new RecipientPageStore(this.filesAccessStore, authSettingsStore, userStore);
        makeObservable(this);
    }

    public readonly userStore: UserStore;

    public readonly sharingStatsStore: SharingStatsStore;

    public readonly filesListStore: FilesListStore;

    public readonly filesAccessStore: FilesAccessStore;

    public readonly usersListStore: UsersListStore;

    public readonly settingsStore: SettingsStore;

    public readonly policyStore: PolicyStore;

    public readonly recipientPageStore: RecipientPageStore;

    public readonly sharedUsersStore: SharedUsersStore;

    public readonly authSettingsStore: AuthSettingsStore;

    public readonly uploadFilesStore: UploadFilesStore;

    public readonly workspacesStore: WorkspacesStore;

    public readonly auditsStore: AuditsStore;

    public readonly organizationsStore: OrganizationsStore;

    public readonly batchDownloadStore: BatchDownloadStore;

    public readonly usersPhonesStore: UsersPhonesStore;

    get stores(): AllStores {
        const {
            userStore,
            policyStore,
            usersListStore,
            filesListStore,
            sharingStatsStore,
            sharedUsersStore,
            authSettingsStore,
            uploadFilesStore,
            auditsStore,
            settingsStore,
            workspacesStore,
            organizationsStore,
            batchDownloadStore,
            filesAccessStore,
            recipientPageStore,
            usersPhonesStore,
        } = this;

        return {
            appStore: this,
            authSettingsStore,
            auditsStore,
            userStore,
            policyStore,
            usersListStore,
            filesListStore,
            sharingStatsStore,
            sharedUsersStore,
            uploadFilesStore,
            settingsStore,
            workspacesStore,
            organizationsStore,
            batchDownloadStore,
            filesAccessStore,
            recipientPageStore,
            usersPhonesStore,
        };
    }

    @observable menuItems: MenuItem[] = [];

    @observable companyInformation: CompanyInformation = null;

    @observable fileUploaderState: UploadDrawerState = 'closed';

    @observable isWorkspaceDrawerOpen = false;

    @observable appAlert: AppAlert = {
        hasAlert: false,
        type: 'warning',
        message: '',
    };

    @observable errorStatus: ErrorStatus = {
        hasError: false,
        errorCode: '',
        errorMessage: '',
    };

    @observable loadingStatus: LoadingStatus = {
        isLoading: false,
        loadingMessage: '',
    };

    @observable sideBarState: SideBarState = {
        isMobile: false,
        isVisible: true,
    };

    @observable blockingPopup: BlockingPopup | null = null;

    @observable isIPAccessAllowed = false;

    @computed
    get adminIPCheckResult(): boolean {
        return appConfig.ENABLE_IP_RESTRICTION ? this.isIPAccessAllowed : true;
    }

    @computed
    get isFileUploaderOpen(): boolean {
        return this.fileUploaderState !== 'closed';
    }

    @computed
    get displayMenuItems(): MenuItem[] {
        const {
            menuItems,
            userStore: { userGroupsSet },
            workspacesStore: { workspacesList },
        } = this;
        return menuItems.filter(({ permissionRequired, allowedGroups, route }) => {
            if (!checkPermissions(permissionRequired, userGroupsSet, allowedGroups)) {
                return false;
            }
            if (workspacesList.length && route === AppRoutes.myWorkspaces) {
                return false;
            }
            return isAdminOnly(allowedGroups) ? this.adminIPCheckResult : true;
        });
    }

    @computed
    get menuRoutesSet(): Set<AppRoutes> {
        return new Set<AppRoutes>(this.displayMenuItems.map(({ route }) => route));
    }

    @computed
    get isFullSetUp(): boolean {
        // const { organizationsStore, userStore } = this;
        const { userStore } = this;
        // return userStore.isUserSetUp && !!organizationsStore.currentOrganizationId;
        // TODO: uncomment it when all things will be ready
        return userStore.isUserSetUp;
    }

    @action
    setLoadingStatus = (isLoading: boolean, loadingMessage?: string): void => {
        this.loadingStatus = { isLoading, loadingMessage: loadingMessage || 'Loading...' };
    }

    @action
    setCompanyInformation = (company: CompanyInformation): void => {
        this.companyInformation = company;
    }

    @action
    setMenuItems = (menuItems: MenuItem[]): void => {
        this.menuItems = menuItems;
    }

    @action
    setAppAlert = ({
        hasAlert = false,
        type = 'warning',
        message = '',
        description,
    }: AppAlert): void => {
        this.appAlert = {
            hasAlert,
            type,
            message,
            description,
        };
    }

    @action
    setErrorStatus = (hasError: boolean, errorCode?: string, errorMessage?: string): void => {
        this.errorStatus = {
            hasError,
            errorMessage: errorMessage || '',
            errorCode: errorCode || '',
        };
    }

    @action
    setFileUploaderState = (val: UploadDrawerState): void => {
        this.fileUploaderState = val;
    }

    @action
    setIsWorkspaceDrawerOpen = (val: boolean): void => {
        this.isWorkspaceDrawerOpen = val;
    }

    @action
    setSideBarState = (newState: SideBarState): void => {
        this.sideBarState = newState;
    }

    @action
    updateSideBarState = (newStatePartial: Partial<SideBarState>): void => {
        this.sideBarState = { ...this.sideBarState, ...newStatePartial };
    }

    toggleSidebarVisibility = (): void => {
        this.updateSideBarState({ isVisible: !this.sideBarState.isVisible });
    }

    @action
    setIsIPAccessAllowed = (value: boolean): void => {
        this.isIPAccessAllowed = value;
    }

    @action
    setBlockingPopup = (value: BlockingPopup | null): void => {
        this.blockingPopup = value;
    }

    clear = (): void => {
        this.userStore.clearUserInformation();
        this.filesListStore.clearFilesListStore();
        this.organizationsStore.clear();
        this.batchDownloadStore.clear();
    }
}

configure({
    enforceActions: 'always',
});

export default AppStore;
