import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { EditOutlined, PhoneOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';
import type { PhoneIdentity, SyncCallback } from '@/types/types';

import RecipientChip from '../../RecipientChip';
import styles from './PhoneView.module.scss';

const nameSpace = 'sharingBlock.recipientsList';

interface PhoneViewProps extends PhoneIdentity {
    enableEditMode: SyncCallback;
    groupId?: string;
    isGroup?: boolean;
}

const PhoneView: FC<PhoneViewProps> = ({
    enableEditMode, phone, prefix, groupId, isGroup,
}) => {
    const { t } = useTranslation();
    const { usersPhonesStore: { setPhoneNumbersGroupId } } = useStores();

    const onClick = (): void => {
        if (groupId) {
            setPhoneNumbersGroupId(groupId);
        } else {
            enableEditMode();
        }
    };

    return (
        <Button
            size="small"
            type="link"
            onClick={onClick}
            className={phone ? styles['phone-edit-btn'] : styles['add-phone-button']}
        >
            {(phone && !groupId)
                && (
                    <>
                        <RecipientChip content={`+${prefix}-${phone}`} />
                        <EditOutlined className={styles['edit-icon']} />
                    </>
                )}
            {(!phone && !isGroup)
                && (
                    <>
                        <PhoneOutlined />
                        <span>{t(`${nameSpace}.${groupId ? 'fillGroupInfo' : 'addPhone'}`)}</span>
                    </>
                )}
        </Button>
    );
};

export default memo(PhoneView);
