import { CSSProperties } from 'react';

import { MILLISECONDS_IN_MINUTE } from '@/consts';

const OAUTH_REDIRECT_VALIDATION_TIME_MIN = 15;

export const OAUTH_REDIRECT_VALIDATION_TIME = MILLISECONDS_IN_MINUTE * OAUTH_REDIRECT_VALIDATION_TIME_MIN;

export const SIGN_OUT_REDIRECT_VALIDATION_TIME = MILLISECONDS_IN_MINUTE;

export const MAIN_SPINNER_STYLE: CSSProperties = {
    position: 'fixed',
    top: '20vh',
};

export const errorsNameSpace = 'recipientPage.errors';
