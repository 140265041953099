import { message } from 'antd';
import type { RcFile } from 'antd/lib/upload/interface';

import i18n from '@/content';
import { MEGABYTE } from '@/consts';
import appConfig from '../../config/env';
import bytesToText from './bytesToText';

const BAD_STARTING_CHARS = '-+=@';
const DEFAULT_ERRORS_NAMESPACE = 'uploadFiles.messages.error';

export const validateFilename = (filename: string, nameSpace: string): void | never => {
    if (!filename) {
        message.error(i18n.t(`${nameSpace}.emptyName`));
        throw Error('emptyName');
    }
    const startingChar: string = filename[0];
    if (BAD_STARTING_CHARS.includes(startingChar)) {
        message.info(i18n.t(`${nameSpace}.invalidName`, { invalidChars: BAD_STARTING_CHARS }));
        throw Error('invalidName');
    }
};

export const validateFileSize = (
    file: RcFile,
    bytesLimit: number,
): void | never => {
    const fileSize = file.size;
    if (fileSize > bytesLimit) {
        message.info(i18n.t(`${DEFAULT_ERRORS_NAMESPACE}.fileSizeLimit`, { limit: bytesToText(bytesLimit) }));
        throw Error('file too big');
    }
};

const TEST_CHUNK_TO_READ_MB = 10;

export const validateFileObject = (file: RcFile): Promise<void | never> => (
    new Promise<void | never>((resolve, reject) => {
        if (file.type) {
            resolve();
        } else {
            const reader = new FileReader();
            reader.onload = () => resolve();
            reader.onerror = () => reject(reader.error);
            reader.readAsArrayBuffer(file.slice(0, TEST_CHUNK_TO_READ_MB * MEGABYTE));
        }
    })
);

const { UPLOAD_FILES_COUNT_LIMIT } = appConfig;
const MESSAGE_DURATION_SECONDS = 8;

export const validateUploadFilesCount = (filesCount: number): void | never => {
    if (UPLOAD_FILES_COUNT_LIMIT && filesCount >= UPLOAD_FILES_COUNT_LIMIT) {
        message.error({
            content: i18n.t(`${DEFAULT_ERRORS_NAMESPACE}.filesCountLimit`, { limit: UPLOAD_FILES_COUNT_LIMIT }),
            duration: MESSAGE_DURATION_SECONDS,
            key: 'validateUploadFilesCount',
        });
        throw Error('too many files');
    }
};
