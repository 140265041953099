/* eslint-disable no-bitwise */

const stringToColor = (str: string, colorsList: string[]): string => {
    /*
    * Return time-stable color for any string
    * */
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash &= hash;
    }
    const colorsCount = colorsList.length;
    hash = ((hash % colorsCount) + colorsCount) % colorsCount;
    return colorsList[hash];
};

export default stringToColor;
