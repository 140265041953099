import {
    createContext,
    FC,
    ReactElement,
    useCallback,
    useContext,
    useState,
} from 'react';

import Modal from '@/components/Common/Modal';
import { ConfirmDownloadContent, BaseConfirmProps } from '@/components/Common/ConfirmDownload';
import AllowPopupContent from '@/components/Common/AllowPopupContent';
import { useStateWithRef } from '@/components/hooks';
import { downloadFile } from '@/components/utils';

type PopupType = 'allow' | 'confirm';

type ConfirmProps = Pick<BaseConfirmProps, 'filename' | 'ownerEmail'>;

interface FileAccessPopupsContextProps {
    openAllowPopup: (link: string) => void;
    openConfirmPopup: (link: string, props: ConfirmProps) => void;
}

export const FileAccessPopupsContext = createContext<FileAccessPopupsContextProps>(null);

export const useFileAccessPopups = (): FileAccessPopupsContextProps => (
    useContext<FileAccessPopupsContextProps>(FileAccessPopupsContext)
);

interface FileAccessPopupsProviderProps {
    children: ReactElement | ReactElement[];
}

export const FileAccessPopupsProvider: FC<FileAccessPopupsProviderProps> = ({ children }) => {
    const [popupType, setPopupType] = useState<PopupType>(null);
    const [confirmPopupProps, setConfirmPopupProps] = useState<ConfirmProps>(null);
    const [link, setLink, linkRef] = useStateWithRef<string>('');

    const openPopup = (popup: PopupType, accessLink: string): void => {
        setLink(accessLink);
        setPopupType(popup);
    };

    const closePopup = useCallback((): void => {
        setLink('');
        setPopupType(null);
        setConfirmPopupProps(null);
    }, []);

    const openAllowPopup = (accessLink: string): void => {
        openPopup('allow', accessLink);
    };

    const openConfirmPopup = (accessLink: string, props: ConfirmProps): void => {
        setConfirmPopupProps(props);
        openPopup('confirm', accessLink);
    };

    const onConfirm = useCallback((isConfirmed: boolean): void => {
        if (isConfirmed) {
            downloadFile(linkRef.current);
        }
        closePopup();
    }, []);

    const getChildElement = (): ReactElement => {
        switch (popupType) {
        case 'allow':
            return (
                <AllowPopupContent
                    link={link}
                    close={closePopup}
                />
            );
        case 'confirm':
            return (
                <ConfirmDownloadContent
                    filename={confirmPopupProps?.filename}
                    ownerEmail={confirmPopupProps?.ownerEmail}
                    onFinish={onConfirm}
                />
            );
        default:
            return <></>;
        }
    };

    return (
        <FileAccessPopupsContext.Provider value={{ openAllowPopup, openConfirmPopup }}>
            <>
                {children}
                <Modal
                    isOpen={!!popupType}
                    closeModal={closePopup}
                >
                    {getChildElement()}
                </Modal>
            </>
        </FileAccessPopupsContext.Provider>
    );
};
