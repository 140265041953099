import { useRef, useState } from 'react';

import { SimpleCallback } from '@/types/types';

export type OnConfirm = (policyId?: string) => void;

interface DiscardModalHandlers {
    showDiscardModal: boolean;
    openDiscardModal: (onConfirm: OnConfirm, policyId?: string) => void;
    onFinish: (isFinished: boolean) => void;
}

const useDiscardModal = (): DiscardModalHandlers => {
    const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);

    const onConfirmRef = useRef<SimpleCallback>(null);

    const openDiscardModal = (onConfirm: OnConfirm, policyId?: string): void => {
        onConfirmRef.current = (): void => onConfirm(policyId);
        setShowDiscardModal(true);
    };

    const onFinish = (isConfirmed: boolean): void => {
        setShowDiscardModal(false);
        if (isConfirmed) {
            onConfirmRef.current();
        }
    };

    return {
        showDiscardModal,
        openDiscardModal,
        onFinish,
    };
};

export default useDiscardModal;
