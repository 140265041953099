import React, { Component, ReactElement } from 'react';

import { Button, Menu, message } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import { inject, observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import i18n from '@/content';
import SetPolicyComponent from '../SetPolicyComponent';
import ShareSettingsTable from '../ShareSettingsTable';
import {
    AuthSettingsStore,
    FilesListStore,
    PolicyStore,
    SharedUsersStore,
    SharedUserToDisplay,
    UsersPhonesStore,
} from '../../../../stores';
import { Policy, SecuredFile } from '@/types/types';
import { ModalContentProps } from '../../../Common/Modal';
import { BASEURL, ENDPOINTS } from '../../../../api';
import { captureErrorForSentry, captureUnexpectedNetworkError } from '../../../utils';
import { ChildTypes } from '../../InfiniteScrollTable/constants';
import {
    checkPhonesChanges,
    getChangedUsers,
    prepareNewUsersList,
    SharedUserChanges,
} from '../helpers';
import './index.scss';

type ErrorMessageKey = ChildTypes;

interface CombinedSettingsState {
    currentChild: ChildTypes;
    users: SharedUserToDisplay[];
    policy: Policy;
    errorMessageKeys?: ErrorMessageKey[];
}

interface CombinedSettingsProps extends ModalContentProps {
    file: SecuredFile;
    closeModal: () => void;
    updateFilesList: (fileId: string) => Promise<void>;
    authSettingsStore?: AuthSettingsStore;
    policyStore?: PolicyStore;
    sharedUsersStore?: SharedUsersStore;
    usersPhonesStore?: UsersPhonesStore;
    filesListStore?: FilesListStore;
    startMenuItem?: ChildTypes;
}

interface RequestResult {
    succeed: boolean;
    key: ChildTypes;
}

type SettingsPartial = Pick<CombinedSettingsState, 'users'> | Pick<CombinedSettingsState, 'policy'>;

interface StateWithErrorPartial extends Pick<CombinedSettingsState, 'errorMessageKeys'> {
    currentChild?: ChildTypes;
}

@inject('authSettingsStore', 'policyStore', 'sharedUsersStore', 'filesListStore', 'usersPhonesStore')
@observer
class CombinedSettingsModal extends Component<CombinedSettingsProps, CombinedSettingsState> {
    readonly nameSpace = 'filesTable.modals.settings';

    constructor(props: CombinedSettingsProps) {
        super(props);
        this.state = {
            currentChild: props.startMenuItem || ChildTypes.policy,
            users: [],
            errorMessageKeys: [],
            policy: null,
        };
    }

    async componentDidMount(): Promise<void> {
        const {
            sharedUsersStore, policyStore, file, setLoadingStatus, setErrorStatus,
        } = this.props;
        const { fetchSharedUsers } = sharedUsersStore;

        const filePolicyId = file.policy && file.policy.id;
        const fileId = file.fid;
        const nameSpace = `${this.nameSpace}.policy.messages`;

        try {
            setLoadingStatus(true, i18n.t(`${nameSpace}.wait.loadingPolicy`));
            await Promise.all([
                this.fetchPolicies(filePolicyId),
                fetchSharedUsers(fileId, true),
            ]);
            if (policyStore.hasLoadingError || sharedUsersStore.hasShareError) {
                setErrorStatus(true, i18n.t(`${nameSpace}.error.loadingError`));
            } else {
                const { sharedUsersToDisplay } = sharedUsersStore;
                const { selectedPolicy } = policyStore;

                this.setState({
                    policy: cloneDeep(selectedPolicy),
                    users: cloneDeep(sharedUsersToDisplay),
                });
            }

            setLoadingStatus(false);
        } catch (error) {
            captureErrorForSentry(error, 'CombinedSettingModal.componentDidMount');
            setErrorStatus(true, i18n.t(`${nameSpace}.error.loadingError`));
        }
    }

    componentWillUnmount(): void {
        const {
            policyStore: { unmount },
            sharedUsersStore: { clearSharedUsers },
            filesListStore: { chosenTableFileId },
        } = this.props;
        unmount();
        if (!chosenTableFileId) {
            clearSharedUsers();
        }
    }

    setCurrentChild = (event: MenuInfo): void => {
        this.setState({ currentChild: event.key as ChildTypes });
    }

    private async fetchPolicies(filePolicyId: string): Promise<void> {
        const { policyStore } = this.props;
        await policyStore.fetchPolicyList();
        const filePolicy = policyStore.policyList.find(({ id }) => id === filePolicyId);
        policyStore.setSelectedPolicy(filePolicy as Policy);
    }

    private async updateUserPermissions(changedUsers: SharedUserChanges[], file: SecuredFile): Promise<RequestResult> {
        const { users } = this.state;
        const { sharedUsersStore, authSettingsStore: { API } } = this.props;
        const { fid: fileId } = file;
        const nameSpace = `${this.nameSpace}.shareSettings.messages`;

        let succeed = true;
        try {
            await API.patch(BASEURL.backend(), ENDPOINTS.shareFile(fileId), {
                body: { data: changedUsers },
            });
            message.success(i18n.t(`${nameSpace}.success.settingsHasBeenUpdated`));
            sharedUsersStore.setSharedUsers(prepareNewUsersList(changedUsers, users), fileId);
        } catch (error) {
            captureUnexpectedNetworkError(error, 'CombinedSettingModal.updateUserPermissions');
            succeed = false;
        }
        return { key: ChildTypes.shareSettings, succeed };
    }

    private async updatePolicy(file: SecuredFile, policyId: string): Promise<RequestResult> {
        const { policy } = this.state;
        const { policyStore, updateFilesList, authSettingsStore: { API } } = this.props;
        const { filename, fid: fileId } = file;
        const nameSpace = `${this.nameSpace}.policy.messages`;

        let succeed = true;

        try {
            await API.put(BASEURL.backend(), ENDPOINTS.filePolicy(fileId), { body: { policy_id: policyId } });
            message.success(i18n.t(`${nameSpace}.success.updateSuccess`, { filename: filename || fileId }));
            policyStore.setSelectedPolicy(cloneDeep(policy));
            updateFilesList(fileId);
        } catch (error) {
            captureUnexpectedNetworkError(error, 'CombinedSettingModal.updatePolicy');
            succeed = false;
        }
        return { key: ChildTypes.policy, succeed };
    }

    private handleResults(results: RequestResult[]): void {
        const { closeModal } = this.props;
        const { currentChild } = this.state;
        const failedRequestKeys = results.filter(({ succeed }) => !succeed).map(({ key }) => key);

        if (!failedRequestKeys.length) {
            closeModal();
        } else {
            const newStatePartial: StateWithErrorPartial = { errorMessageKeys: [...failedRequestKeys] };
            if (failedRequestKeys.length < Object.keys(ChildTypes).length) {
                const failedComponentKey = failedRequestKeys[0];
                if (failedComponentKey !== currentChild) {
                    newStatePartial.currentChild = failedComponentKey;
                }
            }
            this.setState(newStatePartial as CombinedSettingsState);
        }
    }

    private async savePhones(): Promise<RequestResult> {
        const {
            sharedUsersStore: { tryBulkSavePhonesSettings },
        } = this.props;
        const succeed = await tryBulkSavePhonesSettings();
        return { succeed, key: ChildTypes.shareSettings };
    }

    onSubmit = async (): Promise<void> => {
        const {
            file,
            setLoadingStatus,
            sharedUsersStore: { sharedUsersToDisplay },
            policyStore: { selectedPolicy },
        } = this.props;
        const { users, policy } = this.state;
        const nameSpace = `${this.nameSpace}.messages`;
        const policyId = policy?.id;

        setLoadingStatus(true, i18n.t(`${nameSpace}.wait.saveSettings`));
        this.setState({ errorMessageKeys: [] });

        const pendingPromises: Promise<RequestResult>[] = [this.savePhones()];
        if (policyId && selectedPolicy?.id !== policyId) {
            pendingPromises.push(this.updatePolicy(file, policyId));
        }

        const changedUsers = getChangedUsers(users, sharedUsersToDisplay);
        if (changedUsers.length) {
            pendingPromises.push(this.updateUserPermissions(changedUsers, file));
        }

        const results = await Promise.all(pendingPromises);
        setLoadingStatus(false);
        this.handleResults(results);
    }

    private onChangeSettings(newState: SettingsPartial): void {
        const { currentChild, errorMessageKeys } = this.state;

        if (errorMessageKeys && errorMessageKeys.includes(currentChild)) {
            const newErrorKeys = errorMessageKeys.filter((key) => key !== currentChild);
            this.setState({ ...newState, errorMessageKeys: newErrorKeys } as CombinedSettingsState);
        } else {
            this.setState(newState as CombinedSettingsState);
        }
    }

    selectPolicy = (policyId: string): void => {
        const { policyStore } = this.props;
        const policy = policyStore.policyList.find(({ id }) => policyId === id);
        this.onChangeSettings({ policy: cloneDeep(policy) });
    }

    onChangeUsers = (newUsersList: SharedUserToDisplay[]): void => {
        this.onChangeSettings({ users: newUsersList });
    }

    private getChild(currentChild: ChildTypes): ReactElement {
        const { users, policy } = this.state;
        const {
            file,
            policyStore: { hasPolicies },
        } = this.props;
        const sharedItemType = file.is_folder ? 'folder' : 'file';

        switch (currentChild) {
        case ChildTypes.policy:
            return (
                <div className="policy-wrapper">
                    <SetPolicyComponent
                        file={file}
                        selectedPolicy={policy}
                        hasPolicies={hasPolicies}
                        changePolicy={this.selectPolicy}
                    />
                </div>
            );
        case ChildTypes.shareSettings:
            return (
                <ShareSettingsTable
                    fileSelected={file}
                    sharedItemType={sharedItemType}
                    sharedUsers={users}
                    onChangeUsers={this.onChangeUsers}
                />
            );
        default:
            return null;
        }
    }

    private checkIsEqual(): boolean {
        const { users, policy } = this.state;
        const {
            sharedUsersStore: { sharedUsersToDisplay },
            usersPhonesStore: { usersPhonesMap },
            policyStore: { selectedPolicy },
        } = this.props;
        return (
            isEqual(sharedUsersToDisplay, users)
            && selectedPolicy?.id === policy?.id
            && !checkPhonesChanges(sharedUsersToDisplay, usersPhonesMap)
        );
    }

    render(): JSX.Element {
        const { currentChild, errorMessageKeys } = this.state;
        const { nameSpace } = this;
        const isDisabled = this.checkIsEqual();
        const errorMessageKey = errorMessageKeys.find((key) => key === currentChild);

        // TODO: refactor it on Tabs with custom tabBar renderer
        return (
            <div className="combined-modal-wrapper">
                <Menu
                    onClick={this.setCurrentChild}
                    selectedKeys={[currentChild]}
                    mode="horizontal"
                    className="policy-menu"
                    items={[
                        {
                            key: ChildTypes.policy,
                            danger: errorMessageKeys.includes(ChildTypes.policy),
                            label: i18n.t(`${nameSpace}.menu.changePolicy`),
                        },
                        {
                            key: ChildTypes.shareSettings,
                            danger: errorMessageKeys.includes(ChildTypes.shareSettings),
                            label: i18n.t(`${nameSpace}.menu.editPermissions`),
                        },
                    ]}
                />
                {this.getChild(currentChild)}
                <div className="submit-container">
                    {errorMessageKey && (
                        <span className="error-message">
                            {i18n.t(`${nameSpace}.messages.error.failed_${errorMessageKey}`)}
                            <br />
                            {i18n.t(`${nameSpace}.messages.error.tryAgain`)}
                        </span>
                    )}
                    <Button
                        disabled={isDisabled}
                        className="submit-button"
                        onClick={this.onSubmit}
                    >
                        {i18n.t('general.buttons.apply')}
                    </Button>
                </div>

            </div>
        );
    }
}

export default CombinedSettingsModal;
