import React, { FC, memo } from 'react';

import { Descriptions } from 'antd';

import i18n from '@/content';
import ElasticFileName from '../../../../Common/ElasticFileName';
import ElasticEmail from '../../../../Common/ElasticEmail';
import { MostRecentShare as IMostRecentShare } from '../../../../../stores/SharingStatsStore/interfaces';
import { formatDate } from '../../../../utils';
import styles from './MostRecentShare.module.scss';

const nameSpace = 'domainsDashboard.shareStatsTable.mostRecentShare';

const commonNameSpace = 'general.buttons';

const ELLIPSIS_EMAIL_LIMIT = 20;

interface MostRecentShareProps extends Omit<IMostRecentShare, 'creatorId' | 'userId' | 'fileId'> {
    isXl: boolean;
}

const MostRecentShare: FC<MostRecentShareProps> = ({
    filename,
    creatorEmail,
    userEmail,
    readOnly,
    shareTime,
    isXl,
}) => {
    const ellipsisLimit = isXl ? null : ELLIPSIS_EMAIL_LIMIT;

    return (
        <Descriptions title={i18n.t(`${nameSpace}.title`)}>
            <Descriptions.Item
                label={i18n.t(`${nameSpace}.filename`)}
                className={styles['elastic-description-parent']}
            >
                <ElasticFileName filename={filename} />
            </Descriptions.Item>
            <Descriptions.Item
                label={i18n.t(`${nameSpace}.creatorEmail`)}
                className={styles['elastic-description-parent']}
            >
                <ElasticEmail email={creatorEmail} ellipsisDomainLimit={ellipsisLimit} />
            </Descriptions.Item>
            <Descriptions.Item
                label={i18n.t(`${nameSpace}.userEmail`)}
                className={styles['elastic-description-parent']}
            >
                <ElasticEmail email={userEmail} ellipsisDomainLimit={ellipsisLimit} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t(`${nameSpace}.shareTime`)}>
                {formatDate(shareTime)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t(`${nameSpace}.readOnly`)}>
                {i18n.t(`${commonNameSpace}.${readOnly ? 'yes' : 'no'}`)}
            </Descriptions.Item>
        </Descriptions>
    );
};

export default memo(MostRecentShare);
