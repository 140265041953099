import React, { FC } from 'react';

import { Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { FolderOutlined, PaperClipOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';

import { useStores } from '@/components/hooks';
import type { UploadedItem } from '@/stores';
import './index.scss';

const SuccessFilesTable: FC = observer(() => {
    const { uploadFilesStore } = useStores();
    const { successfullyUploadedItems } = uploadFilesStore;

    const fileNameColumn: ColumnType<UploadedItem>[] = [
        {
            dataIndex: 'filename',
            width: 'fit-content',
            render: (value, { isFolder }) => (
                <div>
                    {isFolder ? <FolderOutlined /> : <PaperClipOutlined />}
                    {value}
                </div>
            ),
        },
    ];

    return (
        <div className="files-table">
            <Table
                rowKey="uid"
                pagination={false}
                dataSource={successfullyUploadedItems}
                columns={fileNameColumn}
                className="table-styles"
                rowClassName="files-table-row"
            />
        </div>
    );
});

export default SuccessFilesTable;
