import { MutableRefObject, useEffect, useState } from 'react';

import PolicyStore from '../../../../stores/PolicyStore';
import { SimpleCallback } from '../../../../types/types';
import { checkHasConfirmPolicy } from '../helpers';

interface UseConfirmCheckParams {
    filePolicyId?: string;
    ownerEmail: string;
    currentUserEmail: string;
    toggleLoader: (value: boolean) => void;
    isMountedRef: MutableRefObject<boolean>;
    onFinish: SimpleCallback;
    policyStore: PolicyStore;
}

const useConfirmCheck = ({
    filePolicyId,
    ownerEmail,
    currentUserEmail,
    toggleLoader,
    isMountedRef,
    onFinish,
    policyStore,
}: UseConfirmCheckParams): boolean => {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    useEffect(() => {
        // TODO: use ids here!
        if (ownerEmail === currentUserEmail) {
            onFinish();
        } else if (filePolicyId) {
            toggleLoader(true);
            checkHasConfirmPolicy({
                policyId: filePolicyId,
                policyStore,
            }).then((isConfirmNecessary) => {
                if (isMountedRef.current) {
                    toggleLoader(false);
                    if (isConfirmNecessary) {
                        setShowConfirm(true);
                    } else {
                        onFinish();
                    }
                }
            });
        } else {
            setShowConfirm(true);
        }
    }, []);

    return showConfirm;
};

export default useConfirmCheck;
