import React, { FC, memo } from 'react';

import { Table, Grid } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import MostRecentShare from './MostRecentShare';
import { useStores } from '../../../hooks';
import { TableScrollProps } from '../../../../types/types';
import styles from './ShareStatsTable.module.scss';

const { Column } = Table;
const { useBreakpoint } = Grid;

const nameSpace = 'domainsDashboard.shareStatsTable';

const ShareStatsTable: FC = observer(() => {
    const { sharingStatsStore: { sharingStats: { domains } } } = useStores();

    const screens = useBreakpoint();

    const tableScroll: TableScrollProps = !screens.sm && domains.length ? { x: true } : {};

    return (
        <Table
            rowKey="domainName"
            size="middle"
            pagination={false}
            scroll={tableScroll}
            className={styles['share-stats-table']}
            dataSource={domains}
            expandedRowRender={({ mostRecentShare }) => {
                const {
                    filename, creatorEmail, userEmail, readOnly, shareTime,
                } = mostRecentShare;
                return (
                    <MostRecentShare
                        filename={filename}
                        creatorEmail={creatorEmail}
                        userEmail={userEmail}
                        readOnly={readOnly}
                        shareTime={shareTime}
                        isXl={screens.xl}
                    />
                );
            }}
        >
            <Column
                title={i18n.t(`${nameSpace}.domain`)}
                dataIndex="domainName"
                key="domainName"
            />
            <Column
                title={i18n.t(`${nameSpace}.numberOfShares`)}
                dataIndex="numberOfShares"
                key="numberOfShares"
            />
            <Column
                title={i18n.t(`${nameSpace}.numberOfFilesShared`)}
                dataIndex="numberOfFilesShared"
                key="numberOfFilesShared"
            />
        </Table>
    );
});

export default memo(ShareStatsTable);
