import { useEffect, useRef } from 'react';

type BeforeUnloadListener = (event: BeforeUnloadEvent) => void;

const useWindowBeforeUnload = (checkPreventClose: () => boolean): void => {
    const listenerRef = useRef<BeforeUnloadListener>(null);
    listenerRef.current = (event: BeforeUnloadEvent) => {
        if (checkPreventClose()) {
            // eslint-disable-next-line no-param-reassign
            event.returnValue = true;
        }
    };

    useEffect(() => {
        const onBeforeUnload = (event: BeforeUnloadEvent): void => listenerRef.current(event);
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);
};

export default useWindowBeforeUnload;
