import { ErrorTypes } from './interfaces';

export const DOWNLOAD_FATAL_ERRORS: ErrorTypes[] = [403, 404];
export const DOWNLOAD_SPECIFIC_ERRORS: ErrorTypes[] = [500, 'optionNotSupported'];

export const DOWNLOAD_ERROR_MESSAGE_DURATION = 8;

export const NAME_SPACE = 'viewer';
export const ERRORS_NAME_SPACE = `${NAME_SPACE}.error`;
export const DOWNLOAD_ERRORS_NAME_SPACE = `${NAME_SPACE}.downloadError`;

export const MOBILE_VIEW_UNSUPPORTED_EXTENSIONS = [
    'bmp',
    'dib',
    'eps',
    'gif',
    'ico',
    'jpg',
    'jpeg',
    'msp',
    'pcx',
    'png',
    'apng',
    'ppm',
    'sgi',
    'tga',
    'tif',
    'tiff',
    'webp',
    'xbm',
];
