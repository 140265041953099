import React, { FC } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import FilePolicySelector from './FilePolicySelector';
import SelectionUnavailable from './SelectionUnavailable';
import { useStores } from '../../../hooks';
import styles from './FilesPolicyBlock.module.scss';

const MAX_ALLOWED_SET_FOR_ALL = 20;

const nameSpace = 'uploadFiles.filePolicy';

interface FilesPolicyBlockProps {
    isWorkspace?: boolean;
    contentModulesClassName?: string;
}

const FilesPolicyBlock: FC<FilesPolicyBlockProps> = observer(({
    isWorkspace,
    contentModulesClassName,
}) => {
    const { t } = useTranslation();

    const {
        uploadFilesStore: {
            successfullyUploadedItemsCount,
            isSamePolicy,
            chosenForPolicyFile,
        },
        workspacesStore: {
            chosenForPolicyWorkspace,
        },
        policyStore: { hasPolicies },
    } = useStores();
    const isSetForAllDisabled = isSamePolicy;
    const isSetForAllVisible = (
        successfullyUploadedItemsCount > 1
      && successfullyUploadedItemsCount <= MAX_ALLOWED_SET_FOR_ALL
    );
    const showPolicySelector = (chosenForPolicyFile || chosenForPolicyWorkspace) && hasPolicies;

    return (
        <div className={styles['files-policy-block']}>
            <div className={classNames(
                styles['set-files-title'],
                { [contentModulesClassName]: !!contentModulesClassName },
            )}
            >
                <Trans t={t} i18nKey={`${nameSpace}.title`}>
                    Set security level
                    <span className={styles['title-hint']}>(Optional)</span>
                </Trans>
            </div>
            {showPolicySelector
                ? (
                    <FilePolicySelector
                        isSetForAllDisabled={isSetForAllDisabled}
                        isSetForAllVisible={isSetForAllVisible}
                        chosenFile={chosenForPolicyFile || chosenForPolicyWorkspace}
                        isWorkspace={isWorkspace}
                        contentModulesClassName={contentModulesClassName}
                    />
                )
                : <SelectionUnavailable wrapperClassname={contentModulesClassName} />}
        </div>
    );
});

export default FilesPolicyBlock;
