import React, { FC, useState } from 'react';

import {
    Card, Button, Radio, Upload, message, Divider,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import type { RadioChangeEvent } from 'antd/es/radio';

import { Input } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import i18n from '@/content';
import { captureErrorForSentry } from '@/components/utils';
import { PUBLIC_ASSETS } from '@/consts';

import parseJsonFile from '../fileParcer';
import './index.scss';

interface RecipientChipProps {
    newConnection: boolean;
    setNewConnection: () => void;
    settingsExist: boolean;
}
const nameSpace = 'settings';
const newConnectionNameSpace = 'settings.newConnection';

type StorageType = 'sharepoint' | 'gcs';

interface StorageUIProps {
    iconName: string;
    title: string;
    storageType: StorageType;
}

const STORAGES: StorageUIProps[] = [{
    iconName: 'sharepoint-logo.svg',
    title: 'Sharepoint',
    storageType: 'sharepoint',
}, {
    iconName: 'google-cloud-logo.svg',
    title: 'Google Cloud',
    storageType: 'gcs',
}];

const NewStorageConnection: FC<RecipientChipProps> = observer(({ newConnection, setNewConnection, settingsExist }) => {
    const [newStorageJSONName, setNewStorageJSONName] = useState<string>('');
    const { settingsStore } = useStores();
    const {
        newStorageType, setNewStorageType, newStorageObject, setNewStorageObject, clearStorageObject,
    } = settingsStore;

    const onChangeRadio = (e: RadioChangeEvent): void => {
        const storageType = e.target.value;
        setNewStorageType(storageType);
        setNewStorageJSONName('');
        clearStorageObject();
    };

    const chooseSpecificStorage = (storageType: StorageType): void => {
        setNewConnection();
        setNewStorageType(storageType);
        setNewStorageJSONName('');
        clearStorageObject();
    };

    const onRemoveJSON = (): void => {
        setNewStorageJSONName('');
        setNewStorageObject({});
    };

    const createSettings = (key: string, value: string): void => {
        setNewStorageObject({ ...newStorageObject, [key]: value });
    };

    const props = {
        beforeUpload: (file) => {
            const isJSON = file.type === 'application/json';
            if (!isJSON) {
                message.error(`${file.name} is not a JSON file`);
            }
            return isJSON;
        },
        onChange: async (info) => {
            try {
                const file = info.fileList[0];
                const extractedObject = await parseJsonFile(file.originFileObj);
                const { name } = file;
                setNewStorageJSONName(name);
                setNewStorageObject(extractedObject);
            } catch (error) {
                captureErrorForSentry(error, 'SettingsNewStorage.cantExtractData');
                message.error('Could not extract data from JSON');
            }
        },
        showUploadList: false,
        accept: 'application/json',
    };

    const sampleKeys = Object.keys(newStorageObject);

    return !newConnection
        ? (
            <div className="empty-storage-wrapper">
                {settingsExist
                    ? (
                        <>
                            <img className="icon" alt="" src={`${PUBLIC_ASSETS}/icons/connect-storage-icon.svg`} />
                            <div className="title">{i18n.t(`${newConnectionNameSpace}.selectAndFulfillTitle`)}</div>
                            <Button
                                className="button"
                                onClick={setNewConnection}
                            >
                                {i18n.t(`${newConnectionNameSpace}.selectStorage`)}
                            </Button>
                        </>
                    )
                    : (
                        <>
                            <div className="title">{i18n.t(`${newConnectionNameSpace}.selectTittle`)}</div>
                            {STORAGES.map(({ iconName, title, storageType }) => (
                                <Button
                                    key={storageType}
                                    className="button"
                                    onClick={() => chooseSpecificStorage(storageType)}
                                >
                                    <img className="icon" alt="" src={`${PUBLIC_ASSETS}/icons/${iconName}`} />
                                    {title}
                                </Button>
                            ))}
                        </>
                    )}
            </div>
        )
        : (
            <div>
                <Card bordered={false}>
                    <div className="title">{i18n.t(`${newConnectionNameSpace}.connectStorage`)}</div>
                    <div className="radio-wrapper">
                        <div className="radio-title">{i18n.t(`${newConnectionNameSpace}.selectService`)}</div>
                        <Radio.Group onChange={onChangeRadio} value={newStorageType}>
                            <Radio value="sharepoint" className="radio-title">Share Point</Radio>
                            <Radio value="gcs" className="radio-title">Google Cloud Storage Bucket</Radio>
                        </Radio.Group>
                    </div>

                    <Divider className="new-storage-divider" />

                    {newStorageType === 'sharepoint' ? sampleKeys.map((setKey) => (
                        <div key={`sharepoint-${setKey}`} className="input-wrapper">
                            <label className="label">
                                {i18n.t(`${nameSpace}.sharepoint.${setKey}`)}
                                :
                            </label>
                            <Input
                                onChange={(event) => createSettings(setKey, event.target.value)}
                                placeholder={`Enter ${i18n.t(`${nameSpace}.sharepoint.${setKey}`)}`}
                                value={newStorageObject[setKey]}
                            />
                            <Button
                                onClick={() => createSettings(setKey, '')}
                                type="link"
                            >
                                Clear
                            </Button>
                        </div>
                    ))
                        : (
                            <>
                                { !newStorageJSONName
                                    ? (
                                        <Upload {...props}>
                                            <Button className="upload-json-button">
                                                {i18n.t(`${newConnectionNameSpace}.uploadJSONOnly`)}
                                            </Button>
                                        </Upload>
                                    )
                                    : (
                                        <div className="recipient-chip">
                                            <span className="content">
                                                {newStorageJSONName}
                                            </span>
                                            <CloseOutlined className="close" onClick={onRemoveJSON} />
                                        </div>
                                    )}
                            </>
                        )}
                </Card>
            </div>
        );
});
export default NewStorageConnection;
