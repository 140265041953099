import React, { FC, memo } from 'react';

interface DoubleColorMessageProps {
    coloredPart: string;
    commonPart: string;
    color?: string;
}

const DoubleColorMessage: FC<DoubleColorMessageProps> = ({
    coloredPart,
    commonPart,
    color = '#979797',
}) => (
    <>
        <span style={{ color }}>
            {coloredPart}
        </span>
        {' '}
        {commonPart}
    </>
);

export default memo(DoubleColorMessage);
