const parseJsonFile = async (file: Blob): Promise<Record<string, string>> => (
    new Promise<Record<string, string>>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const result = JSON.parse(event.target.result as string);
            resolve(result as Record<string, string>);
        };
        fileReader.onerror = (error) => reject(error);
        fileReader.readAsText(file);
    })
);

export default parseJsonFile;
