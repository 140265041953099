import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import axiosRetry from 'axios-retry';

const RETRY_CONFIG = {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
};

const createRetryableInstance = (): AxiosInstance => {
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, RETRY_CONFIG);
    return axiosInstance;
};

type UploadMode = 'single' | 'multiple';

type UploadPayload = Int8Array | FormData | ArrayBuffer;

class UploadAxiosInstance {
    private readonly abortControllers: Map<string, AbortController[]> = new Map<string, AbortController[]>();

    private readonly axiosInstance: AxiosInstance;

    private readonly mode: UploadMode;

    constructor(mode: UploadMode) {
        this.axiosInstance = createRetryableInstance();
        this.mode = mode;
    }

    cancel(key: string): void {
        this.abortControllers.get(key)?.forEach((controller) => controller.abort());
    }

    async post(
        url: string,
        body: UploadPayload,
        key: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return this.sendRequest(
            'post',
            url,
            body,
            key,
            config,
        );
    }

    async put(
        url: string,
        body: UploadPayload,
        key: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return this.sendRequest(
            'put',
            url,
            body,
            key,
            config,
        );
    }

    async patch(
        url: string,
        body: UploadPayload,
        key: string,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> {
        return this.sendRequest(
            'patch',
            url,
            body,
            key,
            config,
        );
    }

    private processNewToken(key: string, controller: AbortController): void {
        if (this.abortControllers.has(key) && this.mode === 'single') {
            this.cancel(key);
        }
        const controllers = this.abortControllers.get(key) || [];
        this.abortControllers.set(key, [...controllers, controller]);
    }

    private async sendRequest(
        method: 'post' | 'put' | 'patch',
        url: string,
        data: UploadPayload,
        key: string,
        config: AxiosRequestConfig = {},
    ): Promise<AxiosResponse> {
        const abortController = new AbortController();
        const requestConfig: AxiosRequestConfig = { ...config, signal: abortController.signal };
        this.processNewToken(key, abortController);
        let response: AxiosResponse;
        try {
            response = await this.axiosInstance[method](url, data, requestConfig);
        } finally {
            this.abortControllers.delete(key);
        }
        return response;
    }
}

export default UploadAxiosInstance;
