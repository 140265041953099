import { OpenOptions } from '../../../config/openOptions';

const nameSpace = 'recipientPage.accessButtons';

const GOOGLE_ICON = '/icons/open-with/Drive-for-bt-ic.svg';
const OFFICE365_ICON = '/icons/open-with/Office-for-bt-ic.svg';
const VIEWER_ICON = '/icons/open-with/X-for-bt-ic.svg';

export interface FileButton {
    titleKey: string;
    iconPath?: string;
}

export const GET_FILE_BUTTONS: Record<OpenOptions, FileButton> = {
    [OpenOptions.googledrive]: {
        titleKey: `${nameSpace}.google`,
        iconPath: GOOGLE_ICON,
    },
    [OpenOptions.office365]: {
        titleKey: `${nameSpace}.office`,
        iconPath: OFFICE365_ICON,
    },
    [OpenOptions.download]: {
        titleKey: `${nameSpace}.download`,
    },
    [OpenOptions.viewer]: {
        titleKey: `${nameSpace}.viewer`,
        iconPath: VIEWER_ICON,
    },
    [OpenOptions.webdav]: {
        titleKey: `${nameSpace}.webdav`,
        iconPath: VIEWER_ICON,
    },
};
