import React, {
    ChangeEvent,
    FC,
    memo,
    useCallback,
    useMemo, useState,
} from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface';
import { TFunction } from 'i18next';

import Spinner from '../Spin';
import PrefixSelector, { SelectedCountry, findCountry } from './PrefixSelector';
import { PHONE_REGEXP } from '../../../regExp';
import './index.scss';

export const phoneNameSpace = 'mfaModal.phoneInput';

interface RulesSettings {
    isMfa: boolean;
    disablePhone: boolean;
}

const getRules = (t: TFunction, { isMfa, disablePhone }: RulesSettings): Rule[] => {
    const validationRules: Rule[] = [{ required: isMfa, message: t(`${phoneNameSpace}.pleaseInputYourPhone`) }];
    if (!disablePhone) {
        validationRules.push({
            pattern: PHONE_REGEXP,
            message: t(`${phoneNameSpace}.invalidPhone`),
        });
    }
    return validationRules;
};

interface ChangedField {
    name: 'prefix' | 'phone';
    value: string | SelectedCountry;
}

type UITypes = 'MFA' | 'OTP' | 'Share';

interface PhoneCompProps {
    disablePhone?: boolean;
    initPrefix?: string;
    isLoading?: boolean;
    isMfa?: boolean;
    onChange?: (changedField?: ChangedField) => void;
    UIType?: UITypes;
}

const PhoneComp: FC<PhoneCompProps> = ({
    isLoading = false,
    disablePhone,
    isMfa,
    UIType = 'MFA',
    onChange,
    initPrefix,
}) => {
    const [error, setError] = useState(true);
    const { i18n, t } = useTranslation();
    const isOTP = UIType === 'OTP';
    const hasLabel = UIType === 'MFA';
    const isShare = UIType === 'Share';
    const formItemClasses = classNames('react-tel-input', i18n.dir(), { 'otp-ui': isOTP, 'share-ui': isShare });

    const onPrefixChange = useCallback((value: SelectedCountry) => {
        onChange?.({ name: 'prefix', value });
    }, [onChange]);

    const onPhoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (PHONE_REGEXP.test(event.target.value)) {
            setError(true);
        } else {
            setError(false);
        }
        onChange?.({ name: 'phone', value: event.target.value });
    };

    const validationRules: Rule[] = getRules(t, { isMfa, disablePhone });

    const initPrefixValue = useMemo<SelectedCountry>(() => findCountry(initPrefix), []);

    return (
        <Spinner spinning={isLoading} wrapperClassName="phone-spinner">
            <Form.Item
                name="phone"
                label={hasLabel && t(isMfa ? `${phoneNameSpace}.phoneNumber` : `${phoneNameSpace}.phoneOptional`)}
                className={formItemClasses}
                rules={validationRules}
            >
                <Input
                    required
                    placeholder={isLoading ? t(`${phoneNameSpace}.lookingForANumber`) : ''}
                    disabled={isLoading || disablePhone}
                    onChange={onPhoneChange}
                    className={`phone-input ${!error && 'error-phone-validator'}`}
                    addonBefore={(
                        <Form.Item
                            className="prefix-wrap"
                            name="prefix"
                            required={isMfa}
                            initialValue={initPrefixValue}
                        >
                            <PrefixSelector
                                otpUI={isOTP}
                                disablePhone={disablePhone}
                                onChange={onPrefixChange}
                            />
                        </Form.Item>
                    )}
                />
            </Form.Item>
        </Spinner>
    );
};

export default memo(PhoneComp);
