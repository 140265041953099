import { action, makeObservable } from 'mobx';
import difference from 'lodash/difference';

import { ROOT_FOLDER } from '@/consts';
import { ObservableTree } from '../../../components/utils';
import { SecuredFile } from '../../../types/types';
import {
    FolderNodePayload,
    FolderChildren,
    FolderNode,
} from '../interfaces';
import { createFolderKey } from '../helpers';
import { INIT_FOLDER_PAYLOAD } from '../options';

class FilesTree extends ObservableTree<SecuredFile, FolderNodePayload> {
    private readonly onChangeTree: (addedKeys: string[], removedKeys: string[]) => void;

    constructor(
        key: string,
        rootValue: SecuredFile,
        rootPayload: FolderNodePayload,
        onChange: (addedKeys: string[], removedKeys: string[]) => void,
    ) {
        super(key, rootValue, rootPayload);
        this.onChangeTree = onChange;
        makeObservable(this);
    }

    refreshChildrenValues(key: string, newChildren: FolderChildren): void {
        this.mutateNode(key, (node) => {
            const keysBeforeUpdate = this.getChildNodesKeys(node);
            // eslint-disable-next-line no-param-reassign
            node.children = newChildren;
            const keysAfterUpdate = this.getChildNodesKeys(node);
            // TODO: make reaction for it
            this.dispatchChangeEvent(keysBeforeUpdate, keysAfterUpdate);
        });
    }

    insertBreadcrumbs(breadCrumbs: SecuredFile[]): FolderNode {
        this.setNodePayload(this.root.key, { ...this.root.payload, isIncompleted: true });
        let currentNode: FolderNode = this.root;
        // TODO: create generic util for creation subtree
        let subTree: FolderNode;
        breadCrumbs.forEach((file) => {
            const newNode: FolderNode = {
                key: createFolderKey(currentNode.key, file.fid),
                value: file,
                payload: { ...INIT_FOLDER_PAYLOAD, isIncompleted: true },
                type: 'node',
                parentKey: currentNode.key,
            };
            if (!subTree) {
                subTree = newNode;
            } else {
                currentNode.children = [newNode];
            }
            currentNode = newNode;
        });
        this.appendChildrenNodes(this.root.key, subTree);
        return currentNode;
    }

    @action
    clear(): void {
        this.root = {
            key: ROOT_FOLDER,
            value: null,
            payload: { ...INIT_FOLDER_PAYLOAD },
            parentKey: null,
            type: 'node',
        };
    }

    removeNode(node: FolderNode): void {
        this.remove(node.key);
    }

    getChildNodesKeys(targetNode: FolderNode): string[] {
        const nodesKeysList: string[] = [];
        for (const node of this.traversalNodes(targetNode)) {
            if (node.key !== targetNode.key) {
                nodesKeysList.push(node.key);
            }
        }
        return nodesKeysList;
    }

    private dispatchChangeEvent(keysBefore: string[], keysAfter: string[]): void {
        this.onChangeTree(
            difference(keysAfter, keysBefore),
            difference(keysBefore, keysAfter),
        );
    }
}

export default FilesTree;
