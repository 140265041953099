import { useStores } from './useStores';
import { UserStore } from '../../stores';
import { SimpleCallback, SyncCallback } from '../../types/types';

export const authorizedAction = (
    userStore: UserStore,
    action?: SimpleCallback,
    unauthorisedAction?: SimpleCallback,
): SyncCallback<void> => (): void => {
    if (userStore.isUserSetUp) {
        action();
    } else {
        unauthorisedAction?.();
    }
};

export const useAuthorizedAction = (action: SimpleCallback): SyncCallback<void> => {
    const { userStore } = useStores();

    return authorizedAction(userStore, action);
};
