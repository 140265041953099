import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
    Button, Divider, Typography,
} from 'antd';
import classNames from 'classnames';

import { PUBLIC_ASSETS } from '@/consts';
import SharingBlock from '../../Common/SharingBlock';
import FilesPolicyList from '../FilesPolicyList';
import ResultWithIcon from '../../Common/ResultWithIcon';
import SimpleFilesList from '../../Common/SimpleFilesList';
import { useStores } from '../../hooks';
import { SimpleCallback } from '../../../types/types';
import './index.scss';

const { Text } = Typography;

const nameSpace = 'externalStorage';

interface MultiFilesViewProps {
    success: boolean;
    isPoliciesEditable: boolean;
    onShareClick: SimpleCallback;
}

const MultiFilesView: FC<MultiFilesViewProps> = observer(({
    onShareClick,
    success,
    isPoliciesEditable,
}) => {
    const { t } = useTranslation();
    const {
        usersPhonesStore: { hasMissingNumber },
        uploadFilesStore: {
            uploadedFilesSummary,
            isLoading,
            hasMfa,
        },
    } = useStores();

    if (success) {
        return (
            <ResultWithIcon
                status="shareSuccess"
                title={<div className="result-title">{t(`${nameSpace}.sharingIsCaring`)}</div>}
                className="multi-files-share-success"
                iconSize="small"
                extra={(
                    <SimpleFilesList
                        filesList={uploadedFilesSummary}
                        customTitleKey={`${nameSpace}.filesShared`}
                    />
                )}
            />
        );
    }

    const hasFiles = uploadedFilesSummary.length > 0 && !isLoading;
    const hasMfaNoComplete = hasMfa && hasMissingNumber;
    const footerClasses = classNames('share-footer', { 'bottom-pressed': isPoliciesEditable });

    return (
        <div className="multi-files-view">
            <SharingBlock showPhones={hasMfa} hasNotifyRecipients />
            {hasFiles && (
                <>
                    <Divider />
                    {isPoliciesEditable
                        ? <FilesPolicyList isExternalStorage hasMfaNoComplete={hasMfaNoComplete} />
                        : <SimpleFilesList filesList={uploadedFilesSummary} />}
                </>
            )}

            <div className={footerClasses}>
                <Text type="secondary" className="logo-text">
                    Powered By
                    <img
                        className="logo"
                        src={`${PUBLIC_ASSETS}/company_logo/specterx-yourdata.png`}
                        alt="Specterx logo"
                    />
                </Text>
                <div className="right-footer">
                    <Button type="primary" onClick={onShareClick}>
                        {t('general.specterxCommon.share')}
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default MultiFilesView;
