import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { FOLDER_ID_QUERY_PARAM } from '@/consts';
import { useStores } from '../../../../../../../hooks';
import { AppRoutes } from '../../../../../../../../config/appRoutes';
import { getNewIconUrl } from '../../../../../../../utils';
import styles from './FolderView.module.scss';

const buttonsNameSpace = 'recipientPage.accessButtons';

const FolderView: FC = observer(() => {
    const { t } = useTranslation();

    const {
        recipientPageStore: { sharedFilesStore: { selectedFile } },
        userStore: { currentUserId },
    } = useStores();

    const path = currentUserId === selectedFile?.owner ? AppRoutes.myFiles : AppRoutes.sharedWithMe;
    const iconURL = getNewIconUrl('', true);

    return (
        <div className={styles.container}>
            <img
                src={iconURL}
                alt="folder"
                className={styles.icon}
            />
            <Link
                to={`${path}?${FOLDER_ID_QUERY_PARAM}=${selectedFile?.file_id}`}
                className={styles.link}
                target="_blank"
            >
                {t(`${buttonsNameSpace}.folder`)}
            </Link>
        </div>
    );
});

export default FolderView;
