import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation as WithTranslationProps } from 'react-i18next';
import {
    Alert, Avatar, Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { API as AmplifyAPI } from 'aws-amplify';
import { AxiosError } from 'axios';
import { captureException } from '@sentry/react';

import { MAP_ORIGIN_TO_LOCAL_STORAGE_KEY } from '@/consts';
import LanguageProvider from '../Common/LanguageProvider';
import ResultWithIcon from '../Common/ResultWithIcon';
import TabTitle from '../Common/TabTitle';
import Spinner from '../Common/Spin';
import MultiFilesView from './MultiFilesView';
import SingleFileView from './SingleFileView';
import TitleWithFilename from './TitleWithFilename';
import ConnectBoxAccountBanner from './ConnectBoxAccountBanner';
import { AppStore } from '../../stores';
import NoFilesError from './NoFilesError';
import { OauthCodeResponse, StorageConnectionState, StorageProvider } from '@/types/types';
import { ExternalStorageFileResponse } from './interfaces';
import { FileToShare } from '@/stores/SharedUsersStore/interfaces';
import { BASEURL, ENDPOINTS } from '../../api';
import {
    captureErrorForSentry,
    captureServerError,
    captureUnexpectedNetworkError,
    checkHasPhoneOnlyPolicyInFilesList,
    getCognitoUserToken,
    onFinishSharingFlow,
    tryGetBadRequestMessage,
} from '../utils';
import {
    checkHasPolicies,
    checkHasRecipientBlock,
    checkIsBox,
    getTitleText,
    getFilesIdsQueryString,
    prepareFilesForMobX,
} from './helpers';
import { MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY } from '../Common/ConnectExternalStorageButton';
import './index.scss';

const { Text } = Typography;

interface ExternalStorageProps extends WithTranslationProps {
    storageProvider: StorageProvider;
    appStore?: AppStore;
}

interface BoxConnectionState extends StorageConnectionState {
    errorDescription?: string;
}

interface ExternalStorageState {
    errorMessage: string;
    success: boolean;
    boxConnectionState: BoxConnectionState;
}

@inject('appStore')
@observer
class ExternalStorage extends Component<ExternalStorageProps, ExternalStorageState> {
    private readonly nameSpace = 'externalStorage';

    constructor(props) {
        super(props);
        this.state = { errorMessage: '', success: false, boxConnectionState: { isDisconnected: false } };
    }

    async componentDidMount(): Promise<void> {
        const {
            storageProvider: origin,
            appStore: { uploadFilesStore },
        } = this.props;
        const localStorageFiles = sessionStorage.getItem(MAP_ORIGIN_TO_LOCAL_STORAGE_KEY[origin]);
        window.history.pushState({}, '', `?state=${localStorageFiles}`);
        uploadFilesStore.setIsLoading(true);

        const shouldCheckConnection = checkIsBox(origin);
        if (shouldCheckConnection) {
            this.tryLoadFilesWithAccountConnectionCheck();
        } else {
            this.tryLoadData();
        }
    }

    componentWillUnmount(): void {
        const { appStore: { policyStore, sharedUsersStore } } = this.props;
        policyStore.unmount();
        sharedUsersStore.finishRecipientsFlow();
    }

    private async loadFiles(filesIdsQueryString: string): Promise<ExternalStorageFileResponse[] | never> {
        const {
            storageProvider: origin,
            appStore: { authSettingsStore: { API } },
        } = this.props;
        const { items }: { items: ExternalStorageFileResponse[] } = await API.get(
            BASEURL.backend(),
            ENDPOINTS.getSharedFiles(origin, filesIdsQueryString),
            {},
        );

        if (!items.length) {
            console.warn('files list is empty');
            throw new NoFilesError('Files list is empty');
        }
        return items;
    }

    private async loadData(
        filesIdsQueryString: string, isPoliciesEditable: boolean,
    ): Promise<ExternalStorageFileResponse[]> {
        const {
            storageProvider,
            appStore: {
                sharedUsersStore: { fetchEmailsList },
                policyStore: { fetchPolicyList },
            },
        } = this.props;
        const hasRecipientsBlock = checkHasRecipientBlock(storageProvider);
        const requestPromises: Promise<void | ExternalStorageFileResponse[]>[] = [this.loadFiles(filesIdsQueryString)];
        if (hasRecipientsBlock) {
            requestPromises.push(fetchEmailsList());
        }

        if (isPoliciesEditable) {
            requestPromises.push(fetchPolicyList());
        }
        const [files] = await Promise.all(requestPromises);
        return files as ExternalStorageFileResponse[];
    }

    private async tryLoadData(): Promise<void> {
        const {
            t,
            storageProvider: origin,
            appStore: { uploadFilesStore, policyStore },
        } = this.props;
        try {
            const filesIdsQueryString = getFilesIdsQueryString(origin);
            const isPoliciesEditable = checkHasPolicies(origin);
            const files = await this.loadData(filesIdsQueryString, isPoliciesEditable);
            const { filesForUploadStore } = await prepareFilesForMobX(
                files, policyStore.policyList, origin, isPoliciesEditable,
            );
            uploadFilesStore.setFilesList(filesForUploadStore);
            uploadFilesStore.setIsLoading(false);
        } catch (error) {
            console.log('could not load data', error);
            uploadFilesStore.setIsLoading(false);
            let errorMessage: string;
            const badRequestMessage = tryGetBadRequestMessage(error);
            const isNoFilesError = error instanceof NoFilesError;
            if (isNoFilesError) {
                captureException(error);
            } else {
                captureUnexpectedNetworkError(error, 'ExternalStorage.tryLoadData');
            }
            if (badRequestMessage) {
                errorMessage = badRequestMessage;
            } else {
                const errorMessageKey = isNoFilesError
                    ? `${this.nameSpace}.noFiles`
                    : 'general.specterxCommon.couldNotLoadFileTryAgain';
                errorMessage = t(errorMessageKey);
            }
            this.setState({ errorMessage });
        }
    }

    private async tryLoadFilesWithAccountConnectionCheck(): Promise<void> {
        try {
            const JWT = await getCognitoUserToken();
            const { box_linkable: isDisconnected, client_id: clientId } = await AmplifyAPI.get(
                BASEURL.backend(),
                ENDPOINTS.isBoxRegister(),
                { headers: { Authorization: JWT } },
            );
            if (isDisconnected) {
                this.setState({ boxConnectionState: { isDisconnected, clientId } });
            } else {
                this.tryLoadData();
            }
        } catch (error) {
            console.log('could not check Box connection', error);
            captureErrorForSentry(error, 'ExternalStorage.tryLoadFilesWithAccountConnectionCheck');
            this.tryLoadData();
        }
    }

    onBoxOauthSuccess = async (response: OauthCodeResponse): Promise<void> => {
        const { code } = response;
        const { t } = this.props;
        const { nameSpace } = this;
        try {
            const JWT = await getCognitoUserToken();
            await AmplifyAPI.post(BASEURL.backend(),
                ENDPOINTS.linkBoxAccount(),
                { headers: { Authorization: JWT }, body: { code } });
            this.setState({ boxConnectionState: { isDisconnected: false } });
            this.tryLoadData();
        } catch (error) {
            console.log('error while send oauth code', error);
            const backEndMessage = tryGetBadRequestMessage(error as AxiosError);
            const errorDescriptionKey = MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY[backEndMessage];
            const errorDescription = (errorDescriptionKey
                ? t(`${nameSpace}.${errorDescriptionKey}`)
                : backEndMessage || t(`${this.nameSpace}.couldNotConnectAccount`, { providerName: 'Box' }));
            captureServerError(error, 'ExternalStorage.onBoxOauthSuccess');
            this.setState(
                ({ boxConnectionState }) => ({ boxConnectionState: { ...boxConnectionState, errorDescription } }),
            );
        }
    }

    shareFiles = async (): Promise<void> => {
        const {
            t,
            appStore: { sharedUsersStore, uploadFilesStore },
            storageProvider: origin,
        } = this.props;

        const files: FileToShare[] = uploadFilesStore.uploadedFiles.map<FileToShare>((file) => ({
            origin_id: file.originId,
            policy_id: file.policy?.id,
        }));

        try {
            uploadFilesStore.setIsLoading(true);
            this.setState({ errorMessage: '' });

            await sharedUsersStore.bulkShareFiles({ files, origin });
            uploadFilesStore.setIsLoading(false);
            this.setState({ success: true });
        } catch (e) {
            uploadFilesStore.setIsLoading(false);
            this.setState({ errorMessage: t('general.specterxCommon.couldNotShareTryAgain') });
        }
    }

    onShareClick = (): void => {
        const {
            t,
            storageProvider,
            appStore: {
                sharedUsersStore, uploadFilesStore, usersPhonesStore, policyStore: { policyList },
            },
        } = this.props;
        if (checkHasRecipientBlock(storageProvider)) {
            onFinishSharingFlow(
                t,
                this.shareFiles,
                checkHasPhoneOnlyPolicyInFilesList(uploadFilesStore.successfullyUploadedSingleFiles, policyList),
                { sharedUsersStore, uploadFilesStore, usersPhonesStore },
            );
        } else {
            this.shareFiles();
        }
    }

    onConnectAccountClick = (): void => {
        this.setState(
            ({ boxConnectionState }) => ({ boxConnectionState: { ...boxConnectionState, errorDescription: '' } }),
        );
    }

    render(): JSX.Element {
        const {
            errorMessage, success,
            boxConnectionState: { isDisconnected, clientId, errorDescription },
        } = this.state;
        const { storageProvider, t, appStore: { uploadFilesStore, userStore } } = this.props;
        const { uploadedFiles, isLoading } = uploadFilesStore;
        const { userInformation } = userStore;

        const hasFiles = uploadedFiles.length > 0 && !isLoading;
        const isBox = checkIsBox(storageProvider);
        const isPoliciesEditable = checkHasPolicies(storageProvider);
        const firstFileName = uploadedFiles.length ? uploadedFiles[0].filename : '';

        const titleText = getTitleText(isBox, t, firstFileName);

        const wrapperClasses = classNames('share-files-wrapper', {
            'full-height': !isBox,
            'single-file-view': isBox,
        });

        const showBoxBanner = isDisconnected && clientId;

        if (errorMessage && !hasFiles) {
            return (
                <LanguageProvider>
                    <ResultWithIcon
                        iconSize="small"
                        status="error"
                        title={errorMessage}
                        className="share-success"
                    />
                </LanguageProvider>
            );
        }

        return (
            <LanguageProvider>
                <Spinner
                    className="share-spinner"
                    spinning={isLoading}
                >
                    <div className={wrapperClasses}>
                        <TabTitle title={titleText} />
                        <div className="share-files-header">
                            {isBox
                                ? <TitleWithFilename isLoading={isLoading} filename={firstFileName} />
                                : <Text className="title" strong>{titleText}</Text>}
                            <div className="user-wrapper">
                                <Text type="secondary" className="user-email">{userInformation.email}</Text>
                                <Avatar icon={<UserOutlined />} className="user-avatar" />
                            </div>
                            <div className="share-files-border" />
                        </div>
                        {errorMessage && <Alert message={errorMessage} type="error" />}

                        {isBox
                            ? (
                                <SingleFileView
                                    success={success}
                                    isPoliciesEditable={isPoliciesEditable}
                                    onShareClick={this.onShareClick}
                                />
                            )
                            : (
                                <MultiFilesView
                                    success={success}
                                    isPoliciesEditable={isPoliciesEditable}
                                    onShareClick={this.onShareClick}
                                />
                            )}

                    </div>
                </Spinner>
                {showBoxBanner && (
                    <ConnectBoxAccountBanner
                        clientId={clientId}
                        responseSuccess={this.onBoxOauthSuccess}
                        errorDescription={errorDescription}
                        onClick={this.onConnectAccountClick}
                    />
                )}
            </LanguageProvider>
        );
    }
}

export default withTranslation()((ExternalStorage));
