import React, { FC, ReactNode } from 'react';

import { Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TextArea } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';

import styles from './CustomMessage.module.scss';
import './antOverride.scss';

const nameSpace = 'sharingBlock.notifyRecipients';

const CustomMessage: FC = observer(() => {
    const { t } = useTranslation();

    const {
        sharedUsersStore: {
            customMessage,
            setCustomMessage,
            encryptionToggle,
        },
    } = useStores();

    const onChange = (event): void => {
        const currentValue: string = event.target.value;
        setCustomMessage(currentValue);
    };

    const getSwitchTooltip = (): ReactNode => (
        <div className={styles['switch-tooltip']}>
            <CheckCircleFilled className={styles['switch-tooltip-icon']} />
            <div className={styles['switch-tooltip-text']}>{t(`${nameSpace}.allMessagesEncrypted`)}</div>
        </div>
    );

    return (
        <div className={styles['custom-message']}>
            <div className={styles['message-title']}>
                <Trans t={t} i18nKey={`${nameSpace}.addMessage`}>
                    Add message
                    <span className={styles['title-hint']}>(Optional)</span>
                </Trans>
            </div>
            <TextArea
                rows={4}
                placeholder={t(`${nameSpace}.addMessageToRecipient`)}
                value={customMessage}
                onChange={onChange}
            />
            <div className={styles['encrypt-message-block']}>
                <span className={styles['label-content']}>
                    {t(`${nameSpace}.encryptMessage`)}
                </span>
                <Switch
                    className={classNames(
                        styles['encrypted-switch'],
                        encryptionToggle ? styles.active : styles.inactive,
                    )}
                    checked={encryptionToggle}
                    disabled
                />
                <Tooltip
                    title={getSwitchTooltip}
                    color="white"
                    placement="topRight"
                >
                    <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
            </div>
        </div>
    );
});

export default CustomMessage;
