import { ComponentType, FC } from 'react';

import {
    Navigate,
    Route,
    Routes,
} from 'react-router';
import { observer } from 'mobx-react';

import ExternalStorage from '../ExternalStorage';
import OpenViewer from '../OpenViewer';
import { MyFiles } from '../MyFiles';
import PolicyEditor from '../AdminPanel/PolicyEditor';
import UserRoleEditor from '../AdminPanel/UserRoleEditor';
import { Audits } from '../Audits';
import DomainDashboard from '../AdminPanel/DomainDashboard';
import Settings from '../Settings';
import WorkspacesPage from '../WorkspacesPage';
import { useStores } from '../hooks';
import {
    ExternalStorageRoutes, AppRoutes, VIEWER_ROUTE_TEMPLATE,
} from '@/config/appRoutes';
import { ShareDrawerPage } from '../MiniApps/ShareDrawerPage';

const PagesDict: Record<AppRoutes, ComponentType> = {
    [AppRoutes.myFiles]: MyFiles as ComponentType<unknown>,
    [AppRoutes.sharedWithMe]: MyFiles as ComponentType<unknown>,
    [AppRoutes.allFiles]: MyFiles as ComponentType<unknown>,
    [AppRoutes.myWorkspaces]: WorkspacesPage,
    [AppRoutes.policyEditor]: PolicyEditor,
    [AppRoutes.userRoleEditor]: UserRoleEditor,
    [AppRoutes.auditLogs]: Audits as ComponentType<unknown>,
    [AppRoutes.domainsDashboard]: DomainDashboard as ComponentType<unknown>,
    [AppRoutes.settings]: Settings as ComponentType<unknown>,
};

const RouterWrapper: FC = observer(() => {
    const { userStore: { isExternal }, appStore: { displayMenuItems } } = useStores();

    return (
        <Routes>
            {displayMenuItems.map(({ route }) => {
                const Page = PagesDict[route];
                return (
                    <Route
                        path={route}
                        key={route}
                        element={<Page key={route} />}
                    />
                );
            })}
            <Route path={VIEWER_ROUTE_TEMPLATE} element={<OpenViewer />} />
            <Route
                path={ExternalStorageRoutes.gdrive}
                element={<ShareDrawerPage />}
            />
            <Route
                path={ExternalStorageRoutes.box}
                element={<ExternalStorage storageProvider="box" />}
            />
            <Route
                path="*"
                element={<Navigate to={isExternal ? AppRoutes.sharedWithMe : AppRoutes.myFiles} replace />}
            />
        </Routes>
    );
});

export default RouterWrapper;
