import { RcFile } from 'antd/lib/upload/interface';

import { DeferredFile, OSFolder } from './interfaces';
import { createAntFile } from './helpers';

const FILL_DEBOUNCE_MS = 100;

type FillCallback = (files: DeferredFile[]) => void;

class InsertionBuffer {
    private deferredFiles: DeferredFile[] = [];

    private fillTimeoutId: NodeJS.Timeout;

    private readonly onFill: FillCallback;

    constructor(onFill: FillCallback) {
        this.onFill = onFill;
    }

    appendFile(file: RcFile, folder: OSFolder): Promise<void> {
        if (this.fillTimeoutId) {
            clearTimeout(this.fillTimeoutId);
        }
        this.fillTimeoutId = setTimeout(() => this.debounceFill(), FILL_DEBOUNCE_MS);
        return new Promise<void>((resolve, reject) => {
            this.deferredFiles.push({
                folder,
                file: createAntFile(file),
                next: resolve,
                cancel: reject,
            });
        });
    }

    private debounceFill(): void {
        this.onFill(this.deferredFiles);
        this.deferredFiles = [];
    }
}

export default InsertionBuffer;
