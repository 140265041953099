import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { OAuthOpts } from '@aws-amplify/auth/src/types';

import { EnvConfig } from './env';
import WebappStorageCipherProxy from '../webappStorage/WebappStorageCipherProxy';
import { ExternalStorageRoutes, MiniAppRoutes } from './appRoutes';

const WebappStorageCypherProxy = new WebappStorageCipherProxy(localStorage);

const stateWebappStorage = new WebStorageStateStore({ store: WebappStorageCypherProxy });
const userWebappStorage = new WebStorageStateStore({ store: WebappStorageCypherProxy });

export const getWsoOauth = (config: EnvConfig): UserManagerSettings => ({
    authority: config.WSO.DOMAIN,
    client_id: config.WSO.CLIENT_ID,
    client_secret: config.WSO.CLIENT_SECRET,
    redirect_uri: config.WSO.REDIRECT_URL,
    response_type: 'code',
    query_status_response_type: 'code',
    scope: 'openid profile email',
    revokeAccessTokenOnSignout: true,
    post_logout_redirect_uri: config.WSO.REDIRECT_URL,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: false,
    mergeClaims: true,
    stateStore: stateWebappStorage,
    userStore: userWebappStorage,
    response_mode: 'query',
    metadata: {
        issuer: `${config.WSO.DOMAIN}/oauth2/token`,
        authorization_endpoint: `${config.WSO.DOMAIN}/oauth2/authorize`,
        token_endpoint: `${config.WSO.DOMAIN}/oauth2/token`,
        token_endpoint_auth_methods_supported: ['POST'],
        userinfo_endpoint: `${config.WSO.DOMAIN}/oauth2/userinfo`,
        end_session_endpoint: `${config.WSO.DOMAIN}/oidc/logout`,
        check_session_iframe: `${config.WSO.DOMAIN}/oidc/checksession`,
        jwks_uri: `${config.WSO.DOMAIN}/oauth2/jwks`,
        revocation_endpoint: `${config.WSO.DOMAIN}/oauth2/revoke`,
        introspection_endpoint: `${config.WSO.DOMAIN}/oauth2/introspect`,
        registration_endpoint: `${config.WSO.DOMAIN}/api/identity/oauth2/dcr/v1.1/register`,
    },
});

const getRedirectRoute = (pathname: string): string => {
    let result = '';
    switch (pathname) {
    case ExternalStorageRoutes.gdrive:
    case ExternalStorageRoutes.box:
        result = pathname;
        break;
    default:
        result = '/signin';
    }
    return result;
};

const redirectSignIn = `${window.location.origin}${getRedirectRoute(window.location.pathname)}`;

const MINI_APP_REDIRECT_ROUTES = [MiniAppRoutes.sharedFile, MiniAppRoutes.sharedWorkspace];

const DEFAULT_SIGN_OUT_URL = `${window.location.origin}/signin`;

const getRedirectSignOut = (isCustomURL: boolean): string => {
    if (isCustomURL) {
        const miniAppRoute = MINI_APP_REDIRECT_ROUTES.find((route) => route === window.location.pathname);
        return miniAppRoute ? `${window.location.origin}${miniAppRoute}` : DEFAULT_SIGN_OUT_URL;
    }
    return DEFAULT_SIGN_OUT_URL;
};

export const getCognitoOauth = (config: EnvConfig, isCustomSignOutURL: boolean): OAuthOpts => ({
    domain: config.cognito.DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn,
    redirectSignOut: getRedirectSignOut(isCustomSignOutURL),
    responseType: 'code',
    options: {
        AdvancedSecurityDataCollectionFlag: false,
    },
});
