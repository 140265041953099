import React, { FC } from 'react';

import i18n from '@/content';
import { nameSpace, SUBTITLE_STYLE } from './constants';

const NewTabLink: FC = () => (
    <a
        style={SUBTITLE_STYLE}
        href={window.location.href}
        target="_blank"
        rel="noopener noreferrer"
    >
        {i18n.t(`${nameSpace}.pleaseFollow`)}
    </a>
);

export default NewTabLink;
