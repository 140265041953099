import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { useStores } from '../../../hooks';
import styles from './RefreshButton.module.scss';

const RefreshButton: FC = () => {
    const { filesListStore: { refreshFiles } } = useStores();

    return (
        <Button
            className={classNames(styles.refresh, styles['icon-button'])}
            type="link"
            onClick={refreshFiles}
        >
            <ReloadOutlined />
        </Button>
    );
};

export default memo(RefreshButton);
