import { Component } from 'react';

import { inject, observer } from 'mobx-react';
import {
    Button,
    Dropdown,
    Upload,
} from 'antd';
import { FileAddOutlined, FolderAddOutlined } from '@ant-design/icons';
import type {
    RcFile,
    UploadFile as AntUploadedFile,
} from 'antd/lib/upload/interface';
import type { MenuProps } from 'antd/lib/menu';
import classNames from 'classnames';

import { AppStore, FilesListStore, UploadFilesStore, PolicyStore, UserStore } from '@/stores';
import type { OSFolder, UploadedFile } from '@/stores/UploadFilesStore/interfaces';
import { FileToDisplay } from '@/stores/FilesListStore/interfaces';
import i18n from '@/content';
import { parseFolderPath } from '@/stores/UploadFilesStore/helpers';
import {
    onSpacePress,
    validateFilename,
    validateFileObject,
    validateFileSize,
    validateUploadFilesCount,
} from '@/components/utils';

import DraggerInnerContent from './DraggerInnerContent';
import { DrawerState, HoverType, UploadControlsProps } from './interfaces';
import { nameSpace } from './constants';
import './index.scss';

const MENU_ITEMS: HoverType[] = ['file', 'folder'];

const { Dragger } = Upload;

interface UploadDraggerProps {
    appStore?: AppStore;
    uploadFilesStore?: UploadFilesStore;
    filesListStore?: FilesListStore;
    policyStore?: PolicyStore;
    userStore?: UserStore;
    drawerState?: DrawerState;
    isSidebar?: boolean;
    reopenFirstStep?: () => void;
    sharedWorkspace?: boolean;
    folderId?: string;
    directory?: boolean;
}

interface UploadDraggerState {
    isDisabled: boolean;
    hoverType: HoverType;
}

// TODO: provide here custom renderer for uploader inner content
@inject('uploadFilesStore', 'filesListStore', 'appStore', 'policyStore', 'userStore')
@observer
class FilesUploaderDragger extends Component<UploadDraggerProps, UploadDraggerState> {
    constructor(props: UploadDraggerProps) {
        super(props);
        this.state = {
            isDisabled: false,
            hoverType: 'file',
        };
    }

    getOverlayContent = (uploadProps: UploadControlsProps): MenuProps => ({
        onClick: ({ key }) => this.setState({ hoverType: key as HoverType }),
        className: 'upload-menu-overlay',
        items: MENU_ITEMS.map((item) => {
            const isFile = item === 'file';
            const { isSidebar } = this.props;
            return ({
                key: item,
                className: classNames('upload-menu-item', { 'sidebar-hover-menu': isSidebar }),
                label: (
                    <Upload
                        className="upload-picker-trigger-wrapper"
                        multiple
                        showUploadList={false}
                        directory={item !== 'file'}
                        {...uploadProps}
                    >
                        <Button
                            type="link"
                            className={classNames('upload-dropdown-btn', 'dragger-content')}
                        >
                            {isFile ? <FileAddOutlined /> : <FolderAddOutlined />}
                            <span className="dragger-text">
                                {i18n.t(`${nameSpace}.${isFile ? 'draggerDropdownFile' : 'draggerDropdownFolder'}`)}
                            </span>
                        </Button>
                    </Upload>
                ),
            });
        }),
    })

    modifySelectedFiles = (files: FileToDisplay[]): UploadedFile[] => {
        const { policyStore, userStore } = this.props;
        const { policyList } = policyStore;
        const { currentUserEmail } = userStore;
        return files.reduce((prev, next) => {
            const policy = policyList.find(policyItem => policyItem.id === next.policy_id);
            if (next.owner_email === currentUserEmail) {
                prev.push({
                    fid: next.file_id,
                    uid: next.file_id,
                    filename: next.filename,
                    name: next.filename,
                    hasPolicyError: false,
                    isFolder: next.is_folder,
                    expanded: false,
                    size: next.file_size,
                    percent: 100,
                    policy,
                    status: 'done',
                });
            }
            return prev;
        }, []);
    }

    openUploader = (): void => {
        const {
            appStore: {
                fileUploaderState,
                setFileUploaderState,
            },
            sharedWorkspace,
            uploadFilesStore,
            filesListStore: {
                selectedItemsCount,
                selectedItems,
                getPossibleUploadFolderId,
            },
        } = this.props;
        if (selectedItemsCount) {
            const filesList = this.modifySelectedFiles(selectedItems);
            uploadFilesStore.setFilesList(filesList);
        }

        if (sharedWorkspace) {
            uploadFilesStore.setUploadingPopupOpened(true);
        } else if (fileUploaderState === 'closed') {
            const newFileUploaderState = getPossibleUploadFolderId() && !selectedItemsCount ? 'uploadOnly' : 'uploadAndShare';
            setFileUploaderState(newFileUploaderState);
        }
    }

    onBeforeUpload = async (file: RcFile): Promise<void> => {
        const {
            filesListStore: { getPossibleUploadFolderId },
            reopenFirstStep,
            folderId,
            uploadFilesStore: {
                appendFile,
                runCreateFolderActions,
            },
        } = this.props;

        await this.validateFile(file);
        const [parentFolder, pathToRoot] = parseFolderPath(file.webkitRelativePath);
        if (parentFolder) {
            validateFilename(parentFolder, 'createFolder.messages.error');
        }
        reopenFirstStep?.();
        this.openUploader();
        const folderToUploadId = folderId || getPossibleUploadFolderId();
        const folder: OSFolder = parentFolder ? { parentFolder, pathToRoot } : null;
        await appendFile({ file, myFilesParentFolderId: folderToUploadId, folder });
        if (parentFolder) {
            await runCreateFolderActions(file);
        }
    }

    private async validateFile(file: RcFile): Promise<void | never> {
        const {
            uploadFilesStore: {
                allFilesCount,
                uploadLimit,
            },
            directory = true,
        } = this.props;
        validateFilename(file.name, 'uploadFiles.messages.error');
        validateFileSize(file, uploadLimit);
        validateUploadFilesCount(allFilesCount);
        if (!directory) {
            await validateFileObject(file);
        }
    }

    render(): JSX.Element {
        const { isDisabled, hoverType } = this.state;
        const {
            uploadFilesStore: {
                uploadedFilesToJS,
                uploadFile,
            },
            drawerState,
            isSidebar,
            directory = true,
        } = this.props;
        const isDrawer = !!drawerState;
        const hasDrawerStyles = isDrawer && !isSidebar;
        const draggerClasses = classNames({
            'dragger-wrapper': hasDrawerStyles,
            'drawer-header': hasDrawerStyles,
            'sidebar-header': isSidebar,
        });

        const uploadControlsProps: UploadControlsProps = {
            disabled: isDisabled,
            customRequest: async ({ file }) => uploadFile(file as RcFile),
            fileList: uploadedFilesToJS as AntUploadedFile[],
            beforeUpload: this.onBeforeUpload,
        };

        const content = (
            <Dropdown
                disabled={!directory}
                menu={this.getOverlayContent(uploadControlsProps)}
                placement="bottom"
                trigger={['hover']}
                className={classNames({ 'sidebar-hover-dropdown': isSidebar })}
            >
                <div
                    onClick={directory ? this.openUploader : null}
                    onKeyUp={directory
                        ? (event) => onSpacePress(event, this.openUploader)
                        : null}
                    role="button"
                    tabIndex={0}
                >
                    <DraggerInnerContent
                        option={hoverType === 'file' ? 'draggerFile' : 'draggerFolder'}
                        drawerState={drawerState}
                        isSidebar={isSidebar}
                        openUploader={this.openUploader}
                    />
                </div>
            </Dropdown>
        );

        return isSidebar ? (
            <Upload
                multiple
                directory={directory}
                openFileDialogOnClick={!directory}
                showUploadList={false}
                {...uploadControlsProps}
                className={draggerClasses}
            >
                {content}
            </Upload>
        ) : (
            <Dragger
                multiple
                directory={directory}
                openFileDialogOnClick={!directory}
                showUploadList={false}
                {...uploadControlsProps}
                className={draggerClasses}
                data-testid="uploadDrawer_dragdropArea"
            >
                {content}
            </Dragger>
        );
    }
}

export default FilesUploaderDragger;
