import {
    FC,
    RefObject,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import { Letter } from 'react-letter';
import classNames from 'classnames';

import type { LetterContent } from '@/stores';

import styles from './HTMLEmailWrapper.module.scss';
import { useSize } from '@/components/hooks';

const BODY_EXTRA_OFFSET_PX = 20;
const SCALE_MULTIPLIER = 1.2;
const SMALL_CONTENT_TO_WINDOW_SIZE = 3;

const useLetterContentHeight = (): {
    containerRef: RefObject<HTMLDivElement>;
    isScaled: boolean;
} => {
    const [isScaled, setIsScaled] = useState<boolean>(false);

    const { containerRef } = useSize<HTMLDivElement>();

    useLayoutEffect(() => {
        const iframe = containerRef?.current.querySelector('iframe');
        if (iframe) {
            iframe.style.display = 'none';
        }

        const fitIframeWithContentHeight = (): void => {
            iframe.style.display = 'block';
            const { body } = iframe?.contentDocument ?? { body: null };
            if (body) {
                const { height } = body.getBoundingClientRect();
                const isSmallContent = window.innerHeight / height > SMALL_CONTENT_TO_WINDOW_SIZE;
                setIsScaled(isSmallContent);
                const finalHeight = (height + BODY_EXTRA_OFFSET_PX) * (isSmallContent ? SCALE_MULTIPLIER : 1);
                containerRef.current.style.height = `${finalHeight}px`;
                if (height < finalHeight) {
                    containerRef.current.style.overflowY = `hidden`;
                }
            }
        };

        iframe?.addEventListener('load', fitIframeWithContentHeight);
        return () => iframe?.removeEventListener('load', fitIframeWithContentHeight);
    }, []);

    return { containerRef, isScaled };
};

const LetterWrapper: FC<Omit<LetterContent, 'subject'>> = ({ html, plainText, attachments }) => {
    const { containerRef, isScaled } = useLetterContentHeight();

    return (
        <div
            ref={containerRef}
            className={classNames(styles['letter-wrapper'], { [styles.scaled]: isScaled })}
        >
            <Letter
                useIframe
                className={styles['inner-wrapper']}
                rewriteExternalResources={(url) => {
                    const content = attachments.find(({cid}) => cid === url);
                    return content?.link;
                }}
                html={html}
                text={plainText}
            />
        </div>
    );
};

export default LetterWrapper;
