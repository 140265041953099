import React, { FC, memo } from 'react';

import { Result } from 'antd';

import i18n from '@/content';
import { CopyFileLink } from '../../../../Common/CopyLink';
import { CopyLinkProps } from '../interfaces';
import style from './ShareSuccess.module.scss';

interface ShareSuccessProps extends CopyLinkProps {
    filename: string;
}

const ShareSuccess: FC<ShareSuccessProps> = ({ linkKey, filename }) => (
    <Result
        status="success"
        title={i18n.t('general.specterxCommon.sharingIsCaring')}
        className={style['share-success']}
        subTitle={(
            <div>
                <div>{filename}</div>
                <div className={style['copy-button-wrapper']}>
                    <CopyFileLink linkKey={linkKey} />
                </div>
            </div>
        )}
    />
);

export default memo(ShareSuccess);
