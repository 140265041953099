import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';
import { OpenOptions } from '@/config/openOptions';
import { PUBLIC_ASSETS } from '@/consts';

import { useFilesActionsContext } from '../../hooks/useFilesActionContext';
import useToastError from './useToastError';
import styles from './MessagePreview.module.scss';

const NAME_SPACE = 'recipientPage.message.preview';

const MessagePreview: FC = observer(() => {
    const { t } = useTranslation();
    const { actionHandler } = useFilesActionsContext();
    const {
        recipientPageStore: {
            emailMessageStore: {
                tryFetchMessage,
                messageId,
                isLoading,
                errorType,
            },
        },
    } = useStores();

    useToastError(errorType);

    const onClick = actionHandler({
        action: async () => tryFetchMessage({}),
        verifyAction: async (code) => tryFetchMessage({ code, throwError: true }),
        payload: { fileId: messageId, accessType: OpenOptions.download },
    });

    return (
        <div className={styles.container}>
            <span className={styles.description}>
                {t(`${NAME_SPACE}.messageEncrypted`)}
            </span>
            <Button
                loading={isLoading}
                className={styles['read-button']}
                onClick={onClick}
                icon={(
                    <img
                        alt="SpecterX small logo"
                        className={styles['spx-dark-icon']}
                        src={`${PUBLIC_ASSETS}/company_logo/SpecterX-Logo-X.png`}
                    />
                )}
            >
                {t(`${NAME_SPACE}.readMessage`)}
            </Button>
        </div>
    );
});

export default MessagePreview;
