import React, {
    FC, memo, SyntheticEvent, useCallback, useState, useEffect,
} from 'react';

import { Button, message } from 'antd';
import { Auth } from 'aws-amplify';

import i18n from '@/content';
import TabTitle from '../../Common/TabTitle';
import AuthSubmitButton from '../AuthSubmitButton';
import { BaseAuthLink } from '../AuthLink';
import { IAuthenticatorChildrenProps } from '../interfaces';
import styles from './ConfirmUser.module.scss';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import Timer from '@/components/Common/Timer';
import { LONG_MESSAGE_DURATION_SECONDS } from '@/stores/UploadFilesStore/constants';

const nameSpace = 'forgotPassword';
const RESEND_TIMEOUT_DEFAULT = 60;

interface ConfirmUserProps extends Pick<IAuthenticatorChildrenProps, 'signInCalls'> {
    userName: string;
    userPassword: string;
}

const ConfirmUser: FC<ConfirmUserProps> = ({ userName, userPassword }) => {
    const [codeValue, setCodeValue] = useState<string>('');
    const [step, setStep] = useState<string>('');
    const [attemptsRemain, setAttemptsRemain] = useState<number>(3);
    const [authSucceed, setAuthSucceed] = useState<boolean>(true);
    const [showTimeout, setShowTimeout] = useState<boolean>(false);
    const [resendRestricted, setResendRestricted] = useState<boolean>(false);
    const [confirmUserLoading, setConfirmUserLoading] = useState<boolean>(false);

    const doChangePassword = useCallback(
        async (event: SyntheticEvent): Promise<void> => {
            try {
                await Auth.forgotPasswordSubmit(userName.toLowerCase(), codeValue, userPassword);
                message.success(i18n.t(`${nameSpace}.hiddenForgotPassword.registrationConfirmed`), LONG_MESSAGE_DURATION_SECONDS);
                setStep('done');
            } catch (err) {
                setAttemptsRemain(attemptsRemain - 1);
                setAuthSucceed(false);
                if (attemptsRemain) {
                    message.error(i18n.t(`${nameSpace}.hiddenForgotPassword.wrongCode`, { attemptsRemain: attemptsRemain - 1 }), LONG_MESSAGE_DURATION_SECONDS);
                }
            }
        },
        [codeValue],
    );
    const resendCode = async () => {
        try {
            setConfirmUserLoading(true);
            await Auth.forgotPassword(userName.toLowerCase());
            setShowTimeout(true);
            setResendRestricted(true);
        } catch (err) {
            message.error(i18n.t(`${nameSpace}.hiddenForgotPassword.errorMessage`), LONG_MESSAGE_DURATION_SECONDS);
            setAuthSucceed(false);
        } finally {
            setConfirmUserLoading(false);
        }
    };
    useEffect(() => {
        if (!attemptsRemain) {
            message.error(i18n.t(`${nameSpace}.hiddenForgotPassword.errorMessage`), LONG_MESSAGE_DURATION_SECONDS);
            setStep('done');
        }
    }, [attemptsRemain]);
    const checkIsFormValid = (): boolean => codeValue.length === 6;

    if (step === 'done') {
        return <Navigate to="signIn" />;
    }

    const enableResendButton = (): void => {
        setShowTimeout(false);
        setResendRestricted(false);
    };

    const pageTitle = i18n.t(`${nameSpace}.hiddenForgotPassword.codeSent`);

    return (
        <div className={styles['forgot-wrapper']}>
            <TabTitle title={pageTitle} />
            <div className={styles.title}>
                {pageTitle}
            </div>
            <div className={styles['link-text']}>
                {i18n.t(`${nameSpace}.hiddenForgotPassword.codeSentEmail`, { userName })}
            </div>
            <div>
                <OtpInput
                    containerStyle={classNames(styles['OTP-input'])}
                    inputStyle={classNames(
                        styles['code-input-box'],
                        { [styles['error-otp']]: !authSucceed && (attemptsRemain < 3) },
                    )}
                    inputType="tel"
                    renderInput={(props) => (
                        <input {...props} disabled={false} />
                    )}
                    value={codeValue}
                    onChange={setCodeValue}
                    numInputs={6}
                />
            </div>
            <AuthSubmitButton
                isLimitedWidth
                disabled={!checkIsFormValid()}
                onClick={doChangePassword}
                titleKey={`${nameSpace}.hiddenForgotPassword.signUp`}
                confirmUserLoading={confirmUserLoading}
            />
            <Button
                onClick={resendCode}
                className={styles['resend-code-button']}
                disabled={resendRestricted}
            >
                {i18n.t(`${nameSpace}.hiddenForgotPassword.resendCode`)}
            </Button>
            {showTimeout && (
                <Timer
                    className={classNames(styles['timer'], styles[i18n.dir()])}
                    countdownSeconds={RESEND_TIMEOUT_DEFAULT}
                    onTimeout={enableResendButton}
                    prefix={i18n.t('codeAuth.buttons.youCanResendIn')}
                />
            )}
            <BaseAuthLink href="/signUp">
                {i18n.t(`${nameSpace}.hiddenForgotPassword.wrongEmail`)}
            </BaseAuthLink>
        </div>
    );
};

export default memo(ConfirmUser);
