import React, { FC, memo } from 'react';

import { Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import EditPhone from './EditPhone';
import { PhoneIdentity } from '@/types/types';
import { SharedUserToDisplay } from '../../../../../stores';
// TODO: move styles from table to this component
import styles from '../ShareSettingsTable.module.scss';

const { Text } = Typography;

interface RecipientCellProps {
    cellValue: string;
    phoneInfo: PhoneIdentity;
    setPhone: (email: string, value: PhoneIdentity) => void;
    recipientForPhoneEditing: string;
    setRecipientForPhoneEditing: (value: string) => void;
    sharedUser: SharedUserToDisplay;
    fileOwnerId: string;
}

const RecipientCell: FC<RecipientCellProps> = ({
    cellValue,
    phoneInfo,
    setPhone,
    recipientForPhoneEditing,
    setRecipientForPhoneEditing,
    sharedUser,
    fileOwnerId,
}) => {
    const hasPhoneEditIcon = (sharedUser.userId !== fileOwnerId) && sharedUser.recipientType !== 'group';

    return (recipientForPhoneEditing === cellValue
        ? (
            <EditPhone
                prefix={phoneInfo?.prefix}
                phone={phoneInfo?.phone}
                isSearching={false}
                onSubmit={(val) => setPhone(cellValue, val)}
                onClose={() => setRecipientForPhoneEditing('')}
            />
        ) : (
            <Text
                delete={sharedUser.deleted}
                title={cellValue}
                className={styles.email}
            >
                {cellValue}
                {hasPhoneEditIcon && (
                    <PhoneOutlined
                        className={styles['edit-email']}
                        onClick={() => setRecipientForPhoneEditing(cellValue)}
                    />
                )}
            </Text>
        )
    );
};

export default memo(RecipientCell);
