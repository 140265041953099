import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import { LinkBasedOpenOptions } from '../../../../../../../../../config/openOptions';
import { FileAccessData } from '../../../../hooks';
import styles from './FileOpenOptions.module.scss';
import OpenFileButtonContainer from './OpenFileButtonContainer/OpenFileButtonContainer';

const nameSpace = 'recipientPage.filePopUp';

export interface FileOpenOptionsProps extends FileAccessData {
    allowedApps: LinkBasedOpenOptions[];
}

const FileOpenOptions: FC<FileOpenOptionsProps> = ({
    allowedApps,
    ...fileAccessData
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles['block-title']}>
                {t(`${nameSpace}.openOptions`)}
            </div>
            <OpenFileButtonContainer allowedApps={allowedApps} {...fileAccessData} />
        </div>
    );
};

export default memo(FileOpenOptions);
