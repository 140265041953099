import React, { CSSProperties, FC } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import type { SpinProps } from 'antd/lib/spin';
import classNames from 'classnames';

import './index.scss';

interface SpinnerProps extends Omit<SpinProps, 'loadingIndicator'> {
    iconSize?: number;
    hasIconTranslate?: boolean;
    fullHeight?: boolean;
    screenCentered?: boolean;
}

const SPIN_SIZE_DEFAULT = 24;

const getSpinStyles = (size: number, hasTranslate: boolean): {
    iconStyles: CSSProperties,
    iconWrapperStyles: CSSProperties,
} => {
    const spinSizePx = `${size}px`;
    const iconStyles: CSSProperties = {
        fontSize: size,
        width: spinSizePx,
        height: spinSizePx,
        margin: 0,
    };

    const iconWrapperStyles: CSSProperties = {
        width: spinSizePx,
        height: spinSizePx,
    };
    if (hasTranslate) {
        iconWrapperStyles.transform = 'translate(-50%, -50%)';
    }

    return { iconStyles, iconWrapperStyles };
};

const Spinner: FC<SpinnerProps> = ({
    spinning,
    size = 'default',
    children,
    tip,
    className,
    wrapperClassName,
    style,
    iconSize = SPIN_SIZE_DEFAULT,
    hasIconTranslate = true,
    fullHeight = false,
    screenCentered = false,
}) => {
    const wrapperClasses = classNames(
        wrapperClassName,
        'spx-spinner-wrapper',
        {
            'full-height': fullHeight,
            'screen-centered': screenCentered,
        },
    );

    const { iconStyles, iconWrapperStyles } = getSpinStyles(iconSize, hasIconTranslate);

    return (
        <Spin
            className={className}
            spinning={spinning}
            size={size}
            indicator={(
                <span style={iconWrapperStyles}>
                    <LoadingOutlined
                        spin
                        style={iconStyles}
                    />
                </span>
            )}
            tip={tip}
            wrapperClassName={wrapperClasses}
            style={style}
        >
            {children}
        </Spin>
    );
};

export default Spinner;
