import { useCallback, useState } from 'react';

import { SyncCallback } from '../../../../../../types/types';

export interface OTPState {
    readonly showOTP: boolean;
    readonly openOTP: SyncCallback;
    readonly closeOTP: SyncCallback;
}

const useOTPState = (): OTPState => {
    const [showOTP, setShowOTP] = useState<boolean>(false);

    const openOTP = (): void => {
        setShowOTP(true);
    };

    const closeOTP = useCallback((): void => {
        setShowOTP(false);
    }, []);

    return {
        showOTP,
        openOTP,
        closeOTP,
    };
};

export default useOTPState;
