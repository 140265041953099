type DictRecord = number | string | boolean | null;

export type Dict = Record<string, DictRecord>;

const dictToQuery = (dict: Dict): string => {
    const entries = Object.entries(dict);
    const resultList: string[] = entries.reduce<string[]>((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
            acc.push(`${key}=${encodeURIComponent(value)}`);
        }
        return acc;
    }, []);
    return resultList.join('&');
};

export default dictToQuery;
