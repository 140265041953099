import React, { FC, memo } from 'react';

import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { ModalContentProps } from '../../../Common/Modal';
import { SimpleCallback } from '../../../../types/types';
import { NAME_SPACE } from '../../constants';
import styles from './LockSignaturesModalContent.module.scss';

const nameSpace = `${NAME_SPACE}.lockChanges`;

interface LockSignaturesModalContentProps extends ModalContentProps {
    lockSignatures: () => Promise<boolean>;
    close: SimpleCallback;
}

const LockSignaturesModalContent: FC<LockSignaturesModalContentProps> = ({
    lockSignatures,
    close,
    setLoadingStatus,
}) => {
    const { t } = useTranslation();

    const handleLock = async (): Promise<void> => {
        setLoadingStatus(true);
        const isSucceed = await lockSignatures();
        setLoadingStatus(false);
        if (isSucceed) {
            close();
        } else {
            message.error(t(`${nameSpace}.cannotLock`));
        }
    };

    return (
        <div className={styles.container}>
            <span className={styles.title}>
                {t(`${nameSpace}.title`)}
            </span>
            <span className={styles.subtitle}>
                {t(`${nameSpace}.subtitle`)}
            </span>
            <div className={styles.footer}>
                <Button
                    onClick={close}
                    className={styles.cancel}
                >
                    {t('general.buttons.cancel')}
                </Button>
                <Button
                    onClick={handleLock}
                    className={styles.lock}
                >
                    {t(`${nameSpace}.lock`)}
                </Button>
            </div>
        </div>
    );
};

export default memo(LockSignaturesModalContent);
