import { toJS } from 'mobx';

import { SecuredFile } from '../../../types/types';
import { FilesForUpdate, UploadedFilesDict } from '../interfaces';

type UpdateAction = (fileId: string, isSilent?: boolean) => Promise<void>;

export const getFilesForUpdate = (
    currentList: SecuredFile[],
    uploadedFilesDict: UploadedFilesDict,
): FilesForUpdate => {
    const filesInList: SecuredFile[] = [];
    currentList.forEach((file) => {
        if (uploadedFilesDict[file.fid]) {
            filesInList.push(toJS(file));
        }
    });
    return {
        filesInList,
        filesOutOfListCount: Object.keys(uploadedFilesDict).length - filesInList.length,
    };
};

export const syncFetchedFilesWithUploaded = (
    fetchedFiles: SecuredFile[],
    uploadedFilesDict: UploadedFilesDict,
    updateFileAction: UpdateAction,
): SecuredFile[] => {
    const filesForUpdate: string[] = [];
    const newList: SecuredFile[] = fetchedFiles.map((file) => {
        const uploadedFile = uploadedFilesDict[file.fid];
        if (uploadedFile) {
            const { policy: uploadedFilePolicy } = uploadedFile;
            const { policy: fetchedFilePolicy } = file;
            if (uploadedFilePolicy && fetchedFilePolicy && (uploadedFilePolicy.id !== fetchedFilePolicy.id)) {
                filesForUpdate.push(file.fid);
                return { ...file, policy: { ...uploadedFilePolicy }, policy_id: uploadedFilePolicy.id };
            }
        }
        return file;
    });
    // file update calls is necessary to fetch actual file menu
    // TODO: probably it's not necessary because of frequent refresh
    Promise.all(filesForUpdate.map((fileId) => updateFileAction(fileId, true)));
    return newList;
};
