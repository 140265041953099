import { useRef } from 'react';

const useInstance = <T>(factory: () => T): T => {
    const instanceRef = useRef<T>(null);

    if (!instanceRef.current) {
        instanceRef.current = factory();
    }

    return instanceRef.current;
};

export default useInstance;
