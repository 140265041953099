import { KILOBYTE } from '@/consts';

const BYTE_UNITS = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
];

const bytesToText = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const sizeIndex = parseInt(String(Math.floor(Math.log(bytes) / Math.log(KILOBYTE))), 10);
    if (sizeIndex === 0) return `${bytes} ${BYTE_UNITS[sizeIndex]}`;
    return `${(bytes / (KILOBYTE ** sizeIndex)).toFixed(0)} ${BYTE_UNITS[sizeIndex]}`;
};

export default bytesToText;
