import dayjs from 'dayjs';

import { MILLISECONDS_IN_SECOND } from '@/consts';

const formatDate = (dateStr: string, isHour = true): string => {
    const dateObj = new Date(parseInt(dateStr, 10) * MILLISECONDS_IN_SECOND);
    const today = dayjs(dateObj).isSame(dayjs(), 'day');

    if (!today) {
        return dayjs(dateObj).format(`DD MMM[,] YYYY ${isHour ? 'HH:mm' : ''}`);
    }

    return `Today ${dayjs(dateObj).format('HH:mm')}`;
};

export default formatDate;
