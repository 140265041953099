import React, { FC, useCallback } from 'react';

import { Empty, Grid } from 'antd';
import { Column, Table } from 'react-virtualized';
import { observer } from 'mobx-react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import 'react-virtualized/styles.css';

import i18n from '@/content';
import ContextMenu from '../../Common/ContextMenu';
import {
    ActionsWithSkeleton,
    FileSizeCell,
    FileNameCell,
    LastUsed,
    Owner,
    PolicyTagWithSkeleton,
} from '../Columns';
import { CheckAll, CheckboxCell } from './Checkbox';
import ContextMenuContent from './ContextMenuContent';
import TableFooter from './TableFooter';
import TableRow from './TableRow';
import SortableHeader from './SortableHeader';
import {
    useCurrent, useMounted, useStores,
} from '../../hooks';
import { useDraggedFileContext } from '../FilesDragContext';
import {
    useContextMenu,
    useRowsSelection,
    useTableSize,
} from './hooks';
import { FileActionType } from '../FileAction/menuItems';
import {
    CellProps,
    RowMouseEvent,
    RowProps,
} from './interfaces';
import {
    getFileAccessAction,
    getVTableBody,
    onScrollFilesTable,
    getCellSizes,
} from './helpers';
import {
    AUTO_SCROLL_DELTA,
    MAX_SELECTED_ITEMS,
    TABLE_SIZES,
    THROTTLE_DELAY,
} from './constants';
import styles from './InfiniteScrollTable.module.scss';
import './reactVirtualizedOverride.scss';

const { useBreakpoint } = Grid;

const nameSpace = 'filesTable.headers';

export interface TableProps {
    handleFileAction: (action: FileActionType, fileId: string) => void;
    hasAccessInfoDrawer?: boolean,
}

const InfiniteScrollTable: FC<TableProps> = observer(({ handleFileAction, hasAccessInfoDrawer }) => {
    const screens = useBreakpoint();

    const isMountedRef = useMounted();

    const {
        filesListStore, userStore,
    } = useStores();
    const {
        currentFolderKey: storeFolderKey,
        filesToDisplay,
        hasPageToken,
        displayFilesType,
        isMyFiles,
        isShared,
        nextFiles,
        selectedItemsKeys,
        setCurrentSelectedFiles,
        setChosenTableFileId,
    } = filesListStore;
    const { userInformation } = userStore;
    const userName = userInformation.displayName;
    const hasFiles = !!filesToDisplay.length;

    const currentFolderKeyRef = useCurrent<string>(storeFolderKey);

    const { contextMenuState, onContextMenu } = useContextMenu(isMountedRef);

    const { containerRef, size } = useTableSize(hasPageToken);

    const cellSizes = getCellSizes(screens);

    const isSelectedItemsLimitReached = selectedItemsKeys.length < MAX_SELECTED_ITEMS;

    const showMoreFiles = useCallback(async () => {
        const vTableBody = getVTableBody(containerRef.current);
        const prevScrollHeight = vTableBody?.scrollHeight;
        await nextFiles();
        const isSameFolder = currentFolderKeyRef.current === storeFolderKey;
        if (isSameFolder && prevScrollHeight && isMountedRef.current) {
            const { scrollHeight: newScrollHeight, clientHeight } = vTableBody;
            /*
             * If we scroll to prevScrollHeight, we will see the top of the added records.
             * However, height of the current records could be small enough to 'overscroll' the table.
             * In this case we need to scroll at bottom with little delta.
             */
            const scrollTo = Math.min(prevScrollHeight, newScrollHeight - clientHeight - AUTO_SCROLL_DELTA);
            vTableBody.scroll({ top: scrollTo });
        }
    }, [storeFolderKey]);

    const {
        onRowSelect,
        selectedKeysSet,
        selectAll,
    } = useRowsSelection(selectedItemsKeys, setCurrentSelectedFiles, filesToDisplay);

    const activeDraggedItem = useDraggedFileContext();

    const isDragged = !!activeDraggedItem;

    const tableClasses = classNames(
        'files-virtual-table',
        styles['files-table'],
        { [styles['has-files']]: hasFiles },
    );

    return (
        <div
            className={classNames(
                styles['table-info-wrapper'],
                { [styles['opened-info-drawer']]: hasAccessInfoDrawer },
            )}
            ref={containerRef}
        >
            <Table
                // Temporary solution to prevent saving of scroll position
                key={storeFolderKey}
                onScroll={throttle(
                    (params) => onScrollFilesTable(params, showMoreFiles, hasPageToken),
                    THROTTLE_DELAY,
                )}
                onRowClick={({ event, rowData }: RowMouseEvent) => {
                    const clickableElement = (event.target as HTMLElement).closest('[data-clickable = true]');
                    if (!clickableElement && (isMyFiles || filesListStore?.currentFolder?.is_workspace)) {
                        setChosenTableFileId(rowData.fid);
                    }
                }}
                onRowRightClick={({ event, rowData }: RowMouseEvent) => {
                    onContextMenu(event, rowData);
                }}
                rowRenderer={({ className, rowData, ...rest }: RowProps) => {
                    const isSelected = selectedKeysSet.has(rowData.fid);
                    const isDropAllowed = isMyFiles
                        && isDragged
                        && rowData.is_folder
                        && (
                            activeDraggedItem.fid !== rowData.fid
                            && !(selectedKeysSet.has(activeDraggedItem.fid) && isSelected)
                        );

                    return (
                        <TableRow
                            isDragged={isDragged}
                            isDragAllowed={isMyFiles}
                            isDropAllowed={isDropAllowed}
                            className={className}
                            isSelected={isSelected}
                            rowData={rowData}
                            {...rest}
                        />
                    );
                }}
                noRowsRenderer={() => <Empty className={styles['empty-placeholder']} />}
                className={tableClasses}
                headerClassName={styles['table-header']}
                headerHeight={TABLE_SIZES.headerHeight}
                height={size.height}
                width={size.width}
                rowCount={filesToDisplay.length}
                rowGetter={({ index }) => filesToDisplay[index]}
                rowHeight={TABLE_SIZES.rowHeight}
            >
                <Column
                    dataKey="fid"
                    width={cellSizes.checkbox.width}
                    label=""
                    cellRenderer={({ rowData: { fid } }: CellProps) => (
                        <CheckboxCell fid={fid} value={selectedKeysSet.has(fid)} onChange={onRowSelect} />
                    )}
                    headerRenderer={() => (
                        <CheckAll
                            allLength={filesToDisplay.length}
                            selectedLength={selectedItemsKeys.length}
                            onChange={selectAll}
                        />
                    )}
                />
                <Column
                    label={<SortableHeader field="filename" />}
                    dataKey="filename"
                    className={styles['filename-col']}
                    headerClassName={styles['filename-header']}
                    {...cellSizes.filename}
                    cellRenderer={(
                        {
                            rowData: {
                                fid,
                                filename,
                                is_folder: isFolder,
                                menu,
                            },
                        }: CellProps,
                    ) => (
                        <FileNameCell
                            filename={filename}
                            fileId={fid}
                            isFolder={isFolder}
                            filesListType={displayFilesType}
                            currentFolderKey={storeFolderKey}
                            handleFileAction={handleFileAction}
                            fileAction={getFileAccessAction(menu)}
                        />
                    )}
                />
                {screens.sm && (
                    <Column
                        label={i18n.t(`${nameSpace}.policy`)}
                        dataKey="policy"
                        width={cellSizes.policy.width}
                        cellRenderer={({ rowData: { policy, fid } }: CellProps) => (
                            <PolicyTagWithSkeleton
                                title={policy?.title}
                                fid={fid}
                                handleMenuClick={!isShared && handleFileAction}
                            />
                        )}
                    />
                )}
                {!isMyFiles && screens.sm && (
                    <Column
                        label={i18n.t(`${nameSpace}.owner`)}
                        dataKey="owner"
                        width={cellSizes.owner.width}
                        cellRenderer={({ rowData: { owner } }: CellProps) => (
                            <Owner owner={owner} />
                        )}
                    />
                )}
                {screens.md && (
                    <Column
                        label={<SortableHeader field="last_used_time" />}
                        dataKey="last_used_time"
                        {...cellSizes.lastUsed}
                        cellRenderer={({
                            rowData: {
                                last_used_time: lastUsedTime,
                                last_used_by: lastUsedBy,
                            },
                        }: CellProps) => (
                            <LastUsed lastUsedTime={lastUsedTime} userName={userName} lastUsedBy={lastUsedBy} />
                        )}
                    />
                )}
                <Column
                    label={<SortableHeader field="file_size" />}
                    dataKey="file_size"
                    width={cellSizes.fileSize.width}
                    className={styles['file-size-cell']}
                    cellRenderer={({ rowData: { file_size: fileSize, is_folder } }: CellProps) => (
                        <FileSizeCell filesize={fileSize} isFolder={is_folder} />
                    )}
                />
                <Column
                    dataKey=""
                    className={styles['actions-cell']}
                    width={hasFiles ? cellSizes.actions.width : cellSizes.actionsEmpty.width}
                    cellRenderer={({ rowData: { menu, fid } }: CellProps) => (
                        isSelectedItemsLimitReached
                            ? (
                                <ActionsWithSkeleton
                                    filesListType={displayFilesType}
                                    fileId={fid}
                                    menu={menu}
                                    handleMenuClick={handleFileAction}
                                />
                            )
                            : <></>
                    )}
                />
            </Table>
            {hasPageToken && (
                <TableFooter onShowMoreClick={showMoreFiles} />
            )}
            <ContextMenu x={contextMenuState.x} y={contextMenuState.y} visible={contextMenuState.visible}>
                {contextMenuState.selectedFile && (
                    <ContextMenuContent
                        filesListType={displayFilesType}
                        menu={contextMenuState.selectedFile.menu}
                        fileId={contextMenuState.selectedFile.fid}
                        onFileActionClick={handleFileAction}
                    />
                )}
            </ContextMenu>
        </div>
    );
});

export default InfiniteScrollTable;
