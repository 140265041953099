import React, { FC } from 'react';

import VirtualList from 'rc-virtual-list';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import i18n from '@/content';
import CollapsibleWidget from '../CollapsibleWidget';
import UploadProgress from '../../../UploadDrawer/UploadProgress';
import UploadItem from '../../../UploadDrawer/UploadFiles/VirtualUploadList/UploadItem';
import { useSize, useStores, useUploadCompletion } from '../../../hooks';
import {
    FolderToDisplay,
    handleUploadedFileRemovingResult,
    RemoveResult,
    UploadedFile,
} from '../../../../stores';
import styles from './UploadingPopup.module.scss';

const UPLOADED_ITEM_HEIGHT_PX = 58;
const EXTRA_LIST_HEIGHT_PX = 20;

const UploadingPopup: FC = observer(() => {
    const { uploadFilesStore } = useStores();

    const {
        currentUploadingFile,
        removeUploadingItem,
        uploadedFilesToJS,
    } = uploadFilesStore;
    /*
    * Virtual list tries to read data from indexes, and for some reason it doesn't works with pure observables.
    * That's why we are using uploadedFilesToJS to render UI
    * */

    useUploadCompletion(uploadFilesStore);

    const removeFile = async (file: UploadedFile | FolderToDisplay): Promise<void> => {
        const item = file.isFolder
            ? uploadFilesStore.currentlyUploadingFolders.find(({ uid }) => uid === file.uid)
            : file;
        if (item) {
            const result: RemoveResult = await removeUploadingItem(item);
            const itemName: string = (item as FolderToDisplay).name || (item as UploadedFile).filename;
            handleUploadedFileRemovingResult(i18n.t.bind(i18n), itemName, result);
        }
    };

    const { t } = useTranslation();

    const { containerRef, size } = useSize<HTMLDivElement>();
    const listHeight = `${uploadedFilesToJS.length * UPLOADED_ITEM_HEIGHT_PX + EXTRA_LIST_HEIGHT_PX}px`;

    return (
        <CollapsibleWidget
            ref={containerRef}
            title={<UploadProgress />}
            renderChildren={() => (
                <VirtualList<UploadedFile | FolderToDisplay>
                    data={uploadedFilesToJS}
                    itemKey="uid"
                    height={size?.height}
                    itemHeight={UPLOADED_ITEM_HEIGHT_PX}
                    className={styles['virtual-list-height']}
                    style={{ height: listHeight }}
                >
                    {(file) => (
                        <UploadItem
                            {...file}
                            key={file.uid}
                            onRemove={removeFile}
                            foldedFiles={(file as FolderToDisplay).foldedFiles}
                        />
                    )}
                </VirtualList>
            )}
            renderChildrenInCollapsedMode={() => (
                (currentUploadingFile
                    ? (
                        <div className={styles['single-item-wrapper']}>
                            <UploadItem key={currentUploadingFile.uid} {...currentUploadingFile} />
                        </div>
                    )
                    : (
                        <div className={styles['uploading-done']}>
                            {t('uploadFiles.uploadingDone')}
                        </div>
                    )
                )
            )}
        />
    );
});

export default UploadingPopup;
