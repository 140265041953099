import React, { FC, memo } from 'react';

import i18n from '@/content';
import InfoTooltip from './InfoTooltip';
import styles from './InfoTooltip.module.scss';

interface InfoTooltipProps {
    i18nKey: string;
}

const InfoTooltipItem: FC<InfoTooltipProps> = ({ i18nKey }) => {
    const textContent: string = i18n.exists(i18nKey) ? i18n.t(i18nKey) : '';

    return textContent
        ? <InfoTooltip textContent={textContent} />
        : <span className={styles['empty-placeholder']} />;
};

export default memo(InfoTooltipItem);
