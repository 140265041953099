import React, { FC, memo } from 'react';

import ConnectButton from '../ConnectButton';
import { useGoogleLoginService } from '@/stores/UserStore/helpers'
import { ConnectExternalStorageProps } from '../interfaces';

const GoogleOauthButton: FC<ConnectExternalStorageProps> = ({
    responseFail, responseSuccess, onClick, isLoading = false,
}) => {
    const googleLogin = useGoogleLoginService(responseSuccess, responseFail);
    return (
        <ConnectButton
            oauthClickHandler={googleLogin}
            onClick={onClick}
            isLoading={isLoading}
            storageProvider="googledrive"
        />
    );
};

export default memo(GoogleOauthButton);