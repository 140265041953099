import splitEmail from './splitEmail';
import splitFilename from './splitFilename';

const MAX_LENGTH_DEFAULT = 30;
const TRIPLE_DOT = '...';

const getSlicingIndex = (maxLength: number, splittingIndex: number): number => {
    // prevent cases for get full value before dividing and add 3 dots
    const ellipsisLength = TRIPLE_DOT.length;
    return splittingIndex > maxLength - ellipsisLength
        ? maxLength - ellipsisLength
        : splittingIndex - ellipsisLength;
};

export const ellipsisFile = (filename: string, maxLength = MAX_LENGTH_DEFAULT): string => {
    if (filename.length <= maxLength) {
        return filename;
    }
    const { name, extension } = splitFilename(filename);
    const slicingIndex = getSlicingIndex(maxLength, name.length);
    return `${name.substring(0, slicingIndex)}${TRIPLE_DOT}${extension || ''}`;
};

const ellipsisString = (str: string, limit: number): string => (
    str.length <= limit ? str : `${str.slice(0, limit - TRIPLE_DOT.length)}${TRIPLE_DOT}`
);

export const ellipsisEmail = (email: string, maxLength = MAX_LENGTH_DEFAULT): string => {
    if (email.length <= maxLength) {
        return email;
    }
    const { username, domain } = splitEmail(email);
    /*
    * Because in most cases email contains '@' near to middle of the string,
    * we should ellipsis recipient name more strictly
    * */
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const emailPartMaxLength = Math.ceil(maxLength / 2);
    // Use ellipsisFile because ellipsis for multi-dot domain same as for multi-dot filename
    const domainWithEllipsis = ellipsisFile(domain, emailPartMaxLength);
    return `${ellipsisString(username, emailPartMaxLength)}${domainWithEllipsis}`;
};
