import React, { FC, useMemo, useEffect } from 'react';

import { Table } from 'antd';
import { PaperClipOutlined, FolderOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import zipWith from 'lodash/zipWith';

import i18n from '@/content';
import { getLinkData, CopyLinkButton } from '../../../Common/CopyLink';
import { useStores } from '../../../hooks';
import type { Recipient } from '@/types/types';
import { UploadedItem } from '../../../../stores';
import './index.scss';

const { Column } = Table;

interface MergedItem {
    key: string;
    recipient?: Recipient;
    uploadedItem?: UploadedItem;
}

const nameSpace = 'uploadFiles.successTable';

// We are using toJS() here to prevent warning for read data out ot index
export const createMergedList = (uploadedItems: UploadedItem[], recipientsList: Recipient[]): MergedItem[] => zipWith(
    toJS(uploadedItems),
    toJS(recipientsList),
    (uploadedItem: UploadedItem, recipient: Recipient): MergedItem => ({
        key: uploadedItem?.fid || recipient?.email,
        uploadedItem,
        recipient,
    }),
);

const SharedFilesTable: FC = observer(() => {
    const { sharedUsersStore, uploadFilesStore } = useStores();
    const { recipientsList, shareLinksToJS } = sharedUsersStore;
    const { uploadedFiles, successfullyUploadedItems } = uploadFilesStore;

    useEffect(() => {
        sharedUsersStore.startLinksPreloading({
            filesIds: uploadFilesStore.uploadedFileIds,
            mode: 'personal',
        });
        return () => {
            sharedUsersStore.cancelLinksPreloading();
        };
    }, []);

    const mergedItems = useMemo<MergedItem[]>(
        () => createMergedList(successfullyUploadedItems, recipientsList),
        [successfullyUploadedItems, recipientsList],
    );

    return (
        <div className="shared-files-table">
            <Table<MergedItem>
                pagination={false}
                dataSource={mergedItems}
                className="table-styles"
                rowKey="key"
            >
                <Column
                    title={i18n.t(`${nameSpace}.sharedFiles`, { filesNumber: uploadedFiles.length })}
                    dataIndex="uploadedItem"
                    width="260px"
                    render={(uploadedItem: UploadedItem) => (
                        uploadedItem && (
                            <div className="filename-cell" title={uploadedItem.filename}>
                                <span className="icon-wrapper">
                                    {uploadedItem.isFolder ? <FolderOutlined /> : <PaperClipOutlined />}
                                </span>
                                {uploadedItem.filename}
                            </div>
                        )
                    )}
                />
                <Column
                    title={i18n.t(`${nameSpace}.sharedWith`)}
                    dataIndex="recipient"
                    width="230px"
                    render={(recipient: Recipient) => {
                        if (recipient) {
                            const { email, role } = recipient;
                            const text = i18n.t(`${nameSpace}.recipient`, { email, role });
                            return (
                                <div className="recipient-cell" title={text}>
                                    {text}
                                </div>
                            );
                        }
                        return null;
                    }}
                />
                <Column
                    title={i18n.t(`${nameSpace}.copyLink`)}
                    dataIndex="recipient"
                    width="76px"
                    align="center"
                    render={(recipient: Recipient) => {
                        if (recipient) {
                            const { email, id, type } = recipient;
                            const identity = type === 'user' ? email : id;
                            const { link, isLinkLoading } = getLinkData(
                                shareLinksToJS,
                                identity,
                            );

                            return (
                                <CopyLinkButton
                                    link={link}
                                    isLinkLoading={isLinkLoading}
                                    linkKey={identity}
                                    className="copy-button"
                                />
                            );
                        }
                        return null;
                    }}
                />
            </Table>
        </div>
    );
});

export default SharedFilesTable;
