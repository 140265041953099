import React, {
    CSSProperties,
    FC,
    forwardRef,
    memo,
} from 'react';

import classNames from 'classnames';

import styles from './Icon.module.scss';

interface AntLikeIconProps {
    className?: string;
    style?: CSSProperties;
}

const withIconWrapper = (Component: FC): FC<AntLikeIconProps> => memo(forwardRef<HTMLElement, AntLikeIconProps>(
    ({ className, ...rest }, ref) => (
        <span ref={ref} className={classNames(styles.icon, className)} {...rest}><Component /></span>
    ),
));

export default withIconWrapper;
