import {
    RefObject,
    useRef,
    useMemo,
    useState,
} from 'react';

import { Policy } from '../../../../types/types';
import { getPolicyAggregate, PolicyAggregate } from '../../../utils';

const EMPTY_POLICY: Policy = {
    id: '',
    title: '',
    isMfa: false,
    active: false,
    deletable: false,
    settings: { access: [] },
};

interface FilePolicySettings {
    policy: Policy;
    policyAggregate: PolicyAggregate;
    policyAggregateRef: RefObject<PolicyAggregate>;
    setPolicy: (newValue: Policy) => void;
    setEmptyPolicy: () => void;
}

const usePolicySettings = (initValue: Policy = null): FilePolicySettings => {
    const [policy, setPolicy] = useState<Policy>(initValue);

    const policyAggregate: PolicyAggregate = useMemo<PolicyAggregate>(
        () => (policy ? getPolicyAggregate(policy) : {}),
        [policy],
    );

    const policyAggregateRef = useRef<PolicyAggregate>(policyAggregate);
    policyAggregateRef.current = policyAggregate;

    const setEmptyPolicy = (): void => setPolicy(EMPTY_POLICY);

    return {
        policy,
        setPolicy,
        setEmptyPolicy,
        policyAggregate,
        policyAggregateRef,
    };
};

export default usePolicySettings;
