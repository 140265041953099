import React, { Component } from 'react';

import { Modal as AntModal } from 'antd';
import classNames from 'classnames';

import ResultWithIcon from '../ResultWithIcon';
import Spinner from '../Spin';
import { SimpleCallback } from '@/types/types';
import {
    ModalHeader,
    ModalFooter,
    ModalPortalAnchor,
    SlotPortalContextProvider,
} from './ModalSlots';
import { DEFAULT_MODAL_WIDTH, WRAP_CLASSNAME } from './constants';
import './index.scss';

export * from './constants'; 
export { ModalFooter, ModalHeader };

const TIMEOUT_TO_CLOSE_MS = 6_000;

export interface ModalContentProps {
    resetParentModalState?: () => void;
    setLoadingStatus?: (enabled: boolean, text?: string) => void;
    setErrorStatus?: (enabled: boolean, text?: string) => void;
    setWidth?: (newWidth: number) => void;
    setAdditionalWrapClassName?: (newClassName: string) => void;
}

export interface InitModalStyle {
    initWidth?: number;
    additionalWrapClassName?: string;
}

export interface DynamicStyles {
    width?: boolean;
    wrapClassName: boolean;
}

interface ModalProps {
    isOpen: boolean;
    closeModal: (result?: boolean) => void;
    destroyOnClose?: boolean;
    closable?: boolean;
    children?: React.ReactElement;
    dynamicStyles?: DynamicStyles;
    initStyle?: InitModalStyle;
    loading?: boolean;
    centered?: boolean;
}

interface StylesState {
    width: number;
    wrapClassName: string;
}

interface ModalState {
    isError: { enabled: boolean, text: string };
    isLoading: { enabled: boolean, text: string };
    style: StylesState;
}

/*
 * TODO: provide ability to use additional styles as props;
 * Modal hiding destroy children even if destroyOnClose prop is false
 */
class Modal extends Component<ModalProps, ModalState> {
    private timeOut: NodeJS.Timeout;

    private mounted: boolean = true;

    constructor(props: ModalProps) {
        super(props);
        const { initStyle } = this.props;
        this.state = {
            isLoading: { enabled: false, text: '' },
            isError: { enabled: false, text: '' },
            style: {
                width: initStyle?.initWidth || DEFAULT_MODAL_WIDTH,
                wrapClassName: classNames(WRAP_CLASSNAME,
                    { [initStyle?.additionalWrapClassName]: !!initStyle?.additionalWrapClassName }),
            },
        };
    }

    componentWillUnmount(): void {
        this.mounted = false;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

    setLoadingStatus = (enabled: boolean, text = ''): void => {
        this.doForMounted(() => {
            this.setState({
                isLoading: { enabled, text },
            });
        });
    }

    setErrorStatus = (enabled: boolean, text = ''): void => {
        this.doForMounted(() => {
            this.setState({
                isError: { enabled, text },
            });

            this.timeOut = setTimeout(() => {
                this.closeModal();
            }, TIMEOUT_TO_CLOSE_MS);
        });
    }

    setWidth = (newWidth: number = DEFAULT_MODAL_WIDTH): void => {
        this.doForMounted(() => {
            this.setState((prevState) => ({ style: { ...prevState.style, width: newWidth } }));
        });
    }

    setAdditionalWrapClassName = (newClassName = ''): void => {
        this.doForMounted(() => {
            const wrapClassName = `${WRAP_CLASSNAME} ${newClassName || ''}`;
            this.setState((prevState) => ({ style: { ...prevState.style, wrapClassName } }));
        });
    }

    resetStyles = ({ width, wrapClassName }: DynamicStyles): void => {
        this.doForMounted(() => {
            if (width) {
                this.setWidth();
            }
            if (wrapClassName) {
                this.setAdditionalWrapClassName();
            }
        });
    }

    resetStateBeforeClose = (timeOut = null): void => {
        const { dynamicStyles } = this.props;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        if (timeOut) {
            clearTimeout(timeOut);
        }
        this.doForMounted(() => {
            this.setState({
                isLoading: { enabled: false, text: '' },
                isError: { enabled: false, text: '' },
            });
            if (dynamicStyles) {
                this.resetStyles(dynamicStyles);
            }
        });
    }

    closeModal = (timeOut = null): void => {
        const { closeModal: closeModalProp } = this.props;
        closeModalProp();
        this.resetStateBeforeClose(timeOut);
    }

    onSlotMount = (nodeId: string): void => {
        requestAnimationFrame(() => {
            const node = document.getElementById(nodeId);
            node.style.display = 'block';
        });
    }

    private doForMounted(callback: SimpleCallback): void {
        if (this.mounted) {
            callback();
        }
    }

    render(): JSX.Element {
        const { isLoading, isError, style: { width, wrapClassName } } = this.state;
        const {
            isOpen, children, destroyOnClose = false, closable = true, loading = false,
            centered = true,
        } = this.props;
        const isSpinning = (isLoading.enabled || loading) && isOpen && !isError.enabled;
        const hasChildren = isOpen && !isError.enabled;

        return (
            <AntModal
                open={isOpen}
                wrapClassName={wrapClassName}
                footer={null}
                width={width}
                destroyOnClose={destroyOnClose}
                centered={centered}
                closable={closable}
                maskClosable={isError.enabled}
                onCancel={this.closeModal}
            >
                {isError.enabled ? (
                    <ResultWithIcon
                        status="error"
                        title={isError.text || 'There was a server error'}
                    />
                ) : (
                    <Spinner
                        fullHeight
                        tip={isLoading.text}
                        spinning={isSpinning}
                        hasIconTranslate={false}
                    >
                        <SlotPortalContextProvider dispatchMount={this.onSlotMount}>
                            <ModalPortalAnchor nodeType="headerId" className="modal-header" />
                            <div className="modal-main">
                                {hasChildren
                                    && React.cloneElement(children, {
                                        setLoadingStatus: this.setLoadingStatus,
                                        setErrorStatus: this.setErrorStatus,
                                        setWidth: this.setWidth,
                                        setAdditionalWrapClassName: this.setAdditionalWrapClassName,
                                        resetParentModalState: this.resetStateBeforeClose,
                                    })}
                            </div>
                            <ModalPortalAnchor nodeType="footerId" className="modal-footer" />
                        </SlotPortalContextProvider>
                    </Spinner>
                )}
            </AntModal>
        );
    }
}

export default Modal;
