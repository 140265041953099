import React, { FC, memo } from 'react';

import i18n from '@/content';
import SimpleCell from '../SimpleCell';
import { tryCopyToClipboard } from '../../../utils';

const nameSpace = 'auditLogs.tableColumns';

const LAST_TWELVE_SYMBOLS_SLICE = -12;

const prepareFileIdForAudits = (fileId: string, originId: string): string => {
    let result = '';
    if (originId || fileId) {
        if (originId) {
            result = originId;
        } else if (fileId) {
            result = fileId.slice(LAST_TWELVE_SYMBOLS_SLICE);
        }
    }
    return result;
};

interface FileIdCellProps {
    fileId?: string;
    originId?: string;
}

const FileIdCell: FC<FileIdCellProps> = ({ fileId = '', originId = '' }) => {
    const onCopyId = (id: string): void => {
        tryCopyToClipboard(id, i18n.t(`${nameSpace}.fileId`));
    };

    return <SimpleCell text={prepareFileIdForAudits(fileId, originId)} onClick={() => onCopyId(fileId || originId)} />
};

export default memo(FileIdCell);
