import { Auth } from 'aws-amplify';

import config, { HAS_API_PROXY } from '../../config/env';
import { BASEURL } from '../endpoints';
import { AuthHeader, OrganizationIDHeader } from '../interfaces';
// import getOrganizationIdHeader from './getOrganizationIdHeader';

/* eslint-disable camelcase */
interface AmplifyAPIEndpointConfig {
    name: string;
    endpoint: string;
    region: string;
    custom_header?: () => Promise<Record<string, string>> | Record<string, string>;
}

const getAmplifyAPIEndpoints = (): AmplifyAPIEndpointConfig[] => {
    const endpoints: AmplifyAPIEndpointConfig[] = [
        {
            name: BASEURL.backend(),
            endpoint: config.API_URL,
            region: 'eu-central-1',
        },
        {
            name: BASEURL.localhost(),
            endpoint: 'https://localhost:3001',
            region: 'eu-central-1',
        },
    ];
    if (HAS_API_PROXY) {
        endpoints.push({
            name: BASEURL.backendProxy(),
            endpoint: config.API_PROXY.API_URL,
            region: 'eu-central-1',
            custom_header: async (): Promise<Partial<AuthHeader & OrganizationIDHeader>> => {
                try {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken();
                    return {
                        Authorization: `Bearer ${token.getJwtToken()}`,
                        SpecterxUserId: token.payload.sub,
                    };
                } catch {
                    throw new Error('baseHeaders error');
                }
            },
        });
    }
    return endpoints;
};

export default getAmplifyAPIEndpoints;
