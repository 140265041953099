import { SyncCallback } from '../../../types/types';

export interface Message<Type extends string, Payload = void> {
    type: Type;
    payload?: Payload;
}

export type MessageListener<Type extends string, Payload = void> = (
    message: Message<Type, Payload>,
) => void | Promise<void>;

export class TabsMessenger<Topics extends string> {
    private readonly topicMap: Map<Topics, BroadcastChannel>;

    constructor(topics: Topics[]) {
        this.topicMap = new Map<Topics, BroadcastChannel>(
            topics.map<[Topics, BroadcastChannel]>((topic) => [topic, new BroadcastChannel(topic)]),
        );
    }

    subscribe<Type extends string, Payload = void>(
        topic: Topics,
        listener: MessageListener<Type, Payload>,
    ): SyncCallback {
        const onMessage = (event: MessageEvent<Message<Type, Payload>>): void => {
            listener(event.data);
        };
        this.topicMap.get(topic).addEventListener('message', onMessage);
        return () => {
            this.topicMap.get(topic).removeEventListener('message', onMessage);
        };
    }

    post<Type extends string, Payload = void>(topic: Topics, message: Message<Type, Payload>): void {
        this.topicMap.get(topic).postMessage(message);
    }
}
