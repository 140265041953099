import { MutableRefObject, useRef } from 'react';

const useCurrent = <T>(value: T): MutableRefObject<T> => {
    const ref = useRef<T>(value);

    ref.current = value;

    return ref;
};

export default useCurrent;
