import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import { useStores } from '../../../../hooks';
import styles from './GroupButtons.module.scss';

const nameSpace = 'customGroupsEditor.buttons';

interface GroupButtonsProps {
    onCreate: (isDuplicate: boolean) => void;
    onSave: () => void;
    onCancel: () => void;
    isCreateDisabled: boolean;
    isSaveDisabled: boolean;
}

const GroupButtons: FC<GroupButtonsProps> = observer(({
    onCreate,
    onSave,
    onCancel,
    isCreateDisabled,
    isSaveDisabled,
}) => {
    const {
        usersListStore: {
            newGroupsMode,
        },
    } = useStores();

    return (
        <div className={styles['groups-settings-buttons']}>
            {newGroupsMode && (
                <Button
                    type="primary"
                    className={styles['primary-button']}
                    onClick={() => onCreate(false)}
                    disabled={!isCreateDisabled}
                >
                    {i18n.t(`${nameSpace}.createGroup`)}
                </Button>
            )}
            {!newGroupsMode && (
                <Button
                    type="primary"
                    className={styles['primary-button']}
                    onClick={onSave}
                    disabled={!isSaveDisabled}
                >
                    {i18n.t(`${nameSpace}.saveChanges`)}
                </Button>
            )}
            <Button
                type="link"
                className={styles['secondary-button']}
                onClick={onCancel}
            >
                {i18n.t(`${nameSpace}.cancel`)}
            </Button>
        </div>
    );
});

export default GroupButtons;
