import React, { createContext, FC, ReactElement } from 'react';

import { AllStores } from '../../../stores';

export const StoreOverrideContext = createContext<Partial<AllStores>>({});

interface StoreOverrideProviderProps {
    children: ReactElement | ReactElement[];
    stores: Partial<AllStores>;
}

export const StoreOverrideProvider: FC<StoreOverrideProviderProps> = ({
    stores,
    children,
}) => (
    <StoreOverrideContext.Provider value={stores}>
        {children}
    </StoreOverrideContext.Provider>
);
