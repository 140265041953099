import { useMemo } from 'react';

import { useStores } from '../../hooks';
import { OpenOptions, OPTIONS_LIST, OPTIONS_LIST_WITH_API_PROXY } from '../../../config/openOptions';
import { FileMenu, FileOptionType } from '../../../types/types';
import { FilesListType } from '../../../stores';

const HIDE_ON_SHARED_OPTIONS: FileOptionType[] = [
    'revoke',
];

const getOpenWith = (options: OpenOptions[], HAS_API_PROXY: boolean): OpenOptions[] => {
    let result = [];
    if (options) {
        const allowedMethods: string[] = HAS_API_PROXY ? OPTIONS_LIST_WITH_API_PROXY : OPTIONS_LIST;
        result = options.filter((value) => allowedMethods.includes(value));
    }
    result.reverse();
    return result;
};

export const useOpenWithToDisplay = (
    openWith: OpenOptions[],
    HAS_API_PROXY: boolean,
): OpenOptions[] => useMemo<OpenOptions[]>(
    () => getOpenWith(openWith, HAS_API_PROXY),
    [openWith, HAS_API_PROXY],
);

export const useOptionsToDisplay = (
    options: FileOptionType[],
    isShared: boolean,
): FileOptionType[] => useMemo<FileOptionType[]>(
    () => {
        const reversedList = [...options].reverse();
        return (
            isShared
                ? reversedList.filter((option) => !HIDE_ON_SHARED_OPTIONS.includes(option))
                : reversedList
        );
    },
    [options, isShared],
);

interface MenuToDisplay {
    openWith: OpenOptions[];
    options: FileOptionType[];
    hasShare: boolean;
}

/*
* Note: you need to wrap component into mobx.observer HOC in order to use this hook
* */
export const useMenu = (menu: Partial<FileMenu>, filesListType?: FilesListType): MenuToDisplay => {
    const {
        authSettingsStore: { HAS_API_PROXY },
    } = useStores();

    const isShared = filesListType === 'sharedFiles';

    const openWith = useOpenWithToDisplay(menu?.open_with || [], HAS_API_PROXY);
    const options = useOptionsToDisplay(menu?.options || [], isShared);

    return {
        options,
        openWith,
        hasShare: menu?.share,
    };
};
