import type { FC } from 'react';

import { ConfigProvider, Menu, MenuProps } from 'antd';

import { HEXToRGBA } from '@/components/utils';
import { COLORS } from '@/theme';

const StyledMenu: FC<MenuProps> = (props) => (
    <ConfigProvider
        theme={{
            components: {
                Menu: {
                    groupTitleColor: HEXToRGBA(COLORS.primaryNew, '50%'),
                    itemActiveBg: HEXToRGBA(COLORS.cta, '25%'),
                    itemColor: HEXToRGBA(COLORS.primaryNew, '50%'),
                    itemHoverBg: HEXToRGBA(COLORS.cta, '5%'),
                    itemHoverColor: COLORS.primaryNew,
                    itemSelectedBg: 'transparent',
                    itemSelectedColor: COLORS.primaryNew,
                    subMenuItemBg: 'transparent',
                },
            },
        }}
    >
        <Menu {...props} />
    </ConfigProvider>
);

export default StyledMenu;
