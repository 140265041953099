import {
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';

import type { FormInstance } from 'antd/lib/form';

import { useMounted, useSingleCallAPI } from '@/components/hooks';
import AuthSettingsStore from '../../../../../stores/AuthSettingsStore';
import type { ISingleCallAPI } from '@/api/SingleCallAPI';
import type { PhoneIdentity } from '@/types/types';
import { EMAIL_REGEXP } from '@/regExp';
import { getMyPhone, getPhoneByEmail } from '@/api';
import { captureErrorForSentry, checkIs404 } from '../../../../utils';

const getUserPhone = async (API: ISingleCallAPI, isAuthorized: boolean, email: string): Promise<PhoneIdentity> => {
    if (isAuthorized) {
        return getMyPhone(API);
    }
    return getPhoneByEmail(API, email);
};

export type OnFetchCode = (isSucceed: boolean) => void;

interface UsePhoneLoaderParams {
    authSettingsStore: AuthSettingsStore;
    forceDisabled: boolean;
    isAuthorized: boolean;
    email: string;
    onFetchCode: OnFetchCode;
    formRef: MutableRefObject<FormInstance>;
}

interface UsePhoneLoaderResult {
    isLoading: boolean;
    isDisabled: boolean;
    isFilled: boolean;
}

const usePhoneLoader = ({
    authSettingsStore,
    forceDisabled,
    isAuthorized,
    email,
    onFetchCode,
    formRef,
}: UsePhoneLoaderParams): UsePhoneLoaderResult => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isFilled, setIsFilled] = useState<boolean>(false);

    const asyncTaskIdRef = useRef<number>(null);

    const isMountedRef = useMounted();

    const singleCallAPI = useSingleCallAPI(authSettingsStore, false);

    const setInitFormValues = (): void => {
        formRef.current.setFieldsValue({ prefix: { iso2: 'il', prefix: '972', dialCode: '972' }, phone: '' });
    };

    useEffect(() => {
        if (!forceDisabled) {
            const fetchPhone = async (): Promise<void> => {
                asyncTaskIdRef.current += 1;
                const thisAsyncTaskId = asyncTaskIdRef.current;
                setInitFormValues();
                setIsLoading(true);
                let isSucceed = false;
                try {
                    const { phone, prefix } = await getUserPhone(singleCallAPI, isAuthorized, email);
                    isSucceed = true;
                    if (isMountedRef.current) {
                        formRef.current.setFieldsValue(
                            { phone, prefix: { dialCode: prefix, prefix } },
                        );
                        setIsDisabled(true);
                        setIsFilled(true);
                    }
                } catch (error) {
                    if (!checkIs404(error)) {
                        captureErrorForSentry(error, 'OtpAuth.PhoneInput.fetchPhone');
                    }
                    if (isMountedRef.current) {
                        setIsDisabled(false);
                    }
                } finally {
                    if (isMountedRef.current) {
                        // Prevent hiding loader if new effect already running
                        if (thisAsyncTaskId === asyncTaskIdRef.current) {
                            setIsLoading(false);
                        }
                        onFetchCode(isSucceed);
                    }
                }
            };
            if (EMAIL_REGEXP.test(email)) {
                fetchPhone();
            } else {
                onFetchCode(false);
                singleCallAPI.cancel();
                setInitFormValues();
                setIsDisabled(true);
            }
        }
        // forceDisabled and isAuthorized wasn't included into dependencies list because we need to listen only email
    }, [email]);

    return {
        isFilled,
        isDisabled,
        isLoading,
    };
};

export default usePhoneLoader;
