export enum OpenOptions {
    googledrive = 'googledrive',
    office365 = 'office365',
    download = 'download',
    viewer = 'viewer',
    webdav = 'webdav',
}

export type LinkBasedOpenOptions = OpenOptions.googledrive | OpenOptions.office365 | OpenOptions.download;

export const OPTIONS_LIST: OpenOptions[] = Object.values(OpenOptions);

export const OPTIONS_LIST_WITH_API_PROXY: OpenOptions[] = [
    OpenOptions.download,
    OpenOptions.viewer,
    OpenOptions.webdav,
];
