import React, { FC } from 'react';

import classNames from 'classnames';

import { useDragAndDroppable } from '../../FilesDragContext';
import { RowProps } from '../interfaces';
import getVirtualRowProps, { VirtualRowProps } from './getVirtualRowProps';
import styles from './TableRow.module.scss';

/*
* Forked original implementation
* https://github.com/bvaughn/react-virtualized/blob/master/source/Table/defaultRowRenderer.js
* */

interface TableRowProps extends Omit<RowProps, 'key'> {
    isDragged: boolean;
    isDragAllowed: boolean;
    isDropAllowed: boolean;
    isSelected: boolean;
}

const TableRow: FC<TableRowProps> = ({
    className,
    columns,
    style,
    rowData,
    isDragged,
    isDragAllowed,
    isDropAllowed,
    isSelected,
    ...eventProps
}) => {
    const virtualRowProps: VirtualRowProps = getVirtualRowProps({ ...eventProps, rowData });

    const {
        attributes,
        listeners,
        isOver,
        setNodeRef,
    } = useDragAndDroppable({
        isFolder: rowData.is_folder,
        isDropAllowed,
        isDragAllowed,
        itemKey: rowData.itemKey,
        fileId: rowData.fid,
        filename: rowData.filename,
    });

    return (
        <div
            {...virtualRowProps}
            {...listeners}
            {...attributes}
            ref={setNodeRef}
            className={classNames(
                className,
                styles['file-row'],
                {
                    [styles['row-selected']]: isSelected,
                    [styles['drop-restricted']]: isDragged && !isDropAllowed,
                    [styles['current-drop-area']]: isOver,
                },
            )}
            role="row"
            style={style}
        >
            {columns}
        </div>
    );
};

export default TableRow;
