import React, { FC, memo } from 'react';

import i18n from '@/content';
import {
    Button, Empty,
} from 'antd';
import { useStores } from '../hooks';
import './index.scss';
import '../Common/Tabs/antOverride.scss';

const WorkspacesPage: FC = () => {
    const { appStore } = useStores();

    const openWorkspace = (): void => {
        appStore.setIsWorkspaceDrawerOpen(true);
    };

    return (
        <div className="workspace-wrapper">
            <Empty description={false} />
            <div>{i18n.t('workspaceDrawer.noWorkspaces')}</div>
            <Button className="request-files-button" onClick={openWorkspace}>
                {i18n.t('workspaceDrawer.requestFiles')}
            </Button>
        </div>
    );
};

export default memo(WorkspacesPage);
