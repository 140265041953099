import React, { FC, ReactElement } from 'react';

import { Skeleton } from 'antd';

import { PublicHeader } from '../../../Common/PublicComponents';
import LanguageSwitcher from '../LanguageSwitcher';
import styles from './Header.module.scss';

interface HeaderProps {
    showSkeleton?: boolean;
    children?: ReactElement;
}

const Header: FC<HeaderProps> = ({ showSkeleton = false, children }) => (
    <PublicHeader>
        <div className={styles['extra-content-container']}>
            {children}
            {showSkeleton
                ? <Skeleton.Button active />
                : <LanguageSwitcher />}
        </div>
    </PublicHeader>
);

export default Header;
