import { REQUEST_RECIPIENT_DOWNLOAD_APPROVAL } from '@/consts';
import PolicyStore from '../../../stores/PolicyStore';
import { Policy } from '../../../types/types';

interface Params {
    policyId: string;
    policyStore: PolicyStore;
}

export const checkHasConfirmSetting = (policy: Policy): boolean => {
    const accessSettings = policy.settings?.access || [];
    return accessSettings.find(({ id }) => id === REQUEST_RECIPIENT_DOWNLOAD_APPROVAL)?.value as boolean;
};

export const checkHasConfirmPolicy = async ({
    policyId,
    policyStore,
}: Params): Promise<boolean> => {
    const policy = await policyStore.tryFindPolicy(policyId);
    return policy
        ? checkHasConfirmSetting(policy)
        : false;
};
