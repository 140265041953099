import { FOLDER_ID_QUERY_PARAM } from '@/consts';
import { eqUserIdentities } from '../../utils';
import { AppRoutes } from '../../../config/appRoutes';
import { SharedFile } from '../../../stores';
import { UserIdentity } from '../../../types/types';

const getPathToSharedFolder = (currentUserIdentity: UserIdentity, file: SharedFile): string => {
    const path = eqUserIdentities(currentUserIdentity, {
        id: file.owner,
        email: file.owner_email,
    }) ? AppRoutes.myFiles : AppRoutes.sharedWithMe;
    return `${path}?${FOLDER_ID_QUERY_PARAM}=${file?.file_id}`;
};

export default getPathToSharedFolder;
