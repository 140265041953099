import React, { FC } from 'react';

import { Divider } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../../hooks';
import styles from './UploadIntoFolderHint.module.scss';

const UploadIntoFolderHint: FC = observer(() => {
    const { t } = useTranslation();

    const { filesListStore: { currentFolder } } = useStores();
    const isWorkspace = currentFolder?.is_workspace;
    const folderName = currentFolder?.filename;

    return (
        <div className={styles['info-container']}>
            {isWorkspace && (
                <span className={styles['upload-into-folder-hint']}>
                    {t('uploadFiles.uploadInfoText', { folderType: t(`general.specterxCommon.workspace`).toLowerCase() })}
                </span>
            )}
            {isWorkspace && <Divider className={styles.divider} />}
            <span
                className={styles['upload-into-folder-hint']}
            >
                {t('uploadFiles.uploadFolderInfoText', { folderName })}
            </span>
            {!isWorkspace && <Divider className={styles.divider} />}
        </div>
    );
});

export default UploadIntoFolderHint;
