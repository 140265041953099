import React, {
    forwardRef, memo, MutableRefObject,
} from 'react';

import { Trans, useTranslation } from 'react-i18next';
import type { FormInstance } from 'antd/lib/form';

import PhoneComp from '@/components/Common/PhoneComp';
import { useStores } from '@/components/hooks';
import type { CodeAuthenticatorChildrenProps } from '@/components/Common/CodeAuthenticator/interfaces';
import appConfig from '@/config/env';
import { SPX_SUPPORT_EMAIL } from '@/consts';

import usePhoneLoader, { OnFetchCode } from './usePhoneLoader';
import styles from './PhoneInput.module.scss';

const nameSpace = 'recipientPage.otpAuth.mfaFirstStep';

interface PhoneInputProps extends CodeAuthenticatorChildrenProps {
    isAuthorized: boolean;
    email: string;
    onFetchCode: OnFetchCode;
    onChange: () => void;
}

const PhoneInput = forwardRef<FormInstance, PhoneInputProps>(({
    forceDisabled,
    isAuthorized,
    email,
    onFetchCode,
    onChange,
}, formRef) => {
    const { authSettingsStore } = useStores();

    const { t } = useTranslation();

    const {
        isLoading,
        isFilled,
        isDisabled,
    } = usePhoneLoader({
        authSettingsStore,
        email,
        formRef: formRef as MutableRefObject<FormInstance>,
        forceDisabled,
        isAuthorized,
        onFetchCode,
    });

    return (
        <div className={styles['phone-input-wrapper']}>
            <PhoneComp
                UIType="OTP"
                isMfa
                isLoading={isLoading}
                disablePhone={forceDisabled || isDisabled || appConfig.DISABLE_SELF_PHONE_FILLING}
                onChange={onChange}
            />
            {isFilled && (
                <span className={styles['not-your-number']}>
                    <Trans t={t} i18nKey={`${nameSpace}.notYourNumber`}>
                        Not your number? Contact
                        <a className={styles.underline} href={`mailto:${SPX_SUPPORT_EMAIL}`}>
                            support@specterx.com
                        </a>
                        for assistance
                    </Trans>
                </span>
            )}
        </div>
    );
});

export default memo(PhoneInput);
