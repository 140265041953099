import React, { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';

import CollapsibleWidget from '../CollapsibleWidget';
import DownloadItem from './DownloadItem';
import nameSpace from './nameSpace';

interface BatchDownloadWidgetProps {
    wrapperClassName?: string;
}

const BatchDownloadWidget: FC<BatchDownloadWidgetProps> = observer(({ wrapperClassName }) => {
    const { batchDownloadStore } = useStores();
    const {
        isWidgetOpen, downloadsList, clear, downloadedItemsCount,
    } = batchDownloadStore;

    const { t } = useTranslation();

    const closeWidget = (): void => {
        clear();
    };

    const widgetTitle = t(`${nameSpace}.title`, { filesCount: downloadsList.length, downloadedItemsCount });
    /* eslint-disable camelcase */
    return isWidgetOpen && (
        <CollapsibleWidget
            wrapperClassName={wrapperClassName}
            closeWidget={closeWidget}
            title={widgetTitle}
            renderChildren={() => downloadsList.map(({
                id,
                status,
                message,
                link,
                name,
                downloadStatus,
            }) => (
                <DownloadItem
                    key={id}
                    id={id}
                    status={status}
                    link={link}
                    message={message}
                    name={name}
                    downloadStatus={downloadStatus}
                />
            ))}
        />
    );
});

export default BatchDownloadWidget;
