import React, {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useMatch } from 'react-router';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';

import i18n from '@/content';
import { MFA_ATTEMPTS } from '@/consts';
import TabTitle from '../Common/TabTitle';
import Spinner from '../Common/Spin';
import ViewerErrorResult from './ViewerErrorResult';
import MfaComp from '../AppAuthenticator/MfaComp';
import AttemptsRemainWarning from './AttemptsRemainWarning';
import DownloadFile from './DownloadFile';
import OpenWidget from './OpenWidget';
import { useStores } from '../hooks';
import { BASEURL, ENDPOINTS, getFile } from '../../api';
import appConfig from '../../config/env';
import { IS_MOBILE } from '@/config/browserEnv';
import { OpenOptions } from '@/config/openOptions';
import { OfficeViewerResponse, OpenFileResponse, SecuredFile } from '@/types/types';
import {
    ErrorTypes,
    IframeParams,
    PermissionsConfig,
} from './interfaces';
import {
    preventTimeout,
    captureServerError,
    splitFilename,
} from '../utils';
import {
    checkEditPermissions,
    getErrorType,
    showDownloadErrorMessage,
} from './helpers';
import { DOWNLOAD_FATAL_ERRORS, MOBILE_VIEW_UNSUPPORTED_EXTENSIONS } from './constants';
import styles from './OpenViewer.module.scss';
import { VIEWER_ROUTE_TEMPLATE } from '@/config/appRoutes';

type AccessType = OpenOptions.viewer | OpenOptions.download;

const OpenViewer: FC = observer(() => {
    const [iframeParams, setIframeParams] = useState<IframeParams>(null);
    // TODO: migrate logic on FileAccessStore
    const [isMfa, setIsMfa] = useState<boolean>(false);
    const [isMfaVisible, setIsMfaVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<ErrorTypes>(null);
    const [attemptsCounter, setAttemptsCounter] = useState<number>(MFA_ATTEMPTS);
    const [file, setFile] = useState<SecuredFile>(null);

    const nextActionAfterMFARef = useRef<AccessType>(OpenOptions.viewer);

    const {
        authSettingsStore,
        userStore,
    } = useStores();

    const permissionsConfig = useMemo<PermissionsConfig>(() => {
        const editAllowed = checkEditPermissions(userStore.currentUserEmail, file);
        return {
            editAllowed,
            downloadAllowed: !appConfig.viewer?.HIDE_DOWNLOAD_BUTTON
                && file?.menu?.options?.includes(OpenOptions.download),
        };
    }, [file]);

    const { API, skipMFA } = authSettingsStore;

    const match = useMatch(VIEWER_ROUTE_TEMPLATE);
    const fileId = match.params.id;

    const closeMfa = (): void => {
        setIsMfaVisible(false);
        setIsMfa(false);
        setAttemptsCounter(MFA_ATTEMPTS);
    };

    const takeAwayAttempt = (): void => {
        setIsMfaVisible(true);
        setAttemptsCounter((prevState) => prevState - 1);
    };

    const showMFA = (): void => {
        setIsMfa(true);
        setIsMfaVisible(true);
    };

    const handleFetchFileError = (
        error: unknown,
        onError: (errorType: ErrorTypes) => void,
        mfaCode?: string,
    ): void => {
        const newErrorType = getErrorType(error as AxiosError);
        const shouldTakeAwayAttempt = newErrorType === 'mfaFail' && attemptsCounter > 1;
        if (newErrorType === 'mfaRequired' && !skipMFA) {
            showMFA();
        } else {
            console.log('could not load file', error);
            captureServerError(error as Error, 'OpenViewer.handleFetchFileError');
            if (shouldTakeAwayAttempt) {
                takeAwayAttempt();
            } else {
                onError(newErrorType);
                if (mfaCode) {
                    closeMfa();
                }
            }
        }
    };

    const fetchAccessLink = async <Response, >(
        appName: AccessType,
        setLoader: Dispatch<SetStateAction<boolean>>,
        onSuccess: (response: Response) => void,
        onError: (errorType: ErrorTypes) => void,
        mfaCode = '',
    ): Promise<void> => {
        setLoader(true);
        const requestBody = { app_name: appName, auth_code: mfaCode };
        try {
            const requestCallback = async (): Promise<Response> => API.post(BASEURL.backend(),
                ENDPOINTS.openFile(fileId),
                { body: requestBody });
            const response: Response = await preventTimeout(requestCallback);
            onSuccess(response);
            if (mfaCode) {
                closeMfa();
            }
        } catch (error) {
            handleFetchFileError(error, onError, mfaCode);
        } finally {
            setLoader(false);
        }
    };

    const fetchIframeParams = async (mfaCode = ''): Promise<void> => {
        await fetchAccessLink<IframeParams>(
            OpenOptions.viewer,
            setIsLoading,
            setIframeParams,
            setErrorType,
            mfaCode,
        );
    };

    const onDownloadError = (error: ErrorTypes): void => {
        if (DOWNLOAD_FATAL_ERRORS.includes(error)) {
            setErrorType(error);
        } else {
            showDownloadErrorMessage(error, i18n.t.bind(i18n));
        }
    };

    const fetchDownloadLink = async (mfaCode = ''): Promise<string> => {
        let downloadLink: string;
        await fetchAccessLink<OpenFileResponse>(
            OpenOptions.download,
            setIsDownloadButtonLoading,
            ({ link }: OpenFileResponse) => {
                downloadLink = link;
            },
            onDownloadError,
            mfaCode,
        );
        return downloadLink;
    };

    const executeDownload = useCallback(async (): Promise<string> => {
        nextActionAfterMFARef.current = OpenOptions.download;
        return fetchDownloadLink();
    }, []);

    const onMfaClick = useCallback(async (mfaCode: string): Promise<void> => {
        setIsMfaVisible(false);
        if (nextActionAfterMFARef.current === OpenOptions.download) {
            await fetchDownloadLink(mfaCode);
        } else {
            await fetchIframeParams(mfaCode);
        }
    }, [attemptsCounter]);

    const onMetaDataLoaded = (fileData: SecuredFile): void => {
        const { filename } = fileData;
        setFile(fileData);
        if (filename && IS_MOBILE && !appConfig.viewer?.CANCEL_MOBILE_VIEWER_FILE_EXTENSION_CHECK) {
            const { extension } = splitFilename(filename);
            if (MOBILE_VIEW_UNSUPPORTED_EXTENSIONS.includes(extension.toLowerCase())) {
                setErrorType('mobileView');
            }
        }
    };

    const fetchFileData = async (): Promise<void> => {
        const fileData = await getFile(API, fileId);
        onMetaDataLoaded(fileData);
    };

    useEffect(() => {
        if (fileId) {
            (async (): Promise<void> => {
                setIsLoading(true);
                const requestCallback = async (): Promise<IframeParams> => API.post(BASEURL.backend(),
                    ENDPOINTS.openFile(fileId),
                    { body: { app_name: OpenOptions.viewer } });
                try {
                    const [iframeViewerParams] = await Promise.all([
                        preventTimeout(requestCallback), fetchFileData(),
                    ]);
                    setIframeParams(iframeViewerParams);
                    if ((iframeViewerParams as OfficeViewerResponse).viewUrl) {
                        setIsLoading(false);
                    }
                } catch (error) {
                    setIsLoading(false);
                    handleFetchFileError(error, setErrorType);
                }
            })();
        }
    }, []);

    const hasDownload = permissionsConfig.downloadAllowed;
    const policyId = file?.policy?.id;

    return (
        <div className={styles['viewer-wrapper']}>
            <TabTitle title={i18n.t('viewer.tabTitle')} />
            {errorType
                ? (
                    <ViewerErrorResult
                        errorType={errorType}
                        ownerEmail={file?.owner_email}
                        itemType={file?.is_folder ? 'folder' : 'file'}
                    />
                )
                : (
                    <Spinner
                        tip={i18n.t('general.waitMessages.preparingFile')}
                        size="large"
                        spinning={isLoading}
                        wrapperClassName={styles['spinner-wrapper']}
                    >
                        {isMfa && (
                            <Modal
                                open={isMfaVisible}
                                centered
                                footer={null}
                                maskClosable={false}
                                onCancel={closeMfa}
                            >
                                <>
                                    {attemptsCounter < MFA_ATTEMPTS && (
                                        <AttemptsRemainWarning attemptsCounter={attemptsCounter} />
                                    )}
                                    <MfaComp onMfaClick={onMfaClick} />
                                </>
                            </Modal>
                        )}
                        {hasDownload && (
                            <DownloadFile
                                runDownload={executeDownload}
                                isLoading={isDownloadButtonLoading}
                                ownerEmail={file?.owner_email}
                                filename={file?.filename}
                                filePolicyId={policyId}
                            />
                        )}
                        <OpenWidget
                            isEditable={permissionsConfig.editAllowed}
                            fileId={fileId}
                            filePolicyId={policyId}
                            setIsLoading={setIsLoading}
                            iframeParams={iframeParams}
                            PDFViewerClassName={styles['pdf-viewer']}
                        />
                    </Spinner>
                )}
        </div>
    );
});

export default OpenViewer;
