import React, { FC, memo, ReactElement } from 'react';

import i18n, { I18Payload } from '@/content';
import { START_SIGN_IN_IMMEDIATELY, FEDERATED_PROVIDER, PUBLIC_ASSETS } from '@/consts';
import { SSOTypes } from '@/config/env';
import { IS_INSIDE_IFRAME } from '@/config/browserEnv';
import styles from './FederatedButton.module.scss';

interface FederatedButtonProps {
    type: SSOTypes;
    doFederatedSignIn: (type: SSOTypes) => void | Promise<void>;
    isSignIn?: boolean;
}

const signInNameSpace = 'signIn.signInWith';
const signUpNameSpace = 'signUp.signUpWith';

const getI18nPayload = (type: SSOTypes, isSignIn: boolean): I18Payload => (
    [isSignIn ? signInNameSpace : signUpNameSpace, { type: `$t(federatedProviders.${type})` }]
);

const FederatedButton: FC<FederatedButtonProps> = ({
    type, doFederatedSignIn, isSignIn = false,
}) => {
    const onClick = (): void => {
        localStorage.setItem(FEDERATED_PROVIDER, type);
        doFederatedSignIn(type);
    };

    const content: ReactElement = (
        <>
            <img
                className={styles['social-img']}
                src={`${PUBLIC_ASSETS}/icons/${type}-i.png`}
                alt={type}
            />
            <span>{i18n.t(...getI18nPayload(type, isSignIn))}</span>
        </>
    );

    return IS_INSIDE_IFRAME
        ? (
            <a
                href={`${window.location.href}&${START_SIGN_IN_IMMEDIATELY}=${type}`}
                target="_top"
                className={styles['login-button']}
            >
                {content}
            </a>
        )
        : (
            <button
                type="button"
                className={styles['login-button']}
                onClick={onClick}
            >
                {content}
            </button>
        );
};

export default memo(FederatedButton);
