import { SPXAPI } from '../interfaces';
import { BASEURL, ENDPOINTS } from '../endpoints';

const verifyAccess = async (API: SPXAPI, fileId: string, code?: string): Promise<void> => API.post(
    BASEURL.backend(),
    ENDPOINTS.verifyAccess(fileId),
    { body: { auth_code: code } },
);

export default verifyAccess;
