import React, { FC } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SelectRightOut } from '../../../../../Common/Icons';
import { useStores } from '@/components/hooks';
import { getLinkToViewer } from '@/components/utils';

import FileView from './FileView';
import OverlayActionButton from './OverlayActionButton';
import { useFilesActionsContext } from '../hooks/useFilesActionContext';
import styles from './FilesCarousel.module.scss';

const nameSpace = 'recipientPage.carousel';

const FilesCarousel: FC = observer(() => {
    const { t, i18n } = useTranslation();

    const {
        filesAccessStore: {
            selectedAccessFileId,
            selectedItemAccessTypesState,
        },
        recipientPageStore: {
            setFullScreen,
            setCollapsed,
        },
    } = useStores();

    const { selectFile } = useFilesActionsContext();

    const closeFile = (): void => {
        setCollapsed(false);
        selectFile(null);
        setFullScreen(false);
    };

    const hasResult = !!selectedItemAccessTypesState?.viewer?.result;

    return (
        <div className={classNames(styles.container, styles[i18n.dir()])}>
            <OverlayActionButton
                onClick={closeFile}
                icon={<CloseOutlined data-testid="recipientPage_Viewer_close" />}
                tooltipText={t(`${nameSpace}.close`)}
                buttonClassname={styles.close}
            />
            {hasResult && (
                <OverlayActionButton
                    onClick={() => window.open(getLinkToViewer(selectedAccessFileId))}
                    icon={<SelectRightOut data-testid="recipientPage_fullScreen" />}
                    tooltipText={t(`${nameSpace}.fullScreen`)}
                    buttonClassname={styles['full-screen']}
                />
            )}
            {/* It's necessary for correct refresh of cached Viewers */}
            <FileView key={selectedAccessFileId} />
        </div>
    );
});

export default FilesCarousel;
