import { CSSProperties } from 'react';

import { Modifier } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';

export enum TABLE_SIZES {
    headerHeight = 40,
    rowHeight = 45,
    minHeight = 400,
}

export enum CELLS_SIZES {
    checkboxCellFlexBasis = 30,
    fileNameFlexBasis = 0,
    fileNameFlexGrow = 2,
    fileNameFlexShrink = 2,
    fileNameMinWidth = 100,
    policyFlexBasis = 120,
    policyFlexBasisLg = 200,
    ownerFlexBasis = 140,
    lastUsedFlexBasisLg = 250,
    lastUsedFlexBasisMd = 180,
    lastUsedFlexShrink = 3,
    fileSizeFlexBasis = 70,
    actionsFlexBasisLg = 150,
    actionsFlexBasisMd = 100,
    actionsEmptyFlexBasis = 50,
}

export enum ChildTypes {
    policy = 'policy',
    shareSettings = 'shareSettings',
}

export const THROTTLE_DELAY = 400;
export const AUTO_SCROLL_DELTA = 20;
export const MAX_SELECTED_ITEMS = 2;

export const DRAG_OVERLAY_MODIFIERS: Modifier[] = [snapCenterToCursor];
/**
 * This property allow mouse wheel scroll while dragging
 * */
export const DRAG_OVERLAY_STYLE: CSSProperties = { pointerEvents: 'none' };
