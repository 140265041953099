import {
    ChangeEvent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    Button, Radio, RadioChangeEvent,
} from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
    PROTECT_ON_DOWNLOAD,
    REQUIRED_EMAIL_VERIFICATION,
    REQUIRED_PHONE_VERIFICATION,
} from '@/consts';
import { CodeAuthenticator, ITimeoutCleaner, StatusMessage } from '../../../Common/CodeAuthenticator';
import StepBox from '../../../Common/CodeAuthenticator/StepBox';
import OTPEmailInput from '../../../Common/OTPEmailInput';
import OTPLastStep from './OTPLastStep';
import PhoneInput from './PhoneInput';
import {
    EMAIL_OTP_LENGTH,
    getEmail,
    getPhoneValues,
    MFA_CODE_LENGTH,
    onSendCodeError,
    RESEND_TIMEOUT,
    useEmailOTPFlow,
    useSecondStepTitle,
    useStores,
} from '../../../hooks';
import { BASEURL, ENDPOINTS } from '../../../../api';
import { OpenOptions } from '@/config/openOptions';
import { PhoneIdentity, SimpleCallback } from '@/types/types';
import { getCognitoUserToken, PolicyAggregate } from '../../../utils';
import { OTPTypes } from '../interfaces';
import { AuthFlowType } from './interfaces';
import { getAuthFlowType, getPhoneNumberDisplay } from './helpers';
import {
    EMAIL_AUTH_FLOWS,
    MAP_AUTH_TYPE_TO_PHONE_FIRST_STEP,
    nameSpace,
    PHONE_AUTH_FLOWS,
    STEPS_COUNT_MIN,
} from './constants';
import './index.scss';

interface OTPAuthProps<OpenFileResult> {
    recpEmail: string;
    OTPType: OTPTypes;
    setIsLoading: (value: boolean) => void;
    closeOTP: SimpleCallback;
    onSuccess: SimpleCallback;
    openFile: (code: string) => Promise<OpenFileResult>;
    onMFASuccess: (result: OpenFileResult) => void;
    policyAggregate: PolicyAggregate;
    hasBackButton?: boolean;
    hideTitleOnMobile?: boolean;
}

export type CodeSendingMethod = 'phone' | 'voice';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const OTPAuth = observer(<OpenFileResult, >({
    recpEmail,
    OTPType,
    onSuccess,
    setIsLoading,
    closeOTP,
    policyAggregate,
    openFile,
    onMFASuccess,
    hasBackButton = true,
    hideTitleOnMobile = false,
}: OTPAuthProps<OpenFileResult>): ReactElement => {
    const [isEmailAuthSucceed, setIsEmailAuthSucceed] = useState<boolean>(false);
    const [isPhoneAuthSucceed, setIsPhoneAuthSucceed] = useState<boolean>(false);
    const [phoneFirstStepMessages, setPhoneFirstStepMessages] = useState<StatusMessage[]>([]);
    const [isAlreadyAuthenticated, setIsAlreadyAuthenticated] = useState<boolean>(false);
    const [isPhoneReceivedFromBE, setIsPhoneReceivedFromBE] = useState<boolean>(false);

    const { t } = useTranslation();

    const { authSettingsStore, userStore } = useStores();

    const {
        checkAuthCode,
        email,
        emailAuthFirstStepMessages,
        isMountedRef,
        onChangeEmail,
        onEmailRetryLimitReached,
        onSendEmailError,
        resendTimerRef: resendEmailTimerRef,
        secondStepTitle: emailAuthSecondStepTitle,
        sendEmailAuthCode,
        sendAuthCode,
    } = useEmailOTPFlow(
        t,
        authSettingsStore,
        userStore,
        recpEmail,
    );

    const openFileResultRef = useRef<OpenFileResult>(null);
    const resendPhoneTimerRef = useRef<ITimeoutCleaner>(null);
    const [codeSendingMethod, setCodeSendingMethod] = useState<CodeSendingMethod>('phone');

    const [
        setShowPhoneIntoSecondStepTitle,
        setNewSecondStepTitle,
        phoneSecondStepTitle,
    ] = useSecondStepTitle(
        '',
        MFA_CODE_LENGTH,
        t,
        `${nameSpace}.mfaSecondStep`,
        codeSendingMethod,
    );

    const isFolder = OTPType === 'folder';
    const isDownloadType = OTPType === OpenOptions.download;

    const hasProtectedPolicy = !!policyAggregate?.[PROTECT_ON_DOWNLOAD];
    const hasPhoneVerification = !!policyAggregate?.[REQUIRED_PHONE_VERIFICATION];
    const hasEmailVerification = !!policyAggregate?.[REQUIRED_EMAIL_VERIFICATION];

    const authFlowType: AuthFlowType = getAuthFlowType({
        hasPhoneVerification,
        hasEmailVerification,
        isAlreadyAuthenticated,
    });

    const hasPhoneCodeFlow = PHONE_AUTH_FLOWS.includes(authFlowType);
    const isEmailSignIn = EMAIL_AUTH_FLOWS.includes(authFlowType);

    useEffect(() => {
        setIsAlreadyAuthenticated(userStore.isUserSetUp);
    }, []);

    const initEmailInputValue = useMemo(
        () => ({ email: email || recpEmail || '' }),
        [recpEmail, email],
    );

    const { API, skipMFA } = authSettingsStore;

    // TODO: provide way how to solve in case of multiple files
    const showLastStepPrecondition = hasProtectedPolicy
        && isDownloadType;

    const startLoading = (): void => {
        setIsLoading(true);
    };

    const stopLoading = (): void => {
        setIsLoading(false);
    };

    const handleMFARetryLimitReached = (): void => {
        setPhoneFirstStepMessages([]);
        setShowPhoneIntoSecondStepTitle(false);
    };

    const handleCheckCodeSuccess = (): void => {
        const isFinish = !showLastStepPrecondition
            && (isEmailSignIn
                ? !hasPhoneCodeFlow
                : true);
        if (isFinish) {
            onSuccess();
        } else if (isEmailSignIn) {
            setIsEmailAuthSucceed(true);
        } else {
            setIsPhoneAuthSucceed(true);
        }
    };

    const checkCode = async (code: string): Promise<void> => {
        await checkAuthCode(code, startLoading, stopLoading, handleCheckCodeSuccess);
    };

    const sendMFAPhoneCode = async (phoneIdentity: PhoneIdentity): Promise<void> => {
        const token = await getCognitoUserToken();
        await API.post(
            BASEURL.backend(),
            ENDPOINTS.getPhoneCode(),
            {
                headers: { Authorization: token },
                body: phoneIdentity,
            },
        );
    };

    const sendAuthPhoneCode = async (identity: PhoneIdentity): Promise<void> => {
        const phoneIdentity = isPhoneReceivedFromBE ? null : identity;
        await sendAuthCode(email, codeSendingMethod, phoneIdentity);
    };

    const onSendPhoneCode = async (phoneIdentity: PhoneIdentity): Promise<void> => {
        setPhoneFirstStepMessages([]);
        setShowPhoneIntoSecondStepTitle(false);
        if (userStore.isUserSetUp) {
            await sendMFAPhoneCode(phoneIdentity);
        } else {
            await sendAuthPhoneCode(phoneIdentity);
        }
        if (isMountedRef.current) {
            setPhoneFirstStepMessages([{ message: `${nameSpace}.mfaFirstStep.notReceived` }]);
            setNewSecondStepTitle(getPhoneNumberDisplay(phoneIdentity));
        }
    };

    const handleSendPhoneCodeError = (error: unknown): void => {
        onSendCodeError(error, setPhoneFirstStepMessages);
    };

    const handleCheckMFAPhoneCodeSuccess = (result: OpenFileResult): void => {
        setIsPhoneAuthSucceed(true);
        if (!showLastStepPrecondition) {
            onMFASuccess(result);
        } else {
            openFileResultRef.current = result;
        }
    };

    const checkMFAPhoneCode = async (code: string): Promise<void> => {
        startLoading();
        try {
            const link = await openFile(code);
            if (isMountedRef.current) {
                handleCheckMFAPhoneCodeSuccess(link);
            }
        } catch (error) {
            if (isMountedRef.current) {
                throw error;
            }
        } finally {
            stopLoading();
        }
    };

    const checkPhoneCode = async (code: string): Promise<void> => {
        if (authFlowType === 'phoneAuth') {
            await checkCode(code);
        } else {
            await checkMFAPhoneCode(code);
        }
    };

    const resetPhoneTimer = (): void => {
        resendPhoneTimerRef.current?.clear();
    };

    const onChangeEmailIntoPhoneAuthFlow = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChangeEmail(event);
        resetPhoneTimer();
    }, []);

    const onChangePhone = useCallback(() => {
        resetPhoneTimer();
    }, []);

    const showPhoneAuthenticator = userStore.isUserSetUp
        ? (hasPhoneCodeFlow
            && !skipMFA
            && (authFlowType !== 'emailAndPhone' || isEmailAuthSucceed))
        : !isEmailSignIn;

    const phoneAuthStartingStep = MAP_AUTH_TYPE_TO_PHONE_FIRST_STEP[authFlowType];

    const showLastStep = showLastStepPrecondition && (hasPhoneCodeFlow ? isPhoneAuthSucceed : isEmailAuthSucceed);
    const lastStepNumber = phoneAuthStartingStep + STEPS_COUNT_MIN;

    const onLastStep = useCallback(() => {
        if (hasPhoneCodeFlow && authFlowType !== 'phoneAuth') {
            onMFASuccess(openFileResultRef.current);
        } else {
            onSuccess();
        }
    }, [authFlowType]);

    const handleCodeSendingMethodChange = (e: RadioChangeEvent): void => {
        const { value } = e.target;
        setCodeSendingMethod(value);
    };

    const OPTIONS = [
        {
            label: t(`${nameSpace}.options.SMS`),
            value: 'phone',
        },
        {
            label: t(`${nameSpace}.options.PhoneCall`),
            value: 'voice',
        },
    ];

    return (
        <div className={classNames('shared-file-otp')}>
            <div className="steps-wrapper">
                <p className={classNames('title', { 'hide-on-mobile': hideTitleOnMobile })}>
                    {t(`${nameSpace}.title`)}
                </p>
                <div className="all-steps-wrapper">
                    {isEmailSignIn && (
                        <CodeAuthenticator<string>
                            ref={resendEmailTimerRef}
                            firstStepTitle={t(`${nameSpace}.firstStep.title`)}
                            firstStepMessages={emailAuthFirstStepMessages}
                            identityType="email"
                            initIdentityValues={initEmailInputValue}
                            getIdentity={getEmail}
                            sendCode={sendEmailAuthCode}
                            onSendCodeError={onSendEmailError}
                            secondStepTitle={emailAuthSecondStepTitle}
                            codeLength={EMAIL_OTP_LENGTH}
                            resendTimeout={RESEND_TIMEOUT}
                            checkCode={checkCode}
                            onRetryLimitReached={onEmailRetryLimitReached}
                            authSucceed={isEmailAuthSucceed}
                            render={(forceDisabled) => (
                                <OTPEmailInput forceDisabled={forceDisabled} email={email} onChange={onChangeEmail} />
                            )}
                        />
                    )}
                    {showPhoneAuthenticator && (
                        <CodeAuthenticator<PhoneIdentity>
                            ref={resendPhoneTimerRef}
                            firstStepTitle={t(`${nameSpace}.mfaFirstStep.title`)}
                            firstStepMessages={phoneFirstStepMessages}
                            identityType="phone"
                            initIdentityValues={authFlowType === 'phoneAuth' ? initEmailInputValue : null}
                            getIdentity={getPhoneValues}
                            sendCode={onSendPhoneCode}
                            onSendCodeError={handleSendPhoneCodeError}
                            secondStepTitle={phoneSecondStepTitle}
                            codeLength={MFA_CODE_LENGTH}
                            resendTimeout={RESEND_TIMEOUT}
                            checkCode={checkPhoneCode}
                            onRetryLimitReached={handleMFARetryLimitReached}
                            authSucceed={isPhoneAuthSucceed}
                            startingStep={phoneAuthStartingStep}
                            renderExtraStep={authFlowType === 'phoneAuth'
                                ? (forceDisabled) => (
                                    <StepBox
                                        isSendingStep
                                        disabled={isPhoneAuthSucceed}
                                        stepNumber={1}
                                        title={t(`${nameSpace}.firstStep.title`)}
                                    >
                                        <OTPEmailInput
                                            isSingle
                                            forceDisabled={forceDisabled}
                                            email={email}
                                            onChange={onChangeEmailIntoPhoneAuthFlow}
                                        />
                                    </StepBox>
                                )
                                : null}
                            renderWithRef={(ref, forceDisabled) => (
                                <div className={classNames('email-input-container', { disabled: isPhoneAuthSucceed })}>
                                    <PhoneInput
                                        onChange={onChangePhone}
                                        forceDisabled={forceDisabled}
                                        isAuthorized={userStore.isUserSetUp}
                                        email={email}
                                        onFetchCode={setIsPhoneReceivedFromBE}
                                        ref={ref}
                                    />
                                </div>
                            )}
                            renderRadioButtons={() => (
                                authFlowType !== 'emailAndPhone' &&
                                    <Radio.Group
                                        buttonStyle="outline"
                                        size="large"
                                        options={OPTIONS}
                                        value={codeSendingMethod}
                                        optionType="button"
                                        onChange={handleCodeSendingMethodChange}
                                    />
                            )}
                        />
                    )}

                    {showLastStep && <OTPLastStep onFinish={onLastStep} stepNumber={lastStepNumber} />}
                </div>

            </div>

            {hasBackButton && (
                <div className="backward-container">
                    <Button
                        className="backward-button"
                        onClick={closeOTP}
                    >
                        {t('general.buttons.back')}
                    </Button>
                </div>
            )}
        </div>
    );
});

export default OTPAuth;
