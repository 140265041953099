import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';
import { message } from 'antd';

import i18n from '@/content';
import AuthSettingsStore from '../../AuthSettingsStore';
import CancellableAPI from '../../../api/CancellableAPI';
import CreateFolderService from './CreateFolderService';
import FoldersTree from './FoldersTree';
import {
    DeferredFileToFolder,
    PolicySimpleWithMFA,
    UploadedFolderInfo,
    UploadedFolderNode,
    UploadedRootFolder,
} from '../interfaces';
import { captureErrorForSentry } from '../../../components/utils';
import { partialUpdateUploadedItem } from '../helpers';

interface BufferRecord {
    readonly resolve: (spxFolderId: string) => void;
    readonly reject: (error: unknown) => void;
}

type OnInsert = (uid: string, folderInfo: UploadedFolderInfo) => void;

type TryAttachFuturePolicy = (uid: string, fileId: string) => void | Promise<void>;

const createRequestKey = (treeId: string, folderId: string): string => `${treeId}-${folderId}`;

const FOLDER_PREFIX = 'folder_';

const createTreeId = (firstFileUID: string): string => `${FOLDER_PREFIX}${firstFileUID}`;

class FolderUploader {
    @observable private rootFolders: UploadedRootFolder[] = [];

    private readonly createFolderService: CreateFolderService;

    private foldersTreesList: FoldersTree[] = [];

    private readonly requestsBuffer: Map<string, BufferRecord[]> = new Map<string, BufferRecord[]>();

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly onInsert: OnInsert;

    private readonly tryAttachFuturePolicy: TryAttachFuturePolicy;

    constructor(
        authSettingsStore: AuthSettingsStore,
        onInsert: OnInsert,
        tryAttachFuturePolicy: TryAttachFuturePolicy,
    ) {
        this.createFolderService = new CreateFolderService(authSettingsStore);
        this.authSettingsStore = authSettingsStore;
        this.onInsert = onInsert;
        this.tryAttachFuturePolicy = tryAttachFuturePolicy;
        makeObservable(this);
    }

    @computed
    get currentlyUploadingFolders(): UploadedRootFolder[] {
        return this.rootFolders;
    }

    @computed
    get successfullyUploadedFolders(): UploadedRootFolder[] {
        return this.rootFolders.filter(({ status }) => status === 'done');
    }

    @computed
    get rootsInProgress(): UploadedRootFolder[] {
        return this.rootFolders.filter(({ status }) => status === 'uploading');
    }

    createNewTrees(files: DeferredFileToFolder[]): void {
        const treesDict: Record<string, FoldersTree> = {};
        files.forEach(({ file, folder }) => {
            const { parentFolder, pathToRoot } = folder;
            const rootName: string = pathToRoot ? pathToRoot.split('/')[0] : parentFolder;
            let tree: FoldersTree = treesDict[rootName];
            if (!tree) {
                tree = new FoldersTree(
                    rootName,
                    { id: rootName, name: rootName },
                    { fid: '', status: 'uploading' },
                    createTreeId(file.uid),
                );
                treesDict[rootName] = tree;
            }
            const folderId = tree.tryInsertFile(file.uid, file.name, parentFolder, pathToRoot);
            this.onInsert(file.uid, folderId ? { treeId: tree.id, folderId } : null);
        });
        const trees = Object.values(treesDict);
        this.foldersTreesList.push(...trees);
        const newRoots = trees.map<UploadedRootFolder>(({ id, root }) => ({
            treeId: id,
            filename: root.value.name,
            isFolder: true,
            status: 'uploading',
            uid: id,
            expanded: false,
        }));
        this.setRootFolders([...this.rootFolders, ...newRoots]);
    }

    expandFolder(value: boolean, uid: string): void {
        const arr = [...this.rootFolders];
        const folderItem = this.rootFolders.findIndex((item) => item.uid === uid);
        if (folderItem !== -1) {
            arr[folderItem].expanded = value;
        }
        this.setRootFolders(arr);
    }

    async waitForBEFolder(treeId: string, folderId: string, myFilesParentFolderId: string): Promise<string> {
        const tree: FoldersTree = this.foldersTreesList.find(({ id }) => id === treeId);
        const folderNode: UploadedFolderNode = tree?.find(folderId);
        if (!folderNode) {
            throw Error(`No folder ${folderId} into ${treeId} tree`);
        }
        const specterxFolderId = folderNode.payload.fid;
        if (specterxFolderId) {
            return specterxFolderId;
        }
        const requestKey = createRequestKey(treeId, folderId);
        const isRootCreatingRequired = this.tryInitRootCreating(tree, myFilesParentFolderId);
        if (!isRootCreatingRequired) {
            this.tryCreateBEFolder(requestKey, tree, folderNode, myFilesParentFolderId);
        }
        return this.pushRequestIntoWaitingBuffer(requestKey);
    }

    clear(): void {
        this.foldersTreesList = [];
        this.setRootFolders([]);
        this.requestsBuffer.clear();
        this.createFolderService.clear();
    }

    removeFile(treeId: string, folderId: string, uid: string): void {
        const tree: FoldersTree = this.foldersTreesList.find(({ id }) => id === treeId);
        const node: UploadedFolderNode = tree?.find(folderId);
        if (node) {
            tree.setNodeChildren(node.key, node?.children.filter(
                (child) => child.type === 'node' || child.value.id !== uid,
            ));
        }
    }

    injectInitPolicy(policy: PolicySimpleWithMFA): void {
        this.setRootFolders(this.rootFolders.map((rootFolder) => {
            if (!rootFolder.policy) {
                return { ...rootFolder, policy };
            }
            return rootFolder;
        }));
    }

    injectNewPolicy(spxFolderId: string, newPolicy: PolicySimpleWithMFA): void {
        this.partialUpdateFolder(spxFolderId, { policy: newPolicy });
    }

    setChangePolicyErrorStatus(spxFolderId: string, hasPolicyError: boolean): void {
        this.partialUpdateFolder(spxFolderId, { hasPolicyError });
    }

    prepareRemove(treeId: string): string[] {
        const tree = this.foldersTreesList.find(({ id }) => id === treeId);
        const { filesIds, foldersIds } = tree.getPlainChildren();
        foldersIds.forEach((id) => this.createFolderService.cancel(createRequestKey(treeId, id)));
        return filesIds;
    }

    removeTree(targetTreeId: string): void {
        this.foldersTreesList = this.foldersTreesList.filter(({ id }) => id !== targetTreeId);
        this.setRootFolders(this.rootFolders.filter(({ uid }) => uid !== targetTreeId));
    }

    private partialUpdateFolder(spxFolderId: string, newDataPartial: Partial<UploadedRootFolder>): void {
        this.setRootFolders(
            partialUpdateUploadedItem<UploadedRootFolder>(
                this.rootFolders,
                (rootFolder) => rootFolder.fid === spxFolderId,
                newDataPartial,
            ),
        );
    }

    private tryInitRootCreating(tree: FoldersTree, myFilesParentFolderId: string): boolean {
        const { root } = tree;
        return this.tryCreateBEFolder(createRequestKey(tree.id, root.key), tree, root, myFilesParentFolderId);
    }

    private tryCreateBEFolder(
        requestKey: string,
        tree: FoldersTree,
        node: UploadedFolderNode,
        myFilesParentFolderId: string,
    ): boolean {
        /*
        * The third condition is necessary for 2 cases:
        * 1. Prevent double uploading of child sub-folders
        * 2. Allow upload sub-folders if root has been created before the node was inserted into tree.
        * */
        const shouldMakeRequest: boolean = (
            node.payload.status === 'uploading'
            && !this.createFolderService.checkIsLoading(requestKey)
            && (node.key === tree.root.key || tree.root.payload.status === 'done')
        );
        if (shouldMakeRequest) {
            this.createBEFolder(requestKey, tree, node, myFilesParentFolderId);
        }
        return shouldMakeRequest;
    }

    @action
    private setRootFolders(newList: UploadedRootFolder[]): void {
        this.rootFolders = newList;
    }

    private updateRootNodeOnFinish(treeId: string, spxFolderId: string, success: boolean): void {
        this.setRootFolders(
            partialUpdateUploadedItem<UploadedRootFolder>(
                this.rootFolders,
                (rootFolder) => rootFolder.uid === treeId,
                { fid: spxFolderId, status: success ? 'done' : 'error' },
            ),
        );
    }

    private async createBEFolder(
        requestKey: string,
        tree: FoldersTree,
        folderNode: UploadedFolderNode,
        myFilesParentFolderId: string,
    ): Promise<void> {
        const {
            value: { name },
            parentKey,
        } = folderNode;
        const parentFolderId: string = parentKey ? tree.find(parentKey)?.payload.fid : myFilesParentFolderId;
        const isRoot = folderNode.key === tree.root.key;
        try {
            const spxFolderId: string = await this.createFolderService.createFolder(name, parentFolderId, requestKey);
            tree.setNodePayload(folderNode.key, { fid: spxFolderId, status: 'done' });
            this.requestsBuffer.get(requestKey)?.forEach(({ resolve }) => {
                resolve(spxFolderId);
            });
            if (isRoot) {
                this.updateRootNodeOnFinish(tree.id, spxFolderId, true);
                this.tryAttachFuturePolicy(tree.id, spxFolderId);
            }
            folderNode.children.forEach((child) => {
                if (child.type === 'node') {
                    this.createBEFolder(createRequestKey(tree.id, child.key), tree, child, myFilesParentFolderId);
                }
            });
        } catch (error) {
            if (!CancellableAPI.isCancel(error, this.authSettingsStore.IS_WSO)) {
                console.log('Could not create folder', error);
                message.error(i18n.t('uploadFiles.messages.error.couldNotCreateFolder', { folderName: name }));
                captureErrorForSentry(error, 'FolderUploader.createBEFolder');
            }
            tree.setNodePayload(folderNode.key, { ...folderNode.payload, status: 'error' });
            if (isRoot) {
                this.updateRootNodeOnFinish(tree.id, '', false);
            }
            for (const folder of tree.traversalNodes(folderNode)) {
                tree.setNodePayload(folderNode.key, { ...folderNode.payload, status: 'error' });
                const relatedRequestKey: string = createRequestKey(tree.id, folder.key);
                this.requestsBuffer.get(relatedRequestKey)?.forEach(({ reject }) => {
                    reject(error);
                });
            }
        } finally {
            this.requestsBuffer.delete(requestKey);
        }
    }

    private pushRequestIntoWaitingBuffer(requestKey: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const waitersList = this.requestsBuffer.get(requestKey);
            if (waitersList) {
                waitersList.push({ resolve, reject });
            } else {
                this.requestsBuffer.set(requestKey, [{ resolve, reject }]);
            }
        });
    }
}

export default FolderUploader;
