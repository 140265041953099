import React, { FC, memo } from 'react';

import { Trans } from 'react-i18next';

import styles from './ProtectedFilesDescription.module.scss';

const ProtectedFilesDescription: FC = () => (
    <div className={styles['protected-files']}>
        <Trans i18nKey="policyEditor.protectOnDownload.description">
            <strong>supported file extension</strong>
            extensions
            <strong>condition for unsupported</strong>
        </Trans>
    </div>
);

export default memo(ProtectedFilesDescription);
