import { ToolbarItem } from '../pspdfkitTypes';
import { AllowedOptions } from '../interfaces';
import { isUnionType } from '../../../utils';
import { DEFAULT_TOOLBAR_ITEMS } from '../constants';

const ALL_EXTRA_OPTIONS: AllowedOptions[] = [
    'signature',
    'spacer',
];

const getAllowedOptions = (editAllowed: boolean): AllowedOptions[] => {
    const options: AllowedOptions[] = [];
    if (editAllowed) {
        options.push('signature');
    }
    if (options.length) {
        options.unshift('spacer');
    }
    return options;
};

const handleExtraToolbarItems = (
    items: ToolbarItem[],
    editAllowed: boolean,
): ToolbarItem[] => {
    let result = [...items];
    const extraItems = getAllowedOptions(editAllowed);

    if (extraItems.length) {
        result.push(
            ...extraItems.map((option) => ({ type: option })),
        );
    } else {
        result = result.filter(({ type }) => !isUnionType(type, ALL_EXTRA_OPTIONS));
    }
    return result;
};

const handleDefaultItems = (currentItems: ToolbarItem[]): ToolbarItem[] => {
    const currentTypesSet = new Set(currentItems.map(({ type }) => type));
    const nonIncludedDefaultItems = DEFAULT_TOOLBAR_ITEMS.filter((item) => !currentTypesSet.has(item.type));
    return [...currentItems, ...nonIncludedDefaultItems];
};

const getToolbarItems = (currentItems: ToolbarItem[], editAllowed: boolean): ToolbarItem[] => {
    const itemsWithDefault = handleDefaultItems(currentItems);
    return handleExtraToolbarItems(itemsWithDefault, editAllowed);
};

export default getToolbarItems;
