import { captureException } from '@sentry/react';

import { CUSTOM_ERRORS_LIST, PermissionsCheckFail } from './customErrors';
import { captureUnexpectedNetworkError } from '../../../utils';

const captureSharedFileFlowError = (error: unknown, name: string): void => {
    if (CUSTOM_ERRORS_LIST.some((ErrorClass) => error instanceof ErrorClass)) {
        captureException(error, {
            level: error instanceof PermissionsCheckFail ? 'error' : 'warning',
        });
    } else {
        captureUnexpectedNetworkError(error, name);
    }
};

export default captureSharedFileFlowError;
