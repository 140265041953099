import type { FC } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStores } from '@/components/hooks';
import type { EntityLoadErrorType, FileAccessError } from '@/types/types';
import { isUnionType } from '@/components/utils';

import {
    ErrorResult,
    getDefaultErrorResultProps,
} from '../../../../../Common';

const ENTITY_LOAD_ERROR_TYPES: EntityLoadErrorType[] = [
    'serverError',
    'permissionCheckFail',
    'notFound',
    'noPermissions',
];

const errorTypeToGenericError = (errorType: FileAccessError): EntityLoadErrorType => {
    if (isUnionType<EntityLoadErrorType>(errorType, ENTITY_LOAD_ERROR_TYPES)) {
        return errorType;
    }
    return 'serverError';
};

const ErrorView: FC = observer(() => {
    const {
        recipientPageStore: {
            sharedFilesStore: { linkData },
            emailMessageStore: { errorType },
        },
    } = useStores();

    const { t } = useTranslation();

    return (
        <ErrorResult
            {...getDefaultErrorResultProps(
                t,
                errorTypeToGenericError(errorType),
                'general.specterxCommon.message',
                linkData?.src_email,
            )}
        />
    );
});

export default ErrorView;
