import { message } from 'antd';
import { TFunction } from 'i18next';

import { SecuredFile } from '../../types/types';
import { ErrorTypes } from './interfaces';
import { getErrorResponse, checkMessageContainsMFA, checkMessageContainsPermissions } from '../utils';
import {
    DOWNLOAD_ERRORS_NAME_SPACE, DOWNLOAD_SPECIFIC_ERRORS,
    ERRORS_NAME_SPACE, DOWNLOAD_ERROR_MESSAGE_DURATION,
} from './constants';

export const getErrorType = (error: unknown): ErrorTypes => {
    const { statusCode, message: errorMessage } = getErrorResponse(error);
    let errorType: ErrorTypes;
    const isMFAError = checkMessageContainsMFA(errorMessage);
    const isPermissionsError = checkMessageContainsPermissions(errorMessage);
    switch (statusCode) {
    case 404:
    case 500:
        errorType = statusCode;
        break;
    case 401:
        errorType = isMFAError ? 'mfaRequired' : 401;
        break;
    case 403:
        if (isMFAError) {
            errorType = 'mfaFail';
        } else if (isPermissionsError) {
            errorType = 'noPermissions';
        } else {
            errorType = 403;
        }
        break;
    case 400:
    case 406:
        errorType = 'optionNotSupported';
        break;
    default:
        errorType = 500;
    }
    return errorType;
};

export const getErrorMessage = (errorType: ErrorTypes, t: TFunction): string => (
    errorType === 404
        ? t('general.errors.404', { type: '$t(general.specterxCommon.file)' })
        : t(`viewer.error.${errorType}`)
);

export const showDownloadErrorMessage = (errorType: ErrorTypes, t: TFunction): void => {
    const messageNameSpace: string = DOWNLOAD_SPECIFIC_ERRORS.includes(errorType)
        ? DOWNLOAD_ERRORS_NAME_SPACE
        : ERRORS_NAME_SPACE;
    message.error(t(`${messageNameSpace}.${errorType}`), DOWNLOAD_ERROR_MESSAGE_DURATION);
};

export const checkEditPermissions = (
    currentUserEmail: string,
    file: SecuredFile,
): boolean => {
    if (currentUserEmail === file?.owner_email) {
        return true;
    }
    return file?.menu?.read_only === false;
};
