import React, { FC, memo, useEffect } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { PUBLIC_ASSETS } from '@/consts';
import { useTargetScreen } from '../../hooks';
import { SimpleCallback } from '../../../types/types';
import { ModalContentProps, MODAL_DISABLE_MIN_HEIGHT_CLASS } from '../Modal';
import './index.scss';

const ALLOW_POPUP_WIDTH = 640;

interface PopupContentProps extends ModalContentProps {
    close: SimpleCallback;
    link: string;
}

interface LinkProps {
    target?: '_blank' | '_self' | '_parent' | '_top';
    href?: string;
}

const nameSpace = 'allowPopupContent';

const AllowPopupContent: FC<PopupContentProps> = ({
    close,
    link,
    resetParentModalState,
    setAdditionalWrapClassName,
    setWidth,
}) => {
    useEffect(() => {
        setAdditionalWrapClassName?.(MODAL_DISABLE_MIN_HEIGHT_CLASS);
        setWidth?.(ALLOW_POPUP_WIDTH);
        return () => {
            resetParentModalState?.();
        };
    }, []);

    const { t } = useTranslation();
    const isMobile = useTargetScreen('md');
    const hintKey = isMobile ? `${nameSpace}.allowPopupMobile` : `${nameSpace}.allowPopup`;

    const linkProps: LinkProps = link ? { target: '_blank', href: link } : {};

    return (
        <div className="popup-container">
            <div className="title">
                {t(`${nameSpace}.openFile`)}
            </div>
            <p>{t(hintKey)}</p>
            {!isMobile && <img src={`${PUBLIC_ASSETS}/images/Popup-blocked-with-shadow.png`} alt="popup help" />}
            <div className="buttons-wrapper">
                <Button
                    onClick={close}
                    className="specter-button"
                >
                    {t('general.buttons.cancel')}
                </Button>
                <Button
                    onClick={close}
                    className="specter-button primary"
                    {...linkProps}
                >
                    {t(`${nameSpace}.openFile`)}
                </Button>
            </div>
        </div>
    );
};

export default memo(AllowPopupContent);
