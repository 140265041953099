import React, { FC, ReactElement } from 'react';

import FolderLink from '../../../Common/FolderLink';
import { SimpleCallback } from '../../../../types/types';
import { OpenOptions } from '../../../../config/openOptions';
import { FilesListType, createFolderKey } from '../../../../stores';
import styles from './FileNameCell.module.scss';

type HandleFileAction = (action: OpenOptions, fileId: string) => void | Promise<void>;

export interface BaseClickableFileProps {
    isFolder: boolean;
    fileId: string;
    currentFolderKey?: string;
    filesListType?: FilesListType;
    handleFileAction?: HandleFileAction;
    fileAction?: OpenOptions | null;
}

interface ClickableFileProps extends BaseClickableFileProps {
    children: ReactElement;
}

const ClickableFile: FC<ClickableFileProps> = ({
    isFolder,
    fileId,
    fileAction,
    filesListType,
    handleFileAction,
    currentFolderKey,
    children,
}) => {
    if (isFolder && currentFolderKey && filesListType) {
        return (
            <FolderLink
                className={styles['link-item']}
                filesListType={filesListType}
                folderKey={createFolderKey(currentFolderKey, fileId)}
            >
                {children}
            </FolderLink>
        );
    }

    const onClick: SimpleCallback | null = fileAction && handleFileAction
        ? () => handleFileAction(fileAction, fileId)
        : null;

    return (
        onClick ? (
            <button type="button" onClick={onClick} className={styles['clickable-item']} data-clickable>
                {children}
            </button>
        ) : <>{children}</>
    );
};

export default ClickableFile;
