import { ALLOW_FORWARDING, ALLOW_FORWARDING_IN_DOMAIN } from '@/consts';
import { MapDropdownToBooleans, PolicySettingTemplate } from '../../../interfaces';

export const WITH_ANYONE = 'withAnyone';
export const ONLY_IN_THEIR_DOMAIN = 'onlyInTheirDomain';
export const WITH_NO_ONE = 'withNoOne';
export const FORWARDING_OPTIONS = 'forwardingOptions';

type ForwardingValues = typeof WITH_ANYONE | typeof ONLY_IN_THEIR_DOMAIN | typeof WITH_NO_ONE;
type ForwardingIds = typeof ALLOW_FORWARDING | typeof ALLOW_FORWARDING_IN_DOMAIN;

export const MAP_FORWARDING_DROPDOWN_TO_BOOLEAN_SETTINGS: MapDropdownToBooleans<ForwardingValues, ForwardingIds> = {
    withAnyone: {
        [ALLOW_FORWARDING]: true,
        [ALLOW_FORWARDING_IN_DOMAIN]: false,
    },
    onlyInTheirDomain: [
        {
            [ALLOW_FORWARDING]: false,
            [ALLOW_FORWARDING_IN_DOMAIN]: true,
        },
        {
            [ALLOW_FORWARDING]: true,
            [ALLOW_FORWARDING_IN_DOMAIN]: true,
        },
    ],
    withNoOne: {
        [ALLOW_FORWARDING]: false,
        [ALLOW_FORWARDING_IN_DOMAIN]: false,
    },
};

export const FORWARDING_DROPDOWN_TEMPLATE: PolicySettingTemplate = {
    public: true,
    description: 'Recipients are allowed sharing',
    id: FORWARDING_OPTIONS,
    type: 'dropdown',
    values: [
        WITH_ANYONE,
        ONLY_IN_THEIR_DOMAIN,
        WITH_NO_ONE,
    ],
};

export const ALLOW_FORWARDING_TEMPLATE: PolicySettingTemplate = {
    description: 'Allow recipients to forward everywhere',
    id: ALLOW_FORWARDING,
    public: true,
    type: 'switch',
};

export const ALLOW_FORWARDING_IN_DOMAIN_TEMPLATE: PolicySettingTemplate = {
    description: 'Allow recipients to forward in theirs domains',
    id: ALLOW_FORWARDING_IN_DOMAIN,
    public: true,
    type: 'switch',
};
