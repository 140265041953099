import React, { FC, memo } from 'react';

import { Button, message } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';

import i18n from '@/content';
import { useStores } from '../../../hooks';
import { BaseCopyLinkProps, getLinkLoadState } from '../common';
import { tryCopyToClipboard } from '../../../utils';

const nameSpace = 'copyLink';

interface CopyLinkButtonProps extends BaseCopyLinkProps {
    className?: string;
}

const CopyLinkIconButton: FC<CopyLinkButtonProps> = ({
    linkKey,
    link,
    isLinkLoading,
    className,
}) => {
    const { sharedUsersStore: { forceRequestLink } } = useStores();

    const loadState = getLinkLoadState({ link, isLinkLoading });

    const onClick = (): void => {
        if (loadState === 'success') {
            tryCopyToClipboard(link, i18n.t(`${nameSpace}.link`));
        } else if (loadState === 'error') {
            message.info(i18n.t(`${nameSpace}.creatingNewLink`));
            forceRequestLink(linkKey);
        }
    };

    return (
        <Button
            type="link"
            className={className}
            onClick={onClick}
            loading={isLinkLoading}
            icon={<SnippetsOutlined data-testid="uploadDrawer_copyIcon" />}
        />
    );
};

export default memo(CopyLinkIconButton);
