import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import { useStores } from '../../../hooks';
import { SimpleCallback } from '../../../../types/types';
import styles from './TableFooter.module.scss';
import Spinner from '../../../Common/Spin';

interface TableFooterProps {
    onShowMoreClick: SimpleCallback;
}

const TableFooter: FC<TableFooterProps> = observer(({ onShowMoreClick }) => {
    const { filesListStore: { isNextFilesLoading } } = useStores();

    return (
        <div className={styles['footer-container']}>
            <Spinner spinning={isNextFilesLoading}>
                <Button type="link" onClick={onShowMoreClick}>
                    {i18n.t('general.buttons.showMore')}
                </Button>
            </Spinner>
        </div>
    );
});

export default TableFooter;
