import { SecuredFile } from '../../../types/types';

interface FilesGroups {
    filesToMove: SecuredFile[];
    filesToStay: SecuredFile[];
}

// eslint-disable-next-line import/prefer-default-export
export const splitCurrentList = (currentList: SecuredFile[], filesToMoveIdsSet: ReadonlySet<string>): FilesGroups => {
    const filesToMove: SecuredFile[] = [];
    const filesToStay: SecuredFile[] = [];
    currentList.forEach((file) => {
        if (filesToMoveIdsSet.has(file.fid)) {
            filesToMove.push(file);
        } else {
            filesToStay.push(file);
        }
    });
    return { filesToMove, filesToStay };
};
