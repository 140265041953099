import React, { FC, ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import Message from '../Message';
import { StatusMessage } from '../interfaces';
import styles from './StepBox.module.scss';

interface StepBoxProps {
    stepNumber: number;
    title: ReactNode;
    children: ReactElement | ReactElement[];
    className?: string;
    disabled?: boolean;
    isSendingStep?: boolean;
    isCodeInputStep?: boolean;
    messages?: StatusMessage[];
}

const StepBox: FC<StepBoxProps> = ({
    children,
    messages,
    isSendingStep = false,
    className,
    disabled,
    stepNumber,
    isCodeInputStep,
    title,
}) => {
    const innerWrapperClasses = classNames(styles['code-auth-step'],
        {
            [styles['has-input']]: isSendingStep,
            [styles['code-input-step']]: isCodeInputStep,
            [className]: !!className,
        });

    return (
        <div className={styles['code-auth-step-wrapper']}>
            <div className={innerWrapperClasses}>
                <span className={styles['step-number']}>
                    {stepNumber}
                </span>
                <span className={styles.title}>
                    {title}
                </span>
                <div className={styles.input}>
                    {children}
                </div>
            </div>
            {!disabled && messages && messages.map(({ message, hasError, options }) => (
                <Message key={message} hasError={hasError} message={message} options={options} />
            ))}
        </div>
    );
};

export default StepBox;
