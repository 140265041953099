import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PUBLIC_ASSETS } from '@/consts';
import ColoredTooltip from '../../../../../Common/ColoredTooltip';
import { useStores } from '../../../../../hooks';
import { FileActionType } from '../../../../FileAction/menuItems';
import { GET_FILE_BUTTONS } from '../../../../../MiniApps/Common';
import { ACTIONS } from '../constants';
import styles from './FileInfoButtons.module.scss';

const nameSpace = 'filesTable.modals.settings';
const infoDrawerNameSpace = `${nameSpace}.infoDrawer.buttons`;
const tooltipNameSpace = 'filesTable.contextMenu';

export interface FileInfoButtonsProps {
    handleFileAction: (action: FileActionType, fileId: string) => void;
}

const FileInfoButtons: FC<FileInfoButtonsProps> = observer(({ handleFileAction }) => {
    const { t } = useTranslation();
    const {
        filesListStore: { selectedItem },
    } = useStores();
    const { fid, menu } = selectedItem || {};

    const { open_with: openWith = [] } = menu || {};

    return (
        <div className={styles['file-details-buttons-wrapper']}>
            {!!openWith.length && (
                <div className={styles['info-title']}>
                    {t(`${infoDrawerNameSpace}.openWith`)}
                </div>
            )}
            <div className={styles['buttons-wrapper']}>
                {openWith.map((item) => {
                    const { iconPath } = GET_FILE_BUTTONS[item];
                    const isViewer = item === 'viewer';
                    return (
                        <Button
                            onClick={() => handleFileAction?.(item, fid)}
                            className={classNames(styles.buttons, { [styles.Viewer]: isViewer })}
                            key={item}
                        >
                            <img src={`${PUBLIC_ASSETS}${iconPath}`} alt={item} />
                            {isViewer && t(`${infoDrawerNameSpace}.viewer`)}
                        </Button>
                    );
                })}
            </div>
            <div className={styles['info-title']}>{t(`${infoDrawerNameSpace}.more`)}</div>
            <div className={styles['buttons-wrapper']}>
                {ACTIONS.map((item) => (
                    <ColoredTooltip title={t(`${tooltipNameSpace}.${item.type}`)} placement="topLeft" key={item.type}>
                        <Button
                            onClick={() => handleFileAction?.(item.type as FileActionType, fid)}
                            className={classNames(styles['other-actions'], { [styles.share]: item.type === 'share' })}
                        >
                            {item.icon}
                        </Button>
                    </ColoredTooltip>
                ))}
            </div>
        </div>
    );
});

export default FileInfoButtons;
