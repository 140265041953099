import React, { FC, memo } from 'react';

import { getNewIconUrl } from '../../../utils';

interface FileIconProps {
    filename: string;
    isFolder: boolean;
    className?: string;
}

const FileIcon: FC<FileIconProps> = ({ filename, isFolder, className }) => {
    const iconURL = getNewIconUrl(filename, isFolder);

    return (
        <img
            alt=""
            src={iconURL}
            className={className}
        />
    );
};

export default memo(FileIcon);
