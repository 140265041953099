import React, { FC } from 'react';

import { observer } from 'mobx-react';

import RecipientRoleSelect, { SharedItemType } from '../../RecipientRoleSelect';
import { useStores } from '../../../hooks';

import styles from './InviteAs.module.scss';

interface SharingBlockProps {
    sharedItemType?: SharedItemType;
}

const InviteAs: FC<SharingBlockProps> = observer(({ sharedItemType }) => {
    const { sharedUsersStore: { setInviteAs, inviteAs } } = useStores();
    return (
        <div className={styles['invite-as-wrapper']}>
            <RecipientRoleSelect value={inviteAs} onChange={setInviteAs} sharedItemType={sharedItemType} />
        </div>
    );
});

export default InviteAs;
