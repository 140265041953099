import React, { FC, memo } from 'react';

import { PUBLIC_ASSETS } from '@/consts';
import StatusIcon, { CommonIconProps } from './StatusIcon';

const src = `${PUBLIC_ASSETS}/images/clock_mismatch.png`;

const ClockMismatch: FC<CommonIconProps> = (props) => <StatusIcon src={src} {...props} />;

export default memo(ClockMismatch);
