import { PUBLIC_ASSETS } from '@/consts';
import { splitFilename } from './ellipsis';

const FILE_TYPES_PATH = `${PUBLIC_ASSETS}/icons/file-types`;

const SPREADSHEET = `${FILE_TYPES_PATH}/spreadsheet-file-ic.svg`;
const PRESENTATION = `${FILE_TYPES_PATH}/presentation-file-ic.svg`;
const TEXT = `${FILE_TYPES_PATH}/text-file-ic.svg`;
const PDF = `${FILE_TYPES_PATH}/pdf-file-ic.svg`;
const PICTURE = `${FILE_TYPES_PATH}/pictures-file-ic.svg`;
const VIDEO = `${FILE_TYPES_PATH}/video-file-ic.svg`;
const AUDIO = `${FILE_TYPES_PATH}/audio-file-ic.svg`;
const HTML = `${FILE_TYPES_PATH}/html-file-ic.svg`;
const RAR = `${FILE_TYPES_PATH}/rar-file-ic.svg`;
const RSA = `${FILE_TYPES_PATH}/rsa-file-ic.svg`;
const PSD = `${FILE_TYPES_PATH}/psd-file-ic.svg`;
const ZIP = `${FILE_TYPES_PATH}/zip-file-ic.svg`;
const STEP = `${FILE_TYPES_PATH}/step-file-ic.svg`;
const DWG = `${FILE_TYPES_PATH}/dwg-file-ic.svg`;
const SOLIDWORKS = `${FILE_TYPES_PATH}/solidworks-file-ic.svg`;
const OTHER = `${FILE_TYPES_PATH}/other-file-ic.svg`;
const FOLDER = `${FILE_TYPES_PATH}/folder-ic.svg`;

const FILE_EXTENSION_TO_ICON_URL: Record<string, string> = {
    csv: SPREADSHEET,
    xls: SPREADSHEET,
    xlsx: SPREADSHEET,
    xlsm: SPREADSHEET,
    xltx: SPREADSHEET,
    xltm: SPREADSHEET,
    ods: SPREADSHEET,
    odp: PRESENTATION,
    ppt: PRESENTATION,
    pps: PRESENTATION,
    ppsx: PRESENTATION,
    ppsm: PRESENTATION,
    potx: PRESENTATION,
    potm: PRESENTATION,
    pptx: PRESENTATION,
    pptm: PRESENTATION,
    sld: PRESENTATION,
    sldx: PRESENTATION,
    sldm: PRESENTATION,
    doc: TEXT,
    docx: TEXT,
    docm: TEXT,
    dot: TEXT,
    dotx: TEXT,
    dotm: TEXT,
    odt: TEXT,
    rtf: TEXT,
    txt: TEXT,
    pdf: PDF,
    gif: PICTURE,
    jpg: PICTURE,
    jpeg: PICTURE,
    png: PICTURE,
    svg: PICTURE,
    mp3: AUDIO,
    mp4: VIDEO,
    webm: VIDEO,
    mkv: VIDEO,
    html: HTML,
    rar: RAR,
    rsa: RSA,
    psd: PSD,
    zip: ZIP,
    step: STEP,
    stp: STEP,
    dwg: DWG,
    sldprt: SOLIDWORKS,
    other: OTHER,
    folder: FOLDER,
};

const getNewIconUrl = (name: string, isFolder = false): string => {
    if (isFolder) {
        return FILE_EXTENSION_TO_ICON_URL.folder;
    }
    const fileExtension = splitFilename(name).extension.toLowerCase();
    return FILE_EXTENSION_TO_ICON_URL[fileExtension] || FILE_EXTENSION_TO_ICON_URL.other;
};

export default getNewIconUrl;
