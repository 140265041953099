import useInstance from './useInstance';
import AuthSettingsStore from '../../stores/AuthSettingsStore';
import SingleCallAPI, { ISingleCallAPI } from '../../api/SingleCallAPI';

const useSingleCallAPI = (
    authSettingsStore: AuthSettingsStore,
    failSilently: boolean,
): ISingleCallAPI => useInstance<ISingleCallAPI>(() => new SingleCallAPI(authSettingsStore, failSilently));

export default useSingleCallAPI;
