import {
    FC, useCallback, useEffect, useState,
} from 'react';

import {
    Button, Checkbox, CheckboxProps, Input,
} from 'antd';
import { InputProps } from 'antd/lib';
import { observer } from 'mobx-react';
import Modal, {
    InitModalStyle, ModalFooter, ModalHeader,
} from '../Modal';
import i18n from '@/content';

import styles from './BecomeLicensedUserModal.module.scss';
import { useStores } from '@/components/hooks';
import { EMAIL_REGEXP } from '@/regExp';

interface BecomeLicensedUserModalProps {
    isOpen: boolean;
    closeModal: () => void;
    username: string;
}

const nameSpace = 'becomeLicensedUserModal';

const INIT_STYLE: InitModalStyle = {
    initWidth: 350,
};

const MAX_LENGTH = 70;

const BecomeLicensedUserModal: FC<BecomeLicensedUserModalProps> = (
    { isOpen, closeModal, username },
) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [emailInputValue, setEmailInputValue] = useState<string>('');

    const { auditsStore } = useStores();

    const { generateBecomeLicensedUserEmail, isLoading } = auditsStore;

    const isEmailValid = EMAIL_REGEXP.test(emailInputValue);

    useEffect(() => {
        if (!isOpen || !isEmailValid) {
            setIsChecked(false);
        }
    }, [isOpen, isEmailValid]);

    useEffect(() => {
        if (!isOpen) {
            setEmailInputValue(username);
        }
    }, [isOpen]);

    useEffect(() => {
        setEmailInputValue(username);
    }, [username]);

    const onChange: CheckboxProps['onChange'] = useCallback((e) => {
        setIsChecked(e.target.checked);
    }, []);

    const handleInputEmailValueChange: InputProps['onChange'] = useCallback((e) => {
        setEmailInputValue(e.target.value);
    }, []);

    const handleSendEmailToBecomeLicensedUser = async (): Promise<void> => {
        await generateBecomeLicensedUserEmail(emailInputValue, username);
    };

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            initStyle={INIT_STYLE}
        >
            <>
                <ModalHeader>
                    <span className={styles.title}>
                        {i18n.t(`${nameSpace}.title`)}
                    </span>
                </ModalHeader>
                <div className={styles['main-container']}>
                    <div>
                        <span className={styles.subtitle}>
                            {i18n.t(`${nameSpace}.subtitle`)}
                        </span>
                    </div>
                    <div>
                        <Input
                            className={styles['email-input']}
                            value={emailInputValue}
                            onChange={handleInputEmailValueChange}
                            maxLength={MAX_LENGTH}
                            status={isEmailValid ? '' : 'error'}
                        />
                        {!isEmailValid && (
                            <span className={styles['input-error-message']}>
                                {i18n.t('general.specterxCommon.invalidEmail')}
                            </span>
                        )}
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox onChange={onChange} checked={isChecked} disabled={!isEmailValid} />
                        <span className={styles['user-terms']}>
                            {i18n.t(`${nameSpace}.checkboxText`)}
                        </span>
                    </div>
                </div>
                <ModalFooter>
                    <div className={styles['buttons-container']}>
                        <Button
                            type="primary"
                            disabled={!isChecked}
                            loading={isLoading}
                            className={styles['get-offer-button']}
                            onClick={handleSendEmailToBecomeLicensedUser}
                        >
                            {i18n.t(`${nameSpace}.getOffer`)}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
};

export default observer(BecomeLicensedUserModal);
