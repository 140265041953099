import { memo } from 'react';
import type { FC } from 'react';

import { Popover, Radio } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { FileIcon } from '@/components/Common/Icons';
import { useStores } from '@/components/hooks';
import { SharedFile } from '@/stores';

import FilePopup from '../FilePopup';
import { useFilesActionsContext } from '../../../hooks/useFilesActionContext';
import usePopupToggle from './usePopupToggle';
import getPathToSharedFolder from '../../../../../getPathToSharedFolder';
import styles from './FileRadio.module.scss';
import './popupContainerOverride.scss';

interface FileRadioProps {
    file: SharedFile;
    isSelected: boolean;
    isAccessDisabled: boolean;
}

const FileRadio: FC<FileRadioProps> = ({
    file,
    isSelected,
    isAccessDisabled,
}) => {
    const { userStore } = useStores();

    const { selectFile } = useFilesActionsContext();

    const isPopupTriggerActive = isSelected && !isAccessDisabled;
    const { onVisibilityChange, isPopupOpen } = usePopupToggle(isPopupTriggerActive);

    const changeFile = (event): void => {
        selectFile(event.target.value);
        if (file.is_folder && !isAccessDisabled && userStore.currentUserIdentity.id) {
            const path = getPathToSharedFolder(userStore.currentUserIdentity, file);
            window.open(path);
        }
    };

    return (
        <Radio.Button
            onClick={changeFile}
            className={classNames(
                styles.radio,
                styles['file-radio'],
                {
                    [styles.selected]: isSelected,
                    [styles.disabled]: isAccessDisabled,
                },
            )}
            value={file.file_id}
        >
            <Popover
                trigger="click"
                overlayClassName="file-popup-container"
                onOpenChange={onVisibilityChange}
                open={isPopupOpen}
                content={(
                    <FilePopup
                        policy={file.policy}
                        allowedApps={file.allowed_apps}
                        fileId={file.file_id}
                        ownerEmail={file.owner_email}
                        filename={file.filename}
                    />
                )}
            >
                <div className={styles['file-radio-content']}>
                    <FileIcon
                        filename={file.filename}
                        isFolder={file.is_folder}
                        className={styles.icon}
                    />
                    <span
                        className={styles.filename}
                        title={file.filename}
                        data-testid="recipientPage_openFile"
                    >
                        {file.filename}
                    </span>
                    <div
                        className={styles['popup-trigger-placeholder']}
                        data-testid="recipientPage_popup"
                    >
                        {isPopupTriggerActive
                            && (
                                <>
                                    {isPopupOpen
                                        ? <CaretDownOutlined />
                                        : <CaretUpOutlined />}
                                </>
                            )}
                    </div>
                </div>
            </Popover>
        </Radio.Button>
    );
};

export default memo(FileRadio);
