import AuthSettingsStore from '../../AuthSettingsStore';
import { BASEURL, ENDPOINTS, CancellableAPI } from '../../../api';
import { LoadParams, FilesRequestResult } from '../interfaces';
import { captureErrorForSentry } from '../../../components/utils';
import { getFilesQuerystring, loadParamsToQueryParams } from '../helpers';

class RecursiveLoader {
    private readonly APIInstance: CancellableAPI;

    private readonly requestsSet: Set<string> = new Set<string>();

    private readonly onSuccess: (folderId: string, result: FilesRequestResult) => void;

    private readonly onError?: (folderId: string, error: unknown) => void;

    constructor(
        authSettingsStore: AuthSettingsStore,
        onSuccess: (folderId: string, result: FilesRequestResult) => void,
        onError: (folderId: string, error: unknown) => void,
    ) {
        this.APIInstance = new CancellableAPI(authSettingsStore, { failSilently: false });
        this.onSuccess = onSuccess;
        this.onError = onError;
    }

    checkIsLoading(folderId: string): boolean {
        return this.requestsSet.has(folderId);
    }

    async loadFiles(params: LoadParams): Promise<void> {
        const { folderKey } = params;
        const query = getFilesQuerystring(loadParamsToQueryParams(params));
        this.requestsSet.add(folderKey);
        try {
            const result: FilesRequestResult = await this.requestFiles(query, folderKey);
            this.onSuccess(folderKey, result);
        } catch (error) {
            if (!this.APIInstance.checkIsCancel(error)) {
                console.log('recursive load files failed', error);
                captureErrorForSentry(error, 'FilesLoader.loadFiles');
            }
            this.onError(folderKey, error);
        } finally {
            this.requestsSet.delete(folderKey);
        }
    }

    cancel(folderId: string): void {
        this.APIInstance.cancelRequest(folderId);
    }

    cancelAll(): void {
        this.APIInstance.cancelAll();
    }

    private async requestFiles(query: string, requestKey: string): Promise<FilesRequestResult> {
        const { result } = await this.APIInstance.get<FilesRequestResult>(
            BASEURL.backend(),
            ENDPOINTS.getFiles(query),
            requestKey,
        );
        return result;
    }
}

export default RecursiveLoader;
