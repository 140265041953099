import React, { FC } from 'react';

import { Tooltip } from 'antd';
import type { TooltipProps } from 'antd/lib/tooltip';

import './index.scss';

type ColoredTooltipProps = Omit<TooltipProps, 'color'>;

const ColoredTooltip: FC<ColoredTooltipProps> = ({
    children,
    ...rest
}) => (
    <Tooltip
        {...rest}
        color="#091F56"
    >
        {children}
    </Tooltip>
);

export default ColoredTooltip;
