import {
    ADMIN_PANES_IPS_RESTRICTION,
    ALLOW_SIGNUP_EMAIL,
    AUTO_SHARING_VALUE,
    LIMIT_TO_USERS_VALUE,
    PREVENT_DOWNLOAD_UNPROTECTED,
    PROTECT_ON_DOWNLOAD,
} from '@/consts';
import { ListTypeInputs } from './PolicyTagInputRow/interfaces';
import appConfig from '../../../../../config/env';

export const MAP_POLICY_SETTING_ID_TO_TAG_INPUT_TYPE: Record<string, ListTypeInputs> = {
    [AUTO_SHARING_VALUE]: ListTypeInputs.emailAddresses,
    [LIMIT_TO_USERS_VALUE]: ListTypeInputs.emailAddresses,
    [ALLOW_SIGNUP_EMAIL]: ListTypeInputs.domains,
    [ADMIN_PANES_IPS_RESTRICTION]: ListTypeInputs.ips,
}

export const MAP_UI_CHILD_TO_PARENT: Record<string, string> = {
    [PREVENT_DOWNLOAD_UNPROTECTED]: PROTECT_ON_DOWNLOAD,
};

export const MAP_UI_PARENT_TO_CHILDREN: Record<string, string[]> = Object.keys(MAP_UI_CHILD_TO_PARENT)
    .reduce<Record<string, string[]>>((acc, current) => {
        const parentKey: string = MAP_UI_CHILD_TO_PARENT[current];
        if (!acc[parentKey]) {
            acc[parentKey] = [current];
        } else {
            acc[parentKey].push(current);
        }
        return acc;
    }, {});

export const MAP_SETTING_APPEARANCE_TO_CONFIG_FLAG: Record<string, boolean> = {
    [ADMIN_PANES_IPS_RESTRICTION]: appConfig.ENABLE_IP_RESTRICTION ?? false,
};
