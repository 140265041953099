import React, { FC } from 'react';

import Modal from '../Modal';
import ConfirmDownloadContent from './ConfirmDownloadContent';
import { BaseConfirmProps } from './interfaces';

const ConfirmDownloadModal: FC<BaseConfirmProps> = ({
    onFinish,
    ...rest
}) => (
    <Modal
        isOpen
        destroyOnClose
        closeModal={() => onFinish(false)}
    >
        <ConfirmDownloadContent onFinish={onFinish} {...rest} />
    </Modal>
);

export default ConfirmDownloadModal;
