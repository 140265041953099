import { Location } from 'history';

import { AppRoutes } from '@/config/appRoutes';
import type { MenuItem } from '@/stores';
import { FOLDER_ID_QUERY_PARAM } from '@/consts';

interface MenuItemsGroups {
    myFiles: MenuItem[];
    other: MenuItem[];
}

const FILES_ROUTES: AppRoutes[] = [AppRoutes.myFiles, AppRoutes.sharedWithMe, AppRoutes.myWorkspaces];

export const calculateChosenPath = (location: Location, workspacesIdsSet: ReadonlySet<string>): string => {
    if (location.pathname === AppRoutes.myFiles && location.search) {
        const searchParams = new URLSearchParams(location.search);
        const folderIdParam = searchParams.get(FOLDER_ID_QUERY_PARAM);
        return workspacesIdsSet.has(folderIdParam) ? `${location.pathname}${location.search}` : location.pathname;
    }
    return location.pathname;
};

export const getMenuItemsGroups = (displayMenuItems: MenuItem[]): MenuItemsGroups => (
    displayMenuItems.reduce<MenuItemsGroups>(
        (acc, current) => {
            const { route } = current;
            if (FILES_ROUTES.includes(route)) {
                acc.myFiles.push(current);
            } else {
                acc.other.push(current);
            }
            return acc;
        },
        { myFiles: [], other: [] },
    )
);

export const kebabCase = (str: string): string => str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
