import React, { FC, memo } from 'react';

import classNames from 'classnames';

import { Skeleton } from 'antd';
import styles from './SkeletonItem.module.scss';

interface SkeletonItemProps {
    size?: 'md' | 'sm';
}

const SkeletonItem: FC<SkeletonItemProps> = ({ size = 'md' }) => (
    <Skeleton.Button
        active
        className={classNames(styles['skeleton-item'], styles[`skeleton-item-${size}`])}
    />
);

export default memo(SkeletonItem);
