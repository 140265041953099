import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { useOauthClick, ConnectButtonUIProps } from '../../../Common/ConnectExternalStorageButton';
import styles from './ConnectBoxButton.module.scss';

type ConnectBoxButtonProps = Omit<ConnectButtonUIProps, 'storageProvider'>;

const ConnectBoxButton: FC<ConnectBoxButtonProps> = ({ oauthClickHandler, isLoading, onClick }) => {
    const handleClick = useOauthClick(oauthClickHandler, onClick);

    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <Button onClick={handleClick} loading={isLoading} className={styles['connect-button']}>
                {t('externalStorage.connectToSpecterX')}
            </Button>
        </div>
    );
};

export default memo(ConnectBoxButton);
