import React, { FC } from 'react';

import { Button, message, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { LoadingOutlined } from '@ant-design/icons';
import Modal, {
    DynamicStyles,
    InitModalStyle,
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
    MODAL_NO_PADDINGS_CLASS,
    ModalFooter,
    ModalHeader,
} from '@/components/Common/Modal';
import RecipientPhone from '../RecipientListItem/RecipientPhone';
import { useStores } from '@/components/hooks';
import type { RecipientPhoneInfo } from '@/types/types';
import type { CustomGroup, EmailWithPhone } from '@/stores';
import i18n from '@/content';

import styles from './GroupPhoneNumbersModal.module.scss';

const nameSpace = 'sharingBlock.groupPhonesModal';
const buttonsNameSpace = 'general.buttons';

const MODAL_DYNAMIC_STYLES: DynamicStyles = {
    width: true,
    wrapClassName: true,
};

const INIT_STYLE: InitModalStyle = {
    additionalWrapClassName: classNames(MODAL_DISABLE_MIN_HEIGHT_CLASS, MODAL_NO_PADDINGS_CLASS),
    initWidth: 600,
};

interface DataToDisplay {
    groupName: string;
    groupMembersEmails: string[];
    userPhones: [EmailWithPhone[], EmailWithPhone[]];
}

const getDataToDisplay = (
    userGroupsList: CustomGroup[],
    phoneNumbersGroupId: string,
    usersPhonesMap: Map<string, RecipientPhoneInfo>,
): DataToDisplay => {
    const result: DataToDisplay = { groupName: '', userPhones: [[], []], groupMembersEmails: [] };
    const group = userGroupsList.find((item) => item.groupId === phoneNumbersGroupId);
    if (group) {
        const emails = (group?.members || []).map(({ email }) => email);
        const userPhones = emails.reduce<[EmailWithPhone[], EmailWithPhone[]]>((acc, email) => {
            const phoneInfo = usersPhonesMap.get(email);
            const hasPhone = !!phoneInfo?.phonesVector?.currentPhone?.phone;
            acc[hasPhone ? 0 : 1].push({
                email,
                phone: phoneInfo?.phonesVector?.currentPhone?.phone,
                prefix: phoneInfo?.phonesVector?.currentPhone?.prefix,
                isSearching: phoneInfo?.isSearching,
            });
            return acc;
        }, [[], []]);
        result.groupName = group.groupName;
        result.userPhones = userPhones;
        result.groupMembersEmails = emails;
    }
    return result;
};

const GroupPhoneNumbersModal: FC = observer(() => {
    const {
        sharedUsersStore: {
            setLoadingGroupMembersModal,
            loadingGroupMembersModal,
        },
        usersPhonesStore: {
            setPhoneNumbersGroupId,
            phoneNumbersGroupId,
            tryBulkSaveGroupPhoneNumbers,
            usersPhonesMap,
            saveRecipientPhone,
        },
        usersListStore: {
            userGroupsList,
        },
    } = useStores();

    const {
        groupName,
        userPhones,
        groupMembersEmails,
    } = getDataToDisplay(userGroupsList, phoneNumbersGroupId, usersPhonesMap);

    const closeModal = (): void => {
        setPhoneNumbersGroupId(null);
    };

    const filledColumns: ColumnsType<EmailWithPhone> = [
        {
            title: i18n.t(`${nameSpace}.allMembers`),
            dataIndex: 'email',
            width: 270,
        },
        {
            title: '',
            dataIndex: 'phone',
            render: (value, record) => (
                <RecipientPhone
                    prefix={record.prefix}
                    phone={record.phone}
                    isSearching={false}
                    setPhoneNumber={(val) => saveRecipientPhone(record.email, val)}
                />
            ),
            width: 270,
        },
    ];

    const unfilledColumns: ColumnsType<EmailWithPhone> = [
        {
            title: i18n.t(`${nameSpace}.missingPhoneNumbers`),
            dataIndex: 'email',
            width: 270,
        },
        {
            title: '',
            dataIndex: 'phone',
            render: (value, record) => (
                <RecipientPhone
                    prefix={record.prefix}
                    phone={record.phone}
                    isSearching={false}
                    setPhoneNumber={(val) => saveRecipientPhone(record.email, val)}
                />
            ),
            width: 270,
        },
    ];

    const fictionHeader: ColumnsType<EmailWithPhone> = [
        {
            title: i18n.t(`${nameSpace}.member`),
            dataIndex: 'email',
            width: 270,
        },
        {
            title: i18n.t(`${nameSpace}.phone`),
            dataIndex: 'phone',
            width: 270,
        },
    ];

    const [filled, unfilled] = userPhones;

    const isLoadingPhones = userPhones.flat().some(({ isSearching }) => (
        isSearching === true || isSearching === undefined
    ));

    const isConfirmDisabled = groupMembersEmails.some(
        (email) => {
            const phoneInfo = usersPhonesMap.get(email);
            return (
                !phoneInfo?.phonesVector?.currentPhone?.phone
                && !phoneInfo?.phonesVector?.currentPhone?.prefix
            );
        },
    );

    const handleSubmit = async (): Promise<void> => {
        setLoadingGroupMembersModal(true);
        const isSucceed = await tryBulkSaveGroupPhoneNumbers();
        setLoadingGroupMembersModal(false);
        if (isSucceed) {
            closeModal();
        } else {
            message.error(i18n.t(`${nameSpace}.cannotSavePhones`));
        }
    };

    // TODO: fix warning due to RecipientPhone rendering
    return (
        <Modal
            isOpen
            loading={loadingGroupMembersModal}
            closeModal={closeModal}
            dynamicStyles={MODAL_DYNAMIC_STYLES}
            initStyle={INIT_STYLE}
        >
            <>
                <ModalHeader>
                    <span className={styles.title}>
                        {groupName}
                    </span>
                </ModalHeader>
                <div className={styles['group-phones-table']}>
                    {isLoadingPhones
                        ? (
                            <div className={styles['phones-loader-wrapper']}>
                                <LoadingOutlined className={styles['phones-loader']} spin />
                            </div>
                        ) : (
                            <>
                                {!!unfilled.length && (
                                    <Table
                                        rowKey="id"
                                        pagination={false}
                                        columns={fictionHeader}
                                        className={styles['fiction-table-styles']}
                                        rowClassName={styles['table-styles-row']}
                                    />
                                )}
                                {!!unfilled.length && (
                                    <Table
                                        rowKey="email"
                                        pagination={false}
                                        dataSource={unfilled}
                                        columns={unfilledColumns}
                                        className={styles['table-styles']}
                                        rowClassName={styles['table-styles-row']}
                                    />
                                )}
                                {!!filled.length && (
                                    <Table
                                        rowKey="email"
                                        pagination={false}
                                        dataSource={filled}
                                        columns={filledColumns}
                                        className={styles['table-styles']}
                                        rowClassName={styles['table-styles-row']}
                                    />
                                )}
                            </>
                        )}
                </div>
                <ModalFooter>
                    <div className={styles['buttons-container']}>
                        <Button
                            onClick={closeModal}
                            type="text"
                            className={styles.button}
                        >
                            {i18n.t(`${buttonsNameSpace}.close`)}
                        </Button>
                        <Button
                            disabled={isConfirmDisabled}
                            onClick={handleSubmit}
                            type="link"
                            className={styles.create}
                        >
                            {i18n.t(`${buttonsNameSpace}.done`)}
                        </Button>
                    </div>
                </ModalFooter>
            </>
        </Modal>
    );
});

export default GroupPhoneNumbersModal;
