import type { RecipientPhoneInfo, RecipientRoles } from '@/types/types';
import type { RecipientsShareHints, ShareLink } from './interfaces';

export const LINKS_PARALLEL_REQUESTS_LIMIT = 10;

export const EMPTY_RECIPIENT = '__NO_RECIPIENT__';

export const SHARING_HINT_TIMEOUT = 1800;

export const RECIPIENTS_HINTS_LIST: RecipientsShareHints[] = [
    'noRecipients',
    'missingRecipient',
    'missingRecipientInvalid',
];

export const INVITE_AS_DEFAULT: RecipientRoles = 'editor';

export const INIT_SHARE_LINK_PARTIAL: Omit<ShareLink, 'recipientType'> = { link: '', isLoading: true };
