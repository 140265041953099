import React, { FC, memo } from 'react';

import i18n from '@/content';
import { CopyFileLink } from '../../../../Common/CopyLink';
import { CopyLinkProps } from '../interfaces';
import styles from './ShareModalFooter.module.scss';

interface ShareModalFooterProps extends CopyLinkProps {
    isForwarding: boolean;
}

const nameSpace = 'filesTable.modals.shareFile.footer';

const ShareModalFooter: FC<ShareModalFooterProps> = ({ isForwarding, linkKey }) => (
    <div className={styles.footer}>
        <CopyFileLink linkKey={linkKey} />
        <span className={styles['link-description']}>
            {i18n.t(`${nameSpace}.${isForwarding ? 'anyoneHasAccess' : 'linkWorkOnlyForRecipients'}`)}
        </span>
    </div>
);

export default memo(ShareModalFooter);
