/* eslint-disable @typescript-eslint/no-magic-numbers,no-bitwise,no-mixed-operators */
const UUID_V4_TEMPLATE = '10000000-1000-4000-8000-100000000000';

const uuidv4 = (): string => (UUID_V4_TEMPLATE).replace(/[018]/g, (substr) => {
    const substrNum = Number(substr);
    return (
        substrNum ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> substrNum / 4
    ).toString(16);
});

export default uuidv4;
