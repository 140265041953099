import React, { FC, memo } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';

import type { MenuItem } from '@/stores';

import StyledMenu from './StyledMenu';
import { kebabCase } from '../helpers';
import styles from './SidebarMenu.module.scss';
import './antOverride.scss';
import appConfig from '@/config/env';
import { AppRoutes } from '@/config/appRoutes';

const {
    ENABLE_WORKSPACE,
} = appConfig;

interface SidebarMenuProps {
    items: MenuItem[];
    pathname: string;
    chosenMenuItem: string;
}

const SidebarMenu: FC<SidebarMenuProps> = ({
    items,
    chosenMenuItem,
    pathname,
}) => {
    const menuItems = ENABLE_WORKSPACE ? items : items.filter((item) => item.displayName !== 'My workspaces');
    return (
        <StyledMenu
            className={styles.menu}
            selectedKeys={[chosenMenuItem || pathname]}
            mode="inline"
            items={menuItems.map(({ displayName, route, children }) => {
                if (children?.length) {
                    return {
                        key: route,
                        className: classNames('sub-menu', 'left-menu-my-workspaces'),
                        label: <strong>My workspaces</strong>,
                        children: children.map(({ displayName: childDisplayName, route: childRoute }) => ({
                            key: childRoute,
                            className: styles['sidebar-item'],
                            label: (
                                <Link
                                    className={styles['route-link']}
                                    to={childRoute}
                                >
                                    {childDisplayName}
                                </Link>
                            ),
                        })),
                    };
                }
                if (!(appConfig.IS_MULTITENANCY && route === AppRoutes.policyEditor)) {
                    return {
                        key: route,
                        className: classNames(styles['sidebar-item'], `left-menu-${kebabCase(displayName)}`),
                        label: (
                            <Link
                                data-testid={`left-menu-${camelCase(displayName)}`}
                                className={styles['route-link']}
                                to={route}
                                state={chosenMenuItem === route ? 'refresh' : null}
                            >
                                {displayName}
                            </Link>
                        ),
                    };
                }
            })}
        />
    );
};

export default memo(SidebarMenu);
