import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { RedoOutlined, DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { StatusIcon, GenericStatus } from '@/components/Common/Icons';
import ErrorTooltip from '@/components/Common/ErrorTooltip';
import { useStores } from '@/components/hooks';
import type { AsyncDownloadItem, DownloadStatus } from '@/stores';

import styles from './DownloadItem.module.scss';
import nameSpace from '../nameSpace';

const MAP_DOWNLOAD_STATUS_TO_GENERIC_STATUS: Record<DownloadStatus, GenericStatus> = {
    PENDING: 'loading',
    PROCESSING: 'loading',
    ERROR: 'error',
    FETCHING: 'loading',
    DONE: 'done',
};

const DownloadItem: FC<AsyncDownloadItem> = ({
    id,
    message,
    status,
    link,
    name,
    downloadStatus,
}) => {
    const { batchDownloadStore } = useStores();
    const { t } = useTranslation();

    const genericStatus = MAP_DOWNLOAD_STATUS_TO_GENERIC_STATUS[status];
    const displayName = name || id;
    const isLargeFile = status === 'FETCHING';

    const deleteButton = (): JSX.Element => (
        <Button
            size="small"
            className={styles.cancel}
            type="link"
            onClick={() => batchDownloadStore.cancel(id)}
        >
            <DeleteOutlined />
        </Button>
    );

    return (
        <div className={classNames(styles.container, { [styles['large-file']]: isLargeFile })}>
            <div className={classNames(styles['name-row'], styles[genericStatus])}>
                <StatusIcon
                    className={styles.icon}
                    status={genericStatus}
                />
                <div className={styles.name}>{displayName}</div>
                {(genericStatus === 'loading') && (deleteButton())}
            </div>
            {genericStatus === 'done' && link && (
                <a href={link} download className={styles.download}>
                    <RedoOutlined />
                </a>
            )}
            {genericStatus === 'error' && message && (
                <ErrorTooltip message={t(message)} />
            )}
            {isLargeFile
            && (
                <div className={styles['large-file-text']}>
                    {t(`${nameSpace}.largeDownloadStatuses.${downloadStatus}`)}
                </div>
            )}
        </div>
    );
};

export default memo(DownloadItem);
