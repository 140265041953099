import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { tryCopyToClipboard } from '../../../../utils';
import styles from './CopyLinkButton.module.scss';

const nameSpace = 'copyLink';

interface CopyLinkButtonProps {
    link: string;
}

const CopyLinkButton: FC<CopyLinkButtonProps> = ({ link }) => {
    const { t } = useTranslation();

    const onClick = (): void => {
        tryCopyToClipboard(link, t(`${nameSpace}.link`));
    };

    return (
        <Button
            className={styles['link-block']}
            onClick={onClick}
            type="text"
        >
            <SnippetsOutlined />
            <span className={styles['button-text']}>
                {t(`${nameSpace}.copyLinkToClipboard`)}
            </span>
        </Button>
    );
};

export default memo(CopyLinkButton);
