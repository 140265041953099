import React, {
    ClipboardEvent,
    FC,
    KeyboardEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { AutoComplete } from '@/components/Common/UIKit';
import { useStores } from '@/components/hooks';
import type { DropdownItem, RecipientType } from '@/types/types';
import { findEmailInString } from '@/components/utils';

import useFocus from './useFocus';

import styles from './EmailInput.module.scss';

const nameSpace = 'sharingBlock.input';

export interface InitRecipientItem extends DropdownItem<string> {
    type: RecipientType;
}

export interface EmailInputProps {
    addRecipients: (newRecipients: InitRecipientItem[]) => void;
}

const EmailInput: FC<EmailInputProps> = observer(({ addRecipients }) => {
    const [showFillHighlight, setShowFillHighlight] = useState<boolean>(false);

    const { sharedUsersStore, usersListStore } = useStores();
    const {
        currentInputEmail, emailsList, shareHint, setCurrentInputEmail,
    } = sharedUsersStore;
    const { userGroupsList } = usersListStore;

    const optionsList = [
        ...emailsList.map<DropdownItem>((item) => ({ label: item, value: item })),
        ...userGroupsList.map<DropdownItem>((item) => ({ label: item.groupName, value: item.groupId })),
    ].filter(({ label }) => (label?.toLowerCase().includes(currentInputEmail.toLowerCase())));

    const extractedEmailsArray = useMemo<string[]>(() => findEmailInString(currentInputEmail), [currentInputEmail]);
    const isEmailInvalid = !extractedEmailsArray?.length && currentInputEmail !== '';

    const isNoRecipientsHint = shareHint === 'noRecipients';
    const isMissingRecipientInvalidHint = shareHint === 'missingRecipientInvalid';
    const isFillHighlightTrigger = isNoRecipientsHint || isMissingRecipientInvalidHint;

    useEffect(() => {
        if (isFillHighlightTrigger) {
            setShowFillHighlight(true);
        }
    }, [isFillHighlightTrigger]);

    const status = (isEmailInvalid || isMissingRecipientInvalidHint) ? 'error' : null;

    const autocompleteClasses = classNames(
        styles['email-autocomplete'],
        {
            [styles['fill-red']]: showFillHighlight,
            [styles['blinking-dark-cta']]: isNoRecipientsHint,
            [styles['blinking-error']]: isMissingRecipientInvalidHint,
        },
    );
    const buttonClasses = classNames(
        styles['add-email-btn'],
        { [styles['is-shaking']]: shareHint === 'missingRecipient' },
    );

    const addRecipientsByIdentities = (identities: string[]): void => {
        const recipients = identities?.map<InitRecipientItem>((recipientIdentity) => {
            const group = userGroupsList.find((item) => item.groupId === recipientIdentity);
            if (group) {
                return {
                    label: group.groupName,
                    value: recipientIdentity,
                    type: 'group',
                };
            }
            return {
                label: recipientIdentity,
                value: recipientIdentity,
                type: 'user',
            };
        });
        addRecipients(recipients);
    };

    const getArrayToAdd = (): string[] => {
        const group = userGroupsList.find((item) => item.groupName === currentInputEmail);
        if (group) {
            return [group.groupId];
        }
        if (extractedEmailsArray?.length) {
            return [...extractedEmailsArray];
        }
        return isEmailInvalid ? [currentInputEmail] : [];
    };

    const handleKeyUp = (event: KeyboardEvent): void => {
        const arrayToAdd = getArrayToAdd();
        if (arrayToAdd.length) {
            if (['Tab', ','].includes(event.key)) {
                event.preventDefault();
                addRecipientsByIdentities(arrayToAdd);
            } else if (event.key === 'Enter') {
                /*
                 * Enter key press couldn't be handled as Tab or ',',
                 * because it used for pick value from AutoComplete as well
                 */
                event.preventDefault();
                addRecipientsByIdentities(arrayToAdd);
            }
        }
    };

    const { focus, blur, isFocusedRef } = useFocus();

    const onFocus = (): void => {
        focus();
        setShowFillHighlight(false);
    };

    const onPaste = (event: ClipboardEvent): void => {
        const pastedVal = event.clipboardData.getData('Text');
        const pastedEmails = findEmailInString(pastedVal);
        if (isFocusedRef.current) {
            addRecipientsByIdentities(pastedEmails);
        }
    };

    const onSelect = (optVal: string): void => {
        const group = userGroupsList.find((item) => item.groupId === optVal);
        if (group) {
            setCurrentInputEmail(group.groupName);
        }
        addRecipientsByIdentities([optVal]);
    };

    const { t } = useTranslation();

    return (
        <Space.Compact className={styles['input-wrapper']} onPaste={onPaste}>
            <AutoComplete
                status={status}
                options={optionsList}
                className={autocompleteClasses}
                value={currentInputEmail}
                onChange={setCurrentInputEmail}
                onKeyUp={handleKeyUp}
                placeholder={t(`${nameSpace}.placeholder`)}
                onFocus={onFocus}
                onBlur={blur}
                onSelect={onSelect}
                data-testid="uploadDrawer_emailField"
            />
            {extractedEmailsArray
                && (
                    <Button
                        className={buttonClasses}
                        type="primary"
                        onClick={() => addRecipientsByIdentities(extractedEmailsArray)}
                    >
                        {t('general.buttons.add')}
                    </Button>
                )}
        </Space.Compact>
    );
});

export default EmailInput;
