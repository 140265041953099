import { message } from 'antd';

import i18n from '@/content';
import { DeleteFileResult } from '../../stores';

const nameSpace = 'filesTable.messages';

const onDeleteFile = ({ filename, succeed }: DeleteFileResult): void => {
    if (succeed) {
        message.success(i18n.t(`${nameSpace}.deleteSuccess`, { filename }));
    } else {
        message.error(i18n.t(`${nameSpace}.deleteError`, { filename }));
    }
};

export default onDeleteFile;
