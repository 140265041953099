import type { FC, ReactElement } from 'react';

import { ConfigProvider, Select as AntSelect, SelectProps } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';

import { HEXToRGBA } from '@/components/utils';
import { COLORS, CONTROLS_SIZES } from '@/theme';

import './antOverride.scss';

const Select: FC<SelectProps> = (props) => (
    <ConfigProvider
        theme={{
            token: {
                colorBorder: HEXToRGBA(COLORS.cta, '25%'),
                colorText: COLORS.primaryNew,
                colorPrimary: COLORS.cta,
                colorPrimaryHover: COLORS.cta,
                colorTextPlaceholder: COLORS.primaryNew,
                colorTextQuaternary: HEXToRGBA(COLORS.primaryNew, '50%'),
                controlOutline: HEXToRGBA(COLORS.cta, '15%'),
                borderRadius: CONTROLS_SIZES.borderRadius,
                controlHeightSM: CONTROLS_SIZES.heightSM,
                controlHeight: CONTROLS_SIZES.heightDefault,
                fontSizeSM: CONTROLS_SIZES.fontSM,
            },
            components: {
                Select: {
                    optionSelectedColor: COLORS.cta,
                    optionSelectedBg: HEXToRGBA(COLORS.cta, '5%'),
                },
            },
        }}
    >
        <AntSelect
            {...props}
            suffixIcon={<CaretDownFilled />}
        />
    </ConfigProvider>
);

export default Select as <ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
    props: SelectProps<ValueType, OptionType>
) => ReactElement;
