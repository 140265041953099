import { Component, Key } from 'react';

import {
    Button,
    Card,
    message,
    Table,
} from 'antd';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';
import { Can } from '@casl/react';
import { inject, observer } from 'mobx-react';
import { IReactionDisposer, reaction, toJS } from 'mobx';
import type { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { differenceWith, isEqual } from 'lodash';
import classNames from 'classnames';

import {
    AuthSettingsStore,
    UsersListStore,
    UserStore,
} from '@/stores';

import { ENDPOINTS, BASEURL } from '@/api';
import i18n from '@/content';
import ability, { SpecterXRules } from '@/config/ability';
import { TableScrollProps, CognitoGroupsOrganizations } from '@/types/types';
import { captureErrorForSentry, checkIs404 } from '@/components/utils';

import EmailFilter from './EmailFilter';
import RoleFilter from './RoleFilter';
import {
    Email,
    DeleteUser,
    CreationDate,
    RoleGroup,
    PhoneNumber,
} from './Cells';
import { OrganizationUser } from '@/stores/UsersListStore/interfaces';

const TABLE_SCROLL_OFFSET = '230px';

const { Column } = Table;

type AntFilterValue = Key | boolean ;

type Filters = Record<string, (Key | boolean)[] | null>;
type Sorter = SorterResult<OrganizationUser>;
type SorterUnion = SorterResult<OrganizationUser> | SorterResult<OrganizationUser>[];

interface UserRoleTableProps {
    userStore?: UserStore;
    authSettingsStore?: AuthSettingsStore;
    usersListStore?: UsersListStore;
}

interface UserRoleTableState {
    users: OrganizationUser[];
    filters: Filters;
    sorter: Sorter;
}

@inject('authSettingsStore', 'userStore', 'usersListStore')
@observer
class UserRoleTable extends Component<UserRoleTableProps, UserRoleTableState> {
    private readonly nameSpace = 'userRoleEditor';

    private userListReaction: IReactionDisposer;

    constructor(props: UserRoleTableProps) {
        super(props);
        this.state = {
            users: [],
            filters: {},
            sorter: {},
        };
    }

    componentDidMount(): void {
        const { usersListStore } = this.props;
        this.userListReaction = reaction<OrganizationUser[]>(
            () => usersListStore.usersList,
            (usersList) => this.setState({ users: toJS(usersList) }),
        );
    }

    componentWillUnmount(): void {
        this.userListReaction?.();
    }

    checkUserChanges(): boolean {
        const { usersListStore } = this.props;
        const { users } = this.state;
        const { usersList: initUsersList } = usersListStore;
        const usersChanges: OrganizationUser[] = differenceWith(users, toJS(initUsersList), isEqual);
        return !!usersChanges.length;
    }

    onChangeGroup = (userId: string, group: CognitoGroupsOrganizations): void => {
        const { users } = this.state;
        this.setState({
            users: users.map<OrganizationUser>((userEntity) => {
                if (userEntity.user_id === userId) {
                    return { ...userEntity, role: group };
                }
                return userEntity;
            }),
        });
    };

    handleSubmit = async (): Promise<void> => {
        const { users } = this.state;
        const { usersListStore, authSettingsStore } = this.props;
        const { usersList: initUsersList } = usersListStore;
        const { API } = authSettingsStore;
        const { nameSpace } = this;
        const usersChanges: OrganizationUser[] = differenceWith(users, toJS(initUsersList), isEqual);
        if (usersChanges.length) {
            const changedUsers = usersChanges.map((user: OrganizationUser) => ({
                userId: user.user_id,
                role: user.role,
            }));
            try {
                usersListStore.setLoadingUserRoleEditor(true);
                await API.post(
                    BASEURL.backend(), ENDPOINTS.changeOrganizationUserRole(), { body: { users: changedUsers } },
                );
                // TODO: refactor naive whole list update
                await usersListStore.fetchUsersAndRoles();
                this.resetFilters();
            } catch (error) {
                captureErrorForSentry(error, 'UserRoleTable.handleSubmit');
                usersListStore.setLoadingUserRoleEditor(false);
                message.error(i18n.t(`${nameSpace}.changeRoleError`));
            }
        }
    };

    private onDeleteSuccess(userId: string, email: string): void {
        const { nameSpace } = this;
        const { usersListStore: { removeUser } } = this.props;
        message.success(i18n.t(`${nameSpace}.deleteUserSuccess`, { email }));
        removeUser(userId);
        this.resetFilters();
    }

    deleteUser = async (userId: string, email: string): Promise<void> => {
        const { usersListStore, authSettingsStore, userStore } = this.props;
        const { isAdmin, isUserSetUp } = userStore;
        const { API } = authSettingsStore;
        const { nameSpace } = this;

        if (!(isAdmin && isUserSetUp)) {
            message.error(i18n.t(`${nameSpace}.cannotPerformOperation`));
            return;
        }

        try {
            usersListStore.setLoadingUserRoleEditor(true);
            await API.del(BASEURL.backend(), ENDPOINTS.deleteOrganizationUser(userId), {});
            await API.del(BASEURL.backend(), ENDPOINTS.deleteUser(userId), {});
            this.onDeleteSuccess(userId, email);
        } catch (error) {
            if (checkIs404(error)) {
                this.onDeleteSuccess(userId, email);
            } else {
                captureErrorForSentry(error, 'UserRoleTable.deleteUser');
                message.error(i18n.t(`${nameSpace}.deleteUserError`, { email }));
            }
        } finally {
            usersListStore.setLoadingUserRoleEditor(false);
        }
    };

    onChangeFilters = (pagination: TablePaginationConfig, filters: Filters, sorter: SorterUnion): void => {
        this.setState({ filters, sorter: sorter as Sorter });
    };

    private resetFilters(): void {
        this.setState({ filters: {}, sorter: {} });
    }

    render(): JSX.Element {
        const { nameSpace } = this;
        const {
            users,
            filters,
            sorter,
        } = this.state;
        const searchText = filters.email?.[0] as string || '';
        const {
            userStore: { currentUserId },
            usersListStore: { cognitoGroupsList },
        } = this.props;
        const columnsNameSpace = `${nameSpace}.tableColumns`;
        const rolesSelectOptions = cognitoGroupsList.map((value) => (
            { value, label: i18n.t(`general.groups.${value}`) }
        ));
        const tableScroll: TableScrollProps = users?.length
            ? { y: `calc(100vh - ${TABLE_SCROLL_OFFSET})`, x: true }
            : {};
        const hasActiveChanges = this.checkUserChanges();

        return (
            <div className="tables-wrapper">
                <Card>
                    <Table
                        rowKey="user_id"
                        size="middle"
                        className="users-table"
                        pagination={false}
                        dataSource={users}
                        scroll={tableScroll}
                        onChange={this.onChangeFilters}
                    >
                        <Column
                            title={i18n.t(`${columnsNameSpace}.email`)}
                            dataIndex="user_email"
                            className="users-table-column"
                            key="user_email"
                            defaultSortOrder="descend"
                            // this is required to reset filters
                            filteredValue={filters.user_email || null}
                            filterDropdown={({
                                setSelectedKeys, selectedKeys, confirm, clearFilters, visible, close,
                            }) => (
                                <EmailFilter
                                    visible={visible}
                                    selectedKeys={selectedKeys}
                                    setSelectedKeys={setSelectedKeys}
                                    confirm={confirm}
                                    clearFilters={clearFilters}
                                    close={close}
                                />
                            )}
                            filterIcon={(filtered) => (
                                <SearchOutlined
                                    style={{ color: filtered ? '#1890ff' : undefined }}
                                />
                            )}
                            onFilter={(value: AntFilterValue, { user_email }: OrganizationUser) => (
                                user_email.toLowerCase().includes((value as string).toLowerCase())
                            )}
                            render={(cellValue) => (
                                <Email
                                    email={cellValue}
                                    searchText={searchText}
                                />
                            )}
                        />
                        <Column
                            title={i18n.t(`${columnsNameSpace}.roleGroup`)}
                            dataIndex="role"
                            key="role"
                            className={classNames('users-table-column', 'users-table-column-role')}
                            filterDropdown={({
                                setSelectedKeys, confirm,
                            }) => (
                                <RoleFilter
                                    selectedKeys={cognitoGroupsList}
                                    setSelectedKeys={setSelectedKeys}
                                    confirm={confirm}
                                />
                            )}
                            onFilter={(value: AntFilterValue, { role }: OrganizationUser) => (role === value)}
                            filteredValue={filters.role || null}
                            render={(text, record) => (
                                <RoleGroup
                                    userId={record.user_id}
                                    group={text}
                                    options={rolesSelectOptions}
                                    onChange={this.onChangeGroup}
                                />
                            )}
                        />
                        <Column
                            title={i18n.t(`${columnsNameSpace}.phoneNumber`)}
                            dataIndex="user_email"
                            key="user_email"
                            className="users-table-column-phone"
                            render={(user_email) => <PhoneNumber email={user_email} />}
                        />
                        <Column
                            title={i18n.t(`${columnsNameSpace}.creationDate`)}
                            dataIndex="date_added"
                            key="date_added"
                            className="users-table-column-date"
                            sorter={(current: OrganizationUser, prev: OrganizationUser) => (
                                current.date_added - prev.date_added
                            )}
                            sortOrder={sorter.columnKey === 'create_date' ? sorter.order : null}
                            render={(text: number) => <CreationDate createDate={text} />}
                        />
                        <Column
                            title={(
                                <Can I={SpecterXRules.SpecterxEditUsers} a="ALL" ability={ability}>
                                    <div className="save-change-btn-wrapper">
                                        <Button
                                            type="text"
                                            onClick={this.handleSubmit}
                                            className="save-change-btn"
                                            disabled={!hasActiveChanges}
                                        >
                                            <CheckOutlined />
                                            {i18n.t(`${nameSpace}.applyChanges`)}
                                        </Button>
                                    </div>
                                </Can>
                            )}
                            className="users-table-column-del"
                            render={(text, { user_id: userId, user_email: email }: OrganizationUser) => {
                                const shouldDisplay = userId !== currentUserId;
                                return (
                                    <DeleteUser
                                        shouldDisplay={shouldDisplay}
                                        email={email}
                                        userId={userId}
                                        deleteUser={this.deleteUser}
                                    />
                                );
                            }}
                        />
                    </Table>
                </Card>
            </div>
        );
    }
}

export default UserRoleTable;
