import {
    FC,
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { useStores } from '../../../hooks';
import { PhoneIdentity, RecipientRoles } from '../../../../types/types';
import { RecipientToDisplay } from '../../../../stores/SharedUsersStore/interfaces';

import RecipientPhone from './RecipientPhone';
import RecipientChip from './RecipientChip';
import RecipientRoleSelect, { SharedItemType } from '../../RecipientRoleSelect';
import { ChangePhone } from '../../EditPhoneInput';
import { phoneNameSpace } from '../../PhoneComp';
import { maskPhoneNumber } from '@/components/AdminPanel/UserRoleEditor/Cells/PhoneNumber';

import styles from './RecipientsListItem.module.scss';

interface RecipientListItemProps {
    showPhones: boolean;
    hasAccessRoleToggle?: boolean;
    sharedItemType?: SharedItemType;
    recipient: RecipientToDisplay;
}

const RecipientsListItem: FC<RecipientListItemProps> = ({
    showPhones,
    recipient,
    sharedItemType,
    hasAccessRoleToggle = true,
}) => {
    const {
        sharedUsersStore:
        {
            partialUpdateRecipient,
            removeRecipient,
        },
        usersPhonesStore: {
            changePhoneNumber,
            getUserPhone,
            fetchUserPhone,
        },
    } = useStores();

    const {
        email,
        role,
        type,
        id,
    } = recipient;

    const { t } = useTranslation();

    const [maskedPhone, setMaskedPhone] = useState<PhoneIdentity>(null);
    const [isPhoneChanging, setIsPhoneChanging] = useState<boolean>(false);
    const [isPhoneSearching, setIsPhoneSearching] = useState<boolean>(false);

    const fetchPhone = async (): Promise<void> => {
        if (type !== 'group') {
            setIsPhoneSearching(true);
            await fetchUserPhone(email);
            setIsPhoneSearching(false);
        }
    };

    useEffect(() => {
        fetchPhone();
    }, [email]);

    const { phonesVector: { currentPhone } } = getUserPhone(email);

    const changeAccess = useCallback((key: RecipientRoles): void => {
        partialUpdateRecipient(email, { role: key });
    }, []);

    const onPhoneChange: ChangePhone = useCallback(async (phoneNumber) => {
        setIsPhoneChanging(true);
        const result = await changePhoneNumber({ email, phone: phoneNumber.phone, prefix: phoneNumber.prefix });
        if (result) {
            const maskedPhoneNumber = maskPhoneNumber(phoneNumber);
            setMaskedPhone(maskedPhoneNumber);
            message.success(t(`${phoneNameSpace}.successfullyNumberChange`));
        } else {
            message.error(t(`${phoneNameSpace}.invalidPhone`));
        }
        setIsPhoneChanging(false);
    }, [email]);

    const onRemoveClick = useCallback(() => {
        removeRecipient(id);
    }, [id]);

    const isGroup = type !== 'user';

    const phone = maskedPhone || currentPhone;

    return (
        <li key={email}>
            <div className={styles['recipient-row']}>
                <RecipientChip
                    content={email}
                    onClose={onRemoveClick}
                    isGroup={isGroup}
                    id={id}
                />
                {hasAccessRoleToggle && (
                    <div className={styles['file-access']}>
                        <RecipientRoleSelect
                            value={role}
                            sharedItemType={sharedItemType}
                            onChange={changeAccess}
                        />
                    </div>
                )}
            </div>
            {showPhones && (
                <RecipientPhone
                    prefix={phone?.prefix}
                    phone={phone?.phone}
                    isSearching={isPhoneSearching || isPhoneChanging}
                    changePhone={onPhoneChange}
                    groupId={isGroup ? id : null}
                />
            )}
        </li>
    );
};

export default memo(RecipientsListItem);
