import React, {
    memo, FC
} from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import {  useStores } from '@/components/hooks';
import {
    captureServerError,
    captureWarningForSentry,
    getCognitoUserToken,
    tryGetBadRequestMessage,
} from '@/components/utils';
import styles from './ConnectGdriveAccountBanner.module.scss';
import { PUBLIC_ASSETS } from '@/consts';
import { OauthCodeResponse } from '@/types/types';
import { API as AmplifyAPI } from '@aws-amplify/api/lib-esm/API';
import { AxiosError } from 'axios';
import i18n from '@/content';
import { BASEURL, ENDPOINTS } from '@/api';
import { MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY } from '@/components/Common/ConnectExternalStorageButton';
import { useGoogleLoginService } from '@/stores/UserStore/helpers'

interface ConnectGDriveAccountBannerProps {
    setConnectionBanner: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    tryLoadData: () => void;
}

const nameSpace = 'externalStorage';

const ConnectGDriveAccountBanner: FC<ConnectGDriveAccountBannerProps> = ({ setConnectionBanner, tryLoadData }) => {

    const { t } = useTranslation();
    const { appStore } = useStores();

    const onOAuthSuccess = async (response: OauthCodeResponse): Promise<void> => {
        const { code } = response;
        const { setAppAlert } = appStore;
        const endpoint = ENDPOINTS.isGoogleRegister();

        try {
            const JWT = await getCognitoUserToken();
            await AmplifyAPI.post(BASEURL.backend(),
                endpoint,
                { headers: { Authorization: JWT }, body: { code } });
            setConnectionBanner(false);
            tryLoadData();
        } catch (error) {
            console.log('error while send oauth code', error);
            const backEndMessage = tryGetBadRequestMessage(error as AxiosError);
            const descriptionKey = MAP_EXTERNAL_STORAGE_CONNECT_ERROR_TO_I18N_KEY[backEndMessage];
            const message = backEndMessage || `Could not connect your googledrive account`;
            setAppAlert({
                hasAlert: true,
                type: 'error',
                message,
                description: descriptionKey && i18n.t(`${nameSpace}.${descriptionKey}`),
            });
            captureServerError(error, `Header.onOAuthSuccess.googledrive`);
        }
    };

    const onOAuthFail = (originalMessage: string): void => {
        const { setAppAlert } = appStore;
        setAppAlert({
            hasAlert: true,
            type: 'error',
            message: `Could not connect your Google account`,
        });
        const error = new Error(originalMessage);
        captureWarningForSentry(error, 'Header.onOAuthFail.googledrive');
    };

    const responseSuccess = (response: OauthCodeResponse): void => {
        onOAuthSuccess(response);
    };
    const responseFail = (error: Error): void => {
        onOAuthFail(error.message);
    };

    const googleLogin = useGoogleLoginService(responseSuccess, responseFail);

    return (
        <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
                <div className={styles.text}>{t(`${nameSpace}.needsAccess`)}</div>
                <Button
                    onClick={googleLogin}
                    className={styles.button}
                >
                    {t(`${nameSpace}.connect`)}
                </Button>
            </div>
            <img src={`${PUBLIC_ASSETS}/images/connectGdrive.svg`} alt="Connect GDrive" className={styles.image}/>
        </div>
    );
};

export default memo(ConnectGDriveAccountBanner);