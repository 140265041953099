import React, { FC, memo } from 'react';

import { Divider } from 'antd';

import SharingBlock, { NotifyRecipients } from '../../../Common/SharingBlock';
import CustomMessage from '../../../Common/SharingBlock/CustomMessage';
import FilesPolicyBlock from '../FilesPolicyBlock';
import { SharedItemType } from '../../../Common/RecipientRoleSelect';
import styles from './ShareHalf.module.scss';
import appConfig from '../../../../config/env';

const {
    ENABLE_SHARE_MESSAGE,
} = appConfig;

interface ShareHalfProps {
    hasMfa: boolean;
    hasRecipients: boolean;
    hasItems: boolean;
    sharedItemType: SharedItemType;
}

const ShareHalf: FC<ShareHalfProps> = ({
    hasMfa,
    hasItems,
    hasRecipients,
    sharedItemType,
}) => (
    <div className={styles['sharing-policy-block']}>
        <div className={styles['block-wrapper']}>
            <SharingBlock showPhones={hasMfa} sharedItemType={sharedItemType} />
        </div>
        <Divider />
        {hasRecipients && (
            <>
                <div className={styles['block-wrapper']}>
                    <NotifyRecipients />
                    {ENABLE_SHARE_MESSAGE && <Divider />}
                    {ENABLE_SHARE_MESSAGE && <CustomMessage />}
                </div>
                <Divider />
            </>
        )}
        {hasItems && (
            <div className={styles['block-wrapper']}>
                <FilesPolicyBlock />
            </div>
        )}
    </div>
);

export default memo(ShareHalf);
