import { FC } from 'react';

import { Button } from 'antd';

import Modal, { InitModalStyle, ModalFooter } from '@/components/Common/Modal';
import { YogaIcon } from '@/components/Common/StatusIcon';

import styles from './ErrorModal.module.scss';
import { SPX_SUPPORT_EMAIL } from '@/consts';

const INIT_STYLE: InitModalStyle = {
    initWidth: 550,
};

interface ErrorModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const ErrorModal: FC<ErrorModalProps> = ({ isOpen, closeModal }) => (
    <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        initStyle={INIT_STYLE}
    >
        <>
            <div className={styles['main-container']}>
                <YogaIcon iconSize="large" />
                <span className={styles.title}>
                    Sorry, an error occurred
                </span>
                <span className={styles.subtitle}>
                    Click here to refresh the application
                </span>
                <span className={styles.support}>
                    If issue isn&apos;t solved, please contact
                    {' '}
                    <a className={styles['support-email']} href={`mailto:${SPX_SUPPORT_EMAIL}`}>
                        support@specterx.com
                    </a>
                </span>
            </div>
            <ModalFooter>
                <div className={styles['buttons-container']}>
                    <Button
                        type="primary"
                        className={styles['refresh-button']}
                        onClick={closeModal}
                    >
                        Refresh
                    </Button>
                </div>
            </ModalFooter>
        </>
    </Modal>
);

export default ErrorModal;
