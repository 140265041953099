import React, { FC, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import Spinner from '../../../../../../Common/Spin';
import OpenWidget from '../../../../../../OpenViewer/OpenWidget';
import { usePxSize, useStores } from '@/components/hooks';

import ErrorView from './ErrorView';
import FolderView from './FolderView';
import { useSignatureSaving, useViewerState } from './hooks';
import { checkIsFileEditable } from './helpers';
import styles from './FileView.module.scss';
import './antOverride.scss';

const FileView: FC = observer(() => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { containerRef, size } = usePxSize<HTMLDivElement>();

    const {
        filesAccessStore,
        recipientPageStore,
        userStore,
    } = useStores();
    const { setFullScreen, setCollapsed, sharedFilesStore } = recipientPageStore;
    const { selectedFilePermissions, selectedFile } = sharedFilesStore;
    const { currentUserIdentity } = userStore;

    const { viewerState, errorType, contentType } = useViewerState({
        filesAccessStore,
        sharedFilesStore,
        selectedFile,
        selectedFilePermissions,
    });

    const isFileLoading = viewerState?.isLoading;
    const iframeParams = viewerState?.result;

    const showViewerWidget = contentType === 'viewer' && !!iframeParams;

    useEffect(() => {
        if (!recipientPageStore.fullScreen) {
            setFullScreen(showViewerWidget);
            setCollapsed(showViewerWidget);
        }
    }, [showViewerWidget]);

    const onSaveSignature = useSignatureSaving(filesAccessStore);

    return (
        <div className={styles.container} ref={containerRef}>
            <Spinner
                spinning={isFileLoading || isLoading}
                wrapperClassName={classNames(styles.spinner, 'file-view-spinner-wrapper')}
            >
                {contentType === 'error' && (
                    <ErrorView
                        errorType={errorType}
                    />
                )}
                {contentType === 'folder' && <FolderView />}
                {showViewerWidget && (
                    <OpenWidget
                        carouselMode
                        iframeParams={iframeParams}
                        setIsLoading={setIsLoading}
                        fileId={selectedFile.file_id}
                        filePolicyId={selectedFile.policy_id}
                        isEditable={checkIsFileEditable(
                            selectedFilePermissions,
                            currentUserIdentity,
                            { id: selectedFile.owner, email: selectedFile.owner_email },
                        )}
                        PDFViewerStyle={size}
                        onSaveSignature={onSaveSignature}
                    />
                )}
            </Spinner>
        </div>
    );
});

export default FileView;
