import React, { FC, memo, ChangeEvent } from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { EMAIL_REGEXP } from '../../../regExp';
import { CodeAuthenticatorChildrenProps } from '../CodeAuthenticator/interfaces';
import './index.scss';

const MAX_LENGTH = 70;

const nameSpace = 'codeAuth';

interface OTPEmailInputProps extends CodeAuthenticatorChildrenProps {
    email: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void | Promise<void>;
    isSingle?: boolean;
}

const OTPEmailInput: FC<OTPEmailInputProps> = ({
    forceDisabled, email, onChange, isSingle = false,
}) => {
    const { t } = useTranslation();

    const wrapperClasses = classNames('email-input-container', {
        disabled: forceDisabled,
        'is-single': isSingle,
    });

    return (
        <div className={wrapperClasses}>
            <Form.Item
                className="email-input-wrapper"
                name="email"
                rules={[
                    {
                        pattern: EMAIL_REGEXP,
                        message: t('general.specterxCommon.invalidEmail'),
                    }]}
            >
                <Input
                    className="email-address-otp"
                    onChange={onChange}
                    placeholder={t(`${nameSpace}.firstStepCommon.pleaseInputYourEmail`)}
                    disabled={forceDisabled}
                    value={email}
                    maxLength={MAX_LENGTH}
                />
            </Form.Item>
        </div>
    );
};

export default memo(OTPEmailInput);
