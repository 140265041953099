import React, {
    FC, memo, SyntheticEvent, useCallback, useState,
} from 'react';

import { Link, Navigate } from 'react-router-dom';
import { message } from 'antd';
import { Auth } from 'aws-amplify';

import i18n from '@/content';
import TabTitle from '../../Common/TabTitle';
import AltAuthSeparator from '../AltAuthSeparator';
import { BaseAuthLink } from '../AuthLink';
import EnterEmail from './EnterEmail';
import CreateNewPassword from './CreateNewPassword';
import { IAuthenticatorChildrenProps } from '../interfaces';
import styles from './ForgotPassword.module.scss';

const nameSpace = 'forgotPassword';
const SUCCESS_MESSAGE_DURATION = 8;

type Step = 'enterEmail' | 'createNewPassword' | 'done';

const ForgotPassword: FC<Pick<IAuthenticatorChildrenProps, 'signInCalls'>> = ({ signInCalls }) => {
    const [email, setEmail] = useState<string>('');
    const [showOTP, setShowOTP] = useState<boolean>(true);
    const [step, setStep] = useState<Step>('enterEmail');

    const doForgotPassword = useCallback(async (event: SyntheticEvent): Promise<void> => {
        event.preventDefault();
        setShowOTP(false);
        const callBackFunc = (): Promise<void> => Auth.forgotPassword(email.toLowerCase());
        const response = await signInCalls(callBackFunc, `${nameSpace}.doForgotPassword`, { username: email });
        if (response) {
            setStep('createNewPassword');
        }
    }, [email]);

    const doChangePassword = useCallback(
        async (event: SyntheticEvent, code: string, newPassword: string): Promise<void> => {
            event.preventDefault();
            const callBackFunc = async (): Promise<void> => {
                await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
            };
            const response = await signInCalls(callBackFunc, `${nameSpace}.doChangePassword`, { username: email });
            if (response) {
                message.success(i18n.t(`${nameSpace}.success`), SUCCESS_MESSAGE_DURATION);
                setStep('done');
            }
        },
        [email],
    );

    const backToFirstStep = useCallback((): void => {
        setEmail('');
        setShowOTP(true);
        setStep('enterEmail');
    }, []);

    if (step === 'done') {
        return <Navigate to="signIn" />;
    }

    const pageTitle = i18n.t(`${nameSpace}.${step}.title`);

    return (
        <div className={styles['forgot-wrapper']}>
            <TabTitle title={pageTitle} />
            <h1 className={styles.title}>
                {pageTitle}
            </h1>
            {showOTP && (
                <>
                    <Link to="/OTPSignIn" className={styles['otp-link']}>
                        <span className={styles['link-text']}>
                            {i18n.t(`${nameSpace}.signInWithOTP`)}
                        </span>
                    </Link>
                    <AltAuthSeparator message={i18n.t(`${nameSpace}.orCreateNewPassword`)} />
                </>
            )}

            {step === 'enterEmail' && (
                <EnterEmail email={email} onChange={setEmail} onSubmit={doForgotPassword} />
            )}
            {step === 'createNewPassword' && (
                <CreateNewPassword
                    goBack={backToFirstStep}
                    doForgotPassword={doForgotPassword}
                    onSubmit={doChangePassword}
                />
            )}

            <BaseAuthLink href="/signIn">
                {i18n.t(`${nameSpace}.backToSignIn`)}
            </BaseAuthLink>
        </div>
    );
};

export default memo(ForgotPassword);
