import { REQUIRED_EMAIL_VERIFICATION, REQUIRED_PHONE_VERIFICATION } from '@/consts';
import { Policy, PolicySettingsAll } from '../../types/types';
import { UploadedFile } from '../../stores';

export const checkIsPhoneOnlyPolicy = (policySettings: PolicySettingsAll): boolean => {
    const phoneVerificationSetting = policySettings.access?.find(
        ({ id }) => id === REQUIRED_PHONE_VERIFICATION,
    );
    const emailVerificationSetting = policySettings.access?.find(
        ({ id }) => id === REQUIRED_EMAIL_VERIFICATION,
    );
    const hasPhoneVerification = !!phoneVerificationSetting?.value;
    const hasEmailVerification = !!emailVerificationSetting?.value;
    return hasPhoneVerification && !hasEmailVerification;
};

export const checkHasPhoneOnlyPolicyInFilesList = (filesList: UploadedFile[], policiesList: Policy[]): boolean => {
    const policiesMap: Record<string, Policy> = policiesList.reduce(
        (acc, currentValue) => {
            acc[currentValue.id] = currentValue;
            return acc;
        },
        {},
    );
    const filePoliciesIds = [...new Set<string>(filesList.map<string>(({ policy }) => policy?.id))];
    return !!filePoliciesIds.find((policyId) => {
        const settings = policiesMap[policyId]?.settings;
        return settings ? checkIsPhoneOnlyPolicy(settings) : false;
    });
};
