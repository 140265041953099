import React, { FC, memo } from 'react';

import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { CopyFileLink } from '../../Common/CopyLink';
import styles from './ShareLink.module.scss';

interface ShareLinkProps {
    linkKey: string;
    hideDivider?: boolean;
    showCopyButton?: boolean;
}

const nameSpace = 'externalStorage.shareLink';

const ShareLink: FC<ShareLinkProps> = ({ linkKey, showCopyButton, hideDivider = false }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            {!hideDivider && <Divider className={styles.divider} />}
            <span className={styles.title}>
                {t(`${nameSpace}.title`)}
            </span>
            <CopyFileLink linkKey={linkKey} showTextBoxButton={showCopyButton} />
        </div>
    );
};

export default memo(ShareLink);
