const EXTRA_BUFFER = 10;

const adjustCoords = (x: number, y: number, wrapper: HTMLDivElement): [number, number] => {
    const { innerWidth, innerHeight } = window;
    const xBuffer = wrapper.offsetWidth + EXTRA_BUFFER;
    const yBuffer = wrapper.offsetHeight + EXTRA_BUFFER;
    const xPosition = innerWidth - x > xBuffer ? x : innerWidth - xBuffer;
    const yPosition = innerHeight - y > yBuffer ? y : innerHeight - yBuffer;
    return [xPosition, yPosition];
};

export default adjustCoords;
