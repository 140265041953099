import { captureException } from '@sentry/react';

import { EntityLoadErrorType } from '../../../../types/types';
import { captureErrorForSentry } from '../../../../components/utils';

const captureLinkLoadError = (errorType: EntityLoadErrorType, error: unknown): void => {
    if (errorType === 'serverError') {
        captureErrorForSentry(error, errorType);
    } else {
        captureException(error, { level: 'warning' });
    }
};

export default captureLinkLoadError;
