import React, { FC } from 'react';

/* eslint-disable max-len */
const ViewerSvg: FC = () => (
    <svg height="16" viewBox="0 0 22 16" width="22" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <path
                d="m2.98150327 16h4.87882353l3.7946405-5.5652174-.2710457 1.9478261 2.5749346 3.6173913h5.0143464l-5.2853921-7.37391304 8.2668954-8.62608696-15.72065361 12.1043478z"
                fill="#021136"
            />
            <path
                d="m0 16h7.18271242l3.65911768-5.5652174-.4065687 1.5304348 2.8459804 4.0347826h5.0143464l-5.1498692-7.79130435 7.7248039-8.20869565z"
                fill="#19e7d8"
                transform="matrix(-1 0 0 -1 20.870522 16)"
            />
        </g>
    </svg>
);

export default ViewerSvg;
