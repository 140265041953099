import React, { FC, memo } from 'react';

import Helmet from 'react-helmet';

const DEFAULT_TITLE = 'SpecterX';

interface TitleProps {
    title: string;
}

const TabTitle: FC<TitleProps> = ({ title }) => (
    <Helmet>
        <title>{title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE}</title>
    </Helmet>
);

export default memo(TabTitle);
