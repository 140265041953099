import classNames from 'classnames';

import { SettingContent, SettingTextContent, SettingType } from './interfaces';
import { policySettingIdToI18Key } from '../../../../../utils';
import styles from './PolicySetting.module.scss';

const getSettingTextContent = (settingId: string): SettingTextContent => {
    const settingKey = policySettingIdToI18Key(settingId);
    return {
        titleKey: `policy.${settingKey}.title`,
        explainKey: `policy.${settingKey}.explain`,
    };
};

const MAP_POLICY_SETTING_TYPE_TO_BASE_CLASSNAME: Record<SettingType, string> = {
    switch: styles['switch-row'],
    tag: styles['tag-row'],
    dropdown: styles['dropdown-row'],
    input: styles['input-row'],
};

const getSettingContent = (settingId: string, type: SettingType, isChild: boolean): SettingContent => ({
    ...getSettingTextContent(settingId),
    wrapperClasses: classNames(
        MAP_POLICY_SETTING_TYPE_TO_BASE_CLASSNAME[type],
        { [styles['child-setting']]: isChild },
    ),
});

export default getSettingContent;
