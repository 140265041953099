import React, { FC, useEffect, useState } from 'react';

import { Button, Tabs } from 'antd';
import { observer } from 'mobx-react';

import Spinner from '../../Common/Spin';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import TabTitle from '../../Common/TabTitle';
import { useStores } from '@/components/hooks';
import i18n from '@/content';

import UserRoleTable from './UserRoleTable';
import AddMemberModal from './AddMemberModal/AddMemberModal';
import UserGroups from './UserGroups';
import './index.scss';
import '../../Common/Tabs/antOverride.scss';

const nameSpace = 'userRoleEditor';

type TabState = 'usersTab' | 'groupsTab';

const UserRoleEditor: FC = observer(() => {
    const { usersListStore } = useStores();

    const [tabActiveKey, setTabActiveKey] = useState<TabState>('usersTab');

    const handleTabChange = (activeKey: TabState): void => {
        setTabActiveKey(activeKey);
    };

    useEffect(() => {
        usersListStore.fetchUsersAndRoles();
        return () => usersListStore.setRolesErrorState({});
    }, []);

    const {
        loadingUserRoleEditor,
        usersList,
        roleErrorState: { hasRoleError },
        setAddMembersModal,
    } = usersListStore;

    const licensedUsersCount = usersList?.filter((user) => (
        user.role === 'Collaborators' || user.role === 'Administrators') && user.role !== null).length;

    const title = i18n.t(`${nameSpace}.title`);

    return (
        <Spinner spinning={loadingUserRoleEditor} screenCentered>
            <div className="user-editor-container">
                <TabTitle title={title} />
                <div className="tabs-container">
                    <Tabs
                        activeKey={tabActiveKey}
                        onChange={handleTabChange as (activeKey: string) => void}
                        className="antdesign-tabs-override"
                        type="card"
                        items={[
                            {
                                key: 'usersTab',
                                label: i18n.t(`${nameSpace}.userTab`),
                                children: (
                                    hasRoleError ? (
                                        <ErrorMessage
                                            title={i18n.t('general.errors.1', { type: title })}
                                            subtitle={i18n.t('general.errors.try')}
                                        />
                                    ) : <UserRoleTable />
                                ),
                            },
                            {
                                key: 'groupsTab',
                                label: i18n.t(`${nameSpace}.groupsTab`),
                                children: <UserGroups />,
                            },
                        ]}
                    />
                    {tabActiveKey === 'usersTab' && (
                        <div className="tabs-actions-wrapper">
                            <div className="licensed-users-count-chip">
                                <span className="content">
                                    {`${licensedUsersCount} ${i18n.t(`${nameSpace}.licensedUsers`)}`}
                                </span>
                            </div>
                            <Button
                                className="add-members-button"
                                onClick={() => setAddMembersModal(true)}
                            >
                                {i18n.t(`${nameSpace}.addMembersModal.addMembers`)}
                            </Button>
                        </div>
                    )}
                </div>
                <AddMemberModal />
            </div>
        </Spinner>
    );
});

export default UserRoleEditor;
