import React, { FC, memo } from 'react';

import classNames from 'classnames';

import YogaIcon from '../StatusIcon/YogaIcon';
import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
    title: string;
    subtitle: string;
    className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ title, subtitle, className = '' }) => {
    const wrapperClass = classNames(
        styles['error-container'],
        { [className]: !!className },
    );

    return (
        <div className={wrapperClass}>
            <YogaIcon iconSize="small" />
            <span>{title}</span>
            <span>{subtitle}</span>
        </div>
    );
};

export default memo(ErrorMessage);
