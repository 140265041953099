import React, { FC, memo } from 'react';

import { Typography, Col } from 'antd';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart,
    ArcElement,
    Legend,
    Tooltip,
} from 'chart.js';
import { TFunction } from 'i18next';

import i18n from '@/content';
import { DomainSharingDataset } from './helpers';
import { OTHER_I18N_KEY } from './chartSettings';
import styles from './DomainsDoughnut.module.scss';

Chart.register([ArcElement, Legend, Tooltip]);

export enum ShareTypes {
    numberOfSharesList = 'numberOfSharesList',
    numberOfFilesSharedList = 'numberOfFilesSharedList',
}

interface DomainsDoughnutProps {
    dataset: DomainSharingDataset;
    shareTypeKey: ShareTypes;
    chartNameKey: string;
}

const { Title } = Typography;

const HOVER_OFFSET = 4;

const getTranslatedDomainsList = (domainNamesList: string[], t: TFunction): string[] => domainNamesList.map(
    (domain) => (domain === OTHER_I18N_KEY ? t(domain) : domain),
);

const DomainsDoughnut: FC<DomainsDoughnutProps> = ({ dataset, shareTypeKey, chartNameKey }) => {
    // TODO: restore tooltips view
    const data = {
        labels: getTranslatedDomainsList(dataset.domainNamesList, i18n.t.bind(i18n)),
        datasets: [
            {
                data: dataset[shareTypeKey],
                backgroundColor: dataset.colors,
                hoverOffset: HOVER_OFFSET,
            },
        ],
    };

    return (
        <Col xxl={5} xl={7} lg={9} md={10} sm={13} xs={18}>
            <div className={styles['doughnut-wrapper']}>
                <Title level={3} className={styles['chart-title']}>
                    {i18n.t(chartNameKey)}
                </Title>
                <Doughnut data={data} />
            </div>
        </Col>
    );
};

export default memo(DomainsDoughnut);
