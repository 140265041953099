import React, {
    FC,
} from 'react';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import i18n from '@/content';
import { useStores } from '../../../../hooks';
import styles from './UserGroupsSidebar.module.scss';

interface UserGroupsSidebarProps {
    title: string;
}

const nameSpace = 'customGroupsEditor.buttons';

const UserGroupsSidebar: FC<UserGroupsSidebarProps> = observer(({ title }) => {
    const { usersListStore } = useStores();

    const {
        selectedGroupId,
        setSelectedGroupId,
        userGroupsList,
        fetchUsersCustomGroup,
        setNewGroupsMode,
    } = usersListStore;

    const fetchGroupData = async (groupId: string): Promise<void> => {
        const selectedGroup = userGroupsList.filter((item) => item.groupId === groupId)[0];
        setNewGroupsMode(false);
        if (!selectedGroup?.members.length) {
            await fetchUsersCustomGroup(groupId);
        }
        setSelectedGroupId(groupId);
    };

    return (
        <>
            <div className={styles['groups-list-container']}>
                <div className={styles['groups-title-container']}>
                    <div className={styles['groups-title']}>{title}</div>
                    <Button
                        type="link"
                        className={styles['new-groups-button']}
                        onClick={() => setNewGroupsMode(true)}
                    >
                        <PlusOutlined />
                        {i18n.t(`${nameSpace}.createGroup`)}
                    </Button>
                </div>
                {userGroupsList?.map(({ groupName, groupId }) => (
                    <Button
                        onClick={() => fetchGroupData(groupId)}
                        className={classNames(
                            styles['groups-list-item'],
                            { [styles['groups-list-item-active']]: groupId === selectedGroupId },
                        )}
                        key={groupId}
                        block
                        type="link"
                        title={groupName || groupId}
                    >
                        {groupName || groupId}
                    </Button>
                ))}
            </div>
        </>
    );
});

export default UserGroupsSidebar;
