import { useEffect } from 'react';

import { Grid } from 'antd';

import { AppStore } from '../../stores';
import { SideBarState } from '../../stores/AppStore/interfaces';

const { useBreakpoint } = Grid;

const useSidebarState = (appStore: AppStore): SideBarState => {
    const screens = useBreakpoint();
    const isMobile = !screens.lg;

    const { isVisible } = appStore.sideBarState;

    useEffect(() => {
        if (isMobile) {
            const newState: Partial<SideBarState> = { isMobile: true };
            if (!appStore.sideBarState.isMobile) {
                newState.isVisible = false;
                appStore.updateSideBarState(newState);
            }
        } else {
            appStore.setSideBarState({ isMobile: false, isVisible: true });
        }
    }, [isMobile]);

    return { isMobile, isVisible };
};

export default useSidebarState;
