import React, { FC, memo } from 'react';

import Modal, {
    InitModalStyle,
    MODAL_DISABLE_MIN_HEIGHT_CLASS,
} from '../Modal';
import CreateFolderModalContent from './CreateFolderModalContent';

interface CreateFolderModalProps {
    isOpen: boolean;
    isWorkspace?: boolean;
    closeModal: (folderCreated: boolean) => void;
}

const INIT_STYLE: InitModalStyle = {
    additionalWrapClassName: MODAL_DISABLE_MIN_HEIGHT_CLASS,
    initWidth: 500,
};

const CreateFolderModal: FC<CreateFolderModalProps> = ({ closeModal, isOpen, isWorkspace }) => (
    <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        destroyOnClose
        initStyle={INIT_STYLE}
        closable
    >
        <CreateFolderModalContent closeModal={closeModal} isWorkspace={isWorkspace} />
    </Modal>
);

export default memo(CreateFolderModal);
