import { useState } from 'react';

import { message } from 'antd';
import {
    Active,
    DragEndEvent,
    DragStartEvent,
    Over,
} from '@dnd-kit/core';

import i18n from '@/content';
import { DraggedFile, DraggedFileData, FilesMovingState } from '../interfaces';

const moveNameSpace = 'filesTable.move';

interface UseFilesMovingArguments {
    selectedKeysSet: ReadonlySet<string>;
    selectedRowKeys: string[];
    moveFiles: (folderId: string, filesIds: string[]) => Promise<boolean>;
}

const useFilesMoving = ({
    selectedKeysSet,
    selectedRowKeys,
    moveFiles,
}: UseFilesMovingArguments): FilesMovingState => {
    const [activeDraggedItem, setActiveDraggedItem] = useState<DraggedFile>(null);

    const onDragStart = (event: DragStartEvent): void => {
        const { active: { id, data } } = event;
        const { filename, isFolder, itemKey } = data.current as DraggedFileData;
        setActiveDraggedItem({
            fid: id as string,
            filename,
            isFolder,
            itemKey,
        });
    };

    const destroyDragState = (): void => {
        setActiveDraggedItem(null);
    };

    const moveFilesToFolder = async (over: Over, active: Active): Promise<void> => {
        const { data: { current: { filename } }, id: fileId } = active;
        const { data: { current: { filename: folderName, itemKey } } } = over;

        const fileName: string = filename || activeDraggedItem.filename;
        const selectedItemsCount = selectedKeysSet.size;
        const isMultipleDrag: boolean = selectedItemsCount > 1 && selectedKeysSet.has(fileId as string);

        const result: boolean = await moveFiles(
            itemKey as string,
            isMultipleDrag ? selectedRowKeys : [fileId as string],
        );
        const i18nKeyLastPart: string = isMultipleDrag ? 'multiple' : 'single';
        const i18nPayload: Record<string, string | number> = { fileName, folderName, count: selectedItemsCount };
        if (result) {
            message.success(i18n.t(`${moveNameSpace}.success.${i18nKeyLastPart}`, i18nPayload));
        } else {
            message.error(i18n.t(`${moveNameSpace}.error.${i18nKeyLastPart}`, i18nPayload));
        }
    };

    const onDragEnd = (event: DragEndEvent): void => {
        const { over, active } = event;
        if (over?.id) {
            moveFilesToFolder(over, active);
        }
        destroyDragState();
    };

    return {
        activeDraggedItem,
        destroyDragState,
        onDragEnd,
        onDragStart,
    };
};

export default useFilesMoving;
