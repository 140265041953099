import React, { CSSProperties, FC, memo } from 'react';

import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import i18n from '@/content';

import styles from './DeleteUser.module.scss';

const buttonsNameSpace = 'general.buttons';

const OVERLAY_STYLE: CSSProperties = { maxWidth: 'min(80vw, 600px)', whiteSpace: 'pre-wrap' };

interface DeleteUserProps {
    shouldDisplay: boolean;
    email: string;
    userId: string;
    deleteUser: (userId: string, email: string) => void;
}

const DeleteUser: FC<DeleteUserProps> = ({
    shouldDisplay, userId, email, deleteUser,
}) => {
    const onConfirm = (): void => deleteUser(userId, email);
    return shouldDisplay && (
        <Popconfirm
            overlayStyle={OVERLAY_STYLE}
            placement="topLeft"
            onConfirm={onConfirm}
            title={i18n.t('userRoleEditor.deleteUserMessage')}
            okText={i18n.t(`${buttonsNameSpace}.yes`)}
            cancelText={i18n.t(`${buttonsNameSpace}.no`)}
        >
            <Button type="link">
                <DeleteOutlined className={styles['delete-icon']} />
            </Button>
        </Popconfirm>
    );
};

export default memo(DeleteUser);
