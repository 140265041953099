import { AxiosError } from 'axios';

import { AsyncCallback } from '@/types/types';

import { NotFoundError } from './errorsFactory';

// TODO: move it into API
interface ErrorResponse {
    statusCode: number;
    message: string;
}

interface BEErrorResponse {
    message: string;
}

export const getErrorResponse = (error: unknown): ErrorResponse => {
    const { response } = error as AxiosError<BEErrorResponse>;
    return {
        statusCode: response?.status,
        message: response?.data?.message,
    };
};

export const checkIsNetworkError = (error: unknown): boolean => {
    const { message, response } = error as AxiosError;
    return message === 'Network Error' && !response;
};

export const checkIsSpecificCode = (error: unknown, codes: number | number[]): boolean => {
    const { statusCode } = getErrorResponse(error);
    if (!statusCode) {
        return false;
    }
    const codesList: number[] = typeof codes === 'number' ? [codes] : codes;
    return codesList.some((code) => statusCode === code);
};

export const checkIs404 = (error: unknown): boolean => checkIsSpecificCode(error, 404);

export const tryGetBadRequestMessage = (error: unknown): string => {
    const { statusCode, message: responseMessage } = getErrorResponse(error);
    if (responseMessage && statusCode === 400) {
        return responseMessage;
    }
    return '';
};

export const checkMessageContainsMFA = (message: string): boolean => message
    ?.toLowerCase()
    .includes('mfa');

export const checkMessageContainsPermissions = (message: string): boolean => message
    ?.toLowerCase()
    .includes('permissions');

export const checkIsMFARequired = (statusCode: number, message: string): boolean => (
    statusCode === 401 && checkMessageContainsMFA(message)
);

export const checkIsMFAFailed = (statusCode: number, message: string): boolean => (
    statusCode === 403 && checkMessageContainsMFA(message)
);

export const checkIsPermissionsFailed = (statusCode: number, message: string): boolean => (
    statusCode === 403 && checkMessageContainsPermissions(message)
);

export const checkIsMFARequiredError = (error: unknown): boolean => {
    const { statusCode, message } = getErrorResponse(error);
    return checkIsMFARequired(statusCode, message);
};

export const checkIsMFAFailedError = (error: unknown): boolean => {
    const { statusCode, message } = getErrorResponse(error);
    return checkIsMFAFailed(statusCode, message);
};

export const checkIsPermissionsFailedError = (error: unknown): boolean => {
    const { statusCode, message } = getErrorResponse(error);
    return checkIsPermissionsFailed(statusCode, message);
};

export const handleNotFound = async <T>(callback: AsyncCallback<T>): Promise<T | never> => {
    let result: T;
    try {
        result = await callback();
    } catch (error) {
        const { statusCode } = getErrorResponse(error);
        if (statusCode === 404) {
            throw new NotFoundError('Shared file is not found');
        } else {
            throw error;
        }
    }
    return result;
};
