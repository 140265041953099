import { SPXAPI } from '../interfaces';
import { BASEURL, ENDPOINTS } from '../endpoints';

const changeFilePolicy = (API: SPXAPI, fileId: string, policyId: string): Promise<void> => (
    API.put(BASEURL.backend(), ENDPOINTS.filePolicy(fileId), {
        body: { policy_id: policyId },
    })
);

export default changeFilePolicy;
