import type { FC } from 'react';

import {
    Avatar, Button, Dropdown,
} from 'antd';
import type { MenuProps } from 'antd/lib/menu';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import ColoredTooltip from '@/components/Common/ColoredTooltip';
import { useStores } from '@/components/hooks';

import { FileActionType } from '../../../../FileAction/menuItems';
import styles from './FileInfoUserList.module.scss';

const USERS_LIMIT = 5;

const nameSpace = 'filesTable.modals.settings.infoDrawer.userList';

export interface FileInfoUserListProps {
    handleFileAction: (action: FileActionType, fileId: string) => void;
}

const FileInfoUserList: FC<FileInfoUserListProps> = observer(({ handleFileAction }) => {
    const { t } = useTranslation();

    const {
        filesListStore: { selectedItem, currentFolder },
        sharedUsersStore: { getFileSharedUsersList },
    } = useStores();
    const { fid } = selectedItem || {};
    const sharedUsers = getFileSharedUsersList(selectedItem.fid);
    const usersLength = sharedUsers?.length;
    const usersAboveLimit = usersLength > USERS_LIMIT ? usersLength - USERS_LIMIT : 0;
    const usersForDisplay = usersAboveLimit ? sharedUsers?.slice(0, USERS_LIMIT) : sharedUsers;
    const shareText = currentFolder?.is_workspace
        ? 'workspaces'
        : 'fileNotShared';
    const infoText = usersLength ? 'whoHasAccess' : shareText;

    const usersMenu: MenuProps = ({
        items: sharedUsers?.slice(USERS_LIMIT).map((item) => ({
            key: item.email,
            label: (
                <span className={styles['user-menu']}>
                    <Avatar className={styles.avatar} size={32} gap={7}>
                        {item.email?.[0]?.toUpperCase()}
                    </Avatar>
                    {item.email}
                </span>
            ),
        })),
    });

    return (
        <div className={styles['file-details-users-wrapper']}>
            <div className={styles['info-title']}>
                {t(`${nameSpace}.${infoText}`)}
            </div>
            {!!usersLength && usersForDisplay.map((item) => {
                const firstLetter = item.email[0].toUpperCase();
                return (
                    <ColoredTooltip title={item.email} placement="topLeft" className={styles.users} key={item.email}>
                        <Avatar className={styles.avatar} size={32} gap={7}>
                            {firstLetter}
                        </Avatar>
                    </ColoredTooltip>
                );
            })}
            {!!usersAboveLimit
                    && (
                        <Dropdown
                            placement="bottomRight"
                            menu={usersMenu}
                            trigger={['click']}
                        >
                            <Avatar size={32} gap={7} className={styles['avatar-dropdown']}>
                                {`+${usersAboveLimit}`}
                            </Avatar>
                        </Dropdown>
                    )}
            <Button
                onClick={() => handleFileAction?.(usersLength ? 'manage_access' : 'share', fid)}
                className={styles['manage-access-button']}
            >
                {t(`${nameSpace}.${usersLength ? 'manageAccess' : 'shareFile'}`)}
            </Button>
        </div>
    );
});

export default FileInfoUserList;
