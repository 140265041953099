import { ColumnProps, ScrollParams } from 'react-virtualized';
import { ScreenMap } from 'antd/lib/_util/responsiveObserver';

import { FULL_PERCENTS } from '@/consts';
import { OpenOptions } from '../../../config/openOptions';
import { FileMenu, SimpleCallback } from '../../../types/types';
import { CELLS_SIZES } from './constants';

const SCROLL_PERCENTS = 99;

export const getVTableBody = (parent: HTMLElement): HTMLElement => (
    parent?.querySelector('.ReactVirtualized__Table__Grid')
);

export const onScrollFilesTable = (params: ScrollParams, showMore: SimpleCallback, hasPageToken: boolean): void => {
    const { scrollTop, scrollHeight, clientHeight } = params;
    const heightDiff: number = scrollHeight - clientHeight;
    const scrollPercents = (scrollTop / heightDiff) * FULL_PERCENTS;
    if (scrollPercents >= SCROLL_PERCENTS && hasPageToken) {
        showMore();
    }
};

export const getFileAccessAction = (menu: FileMenu): OpenOptions | null => {
    const openWith = menu?.open_with ?? [];
    const downloadOption = menu?.options?.find((option) => option === OpenOptions.download) as OpenOptions;
    if (openWith.length === 1 || downloadOption) {
        const allActions: OpenOptions[] = downloadOption ? [...openWith, downloadOption] : openWith;
        return allActions.length === 1 ? allActions[0] : null;
    }
    return null;
};

type AllCells = 'checkbox'
    | 'filename'
    | 'owner'
    | 'lastUsed'
    | 'fileSize'
    | 'actions'
    | 'actionsEmpty'
    | 'policy';

type CellSizes = Required<Pick<ColumnProps, 'width'>> & Pick<ColumnProps, 'flexShrink' | 'minWidth' | 'flexGrow'>;

export const getCellSizes = (screens: ScreenMap): Record<AllCells, CellSizes> => ({
    checkbox: {
        width: CELLS_SIZES.checkboxCellFlexBasis,
    },
    filename: {
        width: CELLS_SIZES.fileNameFlexBasis,
        minWidth: CELLS_SIZES.fileNameMinWidth,
        flexGrow: CELLS_SIZES.fileNameFlexGrow,
        flexShrink: CELLS_SIZES.fileNameFlexShrink,
    },
    owner: {
        width: CELLS_SIZES.ownerFlexBasis,
    },
    policy: {
        width: screens.lg ? CELLS_SIZES.policyFlexBasisLg : CELLS_SIZES.policyFlexBasis,
    },
    lastUsed: {
        width: screens.lg ? CELLS_SIZES.lastUsedFlexBasisLg : CELLS_SIZES.lastUsedFlexBasisMd,
        flexShrink: CELLS_SIZES.lastUsedFlexShrink,
    },
    fileSize: {
        width: CELLS_SIZES.fileSizeFlexBasis,
    },
    actions: {
        width: screens.lg ? CELLS_SIZES.actionsFlexBasisLg : CELLS_SIZES.actionsFlexBasisMd,
    },
    actionsEmpty: {
        width: CELLS_SIZES.actionsEmptyFlexBasis,
    },
});
