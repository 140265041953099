import React, { FC } from 'react';

import { message } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import i18n from '@/content';
import PolicySelect from '../../../../Common/PolicySelect';
import FileInfoUserList from './FileInfoUserList';
import FileInfoButtons from './FileDetailsButtons';
import { useStores } from '../../../../hooks';
import { FileActionType } from '../../../FileAction/menuItems';
import { Policy } from '@/types/types';
import { BASEURL, ENDPOINTS } from '../../../../../api';
import { captureUnexpectedNetworkError, formatDate, bytesToText } from '../../../../utils';
import styles from './FileInfoDetails.module.scss';

const nameSpace = 'filesTable.modals.settings';
const messagesNameSpace = `${nameSpace}.policy.messages`;
const infoDrawerNameSpace = `${nameSpace}.infoDrawer.fields`;

export interface FileInfoDrawerProps {
    handleFileAction: (action: FileActionType, fileId: string) => void;
    setIsLoading: (value: boolean) => void;
}

const FileInfoDetails: FC<FileInfoDrawerProps> = observer(({ handleFileAction, setIsLoading }) => {
    const {
        filesListStore: {
            selectedItem,
            updateFile,
            currentFolderNode,
            currentFolder,
        },
        policyStore,
        authSettingsStore: { API },
    } = useStores();
    const { t } = useTranslation();
    const {
        filename,
        owner,
        file_size: fileSize,
        last_used_time: lastUsedTime,
        last_used_by: lastUsedBy,
        creation_time: creationTime,
        owner_email: ownerEmail,
        policy,
        fid,
        is_folder: isFolder,
    } = selectedItem || {};

    const defaultFolder = t(`${infoDrawerNameSpace}.allFiles`);
    const folder = currentFolderNode.value?.filename || defaultFolder;
    const viewFolderName = currentFolder?.file_id === selectedItem?.file_id && currentFolder?.is_workspace;

    const INFO_ARRAY = [
        {
            title: t(`${infoDrawerNameSpace}.owner`),
            type: 'owner',
            value: owner,
            fullWidth: true,
        },
        {
            title: t(`${infoDrawerNameSpace}.fileSize`),
            type: 'fileSize',
            value: bytesToText(fileSize),
            fullWidth: false,
        },
        {
            title: t(`${infoDrawerNameSpace}.folder`),
            type: 'folder',
            value: folder,
            fullWidth: false,
        },
        {
            title: t(`${infoDrawerNameSpace}.changed`),
            type: 'changed',
            value: `${formatDate(lastUsedTime)}, ${lastUsedBy}`,
            fullWidth: true,
        },
        {
            title: t(`${infoDrawerNameSpace}.created`),
            type: 'created',
            value: `${formatDate(creationTime)}, ${owner || ownerEmail}`,
            fullWidth: true,
        },
    ];

    const onChangePolicy = async (newPolicy: Policy): Promise<void> => {
        // TODO: DRY
        const policyId = newPolicy?.id;
        const displayFileName = filename || fid;
        setIsLoading(true);
        try {
            const fileId = fid;
            await API.put(BASEURL.backend(), ENDPOINTS.filePolicy(fileId), { body: { policy_id: policyId } });
            message.success(i18n.t(`${messagesNameSpace}.success.updateSuccess`, { displayFileName }));
            await updateFile(fileId);
        } catch (error) {
            captureUnexpectedNetworkError(error, 'PolicyModal.onSubmit');
            message.error(i18n.t(`${messagesNameSpace}.error.couldNotSavePolicy`, { displayFileName }));
        }
        setIsLoading(false);
    };

    const changePolicy = async (policyId: string): Promise<void> => {
        const newPolicy = policyStore.policyList.find(({ id }) => id === policyId);
        await onChangePolicy(newPolicy);
    };

    const infoForDisplayHelp = isFolder ? INFO_ARRAY.filter((item) => item.type !== 'fileSize') : INFO_ARRAY;
    const infoForDisplay = viewFolderName
        ? infoForDisplayHelp.filter((item) => item.type !== 'folder')
        : infoForDisplayHelp;

    return (
        <div className={styles['file-details']}>
            <FileInfoUserList handleFileAction={handleFileAction} />
            <div className={styles['file-details-text-wrapper']}>
                {infoForDisplay.map(({ fullWidth, title, value }) => (
                    <div
                        key={title}
                        className={classNames(
                            styles['file-details-text'],
                            { [styles['full-width']]: fullWidth },
                        )}
                    >
                        <div className={styles['info-title']}>{title}</div>
                        <div className={styles['info-value']}>{value}</div>
                    </div>
                ))}
                <div className={classNames(styles['file-details-text'], styles['full-width'])}>
                    <div className={styles['info-title']}>{t(`${infoDrawerNameSpace}.securityLevel`)}</div>
                    <PolicySelect
                        onChange={changePolicy}
                        selectedPolicyId={policy?.id}
                        className={styles['policy-select']}
                    />
                </div>
            </div>
            <FileInfoButtons handleFileAction={handleFileAction} />
        </div>
    );
});

export default FileInfoDetails;
