import React, {
    CSSProperties, FC, memo, useMemo,
} from 'react';

import { Grid, Select } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultOptionType } from 'rc-select/lib/Select';

import {
    getCountriesList, findCountry, Country, SelectedCountry,
} from './countriesList';
import './index.scss';

export { findCountry };
export type { SelectedCountry };

const { Option } = Select;
const { useBreakpoint } = Grid;

const DEFAULT_SELECTOR_STYLE = { width: '114px' };
const OTP_SELECTOR_STYLE = { width: '150px' };
const OTP_SELECTOR_MOBILE_STYLE = { width: '135px' };

const useSelectorStyle = (otpUI: boolean): CSSProperties => {
    const screens = useBreakpoint();
    let selectorStyle = DEFAULT_SELECTOR_STYLE;
    if (otpUI) {
        selectorStyle = screens.lg ? OTP_SELECTOR_STYLE : OTP_SELECTOR_MOBILE_STYLE;
    }
    return selectorStyle;
};

const filter = (input: string, option: DefaultOptionType): boolean => {
    const valueObj: Country = option.data;
    const fullVal = `${valueObj.name.toLowerCase()} ${valueObj.dialCode} ${valueObj.iso2.toLowerCase()}`;
    return fullVal.includes(input.replace('+', '').trim().toLowerCase());
};

interface PrefixSelectorProps {
    disablePhone?: boolean;
    otpUI?: boolean;
    value?: SelectedCountry;
    onChange?: (value: SelectedCountry) => void;
}

const PrefixSelector: FC<PrefixSelectorProps> = ({
    disablePhone,
    value,
    onChange,
    otpUI = false,
}) => {
    const fullCountriesList: Country[] = useMemo(() => getCountriesList(), []);

    const handleChange = (prefix, option): void => {
        onChange({
            iso2: option.data.iso2,
            dialCode: prefix,
            prefix,
        });
    };

    const selectorStyle = useSelectorStyle(otpUI);

    const wrapperClasses = classNames('phone-selector', { 'otp-ui': otpUI, 'share-ui': !otpUI });
    const dropdownClasses = classNames('phone-dropdown', { 'otp-ui': otpUI });

    return (
        <Select
            style={selectorStyle}
            onChange={handleChange}
            value={value?.prefix}
            filterOption={filter}
            className={wrapperClasses}
            popupClassName={dropdownClasses}
            showSearch
            disabled={disablePhone}
            suffixIcon={<CaretDownFilled className="trigger-icon" />}
        >
            {fullCountriesList.map((countryObj) => {
                const { iso2: country, dialCode: prefix } = countryObj;
                const flagClass = classNames('flag', country);
                return (
                    <Option className="country-wrapper" value={prefix} key={prefix} data={countryObj}>
                        <span className={flagClass} />
                        <span className="iso">
                            {`(${country.toUpperCase()})`}
                            {' '}
                            <span dir="ltr">{`+${prefix}`}</span>
                        </span>
                    </Option>
                );
            })}
        </Select>
    );
};

export default memo(PrefixSelector);
