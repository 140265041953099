import React, { FC, memo } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PolicyAggregate, policySettingIdToI18Key } from '../../utils';
import { getPoliciesToDisplay } from './helpers';
import './index.scss';

const nameSpace = 'policy';

interface PolicyDescriptionProps {
    policyAggregate: PolicyAggregate;
    isPublic?: boolean;
}

const EMAIL_LIST_VALUES = ['autoSharing_Value'];

const PolicyDescription: FC<PolicyDescriptionProps> = ({ policyAggregate, isPublic = false }) => {
    const { t } = useTranslation();

    const policySettingsToDisplay = getPoliciesToDisplay(policyAggregate, isPublic);

    return (
        <div className="file-policy-wrap">
            <ul>
                {
                    policySettingsToDisplay.map((policy) => {
                        const policyType = policy.type;
                        const value = policyType === 'checkbox' ? Object.keys(policy.value) : policy.value;

                        const policyKey = policySettingIdToI18Key(policy.id);
                        const isIncludedInEmailListValues = EMAIL_LIST_VALUES.includes(policyKey);
                        const isBoolean = (typeof value === 'boolean' && policyType !== 'input')
                            || isIncludedInEmailListValues;
                        const policyDescription = isIncludedInEmailListValues && Array.isArray(policy.value)
                            ? policy.value.join(', ')
                            : t(`${nameSpace}.${policyKey}.title`, { defaultValue: policy.description });
                        const listClassname = classNames('description', { indented: policy.dependency });
                        return (
                            <li key={policy.id} className={listClassname}>
                                <LockOutlined />
                                <div className="check-text">
                                    {isBoolean
                                        ? policyDescription
                                        : (
                                            <>
                                                {policyDescription}
                                                {' '}
                                                <strong>
                                                    {t('general.time.days__withBrackets', { value: policy.value })}
                                                </strong>
                                            </>
                                        )}
                                </div>
                            </li>
                        );
                    })
                }
                <li className="description">
                    <LockOutlined />
                    <div className="check-text">{t(`${nameSpace}.default.cloudAppProtection`)}</div>
                </li>
            </ul>
        </div>
    );
};

export default memo(PolicyDescription);
