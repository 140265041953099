import React, { FC, memo } from 'react';

import classNames from 'classnames';

import { useDroppableFile } from '../../../FilesTable/FilesDragContext';
import { BreadcrumbToDisplay } from './interfaces';
import { parseFolderId } from '../../../../stores';
import styles from './FolderBreadcrumbs.module.scss';

interface DroppableBreadcrumbProps extends BreadcrumbToDisplay {
    isSelected: boolean;
    isDropAllowed: boolean;
}

const DroppableBreadcrumb: FC<DroppableBreadcrumbProps> = ({
    itemKey,
    name,
    isDropAllowed,
    isSelected,
}) => {
    const {
        setNodeRef,
        isOver,
    } = useDroppableFile({
        itemKey,
        isFolder: true,
        filename: name,
        fileId: parseFolderId(itemKey),
        disabled: !isDropAllowed || isSelected,
    });

    return (
        <span
            ref={setNodeRef}
            className={classNames(
                styles.item,
                {
                    [styles.current]: isSelected,
                    [styles['current-drop-area']]: isOver,
                },
            )}
        >
            {name}
        </span>
    );
};

export default memo(DroppableBreadcrumb);
