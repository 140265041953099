import axios, { AxiosResponse } from 'axios';

import appConfig from '../../config/env';
import { ENDPOINTS } from '../../api';
import { sleep } from '../utils';

const HTML_DOC_STARTS_WITH = '<!doctype html>';

/* eslint-disable camelcase */
interface IsOnpremResponse {
    is_onprem: boolean;
}
/* eslint-enable camelcase */

type AppInfoResponse = IsOnpremResponse | string;

const MAX_APP_INFO_DELAY = 3_000;

const checkHasStorageConnector = async (): Promise<boolean> => {
    const { data }: AxiosResponse<AppInfoResponse> = await axios.get(
        `${appConfig.API_URL}${ENDPOINTS.uploadAppInfo()}`,
    );
    return data && typeof data === 'object' && data.is_onprem;
};

const tryGetAppInfo = async (): Promise<AppInfoResponse> => {
    let result: AppInfoResponse;
    try {
        const response: AxiosResponse<AppInfoResponse> = await axios.get(
            `${appConfig.API_PROXY.APP_URL}${ENDPOINTS.appInfo()}`,
        );
        result = response.data;
    } catch (error) {
        console.log('failed to check app info', error);
    }
    return result;
};

const checkHasAPIProxy = async (): Promise<boolean> => {
    let hasAPIProxy = false;
    const data: AppInfoResponse | void = await Promise.race<AppInfoResponse | void>(
        [tryGetAppInfo(), sleep(MAX_APP_INFO_DELAY)],
    );
    const shouldMakeSecondRequest = !data || (typeof data === 'string' && data.startsWith(HTML_DOC_STARTS_WITH));
    console.log('after /appinfo call, data is', data);
    console.log('shouldMakeSecondRequest is', shouldMakeSecondRequest);

    if (shouldMakeSecondRequest) {
        hasAPIProxy = await checkHasStorageConnector();
    } else if (typeof data === 'object') {
        hasAPIProxy = data.is_onprem;
    }
    return hasAPIProxy;
};

export default checkHasAPIProxy;
