import React, { FC, memo } from 'react';

import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import i18n from '@/content';
import SharedFilesTable from './SharedFilesTable';
import './index.scss';

interface SuccessScreenProps {
    closeDrawer: () => void;
}

const nameSpace = 'uploadFiles.copyToClip';

const SuccessScreen: FC<SuccessScreenProps> = ({ closeDrawer }) => (
    <div className="upload-drawer-sucess">
        <div className="text-block" data-testid="uploadDrawer_messageFileShared">
            <CheckCircleFilled className="success-icon" />
            <div>{i18n.t(`${nameSpace}.title`)}</div>
            <div className="text">{i18n.t(`${nameSpace}.subTitle`)}</div>
        </div>
        <SharedFilesTable />
        <div className="buttons-container">
            <Button type="primary" className="done-button" onClick={closeDrawer} data-testid="uploadDrawer_Done">
                {i18n.t(`${nameSpace}.done`)}
            </Button>
        </div>
    </div>
);

export default memo(SuccessScreen);
