import React, { FC, memo, ReactElement } from 'react';

import { Button, Typography } from 'antd';

import styles from './SharePopupHeader.module.scss';

const { Title } = Typography;

interface HeaderProps {
    toggleFileSettings: () => void;
    title: string;
    toggleText: string;
    toggleIcon: ReactElement;
    hideToggle?: boolean;
}

const SharePopupHeader: FC<HeaderProps> = ({
    toggleFileSettings,
    title,
    toggleText,
    toggleIcon,
    hideToggle,
}) => (
    <div className={styles['popup-header']}>
        <Title level={3}>
            {title}
        </Title>
        {!hideToggle && (
            <Button
                className={styles['toggle-button']}
                icon={toggleIcon}
                size="small"
                onClick={toggleFileSettings}
                data-testid="myFIles_manageAccess"
            >
                {toggleText}
            </Button>
        )}
    </div>
);

export default memo(SharePopupHeader);
