import { useMemo } from 'react';

import { DropdownItem } from '@/types/types';

const getEmailsToDropdown = (currentInputEmail: string, emailsList: string[]): DropdownItem<string>[] => {
    if (currentInputEmail) {
        return emailsList.reduce<DropdownItem<string>[]>((acc, current) => {
            if (current?.toLowerCase()
                .includes((currentInputEmail || '').toLowerCase())) {
                acc.push({ value: current, label: current });
            }
            return acc;
        }, []);
    }
    return [];
};

const useMemoizedEmailsToDropdown = (currentInputEmail: string, emailsList: string[]): DropdownItem<string>[] => (
    useMemo<DropdownItem<string>[]>(
        () => getEmailsToDropdown(currentInputEmail, emailsList),
        [currentInputEmail, emailsList],
    )
);

export default useMemoizedEmailsToDropdown;
