import antEn from 'antd/lib/locale/en_US';
import antHe from 'antd/lib/locale/he_IL';
import { Locale } from 'antd/lib/locale';

export enum LANGUAGES {
    en = 'en',
    he = 'he',
}

interface LanguageDescription {
    value: LANGUAGES;
    label: string;
}

export const SUPPORTED_LANGUAGES: LanguageDescription[] = [
    {
        value: LANGUAGES.en,
        label: 'English',
    },
    {
        value: LANGUAGES.he,
        label: 'עברית',
    },
];

export const MAP_I18N_LANGUAGE_TO_ANT_LOCALE: Record<LANGUAGES, Locale> = {
    [LANGUAGES.en]: antEn,
    [LANGUAGES.he]: antHe,
};

export const SUPPORTED_LANGUAGES_KEYS = SUPPORTED_LANGUAGES.map(({ value }) => value);
