import React, { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { StatusMessage } from '../interfaces';
import styles from './Message.module.scss';

const Message: FC<StatusMessage> = ({ hasError, message, options }) => {
    const { t } = useTranslation();
    const className = classNames(styles.message, { [styles['has-error']]: hasError });

    return (
        <p className={className}>
            {t(message, options)}
        </p>
    );
};

export default memo(Message);
