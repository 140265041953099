import { Message } from './TabsMessenger';
import { getInstance, TopicEmitter, TopicSubscriber } from './messageUtils';

export type AuthMessageTypes = 'signIn' | 'signOut';

export type AuthMessage = Message<AuthMessageTypes>;

// FIXME: too many types
type AuthMessageSubscriber<Payload = void> = TopicSubscriber<AuthMessageTypes, Payload>;

type AuthMessageEmitter<Payload = void> = TopicEmitter<AuthMessageTypes, Payload>;

export const subscribeOnAuthMessage: AuthMessageSubscriber = (listener) => getInstance()
    .subscribe<AuthMessageTypes>('auth', listener);

export const postAuthMessage: AuthMessageEmitter = (message) => getInstance()
    .post<AuthMessageTypes>('auth', message);
