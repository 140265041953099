import React, { Component } from 'react';

import { Drawer, message } from 'antd';
import { IReactionDisposer, reaction, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import i18n from '@/content';
import { BASEURL, ENDPOINTS } from '@/api';
import UploadFiles from './UploadFiles';
import FilesUploaderDragger from '../Common/FilesUploaderDragger';
import Spinner from '../Common/Spin';
import SuccessScreen from './SuccessScreen';
import UploadDisclaimer from './UploadDisclaimer';
import {
    AppStore,
    PolicyStore,
    SharedUsersStore,
    UploadedFile,
    UploadFilesStore,
    UsersPhonesStore,
    UserStore,
} from '../../stores';
import { FileToShare } from '../../stores/SharedUsersStore/interfaces';
import appConfig from '../../config/env';
import {
    captureErrorForSentry,
    checkHasPhoneOnlyPolicyInFilesList,
    onFinishSharingFlow,
} from '../utils';
import { DRAWER_UPLOAD_ONLY_WIDTH_PX, DRAWER_WIDTH_PX } from './consts';
import './index.scss';

const shareNameSpace = 'shareEditPolicy';

interface UploadDrawerProps {
    closeUploader: (newFiles: UploadedFile[]) => void;
    appStore?: AppStore;
    policyStore?: PolicyStore;
    sharedUsersStore?: SharedUsersStore;
    usersPhonesStore?: UsersPhonesStore;
    uploadFilesStore?: UploadFilesStore;
    userStore?: UserStore;
}

interface UploadDrawerState {
    showSuccessStep: boolean;
    showUploadDisclaimer: boolean;
}

// TODO: make this component functional
@inject('appStore', 'policyStore', 'sharedUsersStore', 'uploadFilesStore', 'userStore', 'usersPhonesStore')
@observer
export default class UploadDrawer extends Component<UploadDrawerProps, UploadDrawerState> {
    constructor(props: UploadDrawerProps) {
        super(props);
        this.state = {
            showSuccessStep: false,
            showUploadDisclaimer: false,
        };
    }

    private showSuccessStepRef = false;

    private drawerOpenReactionDisposer: IReactionDisposer;

    componentDidMount(): void {
        const { policyStore, sharedUsersStore, appStore } = this.props;
        const { usersListStore } = appStore;
        // TODO: add retry for policy and emails
        policyStore.fetchPolicyList();
        sharedUsersStore.fetchEmailsList();
        usersListStore.fetchUsersCustomGroups();
        if (appConfig.SHOW_UPLOAD_DISCLAIMER) {
            this.drawerOpenReactionDisposer = reaction<boolean>(
                () => appStore.isFileUploaderOpen,
                (isOpen) => this.setState({ showUploadDisclaimer: isOpen }),
            );
        }
    }

    componentWillUnmount(): void {
        this.drawerOpenReactionDisposer?.();
    }

    closeUploadDisclaimer = (): void => {
        this.setState({ showUploadDisclaimer: false });
    }

    tryCloseAll = async () : Promise<void> => {
        const { appStore: {
            uploadFilesStore,
            filesListStore,
            authSettingsStore: { API },
            fileUploaderState
        },
            closeUploader } = this.props;
        const { uploadedFileIds, successfullyUploadedItems } = uploadFilesStore;
        const { currentFolderId } = filesListStore;
        const uploadOnly = fileUploaderState === 'uploadOnly';
        if (!uploadFilesStore.isUploading) {
            this.setState({
                showSuccessStep: false,
            });
            const filesList = toJS(uploadFilesStore.successfullyUploadedItems);
            closeUploader(filesList);
        }
        if (uploadedFileIds && uploadedFileIds.length && uploadOnly) {
            try {
                const files = successfullyUploadedItems.map(item => {
                    return {
                        file_id: item.fid,
                        filename: item.filename
                    }});
                await API.post(BASEURL.backend(), ENDPOINTS.workspaceNotify(currentFolderId), {
                    body: { files, parent_id: currentFolderId },
                })
            } catch (error) {
                message.error(i18n.t('uploadFiles.messages.error.couldNotNotify'))
            }
        }
    }

    reopenFirstStep = (): void => {
        const { uploadFilesStore, sharedUsersStore } = this.props;
        if (this.showSuccessStepRef) {
            uploadFilesStore.clear();
            sharedUsersStore.clearRecipients();
            this.setShowSuccessPage(false);
        }
    }

    private setShowSuccessPage(value: boolean): void {
        this.showSuccessStepRef = value;
        this.setState({ showSuccessStep: value });
    }

    private openSuccessPage(): void {
        this.setShowSuccessPage(true);
    }

    onShareClick = async (): Promise<void> => {
        const { bulkShareFiles } = this;
        const {
            uploadFilesStore, policyStore, sharedUsersStore, usersPhonesStore,
        } = this.props;
        const { customMessage } = sharedUsersStore;
        let result: boolean = null;
        if (customMessage) {
            result = await this.uploadMessage();
        }
        if (result || !customMessage) {
            onFinishSharingFlow(
                i18n.t.bind(i18n),
                bulkShareFiles,
                checkHasPhoneOnlyPolicyInFilesList(uploadFilesStore.successfullyUploadedItems, policyStore.policyList),
                { sharedUsersStore, uploadFilesStore, usersPhonesStore },
            );
        }
    };

    uploadMessage = async (): Promise<boolean> => {
        const { uploadFilesStore } = this.props;
        const { uploadMessage } = uploadFilesStore;
        const result = uploadMessage();
        return result;
    };

    private bulkShareFiles = async (): Promise<void> => {
        const { sharedUsersStore, uploadFilesStore } = this.props;
        const { successfullyUploadedItems, setIsLoading } = uploadFilesStore;

        const filesToShare: FileToShare[] = successfullyUploadedItems.map<FileToShare>((file) => ({
            file_id: file.fid,
            policy_id: file.policy?.id,
        }));
        try {
            setIsLoading(true);
            await sharedUsersStore.bulkShareFiles({ files: filesToShare });
            this.openSuccessPage();
        } catch (error) {
            console.log('bulkShareFiles fail', error);
            captureErrorForSentry(error, 'UploadDrawer.bulkShareFiles');
            message.error(i18n.t(`${shareNameSpace}.result.error`));
        } finally {
            setIsLoading(false);
        }
    }

    render(): JSX.Element {
        const { appStore, uploadFilesStore } = this.props;
        const { fileUploaderState } = appStore;
        const { isLoading } = uploadFilesStore;
        const { showSuccessStep, showUploadDisclaimer } = this.state;
        const isUploaderOpen = fileUploaderState !== 'closed';
        const uploadOnly = fileUploaderState === 'uploadOnly';

        return (
            <>
                <Drawer
                    destroyOnClose
                    title={(
                        <FilesUploaderDragger
                            drawerState={showSuccessStep ? 'success' : 'upload'}
                            reopenFirstStep={this.reopenFirstStep}
                        />
                    )}
                    width={uploadOnly ? DRAWER_UPLOAD_ONLY_WIDTH_PX : DRAWER_WIDTH_PX}
                    className="drawer-wrapper"
                    closable={false}
                    onClose={this.tryCloseAll}
                    open={isUploaderOpen}
                >
                    <Spinner spinning={isLoading}>
                        {!showSuccessStep ? (
                            <UploadFiles
                                uploadOnly={uploadOnly}
                                closeDrawer={this.tryCloseAll}
                                onShareClick={this.onShareClick}
                            />
                        )
                            : <SuccessScreen closeDrawer={this.tryCloseAll} />}
                    </Spinner>
                </Drawer>
                <UploadDisclaimer isOpen={showUploadDisclaimer} close={this.closeUploadDisclaimer} />
            </>
        );
    }
}
export { LIST_WIDTH_PX } from './consts';
