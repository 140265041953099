import React, { FC } from 'react';

import { Button } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppRoutes } from '@/config/appRoutes';

import styles from './OpenAuditsButton.module.scss';

const nameSpace = 'myFiles.header.buttons';

interface OpenAuditsProps {
    currentFolderId: string;
}

const OpenAuditsButton: FC<OpenAuditsProps> = ({ currentFolderId }) => {
    const { t } = useTranslation();
    const hrefs = `${AppRoutes.auditLogs}?file_id=${currentFolderId}`;

    return (
        <Link to={hrefs}>
            <Button
                icon={<SolutionOutlined className={styles.icon} />}
                className={styles['open-button']}
            >
                {t(`${nameSpace}.auditFolder`)}
            </Button>
        </Link>
    );
};

export default OpenAuditsButton;
