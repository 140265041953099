import React, { FC } from 'react';

import { Typography } from 'antd';
import { observer } from 'mobx-react';

import i18n from '@/content';
import styles from './DashboardHeader.module.scss';
import { useStores } from '../../../hooks';

const { Title } = Typography;

const nameSpace = 'domainsDashboard';

const DashboardHeader: FC = observer(() => {
    const { sharingStatsStore: { totalShares } } = useStores();

    return (
        <Title level={2} className={styles['dashboard-header']}>
            {i18n.t(`${nameSpace}.pageTitle`, { totalShares: totalShares ?? 0 })}
        </Title>
    );
});

export default DashboardHeader;
