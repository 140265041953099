import React, { FC } from 'react';

import { observer } from 'mobx-react';
import classNames from 'classnames';

import BatchDeleteButton from './BatchDeleteButton';
import BatchDownloadButton from './BatchDownloadButton';
import OpenUploaderButton from './OpenUploaderButton';
import OpenAuditsButton from './OpenAuditsButton';
import OpenFolderInfoButton from './OpenFolderInfoButton';
import { useStores } from '../../../hooks';
import styles from './ExtraContent.module.scss';

const ExtraContent: FC = observer(() => {
    const {
        filesListStore: {
            isMyFiles,
            isShared,
            selectedItemsCount,
            currentNonRootKey,
            currentFolder,
            displayFilesType,
            currentFolderId,
            setChosenTableFileId,
            hasBatchDownload,
        },
        userStore: { isExternal },
    } = useStores();

    const showViewInfo = currentFolder?.is_workspace && currentFolder?.menu?.edit_shares;
    const showSendButton = isShared && isExternal && !currentNonRootKey;
    const showAddFiles = isMyFiles || currentFolder?.menu?.options?.includes('add_files');
    const hasAbilityToSeeLogs = displayFilesType !== 'sharedFiles' || currentFolder?.menu?.options?.includes('audits');
    const showLogsFiles = hasAbilityToSeeLogs && currentFolderId;
    const areSeveralItemsSelected = selectedItemsCount > 1;
    const hasContent = showSendButton || areSeveralItemsSelected || showAddFiles;

    return (
        <div className={classNames(styles.wrapper, { [styles['has-content']]: hasContent })}>
            {hasBatchDownload && (
                <BatchDownloadButton />
            )}
            {areSeveralItemsSelected && (
                <BatchDeleteButton />
            )}
            {showAddFiles && <OpenUploaderButton />}
            {showLogsFiles && <OpenAuditsButton currentFolderId={currentFolderId} />}
            {showViewInfo
                && (
                    <OpenFolderInfoButton
                        setChosenTableFileId={setChosenTableFileId}
                        currentFolderId={currentFolderId}
                    />
                )}
        </div>
    );
});

export default ExtraContent;
