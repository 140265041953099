import React, { FC, useEffect } from 'react';

import {
    Result, Row, Col,
} from 'antd';
import { observer } from 'mobx-react';
import i18n from '@/content';

import TabTitle from '../../Common/TabTitle';
import Spinner from '../../Common/Spin';
import DashboardHeader from './DashboardHeader';
import ShareStatsTable from './ShareStatsTable';
import Charts from './Charts';
import { useStores } from '../../hooks';

const nameSpace = 'domainsDashboard';

const DomainsDashboard: FC = observer(() => {
    const { sharingStatsStore } = useStores();

    useEffect(() => {
        sharingStatsStore.fetchSharingStats();
    }, []);

    const { sharingStatsLoadingStatus, hasData } = sharingStatsStore;
    const {
        isLoading, hasError, errorMessageKey,
    } = sharingStatsLoadingStatus;

    return (
        <Spinner spinning={isLoading} fullHeight screenCentered>
            <TabTitle title={i18n.t(`${nameSpace}.tabTitle`)} />
            <div>
                {hasError
                    ? (
                        <Result
                            status="500"
                            title={i18n.t(errorMessageKey)}
                            subTitle={i18n.t('general.errors.try')}
                        />
                    )
                    : hasData && (
                        <Row justify="center">
                            <Col span={22}>
                                <DashboardHeader />
                                <Charts />
                                <ShareStatsTable />
                            </Col>
                        </Row>
                    )}
            </div>
        </Spinner>
    );
});

export default DomainsDashboard;
