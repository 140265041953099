const validateNumRange = (value: number, min: number, max: number, isInt = false): boolean => {
    const isInRange = value >= min && value <= max;
    let result: boolean = isInRange;
    if (isInRange && isInt) {
        result = result && Number.isInteger(value);
    }
    return result;
};

export default validateNumRange;
