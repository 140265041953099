import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    useRef,
    useState,
} from 'react';

import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

import type { OTPAuthType } from '@/types/types';

type HookResult = [Dispatch<SetStateAction<boolean>>, (newValue: string) => void, ReactNode];

const useSecondStepTitle = (
    initValue: string,
    codeLength: number,
    t: TFunction,
    nameSpace: string,
    codeSendingMethod?: Exclude<OTPAuthType, 'email'>,
): HookResult => {
    const [showIdentity, setShowIdentity] = useState<boolean>(false);
    const identityRef = useRef<string>(initValue);

    const recipient = identityRef.current;

    let titleAfterSend = '';
    let titleBeforeSend = '';

    switch (codeSendingMethod) {
        case 'phone':
            titleAfterSend = `${nameSpace}.titleAfterSendBySMS`;
            titleBeforeSend = `${nameSpace}.titleBeforeSendBySMS`;
            break;
        case 'voice':
            titleAfterSend = `${nameSpace}.titleAfterSendByPhoneCall`;
            titleBeforeSend = `${nameSpace}.titleBeforeSendByPhoneCall`;
            break;
    }

    const secondStepTitle = recipient && showIdentity
        ? (
            <Trans
                i18nKey={titleAfterSend || `${nameSpace}.titleAfterSend`}
                t={t}
                tOptions={{ codeLength, recipient }}
            >
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                Please enter codeLength code sent to <strong>recipient</strong>
            </Trans>
        )
        : t(titleBeforeSend || `${nameSpace}.titleBeforeSend`, { codeLength });

    const setNewTitle = (newValue: string): void => {
        identityRef.current = newValue;
        setShowIdentity(true);
    };

    return [setShowIdentity, setNewTitle, secondStepTitle];
};

export default useSecondStepTitle;
