import React, { FC } from 'react';

import { observer } from 'mobx-react';

import CopyLinkWithStateIndicator from './CopyLinkWithStateIndicator';
import { useStores } from '../../hooks';
import { getLinkData } from './common';

export type UIMode = 'textBox' | 'button';

export interface CopyShareLinkProps {
    linkKey: string;
    showTextBoxButton?: boolean;
    UIMode?: UIMode;
}

const CopyShareLink: FC<CopyShareLinkProps> = observer(({
    linkKey,
    showTextBoxButton,
    UIMode = 'textBox',
}) => {
    const { sharedUsersStore: { shareLinks } } = useStores();
    const { link, isLinkLoading } = getLinkData(shareLinks, linkKey);

    return (
        <CopyLinkWithStateIndicator
            link={link}
            isLinkLoading={isLinkLoading}
            linkKey={linkKey}
            showButton={showTextBoxButton}
            UIMode={UIMode}
        />
    );
});

export default CopyShareLink;
