import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import i18n from '@/content';
import Spinner from '../../../../Common/Spin';
import { useStores } from '../../../../hooks';
import styles from './SelectionUnavailable.module.scss';

const nameSpace = 'uploadFiles.filePolicy';

interface SelectionUnavailableProps {
    wrapperClassname?: string;
}

const SelectionUnavailable: FC<SelectionUnavailableProps> = observer(({ wrapperClassname }) => {
    const { policyStore, uploadFilesStore } = useStores();
    const { hasFilePolicyError, fetchPolicyList } = policyStore;

    const retryLoadPolicies = async (): Promise<void> => {
        await fetchPolicyList();
        if (policyStore.hasPolicies) {
            uploadFilesStore.injectDefaultPolicies();
        }
    };

    return (
        <div className={classNames(styles.wrapper, { [wrapperClassname]: !!wrapperClassname })}>
            {hasFilePolicyError
                ? (
                    <>
                        <span className={styles['error-message']}>
                            {i18n.t(`${nameSpace}.policyLoadError`)}
                        </span>
                        <Button
                            className={styles['retry-btn']}
                            type="link"
                            onClick={retryLoadPolicies}
                        >
                            {i18n.t('general.buttons.tryAgain')}
                        </Button>
                    </>
                )
                : <Spinner hasIconTranslate={false} />}
        </div>
    );
});

export default SelectionUnavailable;
