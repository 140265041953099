import { NotFoundError, PermissionsError } from '../../utils';
import { PermissionsCheckFail } from '../Common';
import {
    BASEURL,
    ENDPOINTS,
    SPXAPI,
    addPermissionsToFile,
    AddPermissionsParams,
} from '../../../api';
import { EntityLoadErrorType } from '../../../types/types';

export const errorToErrorType = (error: unknown): EntityLoadErrorType => {
    switch (true) {
    case error instanceof PermissionsError:
        return 'noPermissions';
    case error instanceof PermissionsCheckFail:
        return 'permissionCheckFail';
    case error instanceof NotFoundError:
        return 'notFound';
    default:
        return 'serverError';
    }
};

export const verifyAccess = async (API: SPXAPI, fileId: string, code?: string): Promise<void> => API.post(
    BASEURL.backend(),
    ENDPOINTS.verifyAccess(fileId),
    { body: { auth_code: code } },
);

const tryGetResponseError = async (asyncCall: () => Promise<void>): Promise<unknown> => {
    let errorResponse: unknown = null;
    try {
        await asyncCall();
    } catch (error) {
        errorResponse = error;
    }
    return errorResponse;
};

/*
* Call order is important here. Possible error with more priority should be first.
* */
export const validateAccess = async (
    API: SPXAPI,
    { fileId, linkId, email }: AddPermissionsParams,
    onError: (error: unknown) => void,
): Promise<void> => {
    const errors: unknown[] = await Promise.all<unknown>([
        tryGetResponseError(() => addPermissionsToFile(API, { fileId, linkId, email }, onError)),
        tryGetResponseError(() => verifyAccess(API, fileId)),
    ]);
    const firstError = errors.find(Boolean);
    if (firstError) {
        throw firstError;
    }
};
