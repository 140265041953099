import {
    action,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from 'mobx';
import { NavigateFunction } from 'react-router';
import { isEqual, unionBy } from 'lodash';

import { FOLDER_ID_QUERY_PARAM, ROOT_FOLDER } from '@/consts';
import AuthSettingsStore from '../../AuthSettingsStore';
import UserStore from '../../UserStore';
import CancellableAPI from '../../../api/CancellableAPI';
import FilesTree from './FilesTree';
import RecursiveLoader from './RecursiveLoader';
import FilesListLoader from '../FilesListLoader';
import FilesListRefresher from './FilesListRefresher';
import {
    captureErrorForSentry,
    captureNon404Error,
    captureUnexpectedNetworkError,
    checkIs404,
    uuidv4,
} from '../../../components/utils';
import { SecuredFile } from '@/types/types';
import { UploadedFile, UploadedItem } from '../../UploadFilesStore';
import {
    DeleteFileResult,
    FileBreadcrumbsResponse,
    FilesListType,
    FilesRequestResult,
    FileToDisplay,
    FolderLike,
    FolderNode,
    FolderNodePayload,
    FolderReceivingDetails,
    LoadParams,
    OnDelete,
    Ordering,
    RefreshOptions,
    RefreshResult,
    SortableFields,
} from '../interfaces';
import { BASEURL, ENDPOINTS } from '../../../api';
import {
    findRelatedFolder,
    getDeleteFileEndpoint,
    getFilesForUpdate,
    getFilesIdsSet,
    getFolderBreadcrumbs,
    getNewChildNodesKeys,
    getSortedRefreshedFiles,
    mergeCompletedFiles,
    parseFolderId,
    syncFetchedFilesWithUploaded,
    transformFilesToTreeNodes,
    transformTreeNodesToDisplayFiles,
    transformTreeNodesToFiles,
} from '../helpers';
import {
    DEFAULT_ORDERING,
    INIT_FOLDER_PAYLOAD,
    MAP_ROUTE_TO_FILES_LIST_TYPE,
    MIN_FILES_TO_FETCH_NEXT,
    PAGE_SIZE_DEFAULT,
} from '../options';

/*
* TODO: this component become too large
*  We need to split it on parts. It should be pure store with getters and setters, and helper classes for each flow
*  Example:
*  class BaseFilesManager {
*    protected readonly store: FilesStore;
*    protected readonly filesListModule: FilesListModule;
*    protected readonly foldersModule: FoldersModule;
*    protected readonly refreshModule: RefreshModule;
*  }
* */

class BaseFilesManager {
    @observable
    currentFolderKey = ROOT_FOLDER;

    @observable
    selectedItemsKeys: string[] = [];

    @observable
    searchedFileName = '';

    @observable
    orderingFields: SortableFields[] = [];

    @observable
    chosenTableFileId = '';

    public readonly filesTree: FilesTree;

    private readonly recursiveLoader: RecursiveLoader;

    protected readonly filesListType: FilesListType;

    private readonly refresher: FilesListRefresher;

    protected readonly authSettingsStore: AuthSettingsStore;

    protected readonly userStore: UserStore;

    private readonly filesListLoader: FilesListLoader;

    private readonly cancellableAPI: CancellableAPI;

    public navigateFunction: NavigateFunction;

    private currentFolderDisposer: IReactionDisposer;

    constructor(
        filesListType: FilesListType,
        authSettingsStore: AuthSettingsStore,
        userStore: UserStore,
        filesListLoader: FilesListLoader,
        orderingFields: SortableFields[] = [],
    ) {
        this.filesListLoader = filesListLoader;
        filesListLoader.subscribeOnComplete(
            filesListType,
            (filesList, folderKey) => this.onFilesComplete(filesList, folderKey),
        );
        this.authSettingsStore = authSettingsStore;
        this.userStore = userStore;
        this.cancellableAPI = new CancellableAPI(authSettingsStore, { failSilently: false });
        this.filesListType = filesListType;
        this.orderingFields = orderingFields;
        this.filesTree = new FilesTree(
            ROOT_FOLDER,
            null,
            { ...INIT_FOLDER_PAYLOAD },
            (addedKeys: string[], removedKeys: string[]): void => this.onChangeTree(addedKeys, removedKeys),
        );
        this.recursiveLoader = new RecursiveLoader(
            authSettingsStore,
            (folderKey: string, result: FilesRequestResult) => this.onChildFolderLoad(folderKey, result),
            (folderKey: string, error: unknown) => this.onChildFolderLoadError(folderKey, error),
        );
        // TODO: probably it will be better pass refresher instance and then subscribe on complete and errors
        this.refresher = new FilesListRefresher({
            filesListType,
            authSettingsStore,
            userStore,
            onRefreshCompleted: (result) => this.onRefreshFinish(result),
            onRefreshError: (isFolderRemoved, folderKey) => this.onRefreshError(isFolderRemoved, folderKey),
        });
        makeObservable(this);
    }

    /*
    * Computed and actions
    * */
    get root(): FolderNode {
        return this.filesTree.root;
    }

    @computed
    get currentFolder(): FolderNode {
        return this.filesTree.find(this.currentFolderKey);
    }

    @computed
    get currentNonRootKey(): string {
        return this.currentFolderKey === ROOT_FOLDER ? '' : this.currentFolderKey;
    }

    @computed
    get currentFolderId(): string {
        return this.currentFolderKey === ROOT_FOLDER ? ROOT_FOLDER : this.currentFolder.value?.fid;
    }

    @computed
    get currentFolderBreadcrumbs(): FileToDisplay[] {
        return getFolderBreadcrumbs(this.filesTree.root, this.currentFolderKey);
    }

    @computed
    get isNextFilesLoading(): boolean {
        return this.currentFolder.payload?.isNextFilesLoading;
    }

    @computed
    get isLoading(): boolean {
        return this.currentFolder.payload?.isLoading;
    }

    @computed
    get ordering(): Ordering {
        return this.currentFolder.payload?.ordering;
    }

    @computed
    get currentFolderFiles(): SecuredFile[] {
        return transformTreeNodesToFiles(this.currentFolder.children);
    }

    @computed
    get filesToDisplay(): FileToDisplay[] {
        return transformTreeNodesToDisplayFiles(this.currentFolder.children);
    }

    @computed
    get selectedItems(): FileToDisplay[] {
        const selectedKeysSet: ReadonlySet<string> = new Set<string>(this.selectedItemsKeys);
        return this.filesToDisplay.filter(({ fid }) => selectedKeysSet.has(fid));
    }

    @computed
    get selectedFiles(): FileToDisplay[] {
        return this.selectedItems.filter(({ is_folder: isFolder }) => !isFolder);
    }

    @computed
    get selectedItem(): FileToDisplay {
        return this.getSelectedItem(this.chosenTableFileId);
    }

    @action
    setChosenTableFileId =(value: string): void => {
        this.chosenTableFileId = value;
    }

    @action
    setCurrentFolderKey(key: string): void {
        this.currentFolderKey = key;
    }

    @action
    setSearchedFileName = (value: string): void => {
        this.searchedFileName = value;
    }

    @action
    setSelectedItems = (keys: string[]): void => {
        this.selectedItemsKeys = keys;
    }

    private setOrdering(orderBy: SortableFields): void {
        this.updateCurrentFolderPayload({
            ordering: orderBy ? { orderBy, isDescending: DEFAULT_ORDERING.isDescending } : null,
        });
    }

    private toggleOrdering(): void {
        const { ordering } = this;
        if (ordering) {
            this.updateCurrentFolderPayload({
                ordering: { ...ordering, isDescending: !ordering?.isDescending },
            });
        }
    }

    changeOrdering = async (orderBy: SortableFields | null): Promise<void> => {
        if (orderBy !== this.ordering?.orderBy) {
            this.setOrdering(orderBy);
        } else {
            this.toggleOrdering();
        }
        await this.clearAndFetch(this.currentFolderKey);
    }

    @action
    public syncSelectedItems(newList: SecuredFile[]): void {
        const itemsIdsSet: Set<string> = new Set<string>(newList.map(({ fid }) => fid));
        this.selectedItemsKeys = this.selectedItemsKeys.filter((fid) => itemsIdsSet.has(fid));
        if (!itemsIdsSet.has(this.chosenTableFileId) && this.currentFolderId !== this.chosenTableFileId) {
            this.setChosenTableFileId('');
        }
    }

    @action
    public clearSelectedItems(): void {
        this.selectedItemsKeys = [];
        this.setChosenTableFileId('');
    }

    /*
    * Folder related getters and setters
    * */
    protected setCurrentFolderFiles(newList: SecuredFile[]): void {
        this.filesTree.refreshChildrenValues(
            this.currentFolderKey,
            transformFilesToTreeNodes(newList, this.currentFolder),
        );
        this.syncSelectedItems(newList);
    }

    setCurrentFolderLoading(value: boolean): void {
        this.updateCurrentFolderPayload({ isLoading: value });
    }

    protected findTargetFolder(folderKey: string): FolderNode {
        return this.currentFolderKey === folderKey ? this.currentFolder : this.filesTree.find(folderKey);
    }

    private getTargetFolder(folder: FolderLike): FolderNode {
        return typeof folder === 'string' ? this.findTargetFolder(folder) : folder;
    }

    protected getFolderFiles(folder: FolderLike): SecuredFile[] {
        const targetFolder: FolderNode = this.getTargetFolder(folder);
        if (targetFolder?.key === this.currentFolderKey) {
            return this.currentFolderFiles;
        }
        return transformTreeNodesToFiles(targetFolder?.children);
    }

    private resetCurrentFolderPayload(): void {
        this.updateCurrentFolderPayload({ ...INIT_FOLDER_PAYLOAD });
    }

    protected setFolderFiles(folder: FolderLike, newList: SecuredFile[]): void {
        const targetFolder: FolderNode = this.getTargetFolder(folder);
        if (targetFolder?.key === this.currentFolderKey) {
            this.setCurrentFolderFiles(newList);
        } else if (targetFolder) {
            this.filesTree.refreshChildrenValues(
                targetFolder.key,
                transformFilesToTreeNodes(newList, targetFolder),
            );
        }
    }

    protected setFolderLoading(folder: FolderLike, value: boolean): void {
        this.setFolderPayload(folder, { isLoading: value });
    }

    private setFolderValue(value: SecuredFile): void {
        this.filesTree.setNodeValue(this.currentFolderKey, value);
    }

    private updateCurrentFolderPayload(newPayload: Partial<FolderNodePayload>): void {
        this.filesTree.setNodePayload(this.currentFolderKey, { ...this.currentFolder.payload, ...newPayload });
    }

    protected setFolderNextFilesLoading(folder: FolderLike, value: boolean): void {
        this.setFolderPayload(folder, { isNextFilesLoading: value });
    }

    protected setFolderPayload(folder: FolderLike, newPayload: Partial<FolderNodePayload>): void {
        const targetFolder: FolderNode = this.getTargetFolder(folder);
        if (targetFolder) {
            if (targetFolder.key === this.currentFolderKey) {
                this.updateCurrentFolderPayload(newPayload);
            } else {
                this.filesTree.setNodePayload(targetFolder.key, { ...targetFolder.payload, ...newPayload });
            }
        } else {
            console.error('Folder not found', folder);
        }
    }

    private setCurrentFolderPageToken(pageToken: string): void {
        this.updateCurrentFolderPayload({ pageToken });
    }

    private setFolderPageToken(folder: FolderLike, pageToken: string): void {
        this.setFolderPayload(folder, { pageToken });
    }

    /*
    * Set up
    * */
    setUp(navigate?: NavigateFunction): void {
        this.navigateFunction = navigate;
        this.refresher.start();
        this.watchOnNavigation();
    }

    unmount(): void {
        this.stopRefresh();
        this.clearTree();
        runInAction(() => {
            this.destroyFilters();
        });
        this.currentFolderDisposer?.();
    }

    public destroyFilters(): void {
        this.searchedFileName = '';
    }

    private cancelAllRequests(): void {
        this.recursiveLoader.cancelAll();
        this.filesListLoader.cancelBothPacks(this.filesListType);
        this.cancellableAPI.cancelAll();
    }

    protected clearTree(): void {
        this.filesTree.clear();
        this.cancelAllRequests();
        this.setCurrentFolderKey(this.filesTree.root.key);
        this.syncSelectedItems([]);
    }

    private cancelSubtreeRequests(folderKey: string): void {
        this.filesTree.getChildNodesKeys(this.currentFolder)
            .forEach((key) => this.recursiveLoader.cancel(parseFolderId(key)));
        const folderId = parseFolderId(folderKey);
        this.filesListLoader.cancelTargetRequests(this.filesListType, `parent_folder=${folderId}`);
        this.cancellableAPI.cancelByPartialKey(folderId);
    }

    private clearSubTreeFiles(folderKey: string): void {
        if (!folderKey || folderKey === ROOT_FOLDER) {
            this.cancelAllRequests();
        } else {
            this.cancelSubtreeRequests(folderKey);
        }
        this.resetPaginator();
        this.clearFilesList();
    }

    /*
    * Common
    * */
    clearFilesList(): void {
        this.setCurrentFolderFiles([]);
    }

    deleteCurrentFolderContent = (fileIds: string | string[]): void => {
        if (typeof fileIds === 'string' && fileIds === this.currentFolderId) {
            this.tryRemoveFolder(this.currentFolderKey);
        } else {
            this.deleteFilesFromCurrentFolder(fileIds);
        }
    }

    deleteFilesFromCurrentFolder = (fileIds: string | string[]): void => {
        this.handleDeleteFiles(this.currentFolderKey, fileIds);
    }

    getSelectedItem = (fileId: string): FileToDisplay => {
        if (this.currentFolderId === fileId) {
            return <FileToDisplay> this.currentFolder.value;
        }
        return this.filesToDisplay.find(({ fid }) => fid === fileId);
    }

    tryDeleteFileFromBE = async (fileId: string): Promise<DeleteFileResult> => {
        const {
            userStore: { currentUserIdentity },
            authSettingsStore: { API },
            filesListType,
        } = this;
        let succeed = false;
        const file = this.currentFolder.value?.fid === fileId
            ? this.currentFolder.value
            : this.filesToDisplay.find(({ fid }) => fid === fileId);

        if (file) {
            try {
                const endpoint = getDeleteFileEndpoint({
                    file,
                    filesListType,
                    currentUserIdentity,
                });
                await API.del(BASEURL.backend(), endpoint, {});
                succeed = true;
            } catch (error) {
                if (checkIs404(error)) {
                    succeed = true;
                } else {
                    captureUnexpectedNetworkError(error, 'FilesTable.deleteFile');
                }
            }
        }
        return { fileId, filename: file?.filename, succeed };
    }

    private async deleteFilesList(selectedFilesKeys: string[], onDelete?: OnDelete): Promise<void> {
        let succeedDeleted: string[] = [];
        const { currentFolderKey: initFolderKey } = this;
        await this.rescheduleRefreshAsync<void>(
            async (): Promise<void> => {
                this.setCurrentFolderLoading(true);
                const results: DeleteFileResult[] = await Promise.all(
                    selectedFilesKeys.map(async (fid) => {
                        const result = await this.tryDeleteFileFromBE(fid);
                        onDelete?.(result);
                        return result;
                    }),
                );
                succeedDeleted = results.filter((result) => result.succeed).map((result) => result.fileId);
                this.setFolderLoading(initFolderKey, false);
            },
        );
        this.handleDeleteFiles(initFolderKey, succeedDeleted);
    }

    deleteSelectedFiles = async (onDelete?: OnDelete): Promise<void> => {
        const { selectedItemsKeys } = this;
        await this.deleteFilesList(selectedItemsKeys, onDelete);
    }

    async fetchFileList(targetFolderKey?: string): Promise<void> {
        const folderKey = targetFolderKey || this.currentFolderKey;
        const result: FilesRequestResult | null = await this.tryLoadFiles({
            ...this.getBaseQueryParams(),
            folderKey,
        });
        if (result) {
            const { items, paginator: newPaginator } = result;
            this.setFolderPageToken(folderKey, newPaginator.page_token);
            this.setFolderFiles(folderKey, items);
        }
    }

    async nextFiles(): Promise<void> {
        await this.tryLoadNextFiles(this.currentFolder);
    }

    async refreshFiles(): Promise<void> {
        const { payload: { isLoading }, children } = this.currentFolder;
        if (!(isLoading && !children?.length)) {
            runInAction(() => {
                this.destroyFilters();
            });
            this.resetCurrentFolderPayload();
            await this.clearAndFetch(this.currentFolderKey);
        }
    }

    resetPaginator(): void {
        this.setCurrentFolderPageToken('');
    }

    searchByFilename = async (value: string): Promise<void> => {
        this.setSearchedFileName(value);
        await this.clearAndFetch();
    }

    updateFile = async (fileId: string, isSilent = false): Promise<void> => {
        if (this.currentFolderFiles.find(({ fid }) => fid === fileId)) {
            await this.rescheduleRefreshAsync<void>(
                async (): Promise<void> => {
                    if (!isSilent) {
                        this.setCurrentFolderLoading(true);
                    }
                    let isCanceled = false;
                    const { currentFolderId: initFolderId, currentFolderKey: initFolderKey } = this;
                    try {
                        const { result: fileDetails } = await this.cancellableAPI.get<SecuredFile>(
                            BASEURL.backend(),
                            ENDPOINTS.getFileDetails(fileId),
                            `file_id=${fileId}$parent_folder=${initFolderId}`,
                        );
                        const filesList: SecuredFile[] = this.getFolderFiles(initFolderKey);
                        const fileIndex: number = filesList.findIndex((file) => file.fid === fileId);
                        if (fileIndex >= 0) {
                            const newFilesList = filesList.slice();
                            newFilesList[fileIndex] = fileDetails;
                            this.setFolderFiles(initFolderKey, newFilesList);
                        }
                    } catch (error) {
                        isCanceled = this.cancellableAPI.checkIsCancel(error);
                        captureNon404Error(error, 'FilesListStore.updateFile');
                        console.log('Error while update file', error);
                    }
                    if (!isSilent && !isCanceled) {
                        this.setFolderLoading(initFolderKey, false);
                    }
                },
            );
        }
    }

    public getBaseQueryParams(): LoadParams {
        const {
            filesListType,
            currentFolderKey,
            searchedFileName,
            ordering,
        } = this;
        return {
            filesListType,
            ordering,
            page_size: PAGE_SIZE_DEFAULT,
            folderKey: currentFolderKey,
            search_string: searchedFileName,
        };
    }

    private appendFolderFiles(folder: FolderLike, newFiles: SecuredFile[]): void {
        const currentList: SecuredFile[] = this.getFolderFiles(folder);
        this.setFolderFiles(folder, unionBy(currentList, newFiles, 'fid'));
    }

    public checkIsPendingRecursiveLoading(folderKey: string): boolean {
        return this.recursiveLoader.checkIsLoading(folderKey);
    }

    public async clearAndFetch(folderKey?: string): Promise<void> {
        this.clearSubTreeFiles(folderKey);
        await this.fetchFolder({ folderKey, updateMode: 'forceUpdate' });
    }

    private handleDeleteFiles(folderKey: string, fileIds: string | string[]): void {
        const filesToDelete: string[] = typeof fileIds === 'string' ? [fileIds] : fileIds;
        const newFilesList: SecuredFile[] = this.getFolderFiles(folderKey)
            .filter((file) => !filesToDelete.includes(file.fid));
        this.setFolderFiles(folderKey, newFilesList);
        this.onFilesCountReduce(folderKey, newFilesList.length);
    }

    protected onFilesCountReduce(folderKey: string, filesCount: number): void {
        const folderNode = this.findTargetFolder(folderKey);
        if (folderNode?.payload.pageToken && filesCount <= MIN_FILES_TO_FETCH_NEXT) {
            this.tryLoadNextFiles(folderNode);
        }
    }

    private async tryLoadNextFiles(folderNode: FolderNode): Promise<void> {
        if (folderNode && !folderNode.payload.isNextFilesLoading) {
            const { key, payload: { pageToken } } = folderNode;
            if (!pageToken) {
                console.error('This action shouldn\'t use with empty page_token param');
            } else {
                const asyncCall = async (): Promise<void> => {
                    this.setFolderNextFilesLoading(folderNode, true);
                    const result: FilesRequestResult | null = await this.tryLoadFiles({
                        page_token: pageToken,
                        ...this.getBaseQueryParams(),
                        folderKey: key,
                    }, true);
                    this.onNextFilesLoading(key, result);
                };
                if (folderNode.key === this.currentFolderKey) {
                    await this.rescheduleRefreshAsync<void>(asyncCall);
                } else {
                    await asyncCall();
                }
            }
        }
    }

    private onNextFilesLoading(folderKey: string, result: FilesRequestResult | null): void {
        const folder: FolderNode = this.findTargetFolder(folderKey);
        if (folder) {
            this.setFolderNextFilesLoading(folder, false);
            if (result) {
                const { paginator, items } = result;
                if (paginator) {
                    this.setFolderPageToken(folder, paginator.page_token);
                }
                this.appendFolderFiles(folder, items);
            }
        }
    }

    private prependCurrentFolderFiles(newFiles: SecuredFile[]): void {
        const currentList: SecuredFile[] = this.currentFolderFiles;
        this.setCurrentFolderFiles(unionBy(newFiles, currentList, 'fid'));
    }

    protected prependFolderFiles(folder: FolderLike, newFiles: SecuredFile[]): void {
        const currentList: SecuredFile[] = this.getFolderFiles(folder);
        this.setFolderFiles(folder, unionBy(newFiles, currentList, 'fid'));
    }

    protected tryLoadFiles = async (
        params: LoadParams,
        hasCustomLoader = false,
    ): Promise<FilesRequestResult | null> => {
        let result: FilesRequestResult = null;
        let isCanceled = false;
        const triggerDefaultLoader = !hasCustomLoader;
        if (triggerDefaultLoader) {
            this.setFolderLoading(params.folderKey, true);
        }
        try {
            result = await this.filesListLoader.loadFiles(params);
        } catch (error) {
            isCanceled = this.cancellableAPI.checkIsCancel(error);
            console.log('could not load files', error);
            captureErrorForSentry(error, 'FilesListStore.tryLoadFiles');
        }
        if (triggerDefaultLoader && !isCanceled) {
            this.setFolderLoading(params.folderKey, false);
        }
        return result;
    }

    /*
    * Refresh
    * */
    cancelRefresh(): void {
        this.refresher.cancelRefresh();
    }

    checkHasFilters(): boolean {
        return !!this.searchedFileName;
    }

    initScheduleRefresh(): void {
        const { timeout } = this.refresher;
        if (!timeout) {
            this.tryScheduleRefresh();
        }
    }

    rescheduleRefresh(noDelay = false): void {
        this.cancelRefresh();
        this.tryScheduleRefresh(noDelay);
    }

    async rescheduleRefreshAsync<T>(target: () => Promise<T>, noDelay = false): Promise<T> {
        const unlockKey: string = uuidv4();
        this.cancelRefresh();
        this.refresher.disable(unlockKey);
        const result: T = await target();
        this.refresher.enable(unlockKey);
        this.tryScheduleRefresh(noDelay);
        return result;
    }

    setRefresherToActiveMode = (): void => {
        const { refresher } = this;
        refresher.setActiveMode();
        if (!refresher.isRunning) {
            this.rescheduleRefresh();
        }
    }

    setRefresherToPassiveMode(): void {
        this.refresher.setPassiveMode();
    }

    stopRefresh(): void {
        this.refresher.stop();
    }

    private onFilesComplete(completedFilesList: SecuredFile[], folderKey: string): void {
        const folder: FolderLike = this.findTargetFolder(folderKey);
        if (folder) {
            const currentList: SecuredFile[] = this.getFolderFiles(folder);
            this.setFolderFiles(folder, mergeCompletedFiles(currentList, completedFilesList));
        }
    }

    private onRefreshError(isFolderRemoved: boolean, folderKey: string | null): void {
        console.log('refresh failed', this.filesListType);
        if (isFolderRemoved) {
            this.tryRemoveFolder(folderKey);
        } else {
            this.tryScheduleRefresh();
        }
    }

    private onRefreshFinish({
        files,
        pageToken,
        folder,
    }: RefreshResult): void {
        const { currentFolderId } = this;
        if ((!folder && currentFolderId === ROOT_FOLDER) || folder?.fid === currentFolderId) {
            this.onTopOnlyRefresh(files, pageToken, folder);
        }
        this.tryScheduleRefresh();
    }

    private onTopOnlyRefresh(
        fetchedFiles: SecuredFile[],
        newPageToken: string,
        folder?: SecuredFile,
    ): void {
        if (folder) {
            this.setFolderValue(folder);
        }
        const currentFilesList: SecuredFile[] = this.currentFolderFiles;
        const { pageToken: currentPageToken } = this.currentFolder.payload;
        const fetchedFilesIdsSet = getFilesIdsSet(fetchedFiles);
        const currentFilesIdsSet = getFilesIdsSet(currentFilesList);

        const shouldReplaceFiles = (
            !fetchedFiles.length
            || !currentFilesList.length
            || !newPageToken
            || (!currentPageToken && currentFilesList.length < fetchedFiles.length)
            || isEqual(fetchedFilesIdsSet, currentFilesIdsSet)
        );

        if (shouldReplaceFiles) {
            this.setCurrentFolderFiles(fetchedFiles);
            this.setCurrentFolderPageToken(newPageToken);
        } else {
            this.prependCurrentFolderFiles(getSortedRefreshedFiles(currentFilesIdsSet, fetchedFiles));
        }
    }

    protected getRefresherConfig(): RefreshOptions {
        const searchValue: string = this.searchedFileName;
        return { parentFolderKey: this.currentNonRootKey, searchValue, ordering: this.ordering };
    }

    private runBackgroundRefresh(): void {
        const { refresher } = this;
        const refreshOptions: RefreshOptions = this.getRefresherConfig();
        refresher.runTopOnlyRefresh(refreshOptions);
    }

    private tryScheduleRefresh = (noDelay = false): void => {
        this.refresher.trySchedule(() => this.runBackgroundRefresh(), noDelay);
    }

    /*
     * Folders
     * */
    async fetchFolder(details: FolderReceivingDetails): Promise<void> {
        const oldFolder = this.currentFolder;
        const shouldRefreshWithoutDelay = details.updateMode === 'silentUpdate'
            && !this.checkIsPendingRecursiveLoading(details.folderKey);
        await this.rescheduleRefreshAsync<void>(
            async (): Promise<void> => {
                try {
                    await this.changeFolder(details);
                } catch (error) {
                    console.log('could not change folder', error);
                    captureUnexpectedNetworkError(error, 'FilesListStore.fetchFolder');
                    this.setCurrentFolderKey(oldFolder.key);
                    await this.fetchFileList();
                }
            },
            shouldRefreshWithoutDelay,
        );
    }

    async fetchFolderBreadcrumbs(targetFolderKey: string): Promise<void> {
        this.setCurrentFolderLoading(true);
        const oldFolderKey = this.currentFolderKey;
        try {
            const { result: { breadCrumbs } } = await this.cancellableAPI.get<FileBreadcrumbsResponse>(
                BASEURL.backend(),
                ENDPOINTS.getFilePath(parseFolderId(targetFolderKey)),
                targetFolderKey,
            );
            this.setFolderLoading(oldFolderKey, false);
            const { key } = this.filesTree.insertBreadcrumbs(breadCrumbs);
            this.changeFolder({ folderKey: key });
        } catch (error) {
            console.log('could not fetch breadcrumbs', error);
            // TODO: add pathname match
            this.navigateFunction?.(window.location.pathname, { replace: true });
            if (!this.cancellableAPI.checkIsCancel(error)) {
                console.log('could not fetch breadcrumbs', error);
                captureUnexpectedNetworkError(error, 'BaseFilesManager.fetchFoldersBreadcrumbs');
                if (!this.root.children?.length) {
                    await this.fetchFileList();
                } else {
                    this.setFolderLoading(oldFolderKey, false);
                }
            }
        }
    }

    navigateToRoot(): void {
        this.setCurrentFolderKey(this.root.key);
    }

    private async changeFolder(
        {
            folderKey,
            updateMode = 'noUpdate',
            isLevelDown = false,
        }: FolderReceivingDetails,
    ): Promise<void> {
        const targetKey: string = folderKey || ROOT_FOLDER;
        const currentFolderNode: FolderNode = this.currentFolder;
        const isRoot: boolean = targetKey === ROOT_FOLDER;
        const newFolderNode: FolderNode = isRoot
            ? this.root
            : findRelatedFolder(currentFolderNode, this.filesTree, targetKey, isLevelDown);
        if (newFolderNode) {
            this.setCurrentFolderKey(newFolderNode.key);
            const noChildrenToDisplay: boolean = updateMode === 'forceUpdate' || !newFolderNode.children;
            if (noChildrenToDisplay || newFolderNode.payload.isIncompleted) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { isIncompleted, ...rest } = newFolderNode.payload;
                this.filesTree.setNodePayload(newFolderNode.key, { ...rest });
                if (noChildrenToDisplay) {
                    this.setCurrentFolderFiles([]);
                }
                if (!this.checkIsPendingRecursiveLoading(targetKey)) {
                    await this.fetchFileList();
                } else {
                    this.setCurrentFolderLoading(true);
                }
            }
        } else {
            this.fetchFolderBreadcrumbs(targetKey);
        }
    }

    private handleAddedNodes(addedKeys: string[]): void {
        const addedChildrenKeys: string[] = getNewChildNodesKeys(addedKeys, this.currentFolder);
        this.recursiveLoadFiles(addedChildrenKeys);
    }

    private onChangeFolder(): void {
        const { currentFolder, currentFolderKey } = this;
        const newSearch: string = currentFolderKey === ROOT_FOLDER
            ? ''
            : `?${FOLDER_ID_QUERY_PARAM}=${currentFolderKey}`;
        const { pathname, search: currentSearch } = window.location;
        if (newSearch !== currentSearch
            && MAP_ROUTE_TO_FILES_LIST_TYPE[this.filesListType] === pathname) {
            this.navigateFunction?.(`${pathname}${newSearch}`);
        }
        if (currentFolder.children) {
            const emptyChildrenKeys: string[] = [];
            currentFolder.children.forEach((node) => {
                if (node.type === 'node' && !node.children) {
                    emptyChildrenKeys.push(node.key);
                }
            });
            this.recursiveLoadFiles(emptyChildrenKeys);
        }
        this.clearSelectedItems();
    }

    protected onChangeTree(addedKeys: string[], removedKeys: string[]): void {
        this.handleAddedNodes(addedKeys);
        removedKeys.forEach((key) => {
            this.recursiveLoader.cancel(key);
        });
    }

    private onChildFolderLoad(folderKey: string, result: FilesRequestResult): void {
        this.onRecursiveLoad(folderKey, result);
    }

    private onChildFolderLoadError(folderKey: string, error: unknown): void {
        if (!CancellableAPI.isAnyInstanceCancel(error)) {
            console.log('onChildFolderLoadError', { folderKey, error });
        }
        this.onRecursiveLoad(folderKey);
    }

    private onRecursiveLoad(folderKey: string, result?: FilesRequestResult): void {
        const targetFolder: FolderNode = this.findTargetFolder(folderKey);
        if (targetFolder) {
            this.setFolderLoading(targetFolder, false);
            if (result) {
                this.setFolderPageToken(targetFolder, result.paginator.page_token);
                this.setFolderFiles(targetFolder, result.items);
            }
        }
    }

    private recursiveLoadFiles(folderKeys: string[]): void {
        const { filesListType } = this;
        folderKeys.forEach((folderKey) => {
            this.recursiveLoader.loadFiles({
                filesListType,
                folderKey,
                page_size: PAGE_SIZE_DEFAULT,
            });
        });
    }

    private removeFolder(node: FolderNode): void {
        this.filesTree.removeNode(node);
    }

    private tryRemoveFolder(folderKey: string): void {
        if (folderKey === this.currentFolderKey) {
            const currentFolderNode: FolderNode = this.currentFolder;
            if (currentFolderNode !== this.root) {
                this.navigateToRoot();
                this.removeFolder(currentFolderNode);
            }
        } else {
            this.removeFolder(this.filesTree.find(folderKey));
        }
    }

    private watchOnNavigation(): void {
        this.currentFolderDisposer = reaction<string>(
            () => this.currentFolderKey,
            () => this.onChangeFolder(),
        );
    }

    /*
    * Upload flow
    * */
    fetchNewFiles = async (filesCount: number): Promise<void> => {
        const initFolderKey: string = this.currentFolderKey;
        const fetchedFiles: SecuredFile[] = await this.tryLoadNewFiles(filesCount);
        if (fetchedFiles) {
            this.prependFolderFiles(initFolderKey, fetchedFiles);
        }
    }

    onUploadNewFiles = async (uploadedFiles: UploadedFile[]): Promise<void> => {
        const initFolderKey: string = this.currentFolderKey;
        const uploadedFilesDict: Record<string, UploadedItem> = Object.fromEntries(
            uploadedFiles.map((file) => [file.fid, file]),
        );
        const {
            filesInList,
            filesOutOfListCount,
        } = getFilesForUpdate(this.currentFolderFiles, uploadedFilesDict);

        let newFiles: SecuredFile[] = [];
        if (filesOutOfListCount) {
            newFiles = await this.tryLoadNewFiles(filesOutOfListCount) || [];
        }
        const allFilesForUpdate: SecuredFile[] = [...newFiles, ...filesInList];
        const targetFolder = this.findTargetFolder(initFolderKey);
        if (targetFolder && allFilesForUpdate.length) {
            const synchronisedList: SecuredFile[] = syncFetchedFilesWithUploaded(
                allFilesForUpdate,
                uploadedFilesDict,
                this.updateFile,
            );
            this.prependFolderFiles(targetFolder, synchronisedList);
        }
    }

    private async tryLoadNewFiles(filesCount: number): Promise<SecuredFile[]> {
        return this.rescheduleRefreshAsync<SecuredFile[]>(
            async (): Promise<SecuredFile[]> => {
                const result: FilesRequestResult | null = await this.tryLoadFiles({
                    ...this.getBaseQueryParams(),
                    page_size: filesCount,
                });
                return result?.items;
            },
        );
    }
}

export default BaseFilesManager;
