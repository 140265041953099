import React, { CSSProperties, FC, ReactNode } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import type { TooltipPlacement } from 'antd/lib/tooltip';

import ColoredTooltip from '../ColoredTooltip';

interface BaseTooltipProps {
    children: ReactNode;
    iconClassName?: string;
    overlayStyle?: CSSProperties;
    placement?: TooltipPlacement;
    destroyOnHide?: boolean;
}

const QuestionTooltip: FC<BaseTooltipProps> = ({
    children,
    placement = 'top',
    destroyOnHide = false,
    iconClassName,
}) => (
    <ColoredTooltip
        destroyTooltipOnHide={destroyOnHide}
        placement={placement}
        title={children}
    >
        <QuestionCircleOutlined className={iconClassName} />
    </ColoredTooltip>
);

export default QuestionTooltip;
