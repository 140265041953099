import { ThemeConfig } from 'antd/lib/config-provider';

export const COLORS = {
    primary: '#021136',
    primaryNew: '#091F56',
    specterxGreen: '#19E7D8',
    cta: '#0F778C',
    ctaHover: '#0F778C0C',
    ctaDisabled: '#091F5626',
    ctaActive: '#091F56',
    greenSuccess: '#2EB672',
    lightSilver: '#D8D8D8',
    gray90: '#E5E5E5',
    melrose: '#C1C7D5',
    titaniumWhite: '#F3F4F7',
    error: '#DB0012',
    irisBlue: '#00B8D9',
    warning: '#FAAD14',
    backgroundHover: '#F5F5F5',
    specterxDarkGray: '#979797',
};

export const BASE_THEME_CONFIG: ThemeConfig = {
    token: {
        colorPrimary: COLORS.primary,
        colorError: COLORS.error,
        colorLink: COLORS.primary,
    },
};

export const CONTROLS_SIZES = {
    heightDefault: 34,
    heightSM: 34,
    heightDefaultBorderless: 32,
    borderRadius: 4,
    fontMD: 14,
    fontSM: 12,
};
