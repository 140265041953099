import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoutes } from '../../../../config/appRoutes';
import styles from './PublicHeader.module.scss';
import config from '../../../../config/env';

interface PublicHeaderProps {
    children?: ReactElement;
}

const PublicHeader: FC<PublicHeaderProps> = ({ children }) => (
    <header className={styles.header}>
        <Link to={AppRoutes.myFiles}>
            <img
                alt="x-logo"
                src={`${config.HEADER_SMALL_COMPANY_LOGO}`}
                className={styles.logo}
                data-testid="recipientPage_MyFiles"
            />
        </Link>
        {children}
    </header>
);

export default PublicHeader;
