import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import PolicyStore from '../PolicyStore';
import { PolicySimpleWithMFA } from '../UploadFilesStore/interfaces';
import { SecuredFile } from '../../types/types';
import { BASEURL, ENDPOINTS } from '../../api';
import { changeFilePolicy } from '../../api/requests';
import { captureErrorForSentry } from '../../components/utils';

const WORKSPACES_LIST_LIMIT = 100;
const WORKSPACES_LIST_QUERY = `?page_size=${WORKSPACES_LIST_LIMIT}&partial=true&is_workspace=true`;

interface PolicyWithMFAMixin {
    policy?: PolicySimpleWithMFA;
}

type NewWorkspace = Pick<SecuredFile, 'filename' | 'fid' | 'policy_id'> & PolicyWithMFAMixin;

type WorkspaceMeta = Omit<SecuredFile, 'policy'> & PolicyWithMFAMixin;

class WorkspacesStore {
    constructor(authSettingsStore: AuthSettingsStore, policyStore: PolicyStore) {
        this.authSettingsStore = authSettingsStore;
        this.policyStore = policyStore;
        makeObservable(this);
    }

    @observable chosenForPolicyWorkspaceFid = '';

    @observable workspacesList: WorkspaceMeta[] = [];

    @observable isLoading = false;

    @computed
    get workspacesIdsSet(): ReadonlySet<string> {
        return new Set<string>(this.workspacesList.map(({ fid }) => fid));
    }

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly policyStore: PolicyStore;

    private isWorkspacesListLoading = false;

    newWorkspaceId = '';

    @computed
    get chosenForPolicyWorkspace(): WorkspaceMeta {
        return this.workspacesList.find((item) => item.fid === this.chosenForPolicyWorkspaceFid);
    }

    @computed
    get noWorkspace(): boolean {
        return !this.chosenForPolicyWorkspace;
    }

    @action
    setChosenForPolicyWorkspaceFid = (value: string): void => {
        this.chosenForPolicyWorkspaceFid = value;
        this.setWorkspacesWithPolicyInjection(this.workspacesList);
    }

    @action
    setWorkspacesList = (workspaceList: WorkspaceMeta[]): void => {
        this.workspacesList = workspaceList;
    }

    @action
    setIsLoading = (value: boolean): void => {
        this.isLoading = value;
    }

    @action
    setNewWorkspaceId = (value: string): void => {
        this.newWorkspaceId = value;
    }

    insertNewWorkspace = (workspaceId: string, workspaceName: string): void => {
        const { defaultPolicyWithMFA } = this.policyStore;
        const newWorkspace: NewWorkspace = {
            fid: workspaceId,
            filename: workspaceName,
            policy: null,
            policy_id: null,
        };
        if (defaultPolicyWithMFA) {
            newWorkspace.policy = { ...defaultPolicyWithMFA };
            newWorkspace.policy_id = defaultPolicyWithMFA.id;
        }
        this.setWorkspacesList([
            ...this.workspacesList, newWorkspace as unknown as WorkspaceMeta,
        ]);
        this.setChosenForPolicyWorkspaceFid(workspaceId);
    }

    changePolicy = async (workspaceId: string, policy: PolicySimpleWithMFA): Promise<boolean> => {
        const { API } = this.authSettingsStore;
        this.setIsLoading(true);
        let result = true;
        try {
            await changeFilePolicy(API, workspaceId, policy.id);
            this.changeWorkspace(workspaceId, { policy });
        } catch (error) {
            console.log('could no change workspace policy');
            captureErrorForSentry(error, 'WorkspacesStore.changePolicy');
            result = false;
        }
        this.setIsLoading(false);
        return result;
    }

    fetchAllWorkspaces = async (isSilent = true): Promise<void> => {
        const { API } = this.authSettingsStore;
        this.isWorkspacesListLoading = true;
        if (!isSilent) {
            this.setIsLoading(true);
        }
        try {
            const { items } = await API.get(BASEURL.backend(), ENDPOINTS.getFiles(WORKSPACES_LIST_QUERY), {});
            this.setWorkspacesWithPolicyInjection(items);
        } catch (error) {
            console.log('could not fetch workspaces');
            captureErrorForSentry(error, 'UploadFilesStore.fetchAllWorkspaces');
        }
        if (!isSilent) {
            this.setIsLoading(false);
        }
        this.isWorkspacesListLoading = false;
    }

    refreshWorkspaces = async (): Promise<void> => {
        if (!this.isWorkspacesListLoading) {
            await this.fetchAllWorkspaces();
        }
    }

    private changeWorkspace(workspaceId: string, newDataPartial: Partial<WorkspaceMeta>): void {
        this.setWorkspacesList(
            this.workspacesList.map((workspace) => {
                if (workspaceId === workspace.fid) {
                    return { ...workspace, ...newDataPartial };
                }
                return workspace;
            }),
        );
    }

    private setWorkspacesWithPolicyInjection(workspacesList: WorkspaceMeta[]): void {
        const workspacesWithPolicies = (workspacesList as SecuredFile[]).map<WorkspaceMeta>((item) => {
            if (item.policy) {
                return item;
            }
            const workspacePolicy = this.policyStore.findPolicy(item.policy_id);
            if (workspacePolicy) {
                const { id, title, isMfa } = workspacePolicy;
                return { ...item, policy: { id, title, isMfa } };
            }
            return item;
        });
        this.setWorkspacesList(workspacesWithPolicies);
    }
}

export default WorkspacesStore;
