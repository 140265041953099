import React, { FC, forwardRef } from 'react';

import FileItem from './FileItem';
import FolderItem from './FolderItem';
import { useStores } from '../../../../hooks';
import { UploadedFile } from '../../../../../stores';

interface UploadItemProps extends UploadedFile {
    onRemove?: (file: UploadedFile) => void | Promise<void>;
    chosenForPolicyFileUid?: string;
    uploadedFilesCount?: number;
    foldedFiles?: UploadedFile[];
}

/*
* VirtualList children required wrapping by forwardRef
* */
const UploadItem: FC<UploadItemProps> = forwardRef<HTMLElement, UploadItemProps>(({
    onRemove,
    name,
    status,
    percent,
    uid,
    chosenForPolicyFileUid,
    isFolder,
    size,
    uploadedFilesCount,
    foldedFiles,
    expanded,
    ...rest
// eslint-disable-next-line @typescript-eslint/no-unused-vars
}, ref) => {
    const onRemoveClick = (): void | Promise<void> => onRemove({
        name,
        status,
        percent,
        uid,
        isFolder,
        size,
        ...rest,
    });

    const {
        uploadFilesStore: {
            retry,
            setChosenForPolicyFileUid,
        },
    } = useStores();

    return (
        <div style={{ order: !isFolder ? 0 : -1, marginBottom: !isFolder ? '0' : '10px' }}>
            {!isFolder && (
                <FileItem
                    chosenForPolicyFileUid={chosenForPolicyFileUid}
                    onRemove={onRemoveClick}
                    name={name}
                    percent={percent}
                    retry={retry}
                    status={status}
                    setChosenForPolicyFileUid={setChosenForPolicyFileUid}
                    uid={uid}
                    size={size}
                />
            )}
            {isFolder && (
                <FolderItem
                    chosenForPolicyFileUid={chosenForPolicyFileUid}
                    foldedFiles={foldedFiles}
                    name={name}
                    onRemove={onRemoveClick}
                    setChosenForPolicyFileUid={setChosenForPolicyFileUid}
                    status={status}
                    uid={uid}
                    retry={retry}
                    uploadedFilesCount={uploadedFilesCount}
                    expanded={expanded}
                />
            )}
        </div>
    );
});

export default UploadItem;
