import React, { FC, memo } from 'react';

import style from './ResultSubTitle.module.scss';

interface ResultSubTitleProps {
    children: string;
}

const ResultSubTitle: FC<ResultSubTitleProps> = ({ children }) => (
    <h3 className={style.subtitle}>
        {children}
    </h3>
);

export default memo(ResultSubTitle);
