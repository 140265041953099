import React, { FC } from 'react';

import { Button } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { PUBLIC_ASSETS } from '@/consts';
import { useStores } from '../../../../../../../hooks';
import { useFilesActionsContext } from '../../../hooks/useFilesActionContext';
import nameSpace from '../i18nNameSpace';
import styles from './OpenSecurely.module.scss';

const OpenSecurely: FC = observer(() => {
    const { t } = useTranslation();
    const { selectFile } = useFilesActionsContext();
    const { recipientPageStore: { sharedFilesStore: { filesList } } } = useStores();

    const onClick = (): void => {
        selectFile(filesList[0]?.file_id);
    };

    const folderText = filesList[0]?.is_workspace ? 'openWorkspacesSecurely' : 'openFolderSecurely';
    const buttonText = !filesList[0]?.is_workspace && !filesList[0]?.is_folder ? 'openFilesSecurely' : folderText;

    return (
        <Button
            className={styles['open-securely-btn']}
            data-testid="recipientPage_openFileSecurely"
            onClick={onClick}
        >
            <img
                alt="SpecterX small logo"
                className={styles['spx-light-icon']}
                src={`${PUBLIC_ASSETS}/company_logo/specterx-icon-light.png`}
            />
            <span>
                {t(`${nameSpace}.${buttonText}`)}
            </span>
        </Button>
    );
});

export default OpenSecurely;
