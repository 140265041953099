import { useEffect, useMemo } from 'react';

import { i18n } from 'i18next';

import { LANGUAGES, SUPPORTED_LANGUAGES_KEYS } from '../../content/languages';
import config from '../../config/env';

const { DEFAULT_LANGUAGE } = config;

export type ChangeLanguage = (value: LANGUAGES) => void;

export const LANG_STORAGE_KEY = 'chosenLanguage';

export interface LanguageControls {
    changeLanguage: ChangeLanguage;
    chooseLanguage: ChangeLanguage;
    checkIsLanguageChosen: () => boolean;
}

const usePageLanguage = (i18nInstance: i18n): LanguageControls => {
    const changeLanguage = (value: LANGUAGES): void => {
        i18nInstance.changeLanguage(value);
        document.documentElement.dir = i18nInstance.dir(value);
    };

    const chooseLanguage = (value: LANGUAGES): void => {
        localStorage.setItem(LANG_STORAGE_KEY, value);
        changeLanguage(value);
    };

    const checkIsLanguageChosen = (): boolean => !!localStorage.getItem(LANG_STORAGE_KEY);

    const changeSupportedLanguage = (value: LANGUAGES): void => {
        if (SUPPORTED_LANGUAGES_KEYS.includes(value)) {
            changeLanguage(value);
        }
    };

    useEffect(() => {
        const savedLanguage: LANGUAGES = localStorage.getItem(LANG_STORAGE_KEY) as LANGUAGES;
        const lang: LANGUAGES = SUPPORTED_LANGUAGES_KEYS.includes(savedLanguage) ? savedLanguage : DEFAULT_LANGUAGE;
        if (lang !== i18nInstance.language) {
            changeLanguage(lang);
        }

        return () => {
            changeLanguage(LANGUAGES.en);
        };
    }, []);

    const memoizedLanguageControls = useMemo<LanguageControls>(
        () => ({
            changeLanguage: changeSupportedLanguage,
            chooseLanguage,
            checkIsLanguageChosen,
        }),
        [],
    );

    return memoizedLanguageControls;
};

export default usePageLanguage;
