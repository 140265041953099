import React, { FC } from 'react';

import IframeForm from '../../Common/IframeForm';
import PDFViewer, { PDFViewerPublicProps } from '../PDFViewer';
import { IframeParams } from '../interfaces';
import { OfficeViewerResponse, FileAccessResponse } from '../../../types/types';
import styles from './OpenWidget.module.scss';

interface OpenWidgetProps extends PDFViewerPublicProps {
    iframeParams: IframeParams;
}

const OpenWidget: FC<OpenWidgetProps> = ({
    iframeParams,
    PDFViewerStyle,
    ...rest
}) => (
    <div className={styles['viewer-wrapper']}>
        {(iframeParams as OfficeViewerResponse)?.viewUrl && (
            <IframeForm
                name="office_frame"
                config={{
                    url: (iframeParams as OfficeViewerResponse).viewUrl,
                    method: 'post',
                    data: {
                        access_token: (iframeParams as OfficeViewerResponse).accessToken,
                        access_token_ttl: (iframeParams as OfficeViewerResponse).accessTokenTtl,
                    },
                }}
                style={PDFViewerStyle}
            />
        )}
        {(iframeParams as FileAccessResponse)?.link && (
            <PDFViewer
                documentUrl={(iframeParams as FileAccessResponse).link}
                PDFViewerStyle={PDFViewerStyle}
                {...rest}
            />
        )}
    </div>
);

export default OpenWidget;
