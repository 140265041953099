import React, {
    FC, memo, ReactElement, useState,
} from 'react';

import { Dropdown } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import { MoreOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import i18n from '@/content';
import FileAction from '../../../FileAction/FileActionItem';
import { FileActionType, items } from '../../../FileAction/menuItems';
import styles from './FileActionsDropdown.module.scss';
import commonStyle from '../../../../Common/Typography/Typography.module.scss';

export type MenuClickCallback = (event: MenuInfo) => void;

const nameSpace = 'filesTable.actions';
const contextMenuNameSpace = 'filesTable.contextMenu';

type ButtonTitleType = 'open' | 'more'

interface FileActionsDropdownProps {
    options: FileActionType[];
    handleMenuClick: MenuClickCallback;
    buttonTitle?: ButtonTitleType;
    buttonClass?: string;
    openIcon?: ReactElement;
}

const FileActionsDropdown: FC<FileActionsDropdownProps> = ({
    buttonTitle,
    buttonClass,
    options,
    handleMenuClick,
    openIcon,
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const menuOptions = options.filter((option) => !!items[option]);

    const closeDropdown = (): void => setIsVisible(false);

    const onMenuClick: MenuClickCallback = (event): void => {
        closeDropdown();
        handleMenuClick(event);
    };

    const isTitleOpen = buttonTitle === 'open';

    return (
        <Dropdown
            open={isVisible}
            trigger={['click']}
            overlayClassName={styles['file-actions-wrapper']}
            onOpenChange={setIsVisible}
            menu={{
                onClick: onMenuClick,
                className: styles['actions-menu'],
                // Probably it's because of react-i18n override children props
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': 'sharedWithMe_table_more_options',
                items: menuOptions.map((option) => {
                    const { title, icon, component } = items[option];
                    return ({
                        key: option,
                        className: styles['file-action'],
                        label: (
                            <FileAction
                                title={i18n.t(`${contextMenuNameSpace}.${title}`)}
                                icon={icon}
                                component={component}
                            />
                        ),
                    });
                }),
            }}
        >
            <button
                onContextMenu={closeDropdown}
                className={classNames(
                    'ant-dropdown-link',
                    styles['linked-button'],
                    commonStyle['blue-half'],
                    styles['button-inner-color'],
                    buttonClass,
                    {
                        [commonStyle['text-small']]: isTitleOpen,
                        [styles['icon-button']]: !isTitleOpen,
                    },
                )}
                type="button"
                data-clickable
            >
                {openIcon || (
                    <>
                        {isTitleOpen
                            ? <span data-testid="sharedWithMe_table_open">{i18n.t(`${nameSpace}.${buttonTitle}`)}</span>
                            : <MoreOutlined data-testid="sharedWithMe_table_more" />}
                    </>
                )}
            </button>
        </Dropdown>
    );
};

export default memo(FileActionsDropdown);
