import React, { FC, memo } from 'react';

import Icon from '@ant-design/icons';
import camelCase from 'lodash/camelCase'

import { FileMenuItem } from '../menuItems';
import styles from './FileActionItem.module.scss';

const FileActionItem: FC<FileMenuItem> = ({
    icon, title, component,
}) => (
    <div className={styles['file-action']} data-testid={`sharedWithMe_table_more_optionsAll_${camelCase(title)}`}>
        {icon && <span className={styles['icon-wrapper']}>{icon}</span>}
        {component && <Icon className={styles['view-icon']} component={component} />}
        {title}
    </div>
);

export default memo(FileActionItem);
