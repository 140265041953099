import { useEffect } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import type { FileAccessError } from '@/types/types';

const NAME_SPACE = 'recipientPage.message.errors';

const useToastError = (errorType: FileAccessError): void => {
    const { t } = useTranslation();

    useEffect(() => {
        if (errorType === 'serverError') {
            message.error(t(`${NAME_SPACE}.serverError`));
        }
    }, [errorType]);
};

export default useToastError;
