import React, {
    FC, memo, ReactNode, ClipboardEvent,
} from 'react';

import classNames from 'classnames';

import styles from './ElasticDescription.module.scss';

type JustifyContent = 'start' | 'end' | 'center';

const onCopy = (event: ClipboardEvent<HTMLDivElement>): void => {
    const selection = document.getSelection();
    if (selection) {
        const textWithoutBreaks = selection.toString().replace(/(\r\n|\n|\r)/gm, '');
        event.clipboardData.setData('text/plain', textWithoutBreaks);
        event.preventDefault();
    }
};

export interface ElasticDescriptionUIProps {
    minWidth?: string;
    maxWidth?: string;
    width?: string;
    textClassname?: string;
    justify?: JustifyContent;
    prefix?: ReactNode;
    suffix?: ReactNode;
}

interface ElasticDescriptionProps extends ElasticDescriptionUIProps {
    ellipsisPart: string;
    nowrapPart: string;
    fullDescription: string;
}

const ElasticDescription: FC<ElasticDescriptionProps> = ({
    ellipsisPart,
    nowrapPart,
    fullDescription,
    minWidth = '0',
    maxWidth = '100%',
    width = '100%',
    textClassname = '',
    justify = 'start',
    prefix,
    suffix,
}) => {
    const containerStyle = { minWidth, maxWidth, width };
    const containerClasses = classNames(styles['elastic-description'], styles[`justify-${justify}`]);

    return (
        <div style={containerStyle} className={containerClasses} dir="ltr" onCopy={onCopy}>
            {prefix}
            <div className={styles['ellipsis-part-wrapper']}>
                <div className={`${styles['ellipsis-part']} ${textClassname}`} title={fullDescription}>
                    {ellipsisPart}
                </div>
            </div>
            {nowrapPart && (
                <div className={textClassname} title={fullDescription}>
                    {nowrapPart}
                </div>
            )}
            {suffix}
        </div>
    );
};

export default memo(ElasticDescription);
