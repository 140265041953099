import React, { FC, memo } from 'react';

import style from './AltAuthSeparator.module.scss';

interface AltAuthSeparatorProps {
    message: string;
}

const AltAuthSeparator: FC<AltAuthSeparatorProps> = ({ message }) => (
    <div className={style.separator}>
        <span>{message}</span>
    </div>
);

export default memo(AltAuthSeparator);
