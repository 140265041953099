import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';

import { AsyncCallback, FileAccessError, MFAError } from '@/types/types';
import { captureErrorForSentry, isUnionType } from '@/components/utils';

import AuthSettingsStore from '../AuthSettingsStore';
import BatchDownloadStore from '../BatchDownloadStore';
import {
    AccessMap,
    AccessType,
    AccessTypeState,
    BatchDownloadState,
    DownloadActionResult,
    FetchFileArgs,
    FileAccessState,
    FullAccessState,
    StatePartial,
    SuccessAccessResponse,
} from './interfaces';
import {
    BATCH_DOWNLOAD_KEY,
    getErrorType,
    MFA_ERRORS,
    MFA_STATE_INIT,
    openFile,
} from './helpers';

export {
    openFile,
    MAP_ERROR_TYPE_TO_STATUS_CODE,
    checkIsMFAErrorType,
    getOpenFileErrorText,
} from './helpers';

export type { AccessType, FullAccessState, AccessTypeState };

class FilesAccessStore {
    constructor(authSettingsStore: AuthSettingsStore, batchDownloadStore: BatchDownloadStore) {
        this.authSettingsStore = authSettingsStore;
        this.batchDownloadStore = batchDownloadStore;
        makeObservable(this);
    }

    @observable accessMap: AccessMap = new Map<string, FileAccessState>();

    @observable selectedAccessFileId: string;

    private readonly authSettingsStore: AuthSettingsStore;

    private readonly batchDownloadStore: BatchDownloadStore;

    @computed
    get batchDownloadState(): BatchDownloadState {
        const state = this.accessMap.get(BATCH_DOWNLOAD_KEY);
        if (state) {
            const { MFAState, accessTypesState: { batchDownload } } = state;
            return { MFAState, batchDownload };
        }
        return null;
    }

    @computed
    get isBatchDownloadLoading(): boolean {
        return this.batchDownloadState?.batchDownload?.isLoading;
    }

    @computed
    get batchDownloadError(): FileAccessError {
        return this.batchDownloadState?.batchDownload?.errorType;
    }

    @computed
    get batchDownloadAttemptsCounter(): number {
        return this.batchDownloadState?.MFAState?.attemptsCounter;
    }

    @computed
    get selectedItemState(): FileAccessState {
        return this.accessMap.get(this.selectedAccessFileId);
    }

    @computed
    get selectedItemAccessTypesState(): FullAccessState {
        return this.accessMap.get(this.selectedAccessFileId)?.accessTypesState;
    }

    @computed
    get selectedItemAttemptsCounter(): number {
        return this.selectedItemState?.MFAState?.attemptsCounter;
    }

    @computed
    get selectedItemMFAError(): MFAError {
        return this.selectedItemState?.MFAState?.errorType;
    }

    @action
    setAccessItem = (key: string, state: FileAccessState): void => {
        this.accessMap.set(key, state);
    };

    @action
    deleteAccessItem = (key: string): void => {
        this.accessMap.delete(key);
    };

    @action
    setSelectedAccessFileId = (value: string): void => {
        this.selectedAccessFileId = value;
    };

    @action
    clearSelectedItem = (): void => {
        this.accessMap.delete(this.selectedAccessFileId);
        this.selectedAccessFileId = null;
    };

    getAccessTypeState = <T extends AccessType>(fileId: string, accessType: T): AccessTypeState<T> => (
        this.accessMap.get(fileId)?.accessTypesState?.[accessType] as AccessTypeState<T>
    );

    checkIsLoading = (fileId: string, accessType: AccessType): boolean => (
        this.accessMap.get(fileId)?.accessTypesState?.[accessType]?.isLoading
    );

    tryFetchAccessLink = async ({
        fileId,
        filename,
        accessType,
        code,
        throwError = false,
        alwaysCheckMFA,
    }: FetchFileArgs): Promise<boolean | never> => this.processRequest(
        fileId,
        accessType,
        !code,
        async (): Promise<void> => {
            const { API } = this.authSettingsStore;
            const result = await openFile<typeof accessType>({
                API,
                accessType,
                fileId,
                code,
                alwaysCheckMFA,
            });
            this.updateFileAccessState(fileId, accessType, {
                MFAStatePartial: { ...MFA_STATE_INIT },
                newAccessTypeState: { result, isLoading: false },
            });
            if ((result as SuccessAccessResponse).status === 'processing') {
                this.moveStatusCheckToBatchDownload(result as DownloadActionResult, fileId, filename);
            }
        },
        throwError,
    );

    tryStartBatchDownload = async (
        filesIds: string[],
        code?: string,
        throwError = false,
    ): Promise<boolean | never> => this.processRequest(
        BATCH_DOWNLOAD_KEY,
        'batchDownload',
        !code,
        async (): Promise<void> => {
            await this.batchDownloadStore.startBatchDownload(filesIds, code);
            // We don't need to store any result in this flow
            this.deleteAccessItem(BATCH_DOWNLOAD_KEY);
        },
        throwError,
    );

    private async processRequest(
        key: string,
        accessType: AccessType,
        isNewAttempt: boolean,
        worker: AsyncCallback<void>,
        throwError = false,
    ): Promise<boolean | never> {
        this.processStart(key, accessType, isNewAttempt);
        let result = true;
        try {
            await worker();
        } catch (error) {
            this.processError(key, accessType, error, throwError);
            result = false;
        }
        return result;
    }

    private processStart(key: string, accessType: AccessType, isNewAttempt: boolean): void {
        const initAccessState: AccessTypeState<AccessType> = { isLoading: true };
        if (!this.accessMap.get(key)) {
            const initValue: FileAccessState = {
                MFAState: { ...MFA_STATE_INIT },
                accessTypesState: { [accessType]: initAccessState },
            };
            this.setAccessItem(key, initValue);
        } else if (isNewAttempt) {
            this.updateFileAccessState(key, accessType, {
                MFAStatePartial: { ...MFA_STATE_INIT },
                newAccessTypeState: initAccessState,
            });
        } else {
            this.updateFileAccessState(key, accessType, { newAccessTypeState: initAccessState });
        }
    }

    private updateFileAccessState(
        key: string,
        accessType: AccessType,
        {
            MFAStatePartial = {},
            newAccessTypeState,
        }: StatePartial,
    ): void {
        const itemState = this.accessMap.get(key);
        if (itemState) {
            const { accessTypesState, MFAState } = itemState;
            const newAccessTypesState: FullAccessState = newAccessTypeState
                ? { ...accessTypesState, [accessType]: newAccessTypeState }
                : accessTypesState;
            const newItemState: FileAccessState = {
                ...itemState,
                MFAState: { ...MFAState, ...MFAStatePartial },
                accessTypesState: newAccessTypesState,
            };
            this.setAccessItem(key, newItemState);
        }
    }

    private processError(key: string, accessType: AccessType, error: unknown, throwError = false): void {
        let errorType = getErrorType(error);
        const currentState: FileAccessState = this.accessMap.get(key);
        if (currentState) {
            let { MFAState: { attemptsCounter } } = currentState;
            if (errorType === 'MFAFailed') {
                attemptsCounter -= 1;
                if (attemptsCounter === 0) {
                    errorType = 'MFALimitReached';
                }
            }
            const errorState: StatePartial = {
                newAccessTypeState: { errorType, isLoading: false },
            };
            if (isUnionType<MFAError>(errorType, MFA_ERRORS)) {
                errorState.MFAStatePartial = { errorType, attemptsCounter };
            }
            this.updateFileAccessState(key, accessType, errorState);
            if (errorType === 'serverError') {
                captureErrorForSentry(error, 'FilesAccessStore.processError');
            }
        }
        if (throwError) {
            throw error;
        }
    }

    private moveStatusCheckToBatchDownload(result: DownloadActionResult, fileId: string, filename: string): void {
        if (result.batchId) {
            this.batchDownloadStore.startBatchDownloadStatusCheck(result.batchId, filename || fileId);
        } else {
            this.batchDownloadStore.startSingleDownloadStatusCheck(fileId, filename || fileId);
        }
    }
}

export default FilesAccessStore;
