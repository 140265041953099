import React, { FC, memo } from 'react';

import { Tag } from 'antd';

import i18n from '@/content';
import { AccessApps } from '../../../../stores/AuditsStore/interaces';
import { getTranslationOrItem } from '../../../utils';
import { MAP_ACCESS_APP_TO_COLOR } from '../../constants';

interface AccessAppCellProps {
    accessApp: AccessApps;
}

const AccessAppCell: FC<AccessAppCellProps> = ({ accessApp }) => (
    <Tag color={MAP_ACCESS_APP_TO_COLOR[accessApp] || '#108ee9'}>
        <code>
            {getTranslationOrItem(
                i18n.t.bind(i18n),
                accessApp,
                `general.specterxCommon.fileAccessApp.${accessApp.toLowerCase()}`,
            )}
        </code>
    </Tag>
);

export default memo(AccessAppCell);

