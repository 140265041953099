export interface BaseErrorConstructor {
    new(message?: string): Error;
    readonly prototype: Error;
}

const errorsFactory = (name: string): BaseErrorConstructor => (
    class extends Error {
        constructor(message?: string) {
            super(message);
            this.name = name;
        }
    }
);

export const PermissionsError = errorsFactory('PermissionsError');
export const NotFoundError = errorsFactory('NotFoundError');
export const PermissionsCheckFail = errorsFactory('PermissionsCheckFail');

export default errorsFactory;
