import React, { FC, useEffect } from 'react';

const CrossOriginAuthenticator: FC = () => {
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        window.addEventListener('message', (event: MessageEvent) => {
            const isParentOauthMessage = event.origin === window.origin && event.data.message === 'requestResult';
            if (isParentOauthMessage) {
                (event.source as WindowProxy).postMessage(
                    { message: 'deliverResult', result: { code } },
                    window.location.origin,
                );
            }
        });
    }, []);

    return <></>;
};

export default CrossOriginAuthenticator;
