import { UploadProcess, UploadProgressSnapshot } from './interfaces';

type UploadProcessPartial = Partial<UploadProcess>;

type UpdateProcessPartial = (process: UploadProcess) => Partial<UploadProcessPartial>;

class UploadProcessesMap {
    private readonly processesMap: Map<string, UploadProcess>;

    constructor() {
        this.processesMap = new Map<string, UploadProcess>();
    }

    add(process: UploadProcess): void {
        this.processesMap.set(process.uid, process);
    }

    update(uid: string, updater: UploadProcessPartial | UpdateProcessPartial): void {
        const process: UploadProcess = this.processesMap.get(uid);
        if (process) {
            let updatedProcess: UploadProcess;
            if (typeof updater === 'function') {
                updatedProcess = { ...process, ...updater(process) };
            } else {
                updatedProcess = { ...process, ...updater };
            }
            this.processesMap.set(uid, updatedProcess);
        }
    }

    // TODO: provide deep merge to keep it into single method
    updateProgressSnapshot(uid: string, data: Partial<UploadProgressSnapshot>): void {
        const process: UploadProcess = this.processesMap.get(uid);
        if (process?.progressSnapshot) {
            const newSnapshot = { ...process.progressSnapshot, ...data };
            this.processesMap.set(uid, { ...process, progressSnapshot: newSnapshot });
        }
    }

    remove(uid: string): void {
        this.processesMap.delete(uid);
    }

    get(uid: string): UploadProcess {
        return this.processesMap.get(uid);
    }

    has(uid: string): boolean {
        return this.processesMap.has(uid);
    }

    clear(): void {
        this.processesMap.clear();
    }

    complete(uid: string, succeed: boolean): void {
        const process = this.processesMap.get(uid);
        if (process && !process.completed) {
            process.onFinish?.(succeed);
        }
        this.update(uid, { completed: true });
    }
}

export default UploadProcessesMap;
