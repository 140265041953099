import React, { FC, memo } from 'react';

import { bytesToText } from '../../utils';

interface FileSizeProps {
    filesize: number;
    isFolder: boolean;
}

const FileSizeCell: FC<FileSizeProps> = ({ filesize, isFolder }) => (
    <>
        {isFolder ? '' : bytesToText(filesize)}
    </>
);

export default memo(FileSizeCell);
