import { forwardRef } from 'react';

import {
    ConfigProvider,
    Input as AntInput,
    InputProps,
    InputRef,
} from 'antd';
import type { TextAreaProps } from 'antd/es/input';

import { HEXToRGBA } from '@/components/utils';
import { COLORS, CONTROLS_SIZES } from '@/theme';

const DESIGN_TOKENS = {
    colorBorder: HEXToRGBA(COLORS.cta, '25%'),
    colorText: COLORS.primaryNew,
    colorTextPlaceholder: HEXToRGBA(COLORS.primaryNew, '50%'),
    colorPrimary: COLORS.cta,
    colorPrimaryHover: COLORS.cta,
    colorTextQuaternary: HEXToRGBA(COLORS.primaryNew, '50%'),
    controlOutline: HEXToRGBA(COLORS.cta, '15%'),
    borderRadius: CONTROLS_SIZES.borderRadius,
    controlHeightSM: CONTROLS_SIZES.heightSM,
    fontSizeSM: CONTROLS_SIZES.fontSM,
};

export const Input = forwardRef<InputRef, InputProps>((props, ref) => (
    <ConfigProvider
        theme={{
            components: {
                Input: {
                    controlHeight: CONTROLS_SIZES.heightDefault,
                },
            },
            token: DESIGN_TOKENS,
        }}
    >
        <AntInput ref={ref} {...props} />
    </ConfigProvider>
));

export const TextArea = forwardRef<InputRef, TextAreaProps>((props, ref) => (
    <ConfigProvider theme={{ token: DESIGN_TOKENS }}>
        <AntInput.TextArea ref={ref} {...props} />
    </ConfigProvider>
));
