import { action, observable, makeObservable } from 'mobx';

import AuthSettingsStore from '../AuthSettingsStore';
import { MapStorageTypeToSettingsFields, SettingsResponse, StoreType } from './interfaces';
import { BASEURL, ENDPOINTS } from '../../api';
import { captureErrorForSentry } from '../../components/utils';

class SettingsStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.authSettingsStore = authSettingsStore;
        makeObservable(this);
    }

    private readonly authSettingsStore: AuthSettingsStore;

    storagesTypes: string[] = ['gcs', 'sharepoint'];

    defaultStoragesObjects: MapStorageTypeToSettingsFields = {};

    @observable newStorageType: StoreType = 'sharepoint';

    @observable newStorageObject: Record<string, string> = {};

    @action
    setNewStorageType = (newType: StoreType): void => {
        this.newStorageType = newType;
    }

    @action
    setNewStorageObject = (val: Record<string, string>): void => {
        this.newStorageObject = val;
    }

    clearStorageObject = (): void => {
        const newCleanObject = {};
        const newCleanObjectFields = this.defaultStoragesObjects[this.newStorageType];
        if (newCleanObjectFields && typeof newCleanObjectFields === 'object') {
            newCleanObjectFields.forEach((item) => {
                newCleanObject[item] = '';
            });
        }
        this.setNewStorageObject(newCleanObject);
    }

    setDefaultStorageObjects = (val: MapStorageTypeToSettingsFields): void => {
        this.defaultStoragesObjects = val;
    }

    fetchStorageKeys = async (): Promise<void> => {
        const { API } = this.authSettingsStore;
        try {
            const storageFieldsObject = {};
            const allStorages: SettingsResponse[] = await Promise.all([
                ...this.storagesTypes.map((item) => (
                    API.get(BASEURL.backend(), ENDPOINTS.getApiSettingsStorageKeys(item), {}))),
            ]);
            this.storagesTypes.forEach((item, index) => {
                storageFieldsObject[item] = allStorages[index].fields;
            });
            this.setDefaultStorageObjects(storageFieldsObject);
            this.clearStorageObject();
        } catch (error) {
            captureErrorForSentry(error, 'SettingsStore.fetchStorageKeys');
        }
    }
}

export default SettingsStore;
