import React, { ReactText, PureComponent } from 'react';

import { InputNumber, Tooltip } from 'antd';
import type { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';

import i18n from '@/content';
import { validateNumRange } from '../../validators';
import styles from './NumRangeInput.module.scss';

interface NumRangeInputProps extends InputNumberProps {
    onChange: (value: number | string | undefined) => void;
    min: number;
    max: number;
    allowDecimal?: boolean;
}

enum ErrorTypes {
    required = 'required',
    outOfRange = 'outOfRange',
    valueShouldBeInt = 'valueShouldBeInt',
}

interface ErrorState {
    hasError: boolean;
    errorType: ErrorTypes,
}

const errorStateInit: ErrorState = {
    hasError: false,
    errorType: null,
};

const validationNameSpace = 'general.validation';
const numRangeNameSpace = `${validationNameSpace}.numRange`;

const errorMessageKeys: Record<ErrorTypes, string> = {
    [ErrorTypes.outOfRange]: `${numRangeNameSpace}.outOfRange`,
    [ErrorTypes.valueShouldBeInt]: `${numRangeNameSpace}.valueShouldBeInt`,
    [ErrorTypes.required]: `${validationNameSpace}.required`,
};

interface NumRangeInputState {
    errorState: ErrorState;
}

class NumRangeInput extends PureComponent<NumRangeInputProps, NumRangeInputState> {
    constructor(props: NumRangeInputProps) {
        super(props);
        this.state = {
            errorState: { ...errorStateInit },
        };
    }

    private getNewState(value: number | string, isEmpty: boolean): NumRangeInputState {
        const {
            allowDecimal, min, max, required,
        } = this.props;
        const hasError = (required && isEmpty) || !validateNumRange(Number(value), min, max, !allowDecimal);
        let errorType: ErrorTypes = null;
        if (hasError) {
            if (isEmpty) {
                errorType = ErrorTypes.required;
            } else if (!allowDecimal && !Number.isInteger(value)) {
                errorType = ErrorTypes.valueShouldBeInt;
            } else {
                errorType = ErrorTypes.outOfRange;
            }
        }
        return { errorState: { hasError, errorType } };
    }

    onChange = (value: ReactText): void => {
        const { onChange: onChangeCallback } = this.props;
        const number: number = +value;
        const isEmpty = value === '' || value === null;
        const newValue = isEmpty ? NaN : number;
        this.setState(this.getNewState(newValue, isEmpty));
        onChangeCallback(newValue);
    }

    onFocus = (): void => {
        const { value } = this.props;
        const isEmpty = value === null || value === undefined || Number.isNaN(value);
        this.setState(this.getNewState(value, isEmpty));
    }

    render(): JSX.Element {
        const {
            min, max, className, value, ...otherProps
        } = this.props;
        const { errorState: { hasError, errorType } } = this.state;
        const inputClasses = classNames(
            styles['num-range-input'],
            {
                [className]: !!className,
                [styles['has-error']]: hasError,
            },
        );
        const currentValue = Number.isNaN(value) ? null : value;

        return (
            <Tooltip
                color="white"
                open={hasError}
                title={(
                    <span className={styles['error-hint']}>
                        {i18n.t(errorMessageKeys[errorType], { min, max })}
                    </span>
                )}
            >
                <InputNumber
                    {...otherProps}
                    value={currentValue}
                    min={min}
                    max={max}
                    className={inputClasses}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                />
            </Tooltip>
        );
    }
}

export default NumRangeInput;
