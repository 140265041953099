import React from 'react';
import { Button, Result } from 'antd';
import { inject } from 'mobx-react';
import { SecuredFile } from '../../../../types/types';
import { ENDPOINTS, BASEURL } from '../../../../api';
import { ModalContentProps } from '../../../Common/Modal';
import { AuthSettingsStore } from '../../../../stores';
import { captureUnexpectedNetworkError } from '../../../utils';

interface RevokeProps extends ModalContentProps {
    fileSelected: SecuredFile;
    closeModal: () => void;
    authSettingsStore?: AuthSettingsStore;
}

interface RevokeState {
    isLoading: boolean
}

@inject('authSettingsStore')
export default class extends React.Component<RevokeProps, RevokeState> {
    handleRevoke = async (): Promise<void> => {
        const {
            fileSelected,
            setLoadingStatus,
            setErrorStatus,
            authSettingsStore: { API },
        } = this.props;

        try {
            setLoadingStatus(true, 'Revoking File...');
            await API.post(BASEURL.backend(), ENDPOINTS.revoke(fileSelected.fid), {});
            this.closeModal();
        } catch (error) {
            captureUnexpectedNetworkError(error, 'RevokeModal.handleRevoke');
            setErrorStatus(true, 'Could not revoke file. Please try again in a few minutes.');
        }
    };

    closeModal = (): void => {
        const { closeModal: closeModalProp, resetParentModalState } = this.props;
        closeModalProp();
        resetParentModalState?.();
    }

    render(): JSX.Element {
        const { fileSelected } = this.props;
        const displayFileName = fileSelected.filename || fileSelected.fid;

        return (
            <Result
                title={(
                    <div>
                        Are you sure revoke this file?
                        {' '}
                        <div>{displayFileName}</div>
                    </div>
                )}
                extra={(
                    <>
                        <Button onClick={this.handleRevoke} danger>
                            Revoke
                        </Button>
                        <Button type="primary" onClick={() => this.closeModal()}>
                            Cancel
                        </Button>
                    </>
                )}
            />
        );
    }
}
