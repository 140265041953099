import { Component, FC, ComponentClass } from 'react';

import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Location } from 'history';

export interface WithRouterProps {
    location: Location;
    navigate: NavigateFunction
}

export const withRouter = <P, >(Child: ComponentClass<P & WithRouterProps, any>): FC<P> => (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Child location={location} navigate={navigate} {...props} />
    );
};
